export const validationRegexOnlyAlpha = /^(?![\s])^[a-zA-Z\s,]*$/;
export const validationRegexOnlyAlpha1 = /^(?![\s])^[a-zA-Z\s,]*$/;
// export const validationRegexTime = /^(0[1-9]|1[0-2]):[0-5][0-9][APap][Mm]-(0[1-9]|1[0-2]):[0-5][0-9][APap][Mm]$/;
export const validationRegexTime = /^(?![\s])^[A-Z0-9\s:-]*$/;


export const validationRegexOnlyTextarea = /^(?![\s])^[a-zA-Z\s,.@#:&%$!_-]*$/;
export const validationRegexAlphaNumeric = /^(?![\s])^[a-zA-Z0-9\s]*$/;
export const validationRegexMobileNo = /^\d{0,10}$/;
export const validationRegexPinNo = /^[0-9]{0,6}$/;
export const regexOnly = /^([a-zA-Z0-9._%+-]).*@/;
export const validationRegexAmount = /^\d{0,10}$/;
// export const validationRegexAmount = /^[+]?\d{1,3}(,\d{2,3}|\.\d+)?$|^(\d+(\.\d{1,3})?)$/;
export const emailRegex = /^[a-zA-Z0-9@.]{0,50}$/;
export const timeRegex = /^[\d:]{0,10}$/;
export const validationRegexOnlyPincode = /^(?![\s])[0-9]*$/;
export const decimalRegex = /^\d+(\.\d+)?$/;
export const validationRegexAddress = /^(?![\s])^[a-zA-Z0-9\s,./*@#:&$!_-]*$/;
export const textCommaRegex = /\b[a-zA-Z]+(?:, [a-zA-Z]+)*\b/;
export const amountPattern = /^(\d+(\.\d{1,2})?)?$/;
export const distancePattern = /^(?!\s)\d*\.?\d{0,1}$/;
export const amountWithDecimalPattern = /^(?!\s)\d*\.?\d{0,2}$/;
export const validationRegexSalary = /^[\d.,]{0,9}$/;
export const validationRegexClass = /^(?![\s])^[a-zA-Z0-9\s,@#:/-]*$/;
export const commaAlpha = /,?[a-zA-Z][a-zA-Z]*,?/;
export const schoolCode = /^(?![\s])^[a-zA-Z0-9\s-]*$/;
export const passwordRegex = /^(?!\s)[a-zA-Z0-9\s,./*@#:&$!_-]{0,25}$/;
