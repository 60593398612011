/*
    authors: Rajeev
    date: 20-12-2023
    update: 20-12-2023 
    version: 1.0
*/

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { setPostTime } from "./addTimeTableslicer";

const slice = createSlice({
  name: "viewClassTimeTable",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchViewClassTimeTableStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchViewClassTimeTableSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchViewClassTimeTableFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchViewClassTimeTableStart,
  fetchViewClassTimeTableSuccess,
  fetchViewClassTimeTableFailure,
} = slice.actions;

export const fetchViewClassTimeTableOnce = (FranchiseeSchoolId, classesClassSectionsId, month, day) => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewClassTimeTable;
  const tokenId = token();
  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchViewClassTimeTableStart());

    try {
      // Construct the dynamic URL
      const url = `${API_PATH}/franchisee-school/${FranchiseeSchoolId}/timeTables?classesClassSectionsId=${classesClassSectionsId}&month=${month}&day=${day}`;
      
      // Make the API request
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error("Request failed"); // Customize error handling if necessary
      }

      const data1 = await response.json();
      dispatch(setPostTime([]));
      dispatch(fetchViewClassTimeTableSuccess(data1));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchViewClassTimeTableFailure());
    }
  }
};

export default slice.reducer;
