import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import '../TextFields/TextInputField.css'
const AmountField = ({
  label,
  name,
  required,
  value,
  onChange,
  placeholder = 'e.g., 1234.56',
  feedbackMessage,
  colSize,
  validated,
  validationRegex,
  maxLength,
  textType, 
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    let inputValue = event.target.value;

    // Check if the input exceeds maxLength
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    // Use the provided validationRegex or the default regex for numeric amounts
    const isValidInput = (validationRegex || /^[0-9]+(\.[0-9]{1,2})?$/).test(inputValue);

    if (isValidInput) {
      setError(null);
      onChange({ target: { name, value: inputValue } });
    } else {
      setError(`Please enter a valid ${textType || 'numeric'} (max ${maxLength} numbers).`);
    }
  };


  const requiredField = required === false ? false : true;
   
  return (
    
      <Form.Group className="mb-4">
  {label && <Form.Label className="label-style">{label}</Form.Label>}


        <Form.Control
          name={name}
          className="textInput_Field"
          required={requiredField}
          placeholder={placeholder}
          value={value}
          type="text"
          validated={validated}
          onChange={handleInputChange}
          maxLength={maxLength}
          isInvalid={!!error}
        />
        <Form.Control.Feedback type="invalid">
          {error ||
            (validated && !((validationRegex || /^[0-9]+(\.[0-9]{1,2})?$/).test(value)) && feedbackMessage) ||
            (validated && value.length <= 0 && feedbackMessage)}
        </Form.Control.Feedback>
      </Form.Group>
  );
};

export default AmountField;