import React,{useState,useEffect} from 'react';
import { token } from '../../Api/token';
import { API_PATH } from '../../Api/api';
import { Avatar, Card ,Typography,Row,Col} from 'antd';

const { Title, Text } = Typography;

const ChildrenProfile = (props) => {
  
  const { eachChildData } = props;

  // const [displayedImage, setDisplayedImage] = useState(null);
  const dob = eachChildData?.dateOfBirth;

  const image = eachChildData?.profilePictureUrl||'';
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - dob.getFullYear();
    const dobMonth = dob.getMonth();
    const currentMonth = currentDate.getMonth();

    if (currentMonth < dobMonth || (currentMonth === dobMonth && currentDate.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  


  const age = calculateAge(dob);
  // const bufferToBase64 = (buffer) => {
  //   return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  // };
  // const displayImage = async (image) => {
  //   try {
  //     const tokenId = token();
  //     const response = await fetch(API_PATH + `/uploadFiles/?fileId=${image}`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${tokenId}`
  //       }
  //     });
  //     const data = await response.json();

  //     if (data && data.length > 0) {
  //       const base64String = bufferToBase64(data[0].data.data);
  //       setDisplayedImage(`data:image/png;base64,${base64String}`);
  //     } else {
  //       setDisplayedImage(null);
  //     }
  //   } catch (err) {
  //     console.error('Error fetching image:', err);
  //     setDisplayedImage(null);
  //   }
  // };

  // useEffect(() => {
  //   let isMounted = true;
  //     isMounted && displayImage(image);
  //     return ()=>isMounted=false;
  // }, [image]);

  try {

    return (
      <>
        {/* <div className='parent-data-wrapper'>
          <div className='parent-data'>
            <Row>
              <Col>
            <div className='each-prop'><span className='each-key'>Name </span><span className='each-colon'>:</span><span className='each-value'>{eachChildData?.name}</span></div>
            <div className='each-prop'><span className='each-key'>DOB  </span><span className='each-colon'>:</span><span className='each-value'>{formatDate(eachChildData?.dateOfBirth)}</span></div>
            <div className='each-prop'><span className='each-key'>Age  </span><span className='each-colon'>:</span><span className='each-value'>{age}</span></div>
            <div className='each-prop'><span className='each-key'>Gender  </span><span className='each-colon'>:</span><span className='each-value'>{eachChildData?.gender}</span></div>
            </Col>
            </Row>
          </div>
          <div className='profile-data'>
            {image?
              <Image src={image} alt="Children Picture" style={{  width: "140px", height: '125px', borderRadius: "30px" }}/>:null
            }
          </div>
        </div> */}

       <Card style={{ width: '100%', backgroundColor: 'transparent', border: 'none' }}>
        <Row align="middle">
          <Col>
            {image ? <Avatar size={180} src={image} /> : null}
          </Col>
          <Col style={{ marginLeft: '-75px' }}>
            <Title level={4} style={{ margin: 0 }}>{eachChildData?.name || ''}</Title>
            <Text><strong>DOB:</strong> {formatDate(eachChildData?.dateOfBirth)}</Text><br />
            <Text><strong>Age:</strong> {age}</Text><br />
            <Text><strong>Gender:</strong>{eachChildData?.gender}</Text>
          </Col>
          <Col />
        </Row>
      </Card>
      </>
    );
  } catch (err) {
    return <div>Error occurred! {err}</div>;
  }
};

export default ChildrenProfile;




// import React from 'react';

// const ChildrenProfile = (props) => {
//   const { eachChildData } = props;
//   const dob = eachChildData?.dateOfBirth;

//   function calculateAge(dateOfBirth) {
//     const dob = new Date(dateOfBirth);
//     const currentDate = new Date();

//     let age = currentDate.getFullYear() - dob.getFullYear();
//     const dobMonth = dob.getMonth();
//     const currentMonth = currentDate.getMonth();

//     if (currentMonth < dobMonth || (currentMonth === dobMonth && currentDate.getDate() < dob.getDate())) {
//       age--;
//     }

//     return age;
//   }

//   const age = calculateAge(dob);
//   const imagePaths = eachChildData?.profilePictureUrl && eachChildData?.profilePictureUrl.replace(/\\/g, '/');
  
//   const imgs = () => {
//     const pathParts = imagePaths?.split('kidhood-images-and-docs');
//     const docsPaths = pathParts ? pathParts[1] : '';
//     return docsPaths;
//   };

//   const imagePath = `../../kidhood-images-and-docs${imgs()}`;

//   try {
//     // Check if the file exists before attempting to display it
//     const imageExists = new Image();
//     imageExists.src = imagePath;

//     return (
//       <>
//         <div className='parent-data-wrapper'>
//           <div className='parent-data'>
//             <div className='each-prop'><span className='each-key'>Name </span><span className='each-colon'>:</span><span className='each-value'>{eachChildData?.name}</span></div>
//             <div className='each-prop'><span className='each-key'>Date of birth  </span><span className='each-colon'>:</span><span className='each-value'>{eachChildData?.dateOfBirth}</span></div>
//             <div className='each-prop'><span className='each-key'>Age  </span><span className='each-colon'>:</span><span className='each-value'>{age}</span></div>
//             <div className='each-prop'><span className='each-key'>Gender  </span><span className='each-colon'>:</span><span className='each-value'>{eachChildData?.gender}</span></div>
//           </div>
//           <div className='profile-data'>
//             <object
//               className='profileImg'
//               data={imagePath}
//               type="image/jpeg"
//               width='150px'
//               height='150px'
//             >
//               <p>Unable to display Profile Picture or File not found!</p>
//             </object>
//           </div>
//         </div>
//       </>
//     );
//   } catch (err) {
//     return <div>Error occurred! {err}</div>;
//   }
// };

// export default ChildrenProfile;
