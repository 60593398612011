import React from "react";
import { Modal, Table, Button } from "react-bootstrap";

const FeeDueModel = ({ show, handleClose, dueDates }) => {
  return (
    <Modal show={show} onHide={handleClose} className="modal">
      <Modal.Header closeButton>
        <Modal.Title>Due Dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table striped bordered hover>
  <thead>
    <tr>
      <th>Term</th>
      <th>Due Date</th>
      <th>Amount</th>
    </tr>
  </thead>
  <tbody>
    {dueDates &&
      dueDates.map((item, index) => (
        <tr key={index}>
          <td>{item.term}</td>
          <td>{item.dueDate}</td>
          <td>{item.amountDue}</td>
        </tr>
      ))}
  </tbody>
</Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeeDueModel;