/*
    authors: Rajeev
    date: 05-01-2024
    update:05-01-2024 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimingDataOnce } from "./slicer/viewTimingSlicer";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import SelectDropdown from "../SelectDropdown";
import { fetchClassSchoolDataOnce } from "../Franchise/slice/viewSchoolSlice";
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';

function ViewTimeTable() {
  const columns = [
    { field: "timing", header: "Timings" },
    { field: "Monday", header: "Monday" },
    { field: "Tuesday", header: "Tuesday" },
    { field: "Wednesday", header: "Wednesday" },
    { field: "Thursday", header: "Thursday" },
    { field: "Friday", header: "Friday" },
    { field: "Saturday", header: "Saturday" },
    { field: "Sunday", header: "Sunday" },
  ];
  var months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  const [searchPerformed, setSearchPerformed] = useState(false);
  const franchiseeSchoolId = (sessionStorage.getItem('franchiseeSchoolId'))
  const Ids = Number(franchiseeSchoolId)
  const teacherId = Number(sessionStorage.getItem('userId'))
  const roleIds = Number(sessionStorage.getItem("roleId"))

  const [selectedClass, setSelectedClass] = useState("");
  const [dropDowns, setDropDowns] = useState({});
  const [formData, setFormData] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [formErrors, setFormErrors] = useState({
    class: false,
    monthName: false,
    day: false,
    classesSectionsSubject: false,
    student: false
  });

  const [viewClassTimetableData, setViewClassTimetableData] = useState([]);
  const dispatch = useDispatch();
  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
  const viewSchoolData = useSelector((state) => state.viewSchoolData.data) || [];
  useEffect(() => {
    if (roleIds === 1) {
      dispatch(fetchClassSchoolDataOnce());
    }
  }, [formData.schoolId]);

  useEffect(() => {

    if (viewClassTimetableData.length === 0) {
      dispatch(fetchTimingDataOnce());
    }

  }, [viewClassTimetableData, dispatch]);

  useEffect(() => {
    if (formData.schoolId || (franchiseeSchoolId !== "undefined" || Ids)) {

      dispatch(fetchFranchiseClasswiseSectionsDataOnce(formData.schoolId || franchiseeSchoolId));

    }
  }, [formData.schoolId]);

  const [classData, setClassData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tokenId = token();
  useEffect(() => {

    if (roleIds === 6) {
      const fetchData = async () => {
        try {
          const url = `${API_PATH}/franchisee-school/${Ids}/teachers/${teacherId}/classes`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          const result = await response.json();
          setClassData(result);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    }
  }, [Ids, teacherId]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);
    setShowTable(false);

    const classSectionData = classesClassSectionData.find((c) => {
      if (Array.isArray(selectedClass)) {
        return c.classSections.some(section => selectedClass.includes(section.classesclassSection));
      } else {
        return c.id === selectedClass;
      }
    });

    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
    setDropDowns({ ...dropDowns, sections: "" });
  }, [selectedClass]);

  const [sectionsData, setSectionsData] = useState([]);
  const classSectionIds = sessionStorage.getItem("classSectionIds");

  useEffect(() => {
    const parsedSectionsData = JSON.parse(classSectionIds) || [];
    setSectionsData(parsedSectionsData);
  }, []);

  const handleSearchClick = async (e) => {
    e.preventDefault();

    let errors = {};
    // franchisor
    if (franchiseeSchoolId === "undefined") {
      if (!formData.schoolName) errors.schoolName = true;
      if (!formData.monthName) errors.monthName = true;
      if (!selectedClass) {
        errors.class = !selectedClass;
      }

      setFormErrors(errors);
    }
    else {
      if (!formData.monthName) errors.monthName = true;

      if (!selectedClass) {
        errors.class = !selectedClass;
      }
      setFormErrors(errors);
    }
    if (Object.keys(errors).length > 0) return;

    const { monthName, dayId } = formData;
    const tokenId = token();

    try {
      const url = `${API_PATH}/franchisee-school/${Ids || formData.schoolId}/timeTables?classesClassSectionsId=${selectedSection ? selectedSection : selectedClass}&month=${monthName}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();

      setViewClassTimetableData(data);
      setShowTable(true);
      setSearchPerformed(true);
    } catch (error) {
      console.error('Error fetching timetable data:', error);
    }
  };

  const formatTimetableData = () => {
    // Get all unique timings from all days
    const allTimings = [...new Set(Object.values(viewClassTimetableData).flat().map(entry => entry.timing))];
  
    return allTimings.map(timing => {
      const row = { timing };
  
      // For each day, find the subject for the current timing
      Object.keys(viewClassTimetableData).forEach(day => {
        row[day] = viewClassTimetableData[day].find(entry => entry.timing === timing)?.subject || "";
      });
  
      return row;
    });
  };
  
  return (
    <>
      <div className="content-header">View TimeTable</div>
      <>
        {/* franchisor*/}
        {franchiseeSchoolId === "undefined" ? (
          <Row style={{ justifyContent: "center" }}>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                value={
                  dropDowns.schoolName
                    ? { value: dropDowns.schoolName, label: dropDowns.schoolName }
                    : null
                }
                options={viewSchoolData.map((option, index) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, schoolId: selectedOption.id, schoolName: selectedOption.value });
                  setDropDowns({ ...dropDowns, schoolName: selectedOption.value });
                  setFormErrors({ ...formErrors, schoolName: false });
                }}
                placeholder="Select a schoolName"
              // validated={validated}
              />
              {formErrors.schoolName && (
                <div className="error-message">Please Select schoolName .</div>
              )}
            </Col>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                value={
                  dropDowns.monthName
                    ? { value: dropDowns.monthName, label: dropDowns.monthName }
                    : null
                }
                options={Object.keys(months).map(key => ({
                  id: key,
                  value: months[key],
                  label: months[key],
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, monthName: selectedOption.value });
                  setDropDowns({ ...dropDowns, monthName: selectedOption.value });
                  setFormErrors({ ...formErrors, monthName: false });
                }}
                placeholder="Select a month"
              // validated={validated}
              />
              {formErrors.monthName && (
                <div className="error-message">Please Select monthName .</div>
              )}
            </Col>

            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                label="Class"
                // value={formData.classId || ""}
                value={
                  dropDowns.class
                    ? { value: dropDowns.class, label: dropDowns.class }
                    : null
                }
                placeholder="select class"
                onChange={(selectedOption) => {
                  handleChange("classId", selectedOption);
                  setSelectedClass(selectedOption.value);
                  setDropDowns({ ...dropDowns, class: selectedOption.label });
                  setFormErrors({ ...formErrors, class: false });
                }}
                options={classesClassSectionData.map((classItem) => ({
                  id: classItem.id,
                  value: classItem.classSections.length > 0
                    ? classItem.classSections.map(section => section.classesclassSection) // Map over classSections array
                    : classItem.classesclassSection, // handle case when classSections is empty
                  label: classItem.name,
                }))}
                required
                feedbackMessage="Please select a valid class"
              />
              {formErrors.class && (
                <div className="error-message">Please Select class.</div>
              )}
            </Col>
            {formData.classId &&
              selectedClassData &&
              selectedClassData.classSections.length > 0 && (
                <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                  <SelectDropdown
                    label="Section"
                    value={
                      dropDowns.sections
                        ? {
                          value: dropDowns.sections,
                          label: dropDowns.sections,
                        }
                        : dropDowns.sections
                    }
                    placeholder="Select section"
                    onChange={(selectedOption) => {
                      handleChange("sectionId", selectedOption);
                      setSelectedSection(selectedOption.value);
                      setDropDowns({
                        ...dropDowns,
                        sections: selectedOption.label,
                      });
                      setFormErrors({ ...formErrors, sections: false });
                    }}
                    options={selectedClassData.classSections.map((section) => ({
                      key: section.id,
                      value: section.classesclassSection,
                      label: section.name,
                    }))}
                    required
                    feedbackMessage="Please select a valid section"
                  />
                  {formErrors.sections && (
                    <div className="error-message">Please Select Section.</div>
                  )}
                </Col>
              )}
            <Row className="search">
              <Col sm={6} xs={12} md={6} lg={4} >
                <button
                  className="class_search" // Apply appropriate styling
                  onClick={handleSearchClick} // Attach the click event handler
                >
                  Search
                </button>
              </Col>
            </Row>
          </Row>
        ) : sectionsData.length > 0 ? (
          // employee login (teacher)
          <Row>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                value={
                  dropDowns.monthName
                    ? { value: dropDowns.monthName, label: dropDowns.monthName }
                    : null
                }
                options={Object.keys(months).map(key => ({
                  id: key,
                  value: months[key],
                  label: months[key],
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, monthName: selectedOption.value });
                  setDropDowns({ ...dropDowns, monthName: selectedOption.value });
                  setFormErrors({ ...formErrors, monthName: false });
                }}
                placeholder="Select a month"
              // validated={validated}
              />
              {formErrors.monthName && (
                <div className="error-message">Please Select monthName .</div>
              )}
            </Col>

            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                label="Class"
                value={
                  dropDowns.class
                    ? { value: dropDowns.class, label: dropDowns.class }
                    : null
                }
                placeholder="select class"
                onChange={(selectedOption) => {
                  handleChange("classId", selectedOption);
                  setSelectedClass(selectedOption.value);
                  setDropDowns({ ...dropDowns, class: selectedOption.label });
                  setFormErrors({ ...formErrors, class: false });
                }}
                options={classData ? classData.map((classItem) => ({
                  value: classItem.id,
                  label: classItem.name,
                  // classesClassSectionDataId: classItem.classSections
                })) : []}
                required
                feedbackMessage="Please select a valid class"
              />
              {formErrors.class && (
                <div className="error-message">Please Select class.</div>
              )}
            </Col>
            {formData.classId &&
              selectedClassData &&
              selectedClassData.classSections.length > 0 && (
                <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                  <SelectDropdown
                    label="Section"
                    // value={formData.sectionId || ""}
                    value={
                      dropDowns.sections
                        ? {
                          value: dropDowns.sections,
                          label: dropDowns.sections,
                        }
                        : dropDowns.sections
                    }
                    placeholder="Select section"
                    onChange={(selectedOption) => {
                      handleChange("sectionId", selectedOption);
                      setSelectedSection(selectedOption.value);
                      setDropDowns({
                        ...dropDowns,
                        sections: selectedOption.label,
                      });
                      setFormErrors({ ...formErrors, sections: false });
                    }}
                    options={selectedClassData.classSections.map((section) => ({
                      key: section.id,
                      value: section.classesclassSection,
                      label: section.name,
                    }))}
                    required
                    feedbackMessage="Please select a valid section"
                  />
                  {formErrors.sections && (
                    <div className="error-message">Please Select Section.</div>
                  )}
                </Col>
              )}
            <Row className="search">
              <Col sm={6} xs={12} md={6} lg={4} >
                <button
                  className="class_search" // Apply appropriate styling
                  onClick={handleSearchClick} // Attach the click event handler
                >
                  Search
                </button>
              </Col>
            </Row>
          </Row>
        ) : (
          // franchisee login
          <Row>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                value={
                  dropDowns.monthName
                    ? { value: dropDowns.monthName, label: dropDowns.monthName }
                    : null
                }
                options={Object.keys(months).map(key => ({
                  id: key,
                  value: months[key],
                  label: months[key],
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, monthName: selectedOption.value });
                  setDropDowns({ ...dropDowns, monthName: selectedOption.value });
                  setFormErrors({ ...formErrors, monthName: false });
                }}
                placeholder="Select a month"
              />
              {formErrors.monthName && (
                <div className="error-message">Please Select monthName .</div>
              )}
            </Col>
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                label="Class"
                value={
                  dropDowns.class
                    ? { value: dropDowns.class, label: dropDowns.class }
                    : null
                }
                placeholder="select class"
                onChange={(selectedOption) => {
                  handleChange("classId", selectedOption);
                  setSelectedClass(selectedOption.value);
                  setDropDowns({ ...dropDowns, class: selectedOption.label });
                  setFormErrors({ ...formErrors, class: false });
                }}
                options={classesClassSectionData.map((classItem) => ({
                  id: classItem.id,
                  value: classItem.classSections.length > 0
                    ? classItem.classSections.map(section => section.classesclassSection)
                    : classItem.classesclassSection,
                  label: classItem.name,
                }))}
                required
                feedbackMessage="Please select a valid class"
              />
              {formErrors.class && (
                <div className="error-message">Please Select class.</div>
              )}
            </Col>

            {formData.classId &&
              selectedClassData &&
              selectedClassData.classSections.length > 0 && (
                <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                  <SelectDropdown
                    label="Section"
                    value={
                      dropDowns.sections
                        ? {
                          value: dropDowns.sections,
                          label: dropDowns.sections,
                        }
                        : dropDowns.sections
                    }
                    placeholder="Select section"
                    onChange={(selectedOption) => {
                      handleChange("sectionId", selectedOption);
                      setSelectedSection(selectedOption.value);
                      setDropDowns({
                        ...dropDowns,
                        sections: selectedOption.label,
                      });
                      setFormErrors({ ...formErrors, sections: false });
                    }}
                    options={selectedClassData.classSections.map((section) => ({
                      key: section.id,
                      value: section.classesclassSection,
                      label: section.name,
                    }))}
                    required
                    feedbackMessage="Please select a valid section"
                  />
                  {formErrors.sections && (
                    <div className="error-message">Please Select Section.</div>
                  )}
                </Col>
              )}
            <Row className="search">
              <Col sm={6} xs={12} md={6} lg={4} >
                <button
                  className="class_search"
                  onClick={handleSearchClick}
                >
                  Search
                </button>
              </Col>
            </Row>
          </Row>)}
      </>

      {viewClassTimetableData === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : searchPerformed && viewClassTimetableData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : showTable ? ( // Only render the table if showTable is true
        <Table
          data={formatTimetableData()}
          columns={columns}
          hover={true}
          striped={true}
        />
      ) : null}
    </>
  );
}
export default ViewTimeTable;
