import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { franchiseeSchoolId, token } from "../../../Api/token";
import { responseFormat } from "../../Auth/errorResponse";

const slice = createSlice({
  name: "updateParent",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
    successMessage: "",
    showSuccessMessage: false,
    errorMessage: "",
    showErrorMessage: false,
  },
  reducers: {
    fetchUpdateParentStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchUpdateParentSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchUpdateParentFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
  },
  setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
  },
  setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
  },
  setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
  },
  },
});

export const {
    fetchUpdateParentStart,
    fetchUpdateParentSuccess,
    fetchUpdateParentFailure,
  setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage
} = slice.actions;

export const 
fetchUpdateParentDataOnce =
  (id, updatedData) => async (dispatch, getState) => {
    const { data, isLoading } = getState().updateParent;

   const tokenId = token();
   
      dispatch(fetchUpdateParentStart());

      try {
              // Make your API request here using the fetch function
        const response = await fetch(API_PATH + `/guardian/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenId}`,
          },
          body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
          throw new Error("Request failed"); // You can customize error handling
        }

        const data1 = await response.json();
        dispatch(fetchUpdateParentSuccess(data1));
        dispatch(setSuccessMessage("Successfully Updated!"));
        dispatch(setShowSuccessMessage(true));
      } catch (error) {
        // Handle errors and dispatch the failure action
        dispatch(fetchUpdateParentFailure());
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
      }
    }
  

export default slice.reducer;
