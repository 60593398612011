// author:venktesh;
// date:27/10/2023;
// version:1.0

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardDeck, Container, Form, Button, Row, Col } from 'react-bootstrap';
import './AddComplaintsSuggestions.css'; // Import your custom CSS file
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
// import { addStudentPostData } from './addStudentSlicer';
import DescriptionField from '../../ReusableFields/DescriptionFields/DescriptionField';
import { validationRegexAlphaNumeric, validationRegexOnlyPincode, validationRegexOnlyAlpha } from "../reUsableForm/regex";
import DatePickerPast from '../reUsableForm/DatePicketPast_present';
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import Select from 'react-select';
import ComplaintsSuggestionsTable from './ViewComplaintsSuggestions';
import { ComplaintsSuggestionsPost, setShowSuccessMessage, setShowErrorMessage } from './slice/ComplaintsSuggestionsPostSlice';
import TextInputField from '../../components/reUsableForm/textInputField';
import { franchiseeSchoolId } from '../../Api/token';
import { getParentLoginData } from './parentLoginSlicer/parentLoginRequests';

const AddComplaintsSuggestions = () => {
    const [formData, setFormData] = useState({ type: null, description: "" });
    const [validated, setValidated] = useState(false);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [formErrors, setFormErrors] = useState({
        type: false,
        description: false
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const options = [
        { value: 'complaint', label: 'Complaints' },
        { value: 'suggestion', label: 'Suggestions' },
    ];

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`
    }

    const formatDate1 = (date) => {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `Date : ${day}-${month}-${year}`
    };
    const selectDate = formatDate(selectedDate)


    const dispatch = useDispatch();

    const showSuccessMsg = useSelector((state) => state.ComplaintsSuggestions.showSuccessMessage);
    const successMsg = useSelector((state) => state.ComplaintsSuggestions.successMessage);

    const showErrorMsg = useSelector((state) => state.ComplaintsSuggestions.showErrorMessage);
    const errorMsg = useSelector((state) => state.ComplaintsSuggestions.errorMessage);

    const parentId = sessionStorage.getItem("userId");


    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
    const data = parentLoginObject?.data;

    const FranchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId")

    useEffect(() => {
        if (parentLoginObject?.loading === "idle") {
            const parentId = sessionStorage.getItem('userId');
            dispatch(getParentLoginData(parentId))
        }
    }, [dispatch, parentLoginObject?.loading])



    const handleSubmit = async (e) => {
        e.preventDefault();
        const newFormErrors = {
            type: !formData.type,
            description: !formData.description,
        };

        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            return;
        }

        setSelectedDate(new Date());

        const data = {
            ...formData,
            type: formData.type ? formData.type.value : "",
            date: formatDate(selectedDate),
            guardianId: parentId,
            franchiseeSchoolId: FranchiseeSchoolId
        };
        dispatch(ComplaintsSuggestionsPost(data));
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    const handleCancel = () => {
        setFormData({
            date: selectDate,
            type: null,
            description: "",

        });
    }




    return (

        <div >

            <Container>
                <Row>
                    {/* <Col> */}
                    <Form noValidate validated={validated} onSubmit={handleSubmit} >
                        <span className="content-header">Add Complaints / Suggestions</span>

                        <Row className='mt-4' style={{ display: "flex", justifyContent: "center" }}>
                            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                <TextInputField
                                    value={formatDate1(selectedDate)}
                                    disabled
                                />
                            </Col>

                            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                <SearchableDropDownField
                                    isSearchable
                                    value={formData.type ? { value: formData.type.value, label: formData.type.label } : null}
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, type: selectedOption });
                                        setFormErrors({ ...formErrors, type: !selectedOption });
                                    }}
                                    options={options}
                                    placeholder="Select Type"
                                />
                                {formErrors.type && (
                                    <div className="error-message">
                                        Please Select Type.
                                    </div>
                                )}

                            </Col>

                            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                {/* <Col className='mt-3'> */}
                                <DescriptionField
                                    name="description"
                                    placeholder="Description"
                                    textType="Alphabet"
                                    maxLength={255}
                                    required
                                    value={formData.description}
                                    onChange={(event) => setFormData({ ...formData, description: (event.target.value) })}
                                    validated={validated}
                                    feedbackMessage="Please enter description."
                                    validationRegex={validationRegexOnlyAlpha}
                                    validationErrorMessage="Please enter a description."
                                />
                            </Col>

                        </Row>
                        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "1%",
                            }}
                        >
                            <button
                                className="clear-button-figma-div"
                                type="button"
                                onClick={handleCancel}
                            >
                                Clear
                            </button>
                            <button className="save-button-figma-div" type="submit">
                                Submit
                            </button>
                        </div>
                    </Form>
                    {/* ... Displaying school kids in cards */}
                    {/* </Col> */}
                </Row>
            </Container>
            <div className='mt-4'>
                <ComplaintsSuggestionsTable />
            </div>
        </div>

    );
};

export default AddComplaintsSuggestions;
