import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from "../../Auth/dependency_post";
import { responseFormat } from "../../Auth/errorResponse";

const slice = createSlice(
    {
        name: "state",
        initialState: {
            data: null,
            isLoading:false,
            hasError:false,
        },
        reducers: {
            fetchAddStateData: (state) => {
                state.isLoading = true
                state.hasError = false;
            },
            fetchAddStateSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchAddStateFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            fetchStatePostData:(state,action)=>{
                state.isLoading = false;
                state.data = action.payload;
              }
        },
    },
);

export const { fetchAddStateData, fetchAddStateSuccess, fetchAddStateFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage, fetchStatePostData } = slice.actions;



        export const postAddStateRequest = (postData) => async (dispatch) => {
            dispatch(fetchAddStateData());
            const tokenId = token();
            try {
                const response = await fetchWithAuthPost(API_PATH + "/state", "POST", tokenId, postData);
        
                if (!response) {
                    const errorData = await response;
                    throw new Error(errorData.errors);
                } else {
                    const responseData = await response;
                    dispatch(fetchAddStateSuccess([responseData.data]));
                    dispatch(setSuccessMessage("Successful!"));
                    dispatch(setShowSuccessMessage(true));
                }
            } catch (error) {
                const errMessage = responseFormat({ error });
                console.error("....error", errMessage);
                dispatch(setErrorMessage(errMessage));
                dispatch(setShowErrorMessage(true));
            }
        };
export default slice.reducer;