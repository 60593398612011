import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SearchableDropDownField from "../../../ReusableFields/DropDown/SearchableDropDownField";
import { schoolCode, validationRegexAddress, validationRegexAlphaNumeric, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyPincode, validationRegexOnlyTextarea } from "../../reUsableForm/regex";
import TextInputField from "../../reUsableForm/textInputField";
import { useDispatch, useSelector } from "react-redux";
import { getAddSchoolData } from "../slice/schoolCreationslice";
import { currentPage } from "../slice/currentPageSlice";
import SearchableDropDown from "../../../ReusableFields/DropDown/searchableDroDown";
import { fetchAreaData } from "../../EnquiryDetails/slice/areaSlicer";
import { FetchFranchiseOwners } from "../slice/franchiseOwnersGet";




export const AddSchool = () => {
    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const [franchiseHolderId, setFranchiseHolderId] = useState('');
    const [previousData, setPreviousData] = useState('');
    const [addressInfo, setAddressInfo] = useState({});

    const [formErrors, setFormErrors] = useState({
        franchiseeOwnerId: false, areaId: false
    })

    const dispatch = useDispatch();

    const franchiseHolder = useSelector((state) => state.franchiseOwnerDetails.data) || [];

    const FranchiseDetails = franchiseHolder.find((data) => data.id === franchiseHolderId) || [];

    const areaDetails = useSelector((state) => state.areaDetails.data) || [];

    const selectArea = areaDetails.find((data) => data.id === addressInfo.areaId) || [];


    const Franchise_Details = franchiseHolder.find((data) => data.id === previousData.franchiseeOwnerId) || {};

    const previousAreaDetails = areaDetails.find((data) => data.id === previousData?.address?.areaId) || {}


    useEffect(() => {
        dispatch(FetchFranchiseOwners())
    }, [])


    useEffect(() => {
        if (addressInfo.areaId) {
            setAddressInfo({ ...addressInfo, cityId: selectArea?.city?.id, stateId: selectArea?.city?.state?.id })
        }
    }, [addressInfo.areaId])




    const addSchoolPreviousData = useSelector((state) => state.addSchoolData.data);

    useEffect(() => {
        if (addSchoolPreviousData) {
            setPreviousData(addSchoolPreviousData)
        }

    }, [addSchoolPreviousData]);


    useEffect(() => {
        dispatch(fetchAreaData());
    }, [dispatch]);


    const handleSubmit = (event) => {
        event.preventDefault();

        if (!addSchoolPreviousData) {

            const newFormErrors = {
                franchiseeOwnerId: !formData.franchiseeOwnerId,
                areaId: !addressInfo.areaId
            }

            const hasErrors = Object.values(newFormErrors).some(error => error);
            setFormErrors(newFormErrors);
            if (hasErrors) {
                setValidated(true);
                return;
            }
        }


        const sendData = {
            ...formData,
            "address": addressInfo
        }

        if (addSchoolPreviousData) {
            dispatch(getAddSchoolData(previousData))
            dispatch(currentPage('1'))
        } else {
            dispatch(getAddSchoolData(sendData))
            dispatch(currentPage('1'))

        }

    }

    const handleCancel = () => {
        setFormData({})
        setValidated(false);
    }

    const handleCancel1 = () => {
        setPreviousData({})
        setValidated(false);
    }



    return (
        <>
            {addSchoolPreviousData ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col >
                            <SearchableDropDown sm={12} xs={12} md={6} lg={3} className="rowCss"
                                value={Franchise_Details.name}
                                onChange={(selectedOption) => {
                                    setPreviousData({ ...previousData, franchiseeOwnerId: selectedOption.id })
                                    setFranchiseHolderId(selectedOption.id)
                                }
                                }
                                placeholder="Franchise Holder Name"
                                options={franchiseHolder.map((option, index) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage="Please select a Franchise Holder."
                                validated={validated}

                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Mobile Number"
                                placeholder="Mobile Number"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={Franchise_Details.primaryContactNumber}
                                disabled
                                feedbackMessage="Please enter Mobile Number."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Mobile Number."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Email"
                                placeholder="Email"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={Franchise_Details.email}
                                disabled
                                feedbackMessage="Please enter first name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Email."
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="School Code"
                                placeholder="School Code"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData.code}
                                onChange={(event) => setPreviousData({ ...previousData, code: (event.target.value) })}
                                feedbackMessage="Please enter School Code."
                                validationRegex={schoolCode}
                                validationErrorMessage="Please enter valid School Code."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="School Name"
                                placeholder="School Name"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData.name}
                                onChange={(event) => setPreviousData({ ...previousData, name: (event.target.value) })}
                                feedbackMessage="Please enter School Name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid School Name."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Mobile No"
                                placeholder="Mobile No"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData.primaryContactNumber}
                                onChange={(event) => setPreviousData({ ...previousData, primaryContactNumber: (event.target.value) })}
                                feedbackMessage="Please enter Mobile No."
                                validationRegex={validationRegexMobileNo}
                                validationErrorMessage="Please enter valid Mobile No."
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Alternate Mobile No"
                                placeholder="Alternate Mobile No"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData.secondaryContactNumber}
                                onChange={(event) => setPreviousData({ ...previousData, secondaryContactNumber: (event.target.value) })}
                                feedbackMessage="Please enter Alternate Mobile No."
                                validationRegex={validationRegexMobileNo}
                                validationErrorMessage="Please enter valid Alternate Mobile No."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Address"
                                placeholder="Addresssss"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData?.address?.address}
                                onChange={(event) => setPreviousData({ ...previousData, address: { ...previousData.address, address: event.target.value } })}
                                feedbackMessage="Please enter Address."
                                validationRegex={validationRegexAddress}
                                validationErrorMessage="Please enter valid Address."
                            />
                        </Col>

                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <SearchableDropDown
                                value={previousAreaDetails.name}
                                className="input-style"
                                onChange={(selectedOption) => {
                                    setPreviousData({ ...previousData, address: { ...previousData.address, areaId: selectedOption.id } })
                                }}

                                placeholder="Area"
                                options={areaDetails.map((option, index) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage="Please select a class."
                                validated={validated}

                            />

                        </Col>

                        <Col></Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="City"
                                placeholder="City"
                                textType="Alphabet"
                                // required
                                disabled
                                // validated={validated}
                                value={previousAreaDetails?.city?.name}
                                // onChange={(event) => setPreviousData({ ...previousData, city: (event.target.value) })}
                                feedbackMessage="Please enter City."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid City."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="State"
                                placeholder="State"
                                textType="Alphabet"
                                // required
                                // validated={validated}
                                disabled
                                value={previousAreaDetails?.city?.state?.name}
                                // onChange={(event) => setPreviousData({ ...previousData, state: (event.target.value) })}
                                feedbackMessage="Please enter State."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid State."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Pin Code"
                                placeholder="Pin Code"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={previousData?.address?.pincode}
                                onChange={(event) => setPreviousData({ ...previousData, address: { ...previousData.address, pincode: (event.target.value) } })}
                                feedbackMessage="Please enter Pin Code."
                                validationRegex={validationRegexOnlyPincode}
                                validationErrorMessage="Please enter valid Pin Code."
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "1%",
                            marginTop: "5%"
                        }}
                    >
                        <button
                            className="clear-button-figma-div"
                            type="button"
                            onClick={handleCancel1}
                        >
                            Clear
                        </button>
                        <button className="save-button-figma-div" type="submit" >
                            Save & Next
                        </button>
                    </div>
                </Form>
            ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row >
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <SearchableDropDown
                                value={FranchiseDetails.name}
                                // className="input-style"
                                onChange={(selectedOption) => {
                                    setFormData({ ...formData, franchiseeOwnerId: selectedOption.id })
                                    setFranchiseHolderId(selectedOption.id)
                                    setFormErrors({ ...formErrors, franchiseeOwnerId: false });
                                }
                                }
                                placeholder="Franchise Holder Name"
                                options={franchiseHolder.map((option, index) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage="Please select a franchise holder name."
                                validated={validated}

                            />
                            {formErrors.franchiseeOwnerId && (
                                <div className="error-message">
                                    Please select a class .
                                </div>
                            )}
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Mobile Number"
                                placeholder="Mobile Number"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={FranchiseDetails.primaryContactNumber}
                                disabled
                                feedbackMessage="Please enter Mobile Number."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Mobile Number."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Email"
                                placeholder="Email"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={FranchiseDetails.email}
                                disabled
                                feedbackMessage="Please enter first name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Email."
                            />
                        </Col>
                        <Col></Col>

                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="School Code"
                                placeholder="School Code"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={formData.code}
                                onChange={(event) => setFormData({ ...formData, code: (event.target.value) })}
                                feedbackMessage="Please enter School Code."
                                validationRegex={schoolCode}
                                validationErrorMessage="Please enter valid School Code."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="School Name"
                                placeholder="School Name"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={formData.name}
                                onChange={(event) => setFormData({ ...formData, name: (event.target.value) })}
                                feedbackMessage="Please enter School Name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid School Name."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Mobile No"
                                placeholder="Mobile No"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={formData.primaryContactNumber}
                                onChange={(event) => setFormData({ ...formData, primaryContactNumber: (event.target.value) })}
                                feedbackMessage="Please enter Mobile No."
                                validationRegex={validationRegexMobileNo}
                                validationErrorMessage="Please enter valid Mobile No."
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Alternate Mobile No"
                                placeholder="Alternate Mobile No"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={formData.secondaryContactNumber}
                                onChange={(event) => setFormData({ ...formData, secondaryContactNumber: (event.target.value) })}
                                feedbackMessage="Please enter Alternate Mobile No."
                                validationRegex={validationRegexMobileNo}
                                validationErrorMessage="Please enter valid Alternate Mobile No."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Address"
                                placeholder="Address"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={addressInfo.address || ''}
                                onChange={(event) => setAddressInfo({ ...addressInfo, address: (event.target.value) })}
                                feedbackMessage="Please enter Address."
                                validationRegex={validationRegexAddress}
                                validationErrorMessage="Please enter valid Address."
                            />
                        </Col>

                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <SearchableDropDown
                                value={selectArea.name}
                                className="input-style"
                                onChange={(selectedOption) => {
                                    setAddressInfo({ ...addressInfo, "areaId": (selectedOption.id) });
                                    setFormErrors({ ...formErrors, areaId: false });
                                }}

                                placeholder="Area"
                                options={areaDetails.map((option, index) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage="Please select a class."
                                validated={validated}

                            />
                            {formErrors.areaId && (
                                <div className="error-message">
                                    Please Select a Area .
                                </div>
                            )}
                        </Col>

                        <Col></Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="City"
                                placeholder="City"
                                textType="Alphabet"
                                disabled
                                value={selectArea?.city?.name}
                                feedbackMessage="Please enter City."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid City."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss" >
                            <TextInputField
                                name="State"
                                placeholder="State"
                                textType="Alphabet"
                                disabled
                                value={selectArea?.city?.state?.name}
                                feedbackMessage="Please enter State."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid State."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="Pin Code"
                                placeholder="Pin Code"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={addressInfo.pincode}
                                onChange={(event) => setAddressInfo({ ...addressInfo, pincode: (event.target.value) })}
                                feedbackMessage="Please enter Pin Code."
                                validationRegex={validationRegexOnlyPincode}
                                validationErrorMessage="Please enter valid Pin Code."
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            className="clear-button-figma-div"
                            type="button"
                            onClick={handleCancel}
                        >
                            Clear
                        </button>
                        <button className="save-button-figma-div" type="submit" style={{ width: '10rem' }} >
                            Save & Next
                        </button>
                    </div>
                </Form>
            )}

        </>
    )
}
