import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { token } from '../../../Api/token';
import { fetchWithAuthorization } from '../../Auth/dependency-get';
import { API_PATH } from '../../../Api/api';


const slice = createSlice(
    {
        name: 'transportationFees',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            }
        },
    },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = slice.actions;

export const fetchTransportationFees = () => async (dispatch, getState) => {

    const { data, isLoading } = getState().transportationFees;

    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart());
        try {
            const response = await fetch(API_PATH + '/transportationFees', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data = await response.json();

            dispatch(fetchDataSuccess(data));
 
            
        } catch (error) {

            dispatch(fetchDataFailure())
        }
    }

}

export default slice.reducer;
