/*
    authors: Naveen
    date: 13-01-2024
    update:13-01-2024 
    version: 1.0
*/

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchFoodUpdatesData } from "./slice/getFoodUpdatesData";
import Table from "../reUsableForm/table/table"
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchFoodItems } from "./slice/getFoodItems";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";

export const ViewFoodUpdates = () => {

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "studentName", header: "Student Name" },
        { field: "time", header: "Time" },
        { field: "foodItem", header: "Food Item" },
        { field: "mealType", header: "Meal Type" },
        { field: "quantity", header: "Quantity" },
        { field: "remark", header: "Remark" },
    ]

    const dispatch = useDispatch()

    const roleId = sessionStorage.getItem('roleId')


    const apiData = useSelector((state) => state.FoodUpdatedData.data) || [];
    // const student = useSelector((state) => state.viewStudent.data) || [];
    const foodItems = useSelector((state) => state.foodItems.data) || [];

    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);

    const data = parentLoginObject?.data;



    useEffect(() => {

        if (roleId == 5) {
            if (parentLoginObject?.loading === "idle") {
                const parentId = sessionStorage.getItem('userId');
                dispatch(getParentLoginData(parentId))
            }
        }

    }, [dispatch, parentLoginObject])

    const studentIds = data && data?.childrens?.map(id => id.id)

    useEffect(() => {
        if (studentIds?.length > 0) {
            dispatch(FetchFoodUpdatesData(studentIds))
        } else {
            dispatch(FetchFoodUpdatesData())
        }


    }, [dispatch, studentIds])

    useEffect(() => {
        dispatch(fetchFoodItems())
    }, [dispatch]);

    function convertTo12HourFormat(time24) {
        const [hour, minute] = time24.split(":");
        let period = "AM";
        let hour12 = parseInt(hour, 10);

        if (hour12 >= 12) {
            period = "PM";
            if (hour12 > 12) {
                hour12 -= 12;
            }
        }

        return `${hour12.toString().padStart(2, "0")}:${minute} ${period}`;
    }


    let newModifiedData = []
    apiData.forEach((entry, index) => {
        let studentId = entry.studentId;
        let foodItemId = entry.foodItemId
        let studentName = entry.student.name
        let matchingFoodItem = foodItems.find(itemId => itemId.id === foodItemId)

        if (matchingFoodItem) {
            let time12Hour = convertTo12HourFormat(entry.time);
            entry = { ...entry, index: index + 1, time: time12Hour, studentName: studentName, foodItem: matchingFoodItem.itemName };
            newModifiedData.push(entry)
        }
    });


    return (
        <>
            <div className="content-header ">View Food Updates</div>
            {newModifiedData === null ? (
                <center>
                    <p>Loading...</p>
                </center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                    <p>No records available</p>
                </center>
            ) : (
                <Table data={newModifiedData} columns={columns} hover={true} />
            )}

        </>
    )
}
