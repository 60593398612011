import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { fetchProgramPostData } from './addProgramSlice';



const slice = createSlice(
  {
    name: 'getProgramBySchool',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchGetProgramBySchoolData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchGetProgramBySchoolSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchGetProgramBySchoolFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      },
    
    },
  },

);

export const { fetchGetProgramBySchoolData, fetchGetProgramBySchoolSuccess, fetchGetProgramBySchoolFailure, } = slice.actions;


export const fetchDataGetProgramBySchool = (schoolId) => async (dispatch, getState) => {

  const tokenId = token()

  const { data, isLoading } = getState().getProgramBySchool;
  if (data === null && !isLoading) {
    dispatch(fetchGetProgramBySchoolData());

    try {
      const response = await fetch(API_PATH + `/franchisee-school/${schoolId}/programs`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenId}`

        }
      })
      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data1 = await response.json();
      dispatch(fetchProgramPostData([]))
      dispatch(fetchGetProgramBySchoolSuccess(data1));
    } catch (error) {
      dispatch(fetchGetProgramBySchoolFailure());
    }
  }

}

export default slice.reducer;
