import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchAddFrequencyFailure } from "./frequencySlice";

const slice = createSlice({
  name: "dayFrequency",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchFrequencyData: (state, action) => {
      state.isLoading = action.payload;
      state.hasError = false;
    },
    fetchFrequencySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchEventAndHolidayFailure: (state, action) => {
      state.isLoading = false;
      state.hasError = action.payload;
    },
  },
});

export const {
  fetchFrequencyData,
  fetchFrequencySuccess,
  fetchEventAndHolidayFailure,
} = slice.actions;

export const fetchFrequencyDataOnce = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().dayFrequency;
  const tokenId = token();
  // Check if viewFrequency and viewFrequency.data are defined
  if (data === null && !isLoading) {
    dispatch(fetchFrequencyData());

    try {
      const response = await fetch(API_PATH + "/frequencies", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const data1 = await response.json();
      dispatch(fetchFrequencySuccess(data1));
    } catch (error) {
      dispatch(fetchAddFrequencyFailure());
    }
  }
};

export default slice.reducer;
