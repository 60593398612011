import React, { useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentParameterOnce } from "./slicer/viewAssessmentParametSlicer";
import { PostAssessmentRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addAssessmentSlicer";
import { fetchAssessmentDataOnce } from "./slicer/viewAssessmentSlicer";

const AssessmentParameterSkillView = () => {
    const columns = [
        { field: "index", header: "Sr.no" },
        { field: "class", header: "Class" },
        { field: "assessmentSkillName", header: "Skill" },
        { field: "name", header: "Parameter" },

    ];

    const apiData = useSelector((state) => state.viewAssessmentParameter.data) || [];
    const postData = useSelector((state) => state.addAssessmentParameter.data) || [];
    const data = useSelector((state) => state.viewAssessment.data) || [];
    const assessmentData = [...(data || [])]
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAssessmentParameterOnce());
        dispatch(fetchAssessmentDataOnce());
    }, [dispatch])
    let newModifiedData = []
    if (postData && postData.length > 0) {
        postData.forEach((postItem) => {
            const modifiedData = postItem.assessmentsParameter;
            const findObject = assessmentData.find((obj) => obj.id == postItem.assessmentsParameter.assessmentSkillId);
            const newData = {
                ...modifiedData,
                assessmentSkill: findObject,
                assessmentSkillName: findObject?.name,
                class: findObject?.class?.name
            };
            newModifiedData.push(newData);
        });
    }
    const combinedData = [...(apiData || []), ...(newModifiedData)];
    const processedData = combinedData.map((row, index) => ({
        ...row,
        index: index + 1,
        assessmentSkillName: row.assessmentSkill ? row.assessmentSkill.name : "",
        class: row.assessmentSkill?.class ? row.assessmentSkill?.class?.name : "",
    }));

    return (
        <>
            {/* <div className="content-header">View Area</div> */}
            {processedData === null ? (
                <center><p>Loading...</p></center>
            ) : processedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) :
                (
                    <Table data={processedData} columns={columns} hover={true} striped={true} />
                )
            }
        </>
    );
}
export default AssessmentParameterSkillView;
