import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewEventsAndHolidaysSlice";
import { fetchSchoolEventAndHoliday } from "./slice/viewSchoolEventsAndHolidaysSlice";

function ViewEventsAndHolidays() {
  const columns = [
    { field: "serialNumber", header: "Sr.no" },
    { field: "schoolName", header: "School" },
    { field: "name", header: "Name" },
    { field: "type", header: "Type" },
    { field: "fromDate", header: "From Date" },
    { field: "toDate", header: "To Date" },


  ];

  const apiData = useSelector((state) => state.viewEventsAndHolidays.data) || [];
  const postData = useSelector((state) => state.eventAndHolidays.data) || [];
  const schoolData = useSelector((state) => state.viewSchoolData.data) || [];

  const combinedData = [...(apiData || []), ...postData];

  function getSchoolNameById(id) {
    for (let school of schoolData) {
      if (school.id === id) {
        return school.name;
      }
    }
    return "N/A"; // Return null if the school with the given ID is not found
  }

  function formatDate(dateString) {
    if (!dateString) return "N/A";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }


  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchDataOnce());
  }, [dispatch]);



  const dataWithSerialNumbers = combinedData.map((item, index) => ({
    ...item,
    serialNumber: index + 1,
    schoolName: item.franchiseeSchool.name,
    franchiseeSchoolId: getSchoolNameById(item.franchiseeSchoolId),
    fromDate: formatDate(item.fromDate),
    toDate: formatDate(item.toDate),
    type: item.type === 'holiday' ? "Holiday" : "Event",
  }));


  return (
    <>
      <div className="content-header">View Events And Holidays</div>
      {dataWithSerialNumbers === null ? (
        <center><p>Loading...</p></center>
      ) : dataWithSerialNumbers.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (

          <Table data={dataWithSerialNumbers} columns={columns} hover={true} striped={true} />
        )}
    </>
  );
}
export default ViewEventsAndHolidays;