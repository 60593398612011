
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/authentication/login';
import AddStudent from './components/StudentDetails/Student';
import AddParent from './components/parent/parent';
import React, { useEffect } from 'react'
import Sidebar from './components/Sidebar/Sidebar'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AddTeacher from "./components/Teachers/AddTeacher";
import EmployeeView from './components/Teachers/EmployeeView';
import Settings from './components/Settings/Settings';
import Statistics from './components/Statistics/Statistics';
import StaffRoom from './components/StaffRoom/StaffRoom';
import Signout from './components/Logout/Signout';
import CreateSubject from "../src/components/subject/addSubject"
import AddClass from './components/Classes/addClass';
import AddFees from './components/fee/feeDetails';
import { AllocationBusRoute } from './components/Transportation/allocationBusRoute';
import { ViewAllocationBusRoute } from './components/Transportation/viewAllocationBusRoute';
import ViewFeeDetails from "./components/fee/viewfeeDetails";
import ViewSubject from './components/subject/viewSubject';
import ViewClass from "./components/Classes/viewClass";
import AddSchool from './components/school/addschool';
import EventAndHolidays from './components/event_Holidays/eventAndHolidays';
import EmployeeEnquiry from './components/employee/employeeEnquiry';
import EnquiryEntry from './components/EnquiryDetails/enquiryEntry';
import EnquiryView from './components/EnquiryDetails/enquiryView';
import EnquiryFollowUp from './components/EnquiryDetails/entryFollowUp';
import EnquiryFollowUpList from './components/EnquiryDetails/entryFollowUpList';
import FeeQuote from './components/EnquiryDetails/feeQuote';
import FranchiseEnquiry from './components/Franchise/franchiseEnquiry';
import { TransportFeeCollection } from './components/Transportation/TransportFeeCollection';
import { ViewStudent } from './components/StudentDetails/ViewStudent';
import { ViewParent } from './components/parent/ViewParent';
import RoyaltyCollections from './components/Franchise/RoyaltyCollections';
import TimeTable from './components/TimeTableDetails/timeTable';
import Assessment from "./components/Assessment/assessmentSkill";
import ClassAttendance from './components/AttendanceDetails/classAttendance';
import StudentAttendance from './components/AttendanceDetails/studentAttendance';
import AddComplaintsSuggestions from './components/parent/AddComplaintsSuggestions';
import AssignTeacherToClass from './components/AssignTeacherToClass/AssignTeacherToClass';
import { ViewAssignedClasses } from './components/AssignTeacherToClass/ViewAssignedClasses';
import { useDispatch, useSelector } from 'react-redux';
import ViewEventsAndHolidays from './components/event_Holidays/viewEentAndHolidays';
import { TestPost } from './components/Transportation/testPost';
import PrincipalComplaintsSuggestions from './components/parent/PrincipalComplaintsSuggestions';
import ViewEmployeeEnquiry from "./components/employee/viewEmployeeEnquiry";
import AddSection from './components/section/addSection';
import FranchiseRequestAndSuggestions from './components/Franchise/franchiseRequestAndSuggestions';
import FranchiseFollowUp from './components/Franchise/franchiseFollowUp';
import { ViewTransportationFeeCollection } from './components/Transportation/ViewFeeCoolections';
import { FranchiseOwnerCreation } from './components/Franchise/FranchsisOwnerCreation';
import { SchoolCreation } from './components/Franchise/school-creation/schoolCreation';
import ParentLogin from './components/parent/ParentLogin';
import CompetitionAwards from './components/CoCurriculumActivities/CompetitionAwards';
import PicturesUploading from './components/Co-Curriculem_Actions/PicturesUploading';
import { ViewAssessment } from './components/Assessment/viewAssessmentSkill';
import { ViewAttendance } from './components/AttendanceDetails/viewAttendance';
import AddState from "./components/state/state";
import AddCity from "./components/city/addCity";
import ViewCity from './components/city/viewCity';
import EmployeeEnquiryFollowup from "./components/employee/employeeEnquiryFollowup";
import ViewEmployeeEnquiryFollowup from "./components/employee/viewEmployeeEnquiryFollowup"
import CompetetionAwardsView from './components/CoCurriculumActivities/CompetetionAwardsView';
import StudentReport from './components/Records/StudentReport';
import ExpenseEntry from './components/pettyExpenses/ExpenseEntry';
import AddProgram from './components/programs/addProgram';
import EmployeeViewEdit from './components/Teachers/EmployeeViewEdit';
import FoodUpdates from './components/FoodUpdates/FoodUpdates';
import ViewProgram from './components/programs/viewProgram';
import FromToTime from './components/TimeTableDetails/timings';
import { FeeStructure } from './components/Administration/FeeStructure/feeStructure';
import { FeeHead } from './components/Administration/FeeStructure/feeHead';
import { FeeDueDate } from './components/Administration/FeeStructure/feeDueDate';
import RolesAssign from './components/Administration/RolesAssign/RolesAssign';
import { TransportationFee } from './components/Administration/Transportation/transportationFee';
import { AllocationBusRoutes } from './components/Transportation/AllocationBusRoutes';
import FoodItems from './components/FoodUpdates/FoodItems';
import ViewSection from './components/section/viewSection';
import AddArea from './components/Areas/addArea';
import ViewArea from './components/Areas/viewArea';
import CompetetionName from './components/CoCurriculumActivities/CompetetionName';
import EditCompetetion from './components/CoCurriculumActivities/EditCompetetion';
import AssessmentParameters from './components/Assessment/assessmentParameters';
import MonthlyAssessment from './components/Assessment/preSchoolMonthlyAssessment';
import AssessmentReports from './components/Assessment/assessmentReport';
import AddFrequency from './components/Frequency/FrequencyCreate';
import CreateLane from './components/Transportation/CreateLane';
import AddVehicle from './components/Administration/Transportation/AddVehicle';
import AddRoyaltyAmount from './components/Royalty/royaltyAmount';
import ViewRoyaltyAmount from './components/Royalty/viewRoyaltyAmount';
import { ProtectedRoute } from './components/authentication/ProtectedRoute';
import AddVehicleView from './components/Administration/Transportation/addVehicleView';
import FranchiseFollowUpList from './components/Franchise/franchiseFollowUpList';
import CreateLaneView from './components/Transportation/createLaneView';
import ViewTimeTable from './components/TimeTableDetails/viewTimeTable';
import { ViewFoodUpdates } from './components/FoodUpdates/viewFoodUpdates';
import { ViewPicturesUploads } from './components/Co-Curriculem_Actions/ViewPicturesUploads';
import { ViewGallery } from './components/Co-Curriculem_Actions/GalleryView';
import AddQualification from './components/Administration/Qualifications/AddQualification';
import { RoleBasedRoute } from './components/authentication/ParentProtectedRotue';
import AddCompetition from './components/compitations/compitations';
import { PrincipalComplaintsReply } from './components/parent/PrincipalComplaintReply';
import { ObservationReport } from './components/observation/observationReport';
import ViewReportCard from './components/ReportCardDetails/reportCard';
import AddRequestsSuggestions from './components/Request-Suggestions/addRequestAndSuggestions';
import { FranchisorRequestReply } from './components/Request-Suggestions/franchisorReply';
import FranchiseView from './components/Franchise/franchiseView';
import ViewSchool from './components/Franchise/school-creation/viewSchools';
import EditStudent from './components/StudentDetails/editViewStudent';
import StudentFeeCollection from './components/StudentDetails/studentFee';
import ViewStudentFee from './components/StudentDetails/viewStudentFee';
import StudentFeeDueReports from './components/StudentDetails/studentFeeReports';
import EditParent from './components/parent/editParent';
import { FranchiseDetails } from './components/Franchise/FranchsisOwnerDetails';
import DueFee from './components/StudentDetails/dueFee';
import ViewStudentTimeTable from './components/parent/viewStudentTimetable';
import FitnessParameter from './components/FitnessParameter/fitnessParameterView';
import Fitness from './components/FitnessParameter/fitnessParameter';
import ViewSchoolEventsAndHolidays from './components/event_Holidays/viewSchoolEventsAndHolidays';
import FitnessAnalysis from './components/FitnessParameter/fitnessAnalysis';
import { ObservationReports } from './components/Assessment/observationReport';
import ClassActivityUpdates from './components/ClassActivityUpdates/classActivtyUpdate';
import ParentTeacherMeeting from './components/Parent Teacher Meeting/parentTeacherMeeting';
import ViewParentTeacherMeeting from './components/Parent Teacher Meeting/viewParentTeacherMeeting';
import UpdatePassword from './components/UpdatePassword/updatePassword';
import ViewActivityUpdate from './components/ClassActivityUpdates/viewActivityUpdate';
import PtmDates from './components/Parent Teacher Meeting/ptmDates';
import PtmDatesView from './components/Parent Teacher Meeting/ptmDatesView';
import ViewMessages from './components/MessagingCenter/ViewMessages';
import SendMessage from './components/MessagingCenter/SendMessage';
import ParentLoginPath from './components/authentication/parentLoginPath';
import RevenueSheet from './components/Revenue/revenueSheet';
import RevenueSheetView from './components/Revenue/revenueSheetView';
import PettyExpenses from './components/pettyExpenses/pettyExpenses';
import PettyExpensesView from './components/pettyExpenses/pettyExpensesView';
import ExpensesType from './components/pettyExpenses/expensesType';
import RevenueType from './components/Revenue/revenueType';
import ExpensesEntryView from './components/pettyExpenses/expensesEntryView';
import EditAssessmentReport from './components/Assessment/editAssessmentReport';





const App = () => {

  // principle  = 3 , franchisee = 2 ,parents = 5 ,franchisor =1 ,teacher =6 ,accountant =4


  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute> <Sidebar /></ProtectedRoute>,
      children: [
        { path: "addTeacher", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AddTeacher /> </RoleBasedRoute> },
        { path: "employeeView", element: <RoleBasedRoute allowedRoles={[2, 3]}> <EmployeeView /> </RoleBasedRoute> },
        { path: "addStudent", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AddStudent /> </RoleBasedRoute> },
        { path: "timeTable", element: <RoleBasedRoute allowedRoles={[1]}> <TimeTable /> </RoleBasedRoute> },
        { path: "viewTimeTable", element: <RoleBasedRoute allowedRoles={[1, 2, 3, 6, 5]}> <ViewTimeTable /> </RoleBasedRoute> },
        { path: "ViewStudentTimeTable", element: <RoleBasedRoute allowedRoles={[5]}> <ViewStudentTimeTable /> </RoleBasedRoute> },
        ////////Assessment/////////////
        { path: "assessment", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <Assessment /> </RoleBasedRoute> },
        { path: "preschoolAssessmentParameters", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <AssessmentParameters /></RoleBasedRoute> },
        { path: "monthlyAssessment", element: <RoleBasedRoute allowedRoles={[2, 3, 1, 6]}> <MonthlyAssessment /> </RoleBasedRoute> },
        { path: "viewAssessment", element: <RoleBasedRoute allowedRoles={[2, 3, 6, 5]}> <ViewAssessment /> </RoleBasedRoute> },
        { path: "assessmentReports", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <AssessmentReports /> </RoleBasedRoute> },
        { path: "Student", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AddStudent /> </RoleBasedRoute> },
        { path: "viewStudent", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <ViewStudent /> </RoleBasedRoute> },
        { path: "addParent", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AddParent /> </RoleBasedRoute> },
        { path: "guardian/:editId", element: <RoleBasedRoute allowedRoles={[2, 3]}> <EditParent /> </RoleBasedRoute> },
        { path: "viewParent", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <ViewParent /> </RoleBasedRoute> },
        { path: 'parentProfile', element: <RoleBasedRoute allowedRoles={[2, 3, 5]}> <ParentLogin /> </RoleBasedRoute> },
        { path: "studentReport", element: <RoleBasedRoute allowedRoles={[6, 3, 2, 5]}> <StudentReport /> </RoleBasedRoute> },
        { path: "addClassAttendance", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <ClassAttendance /> </RoleBasedRoute> },
        { path: "studentAttendance", element: <RoleBasedRoute allowedRoles={[2, 3, 6, 5]}> <StudentAttendance /> </RoleBasedRoute> },
        { path: "viewClassAttendance", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <ViewAttendance /> </RoleBasedRoute> },
        { path: "addSubject", element: <RoleBasedRoute allowedRoles={[1]}> <CreateSubject /> </RoleBasedRoute> },
        { path: "addClass", element: <RoleBasedRoute allowedRoles={[1]}> <AddClass /> </RoleBasedRoute> },
        { path: "addFees", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AddFees /> </RoleBasedRoute> },
        { path: "allocationBusRoute", element: <RoleBasedRoute allowedRoles={[2, 3]}> <AllocationBusRoute /> </RoleBasedRoute> },
        { path: "viewAllocationBusRoute", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}> <ViewAllocationBusRoute /> </RoleBasedRoute> },
        { path: "viewfeeDetails", element: <RoleBasedRoute allowedRoles={[2, 3]}> <ViewFeeDetails /> </RoleBasedRoute> },
        { path: "ViewSubject", element: <RoleBasedRoute allowedRoles={[1]}> <ViewSubject /> </RoleBasedRoute> },
        { path: "ViewClass", element: <RoleBasedRoute allowedRoles={[1]}><ViewClass /> </RoleBasedRoute> },
        { path: "EventAndHolidays", element: <RoleBasedRoute allowedRoles={[1]}> <EventAndHolidays /></RoleBasedRoute> },
        { path: "EmployeeEnquiry", element: <RoleBasedRoute allowedRoles={[2, 3]}> <EmployeeEnquiry /></RoleBasedRoute> },
        { path: "ViewEmployeeEnquiry", element: <RoleBasedRoute allowedRoles={[2, 3]}> <ViewEmployeeEnquiry /> </RoleBasedRoute> },
        { path: "EnquiryEntry", element: <RoleBasedRoute allowedRoles={[2, 3]}> <EnquiryEntry /> </RoleBasedRoute> },
        { path: "EnquiryView", element: <RoleBasedRoute allowedRoles={[2, 3]}><EnquiryView /></RoleBasedRoute> },
        { path: "EnquiryFollowUp", element: <RoleBasedRoute allowedRoles={[2, 3]}><EnquiryFollowUp /></RoleBasedRoute> },
        { path: "EnquiryFollowUpList", element: <RoleBasedRoute allowedRoles={[2, 3]}><EnquiryFollowUpList /></RoleBasedRoute> },
        // { path: "FeeQuote", element: <FeeQuote /> },
        { path: "FranchiseEnquiry", element: <RoleBasedRoute allowedRoles={[1]}> <FranchiseEnquiry /> </RoleBasedRoute> },
        // { path: "TestPost", element: <TestPost /> },
        { path: "TransportFeeCollection", element: <RoleBasedRoute allowedRoles={[2, 3]}> <TransportFeeCollection /> </RoleBasedRoute> },
        { path: "AddComplaintsSuggestions", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}><AddComplaintsSuggestions /> </RoleBasedRoute> },
        { path: "AssignTeacherToClass", element: <RoleBasedRoute allowedRoles={[2, 3]}><AssignTeacherToClass /></RoleBasedRoute> },
        { path: "ViewAssignedClasses", element: <RoleBasedRoute allowedRoles={[3, 1, 6, 5]}> <ViewAssignedClasses /> </RoleBasedRoute> },
        { path: "ViewEventsAndHolidays", element: <RoleBasedRoute allowedRoles={[1]}> <ViewEventsAndHolidays /></RoleBasedRoute> },
        { path: "ViewTransportationFeeCollection", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <ViewTransportationFeeCollection /> </RoleBasedRoute> },
        { path: "AddSection", element: <RoleBasedRoute allowedRoles={[1]}><AddSection /> </RoleBasedRoute> },
        { path: "FranchiseOwnerCreation", element: <RoleBasedRoute allowedRoles={[1]}><FranchiseOwnerCreation /></RoleBasedRoute> },
        { path: "SchoolCreation", element: <RoleBasedRoute allowedRoles={[1]}><SchoolCreation /></RoleBasedRoute> },
        { path: "competitionAwards", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}><CompetitionAwards /></RoleBasedRoute> },
        { path: "PicturesUploading", element: <RoleBasedRoute allowedRoles={[6, 2, 3]}><PicturesUploading /> </RoleBasedRoute> },
        { path: "PrincipalComplaintsSuggestions", element: <RoleBasedRoute allowedRoles={[2, 3]}> <PrincipalComplaintsSuggestions /></RoleBasedRoute> },
        { path: "FranchiseRequestAndSuggestions", element: <RoleBasedRoute allowedRoles={[1, 2, 3]}> <FranchiseRequestAndSuggestions /> </RoleBasedRoute> },
        { path: "FranchiseFollowUp", element: <RoleBasedRoute allowedRoles={[1]}> <FranchiseFollowUp /> </RoleBasedRoute> },
        { path: "AddState", element: <RoleBasedRoute allowedRoles={[1]}><AddState /></RoleBasedRoute> },
        { path: "AddCity", element: <RoleBasedRoute allowedRoles={[1]}><AddCity /> </RoleBasedRoute> },
        { path: "ViewCity", element: <RoleBasedRoute allowedRoles={[1]}> <ViewCity /> </RoleBasedRoute> },
        { path: "EmployeeEnquiryFollowup", element: <RoleBasedRoute allowedRoles={[2, 3]}><EmployeeEnquiryFollowup /> </RoleBasedRoute> },
        { path: "ViewEmployeeEnquiryFollowup", element: <RoleBasedRoute allowedRoles={[2, 3]}> <ViewEmployeeEnquiryFollowup /> </RoleBasedRoute> },
        { path: "competitionAwardsView", element: <RoleBasedRoute allowedRoles={[1, 2, 3, 6, 5]}><CompetetionAwardsView /> </RoleBasedRoute> },
        { path: "competitionName", element: <RoleBasedRoute allowedRoles={[1]}> <CompetetionName /> </RoleBasedRoute> },
        { path: "editCompetetion/:id", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}><EditCompetetion /> </RoleBasedRoute> },
        { path: "expenseEntry", element: <RoleBasedRoute allowedRoles={[2]}> <ExpenseEntry /> </RoleBasedRoute> },
        { path: "AddProgram", element: <RoleBasedRoute allowedRoles={[1]}> <AddProgram /></RoleBasedRoute> },
        { path: "ViewProgram", element: <RoleBasedRoute allowedRoles={[1]}> <ViewProgram /> </RoleBasedRoute> },
        { path: "editEmployee/:editId", element: <RoleBasedRoute allowedRoles={[2, 3]}><EmployeeViewEdit /> </RoleBasedRoute> },
        { path: "student/:editId", element: <RoleBasedRoute allowedRoles={[2, 3]}><EditStudent /> </RoleBasedRoute> },
        { path: "FoodUpdates", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}><FoodUpdates /></RoleBasedRoute> },
        { path: "FoodItems", element: <RoleBasedRoute allowedRoles={[2, 3]}><FoodItems /> </RoleBasedRoute> },
        { path: "FeeStructure", element: <RoleBasedRoute allowedRoles={[1]}> <FeeStructure /> </RoleBasedRoute> },
        { path: "FeeHead", element: <RoleBasedRoute allowedRoles={[1]}> <FeeHead /> </RoleBasedRoute> },
        { path: "FeeDue", element: <RoleBasedRoute allowedRoles={[1]}> <FeeDueDate /> </RoleBasedRoute> },
        { path: "RolesAssign", element: <RoleBasedRoute allowedRoles={[2, 3]}><RolesAssign /> </RoleBasedRoute> },
        { path: "timings", element: <RoleBasedRoute allowedRoles={[1]}> <FromToTime />  </RoleBasedRoute> },
        ///Transportation Fee ////
        { path: "TransportationFee", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <TransportationFee /> </RoleBasedRoute> },
        { path: "allocationBusRoutes", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}><AllocationBusRoutes /> </RoleBasedRoute> },
        { path: "ViewSection", element: <RoleBasedRoute allowedRoles={[1]}><ViewSection /> </RoleBasedRoute> },
        { path: "AddArea", element: <RoleBasedRoute allowedRoles={[1, 2]}> <AddArea /></RoleBasedRoute> },
        { path: "ViewArea", element: <RoleBasedRoute allowedRoles={[1]}><ViewArea /></RoleBasedRoute> },
        { path: "AddFrequency", element: <RoleBasedRoute allowedRoles={[1]}><AddFrequency /> </RoleBasedRoute> },
        { path: "createLane", element: <RoleBasedRoute allowedRoles={[1]}> <CreateLane /> </RoleBasedRoute> },
        { path: "addVehicle", element: <RoleBasedRoute allowedRoles={[1]}><AddVehicle /> </RoleBasedRoute> },
        { path: "addRoyaltyAmount", element: <RoleBasedRoute allowedRoles={[1]}> <AddRoyaltyAmount /> </RoleBasedRoute> },
        { path: "viewRoyaltyAmount", element: <RoleBasedRoute allowedRoles={[1]}><ViewRoyaltyAmount /></RoleBasedRoute> },
        { path: "addVehicleView", element: <RoleBasedRoute allowedRoles={[1]}><AddVehicleView /></RoleBasedRoute> },
        // { path: "franchiseFollowUpList", element: <FranchiseFollowUpList /> },
        { path: "CreateLaneView", element: <RoleBasedRoute allowedRoles={[1]}><CreateLaneView /> </RoleBasedRoute> },
        { path: "ViewFoodUpdates", element: <RoleBasedRoute allowedRoles={[5, 2, 3, 6]}> <ViewFoodUpdates /></RoleBasedRoute> },
        { path: "ViewPicturesUploads", element: <RoleBasedRoute allowedRoles={[6, 2, 3]}> <ViewPicturesUploads /> </RoleBasedRoute> },
        { path: "ViewGallery", element: <RoleBasedRoute allowedRoles={[5, 2, 3]}> <ViewGallery /> </RoleBasedRoute> },
        { path: 'AddQualification', element: <RoleBasedRoute allowedRoles={[1]}><AddQualification /> </RoleBasedRoute> },
        { path: "addCompetition", element: <RoleBasedRoute allowedRoles={[1]}> <AddCompetition /> </RoleBasedRoute> },

        //Extra Components
        { path: "settings", element: <RoleBasedRoute allowedRoles={[2, 3]}> <Settings /> </RoleBasedRoute> },
        { path: "staffRoom", element: <RoleBasedRoute allowedRoles={[2, 3]}> <StaffRoom /> </RoleBasedRoute> },
        { path: "signout", element: <RoleBasedRoute allowedRoles={[2, 3]}> <Signout /> </RoleBasedRoute> },
        { path: "AddSchool", element: <AddSchool /> },
        { path: "statistics", element: <RoleBasedRoute allowedRoles={[2, 3]}> <Statistics /> </RoleBasedRoute> },
        { path: "royaltyCollections", element: <RoleBasedRoute allowedRoles={[1]}> <RoyaltyCollections /> </RoleBasedRoute> },
        { path: "PrincipalComplaintsReply", element: <RoleBasedRoute allowedRoles={[5]}> <PrincipalComplaintsReply /> </RoleBasedRoute> },
        { path: "ObservationReport", element: <RoleBasedRoute allowedRoles={[5]}> <ObservationReport /> </RoleBasedRoute> },
        { path: "viewReportCard", element: <RoleBasedRoute allowedRoles={[5]}> <ViewReportCard /> </RoleBasedRoute> },
        { path: "addRequestAndSuggestions", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <AddRequestsSuggestions /> </RoleBasedRoute> },
        { path: "franchisorRequestReply", element: <RoleBasedRoute allowedRoles={[2, 3, 1]}> <FranchisorRequestReply /> </RoleBasedRoute> },
        { path: "franchiseFollowUpList", element: <RoleBasedRoute allowedRoles={[1]}> <FranchiseFollowUpList /> </RoleBasedRoute> },
        { path: "franchiseView", element: <RoleBasedRoute allowedRoles={[1]}> <FranchiseView /> </RoleBasedRoute> },
        { path: "schoolView", element: <RoleBasedRoute allowedRoles={[1]}> <ViewSchool /> </RoleBasedRoute> },
        { path: "ObservationReports", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}> <ObservationReports /> </RoleBasedRoute> },

        //student fees
        { path: "studentFeeCollection", element: <RoleBasedRoute allowedRoles={[2, 3]}> <StudentFeeCollection /> </RoleBasedRoute> },
        { path: "viewStudentFee", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}> <ViewStudentFee /> </RoleBasedRoute> },
        { path: "StudentFeeDueReports", element: <RoleBasedRoute allowedRoles={[2, 3]}> <StudentFeeDueReports /> </RoleBasedRoute> },
        { path: "dueFee/:id", element: <RoleBasedRoute allowedRoles={[2, 3]}><DueFee /> </RoleBasedRoute> },
        { path: "FranchisorDetails", element: <FranchiseDetails /> },
        { path: "fitnessParameter", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}><FitnessParameter /> </RoleBasedRoute> },
        { path: "fitness", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}><Fitness /> </RoleBasedRoute> },
        { path: "viewSchoolEventsAndHolidays", element: <RoleBasedRoute allowedRoles={[2, 3, 5, 6]}><ViewSchoolEventsAndHolidays /> </RoleBasedRoute> },
        { path: "fitnessAnalysis", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}><FitnessAnalysis /> </RoleBasedRoute> },
        { path: "classActivityUpdates", element: <RoleBasedRoute allowedRoles={[2, 3, 6]}><ClassActivityUpdates /> </RoleBasedRoute> },
        { path: "ParentTeacherMeeting", element: <RoleBasedRoute allowedRoles={[2, 3]}><ParentTeacherMeeting /> </RoleBasedRoute> },
        { path: "ViewParentTeacherMeeting", element: <RoleBasedRoute allowedRoles={[2, 3, 5]}><ViewParentTeacherMeeting /> </RoleBasedRoute> },
        { path: "UpdatePassword", element: <RoleBasedRoute allowedRoles={[2, 3]}><UpdatePassword /> </RoleBasedRoute> },
        { path: "UpdatePassword", element: <RoleBasedRoute allowedRoles={[2, 3]}><UpdatePassword /> </RoleBasedRoute> },
        { path: "viewClassActivity", element: <RoleBasedRoute allowedRoles={[5]}><ViewActivityUpdate /> </RoleBasedRoute> },
        { path: "ptmDates", element: <RoleBasedRoute allowedRoles={[2]}><PtmDates /> </RoleBasedRoute> },
        { path: "ptmDatesView", element: <RoleBasedRoute allowedRoles={[5]}><PtmDatesView /> </RoleBasedRoute> },
        { path: "viewMessages", element: <RoleBasedRoute allowedRoles={[2, 5]}><ViewMessages /> </RoleBasedRoute> },
        { path: "sendMessage", element: <RoleBasedRoute allowedRoles={[2]}><SendMessage /> </RoleBasedRoute> },
        { path: "revenueSheet", element: <RoleBasedRoute allowedRoles={[1, 2]}><RevenueSheet /> </RoleBasedRoute> },
        { path: "revenueSheetView", element: <RoleBasedRoute allowedRoles={[1, 2]}><RevenueSheetView /> </RoleBasedRoute> },
        { path: "pettyExpenses", element: <RoleBasedRoute allowedRoles={[1, 2]}><PettyExpenses /> </RoleBasedRoute> },
        { path: "pettyExpensesView", element: <RoleBasedRoute allowedRoles={[1, 2]}><PettyExpensesView /> </RoleBasedRoute> },
        { path: "expensesType", element: <RoleBasedRoute allowedRoles={[1]}><ExpensesType /> </RoleBasedRoute> },
        { path: "revenueType", element: <RoleBasedRoute allowedRoles={[1]}><RevenueType /> </RoleBasedRoute> },
        { path: "expensesEntryView", element: <RoleBasedRoute allowedRoles={[1, 2]}><ExpensesEntryView /> </RoleBasedRoute> },
        { path: "editAssessmentReport", element: <RoleBasedRoute allowedRoles={[2]}><EditAssessmentReport /> </RoleBasedRoute> },

      ]
    },
    {
      path: "/admin-login",
      element: <Login />
    },
    {
      path: "/parent-login",
      element: <ParentLoginPath />
    }
  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App