/*
    authors: Mamatha
    date: 23-11-2023
    update:23-11-2023 
    version: 1.0
*/

import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import './RolesAssign.css'
import TextInputField from '../../reUsableForm/textInputField';
import SearchableDropDownField from '../../../ReusableFields/DropDown/SearchableDropDownField';
import { useSelector, useDispatch } from 'react-redux';
import { getEmployeeList } from '../../Teachers/teacherSlicers/allRequests';
import { fetchRolesData } from './slice/rolesSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIconOff from '@mui/icons-material/VisibilityOff';
import { RolesAssignPost, setShowSuccessMessage, setShowErrorMessage } from './slice/RolesAssignPostSlice';
import SuccessMessagePopup from "../../../components/reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../../components/reUsableForm/pop-card/errorMessage";

const RolesAssign = () => {

    // const [selectedDate, setSelectedDate] = useState(new Date());
    const [validated, setValidated] = useState(false)

    const [showPassword, setShowPassword] = useState(false);

    const validationRegex = /^$|^(?=.*[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{1,50}$/;

    const [formErrors, setFormErrors] = useState({
        name: false,
    });
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, "0");
    //     const year = date.getFullYear();
    //     return `${year}-${month}-${day}`
    // }

    // const formatDate1 = (date) => {
    //     if (!date) return '';
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, "0");
    //     const year = date.getFullYear();
    //     return `Date : ${day}-${month}-${year}`
    // };
    // const selectDate = formatDate(selectedDate)
    const [formData, setFormData] = useState({
        profileId: null,
        roleId: null,
        password: '',

    })



    const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState(null);
    const [selectedEmployeePhone, setSelectedEmployeePhone] = useState(null);

    const dispatch = useDispatch();

    const showSuccessMsg = useSelector((state) => state.RolesAssignPostSlice.showSuccessMessage);
    const successMsg = useSelector((state) => state.RolesAssignPostSlice.successMessage);

    const showErrorMsg = useSelector((state) => state.RolesAssignPostSlice.showErrorMessage);
    const errorMsg = useSelector((state) => state.RolesAssignPostSlice.errorMessage);

    const apiEmployeeData = useSelector((state) => state.employeeSlicer.teachers.getEmployeeViewList.data) || [];
    const [passwordEmpty, setPasswordEmpty] = useState(true);


    useEffect(() => {
        if (apiEmployeeData.length === 0) {
            dispatch(getEmployeeList());
        }
    }, [dispatch]);

    const apiRolesData = useSelector((state) => state.rolesDetails.data) || [];

    useEffect(() => {
        if (apiRolesData.length === 0) {
            dispatch(fetchRolesData());
        }
    }, [apiRolesData, dispatch]);

    useEffect(() => {
        if (apiEmployeeData.length > 0) {
            const selectedEmployee = apiEmployeeData.find((emp) => emp.id === formData.profileId?.id);
            setSelectedEmployeePhone(selectedEmployee ? selectedEmployee.primaryContactNumber : null);
        }
    }, [formData.profileId, apiEmployeeData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const newFormErrors = {
            profileId: !formData.profileId,
            roleId: !formData.roleId,
            password: !formData.password,
        };
        // Check if there are any errors
        const hasErrors = Object.values(newFormErrors).some(error => error);

        // Set the formErrors state
        setFormErrors(newFormErrors);

        // If there are errors, return without dispatching the action
        if (hasErrors) {
            setValidated(true);
            return;
        }

        // setSelectedDate(new Date());

        // Extract the id from the cityId option object
        const profileId = formData.profileId?.id;
        const roleId = formData.roleId?.id;
        const email = selectedEmployeeEmail;
        const contactNumber = selectedEmployeePhone;
        // const date = selectedDate;

        // Update the formData directly with cityId as the ID
        formData.profileId = profileId;
        formData.roleId = roleId;
        formData.email = email;
        formData.contactNumber = Number(contactNumber);
        // formData.date = date;

        dispatch(RolesAssignPost(formData));
    }

    const handleCancel = () => {
        setFormData({
            // date: selectDate,
            profileId: null,
            roleId: null,
            password: '',
        });

        setFormErrors({
            profileId: false,
            roleId: false,
            password: false,
        });

        setValidated(false);
        setSelectedEmployeeEmail(null);
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='content-header'>Roles Assign</div>
                <Row className='mt-4'>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>

                        <SearchableDropDownField
                            isSearchable
                            value={formData.profileId ? { id: formData.profileId.id, label: formData.profileId.label } : null}
                            options={(Array.isArray(apiEmployeeData) ? apiEmployeeData : []).map((emp) => ({
                                id: emp.id,
                                label: emp.name,
                                value: emp.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, profileId: selectedOption });
                                setFormErrors({ ...formErrors, profileId: !selectedOption });
                                const selectedEmployee = Array.isArray(apiEmployeeData) ? apiEmployeeData.find((emp) => emp.id === selectedOption.id) : null;
                                setSelectedEmployeeEmail(selectedEmployee ? selectedEmployee.email : null);
                            }}
                            placeholder="Select Employee"
                        />
                        {formErrors.profileId && (
                            <div className="error-message">
                                Please Select Employee.
                            </div>
                        )}

                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <TextInputField
                            value={selectedEmployeeEmail || ''}
                            disabled
                            placeholder="Email"
                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>

                        <SearchableDropDownField
                            isSearchable
                            value={formData.roleId ? { id: formData.roleId.id, label: formData.roleId.label } : null}
                            options={apiRolesData.map((role) => ({ id: role.id, label: role.name, value: role.name }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, roleId: selectedOption });
                                setFormErrors({ ...formErrors, roleId: !selectedOption });
                            }}
                            placeholder="Select Role"
                        />
                        {formErrors.roleId && (
                            <div className="error-message">
                                Please Select Role.
                            </div>
                        )}

                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} style={{ position: 'relative' }} className='rowCss'>
                        <TextInputField
                            placeholder="Password"
                            name="password"
                            textType="Alphabet"
                            value={formData.password}
                            onChange={(event) => {
                                setFormData({ ...formData, "password": event.target.value });
                                setPasswordEmpty(event.target.value === ''); // Update passwordEmpty state
                            }}
                            // type={showPassword ? "text" : "password"}
                            colSize={4}
                            validated={validated}
                            feedbackMessage="Please enter the password."
                            validationRegex={validationRegex}
                            validationErrorMessage="Please enter a valid password."
                            required
                        />
                        <span
                            aria-label="Toggle password visibility"
                            aria-describedby="button-addon2"
                            id="button-addon2"
                            className="password-toggle-icon"
                            onClick={togglePasswordVisibility}
                            style={{
                                position: "absolute",
                                top: "44%",
                                right: "25px", // Adjusted the right positioning
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                                marginTop:validated ? "-10px" : "0px"

                            }}
                        >
                            {showPassword ? <VisibilityIcon style={{margin:"0px"}}  /> : <VisibilityIconOff className="password-icon" />}
                        </span>
                    </Col>

                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button className='clear-button-figma-div' type='button' onClick={handleCancel}>Clear</button>
                    <button className='save-button-figma-div' type='submit'>Submit</button>
                </div>
            </Form>
        </div>
    )
};
export default RolesAssign;