import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import dayjs from "dayjs";
import TextInputField from "../reUsableForm/textInputField";
import {
  validationRegexOnlyAlpha1,
  validationRegexTime,
} from "../reUsableForm/regex";
import TimeInputField from "../reUsableForm/timeInputField";
import { useDispatch, useSelector } from "react-redux";
import {
  postTimingRequest,
  setShowErrorMessage,
  setShowSuccessMessage,
} from "./slicer/addTimingSlicer";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import ViewTimings from "./viewTimings";
import SelectDropdown from "../SelectDropdown";
import { API_PATH } from "../../Api/api";
import { franchiseeSchoolId, token } from "../../Api/token";
import { fetchDataOnce } from "../Classes/slice/viewClassSlice";

const FromToTime = () => {
  const [fromTime, setFromTime] = useState("");
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [toTime, setToTime] = useState("");
  const [formErrors, setFormErrors] = useState({
    fromTime: false,
    toTime: false,
    title: false,
  });
  const [sectionData, setSectionData] = useState();
  const showSuccessMsg = useSelector(
    (state) => state.addTimings.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.addTimings.successMessage);
  const [dropDowns, setDropDowns] = useState({});
  const showErrorMsg = useSelector(
    (state) => state.addTimings.showErrorMessage
  );
  const errorMsg = useSelector((state) => state.addTimings.errorMessage);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const handleTimeChange = (timeType, newValue) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [timeType]: false,
    }));

    if (timeType === "fromTime") {
      setFromTime(newValue);
    } else if (timeType === "toTime") {
      setToTime(newValue);
    }

    const concatenatedTime = `${timeType === "fromTime" ? newValue : fromTime
      }-${timeType === "toTime" ? newValue : toTime}`;

    function convertTo12HourFormat(time24) {
      const time = time24.split(":");
      let hours = parseInt(time[0], 10);
      const minutes = parseInt(time[1], 10);
      const period = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      return `${formattedHours}:${formattedMinutes} ${period}`;
    }
    function separateTimes(timeRange) {
      // Split the time range using the hyphen
      const [startTime, endTime] = timeRange.split("-");

      // Convert the start and end times to 12-hour format
      const formattedStartTime = startTime
        ? convertTo12HourFormat(startTime.trim())
        : "";
      const formattedEndTime = endTime
        ? convertTo12HourFormat(endTime.trim())
        : "";

      return formattedStartTime + " " + "to" + " " + formattedEndTime;
    }

    const separatedTimes = separateTimes(concatenatedTime);
    setFormData((prevData) => ({
      ...prevData,
      title: separatedTimes,
    }));
  };

  const calculateTimeDifference = () => {
    if (fromTime && toTime) {
      const differenceInHours = Math.abs(
        dayjs(toTime, "HH:mm").diff(dayjs(fromTime, "HH:mm"), "hour")
      );
    }
  };
  const dispatch = useDispatch();
  const classData = useSelector((state) => state.viewClass.data) || [];
  useEffect(() => {
    if (classData.length === 0) {
      dispatch(fetchDataOnce());
    }
  }, [classData, dispatch]);
  const tokenId = token();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_PATH + `/class/${selectedClass}/sections`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setSectionData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call fetchData function when component mounts or when dependencies change

    // Clean-up function (optional)
    return () => {
      // Perform any clean-up (if necessary)
    };
  }, [selectedClass]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const isFromTimeValid = fromTime.trim() !== "";
    const isToTimeValid = toTime.trim() !== "";
    const istitleValid = formData.title && formData.title.trim() !== "";

    setFormErrors({
      fromTime: !isFromTimeValid,
      toTime: !isToTimeValid,
      title: !istitleValid,
    });

    if (isFromTimeValid && isToTimeValid && istitleValid) {
      calculateTimeDifference();

      const hasErrors = Object.values(formErrors).some((error) => error);
      setValidated(hasErrors);

      if (hasErrors) {
        return;
      }
    }
    const sendData = {
      fromTime: fromTime,
      toTime: toTime,
      title: formData.title,

    };

    if (sendData.fromTime && sendData.toTime && sendData.title) {
      dispatch(postTimingRequest(sendData));
    }
  };

  const handleClear = () => {
    setFromTime("");
    setToTime("");
    setFormErrors({
      fromTime: false,
      toTime: false,
      title: false,
    });
    setFormData({});
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <div>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Timings</div>
            <Row style={{ justifyContent: "center", marginTop: "1%" }}>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <Form.Label
                  className="labelName"
                  style={{ marginRight: "20px" }}
                >
                  From Time:
                </Form.Label>
                <Form.Control
                  type="time"
                  value={fromTime}
                  onChange={(e) => handleTimeChange("fromTime", e.target.value)}
                  isInvalid={formErrors.fromTime}
                />
                {formErrors.fromTime && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid time for From Time.
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <Form.Label
                  className="labelName"
                  style={{ marginRight: "20px" }}
                >
                  To Time:
                </Form.Label>
                <Form.Control
                  type="time"
                  value={toTime}
                  onChange={(e) => handleTimeChange("toTime", e.target.value)}
                  isInvalid={formErrors.toTime}
                />
                {formErrors.toTime && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid time for To Time.
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col
                style={{ marginTop: "30px" }}
                sm={6}
                xs={12}
                md={6}
                lg={4}
                className="rowCss"
              >
                <TimeInputField
                  name="From-To Time"
                  placeholder="From-To Time"
                  required
                  type="text"
                  validated={validated}
                  value={formData.title || ""}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      title: event.target.value,
                    });
                  }}
                  disabled
                  feedbackMessage="Please enter From-To time."
                  validationRegex={validationRegexTime}
                  validationErrorMessage="Please enter a valid From-To time."
                  isInvalid={formErrors.title} // Set isInvalid based on formErrors
                />
                {formErrors.title && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid From-To time.
                  </Form.Control.Feedback>
                )}
              </Col>

            </Row>
            <SuccessMessagePopup
              show={showSuccessMsg}
              onClose={handleCloseSuccessPopup}
              successMessage={successMsg}
            />
            <ErrorMessagePopup
              show={showErrorMsg}
              onClose={handleCloseErrorPopup}
              errorMessage={errorMsg}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1%",
              }}
            >
              <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
                style={{ width: "6rem", height: "2rem" }}
              >
                Clear
              </button>
              <button
                className="save-button-figma-div"
                type="submit"
                style={{ width: "6rem", height: "2rem" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Col>
      </Row>
      <div>
        <ViewTimings />
      </div>
    </div>
  );
};

export default FromToTime;
