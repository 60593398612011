/*
    authors: Venkatesh
    date: 26-10-2023
    update:26-10-2023 
    version: 1.0
*/
import React, { useState, useEffect } from "react";
import {
  Card,
  CardDeck,
  Container,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import {
  validationRegexOnlyAlpha,
  validationRegexOnlyTextarea,
  validationRegexAmount,
} from "../reUsableForm/regex";
import InputGroup from "react-bootstrap/InputGroup";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SelectDropdown from "../SelectDropdown";
// import { useDispatch } from "react-redux";
// import { postFeeDetailsRequest } from "./slice/feeDetailsSlice";

const initialFormData = {};

const data1 = [
  {
    id: 1,
    date: "2023-10-17",
    studentId: "S001",
    name: "John Doe",
    className: "2nd Class",
    section: "A",
  },
  {
    id: 2,
    date: "2023-10-18",
    studentId: "S002",
    name: "Jane Smith",
    className: "1st Class",
    section: "B",
  },
];

const AddFees = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [indianTime, setIndianTime] = useState("");
  const [studentDetails, setStudentDetails] = useState("");

  useEffect(() => {
    const updateIndianTime = () => {
      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString().slice(0, 16);
      setIndianTime(formattedDateTime);
    };
    updateIndianTime();
    const interval = setInterval(updateIndianTime, 60000);
    return () => clearInterval(interval);
  }, []);
  const classFeeStructure = [
    {
      id: 1,
      class: "1st Class",
      "1stInstallment": 10000,
      "2ndInstallment": 10000,
      "3rdInstallment": 5000,
      annualFee: 25000,
    },
    {
      id: 2,
      class: "2nd Class",
      "1stInstallment": 15000,
      "2ndInstallment": 10000,
      "3rdInstallment": 5000,
      annualFee: 30000,
    },
    {
      id: 3,
      class: "3rd Class",
      "1stInstallment": 15000,
      "2ndInstallment": 15000,
      "3rdInstallment": 5000,
      annualFee: 35000,
    },
  ];

  const studentData =
    data1.find((student) => student.id === formData.studentId) || [];

  const feeDetails =
    classFeeStructure.find((data) => data.class === studentData.className) ||
    [];


  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    // Process and submit the form data
    // Example: const data = { ...formData, schoolId: 1 };
    const sendData = {
      ...formData,
      studentData: studentData,
      feeDetails: feeDetails,
    };

    setShowSuccessMsg(true);
    setSuccessMsg("Successfully Updated!");
    setShowErrorMsg(false);
    setErrorMsg("Some fields are missing or contain wrong data");
  };

  const handleClear = () => {
    setFormData(initialFormData);
    setValidated(false);
    setShowErrorMsg({});
  };

  return (
    // <div >
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div className="content-header">Add Fees</div>

      <Row
        style={{ marginRight: "-100px", marginTop: "4%", marginLeft: "125px" }}
      >
        <Col xs={12} md={6} lg={3}>
          {/* <Form.Group className="mb-4"> */}
          <Form.Label className="lableName">Date</Form.Label>
          <TextInputField
            type="datetime-local"
            className="fcontrol"
            required
            placeholder="Date"
            value={indianTime}
          />
          {/* </Form.Group> */}
        </Col>
        <Col xs={12} md={6} lg={3}>
          {/* <Form.Group > */}
          <Form.Label className="mt-3"></Form.Label>
          <SelectDropdown
            isSearchable
            className="fcontrol"
            type="text"
            name="className"
            placeholder="Student ID"
            options={data1.map((option, index) => ({
              id: option.id,
              value: option.id,
              label: option.name,
            }))}
            onChange={(eventValue) => {
              setFormData({ ...formData, studentId: eventValue.id });
              setStudentDetails(eventValue.value);
            }}
            validated={validated}
            feedbackMessage="Please enter Student Id."
            validationRegex={validationRegexOnlyTextarea}
            validationErrorMessage="Please enter a valid Student Id."
          />
          {/* </Form.Group> */}
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <TextInputField
              className="fcontrol"
              type="text"
              name="className"
              placeholder="Name "
              required
              value={studentData.name}
              disabled
              validated={validated}
              feedbackMessage="Please enter Name."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid Name."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <TextInputField
              className="fcontrol"
              type="text"
              name="Name"
              placeholder="Class"
              required
              value={studentData.className}
              validated={validated}
              disabled
              feedbackMessage="Please enter Class Name."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid Class Name."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <TextInputField
            type="text"
            name="section"
            placeholder="Section"
            className="fcontrol"
            textType="Alphabet"
            required
            value={studentData.section}
            disabled
            validated={validated}
            feedbackMessage="Please enter section."
            validationRegex={validationRegexOnlyAlpha}
            validationErrorMessage="Please enter a valid section."
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <Form.Label className="mt2"> Installment</Form.Label>
            {/* <TextInputField
              className="fcontrol"
              type="text"
              name="feePaid"
              placeholder='1st Installment'
              required
              value={feeDetails['1stInstallment']}
              // onChange={(event) => {
              //   setFormData({ ...formData, "feePaid": event.target.value });
              // }}
              validated={validated}
              feedbackMessage="Please enter feePaid."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a validfeePaid."
            /> */}
            <p>1st Installment</p>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <Form.Label className="mt2"> Amount</Form.Label>
            <TextInputField
              className="fcontrol"
              type="text"
              name="dueFee"
              placeholder=" Fee"
              required
              value={feeDetails["1stInstallment"]}
              // onChange={(event) => {
              //   setFormData({ ...formData, "dueFee": event.target.value });
              // }}
              validated={validated}
              feedbackMessage="Please enter due fee."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a valid due fee."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group className="mt-2">
            <Form.Label style={{ marginLeft: "40px" }}>
              Payment Status
            </Form.Label>
            <InputGroup style={{ marginLeft: "70px", border: "none" }}>
              <InputGroup.Checkbox
                name="damage"
                className="input-figma"
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <Form.Label className="mt2"> Installment</Form.Label>
            {/* <TextInputField
              className="fcontrol"
              type="text"
              name="feePaid"
              placeholder='1st Installment'
              required
              value={formData.feePaid}
              // onChange={(event) => {
              //   setFormData({ ...formData, "feePaid": event.target.value });
              // }}
              validated={validated}
              feedbackMessage="Please enter feePaid."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a validfeePaid."
            /> */}
            <p>2nd Installment</p>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <Form.Label className="mt2"> Amount</Form.Label>
            <TextInputField
              className="fcontrol"
              type="text"
              name="dueFee"
              placeholder=" Fee"
              required
              value={feeDetails["2ndInstallment"]}
              // onChange={(event) => {
              //   setFormData({ ...formData, "dueFee": event.target.value });
              // }}
              validated={validated}
              feedbackMessage="Please enter due fee."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a valid due fee."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group className="mt-2">
            <Form.Label style={{ marginLeft: "40px" }}>
              Payment Status
            </Form.Label>
            <InputGroup style={{ marginLeft: "70px", border: "none" }}>
              <InputGroup.Checkbox
                name="damage"
                className="input-figma"
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <p>3rd Installment</p>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <Form.Label className="mt2"> Amount</Form.Label>
            <TextInputField
              className="fcontrol"
              type="text"
              name="dueFee"
              placeholder=" Fee"
              required
              value={feeDetails["3rdInstallment"]}
              // onChange={(event) => {
              //   setFormData({ ...formData, "dueFee": event.target.value });
              // }}
              validated={validated}
              feedbackMessage="Please enter due fee."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a valid due fee."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group className="mt-2">
            <Form.Label style={{ marginLeft: "40px" }}>
              Payment Status
            </Form.Label>
            <InputGroup style={{ marginLeft: "70px", border: "none" }}>
              <InputGroup.Checkbox
                name="damage"
                className="input-figma"
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col xs={12} md={6} lg={3}>
          <Form.Group>
            <p style={{ marginLeft: "150px", marginTop: "1%" }}>Annual Fee</p>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Form.Group style={{ marginTop: "1%", justifyContent: "center" }}>
            <Form.Label className="mt2"> Amount</Form.Label>
            <TextInputField
              className="fcontrol"
              type="text"
              name="dueFee"
              placeholder="Fee"
              required
              value={feeDetails["annualFee"]}
              validated={validated}
              feedbackMessage="Please enter annual fee."
              validationRegex={validationRegexAmount}
              validationErrorMessage="Please enter a valid annual fee."
            />
          </Form.Group>
        </Col>
      </Row>
      <SuccessMessagePopup
        show={showSuccessMsg}
        onClose={() => {
          setShowSuccessMsg(false);
          handleClear();
        }}
        successMessage={successMsg}
      />
      <ErrorMessagePopup
        show={showErrorMsg}
        onClose={() => {
          setShowErrorMsg(false);
        }}
        errorMessage={errorMsg}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1%",
          marginTop: "5%",
        }}
      >
        <button
          className="clear-button-figma-div"
          type="button"
          onClick={handleClear}
          style={{ width: "6rem", height: "2rem" }}
        >
          Clear
        </button>
        <button
          className="save-button-figma-div"
          type="submit"
          style={{ width: "6rem", height: "2rem" }}
        >
          Submit
        </button>
      </div>
    </Form>

    // </div>
  );
};

export default AddFees;
