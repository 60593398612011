import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';
import { setFranchiseEmptyData } from './franchiseEnquirySlicer';

const slice = createSlice(
    {
        name: 'franchiseFollowUpSlicerGet',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setFranchiseData: (state, action) => {
                state.isLoading = false;
                const newData = action.payload;

                const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

                if (existingObjectIndex !== -1) {
                    state.data[existingObjectIndex] = newData;
                }

            },
        },
    }
);

export const { fetchDataFailure, fetchDataStart, fetchDataSuccess, setFranchiseData } = slice.actions;

export const fetchFranchiseFollowUpView = () => async (dispatch, getFranchiseView) => {
    const { data, isLoading } = getFranchiseView().franchiseFollowUpSlicerGet;
    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart())

        try {
            const response = await fetch(API_PATH + '/franchise-enquires?isActive=true', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenId}`
                },
                // body: JSON.stringify(getFranchiseView),
            })

            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data = await response.json();
            dispatch(setFranchiseEmptyData([]))
            dispatch(fetchDataSuccess(data));
            dispatch(setFranchiseData(data))
        } catch (error) {
            dispatch(fetchDataFailure())
        }
    }
}



export default slice.reducer;