import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "ViewStudentFees",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchStudentFeeStart: (state, action) => {
            state.isLoading = action.payload;
            state.hasError = false;
        },
        fetchStudentFeeSuccess: (state, action) => {
            state.isLoading = true;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;
        },
        fetchStudentFeeFailure: (state, action) => {
            state.isLoading = false;
            state.hasError = action.payload;
        }

    }
});
export const { fetchStudentFeeStart, fetchStudentFeeFailure, fetchStudentFeeSuccess } = slice.actions;

export const fetchStudentFeeView = (id, studentIds) => async (dispatch, getState) => {
    const { data, isLoading } = getState().ViewStudentFees;
    const tokenId = token();
    if ( !isLoading) {
        dispatch(fetchStudentFeeStart());
        try {
            const response = await fetch(API_PATH + `/franchisee-school/${id}/student-fees?${studentIds}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${tokenId}`
                }
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const responseData = await response.json();
            dispatch(fetchStudentFeeSuccess(responseData));
        } catch (error) {
            dispatch(fetchStudentFeeFailure(error));
        }
    }
}
export default slice.reducer;
