import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../../Api/token";
import { API_PATH } from "../../../Api/api";
import { responseFormat } from "../../Auth/errorResponse";
import { fetchWithAuthorization } from "../../Auth/dependency-get";

const slice = createSlice({
  name: "schoolFees",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchSchoolFee: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchSchoolFeeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchSchoolFeeFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
  },
});

export const {
  fetchSchoolFee,
  fetchSchoolFeeSuccess,
  fetchSchoolFeeFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
} = slice.actions;

export const fetchSchoolFeeData = () => async (dispatch) => {
  dispatch(fetchSchoolFee());

  const tokenId = token();

  try {
    const response = await fetchWithAuthorization(
      API_PATH + "/franchiseeSchoolFees",
      "GET", // assuming this is a GET request
      tokenId
    );
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const responseData = await response;
      dispatch(fetchSchoolFeeSuccess(responseData));

      dispatch(setSuccessMessage("Data fetched successfully!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    console.error("Error:", error);
    const errMessage = responseFormat({ error });
    dispatch(fetchSchoolFeeFailure());
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
