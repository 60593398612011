import React, { useEffect, useState } from 'react';
import Table from '../reUsableForm/table/table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeeEnquiryFollowupView } from "./slice/viewEmployeeEnquiryFollowupSlice";
import { Modal, Button } from 'react-bootstrap';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import Pagination from 'react-bootstrap/Pagination';

// import './ViewEmployeeEnquiryFollowup.css'; // Ensure to create and import a CSS file for custom styles

const ViewEmployeeEnquiryFollowup = () => {
    const dispatch = useDispatch();
    const [employeeShowViewIconModal, setEmployeeShowViewIconModal] = useState(false);
    const [employeeSelectedIconRow, setEmployeeSelectedIconRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "name", header: "Name" },
        { field: "expectedSalary", header: "Expected Salary" },
        {
            field: "remarks",
            header: "Remarks",
            render: (row) => (
                <IconButton
                    onClick={() => employeeHandleViewIconClick(row)}
                    color="primary"
                    size="small"
                >
                    <VisibilityIcon />
                </IconButton>
            )
        },
    ];

    const apiData = useSelector((state) => state.ViewEmployee.data) || [];
    const putData = useSelector((state) => state.employeeEnquiryFollowUp.data) || [];

    const getLatestFollowUpDetails = (followUps) => {
        if (followUps.length === 0) {
            return { roleConfirmation: '', expectedDateOfJoining: '', remarks: '' };
        }
        else {
            return followUps;
        }
    };

    const combinedData = apiData.map((apiItem, index) => {
        const followUpDetails = Array.isArray(getLatestFollowUpDetails(apiItem.employeeEnquiryFollowUps || []))
            ? getLatestFollowUpDetails(apiItem.employeeEnquiryFollowUps || [])
            : [];

        const matchingPutItems = putData.filter(putItem => putItem.employeeEnquiryId == apiItem.id);
        let data;
        if (matchingPutItems.length > 0) {
            data = [...followUpDetails, ...matchingPutItems];
        } else {
            data = [...followUpDetails];
        }

        const uniqueData = data.filter((item, idx, self) => 
            idx === self.findIndex((t) => t.id === item.id)
        );

        return {
            ...apiItem,
            employeeEnquiryFollowUps: uniqueData,
            index: index + 1
        };
    });

    useEffect(() => {
        dispatch(fetchEmployeeEnquiryFollowupView());
    }, [dispatch]);

    const employeeHandleViewIconClick = (row) => {
        setEmployeeSelectedIconRow({ ...row, employeeEnquiryFollowUps: row.employeeEnquiryFollowUps });
        setEmployeeShowViewIconModal(true);
        setCurrentPage(1); // Reset to the first page when opening the modal
    };

    const itemsPerPage = 10;
    const totalPages = employeeSelectedIconRow
        ? Math.ceil(employeeSelectedIconRow.employeeEnquiryFollowUps.length / itemsPerPage)
        : 1;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = employeeSelectedIconRow
        ? employeeSelectedIconRow.employeeEnquiryFollowUps.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        )
        : [];

    return (
        <div>
            <div className="content-header">View Employee Enquiry Follow Up</div>
            {combinedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}><p>No records available</p></center>
            ) : (
                <Table data={combinedData} columns={columns} hover={true} />
            )}

            <Modal
                show={employeeShowViewIconModal}
                onHide={() => setEmployeeShowViewIconModal(false)}
                className="modal"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Employee Follow Ups</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                    <div className="table-responsive"> 
                        <table className="table table-striped table-bordered yash-table w-100">
                            <thead>
                                <tr>
                                    <th>Remarks</th>
                                    <th>Updated&nbsp;At</th>
                                    <th>Role&nbsp;Confirmation</th>
                                    <th>Date&nbsp;Of&nbsp;Joining</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((followUp, index) => {
                                    const updatedAtDate = new Date(followUp.updatedAt);

                                    const day = updatedAtDate.getDate().toString().padStart(2, '0');
                                    const month = (updatedAtDate.getMonth() + 1).toString().padStart(2, '0');
                                    const year = updatedAtDate.getFullYear();

                                    const hours = updatedAtDate.getHours().toString().padStart(2, '0');
                                    const minutes = updatedAtDate.getMinutes().toString().padStart(2, '0');
                                    const period = hours >= 12 ? 'PM' : 'AM';
                                    const formattedHours = (hours % 12) || 12;

                                    const formattedDateTime = `${day}-${month}-${year}<br/>${formattedHours}:${minutes} ${period}`;

                                    return (
                                        <tr key={index}>
                                            <td>{followUp.remark || "N/A"}</td>
                                            <td dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                                            <td>{followUp.roleConfirmation || "N/A"}</td>
                                            <td>{followUp.expectedDateOfJoining || "N/A"}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination className="pagination-css">
                        {Array.from({ length: totalPages }, (_, idx) => (
                            <Pagination.Item
                                key={idx + 1}
                                active={idx + 1 === currentPage}
                                onClick={() => handlePageChange(idx + 1)}
                            >
                                {idx + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setEmployeeShowViewIconModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ViewEmployeeEnquiryFollowup;
