import { responseStates } from "../../../Teachers/teacherSlicers/teachersInitialState";
import { createSlice } from "@reduxjs/toolkit";
import { postQualification } from "./addQualRequests";

const initialState = {
  postQualification: responseStates,
};
const addQualReducer = createSlice({
  name: "add qualification",
  initialState,
  reducers: {
    changeQualLoadingState: (state, action) => {
      state.postQualification.loading = "idle";
      state.postQualification.error = null;
    },
    extraReducers:(builder)=>{
        builder
        .addCase(postQualification.pending,(state,action)=>{
            state.postQualification.loading = 'pending';
        })
        .addCase(postQualification.fulfilled,(state,action)=>{
            state.postQualification.loading = 'fulfilled';
            state.postQualification.data = action.payload;
            state.postQualification.error = null;
        })
        .addCase(postQualification.rejected,(state,action)=>{
            state.postQualification.loading = 'rejected';
            state.postQualification.error = action.payload;
        })
    }
  }
});

export const { changeQualLoadingState } = addQualReducer.actions;
export default addQualReducer.reducer;
