import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    profilePictureUrl:'',
}

const profileSlicer = createSlice({
    name:'profile picture',
    initialState,
    reducers:{
        storeAppProfileUrl:(state,action)=>{
            state.profilePictureUrl = action.payload;
        },
        
    }
})

export const {storeAppProfileUrl,changeLoggedInState} = profileSlicer.actions
export default profileSlicer.reducer;