
import React, { useEffect, useMemo, useState } from "react";
import { updateEnquiry } from "../../EnquiryDetails/slice/enquiryFollowUpSlicer";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, TableSortLabel, IconButton, Button, TextField, MenuItem, ButtonBase, } from "@mui/material";
import "../table/FoodUpdatesTable.css";
// import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import SuccessMessagePopup from "../pop-card/successfulMessage";
import { ErrorMessagePopup } from "../pop-card/errorMessage";
import { useDispatch, useSelector } from 'react-redux';
import { fetchFoodItems } from "../../FoodUpdates/slice/getFoodItems";

const FoodUpdatesTable = ({
    data = null,
    columns = null,
    handleFoodItems,
    mealsOptions,
    mealsSection,
    selectFoodItems,
    handleMealsType,
    onChangeToTime,
    toTime,
    renderRemarksInput,
    foodRemarks,
    quantity,
    renderQuantity

}) => {
    const dispatch = useDispatch();

    // const employeeViewList = useSelector((state) => state.employeeSlicer.teachers.employee.employeeViewList)
    const editedRowValue = useSelector((state) => state.employeeSlicer.teachers.employeeRow)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState(columns && columns[0] ? columns[0].field : "");
    const [statusValues, setStatusValues] = useState({});
    const [commentsValues, setCommentsValues] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const getCaps = (head, field) => (head ? head.toUpperCase() : field.toUpperCase());



    const foodItems = useSelector((state) => state.foodItems.data) || [];

    useEffect(() => {
        dispatch(fetchFoodItems())
    })


    const handleSortClick = (column) => {
        if (column === "edit") {
            return;
        }

        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const filteredData = data.filter((row) => {
        const searchValue = searchQuery.toLowerCase();

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnValue = row[column.field];

            if (typeof columnValue === "string") {
                if (columnValue.toLowerCase().includes(searchValue)) {
                    return true;
                }
            } else if (typeof columnValue === "number") {
                if (columnValue.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (typeof valueA === "string" && typeof valueB === "string") {
            return sortOrder === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else {
            return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        }
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
    const filteredColumns = columns.filter(
        (col) => !["id", "view", "edit", "delete"].includes(col.field)
    );

    const options = [
        { value: '10:30', label: '10:30' },
        { value: '12:30', label: '12:30' },
        { value: '4:30', label: '4:30' },
    ];

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleViewClick = (row) => {
    };
    const renderRowColor = (backgroundColor) => {
        return backgroundColor;
    };




    return (
        <div className="table-Maindiv">
            <TextField
                placeholder="Search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "10px" }}
            />
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns &&
                                columns.map((head) => (
                                    <TableCell sx={{ backgroundColor: "#E2E8F0", color: "#464E5F", fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", textAlign: "center", whiteSpace: "nowrap" }} key={head.field} className="tablehead">
                                        <TableSortLabel
                                            active={sortColumn === head.field}
                                            direction={sortOrder}
                                            onClick={() => handleSortClick(head.field)}
                                        >
                                            {(head.field, head.header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map((row, rowIndex) => (
                            <TableRow key={`${row.id}-${rowIndex}`}
                                sx={{ backgroundColor: renderRowColor(row.backgroundColor) }}>

                                {columns.map((col) => (
                                    <TableCell key={col.field} className="tablecell">
                                        {
                                            col.field === "view" ? (<button onClick={() => handleViewClick(row)}>View</button>
                                            ) :
                                                col.field === "foodTime" ?
                                                    (
                                                        <input
                                                            type="time"
                                                            value={toTime[row.id] || ''}
                                                            onChange={(e) => onChangeToTime(row.id, e)}
                                                            style={{ minWidth: '150px', height: '30px' }}

                                                        />
                                                    ) :
                                                    col.field === "mealType" ?
                                                        (
                                                            <Select
                                                                value={mealsSection[row.id] || 'select meals'}
                                                                onChange={(e) => { handleMealsType(row.id, e); }}
                                                                sx={{ minWidth: '150px', height: '30px' }}
                                                                placeholder="select status"
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select meals
                                                                </MenuItem>
                                                                {mealsOptions && mealsOptions.map((option) => (
                                                                    <MenuItem key={option.id} value={option.value}>
                                                                        {option.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        ) :
                                                        col.field === "foodItems" ?
                                                            (
                                                                <Select
                                                                    value={selectFoodItems[row.id] || 'select food'}
                                                                    onChange={(e) => { handleFoodItems(row.id, e); }}
                                                                    sx={{ minWidth: '150px', height: '30px' }}
                                                                    placeholder="select status"
                                                                >

                                                                    {foodItems && foodItems.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.itemName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            ) :
                                                            col.field === "quantity" ?
                                                                (
                                                                    <input
                                                                        type="number"
                                                                        placeholder="Quantity"
                                                                        value={quantity[row.id] || ''}
                                                                        onChange={(e) => renderQuantity(row.id, e)}
                                                                        style={{ minWidth: '150px', height: '30px' }}

                                                                    />
                                                                ) :
                                                                col.field === "test" ?
                                                                    (
                                                                        <input
                                                                            placeholder="remarks"
                                                                            value={foodRemarks[row.id] || ''}
                                                                            onChange={(e) => renderRemarksInput(row.id, e)}
                                                                            style={{ minWidth: '150px', height: '30px' }}

                                                                        />
                                                                    ) :
                                                                    (
                                                                        row[col.field]
                                                                    )}
                                    </TableCell>
                                ))}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data ? null : <p>No data found</p>}

            <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            />
            {/* </Box> */}

        </div>
    );
};

export default FoodUpdatesTable;

