import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { setSectionAdd } from './addSectionSlice';

const slice = createSlice(
  {
    name: 'viewSection',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchSectionData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchSectionSuccess: (state, action) => {
        state.isLoading = false
        state.data = action.payload;
      },
      fetchSectionFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },
);
export const { fetchSectionData, fetchSectionSuccess, fetchSectionFailure } = slice.actions;

export const fetchSectionDataOnce = () => async (dispatch, getState) => {
  const tokenId = token();

  const { data, isLoading } = getState().viewSection;
  if (data === null && !isLoading) {
    dispatch(fetchSectionData());

    try {
      const response = await fetch(API_PATH + '/class-sections', {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const responseData = await response.json();
      dispatch(setSectionAdd([]))
      dispatch(fetchSectionSuccess(responseData));
    } catch (error) {
      dispatch(fetchSectionFailure());
    }
  }
}
export default slice.reducer;
