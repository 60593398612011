import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';


const slice = createSlice(
  {
    name: 'putFeeDetails',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchFeeDetailsData: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchFeeDetailsSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchFeeDetailsFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchFeeDetailsData, fetchFeeDetailsSuccess, fetchFeeDetailsFailure } = slice.actions;


export default slice.reducer;
