import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';

const slice = createSlice({
    name: 'fitnessAnalysisView',
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
    },
});

export const { fetchDataFailure, fetchDataStart, fetchDataSuccess } = slice.actions;

export const FitnessAnalysisView = (franchiseeSchoolId, studentId, month) => async (dispatch) => {
    const tokenId = token();
    dispatch(fetchDataStart());

    try {
        const response = await fetch(`${API_PATH}/franchisee-school/${franchiseeSchoolId}/student/${studentId}/fitness-parameters?month=${month}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenId}`,
            },
        });
        if (!response.ok) {
            throw new Error('Request failed');
        }
        const data = await response.json();
        dispatch(fetchDataSuccess(data));
    } catch (error) {
        dispatch(fetchDataFailure());
    }
};

export default slice.reducer;
