import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice(
    {
        name: 'addSchool',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {

            getAddSchoolData: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }
        },
    },

    {
        name: 'royaltyAmount',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {

            royaltyAmountData: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }
        },

    }


);

export const { getAddSchoolData ,royaltyAmountData } = slice.actions;


export default slice.reducer;