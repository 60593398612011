import React, { useEffect } from "react"; // Importing necessary modules
import { useDispatch, useSelector } from "react-redux"; // Importing useDispatch and useSelector hooks
import TableView from "../../reUsableForm/table/table"; // Importing TableView component
import { fetchDueDateGet } from "../FeeStructureSlice/feeDueDateGet";

function FeeDueDatesDetails() {
  const dispatch = useDispatch();
  const dueDateData = useSelector((state) => state.feeDueDates.data) || [];
  const PostdueDateData = useSelector((state) => state.feeDueDate.data) || [];
  const isLoading = useSelector((state) => state.feeDueDates.isLoading);
  const hasError = useSelector((state) => state.feeDueDates.hasError);

  useEffect(() => {
    dispatch(fetchDueDateGet());
  }, [dispatch]);

  const columns = [
    { field: "feeHeadId", header: "Sr.No" },
    { field: "feeHeadName", header: "Name" },
    { field: "DueDatesView", header: "Due Date" },
  ];
  

  const combinedData = [...(dueDateData || []), ...PostdueDateData];
  // Format the data to match the structure expected by the TableView component
  const formattedData = combinedData.map((item, index) => ({
    feeHeadId: index + 1,
    feeHeadName: item.dueDate.feeHeadName,
    dueDates: item.dueDate.dueDates,
  }));

  return (
    <div>
      <h2>Fee Due Dates</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : hasError ? (
        <p>No Data</p>
      ) : dueDateData === null ? (
        <p>No due dates available.</p>
      ) : (
        <TableView
          data={formattedData}
          columns={columns}
          hover={true}
          striped={true}
        />
      )}
    </div>
  );
}

export default FeeDueDatesDetails;
