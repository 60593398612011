import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexClass } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { postAddSectionRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addSectionSlice";
import ViewSection from './viewSection';
import SelectDropdown from "../SelectDropdown";
import { fetchDataOnceProgram } from '../programs/slice/viewProgramSlice';
import { fetchDataOnce } from '../Classes/slice/viewClassSlice';

const AddSection = () => {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({ classId: false,name:false })

  const dispatch = useDispatch();

  const apiData = useSelector((state) => state.addClass.data) || [];
  const classData = useSelector((state) => state.viewClass.data) || [];

  const combinedData = [...(apiData||[]),...classData]


  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchDataOnce());
    }
  }, [apiData, dispatch]);

  const showSuccessMsg = useSelector((state) => state.addSection.showSuccessMessage);
  const successMsg = useSelector((state) => state.addSection.successMessage);

  const showErrorMsg = useSelector((state) => state.addSection.showErrorMessage);
  const errorMsg = useSelector((state) => state.addSection.errorMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   setValidated(true);
    //   return;
    // }

    const newFormErrors = {

      classId: !formData.classId,
      name:!formData.name

    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }

  const class_id = formData.classId.id;

  formData.classId = class_id;
  
    dispatch(postAddSectionRequest(formData));
  };
  const handleClear = () => {
    setFormData({
      name: '',
      className:""
    });
    setValidated(false);
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >

        <div className="content-header">Add Section</div>
        <Row style={{ justifyContent: "center" }}>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.classId ? { id: formData.classId.id, label: formData.classId.label } : null}
              options={combinedData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name
              }))}

              onChange={(selectedOption) => {
                setFormData({ ...formData, classId: selectedOption});
                setFormErrors({ ...formErrors, classId: !selectedOption });
              }}
              placeholder="Select Class"
            />
            {formErrors.classId && (
              <div className="error-message">
                Please Select class .
              </div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
           
              {/* <Form.Label className='mt2' ></Form.Label> */}
              <TextInputField
                className="fcontrol"
                type="text"
                name="Section"
                placeholder='Section'
                required
                value={formData.name}
                onChange={(event) => {
                  setFormData({ ...formData, "name": event.target.value });
                  setFormErrors({ ...formErrors, name: false });
                }}
                validated={validated}
                feedbackMessage="Please enter Section."
                validationRegex={validationRegexClass}
                validationErrorMessage="Please enter a valid  Section."
              />
          
          </Col>
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
            style={{ width: '6rem', height: '2rem' }}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
            Submit
          </button>
        </div>

      </Form>
      <div>
        <ViewSection />
      </div>
    </>
    // </div>
  );
};

export default AddSection;