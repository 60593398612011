import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import TextInputField from '../reUsableForm/textInputField';
// import { validationRegexAlphaNumeric } from '../reUsableForm/regex';
// import './franchiseEntry.css';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { validationRegexAlphaNumeric, validationRegexAmount, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea } from "../reUsableForm/regex";

const RoyaltyCollections = () => {
  const amountRegex = /^[0-9,]+$/;
  const percentageRegex = /^[0-9]+(\.[0-9]+)?%?$/;

  const defaultData = {
    date: new Date().toISOString().slice(0, 10),
    schoolCode: '',
    schoolName: '',
    address: '',
    amount: '',
    royaltyPercentage: '',
    royaltyAmount: '',
    payingAmount: '',
    dueAmount: '',
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: e.target.name.includes('amount'||'paidAmount'||'royaltyPercentage'||'dueAmount') ? +value : value,
  //   });
  // };
  useEffect(() => {
    calculateRoyaltyAmount();
  }, [formData.amount, formData.royaltyPercentage]);
  useEffect(() => {
    if (formData.payingAmount !== '') {
      calculateDueAmount();
    }
  }, [formData.payingAmount, formData.royaltyAmount]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isNumberField = ['payingAmount', 'royaltyPercentage', 'dueAmount', 'royaltyAmount'].includes(name);
    // setFormData({
    //   ...formData,
    //   [name]: isNumberField ? +value : value,
    // });
    setFormData({ ...formData, [name]: value })

  };
  const calculateRoyaltyAmount = () => {
    const amount = parseFloat(formData.amount) || 0;
    const royaltyPercentage = parseFloat(formData.royaltyPercentage.replace('%', '')) || 0;
    const royaltyAmount = (amount * royaltyPercentage) / 100;
    setFormData({
      ...formData,
      royaltyAmount: royaltyAmount.toFixed(2),
    });
  };
  const calculateDueAmount = () => {
    const payingAmount = parseFloat(formData.payingAmount) || 0;
    const royaltyAmount = parseFloat(formData.royaltyAmount) || 0;
    const dueAmount = royaltyAmount - payingAmount;
    setFormData({ ...formData, dueAmount: dueAmount.toFixed(2) });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setSuccessMsg('SuccessFully Updated! ')
    setShowSuccessMsg(true);
    const updatedFormData = {
      ...formData,
      amount: parseFloat(formData.amount) || 0,
      royaltyPercentage: parseFloat(formData.royaltyPercentage.replace('%', '')) || 0,
      royaltyAmount: parseFloat(formData.royaltyAmount) || 0,
      payingAmount: parseFloat(formData.payingAmount) || 0,
      dueAmount: parseFloat(formData.dueAmount) || 0,
    };

    setFormData(defaultData);
    // const defaultData = {
    //   date: new Date().toISOString().slice(0, 10),
    //   schoolCode: '',
    //   schoolName: '',
    //   address: '',
    //   amount: '',
    //   royaltyPercentage: '',
    //   royaltyAmount: '',
    //   paidAmount: '',
    //   dueAmount: '',
    // };
    // setShowErrorMsg(true)
    // setErrorMsg("Some Filed are Missing or Wrong Data")

  }

  const handleClear = (e) => {
    // e.preventDefault();
    setFormData(defaultData);
    setValidated(false);
  }

  return (
    <>
      <h4>Royalty Collections</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mt2'>
          <Col sm={6} xs={12} md={6} lg={4}>
            <TextInputField
              name='date'
              disabled='true'
              value={formData.date}
            />
          </Col>
          <Col sm={6} xs={12} md={6} lg={4}>
            <TextInputField
              name='schoolCode'
              placeholder='Enter School Code'
              textType='AlphaNumeric'
              validated={validated}
              required='true'
              maxLength={25}
              feedbackMessage='School Code is Required!'
              validationErrorMessage='Give valid School Code!'
              value={formData.schoolCode}
              validationRegex={validationRegexAlphaNumeric}
              onChange={handleInputChange}
            // onChange={(e)=>{
            //   setFormData({ ...formData, "schoolCode": e.target.value })
            // }}
            />
          </Col>
          <Col sm={6} xs={12} md={6} lg={4}>
            <TextInputField
              name='schoolName'
              // validated={validated}
              disabled='true'
              placeholder='School Name'
              textType='Alphabet'
              maxLength={25}
              // validationRegex={validationRegexAlphaNumeric}
              value={formData.schoolName}
            />
          </Col>
        </Row>
        <Row className='mt2' style={{marginTop:'1rem'}}>
          <Col lg={4} md={4}>
            <TextInputField
              name='address'
              // validated={validated}
              disabled='true'
              placeholder='Address'
              textType='Alphabet'
              // validationRegex={validationRegexAlphaNumeric}
              value={formData.address}
            />
          </Col>

          <Col lg={4} md={4}>
            <TextInputField
              name='amount'
              // textType='number'
              validated={validated}
              placeholder='Enter Amount'
              // textType='AlphaNumeric'
              // textType="number"
              required='true'
              maxLength={25}
              feedbackMessage='Amount is Required!'
              validationErrorMessage='Amount should contain [0-9] and ,'
              // onChange={handleInputChange}
              onChange={(e) => {
                setFormData({ ...formData, "amount": +e.target.value })
              }}
              value={formData.amount}
              validationRegex={validationRegexAmount}
            />
          </Col>
          <Col lg={4} md={4}>
            <TextInputField
              name='royaltyPercentage'
              validated={validated}
              placeholder='Enter Royalty Percentage'
              textType='number'
              required='true'
              maxLength={25}
              feedbackMessage='Royalty Percentage is Required!'
              validationErrorMessage='percentage should contain only decimals,% is optional'
              onChange={handleInputChange}
              // onChange={(e)=>{
              //   setFormData({ ...formData, "royaltyPercentage": e.target.value })
              // }}
              value={formData.royaltyPercentage}
              validationRegex={validationRegexAmount}
            />
          </Col>
        </Row>
        <Row className='mt2' style={{marginTop:'1rem'}}>
          <Col lg={4} md={4}>
            <TextInputField
              name='royaltyAmount'
              disabled={true}
              validated={validated}
              placeholder='Royalty Amount to be paid'
              textType='number'
              required='true'
              maxLength={25}
              feedbackMessage='Royalty Amount is Required!'
              validationErrorMessage='Amount should contain [0-9] and ,'
              onChange={handleInputChange}
              // onChange={(e)=>{
              //   setFormData({ ...formData, "royaltyAmount": e.target.value })
              // }}
              value={formData.royaltyAmount}
              validationRegex={validationRegexAmount}
            />
          </Col>
          <Col lg={4} md={4}>
            <TextInputField
              name='payingAmount'
              validated={validated}
              placeholder='Enter Paying Amount!'
              textType='number'
              required='true'
              maxLength={25}
              feedbackMessage='Paying Amount is Required!'
              validationErrorMessage='Amount should contain [0-9] and ,'
              onChange={handleInputChange}
              // onChange={(e)=>{
              //   setFormData({ ...formData, "paidAmount": e.target.value })
              // }}
              value={formData.payingAmount}
              validationRegex={validationRegexAmount}
            />
          </Col>
          <Col lg={4} md={4}>
            <TextInputField
              name='dueAmount'
              disabled={true}
              validated={validated}
              placeholder='Remaining Due Amount!'
              textType='number'
              required='true'
              maxLength={25}
              feedbackMessage='Due Amount is Required!'
              validationErrorMessage='Amount should contain [0-9] and ,'
              onChange={handleInputChange}
              // onChange={(e)=>{
              //   setFormData({ ...formData, "dueAmount": e.target.value })
              // }}
              value={formData.dueAmount}
              validationRegex={validationRegexAmount}
            />
          </Col>
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={() => { setShowSuccessMsg(false); handleClear() }} successMessage={successMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </>
  );
};

export default RoyaltyCollections;
