import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table";
import { selectTeacherData, fetchTeacherData } from "./AssignTeacherToClassSlicer";
import useApiWithToken from "../../Api/getApiHook";
import { API_PATH } from "../../Api/api";

export const ViewAssignedClasses = () => {
  // const dispatch = useDispatch();
  // const teacherData = useSelector(selectTeacherData);

  // // Use the custom hook to fetch teacher data conditionally
  // const { isLoading, apiData: teacherDataFromAPI, serverError } = useApiWithToken(
  //   API_PATH +`/AssignTeacherToClasses` // Update the API URL
  // );

  // const fetchData = async () => {
  //   if (teacherData.length === 0 && teacherDataFromAPI === null) {
  //     try {
  //       await dispatch(fetchTeacherData());
  //     } catch (error) {
  //       console.error("Error fetching teacher data:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [dispatch, teacherData, teacherDataFromAPI]);

  // const columns = [
  //   { field: "id", header: "Sl.No" },
  //   { field: "TeacherName", header: "Teacher Name" },
  //   { field: "Subjects", header: "Subjects" },
  //   { field: "Class", header: "Class" },
  //   { field: "Section", header: "Section" },
  // ];

  // const formattedData = (teacherDataFromAPI || teacherData).map((teacher, index) => ({
  //   id: index + 1,
  //   TeacherName: teacher.teacherId,
  //   Subjects: teacher.subjectId,
  //   Class: teacher.classId,
  //   Section: teacher.sectionId,
  // }));

  // return (
  //   <>
  //     {isLoading ? (
  //       <p>Loading teacher data...</p>
  //     ) : serverError ? (
  //       <p>Error: {serverError.message}</p>
  //     ) : (
  //       <Table data={formattedData} columns={columns} hover={true} />
  //     )}
  //   </>
  // );
};
