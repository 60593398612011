
export const responseStates = {
    data: [],
    loading: 'idle', // 'idle', 'pending', 'fulfilled', 'rejected'
    error: null,
}
export const initialState = {
    teachers:{
        customHookOptions:{
            states:[],
            cities:[],
            areas:[],
            designation:[],
            qualifications:[]
        },
        employeeRow:{},
        postEmployeeData:responseStates,
        getEmployeeViewList:responseStates,
        getEmployeeQualifications:responseStates,
        getEmployeeStates:responseStates,
        getEmployeeAreas:responseStates,
        getEmployeeCities:responseStates,
        putEmployeeData:responseStates
    }
}