import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../../reUsableForm/textInputField";
import CustomCheckbox from "../../reUsableForm/checkBox";
import { useDispatch, useSelector } from "react-redux";
import {
  PostFeeHead,
  setShowErrorMessage,
  setShowSuccessMessage,
} from "../FeeStructureSlice/feeHeadPost";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";
import {
  validationRegexAlphaNumeric,
  validationRegexOnlyAlpha,
  validationRegexOnlyAlpha1,
  validationRegexPinNo,
} from "../../reUsableForm/regex";
import ViewFeeDetails from "../../fee/viewfeeDetails";
import FeeHeadDetails from "./FeeHeadDetails";

export const FeeHead = () => {
  const [formData, setFormData] = useState({
    name: "",
    oneTimeAtAdmission: false,
    monthly: false,
    biMonthly: false,
    term: false,
    halfYearly: false,
    yearly: false,
    refundable: false,
  });
  const [validated, setValidated] = useState(false);

  const [formErrors, setFormErrors] = useState({
    name: false,
    refundable: false,
    selectValue: false,
  });

  const dispatch = useDispatch();

  const showSuccessMsg = useSelector(
    (state) => state.feeHead.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.feeHead.successMessage);

  const showErrorMsg = useSelector((state) => state.feeHead.showErrorMessage);
  const errorMsg = useSelector((state) => state.feeHead.errorMessage);

  const [name1, setName1] = useState("");
  const handleCheckboxChange = (checkboxName) => {
    const updatedFormData = {
      ...formData,
      [checkboxName]: !formData[checkboxName],
    };
    setName1(checkboxName);
    if (updatedFormData[checkboxName]) {
      for (const key in updatedFormData) {
        if (key !== checkboxName && key !== "name") {
          updatedFormData[key] = false;
        }
      }
    }
    setFormErrors({ ...formErrors, selectValue: false });
    setFormData(updatedFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newFormErrors = {
      name: !formData.name,
      selectValue: !formData[name1],
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    if (hasErrors) {
      setValidated(true);
      return;
    }
    dispatch(PostFeeHead(formData));
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      oneTimeAtAdmission: false,
      monthly: false,
      biMonthly: false,
      term: false,
      halfYearly: false,
      yearly: false,
      refundable: false,
    });
    setFormErrors({ name: false, refundable: false, selectValue: false });
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header" style={{ marginTop: "1rem" }}>
          Fee Head
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Fee Name"
              placeholder="Fee Name"
              textType="Alphabet"
              required
              validated={validated}
              value={formData.name || ""}
              onChange={(event) => {
                setFormData({ ...formData, name: event.target.value });
                setFormErrors({ ...formErrors, name: false });
              }}
              feedbackMessage="Please enter Fee Name."
              validationRegex={validationRegexOnlyAlpha1}
              validationErrorMessage="Please enter valid Fee Name."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomCheckbox
              label="One time at admission"
              checked={formData.oneTimeAtAdmission}
              onChange={() => handleCheckboxChange("oneTimeAtAdmission")}
            />
          </Col>
          <Col>
            <CustomCheckbox
              label="Monthly"
              checked={formData.monthly}
              onChange={() => handleCheckboxChange("monthly")}
            />
          </Col>
          <Col>
            <CustomCheckbox
              label="Bi Monthly"
              checked={formData.biMonthly}
              onChange={() => handleCheckboxChange("biMonthly")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomCheckbox
              label="Term"
              checked={formData.term}
              onChange={() => handleCheckboxChange("term")}
            />
          </Col>
          <Col>
            <CustomCheckbox
              label="Half yearly"
              checked={formData.halfYearly}
              onChange={() => handleCheckboxChange("halfYearly")}
            />
          </Col>
          <Col>
            <CustomCheckbox
              label="Yearly"
              checked={formData.yearly}
              onChange={() => handleCheckboxChange("yearly")}
            />
          </Col>
          {formErrors.selectValue && (
            <div className="error-message">
              Please Select transportation way.
            </div>
          )}
        </Row>
        <Row>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <CustomCheckbox
              label="Refundable"
              checked={formData.refundable}
              onChange={(e) => {
                setFormData({ ...formData, refundable: e.target.checked });
              }}
            />
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="GST "
              placeholder="GST"
              textType="Alphabet"
              // required
              // validated={validated}
              value={formData.gst || ""}
              onChange={(event) =>
                setFormData({ ...formData, gst: Number(event.target.value) })
              }
              feedbackMessage="Please enter GST number."
              validationRegex={validationRegexAlphaNumeric}
              validationErrorMessage="Please enter valid  GST."
            />
          </Col>
        </Row>

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear{" "}
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <FeeHeadDetails />;
    </>
  );
};
