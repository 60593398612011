import React, { useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchSchoolEventAndHoliday } from "./slice/viewSchoolEventsAndHolidaysSlice";

function ViewSchoolEventsAndHolidays() {
  const columns = [
    { field: "serialNumber", header: "Sr.no" },
    { field: "name", header: " Name" },
    { field: "type", header: "Type" },
    { field: "fromDate", header: "From Date" },
    { field: "toDate", header: "To Date" },
  ];

  const apiData = useSelector((state) => state.viewSchoolEventsAndHolidays.data) || [];
  const postData = useSelector((state) => state.eventAndHolidays.data) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSchoolEventAndHoliday());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const combinedData = [...apiData, ...postData].map((item, index) => ({
    ...item,
    serialNumber: index + 1,
    fromDate: formatDate(item.fromDate),
    toDate: formatDate(item.toDate),
    type: item.type === "holiday" ? "Holiday" : "Event",
    name: capitalizeFirstLetter(item.name),
  }));

  return (
    <>
      <div className="content-header">View School Events And Holidays</div>
      {combinedData.length === 0 ? (
        <center><p>No records available</p></center>
      ) : (
        <Table data={combinedData} columns={columns} hover={true} striped={true} />
      )}
    </>
  );
}

export default ViewSchoolEventsAndHolidays;
