/*
    authors: Rajeev
    date: 20-12-2023
    update:20-12-2023 
    version: 1.0
*/

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { setPostTime } from "./addTimeTableslicer";

const slice = createSlice({
  name: "viewTimeTable",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchViewTimeTableStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchViewTimeTableSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchViewTimeTableFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchViewTimeTableStart,
  fetchViewTimeTableSuccess,
  fetchViewTimeTableFailure,
} = slice.actions;
export const fetchViewTimeTableOnce = (FranchiseeSchoolId) => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewTimeTable;
  const tokenId = token();
  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchViewTimeTableStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(
        API_PATH +
          `/timeTables?isActive=true&franchiseeSchoolId=${FranchiseeSchoolId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Request failed"); // You can customize error handling
      }

      const data1 = await response.json();
      dispatch(setPostTime([]));
      dispatch(fetchViewTimeTableSuccess(data1));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchViewTimeTableFailure());
    }
  }
};

export default slice.reducer;
