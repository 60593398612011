import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Table from "../reUsableForm/table/table1";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentAttendanceDataOnce } from "./viewStudentAttendanceSlicer"; // Modify this to match your actual action
import SelectDropdown from "../SelectDropdown";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { fetchFranchiseClassSectionsDataOnce } from "../Franchise/slice/viewfranchiseclasssections";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";
import { fetchAttendanceDataOnce } from "./viewAttendanceSlicer";
import { SelectingValuesDropDownField } from "../../ReusableFields/DropDown/multipleSelectDropDown";
import { fetchMonthlyStudentAttendanceDataOnce } from "./studentAttendanceSclier";
import { fetchClassSchoolDataOnce } from "../Franchise/slice/viewSchoolSlice";

const StudentAttendance = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [dropDowns, setDropDowns] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [processData, setProcessData] = useState(null);
  const dispatch = useDispatch();

  const apiData = useSelector((state) => state.studentAttendance.data) || [];

  const months = {
    1: { name: "January", index: 0 },
    2: { name: "February", index: 1 },
    3: { name: "March", index: 2 },
    4: { name: "April", index: 3 },
    5: { name: "May", index: 4 },
    6: { name: "June", index: 5 },
    7: { name: "July", index: 6 },
    8: { name: "August", index: 7 },
    9: { name: "September", index: 8 },
    10: { name: "October", index: 9 },
    11: { name: "November", index: 10 },
    12: { name: "December", index: 11 },
  };

  const monthOptions = Object.keys(months).map((key) => ({
    id: parseInt(key),
    value: months[key].name,
    label: months[key].name
  }));

  const [formErrors, setFormErrors] = useState({
    studentName: false,
    classesId: false,
  });

  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
  const FranchiseeSchoolId = Number(franchiseeSchoolId);

  const roleId = sessionStorage.getItem("roleId");
  const parentLoginObject = useSelector(
    (state) => state.parentLoginReducer.getParentLoginData
  );

  const data = parentLoginObject?.data;
  const studentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (roleId == 5) {
      if (parentLoginObject?.loading === "idle") {
        const parentId = sessionStorage.getItem("userId");
        dispatch(getParentLoginData(parentId));
      }
    } else {
      if (studentData.length === 0) {
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
      }
    }
  }, [dispatch, parentLoginObject, studentData]);


  const viewSchoolData = useSelector((state) => state.viewSchoolData.data) || [];

  useEffect(() => {
    if (viewSchoolData.length == 0) {
      dispatch(fetchClassSchoolDataOnce());
    }
  }, [viewSchoolData, dispatch]);

  const handleSearchClick = () => {
    const { studentId, classesId } = formData;

    if (!studentId || !classesId) {
      setFormErrors({
        studentName: !studentId,
        classesId: !classesId || classesId.length === 0,
      });
      return;
    }

    let ids = "";
    if (classesId !== undefined && classesId !== null) {
      ids = classesId.map(id => `month=${months[id].name}`).join('&');
    }

    dispatch(fetchMonthlyStudentAttendanceDataOnce(FranchiseeSchoolId, studentId, ids));
    setShowTable(true);
  };

  useEffect(() => {
    if (apiData.length > 0) {
      const processedData = apiData.flatMap(monthData => {
        return Object.keys(monthData).map(monthName => {
          // Convert monthName to lowercase for case insensitivity
          const lowercaseMonthName = monthName.toLowerCase();
          const monthAttendance = monthData[monthName];

          if (!Array.isArray(monthAttendance)) {
            return null;
          }

          // Convert monthName to a number to match with keys in the months object
          const monthNumber = Object.keys(months).find(key => months[key].name.toLowerCase() === lowercaseMonthName);

          if (!monthNumber) {
            console.error(`Month name "${monthName}" does not match any known month.`);
            return null;
          }

          const daysInMonth = new Date(2024, monthNumber, 0).getDate();

          const attendance = monthAttendance.reduce((acc, cur) => {
            if (cur.date) {
              const dayOfMonth = new Date(cur.date).getDate();
              if (cur.attendanceStatus !== null) {
                if (cur.dayType === "Half Day") {
                  acc[`day${dayOfMonth}`] = "Half Day";
                } else if (cur.dayType === "Absent") {
                  acc[`day${dayOfMonth}`] = "A";
                } else {
                  acc[`day${dayOfMonth}`] = "P";
                }
              } else if (cur.dayOfWeek === "Sunday") {
                acc[`day${dayOfMonth}`] = "H";
              } else if (cur.eventOrHolidays !== null) {
                acc[`day${dayOfMonth}`] = "H";
              }
            }
            return acc;
          }, {});

          return {
            month: monthName,
            monthIndex: months[monthNumber].index,
            daysPresent: monthData.presentDays,
            totalWorkingDays: monthData.totalWorkingDays,
            ...attendance,
          };
        });
      }).filter(monthData => monthData !== null);


      processedData.sort((a, b) => a.monthIndex - b.monthIndex);
      setProcessData(processedData);
    }
  }, [apiData]);

  const generateColumns = () => {
    const daysInMonth = formData.classesId
      ? formData.classesId.map((monthId) => new Date(2024, monthId, 0).getDate())
      : [31];

    const maxDays = Math.max(...daysInMonth);

    return [
      { field: "month", header: "Month" },
      ...Array.from({ length: maxDays }, (_, i) => ({
        field: `day${i + 1}`,
        header: `${i + 1}`,
      })),
      { field: "totalWorkingDays", header: "Total Working Days" },
      { field: "daysPresent", header: "Days Present" },
    ];
  };

  return (
    <>
      <div className="content-header">Student Attendance</div>
      <Row style={{ justifyContent: "center" }}>
        {studentData.length > 0 ? (
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.studentName
                  ? { value: dropDowns.studentName, label: dropDowns.studentName }
                  : dropDowns.studentName
              }
              options={studentData.map(item => ({
                id: item.id,
                value: item.name,
                label: item.name,
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  studentId: selectedOption.id,
                });
                setDropDowns({ ...dropDowns, studentName: selectedOption.label });
                setFormErrors({ ...formErrors, studentName: false });
              }}
              placeholder="Select studentName"
            />
            {formErrors.studentName && (
              <div className="error-message">Please Select studentName.</div>
            )}
          </Col>
        ) : (
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.studentName
                  ? { value: dropDowns.studentName, label: dropDowns.studentName }
                  : dropDowns.studentName
              }
              options={data?.childrens?.map(item => ({
                id: item.id,
                value: item.name,
                label: item.name,
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  studentId: selectedOption.id,
                });
                setDropDowns({ ...dropDowns, studentName: selectedOption.label });
                setFormErrors({ ...formErrors, studentName: false });
              }}
              placeholder="Select studentName"
            />
            {formErrors.studentName && (
              <div className="error-message">Please Select studentName.</div>
            )}
          </Col>
        )}
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <SelectingValuesDropDownField
            isMulti={true}
            value={formData.classesName}
            className="input-style"
            onChange={(selectedOptions) => {
              const selectedIds = selectedOptions.map(
                (selectedOption) => selectedOption.id
              );
              setFormData({
                ...formData,
                classesId: selectedIds,
                classesName: selectedOptions.label,
              });
              setFormErrors({ ...formErrors, classesId: false });
            }}
            placeholder="Select months"
            options={monthOptions}
            feedbackMessage="Please select months."
          />
          {formErrors.classesId && (
            <div className="error-message">Please Select months.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <div className="searchCss">
            <button className="class_search" onClick={handleSearchClick}>
              Search
            </button>
          </div>
        </Col>
      </Row>

      {processData?.length > 0 ? (
        <Table data={processData} columns={generateColumns()} hover={true} />
      ) : (
        <center style={{ color: "Red", margin: "1rem" }}>
          <p>No Data</p>
        </center>
      )}
    </>
  );
};

export default StudentAttendance;
