import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentTeacherMeetingDataOnce } from "./slice/viewParentTeacherMeetingSlice";
import { Col, Form } from 'react-bootstrap';
import { render } from "@testing-library/react";
import SearchableDropDown from "../../ReusableFields/DropDown/searchableDroDown";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";

function ViewParentTeacherMeeting() {
  const [formData, setFormData] = useState({});

  const roleId = sessionStorage.getItem("roleId");

  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "studentName", header: "Student Name" },
    roleId !== "5" ? { field: "hasParentAttend", header: "Has Parent Attend", render: (data) => (data.hasParentAttend ? "Yes" : "No") } : {},
    { field: "remark", header: "Remarks" },
  ];

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      ptm: value,
    });
  };

  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.viewParentTeacherMeeting.data) || [];

  useEffect(() => {
    if (formData.ptm || (formData.studentId && (formData.hasParentAttend !== undefined || formData.hasParentAttend !== null))) {
      dispatch(fetchParentTeacherMeetingDataOnce(FranchiseeSchoolId, formData.ptm, formData.studentId, formData.hasParentAttend));
    }
  }, [dispatch, formData.ptm, formData.studentId]);

  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const parentData = parentLoginObject?.data || [];

  useEffect(() => {
    if (roleId === "5") {
      if (parentLoginObject?.loading === "idle") {
        const parentId = sessionStorage.getItem('userId');

        dispatch(getParentLoginData(parentId));
      }
    }
  }, [dispatch, parentLoginObject?.loading]);

  const processedData = apiData.map((row, index) => {
    return {
      ...row,
      index: index + 1,
      studentName: row.student ? row.student.name : '',
    };
  });

  const Data = processedData.map(item => {
    const rowData = {};
    columns.forEach(column => {
      if (column?.field === "remark") {
        rowData["remark"] = item.remarks;
      }
      else {
        rowData[column.field] = item[column.field];
      }
    });
    return rowData;
  });


  return (
    <>
      <div className="content-header">View Parent Teacher Meeting</div>
      {roleId && roleId !== "5" ? (
        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
          <Form.Select
            className="textInput_Field"
            value={formData.ptm === undefined ? "select" : formData.ptm.toString()}
            onChange={handleSelectChange}>
            <option value="select">Select Parent attended PTM</option>
            <option value="PTM 1">PTM 1</option>
            <option value="PTM 2">PTM 2</option>
            <option value="PTM 3">PTM 3</option>
            <option value="PTM 4">PTM 4</option>

          </Form.Select>
        </Col>
      ) : (
        <Col sm={6} xs={12} md={2} lg={2} className="rowCss">
          <SearchableDropDown
            value={formData.studentName || ''}
            className="input-style"
            onChange={(selectedOption) => {
              setFormData({ ...formData, "studentId": Number(selectedOption.id), "studentName": selectedOption.value, hasParentAttend: true });
            }}
            placeholder="Select child"
            options={parentData?.childrens?.map((option) => ({
              id: option.id,
              value: option.name,
              label: option.name,
            }))}
            feedbackMessage="Please select Fee Head."
          />
        </Col>
      )}
      {Data.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table
          data={Data}
          columns={columns}
          hover={true}
          striped={true}
        />
      )}
    </>
  );
}

export default ViewParentTeacherMeeting;
