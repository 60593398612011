import React, { useEffect, useState } from 'react';
import Table from '../../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddVehicleView } from './addVehicleViewSlicer';

import SuccessMessagePopup from '../../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../../reUsableForm/pop-card/errorMessage';
import { setShowErrorMessageVehicle, setShowSuccessMessageVehicle, updateVehicle } from './addVehicleEditSlicer';
import { API_PATH } from '../../../Api/api';
import { folderPath } from '../../../Api/folderPath';
import { token } from '../../../Api/token';

const AddVehicleView = () => {

    const defaultData = {
        uploadDocuments: []
    }

    const [documentFiles, setDocumentFiles] = useState([]);
    const [formData, setFormData] = useState(defaultData);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});

    const [selectedDocs, setSelectedDocs] = useState([])

    const apiData = useSelector((state) => state.addVehicleView.data) || [];
    const postData = useSelector((state) => state.addVehicle.data) || [];
    const putData = useSelector((state) => state.addVehicleEdit.data) || [];

    const showSuccessMsg = useSelector((state) => state.addVehicleEdit.showSuccessMessage);
    const successMsg = useSelector((state) => state.addVehicleEdit.successMessage);

    const showErrorMsg = useSelector((state) => state.addVehicleEdit.showErrorMessage);
    const errorMsg = useSelector((state) => state.addVehicleEdit.errorMessage);

    const updateUploadDocuments = (newUploadDocuments, id) => {
        dispatch(updateVehicle({ id, updatedVehicleData: newUploadDocuments }));
    };
    const tokenId = token();

    const handleUpload = async (editedId) => {
        const formData = new FormData();
        for (let i = 0; i < documentFiles.length; i++) {
            formData.append('files', documentFiles[i]);
        }
        const apiUrl = API_PATH + `/uploadFiles?folderPath=${folderPath}`
        fetch(apiUrl, {
            method: 'POST',
    body: formData,
    headers: {
        "Authorization": `Bearer ${tokenId}`
    }
})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setFormData((prevData) => ({ ...prevData, uploadDocuments: data.filePaths }));
                updateUploadDocuments(data.filePaths, editedId);
                setSelectedDocs(data.filePaths )
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
    };



    const handleDocumentChange = (event) => {
        const files = event.target.files;
        setDocumentFiles(files);
    };

    useEffect(() => {
        if (documentFiles.length > 0) {
            handleUpload();
        }
    }, [documentFiles]);

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessageVehicle(false));
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAddVehicleView());
    }, [dispatch]);


    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "brand", header: "Brand" },
        { field: "chasisNumber", header: "Chasis Number" },
        { field: "model", header: "Model" },
        { field: "uploadDocuments", header: "Upload Documents" },
        { field: "vehicleNumber", header: "Vehicle Number" },
        { field: "vehicleEdit", header: "Edit" },
    ];

    const combinedData = [...(apiData || []), ...postData];

    const newObj = putData

    function replaceObjects(array, newObjects) {
        return array.map(obj => {
            const replacement = newObjects.find(newObj => newObj.id === obj.id);
            return replacement ? replacement : obj;
        })
    }

    const combinedData1 = replaceObjects(combinedData, newObj);

    const newModifiedData = combinedData1.map((postItem, index) => {
        return {
            index: index + 1,
            id: postItem.id,
            brand: postItem.brand,
            chasisNumber: postItem.chasisNumber,
            model: postItem.model,
            uploadDocuments: postItem.uploadDocuments,
            vehicleNumber: postItem.vehicleNumber,
        };
    });

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessageVehicle(false));
    };

    return (
        <div>
            <h4 className='enquiryView'>Vehicle View</h4>
            {newModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={newModifiedData} columns={columns} hover={true} showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    handleUpload={(editedId) => handleUpload(editedId)}
                    handleDocumentChange={handleDocumentChange}
                    uploadedDocs={selectedDocs}

                />
            )}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        </div>
    );
};

export default AddVehicleView;