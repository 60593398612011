import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { postAddFrequencyRequest } from "./slice/frequencySlice";
import { fetchFrequencyDataOnce } from "./slice/viewFrequencySlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table";
import SelectDropdown from "../SelectDropdown";
import {
  validationRegexAlphaNumeric,
  validationRegexOnlyTextarea,
} from "../reUsableForm/regex";

const AddFrequency = () => {
  const [formData, setFormData] = useState({
    fromDay: "",
    toDay: "",
    code: "",
  });

  const [formErrors, setFormErrors] = useState({
    fromDay: false,
    toDay: false,
    code: false,
  });

  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fromDay, setFromDay] = useState("");
  const [toDay, setToDay] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    // const form = event.currentTarget;
    // if (form.checkValidity() === false || "") {
    //   setValidated(true);
    //   return;
    // }

    const newFormErrors = {
      fromDay: !formData.fromDay,
      toDay: !formData.toDay,
      code: !formData.code,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    if (hasErrors) {
      setValidated(true);
      return;
    }

    const data = {
      ...formData,
      name: `${formData.fromDay}  ${formData.toDay}`,
    };

    dispatch(postAddFrequencyRequest(data));
    setShowSuccessMsg(true);
    setSuccessMsg("Successfully Updated!");
  };

  const handleClear = () => {
    setFormData({
      fromDay: "",
      toDay: "",
      code: "",
    });
    setFormErrors({
      fromDay: false,
      toDay: false,
      code: false,
    });
    setToDay("");
    setFromDay("");
    setValidated(false);
    setShowErrorMsg(false);
  };

  const columns = [
    { field: "id", header: "Sr.No" },
    { field: "name", header: "Frequency" },
    { field: "code", header: "Code" },
  ];

  const dayOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const apiData = useSelector((state) => state.dayFrequency.data) || [];
  const postData = useSelector((state) => state.frequencySlice.data) || [];

  useEffect(() => {
    dispatch(fetchFrequencyDataOnce());
  }, [dispatch]);

  const combinedData = [...(apiData || []), ...postData];

  return (
    <div>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Add Frequency</div>
            <Row>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <SelectDropdown
                  value={fromDay}
                  className={`input-style ${formErrors.fromDay ? "is-invalid" : ""
                    }`}
                  required
                  options={dayOptions.map((option) => ({
                    id: option,
                    value: option,
                    label: option,
                  }))}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, fromDay: selectedOption.value });
                    setFromDay(selectedOption);
                    setFormErrors({ ...formErrors, fromDay: false });
                  }}
                  placeholder="Select From day"
                />
                {formErrors.fromDay && (
                  <div className="error-message">Please Select fromDay.</div>
                )}
              </Col>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <SelectDropdown
                  value={toDay}
                  className={`input-style ${formErrors.toDay ? "is-invalid" : ""
                    }`}
                  options={dayOptions.map((option) => ({
                    id: option,
                    value: option,
                    label: option,
                  }))}
                  required
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, toDay: selectedOption.value });
                    setToDay(selectedOption);
                    setFormErrors({ ...formErrors, toDay: false });
                  }}
                  placeholder="Select to day"
                />
                {formErrors.toDay && (
                  <div className="error-message">Please Select toDay.</div>
                )}
              </Col>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <Form.Group>
                  <TextInputField
                    type="text"
                    className={`input-style ${formErrors.code ? "is-invalid" : ""
                      }`}
                    required
                    placeholder="Frequency Code"
                    value={`${formData.fromDay} - ${formData.toDay}`}

                    validated={validated}
                    feedbackMessage="please enter frequency code"
                    validationRegex={validationRegexAlphaNumeric}
                    validationErrorMessage="please enter valid code"
                  />
                </Form.Group>
              </Col>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <TextInputField
                  type="text"
                  className="input-style"
                  required
                  placeholder="Frequency Code"
                  value={formData.code}
                  onChange={(event) => {
                    setFormData({ ...formData, code: event.target.value });
                    setFormErrors({ ...formErrors, code: false });
                  }}
                  feedbackMessage="please enter frequency code"
                  validationRegex={validationRegexOnlyTextarea}
                  validationErrorMessage="please enter valid code"
                />
                {formErrors.code && (
                  <div className="error-message">
                    Please Enter Valid Frequency Code
                  </div>
                )}
              </Col>
            </Row>
            <SuccessMessagePopup
              show={showSuccessMsg}
              onClose={() => {
                setShowSuccessMsg(false);
                handleClear();
              }}
              successMessage={successMsg}
            />
            <ErrorMessagePopup
              show={showErrorMsg}
              onClose={() => setShowErrorMsg(false)}
              errorMessage={errorMsg}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0.5%",
                marginTop: "0%",
              }}
            >
              <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
                style={{ width: "6rem", height: "2rem" }}
              >
                Clear
              </button>
              <button
                className="save-button-figma-div"
                type="submit"
                style={{ width: "6rem", height: "2rem" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Col>
      </Row>
      <div className="App">
        <div></div>
        <Table data={combinedData} columns={columns} />
      </div>
    </div>
  );
};

export default AddFrequency;
