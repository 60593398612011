/*
    authors: Rajeev
    date: 05-01-2024
    update:05-01-2024 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown";
import { getParentLoginData } from "./parentLoginSlicer/parentLoginRequests";
import SearchableDropDown from "../../ReusableFields/DropDown/searchableDroDown";
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';

function ViewStudentTimeTable() {


  const columns = [
    { field: "timing", header: "Timings" },
    { field: "Monday", header: "Monday" },
    { field: "Tuesday", header: "Tuesday" },
    { field: "Wednesday", header: "Wednesday" },
    { field: "Thursday", header: "Thursday" },
    { field: "Friday", header: "Friday" },
    { field: "Saturday", header: "Saturday" },
    { field: "Sunday", header: "Sunday" },
  ];
  var months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  const days = [
    { id: 1, day: "Monday" },
    { id: 2, day: "Tuesday" },
    { id: 3, day: "Wednesday" },
    { id: 4, day: "Thursday" },
    { id: 5, day: "Friday" },
    { id: 6, day: "Saturday" },
    { id: 7, day: "Sunday" },
  ];
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [viewStudentTimetableData, setViewStudentTimetableData] = useState([]);
  const [dropDowns, setDropDowns] = useState({});
  const [formData, setFormData] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [formErrors, setFormErrors] = useState({

    monthName: false,
    day: false,
    studentName: false
  });
  const dispatch = useDispatch();
  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const data = parentLoginObject?.data;

  useEffect(() => {
    if (parentLoginObject?.loading === "idle") {
      const parentId = sessionStorage.getItem('userId');
      dispatch(getParentLoginData(parentId))
    }
  }, [dispatch, parentLoginObject?.loading])


  //  const viewStudentTimetableData = useSelector((state) => state.ViewClassTimetableByStudentId.data) || [];
  const handleSearchClick = async (e) => {
    e.preventDefault();
    let errors = {};
    if (!formData.monthName) errors.monthName = true;
    if (!formData.studentId) errors.studentName = true;
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const tokenId = token();
    const { studentId, monthName } = formData;

    try {
      const response = await fetch(API_PATH + `/student/${studentId}/timeTables?month=${monthName}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setViewStudentTimetableData(data);
      setShowTable(true);
      setSearchPerformed(true);
    } catch (error) {
      console.error('Error fetching timetable data:', error);
    }
  };

  const formatTimetableData = () => {
    const allTimings = [...new Set(Object.values(viewStudentTimetableData).flat().map(entry => entry.timing))];

    return allTimings.map(timing => {
      const row = { timing };

      Object.keys(viewStudentTimetableData).forEach(day => {
        row[day] = viewStudentTimetableData[day].find(entry => entry.timing === timing)?.subject || "";
      });

      return row;
    });
  };

  return (
    <>
      <div className="content-header">View TimeTable</div>
      <>
        {data?.childrens?.length > 0 ? (
          //franchisor login
          <Row style={{ justifyContent: "center" }}>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SearchableDropDown
                value={formData.studentName || ''}
                className="input-style"
                onChange={(selectedOption) => {
                  setFormData({ ...formData, "studentId": Number(selectedOption.id), "studentName": selectedOption.value });
                  setFormErrors({ ...formErrors, studentName: false });
                }}
                placeholder="Select child"
                options={data?.childrens?.map((option, index) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                }))}
                feedbackMessage="Please select Fee Head."
              // validated={validated}
              />
              {formErrors.studentName && (
                <div className="error-message">Please Select studentName .</div>
              )}
            </Col>
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                value={
                  dropDowns.monthName
                    ? { value: dropDowns.monthName, label: dropDowns.monthName }
                    : null
                }
                options={Object.keys(months).map(key => ({
                  id: key,
                  value: months[key],
                  label: months[key],
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, monthName: selectedOption.value });
                  setDropDowns({ ...dropDowns, monthName: selectedOption.value });
                  setFormErrors({ ...formErrors, monthName: false });
                }}
                placeholder="Select a month"
              // validated={validated}
              />
              {formErrors.monthName && (
                <div className="error-message">Please Select monthName .</div>
              )}
            </Col>
          </Row>
        ) : null}
        <Row className="search">
          <Col sm={6} xs={12} md={6} lg={4} >
            <button
              className="class_search" // Apply appropriate styling
              onClick={handleSearchClick} // Attach the click event handler
            >
              Search
            </button>
          </Col>
        </Row>


      </>


      {viewStudentTimetableData === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : searchPerformed && viewStudentTimetableData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : showTable ? (
        <Table
          data={formatTimetableData()}
          columns={columns}
          hover={true}
          striped={true}
        />
      ) : null}
    </>
  );
}
export default ViewStudentTimeTable;
