import React from 'react';
import { Form } from 'react-bootstrap';
import "./ReadOnlyDateField.css"

const PastDateField = ({
  label,
  name,
  value,
  onChange,
  required,
  feedbackMessage,
  validated
}) => {
  // Get the current date in ISO format (YYYY-MM-DD)
  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <Form.Group >
      {label && <Form.Label className="label-style">{label}</Form.Label>}

      <Form.Control
        type="date"
        className="readOnlyDate_field"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        validated={validated}
        // Set the max attribute to the current date to restrict to past dates
        max={currentDate}
        placeholder={label} // Use label as a placeholder
      />
      {validated && feedbackMessage && (
        <Form.Control.Feedback type="invalid">
          {feedbackMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default PastDateField;
