import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

const slice = createSlice(
  {
    name: 'viewClass',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchClassData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchClassSuccess: (state, action) => {
        state.isLoading = true;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchClassFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },

);

export const { fetchClassData, fetchClassSuccess, fetchClassFailure } = slice.actions;

export const fetchDataOnce = () => async (dispatch, getState) => {

  const tokenId = token()
  const { data, isLoading } = getState().viewClass;
  if (data === null && !isLoading) {
    dispatch(fetchClassData());

    try {
      const response = await fetch(API_PATH + "/classes", {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchClassSuccess(data1));
    } catch (error) {
      dispatch(fetchClassFailure());
    }
  }

}

export default slice.reducer;
