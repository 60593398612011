import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice(
  {
    name: 'viewArea',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchViewAreaData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchViewAreaSuccess: (state, action) => {
        state.isLoading = true;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchViewAreaFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },
);
export const { fetchViewAreaData, fetchViewAreaSuccess, fetchViewAreaFailure } = slice.actions;

export const fetchAreaDataOnce = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewArea;
  const tokenId = token();

  if (data === null && !isLoading) {
    dispatch(fetchViewAreaData());

    try {
      const response = await fetch(API_PATH + '/areas', {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchViewAreaSuccess(data1));
    } catch (error) {
      dispatch(fetchViewAreaFailure());
    }
  }
}
export default slice.reducer;
