import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
// import { setSectionAdd } from './addSectionSlice';

const slice = createSlice(
  {
    name: 'viewSections',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchSectionsData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchSectionsSuccess: (state, action) => {
        state.isLoading = false
        state.data = action.payload;
      },
      fetchSectionsFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },
);
export const { fetchSectionsData, fetchSectionsSuccess, fetchSectionsFailure } = slice.actions;

export const fetchClassSectionsDataOnce = (id) => async (dispatch, getState) => {
  const tokenId = token();
  const class_id = id;

  const { data, isLoading } = getState().viewSections;
  if (!isLoading) {
    dispatch(fetchSectionsData());

    try {
      const response = await fetch(API_PATH + `/class/${class_id}/sections`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const responseData = await response.json();
    //   dispatch(setSectionAdd([]))
      dispatch(fetchSectionsSuccess(responseData));
    } catch (error) {
      dispatch(fetchSectionsFailure());
    }
  }
}
export default slice.reducer;
