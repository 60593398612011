/*
    authors: Revanth
    date: 18-10-2023
    update:18-10-2023 
    version: 1.0
*/

import React, { useState } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
// import "./franchiseRequestAndSuggestions.css"
import { useDispatch, useSelector } from 'react-redux';
import TextInputField from '../reUsableForm/textInputField';
import { validationRegexAlphaNumeric, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import DropdownField from "../reUsableForm/dropDownField";
import { requestAndSuggestionPost, setShowErrorMessage, setShowSuccessMessage } from './franchiseSlice/requestAndSuggestionsSlicer';
import { Label } from '@mui/icons-material';

const EnquiryEntry = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const showSuccessMsg = useSelector((state) => state.franchiseRequestAndSuggestions.showSuccessMessage);
    const successMsg = useSelector((state) => state.franchiseRequestAndSuggestions.successMessage);

    const showErrorMsg = useSelector((state) => state.franchiseRequestAndSuggestions.showErrorMessage);
    const errorMsg = useSelector((state) => state.franchiseRequestAndSuggestions.errorMessage);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const formatDate1 = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const selectDate = formatDate(selectedDate)

    const [formData, setFormData] = useState({
        date: selectDate,

        type: 'Request',
        description: '',
    })
    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch();

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setSelectedDate(new Date());
        dispatch(requestAndSuggestionPost(formData));
    }

    const handleClear = () => {
        setValidated(false);
        setFormData({
            date: selectDate,
            type: "",
            description: "",
            schoolCode: "",
        });
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    return (
        <div>
            <Container >
                <span className="content-header">Request And&nbsp;Suggestions</span>
                <Row className='mb2 mt-4'>
                    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                        <Row >
                            <Col>
                                <Form.Control
                                    className='enquiryEntryDate'
                                    value={formatDate1(selectedDate)}
                                >
                                </Form.Control>
                            </Col>
                            <Col>
                                <TextInputField
                                    name="School Code"
                                    textType="Alphabet"
                                    value={formData.schoolCode || ''}
                                    onChange={(event) => { setFormData({ ...formData, 'schoolCode': event.target.value }) }}
                                    maxLength={25}
                                    required
                                    placeholder="School Code*"
                                    validated={validated}
                                    feedbackMessage="Please enter school code."
                                    validationRegex={validationRegexAlphaNumeric}
                                    validationErrorMessage="Please enter a valid school code."
                                />
                            </Col>
                            <Col>
                                <TextInputField
                                    name="school Name"
                                    textType="Alphabet"
                                    readonly
                                    value={formData.schoolName || ''}
                                    onChange={(event) => { setFormData({ ...formData, schoolName: event.target.value }) }}
                                    required
                                    placeholder="school Name*"
                                    validated={validated}
                                    feedbackMessage="Please enter school name."
                                    validationRegex={validationRegexOnlyAlpha}
                                    validationErrorMessage="Please enter a valid school name."
                                />
                            </Col>
                        </Row>
                        <Row  className='mt-3'>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Select
                                    as="select"
                                    name="request and suggestion"
                                    value={formData.type}
                                    required
                                    onChange={(event) => { setFormData({ ...formData, type: event.target.value }) }}
                                >
                                    <option value="Request">Request</option>
                                    <option value="Suggestion">Suggestion</option>
                                </Form.Select>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <TextInputField
                                    name="Description"
                                    textType="Alphabet"
                                    value={formData.description}
                                    onChange={(event) => { setFormData({ ...formData, description: event.target.value }) }}
                                    required
                                    placeholder="Description*"
                                    validated={validated}
                                    feedbackMessage="Please enter description."
                                    validationRegex={validationRegexOnlyTextarea}
                                    validationErrorMessage="Please enter a valid description."
                                />
                            </Col>
                        </Row>
                        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                        <div className='clearsubmit' style={{ display: "flex", justifyContent: "center", marginLeft: "-6%",marginTop:'2%' }}>
                            <button className="clear-button-figma-div" type="button" onClick={handleClear}> Clear </button>
                            <button className="save-button-figma-div" type="submit" >Submit</button>
                        </div>
                    </Form>
                </Row>
            </Container>
        </div >
    );
};

export default EnquiryEntry;