import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewClassSlice";
import { fetchDataOnceProgram } from "../programs/slice/viewProgramSlice"

function ViewClass() {
  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "programName", header: "Program Name" },
    { field: "name", header: "Class Name" },

  ];

  const apiData = useSelector((state) => state.viewClass.data) || [];
  const postData = useSelector((state) => state.addClass.data)
  const data = useSelector((state) => state.viewProgram.data) || [];
  const getData = useSelector((state) => state.addProgram.data) || [];

  const addData = [...(data||[]),...getData]
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataOnce());
    dispatch(fetchDataOnceProgram());
  }, [dispatch]);

  let newModifiedData = []

  if (postData && postData.length > 0) {
    postData.forEach((postItem) => {
      const modifiedData = postItem;
      const findObject = addData.find((obj) => obj.id == postItem.programId);

      const newData = {
        ...modifiedData,
        program: findObject,
        programName: findObject?.name
      };
      newModifiedData.push(newData);
    });
  }
  const combinedData = [...(apiData || []), ...(newModifiedData)];
  const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1,
    programName: row.program ? row.program.name : "",
  }));


  return (
    <>
      {/* <div className="content-header">View Class</div> */}
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={processedData} columns={columns} hover={true} striped={true} />
        )
      }
    </>
  );
}
export default ViewClass;