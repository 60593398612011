import { createSlice } from "@reduxjs/toolkit";
import { getParentLoginData } from "./parentLoginRequests";
import { parentLoginInitialState } from "./parentLoginInitialState";


const parentLoginSlicer = createSlice({
    name:"parentLogin",
    initialState:parentLoginInitialState,
    reducers:{
        changeParentProfileRenderedState:(state)=>{
            state.parentProfileRendered = false
        },
        
        storeParentProfilePicture:(state,action)=>{
            state.parentProfilePicture = action.payload
        },
        
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getParentLoginData.pending,(state)=>{
            state.getParentLoginData.loading="pending"
        })
        .addCase(getParentLoginData.fulfilled,(state,action)=>{
            const response = action.payload;
            if(response.hasOwnProperty("errors")){
                state.getParentLoginData.loading = "rejected";
                state.getParentLoginData.error = response.errors
            }if(response.hasOwnProperty("error")){
                state.getParentLoginData.loading = "rejected";
                state.getParentLoginData.error = response.error
            }
            else{
                state.getParentLoginData.loading = "fulfilled";
                state.getParentLoginData.data = response;
                state.getParentLoginData.error = null;
            }
        })
        .addCase(getParentLoginData.rejected,(state,action)=>{
            state.getParentLoginData.loading="rejected";
            state.getParentLoginData.error = action.payload
        })
    }

})


export default parentLoginSlicer.reducer;
export const {changeParentProfileRenderedState,storeParentProfilePicture} = parentLoginSlicer.actions;
