import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from "../../Auth/dependency_post";
import { responseFormat } from "../../Auth/errorResponse";


const slice = createSlice(
    {
        name: "eventAndHolidays",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchEventAndHolidaysData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchEventAndHolidaySuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchEventAndHolidayFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setEventAndHolidays: (state, action) => {
                state.data = action.payload
            }
        },
    },
);

export const { fetchEventAndHolidaysData, fetchEventAndHolidaySuccess, fetchEventAndHolidayFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage,
    setShowErrorMessage, setEventAndHolidays } = slice.actions;

export const postEventAndHolidaysRequest = (postData) => async (dispatch) => {
    dispatch(fetchEventAndHolidaysData());
    const tokenId = token();

    try {
        const response = await fetchWithAuthPost(API_PATH + '/event-holiday', 'POST', tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            responseData.data.map(item => dispatch(fetchEventAndHolidaySuccess([item])));

            dispatch(setSuccessMessage("Successfully Created!"));
            dispatch(setShowSuccessMessage(true));

        }
    }
    catch (error) {

        console.error("....error", error)
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;