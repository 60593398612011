/*
    authors: Naveen
    date: 17-10-2023
    update:17-10-2023 
    version: 1.0
*/

import React, { useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewAllocationSlicer"
import { getEmployeeList } from "../Teachers/teacherSlicers/allRequests";
import { fetchCreateLaneView } from "./createLineViewSlicer";


export const ViewAllocationBusRoute = () => {


    const columns = [
        { field: "index", header: "Sl.No" },
        { field: "vehicleNo", header: "vehicle No" },
        { field: "driverName", header: "Driver Name" },
        { field: "mobileNo", header: "Mobile No" },
        { field: "title", header: "Title" },
        { field: "fromLane", header: "From Lane" },
        { field: "toLane", header: "To Lane" },
        { field: "stops", header: "Stops" },
        { field: "pickupTime", header: "Pickup Time" },
        { field: "dropTime", header: "Drop Time" },
    ]

    // const isLoading = useSelector((state) => state.viewAllocationBusRoute.isLoading);
    // const hasError = useSelector((state) => state.viewAllocationBusRoute.hasError);


    const apiData = useSelector((state) => state.viewAllocationBusRoute.data) || []
    const postData = useSelector((state) => state.allocationBusRoute.data) || []

    const employeesListStatus = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeViewList?.loading);
    const employeesList = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeViewList?.data) || [];

    const laneGet = useSelector((state) => state.createLane.data) || [];

    const lanePost = useSelector((state) => state.postLaneSlicer.postLane.data) || [];

    const lanesData = [...(laneGet || []), ...lanePost]



    const dispatch = useDispatch();

    useEffect(() => {
        if (employeesListStatus === 'idle') {
            dispatch(getEmployeeList())
        }
    }, [dispatch, employeesListStatus])


    useEffect(() => {
        dispatch(fetchDataOnce());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCreateLaneView());
    }, [dispatch]);


    const combinedData = [...(apiData || []), ...postData];


    let modifiedData = []

    combinedData.forEach((obj, index) => {
        const driverDetails = employeesList ? employeesList.find((item) => item.id === obj.driverId) : null;
        const lanesDetails = lanesData ? lanesData.find((item) => item.id === obj.laneId) : null;

        modifiedData = [
            ...modifiedData,
            {
                ...obj,
                index: index + 1,
                mobileNo: driverDetails?.primaryContactNumber,
                driverName: driverDetails?.name,
                title: lanesDetails?.title,
                fromLane: lanesDetails?.from,
                toLane: lanesDetails?.to,
                stops: lanesDetails?.stops,
            }

        ]
    })

    return (
        <>
            {modifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : modifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={modifiedData} columns={columns} hover={true} />
            )}
        </>
    )
}