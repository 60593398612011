import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import StudentReportTable from "./StudentReportTable";
import SubjectAssessmentModal from "./SubjectAssessmentModal";
import SkillsAssessmentModal from "./SkillsAssessmentModal";
import ActivitiesAssessmentModal from "./ActivitiesAssessmentModal";
import FoodUpdatesModal from "./foodUpdatesModal";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { franchiseeSchoolId, token } from "../../Api/token";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown";
import { API_PATH } from "../../Api/api";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";
import TextInputField from "../reUsableForm/textInputField";

const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const columns = [
  { field: "id", header: "Sr.No" },
  { field: "date", header: "Date" },
  { field: "attdence", header: "Attendance" },
  { field: "subjectsAssessments", header: "subject Assessments" },
  { field: "skillsAssessments", header: "skill Assessments" },
  { field: "activitiesAssessments", header: "activity Assessments" },
  { field: "foodUpdates", header: "Food Updates" },
];

const getCurrentYear = () => new Date().getFullYear();
const getCurrentMonth = () => new Date().getMonth() + 1;

const generateYearOptions = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
  }
  return years;
};

const generateMonthOptions = (selectedYear) => {
  const months = monthOptions.slice(0, 11);
  return months.map((month) => ({
    label: month.label,
    value: month.value.toString(),
  }));
};

const StudentReport = () => {
  const defaultData = {
    month: "",
    year: "",
  };

  const [validated, setValidated] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [selectedStudentData, setSelectedStudentData] = useState([]);
  const [showSubjectAssessmentModal, setShowSubjectAssessmentModal] =
    useState(false);
  const [showSkillsAssessmentModal, setShowSkillsAssessmentModal] =
    useState(false);
  const [showActivitiesAssessmentModal, setShowActivitiesAssessmentModal] =
    useState(false);
  const [showFoodUpdatesModal, setShowFoodUpdatesModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [monthOptionsForSelectedYear, setMonthOptionsForSelectedYear] =
    useState([]);
  const [dropDowns, setDropDowns] = useState({
    studentName: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const yearOptions = generateYearOptions(
    getCurrentYear() - 10,
    getCurrentYear()
  );

  const dispatch = useDispatch();

  const roleId = sessionStorage.getItem("roleId");

  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);

  const data = parentLoginObject?.data;

  const studentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (Number(roleId) === 5) {
      if (parentLoginObject?.loading === "idle") {
        const parentId = sessionStorage.getItem("userId");
        dispatch(getParentLoginData(parentId));
      }
    }
  }, [dispatch, parentLoginObject]);


  useEffect(() => {
    if (Number(roleId) !== 5) {
      const FranchiseeSchoolId = franchiseeSchoolId()
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }
  }, [dispatch, studentData])


  useEffect(() => {
    setMonthOptionsForSelectedYear(generateMonthOptions(year));
  }, [year]);

  const handleClear = () => {
    setMonth("");
    setYear("");
    setDropDowns({ studentName: "" });
    setFormErrors({});
    setSelectedStudentData([])
  };

  const handleStudentNameChange = (selectedOption) => {
    if (studentData.length > 0) {
      const selectedStudent = studentData.find(
        (student) => student.name === selectedOption.value
      );
      setDropDowns((prevDropDowns) => ({
        ...prevDropDowns,
        id: selectedStudent?.id,
        studentName: selectedStudent?.name,
        rollNumber:
          selectedStudent?.studentEnrollments[0]?.studentRollNumber || "",
        class:
          selectedStudent.studentEnrollments[0]?.classesClassSection?.class
            ?.name || "",
        section:
          selectedStudent?.studentEnrollments[0]?.classesClassSection?.classSection
            .name || "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        studentName: "",
      }));
    } else {
      const selectedStudent = data?.childrens?.find(
        (student) => student.name === selectedOption.value
      );
      setDropDowns((prevDropDowns) => ({
        ...prevDropDowns,
        id: selectedStudent.id,
        studentName: selectedStudent.name,
        rollNumber:
          selectedStudent?.studentEnrollments?.[0]?.studentRollNumber || "",
        class:
          selectedStudent?.studentEnrollments?.[0]?.class
          || "",
        section:
          selectedStudent?.studentEnrollments?.[0]?.section
          || "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        studentName: "",
      }));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
  };

  const fetchData = async () => {
    try {
      const queryParams = new URLSearchParams({
        month: month.value,
        year: year,
      });
      const url = `${API_PATH}/assessmentmonthlyReport/${dropDowns.id}?${queryParams}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setSelectedStudentData(responseData);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (dropDowns.id && month && year) {
      fetchData();
    }
  }, [dropDowns.id, month, year]);

  const handleVisibilityIconClick = (field, rowIndex) => {
    setSelectedRow(rowIndex);
    switch (field) {
      case "subjectsAssessments":
        setShowSubjectAssessmentModal(true);
        break;
      case "skillsAssessments":
        setShowSkillsAssessmentModal(true);
        break;
      case "activitiesAssessments":
        setShowActivitiesAssessmentModal(true);
        break;
      case "foodUpdates":
        setShowFoodUpdatesModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="content-header">Student Report</div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              name="year"
              value={year ? { label: year } : null}
              onChange={(e) => setYear(e.value)}
              placeholder="Select Year"
              options={yearOptions}
              feedbackMessage="Year is Required!"
              validated={validated}
            />
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              name="month"
              value={month ? { label: month.label } : null}
              onChange={(e) => setMonth(e)}
              placeholder="Select Month"
              options={monthOptionsForSelectedYear}
              feedbackMessage="Month is Required!"
              validated={validated}
            />
          </Col >
          {studentData.length > 0 ? (
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.studentName
                    ? {
                      value: dropDowns.studentName,
                      label: dropDowns.studentName,
                    }
                    : null
                }
                className="input-style"
                options={studentData?.map((option) => ({
                  id: option.id,
                  value:
                    option.name || option.studentEnrollments[0].studentRollNumber,
                  label: option.name,
                  class:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.name || "", // Access class information
                  classId:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.id || "",
                }))}
                onChange={handleStudentNameChange}
                placeholder="Student Name"
              />
              {formErrors.studentName && (
                <div className="error-message">Please Select a studentName.</div>
              )}
            </Col>
          ) : (
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.studentName
                    ? {
                      value: dropDowns.studentName,
                      label: dropDowns.studentName,
                    }
                    : null
                }
                className="input-style"
                options={data?.childrens?.map((option) => ({
                  id: option.id,
                  value:
                    option.name || option.studentEnrollments[0].studentRollNumber,
                  label: option.name,
                  class:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.name || "", // Access class information
                  classId:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.id || "",
                }))}
                onChange={handleStudentNameChange}
                placeholder="Student Name"
              />
              {formErrors.studentName && (
                <div className="error-message">Please Select a studentName.</div>
              )}
            </Col>
          )}
        </Row>
        {dropDowns.rollNumber && dropDowns.class && dropDowns ? (
          <Row style={{ justifyContent: "center" }}>
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <TextInputField
                placeholder="Roll Number"
                textType="Alphabet"
                value={dropDowns.rollNumber}
                disabled
              />
            </Col>
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <TextInputField
                placeholder="Class"
                value={dropDowns.class}
                disabled
              />
            </Col>
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <TextInputField
                placeholder="Section"
                value={dropDowns.section}
                disabled
              />
            </Col>
          </Row>
        ) : (
          <Row></Row>
        )}

        {/* <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={2} className="rowCss">
          <div className="searchCss">
            <button className="clear-button-figma-div" onClick={handleClear}>
              Clear
            </button>
          </div>
         
          </Col>
          <Col sm={6} xs={12} md={6} lg={2} className="rowCss">
          <div className="searchCss">
            <button className="save-button-figma-div" onClick={handleSubmit}>
              Get Report
            </button>
             </div>
          </Col>
        </Row> */}

        <div
          className="clearsubmit"
          style={{ display: "flex", justifyContent: "center", marginLeft: "-6%" }}
        >
          <button className="clear-button-figma-div" onClick={handleClear}>
            Clear
          </button>
          <button className="save-button-figma-div" onClick={handleSubmit}>
            Get&nbsp;Report
          </button>
        </div>

      </Form>

      <div style={{ marginTop: "20px" }}>
        <StudentReportTable
          columns={columns}
          data={selectedStudentData}
          handleVisibilityIconClick={handleVisibilityIconClick}
        />
      </div>

      <SubjectAssessmentModal
        show={showSubjectAssessmentModal}
        onHide={() => setShowSubjectAssessmentModal(false)}
        subjectAssessments={
          selectedStudentData[selectedRow]?.subjectsAssessments || []
        }
      />

      <SkillsAssessmentModal
        show={showSkillsAssessmentModal}
        onHide={() => setShowSkillsAssessmentModal(false)}
        skillsAssessments={
          selectedStudentData[selectedRow]?.skillsAssessments || []
        }
      />

      <ActivitiesAssessmentModal
        show={showActivitiesAssessmentModal}
        onHide={() => setShowActivitiesAssessmentModal(false)}
        activitiesAssessments={
          selectedStudentData[selectedRow]?.activitiesAssessments || []
        }
      />

      <FoodUpdatesModal
        show={showFoodUpdatesModal}
        onHide={() => setShowFoodUpdatesModal(false)}
        foodUpdates={selectedStudentData[selectedRow]?.foodUpdates || []}
      />
    </>
  );
};

export default StudentReport;
