import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { setEventAndHolidays } from './eventAndHolidaysSlice';


const slice = createSlice(
    {
        name: 'viewSchoolEventsAndHolidays',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchSchoolEventAndHolidaysData: (state, action) => {
                state.isLoading = action.payload;
                state.hasError = false;
            },
            fetchSchoolEventAndHolidaySuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchSchoolEventAndHolidayFailure: (state, action) => {
                state.isLoading = false;
                state.hasError = action.payload;
            }
        },
    },

);

export const { fetchSchoolEventAndHolidaysData, fetchSchoolEventAndHolidaySuccess, fetchSchoolEventAndHolidayFailure } = slice.actions;

export const fetchSchoolEventAndHoliday = () => async (dispatch, getState) => {

    const { data, isLoading } = getState().viewSchoolEventsAndHolidays;
    const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

    const tokenId = token();

    if (data === null && !isLoading) {
        dispatch(fetchSchoolEventAndHolidaysData());
        try {
            const response = await fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId}/events-holidays`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${tokenId}`
                }
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data1 = await response.json();
            dispatch(setEventAndHolidays([]))
            dispatch(fetchSchoolEventAndHolidaySuccess(data1));
        } catch (error) {
            dispatch(fetchSchoolEventAndHolidayFailure());
        }
    }
}
export default slice.reducer;
