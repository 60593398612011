/*
    authors: Rajeev
    date: 03-11-2023
    update:03-11-2023 
    version: 1.0
*/

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { setPostAttendanceData } from "./addAttendanceSlicer";

const slice = createSlice({
  name: "viewAttendance",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchAttendanceDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchAttendanceDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchAttendanceDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchAttendanceDataStart,
  fetchAttendanceDataSuccess,
  fetchAttendanceDataFailure,
} = slice.actions;

export const fetchAttendanceDataOnce = (id) => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewAttendance;

  const tokenId = token();
  
  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchAttendanceDataStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/franchisee-school/${id}/studentAttendances`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error("Request failed"); // You can customize error handling
      }

      const data1 = await response.json();
      dispatch(setPostAttendanceData([]));
      dispatch(fetchAttendanceDataSuccess(data1));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchAttendanceDataFailure());
    }
  }
};

export default slice.reducer;
