import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "revenueSheetView",
    initialState: {
        data: [],
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setRevenueData: (state, action) => {
            state.isLoading = false;
            const newData = action.payload;
            const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

            if (existingObjectIndex !== -1) {
                state.data[existingObjectIndex] = newData;
            }
        },
    }
});

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess, setRevenueData } = slice.actions;

export const RevenuesViewSlicer = (fromDate, toDate, revenueTypeId) => async (dispatch, getState) => {
    const { data } = getState().revenueSheetView;
    const tokenId = token();
    const franchiseeSchoolId = Number(sessionStorage.getItem("franchiseeSchoolId"));
    const roleId = Number(sessionStorage.getItem("roleId"));  // Get roleId from sessionStorage

    if (data) {
        dispatch(fetchDataStart());
        try {
            // Determine the URL based on roleId
            let url;

            if (roleId === 1) {
                // If roleId is 1, call the API with only isActive=true
                url = fromDate && toDate
                    ? `${API_PATH}/revenues?isActive=true&fromDate=${fromDate}&toDate=${toDate}&revenueTypeId=${revenueTypeId}`
                    : `${ API_PATH }/revenues?isActive=true`

            } else {
                // Otherwise, include other parameters
                url = fromDate && toDate
                    ? `${API_PATH}/revenues?isActive=true&fromDate=${fromDate}&toDate=${toDate}&franchiseeSchoolId=${franchiseeSchoolId}&revenueTypeId=${revenueTypeId}`
                    : `${API_PATH}/revenues?isActive=true&franchiseeSchoolId=${franchiseeSchoolId}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const responseData = await response.json();
            dispatch(fetchDataSuccess(responseData));
        } catch (error) {
            dispatch(fetchDataFailure());
        }
    }
};


export default slice.reducer;
