import React, { useEffect, useState } from "react";
import Table from "../reUsableForm/table/table";
import { viewComplaintsSuggestionsGet } from "./slice/viewComplaintsSuggestionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CommentsAndSuggestions, setShowErrorMessage, setShowSuccessMessage } from "./slice/commentPut";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";


function PrincipalComplaintsSuggestions() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.ViewComplaintsSuggestions.data) || [];
  const putData = useSelector((state) => state.CommentsAndSuggestions.data) || [];


  const showSuccessMsg = useSelector((state) => state.CommentsAndSuggestions.showSuccessMessage);
  const successMsg = useSelector((state) => state.CommentsAndSuggestions.successMessage);

  const showErrorMsg = useSelector((state) => state.CommentsAndSuggestions.showErrorMessage);
  const errorMsg = useSelector((state) => state.CommentsAndSuggestions.errorMessage);
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  useEffect(() => {
    const isActive = true
    dispatch(viewComplaintsSuggestionsGet(isActive, franchiseeSchoolId));
  }, [dispatch]);


  // const [sendData, setSendData] = useState(data && data?.map((item) => ({ ...item, comment: "" })));
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      setSendData(data.map((item) => ({ ...item, comment: "" })))
    }
  }, [data])
  
  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "name", header: "Name" },  
    { field: "type", header: "Complaints/Suggestion" },
    { field: "description", header: "Description" },
    {
      field: "commentInput", header: "Comment", render: (row) => (
        <textarea
          className="form-control"
          style={{fontSize:'12px'}}
          // value={row.comment}
          onChange={(e) => {
            const newData = [...sendData];
            const rowIndex = newData.findIndex((item) => item.id === row.id);
            newData[rowIndex].comment = e.target.value;
            setSendData(newData);
          }}
        />
      )
    },
    {
      field: "comments_send", header: "Edit", render: (row) => (
        <button className="table-button" onClick={() => {
          handleSubmit(row)
        }}>Submit</button>
      ),
    },

  ];

  const handleSubmit = (row) => {
    dispatch(CommentsAndSuggestions(row))
  }

  const processedData = sendData.map((row, index) => ({
    ...row,
    index: index + 1,
    name: row?.guardian?.name
  }));

  const newUpdatedData = processedData.filter(obj => !putData.some(postObj => postObj.id === obj.id))

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));

  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };


  return (
    <>
      <div className="content-header">View Complaints / Suggestions</div>
      {newUpdatedData === null ? ( // Check if data is null
        <center><p>Loading...</p></center>
      ) : newUpdatedData.length === 0 ? ( // Check if data array is empty
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={newUpdatedData} columns={columns} hover={true} striped={true} />
        )
      }

      <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
    </>
  );
}
export default PrincipalComplaintsSuggestions;

