import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthorization } from "../../Auth/dependency-get";


const slice = createSlice({
    name: "studentsFeeCollections",
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      },
    },
})

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } =
  slice.actions;

export const fetchStudentsFeeCollections = (classId,schoolId) => async (dispatch, getState) => {
  const { data, isLoading } = getState().studentsFeeCollections;

  const tokenId = token();
  

  if (classId) {
    dispatch(fetchDataStart());

    try {
      const response = await fetchWithAuthorization(
        API_PATH + `/school/${schoolId}/class/${classId}/fee`,
        "GET",
        tokenId
      );

      if (!response) {
        throw new Error("Request failed");
      }

      const data1 = await response;
      dispatch(fetchDataSuccess(data1));
    } catch (error) {
      dispatch(fetchDataFailure());
    }
  }
};

export default slice.reducer;
