import { Typography, Avatar, Space } from "antd";
import React, { useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { storeAppProfileUrl } from "./profileSlicer";
import { NotificationsActive } from "@mui/icons-material";

const CustomAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fde3cf;
  color: #f56a00;
  border: 1px solid #f56a00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
`;

const PopoverText = styled(Popover)`
  :hover {
    background-color: #112F6E; 
    color: #ffffff;
  }
  .cursor {
    cursor: default;
  }
`;

export const Profile = () => {
  const navigate = useNavigate();
  const userName = sessionStorage.getItem('userName');
  const profilePic = sessionStorage.getItem('profilePictureUrl');
  const roleId = sessionStorage.getItem('roleId');

  const dispatch = useDispatch();
  const profileImageUrl = useSelector(state => state.profileSlicer.profilePictureUrl);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/parent-login');
    window.location.reload();
  };

  const handleNotification = () => {
    navigate('/viewMessages');
  }



  useEffect(() => {
    if (profilePic && profilePic.startsWith("https://")) {
      // Assuming profilePic is the URL to the image
      dispatch(storeAppProfileUrl(profilePic));
    }
  }, [profilePic]);

  const popover = (
    <PopoverText style={{ width: '10rem', padding: '0.2rem' }}>
      <center style={{ marginTop: "0.5rem" }}>
        <Typography.Text>{userName}</Typography.Text>
      </center>
      <center style={{ marginBottom: "0.5rem" }}>
        <Typography.Text onClick={handleLogout}>Logout</Typography.Text>
      </center>
    </PopoverText>
  );

  const getAvatarContent = () => {
    if (profileImageUrl || profileImageUrl.startsWith("https://")) {
      return (
        <Avatar
          src={profileImageUrl}
          alt="Profile Image"
          style={{ width: "40px", height: "40px" }}
        />
      );
    } else {
      return (
        <CustomAvatar>
          {userName ? userName.charAt(0).toUpperCase() : ""}
        </CustomAvatar>
      );
    }
  };

  return (
    <Space size={16} wrap>
      {roleId === "5" ? <NotificationsActive onClick={handleNotification} style={{ cursor: 'pointer' }} /> : null}
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <Space size={16} wrap>

          {getAvatarContent()}
        </Space>
      </OverlayTrigger>
    </Space>
  );
}
