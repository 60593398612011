/*
    authors: Naveen
    date: 18-10-2023
    update:18-10-2023 
    version: 1.0
*/

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import { distancePattern, validationRegexAmount } from "../reUsableForm/regex";
import DropdownField from "../reUsableForm/dropDownField";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "../reUsableForm/radioButton";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchFrequencyDataOnce } from "../Frequency/slice/viewFrequencySlice";
import { fetchTransportationFees } from "../Administration/Transportation/getTransportationFees";

import { fetchCreateLaneView } from "./createLineViewSlicer";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { PostTransportationRequest, setShowErrorMessage, setShowSuccessMessage } from "./slice/transportationSlice";

export const TransportFeeCollection = () => {

    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [amount, setAmount] = useState("");
    const [selectedOption, setSelectedOption] = useState('');
    const [paymentOption, setPaymentOption] = useState('');
    const [selected, setSelected] = useState([])

    const [formErrors, setFormErrors] = useState({
        paymentOption: false, selectedOption: false, studentId: false, laneId: false,
        frequencyId: false, distance: false, amountPaid: false
    })

    const paidAmount = parseFloat(formData.amountPaid) || 0;
    const dueAmount = amount - paidAmount;


    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
    const FranchiseeSchoolId = Number(franchiseeSchoolId);

    const dispatch = useDispatch();

    const studentData = useSelector((state) => state.viewStudent.data) || [];

    const frequencyPost = useSelector((state) => state.dayFrequency.data) || [];
    const frequencyGet = useSelector((state) => state.frequencySlice.data) || [];

    const newTransportationFee = useSelector((state) => state.transportationFees.data) || [];

    const showSuccessMsg = useSelector((state) => state.transportationCollectFee.showSuccessMessage);
    const successMsg = useSelector((state) => state.transportationCollectFee.successMessage);

    const showErrorMsg = useSelector((state) => state.transportationCollectFee.showErrorMessage);
    const errorMsg = useSelector((state) => state.transportationCollectFee.errorMessage);

    useEffect(() => {
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
        dispatch(fetchFrequencyDataOnce());
        dispatch(fetchTransportationFees())
    }, [dispatch]);


    const DayFrequency = [...(frequencyPost || []), ...frequencyGet]



    const lanesList = useSelector((state) => state.createLane.data) || [];


    useEffect(() => {
        dispatch(fetchCreateLaneView());
    }, [dispatch]);

    const ways = [{ "value": 1, "way": "PickOrDrop" }, { "value": 1, "way": "PickOrDrop" }, { "value": 2, "way": "PickAndDrop" }]


    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        setFormErrors({ ...formErrors, selectedOption: false });
        let value = event.target.value.trim().toLowerCase();
        const data = ways.find((item) => item.way.trim().toLowerCase() === value);
        setFormData({ ...formData, wayId: data.way })

    };

    const handleSelectPayment = (event) => {
        setFormErrors({ ...formErrors, paymentOption: false });
        setPaymentOption(event.target.value);
        let value = event.target.value.trim();
        // const data = ways.find((item) => item.way.trim().toLowerCase() === value);
        setFormData({ ...formData, payment: value })

    };




    function customRound(value) {
        const integerPart = Math.floor(value);
        const decimalPart = value - integerPart;
        const roundedValue = decimalPart >= 0.5 ? integerPart + 1 : integerPart;

        return roundedValue;
    }

    const selectedStudent = studentData.find(student => student.id === formData.studentId);

    const selectedStudentProgram = selectedStudent ? selectedStudent.program : '';
    const selectedStudentClass = selectedStudent ? selectedStudent.studentEnrollments[0]?.classesClassSection?.class?.name : '';
    const selectedStudentSection = selectedStudent ? selectedStudent.studentEnrollments[0]?.classesClassSection?.classSection?.name : '';
    const selectedStudentAddress = selectedStudent ? selectedStudent.currentAddress?.address : '';

    const transportFee = newTransportationFee.filter(item => item.frequencyId == formData.frequencyId);
    const distanceAmount = transportFee?.filter(item => item.distance == customRound(formData.distance));



    const transportationAmount = distanceAmount && distanceAmount.length > 0 ? distanceAmount.filter(item => item.way === formData.wayId) : null;

    let calculatedAmount = ''
    transportationAmount && transportationAmount.forEach(item => {
        const bimonthlyValue = item[formData.payment];
        calculatedAmount = bimonthlyValue

    });


    useEffect(() => {
        setAmount(calculatedAmount)
    }, [formData, calculatedAmount]);


    const handleSubmit = (e) => {
        e.preventDefault();

        const newFormErrors = {
            studentId: !formData.studentId,
            laneId: !formData.laneId,
            frequencyId: !formData.frequencyId,
            distance: !Number(formData.distance),
            amountPaid: !formData.amountPaid,
            paymentOption: !paymentOption,
            selectedOption: !selectedOption
        }
        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            return;
        }

        const send = {
            dueFee: dueAmount,
            fee: amount,
            paidFee: formData.amountPaid,
            pickupOption: formData.wayId,
            feeOption: formData.payment,
            actualDistance: Number(formData.distance),
            studentId: formData.studentId,
            frequencyId: formData.frequencyId,
            laneId: formData.laneId
        }
        dispatch(PostTransportationRequest(send));
    };

    const handleCancel = () => {
        setFormData({
            studentId: "", frequencyId: "", wayId: ""
        })
        setAmount({})
        setValidated(false);
        setFormErrors({ paymentOption: false, selectedOption: false })
        setSelected({})
        setSelectedOption('')
        setPaymentOption('')
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="content-header" style={{ margin: '1rem' }}>Fee Collection</div>
                <Row className='mt4'>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.studentName ? { label: selected.studentName, value: formData.studentId } : null}
                            options={studentData.map((student) => ({
                                id: student.id,
                                label: student.name || student.studentEnrollments[0].studentRollNumber,
                                value: student.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, studentId: selectedOption ? Number(selectedOption.value) : null });
                                setSelected({ ...selected, studentName: selectedOption ? selectedOption.label : "" })
                                setFormErrors({ ...formErrors, studentId: false });
                            }}
                            placeholder="Select Student Name"
                        />
                        {formErrors.studentId && (
                            <div className="error-message">
                                Please Select Student .
                            </div>
                        )}
                    </Col>
                    <Col xs={12}  sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Class Name"
                            textType="Class Name"
                            value={selectedStudentClass}
                            disabled
                            placeholder="Class Name"
                        />
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Section"
                            textType="Section"
                            value={selectedStudentSection}
                            disabled
                            placeholder="Section"
                        />
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Address"
                            textType="Address"
                            value={selectedStudentAddress}
                            disabled
                            placeholder="Address"
                        />
                    </Col>
                </Row>
                <Row >

                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.title ? { label: selected.title, value: formData.laneId } : null}
                            options={lanesList.map((obj) => ({
                                id: obj.id,
                                label: obj.title,
                                value: obj.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, laneId: selectedOption ? Number(selectedOption.value) : null });
                                setSelected({ ...selected, title: selectedOption ? selectedOption.label : "" })
                                setFormErrors({ ...formErrors, laneId: false });
                            }}
                            placeholder="Please select Lane"
                        />
                        {formErrors.laneId && (
                            <div className="error-message">
                                Please Select Lane View .
                            </div>
                        )}
                    </Col>

                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.frequencyName ? { label: selected.frequencyName, value: formData.frequencyId } : null}
                            options={DayFrequency.map((obj) => ({
                                id: obj.id,
                                label: obj.name,
                                value: obj.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, frequencyId: selectedOption ? Number(selectedOption.value) : null });
                                setSelected({ ...selected, frequencyName: selectedOption ? selectedOption.label : "" })
                                setFormErrors({ ...formErrors, frequencyId: false });
                            }}
                            placeholder="Please select Frequency"
                        />
                        {formErrors.frequencyId && (
                            <div className="error-message">
                                Please Select Frequency.
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Distance"
                            textType="name"
                            value={formData.distance}
                            onChange={(event) => {
                                setFormData({ ...formData, "distance": (event.target.value) });
                                setFormErrors({ ...formErrors, distance: false });
                            }}
                            required
                            placeholder="Distance"

                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter distance."
                            validationRegex={distancePattern}
                            validationErrorMessage="Please enter distance(decimal & Number)."
                        />
                    </Col  >
                    <Col></Col>

                </Row>
                <Row >
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Pick / Drop"
                            value="PickOrDrop"
                            checked={selectedOption === 'PickOrDrop'}
                            onChange={handleRadioChange}
                        />
                    </Col>

                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Pick and Drop"
                            value="PickAndDrop"
                            checked={selectedOption === 'PickAndDrop'}
                            onChange={handleRadioChange}
                        />
                        {formErrors.selectedOption && (
                            <div className="error-message">
                                Please Select one Transportation Way .
                            </div>
                        )}
                    </Col>
                    <Col></Col>
                    <Col></Col>

                </Row>

                <Row >
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Monthly"
                            value="monthlyFee"
                            checked={paymentOption === 'monthlyFee'}
                            onChange={handleSelectPayment}
                        />
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Bimonthly"
                            value="bimonthlyFee"
                            checked={paymentOption === 'bimonthlyFee'}
                            onChange={handleSelectPayment}
                        />
                        {formErrors.paymentOption && (
                            <div className="error-message">
                                Please Select one Payment Option .
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Day"
                            value="dayFee"
                            checked={paymentOption === 'dayFee'}
                            onChange={handleSelectPayment}
                        />
                    </Col>
                    <Col></Col>
                </Row>
                <Row >

                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Amount"
                            textType="Amount"
                            value={amount}
                            disabled
                            placeholder="Amount"
                        />
                    </Col>

                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="PaidAmount"
                            textType="PaidAmount"
                            value={formData.amountPaid}
                            onChange={(event) => {
                                setFormData({ ...formData, "amountPaid": Number(event.target.value) });
                                setFormErrors({ ...formErrors, amountPaid: false });
                            }}
                            required
                            placeholder="Paid Amount"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter Paid Amount."
                            validationRegex={validationRegexAmount}
                            validationErrorMessage="Please enter a PaidAmount(Number)."
                        />
                    </Col>
                    <Col xs={12} sm={6}  md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="DueAmount"
                            textType="DueAmount"
                            value={dueAmount}
                            disabled
                            placeholder="Due Amount"
                            // validated={validated}
                            feedbackMessage="Please enter Due Amount."
                            // validationRegex={validationRegexAmount}
                            validationErrorMessage="Please enter a Due Amount."
                        />
                    </Col>
                    <Col></Col>


                </Row>


                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleCancel}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>

            </Form >
        </>
    )
}
