import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./textInputField.css"
const TextInputField = ({
    label,
    name,
    required,
    value = '',
    type,
    onChange,
    placeholder,
    feedbackMessage,
    colSize,
    validated,
    validationRegex,
    validationErrorMessage,
    maxLength,
    textType,
    disabled,
    mt4
}) => {
    const [error, setError] = useState(null);
    const handleInputChange = (event) => {
        let inputValue = event.target.value;
        if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.substring(0, maxLength);
        }
        const isValidInput = validationRegex.test(inputValue);

        if (isValidInput) {
            setError(null);
            onChange({ target: { name, value: inputValue } });
        } else {
            setError(
                validationErrorMessage
            );

        }

    };

    return (
        <Form.Group>
            {label && <Form.Label className="label-style">{label}</Form.Label>}
            <Form.Control
                name={name}
                className="textInput_Field"
                required={required}
                placeholder={placeholder}
                value={value}
                type={type}
                disabled={disabled}
                onChange={handleInputChange}
                maxLength={maxLength}
                isInvalid={!!error}
            />

            <Form.Control.Feedback type="invalid" className="error-message">
                {error ||
                    (validated && !validationRegex.test(value) && error)
                    ||
                    (validated && value.length <= 0 && feedbackMessage)
                }
            </Form.Control.Feedback>
        </Form.Group>

// .error-message {
//     margin-top: 5px;
//     margin-bottom: 5px;
//     font-size: 0.7rem;
//     color: red;
//   }
     );
 };

export default TextInputField;