import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';

const slice = createSlice({
    name: 'expensesTypeDelete',
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
        successMessage: '',
        showSuccessMessage: false,
        errorMessage: '',
        showErrorMessage: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;

        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessageExpenses: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessageExpenses: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setSuccessMessage, setShowSuccessMessageExpenses, setErrorMessage, setShowErrorMessageExpenses } = slice.actions;

export const deleteExpensesType = (expensesId) => async (dispatch) => {
    dispatch(fetchDataStart());
    const tokenId = token();

    try {
        const response = await fetchWithAuthPost(API_PATH + `/expense-types/${expensesId}`, "DELETE", tokenId);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            dispatch(fetchDataSuccess([expensesId]));
            dispatch(setSuccessMessage("Successfully deleted!"));
            dispatch(setShowSuccessMessageExpenses(true));
        }
    } catch (error) {
        console.error("Error", error);
        const errMessage = responseFormat({ error });
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessageExpenses(true));
    }
};

export default slice.reducer;
