import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { validationRegexOnlyAlpha, validationRegexOnlyTextarea, validationRegexSalary } from "../reUsableForm/regex";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { updateEnquiry, setShowSuccessMessage, setShowErrorMessage } from "./slice/employeeEnquiryFollowupSlice";
import FutureDateField from "../../ReusableFields/DateFields/FutureDateField"
import { fetchDataOnce } from "./slice/viewEmployeeEnquirySlice"
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { fetchDropwDownDataOnce } from "./slice/viewDropdownEmployeeSlice";

const EmployeeEnquiryFollowup = () => {
    const [formData, setFormData] = useState({});
    const [enquiryData, setEnquiryData] = useState({});
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({ name: false });
    const [selectedName, setSelectedName] = useState({ name: '' });

    const apiData = useSelector((state) => state.viewDropdownEmployeeEnquiry.data) || [];
    const data = useSelector((state) => state.employeeEnquiry.data) || [];

    const combinedData = [...(apiData || []), ...data]
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (apiData.length === 0) {
            dispatch(fetchDropwDownDataOnce());
        }
    }, [apiData, dispatch]);

    const showSuccessMsg = useSelector((state) => state.employeeEnquiryFollowUp.showSuccessMessage);
    const successMsg = useSelector((state) => state.employeeEnquiryFollowUp.successMessage);

    const showErrorMsg = useSelector((state) => state.employeeEnquiryFollowUp.showErrorMessage);
    const errorMsg = useSelector((state) => state.employeeEnquiryFollowUp.errorMessage);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newFormErrors = {
            name: !selectedName,
            dateOfFollowup: !formData.dateOfFollowup,
            salary: !formData.salary,
            roleConfirmation: !formData.roleConfirmation,
            expectedDateOfJoining: !formData.expectedDateOfJoining,
            remark: !formData.remark,
        };

        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);

        const form = event.currentTarget;
        if (hasErrors) {
            setValidated(true);
            return;
        }
        dispatch(updateEnquiry(enquiryData.id, formData));

    };
    const fileInputRef = (null);
    const handleClear = () => {
        setFormData({
            dateOfFollowup: "",
            expectedDateOfJoining: "",
            name: "",
        });
        setFormErrors({
            name: false,
        });
        setValidated(false);
        setSelectedName({ name: "" })
        setShowErrorMessage({});
    }
    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Employee Enquiry follow up</div>
            <Row >
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                    <SearchableDropDownField
                        isSearchable
                        value={selectedName.label}
                        options={combinedData.map((name) => ({ id: name.id, label: name.name, value: name.id }))}
                        onChange={(selectedOption) => {
                            setEnquiryData({

                                ...enquiryData, name: selectedOption.label,
                                id: selectedOption.id,
                            });
                            setSelectedName({ id: selectedOption.id, name: selectedOption.label })
                            setFormErrors({ ...formErrors, name: false });
                        }}
                        placeholder="Select Name"
                    />
                    {formErrors.name && (
                        <div className="error-message">
                            Please Select Name.
                        </div>
                    )}

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <FutureDateField
                        label="Date of Followup"
                        type="date"
                        required
                        placeholder="Date of Followup"
                        value={formData.dateOfFollowup}
                        onChange={(date) => {
                            setFormData({ ...formData, dateOfFollowup: date });
                        }}
                        validated={validated}
                        feedbackMessage="Please enter a date"
                        validationRegex={validationRegexOnlyAlpha}
                        validationErrorMessage="Please enter a valid date"
                    />
                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Expected Salary"
                        value={formData.salary}
                        onChange={(event) => {
                            setFormData({ ...formData, "salary": Number(event.target.value) });
                        }}
                        validated={validated}
                        feedbackMessage="please enter salary"
                        validationRegex={validationRegexSalary}
                        validationErrorMessage="please enter valid Salary"
                    />

                </Col>
            </Row>
            <Row >
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Role Confirmation"
                        value={formData.roleConfirmation}
                        onChange={(event) => {
                            setFormData({ ...formData, "roleConfirmation": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Role Confirmation"
                        validationRegex={validationRegexOnlyAlpha}
                        validationErrorMessage="please enter valid Role Confirmation"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <FutureDateField
                        type="date"
                        required
                        placeholder="Expected DOJ"
                        value={formData.expectedDateOfJoining}
                        onChange={(date) => {
                            setFormData({ ...formData, expectedDateOfJoining: date });
                        }}
                        validated={validated}
                        feedbackMessage="Please enter a date"
                        validationRegex={validationRegexOnlyAlpha}
                        validationErrorMessage="Please enter a valid date"
                    />
                </Col>
                <Col xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        name="remark"
                        textType="Alphabet"
                        value={formData.remark}
                        onChange={(event) => { setFormData({ ...formData, remark: event.target.value }) }}
                        required
                        placeholder="Remarks*"
                        validated={validated}
                        feedbackMessage="Please enter remarks."
                        validationRegex={validationRegexOnlyTextarea}
                        validationErrorMessage="Please enter a valid remarks."
                    />

                </Col>
            </Row>
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1%",
                    marginTop: "5%"
                }}
            >
                <button
                    className="clear-button-figma-div"
                    type="button"
                    onClick={handleClear}
                >
                    Clear
                </button>
                <button className="save-button-figma-div" type="submit">
                    Submit
                </button>
            </div>
        </Form>
    )
}
export default EmployeeEnquiryFollowup;
