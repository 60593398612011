import React from "react";
import Select from "react-select";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    display: "flex",
    // height: "52px",
    color: "#5E6366",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "8px 36px 8px 16px",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: "1.5",
    gap: "10px",
    alignSelf: "stretch",
    backgroundColor: "#EFF1F999",
    borderRadius: "8px",
    border: "none",
  }),
};
const SearchableDropDownField = ({
  options,
  validated,
  value,
  onChange,
  placeholder,
  clearable = false,
}) => {
  return (
    <div>
      <Select
        className={`dropdown ${validated && "errorBorder"}`}
        isSearchable
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}

        // styles={customStyles}
        // styles={{ menu: (provided) => ({ ...provided, maxHeight: '200px' }) }}
      />
      {/* {
                clearable && value && (
                    <button onClick={() => onChange(null)}>Clear</button>
                )
            } */}
    </div>
  );
};

export default SearchableDropDownField;
