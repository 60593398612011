import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentsFeeCollections } from './slice/studentFeeGet';
import TableView from "../reUsableForm/table/table4";
import { Col, Form, Row } from "react-bootstrap";
import SelectDropdown from '../SelectDropdown';
import { fetchStudentDataOnce } from './viewStudentSlicer';
import { postFeeRequest, setShowSuccessMessage, setShowErrorMessage } from './slice/studentFeePost';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { useNavigate } from "react-router-dom";
import { API_PATH } from '../../Api/api';
import TextInputField from '../reUsableForm/textInputField';
import { amountPattern, amountWithDecimalPattern, validationRegexOnlyAlpha } from '../reUsableForm/regex';


const StudentFeeCollection = () => {
  const dispatch = useDispatch();
  const feeCollections = useSelector((state) => state.studentsFeeCollections.data) || [];
  const isLoading = useSelector((state) => state.studentsFeeCollections.isLoading);
  const hasError = useSelector((state) => state.studentsFeeCollections.hasError);
  const postStudentData = useSelector((state) => state.addStudent.data) || [];
  const getStudentData = useSelector((state) => state.viewStudent.data) || [];

  const studentData = [...(getStudentData || []), ...postStudentData]
  const [submit, setSubmit] = useState(false);
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const [formData, setFormData] = useState({
    studentClassId: "", studentName: '', classId: '', remark: ''
  });
  const [validated, setValidated] = useState(false);

  const [FeeShowErrorMsg, setFeeShowErrorMsg] = useState(false);
  const [FeeErrorMsg, setFeeErrorMsg] = useState("");


  const showSuccessMsg = useSelector((state) => state.studentFeePost.showSuccessMessage);
  const successMsg = useSelector((state) => state.studentFeePost.successMessage);

  const showErrorMsg = useSelector((state) => state.studentFeePost.showErrorMessage);
  const errorMsg = useSelector((state) => state.studentFeePost.errorMessage);


  useEffect(() => {
    if (formData.classId) {
      dispatch(fetchStudentsFeeCollections(formData.classId, FranchiseeSchoolId));
    }
  }, [dispatch, formData.classId]);

  useEffect(() => {
    dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
  }, [dispatch, FranchiseeSchoolId]);

  const [selectedRows, setSelectedRows] = useState({});
  const [selectRows, setSelectRows] = useState({});
  const [paid, setPaid] = useState({});
  const [unpaid, setUnpaid] = useState({});
  const [checkboxValidation, setCheckboxValidation] = useState(false);

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectRows(prevState => ({
      ...prevState,
      [row.term]: {
        ...prevState[row.term],
        isChecked: isChecked,
        paidAmount: isChecked ? (prevState[row.term]?.paidAmount || row.amount) : 0 // Keep the existing paidAmount if checked, otherwise set to 0
      }
    }));
    setSelectedRows(prevState => ({
      ...prevState,
      [row.term]: {
        ...prevState[row.term],
        isChecked: isChecked,
        paidAmount: isChecked ? (prevState[row.term]?.paidAmount || row.amount) : 0 // Keep the existing paidAmount if checked, otherwise set to 0
      }
    }));
  };



  const shouldDisableSubmit = () => {
    const checkedCount = Object.values(selectedRows).filter((row) => row.isChecked).length;
    const rowCount = feeCollections?.feeHead?.dueDates.length;
    if (checkedCount === rowCount && Object.keys(selectRows).length === 0) {
      return true;
    }
    if (
      Object.keys(selectRows).length === 0 || Object.keys(selectRows).length > 0) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true);
    
    const uncheckedBoxes = Object.keys(selectRows).filter(term => selectRows[term].isChecked);
    if (uncheckedBoxes.length === 0) {
      const newCheckboxValidation = {};
      uncheckedBoxes.forEach(term => {
        newCheckboxValidation[term] = true; // Set validation to true for unchecked boxes
      });
      setCheckboxValidation(true);
      {
        setFeeShowErrorMsg(true);
        setFeeErrorMsg("select at least one checkbox");
      }
      setSubmit(false);
      return; // Prevent form submission if there are unchecked boxes
    }
    const selectedTerms = Object.entries(selectRows)
      .filter(([term, data]) => data.isChecked)
      .map(([term, data]) => ({ term, ...data }));
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const feePayments = feeCollections.feeHead.dueDates
      .filter(date => selectedTerms.some(term => term.term === date.term))
      .map(date => {
        const selectedTerm = selectedTerms.find(term => term.term === date.term);
        return {
          term: date.term,
          dueDate: date.date,
          amountDue: date.amount,
          paidAmount: selectedTerm ? selectedTerm.paidAmount : 0,
          isPaid: selectedTerm.isChecked
        };
       
      });
      
      
      const payload = {
        studentId: formData.studentClassId,
        paidDate: getCurrentDateTime(),
        classId: formData.classId,
        franchiseeSchoolFeeId: feeCollections.id,
        feePayments: feePayments,
        totalDue:totalPaidAmount,
        received:amountPaid,
        balance:balanceAmount,
        discount:selectDiscount,
        reason:formData.remark,
        netDue:netDue,
        hasFullyPaid: balanceAmount === 0 ? true : false
  
  
      };
      dispatch(postFeeRequest(payload));
  
  };
 
  const columns = [
    { field: "term", header: "Term" },
    { field: "date", header: "Date" },
    { field: "amount", header: "Amount" },
    {
      field: 'checkBox', header: "checkBox",
      render: (row) => (
        <input
          type="checkbox"
          style={{ width: '5rem', height: '1.1rem', backgroundColor: 'red' }}
          checked={selectedRows[row.term]?.isChecked || false}
          onChange={(event) => handleCheckboxChange(event, row)}
          disabled={paid[row.term]?.isChecked}
        />
      ),
    }

  ];
  const handleClear = (e) => {
    setFormData({
    });
    setValidated(false);
  };
  useEffect(() => {
    if (formData.studentClassId && formData.classId) {
      const apiUrl = API_PATH + `/student/${formData.studentClassId}/class/${formData.classId}/fees`;
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const paidTerms = {};
          const unpaidTerms = {};
          data.forEach(payment => {
            if (payment.isPaid) {
              paidTerms[payment.term] = {
                paidAmount: payment.paidAmount,
                isChecked: payment.isPaid
              };
            } else {
              unpaidTerms[payment.term] = {
                paidAmount: payment.paidAmount,
                isChecked: payment.isPaid
              };
            }
          });
          setSelectedRows(paidTerms);
          setPaid(paidTerms);
          setUnpaid(unpaidTerms); // Assuming you have a state variable named 'unpaid' to store unpaid terms
        })
        .catch(error => console.error(error));
    }
    
  },  [formData.studentClassId && formData.classId]);
  
  const navigate = useNavigate()
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
    navigate("/ViewStudentFee")
    // window.location.reload();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };


  const calculateTotalPaidAmount = (selectedRows) => {
    let totalPaidAmount = 0;
    Object.values(selectedRows).forEach((term) => {
      if (term.isChecked && term.paidAmount) {
        totalPaidAmount += parseFloat(term.paidAmount);
      }
    });

    return totalPaidAmount;
  };

  const totalPaidAmount = calculateTotalPaidAmount(selectRows);


  const [totalAmount, setTotalAmount] = useState(totalPaidAmount);
  const [amountPaid, setAmountPaid] = useState()
  const [balanceAmount, setBalanceAmount] = useState()
  const [netDue, setNetDue] = useState(totalPaidAmount);
  const [selectDiscount, setSelectDiscount] = useState()
  const handleDiscount = (event) => {
    setSelectDiscount(event.target.value)
  }

  useEffect(() => {
    if (totalPaidAmount) {
      setTotalAmount(totalPaidAmount)
      setNetDue(totalPaidAmount)
      setBalanceAmount(totalPaidAmount)
    }

  }, [totalPaidAmount])

  useEffect(() => {
    if (selectDiscount) {
      const totalAmount = Number(totalPaidAmount) - Number(selectDiscount)
      setTotalAmount(totalAmount)
      setBalanceAmount(Number(totalAmount) - Number(amountPaid))
      const netDue = Number(totalPaidAmount) - Number(selectDiscount)
      setNetDue(netDue)
      setBalanceAmount(Number(netDue) - Number(amountPaid))
    }
  }, [selectDiscount, totalPaidAmount])


  const handleAmountPaid = (event) => {
    setAmountPaid(event.target.value)
  }



  useEffect(() => {
    if (amountPaid) {
    
      setBalanceAmount(Number(netDue) - Number(amountPaid))
    
    }

  }, [amountPaid, netDue])
  

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div>

          <h2>Fee Collection</h2>
          <Row>
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                isSearchable
                required
                value={formData.studentClassId ? { label: formData.studentName } : null}
                options={studentData.map((option, index) => ({
                  id: option.id,
                  value: option.studentEnrollments[0].studentRollNumber,
                  label: option.name,
                  classId: option.studentEnrollments[0].classesClassSection.classId
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    studentClassId: Number(selectedOption.id),
                    studentName: selectedOption.label,
                    classId: selectedOption.classId
                  });
                  setSelectedRows({});
                }}
                placeholder="Student Name"
                feedbackMessage="Please select student name."
              />
            </Col>
          </Row>

          {feeCollections.length === 0 ? (
            <p></p>
          ) : (
            <Col>
              {/* <label><h6>Fee Head: {feeCollections?.feeHead?.name}</h6></label> */}

            </Col>
          )}

          {isLoading ? (
            <p>Loading...</p>
          ) : feeCollections.length === 0 ? (
            <p>Select Student.</p>
          ) : (


            <TableView
              data={feeCollections?.feeHead?.dueDates || []}
              columns={columns}
              hover={true}
              striped={true}
            />
          )}


          <Row style={{ marginTop: 30 }}>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Total Due</Form.Label>
              <TextInputField
                name="Total Due"
                placeholder="Total Due"
                textType="Alphabet"
                disabled
                validated={validated}
                value={totalPaidAmount}
                feedbackMessage="Please enter first name."
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter valid Total Due."
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Discount</Form.Label>
              <TextInputField
                name="Monthly Fee"
                textType="name"
                value={selectDiscount}
                onChange={(event) => handleDiscount(event)}
                required
                placeholder="Discount"
                validated={validated}
                maxLength={25}
                feedbackMessage="Please enter Monthly Fee."
                validationRegex={amountWithDecimalPattern}
                validationErrorMessage="Please enter Monthly Fee."
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Remarks</Form.Label>
              <TextInputField
                name="Remarks"
                placeholder="Remarks"
                textType="text"
                validated={validated}
                value={formData.remark || ""}
                onChange={(event) => setFormData({ ...formData, remark: event.target.value })}
                feedbackMessage="Please enter Remarks."
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter valid Remarks."
              />

            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Due</Form.Label>
              <TextInputField
                name="Total Due"
                placeholder="Total Due"
                textType="Alphabet"
                disabled
                validated={validated}
                value={netDue}
                feedbackMessage="Please enter first name."
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter valid Total Due."
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Amount Paid</Form.Label>
              <TextInputField
                name="Monthly Fee"
                textType="name"
                value={amountPaid}
                onChange={(event) => handleAmountPaid(event)}
                required
                placeholder="Amount Paid"
                validated={validated}
                maxLength={25}
                feedbackMessage="Please enter Monthly Fee."
                validationRegex={amountWithDecimalPattern}
                validationErrorMessage="Please enter Monthly Fee."
              />
            </Col>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Label className="lableName">Balance</Form.Label>
              <TextInputField
                name="Total Due"
                placeholder="Balance"
                textType="Alphabet"
                disabled
                validated={validated}
                value={balanceAmount}
                feedbackMessage="Please enter first name."
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter valid Total Due."
              />
            </Col>
          </Row>
        </div>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup
          show={FeeShowErrorMsg}
          onClose={() => {
            setFeeShowErrorMsg(false);
          }}
          errorMessage={FeeErrorMsg}
        />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        > 
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="save-button-figma-div"
            type="submit"
            disabled={submit || shouldDisableSubmit()}
          >
            {submit ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
    </>
  );
};
export default StudentFeeCollection;
