//Author: Chandrashekar
//date: 13-10-23
import React from 'react'
import './Header.css'
import { Typography } from "antd";
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Popover, Button } from 'antd';
import { Profile } from '../authentication/profile';

const PopoverText = styled(Popover)`
  :hover {
    background-color: #112F6E; 
    color:#ffffff;
    
  }
  .cursor{
    cursor:default;
  }

`;

const Header = () => {

    const content = (
        <div className='content-popup'>
            {/* <center><div>Username</div></center> */}
            <center><div className='changepswd'>Change Password</div></center>
            <center><div className='logout'>Logout</div></center>
        </div>
    );
    return (

        <>
            <div className='h-card'>
                <div className='h-main-div'>
                    <div className='second-div'>
                        {/* <span><img src='../../images/BellOutline.svg' /><sup>2</sup></span> */}

                        <Profile />

                    </div>
                </div>
            </div>
        </>

    )
}

export default Header;