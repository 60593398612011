import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "ViewEmployee",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchEmployeeEnquiryFollowupStart: (state, action) => {
            state.isLoading = action.payload;
            state.hasError = false;
        },
        fetchEmployeeEnquiryFollowupSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchEmployeeEnquiryFollowupFailure: (state, action) => {
            state.isLoading = false;
            state.hasError = action.payload;
        },
        setEnquiryData: (state, action) => {
            state.isLoading = false;
            const newData = action.payload;

            const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

            if (existingObjectIndex !== -1) {
                state.data[existingObjectIndex] = newData;
            }

        }

    }
});
export const { fetchEmployeeEnquiryFollowupStart, fetchEmployeeEnquiryFollowupFailure, fetchEmployeeEnquiryFollowupSuccess, setEnquiryData } = slice.actions;

export const fetchEmployeeEnquiryFollowupView = () => async (dispatch, getEmployeeEnquiryFollowupView) => {
    const { data, isLoading } = getEmployeeEnquiryFollowupView().ViewEmployee;
    const tokenId = token();

    const franchiseeSchoolId = parseInt(sessionStorage.getItem('franchiseeSchoolId'));
    if (data === null && !isLoading) {
        dispatch(fetchEmployeeEnquiryFollowupStart());
        try {
            const response = await fetch(API_PATH + `/employee-enquires?franchiseeSchoolId=${franchiseeSchoolId}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${tokenId}`
                }
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data1 = await response.json();
            // dispatch(setEmployeeFollowUpFirst([]))
            dispatch(fetchEmployeeEnquiryFollowupSuccess(data1));
        } catch (error) {
            dispatch(fetchEmployeeEnquiryFollowupFailure())
        }
    }
}
export default slice.reducer;
