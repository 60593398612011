import React, { useEffect, useState } from 'react';
import Table from '../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreateLaneView } from './createLineViewSlicer';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { setShowErrorMessageVehicle, setShowSuccessMessageVehicle } from './createLineViewEditSlicer';

const CreateLaneView = () => {

    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});

    const apiData = useSelector((state) => state.createLane.data) || [];

    const postData = useSelector((state) => state.postLaneSlicer.postLane.data) || [];
    const putData = useSelector((state) => state.addCreateLaneEdit.data) || [];

    const showSuccessMsg = useSelector((state) => state.addCreateLaneEdit.showSuccessMessage);
    const successMsg = useSelector((state) => state.addCreateLaneEdit.successMessage);

    const showErrorMsg = useSelector((state) => state.addCreateLaneEdit.showErrorMessage);
    const errorMsg = useSelector((state) => state.addCreateLaneEdit.errorMessage);


    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessageVehicle(false));
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCreateLaneView());
    }, [dispatch]);


    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "title", header: "Title" },
        { field: "from", header: "From" },
        { field: "stops", header: "Stops" },
        { field: "to", header: "To" },
        { field: "createLaneEdit", header: "Edit" },
    ];

    const combinedData = [...(apiData || []), ...postData];

    const newObj = putData

    function replaceObjects(array, newObjects) {
        return array.map(obj => {
            const replacement = newObjects.find(newObj => newObj.id === obj.id);
            return replacement ? replacement : obj;
        });
    }

    const combinedData1 = replaceObjects(combinedData, newObj);


    const newModifiedData = combinedData1.map((postItem, index) => {
        return {
            index: index + 1,
            stops: postItem.stops,
            id: postItem.id,
            from: postItem.from,
            to: postItem.to,
            title: postItem.title,
        };
    });

    const uniqueNewModifiedData = newModifiedData.filter(
        (obj, index, self) => self.findIndex(item => item.id === obj.id) === index
    );

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessageVehicle(false));
    };

    return (
        <div>
            <h4 className='content-header'>View Lanes</h4>
            {uniqueNewModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : uniqueNewModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={uniqueNewModifiedData} columns={columns} hover={true} showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    editedData={editedData}
                    setEditedData={setEditedData} />
            )}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        </div>
    );
};

export default CreateLaneView;
