import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { setTransportationFee } from './transportationSlice';


const slice = createSlice(
    {
        name: 'viewTransportationFeeCollection',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            }
        },
    },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = slice.actions;

export const fetchDataOnce = () => async (dispatch, getState) => {

    const { data, isLoading } = getState().viewTransportationFeeCollection;
    const tokenId = token();

    if (data === null && !isLoading) {
        // If data is null and the API is not loading, trigger the API request
        dispatch(fetchDataStart());

        try {
            const response = await fetch(API_PATH + '/transportation_fees',
                {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${tokenId}`
                    }
                });

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data1 = await response.json();
            dispatch(setTransportationFee([]))
            dispatch(fetchDataSuccess(data1));
        } catch (error) {
            dispatch(fetchDataFailure());
        }
    }

}

export default slice.reducer;
