import React from "react";
import Select from "react-select";
import { Form, Col } from "react-bootstrap";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    color: "#A7A7A7",
    display: "flex",
    // width: "248px",
    height: "42px",
    color: "#5E6366",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    padding: "-0.625rem 0.75rem 0.375rem 0.75rem",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: "normal",
    gap: "10px",
    alignSelf: "stretch",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    border: "0.5px solid #A7A7A7",
    zIndex: 1000,
  }),
};

const SearchableDropDown = ({
  name,
  value,
  onChange,
  placeholder,
  options,
  label,
  feedbackMessage,
  validated,
  colSize,
}) => {
  const selectedOption = options.length > 0 ? options.find((option) => option.value === value) : [];

  // Determine if the input is invalid based on the 'validated' prop
  const isInvalid = validated && !selectedOption;

  return (
    <>
      <Form.Group>
        {label && <Form.Label className="label-style">{label}</Form.Label>}

        <Select
          name={name}
          required
          className="search-dropdown"
          isSearchable
          validated={validated}
          value={selectedOption || null}
          onChange={(selectedOption) =>
            onChange(selectedOption ? selectedOption : '')
          }
          options={options}
          placeholder={placeholder}

          styles={customStyles}
          menuPlacement="auto" // Set menuPlacement to 'auto' to enable scrolling
          maxMenuHeight={200} // Set max height for the menu to enable scrolling after 6 options
        />

        {isInvalid && feedbackMessage && (
          <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default SearchableDropDown;

