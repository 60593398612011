import { useLayoutEffect, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeAreas, getEmployeeCities, getEmployeeQualifications, getEmployeeStates } from '../teacherSlicers/allRequests';

const useCitiesState = () => {
    const dispatch = useDispatch();

    const employeeQualificationsObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeQualifications);
    const getEmployeeStatesObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeStates);
    const getEmployeeCitiesObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeCities);
    const getEmployeeAreasObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeAreas);

    const employeeQualificationsLoading = employeeQualificationsObject?.loading;
    const employeeQualifications = employeeQualificationsObject?.data;

    const getEmployeeStatesData = getEmployeeStatesObject?.data;
    const getEmployeeCitiesData = getEmployeeCitiesObject?.data;
    const getEmployeeAreasData = getEmployeeAreasObject?.data;

    useLayoutEffect(() => {
        if (employeeQualificationsLoading === 'idle') {
            dispatch(getEmployeeQualifications())
        }
    }, [dispatch, employeeQualificationsLoading])
    useLayoutEffect(() => {
        if (getEmployeeStatesObject?.loading === 'idle') {
            dispatch(getEmployeeStates())
        }
    }, [dispatch, getEmployeeStatesObject?.loading])
    useLayoutEffect(() => {
        if (getEmployeeCitiesObject?.loading === 'idle') {
            dispatch(getEmployeeCities())
        }
    }, [dispatch, getEmployeeCitiesObject?.loading])
    useLayoutEffect(() => {
        if (getEmployeeAreasObject?.loading === 'idle') {
            dispatch(getEmployeeAreas())
        }
    }, [dispatch, getEmployeeAreasObject?.loading])
    const qualificationsOptions = employeeQualifications?.length > 0 ? employeeQualifications?.map((e) => { return { id: e.id, label: e.name, value: e.name } }) : [];
    const statesOptions = getEmployeeStatesData?.length > 0 ? getEmployeeStatesData?.map((e) => { return { id: e.id, label: e.name, value: e.name } }) : [];
    const citiesOptions = getEmployeeCitiesData?.length > 0 ? getEmployeeCitiesData?.map((e) => { return { id: e.id, label: e.name, value: e.name } }) : [];
    const areasOptions = getEmployeeAreasData?.length > 0 ? getEmployeeAreasData?.map((e) => { return { id: e.id, label: e.name, value: e.name } }) : [];
    const designationOptions=[{ label: 'teacher', value: 'teacher' }, { label: 'accountant', value: 'accountant' }, { label: 'principal', value: 'principal' }, { label: 'vice principal', value: 'vice principal' },{ label: 'driver', value: 'driver' },{ label: 'maid', value: 'maid' }]

    return [qualificationsOptions, statesOptions, citiesOptions,designationOptions,areasOptions]
}

// export const {qualificationsOptions, statesOptions, citiesOptions,designationOptions} = useCitiesState();
export default useCitiesState;

