import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';
import { setFranchiseData } from './franchiseFollowUpSlicer';

const slice = createSlice(
    {
        name: 'franchiseFollowUp',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            successMessageFranchise: '',
            showSuccessMessageFranchise: false,
            errorMessageFranchise: '',
            showErrorMessageFranchise: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state?.data, ...action.payload] : action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessageFranchise: (state, action) => {
                state.successMessageFranchise = action.payload;
            },
            setShowSuccessMessageFranchise: (state, action) => {
                state.showSuccessMessageFranchise = action.payload;
            },
            setErrorMessageFranchise: (state, action) => {
                state.errorMessageFranchise = action.payload;
            },
            setShowErrorMessageFranchise: (state, action) => {
                state.showErrorMessageFranchise = action.payload;
            },
        },
    },

);

export const { fetchDataFailure, fetchDataStart, fetchDataSuccess, setSuccessMessageFranchise, setShowSuccessMessageFranchise, setErrorMessageFranchise, setShowErrorMessageFranchise, } = slice.actions;

export const updateFranchise = (franchiseId, updateFranchiseData) => async (dispatch) => {
    dispatch(fetchDataStart());
    const tokenId = token();
    try {
        const response = await fetchWithAuthPost(API_PATH + `/franchise-enquiry/${franchiseId}`, "PUT", tokenId, updateFranchiseData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchDataSuccess([responseData.franchiseEnquiryUpdate]));
            // dispatch(setFranchiseData([responseData.franchiseEnquiryUpdate]));
            dispatch(setSuccessMessageFranchise("Successfull!"));
            dispatch(setShowSuccessMessageFranchise(true));
        }
    } catch (error) {
        console.error("Error", error);
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessageFranchise(errMessage));
        dispatch(setShowErrorMessageFranchise(true));
    }
}

export default slice.reducer;