// ActivitiesAssessmentModal.js
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const ActivitiesAssessmentModal = ({ show, onHide, activitiesAssessments }) => {
  return (
    <Modal show={show} onHide={onHide} className="modal" size="x2" style={{marginTop: '5rem'}}>
      <Modal.Header closeButton>
        <Modal.Title>Activities Assessments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", overflowX: "auto" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Activities Given</th>
              <th>Activities Submitted</th>
              <th>Remarks</th>
            </tr>
          </thead>
          {activitiesAssessments.length > 0 ? (
          <tbody>
            {activitiesAssessments.map((assessment, index) => (
              <tr key={index}>
                <td>{assessment.activitiesGiven}</td>
                <td>{assessment.activitiesSubmitted}</td>
                <td>{assessment.remarks}</td>
              </tr>
            ))}
          </tbody>
          ) : (
            <center style={{marginLeft: '75px'}}>
              <p>No Data</p>
            </center>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivitiesAssessmentModal;
