import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
const slice = createSlice(
    {
        name: 'addAssessmentParameter',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchAssessmentParameterData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchAssessmentParameterSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchAssessmentParameterFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    }


);

export const { fetchAssessmentParameterData, fetchAssessmentParameterSuccess, fetchAssessmentParameterFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;


export const PostAssessmentParameterRequest = (postData) => async (dispatch) => {
    dispatch(fetchAssessmentParameterData());

    const tokenId = token();
    try {
        const response = await fetchWithAuthPost(API_PATH + '/assessment-parameter', 'POST', tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchAssessmentParameterSuccess([responseData]));

            dispatch(setSuccessMessage("Successfully Created!"));
            dispatch(setShowSuccessMessage(true));

        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));

    }
};


export default slice.reducer;
