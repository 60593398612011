/*
    authors: Rajeev
    date: 23-10-2023
    update:23-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import '../StudentDetails/Student.css'; // Import your custom CSS file
import TextInputField from '../reUsableForm/textInputField';
import { validationRegexAddress, validationRegexOnlyAlpha1, validationRegexOnlyTextarea, } from "../reUsableForm/regex";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import SelectDropdown from '../SelectDropdown';
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentDataOnce } from './slicer/viewAssessmentSlicer';
import { PostAssessmentParameterRequest, setShowSuccessMessage, setShowErrorMessage } from './slicer/addAssessmentParameterSlicer';
import { fetchDataOnce } from '../Classes/slice/viewClassSlice';
import AssessmentParameterSkillView from './assessmentParameterSkillView';

const AssessmentParameters = () => {

  const [validated, setValidated] = useState(false);
  const [dropDowns, setDropDowns] = useState({})
  const [formData, setFormData] = useState({

  });
  const [selectedSection, setSelectedSection] = useState("");
  const [availableSkills, setAvailableSkills] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const selectedFranchiseeSchoolId = Number(franchiseeSchoolId);

  const [selectedClassId, setSelectedClassId] = useState('')
  const [selectedSectionId, setSelectedSectionId] = useState("")
  const [selectedSubjectId, setSelectedSubjectId] = useState("")

  const showSuccessMsg = useSelector((state) => state.addAssessmentParameter.showSuccessMessage);
  const successMsg = useSelector((state) => state.addAssessmentParameter.successMessage);

  const showErrorMsg = useSelector((state) => state.addAssessmentParameter.showErrorMessage);
  const errorMsg = useSelector((state) => state.addAssessmentParameter.errorMessage);
  const dispatch = useDispatch();


  const [formErrors, setFormErrors] = useState({
    class: false, skill: false, name: false
  })


  const postClassData = useSelector((state) => state.addClass.data) || [];
  const getclassData = useSelector((state) => state.viewClass.data) || [];

  const getAssessmentSkills = useSelector((state) => state.addAssessment.data) || [];

  const classData = [...(getclassData || []), ...postClassData]

  getAssessmentSkills.forEach((postItem) => {
    const matchingClassIndex = classData.findIndex((classItem) => classItem.id === postItem.classId);
    if (matchingClassIndex !== -1) {
      classData[matchingClassIndex] = {
        ...classData[matchingClassIndex],
        assessmentsSkills: [
          ...(classData[matchingClassIndex].assessmentsSkills || []),
          {
            id: postItem.id,
            classId: postItem.classId,
            name: postItem.name,
          },
        ],
      };
    }
  });


  useEffect(() => {
    if (classData.length === 0) {
      dispatch(fetchDataOnce());
    }
  }, [classData, dispatch]);


  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });

    if (name === 'class') {
      const selectedClass = classData.find((item) => item.id === value);

      // Use map to extract names and ids from assessmentsSkills array
      const skillsData = selectedClass ? selectedClass.assessmentsSkills.map(skill => ({ id: skill.id, name: skill.name })) : [];

      setAvailableSkills(skillsData);
    }
  };





  const handleSubmit = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    const newFormErrors = {
      class: !dropDowns.class,
      skill: !dropDowns.skill,
      name: !formData.name

    }
    setFormErrors(newFormErrors);

    const hasErrors = Object.values(newFormErrors).some(error => error);
    setFormErrors(newFormErrors)

    if (hasErrors) {
      setValidated(true);
      return;
    }
    const sendData = {
      // ...formData,
      classId: formData.class,
      name: formData.name,
      assessmentSkillId: formData.assessmentSkillId


    };


    dispatch(PostAssessmentParameterRequest(sendData));

  }




  const handleClear = () => {
    setFormData({

    });
    setValidated(false)
    setFormErrors({})
    setDropDowns({ dropDowns: "" })
    setSelectedClassId('')
    setSelectedSectionId("")
    setSelectedSubjectId("")

  }

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };


  return (
    <>


      <Form noValidate validated={validated} onSubmit={handleSubmit}>


        <div className="content-header">Assessment Parameters</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : null
              }
              options={classData.map(item => ({
                value: item.id,
                label: item.name,
                classId: item.id,
              }))}
              onChange={(selectedOption) => {
                handleChange('class', selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              placeholder="Select class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              value={dropDowns.skill ? { value: dropDowns.skill, label: dropDowns.skill } : null}
              className="input-style"
              options={availableSkills.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name
              }))}
              onChange={(selectedOption) => {
                handleChange('skill', selectedOption.label);

                setFormData({ ...formData, assessmentSkillId: selectedOption.id })
                setDropDowns({ ...dropDowns, skill: selectedOption.value })
                setFormErrors({ ...formErrors, skill: false });
              }
              }
              placeholder="Select a skill"
            />
            {formErrors.skill && (
              <div className="error-message">
                Please Select skill .
              </div>
            )}
          </Col>

          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">


            <TextInputField
              name="Parameter"
              placeholder="Parameter*"
              textType="Alphabet"
              required
              value={formData.name || ""}
              onChange={(event) => {
                setFormData({ ...formData, name: (event.target.value) })

              }}

              feedbackMessage="Please enter parameter."
              validated={validated}
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter a valid parameter."
            />


          </Col>
        </Row>


        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <div>
        <AssessmentParameterSkillView />
      </div>

    </>
  );
};


export default AssessmentParameters;