
import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
// import { fetchDataOnceProgram } from "./slice/viewProgramSlice"
import { fetchTimingDataOnce } from "./slicer/viewTimingSlicer";


const ViewTimings = () => {

  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "fromTime", header: "FromTime"},
    { field: "toTime", header: "ToTime"},
    { field: "title", header: "Title"},
    ];
 
  const apiData = useSelector((state) => state.viewTimings.data) || [];
  const postData = useSelector((state) => state.addTimings.data) || [];

  const combinedData = [...(apiData || []), ...postData];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTimingDataOnce());
  }, [dispatch]);

const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1,
    toTime: convertTo12HourFormat(row.toTime)
  }));
  
  function convertTo12HourFormat(timeString) {
    const [hours, minutes] = timeString.split(':');
    let period = 'AM';
    let hours12 = parseInt(hours, 10);
  
    if (hours12 === 0) {
      hours12 = 12;
    } else if (hours12 >= 12) {
      period = 'PM';
      if (hours12 > 12) { 
        hours12 -= 12;
      }
    }
  
    return `${hours12}:${minutes} ${period}`;
  }
  
  return (
    <>
      {/* <div className="content-header">View Program</div> */}
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (

          <Table data={processedData} columns={columns} hover={true} striped={true} />
        )}
    </>
  );
}
export default ViewTimings;