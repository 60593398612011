import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { DisabledByDefault } from '@mui/icons-material';
import { setSubjectEmpty } from './addSubjectSlice';


const slice = createSlice(
  {
    name: 'viewSubject',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchSubjectData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchSubjectSuccess: (state, action) => {
        state.isLoading = false;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchSubjectFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },

);

export const { fetchSubjectData, fetchSubjectSuccess, fetchSubjectFailure } = slice.actions;

export const fetchSubjectDataOnce = () => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewSubject;
  const tokenId = token();

  if (data === null && !isLoading) {

    dispatch(fetchSubjectData());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + '/subjects', {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed'); // You can customize error handling
      }

      const data1 = await response.json();
      dispatch(setSubjectEmpty([]))
      dispatch(fetchSubjectSuccess(data1));
    } catch (error) {
      dispatch(fetchSubjectFailure());
    }
  }

}

export default slice.reducer;
