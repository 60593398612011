


import { createSlice } from '@reduxjs/toolkit';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';


const slice = createSlice(
    {
        name: 'schoolCreation',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchSchoolCreation: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchSchoolCreationSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchSchoolCreationFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            fetchSchoolCreationPost: (state, action) => {
                state.isLoading = false;
                state.data = action.payload

            }
        },
    }


);

export const { fetchSchoolCreation, fetchSchoolCreationSuccess, fetchSchoolCreationFailure, setSuccessMessage,
    setShowSuccessMessage, setErrorMessage, setShowErrorMessage, fetchSchoolCreationPost } = slice.actions;


export const  PostSchoolCreation = (postData) => async (dispatch) => {
    dispatch(fetchSchoolCreation());
    const tokenId = token();

    try {
        const response = await fetchWithAuthPost(API_PATH + '/franchiseSchool', 'POST', tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchSchoolCreationSuccess([responseData]));

            dispatch(setSuccessMessage("Successfully Created!"));
            dispatch(setShowSuccessMessage(true));

        }
    }
    catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};


export default slice.reducer;
