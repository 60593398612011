import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import TextInputField from "../../ReusableFields/TextFields/TextInputField";
import {
  saveTeacherData,
  setSelectedSection,
  setErrorMessage,
  setShowErrorMessage,
  setSuccessMessage,
  setShowSuccessMessage,
  PostAssignTeacherRequest,
} from "./AssignTeacherToClassSlicer";
import { getEmployeeList } from "../Teachers/teacherSlicers/allRequests";
import { fetchDataOnce } from "../Classes/slice/viewClassSlice";
import { fetchSubjectDataOnce } from "../subject/slice/viewSubjectSlice";
import { validationRegexAlphaNumeric } from "../reUsableForm/regex";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";

const AssignTeacherToClass = () => {
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({
    teacherId: false,
    classId: false,
    sectionId: false,
    subjectId: false,
  });
  const [section, setSection] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [subjects, setSubjects] = useState([]);

  const [formData, setFormData] = useState({
    teacherId: "",
    employeeName: "",
    sectionId: section,
    subjectId: "",
    classId: "",
  });

  const dispatch = useDispatch();
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

  const showSuccessMsg = useSelector(
    (state) => state.assignTeacher.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.assignTeacher.successMessage);

  const showErrorMsg = useSelector(
    (state) => state.assignTeacher.showErrorMessage
  );
  const errorMsg = useSelector((state) => state.assignTeacher.errorMessage);

  const employeeApiData = useSelector(
    (state) => state.employeeSlicer.teachers.getEmployeeViewList.data
  );
  const classesClassSectionData =
    useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length === 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [dispatch, classesClassSectionData]);

  useEffect(() => {
    if (employeeApiData.length === 0) {
      dispatch(getEmployeeList());
    }
  }, [dispatch, employeeApiData]);

  const SelectedClassData = useMemo(() => {
    return classesClassSectionData.find((c) => c.id === selectedClass);
  }, [classesClassSectionData, selectedClass]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const queryParams = new URLSearchParams({
        //   classId: selectedClass,
        //   // franchiseeSchoolId: franchiseeSchoolId(),
        // });

        const url = `${API_PATH}/class/${selectedClass}/subjects`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setSubjects(responseData);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed
      }
    };

    if (selectedClass && token()) {
      fetchData();
    }
  }, [selectedClass, token()]);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [name]: false }));
  };

  const handleClassChange = () => {
    setSelectedSection("");
    setSection("");
    handleChange("sectionId", "");
    handleChange("subjectId", "");

    if (SelectedClassData && SelectedClassData.classSections.length === 0) {
      setSelectedSection(SelectedClassData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange();
  }, [selectedClass]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newFormErrors = {
      teacherId: !formData.teacherId,
      classId: !formData.classId,
      subjectId: !formData.subjectId,
      sectionId: !formData.sectionId,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    setValidated(true);

    if (hasErrors) {
      return;
    }

    try {
      const formatData = {
        teacherId: selectedTeacher.value,
        employeeName: selectedTeacher,
        classId: formData.classId.value,
        sectionId: formData.sectionId.value,
        subjectId:formData.subjectId.value,
        franchiseeSchoolId
      };

      dispatch(PostAssignTeacherRequest(formatData));

    } catch (error) {
      console.error("Failed to save data:", error);
      dispatch(setErrorMessage(error.message));
      dispatch(setShowErrorMessage(true));
    }
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  const clearForm = () => {
    setFormData({
      teacherId: "",
      employeeName: "",
      subjectId: "",
      classId: "",
      sectionId: "",
    });
    setSelectedSection("");
    setSelectedTeacher("");
    setSection("");
    setFormErrors({
      teacherId: false,
      classId: false,
      sectionId: false,
      subjectId: false,
    });
    setValidated(false);
  };

  useEffect(() => {
    if (showSuccessMsg) {
      clearForm();
    }
  }, [showSuccessMsg]);

  return (
    <Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Assign Teacher To Class</div>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={6} lg={3} className="rowCss">
            <SearchableDropDownField
              label="Teacher"
              placeholder="select teacher"
              value={selectedTeacher}
              onChange={(selectedOption) => {
                handleChange("teacherId", selectedOption.value);
                setSelectedTeacher(selectedOption);
              }}
              options={employeeApiData.map((employee) => ({
                value: employee.id,
                name: employee.name,
                label: employee.code,
              }))}
              required
              feedbackMessage="Please select a valid teacher"
            />
            {formErrors.teacherId && (
              <div className="error-message">Please Select teacher.</div>
            )}
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="employeeName"
              placeholder="Employee Name"
              value={selectedTeacher.name || ""}
              onChange={(e) => handleChange("employeeName", e.target.value)}
              validationRegex={validationRegexAlphaNumeric}
              textType="alphanumeric"
              maxLength={25}
              required
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={6} lg={3} className="rowCss">
            <SearchableDropDownField
              label="Class"
              value={formData.classId || ""}
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
              }}
              options={classesClassSectionData.map((classItem) => ({
                value: classItem.id,
                label: classItem.name,
              }))}
              required
              feedbackMessage="Please select a valid class"
            />
            {formErrors.classId && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {selectedClass &&
            SelectedClassData &&
            SelectedClassData.classSections.length > 0 && (
              <Col xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDownField
                  label="Section"
                  value={formData.sectionId || ""}
                  placeholder="select section"
                  onChange={(selectedOption) => {
                    handleChange("sectionId", selectedOption);
                    setSection(selectedOption);
                    setSelectedSection(selectedOption.value);
                  }}
                  options={SelectedClassData.classSections.map((section) => ({
                    key: section.id,
                    value: section.classesclassSection,
                    label: section.name,
                  }))}
                  required
                  feedbackMessage="Please select a valid section"
                />
                {formErrors.sectionId && (
                  <div className="error-message">Please Select Section.</div>
                )}
              </Col>
            )}

          <Col xs={12} md={6} lg={3} className="rowCss">
            <SearchableDropDownField
              label="Subject"
              value={formData.subjectId || ""}
              placeholder="select subject"
              onChange={(selectedOption) =>
                handleChange("subjectId", selectedOption)
              }
              options={subjects.map((subject) => ({
                value: subject.id,
                label: subject.name,
              }))}
              required
              feedbackMessage="Please select a valid subject"
            />
            {formErrors.subjectId && (
              <div className="error-message">Please Select subject.</div>
            )}
          </Col>
        </Row>
        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={clearForm}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </Row>
  );
};

export default AssignTeacherToClass;
