import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../Api/token";
import { API_PATH } from "../../Api/api";
import { responseFormat } from "../Auth/errorResponse";
import { fetchWithAuthPost } from "../Auth/dependency_post";

const slice = createSlice({
  name: "addStudent",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchStudentData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchStudentSuccess: (state, action) => {
      state.isLoading = false;
      state.data = state.data
        ? [...state.data, ...action.payload]
        : action.payload;
    },
    fetchStudentFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
  },
});

export const {
  fetchStudentData,
  fetchStudentSuccess,
  fetchStudentFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
} = slice.actions;

export const postStudentRequest = (postData) => async (dispatch) => {
  dispatch(fetchStudentData());
  const tokenId = token();
  try {
    // Make your POST request here using the fetch function or an HTTP library like Axios
    const response = await fetchWithAuthPost(API_PATH + "/student", "POST", tokenId, postData);
    const responseData = response;
    dispatch(fetchStudentSuccess(responseData));
    dispatch(setSuccessMessage("Successfully Created !"));
    dispatch(setShowSuccessMessage(true));

  } catch (error) {
    dispatch(fetchStudentFailure());
    const errMessage = responseFormat({ error });
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
