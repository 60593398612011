
//export const localPath = 'C:\\Projects\\kidhoodfe\\src\\kidhood-images-and-docs\\'

export const localPath = 'C:\\Users\\chand\\OneDrive\\Desktop\\projectfe\\kidhoodfe\\src\\kidhood-images-and-docs\\'



export const folderPath = localPath + 'vehicles';
export const franchiseOwnerProfile = localPath + 'franchiseOwner\\profile-images';
export const franchiseOwnerDocument = localPath + 'franchiseOwner\\documents';
export const employeeProfilePath = localPath + 'employees\\profile-images';
export const employeeDocumentsPath = localPath + 'employees\\documents';
export const employeeEnquiry = localPath + "employee-enquiry";
export const CoCurriculumActivitiesDocuments = localPath + 'CoCurriculumActivities';
export const studentDocument = localPath + 'students\\documents';
export const studentImages = localPath + 'students\\profile-images';
export const pictureUpload = localPath + 'picturesUpload\\images';
export const guardianDocuments = localPath + 'guardians\\documents';
export const guardianImages = localPath + 'guardians\\profile-images';
export const bonafideDocuments = localPath + 'students\\bonafide-images';




// export const localPath = '/home/digifamo/public_html/src/kidhood-images-and-docs/'

// export const folderPath = localPath + 'vehicles';
// export const franchiseOwnerProfile = localPath + 'franchiseOwner/profile-images';
// export const franchiseOwnerDocument = localPath + 'franchiseOwner/documents';
// export const employeeProfilePath = localPath+'employees/profile-images';
// export const employeeDocumentsPath = localPath+'employees/documents';
// export const employeeEnquiry = localPath+"employee-enquiry";
// export const CoCurriculumActivitiesDocuments = localPath+'CoCurriculumActivities';
// export const studentDocument = localPath+'students/documents';
// export const studentImages = localPath+'students/profile-images';
// export const pictureUpload = localPath + 'picturesUpload/images';
// export const guardianDocuments = localPath + 'guardians/documents';
// export const guardianImages = localPath + 'guardians/profile-images';
// export const bonafideDocuments = localPath + 'students/bonafide-images';
