import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import SelectDropdown from "../SelectDropdown";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import { useDispatch, useSelector } from "react-redux";
import { getParentLoginData } from '../parent/parentLoginSlicer/parentLoginRequests';
import {
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    Button,
    TextField,
    MenuItem,
    Select,
    ButtonBase,
    Tooltip,
} from "@mui/material";

const PtmDatesView = () => {
    const dispatch = useDispatch();
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
    const tokenId = token();
    const roleIds = Number(sessionStorage.getItem("roleId"));
    const teacherId = Number(sessionStorage.getItem('userId'));
    const [classData, setClassData] = useState([]);
    const [formData, setFormData] = useState({ date: '', classId: '' });
    const [formErrors, setFormErrors] = useState({ update: false, ClassActivity: false, class: false, sections: false });
    const [dropDowns, setDropDowns] = useState({ class: '', sections: '' });
    const [selectedSectionId, setSelectedSectionId] = useState('');
    const [selectedClassData, setSelectedClassData] = useState([]);
    const [selectedClass, setSelectedClass] = useState("");
    const [ptmData, setPtmData] = useState([]);  // New state for PTM data

    const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
    const data = parentLoginObject?.data;

    const [formData1, setFormData1] = useState({
        studentId: "",
    });

    useEffect(() => {
        if (roleIds === 5) {
            if (parentLoginObject?.loading === "idle") {
                const parentId = sessionStorage.getItem('userId');

                dispatch(getParentLoginData(parentId));
            }
        }
    }, [dispatch, parentLoginObject?.loading]);

    useEffect(() => {
        if (roleIds === 6) {
            const fetchData = async () => {
                try {
                    const url = `${API_PATH}/franchisee-school/${franchiseeSchoolId}/teachers/${teacherId}/classes`;
                    const response = await fetch(url, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${tokenId}`,
                        },
                    });
                    if (!response.ok) {
                        throw new Error(`Failed to fetch classes data. Status: ${response.status}`);
                    }
                    const result = await response.json();
                    setClassData(result);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
    }, [franchiseeSchoolId, teacherId, tokenId, roleIds]);

    useEffect(() => {
        if (classesClassSectionData.length === 0) {
            dispatch(fetchFranchiseClasswiseSectionsDataOnce());
        }
    }, [classesClassSectionData, dispatch]);

    const fetchClassActivities = async (classSectionId) => {
        try {
            const response = await fetch(`${API_PATH}/franchisee-school/${Number(franchiseeSchoolId)}/parent-teacher-meets-dates?classSectionId=${classSectionId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            setPtmData(data);  // Set the PTM data to state
        } catch (error) {
            console.error("Error fetching class activities:", error);
            setPtmData([]);  // Clear PTM data on error
        }
    };

    const handleClassChange = (selectedClassId) => {
        let classSectionData;
        if (roleIds === 6) {
            classSectionData = classData.find((c) => c.id === selectedClassId);
        } else {
            classSectionData = classesClassSectionData.find((c) => c.id === selectedClassId);
        }

        if (classSectionData) {
            setSelectedClassData(classSectionData);
            if (classSectionData.classSections.length === 1) {
                setSelectedSectionId(classSectionData.classSections[0].classesclassSection);
            } else if (classSectionData.classSections.length === 0) {
                setSelectedSectionId(classSectionData.classesclassSection);
            } else {
                setSelectedSectionId('');
            }
        }
    };

    useEffect(() => {
        if (selectedClass) {
            handleClassChange(selectedClass);
            setDropDowns({ ...dropDowns, sections: "" });
        }
    }, [selectedClass]);


    const handleChange = (name, value) => {
        if (name === "classId") {
            const classIdValue = value.value || value;
            setFormData({ ...formData, [name]: classIdValue });
            handleClassChange(classIdValue);
            setDropDowns({ ...dropDowns, class: value.label });
        } else if (name === "sectionId") {
            setSelectedSectionId(value.value);
            setDropDowns(prev => ({ ...prev, sections: value.label }));
        }
    };

    useEffect(() => {
        if (selectedSectionId) {
            fetchClassActivities(selectedSectionId);
        }
    }, [selectedSectionId]);


    return (
        <>
            <Row style={{ textAlign: 'center', marginTop: '20px' }}>

                {roleIds === 2 ? (
                    <>
                        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                            <SelectDropdown
                                label="Class"
                                value={dropDowns.class ? { value: dropDowns.class, label: dropDowns.class } : null}
                                placeholder="Select class"
                                onChange={(selectedOption) => {
                                    handleChange("classId", selectedOption);
                                }}
                                options={roleIds === 6 && classData
                                    ? classData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                                    : classesClassSectionData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                                }
                                required
                                feedbackMessage="Please select a valid class"
                            />
                            {formErrors.class && (
                                <div className="error-message">Please select a valid class.</div>
                            )}
                        </Col>

                        {formData.classId &&
                            selectedClassData &&
                            selectedClassData?.classSections?.length > 0 && (
                                <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                    <SelectDropdown
                                        label="Section"
                                        value={
                                            dropDowns.sections
                                                ? {
                                                    value: dropDowns.sections,
                                                    label: dropDowns.sections,
                                                }
                                                : null
                                        }
                                        placeholder="Select section"
                                        onChange={(selectedOption) => {
                                            handleChange("sectionId", selectedOption);
                                        }}
                                        options={selectedClassData.classSections?.map((section) => ({
                                            key: section.id,
                                            value: section.classesclassSection,
                                            label: section.name,
                                        }))}
                                        required
                                        feedbackMessage="Please select a valid section"
                                    />
                                    {formErrors.sections && (
                                        <div className="error-message">Please Select Section.</div>
                                    )}
                                </Col>
                            )}
                    </>
                ) : (
                    <>
                        <Col sm={6} xs={12} md={2} lg={2} className="rowCss">
                            <SelectDropdown
                                isSearchable
                                required
                                value={formData1.studentId ? { label: formData1.studentName } : null}
                                options={data?.childrens?.map((option) => ({
                                    id: option.id,
                                    value: option.studentEnrollments[0].classesClassSectionId,
                                    label: option.name,
                                }))}
                                onChange={(selectedOption) => {
                                    setFormData1({
                                        ...formData1,
                                        studentId: Number(selectedOption.id),
                                        studentName: selectedOption.label,
                                    });
                                    setSelectedSectionId(selectedOption.value);
                                }}
                                placeholder="Child Name"
                                feedbackMessage="Please select child name."
                            />
                        </Col>
                    </>
                )}



                {ptmData.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{
                                    backgroundColor: "#E2E8F0",
                                    color: "#464E5F",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                }}
                                >
                                    Sr.No
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: "#E2E8F0",
                                    color: "#464E5F",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                }}
                                >
                                    Date
                                </TableCell>
                                <TableCell sx={{
                                    backgroundColor: "#E2E8F0",
                                    color: "#464E5F",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                }}
                                >
                                    PTM
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ptmData.map((item, index) => {
                                // Format the date as dd/mm/yyyy
                                const formattedDate = new Date(item.date).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                }).replace(/\//g, '-')

                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {formattedDate}
                                        </TableCell>
                                        <TableCell>
                                            {item.ptm}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <p>No records available.</p>
                )}
            </Row >
        </>
    );
};

export default PtmDatesView;
