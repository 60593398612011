import AWS from 'aws-sdk';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";

// Initialize the S3 client
const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const bucketName = process.env.REACT_APP_BUCKET_NAME

// Function to upload a file to S3
export const uploadFile = (file, options) => {
    return new Promise((resolve, reject) => {
        s3.upload({
            Bucket: options.bucketName,
            Key: options.fileName,
            Body: file,
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

// Function to upload multiple files
export const uploadFiles = async (files, folderName) => {
    // Ensure files is an array-like object
    const fileArray = Array.from(files);

    // Get the bucket name from environment variables
    const bucketName = process.env.REACT_APP_BUCKET_NAME

    // Create a FormData instance for the entire batch of files
    const formData = new FormData();
    fileArray.forEach((file) => {
        formData.append('files', file);
    });
    formData.append('folder', `kidhood/${folderName}`);
    formData.append('bucketName', bucketName); // Include the bucket name

    try {
        const response = await fetch(`https://dhbjzrq63pauocozsih622j2ji0ijvea.lambda-url.ap-south-1.on.aws/upload`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Bearer ${token()}`,
            },
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(`Upload failed: ${response.statusText}`);
        }

        return responseData.files;
    } catch (error) {
        console.error('Error uploading files:', error);
        // Return error or null to indicate failure
        return { success: false, error: error.message };
    }
};




// Function to delete a file from S3
export const deleteFile = (fileUrl) => {
    return new Promise((resolve, reject) => {
        const fileName = fileUrl.split('/').slice(-3).join('/');
        s3.deleteObject({
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: fileName,
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

// Function to delete multiple files
export const deleteFiles = async (fileUrls) => {
    const deletePromises = fileUrls.map((fileUrl) => {
        if (typeof fileUrl === 'string') {
            return deleteFile(fileUrl);
        }
        console.error("Invalid file URL:", fileUrl);
        return Promise.reject(new Error("Invalid file URL"));
    });
    return await Promise.all(deletePromises);
};