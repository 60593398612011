import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { responseFormat } from '../../Auth/errorResponse';

const slice = createSlice({
    name: "classData",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
        errorMessage: "",
        successMessage: "",
        showSuccessMessage: false,
        showErrorMessage: false,
    },
    reducers: {
        fetchClassData: (state) => {
            state.isLoading = true;
            state.hasError = false;
            state.data = null;
        },
        fetchClassDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchClassDataFailure: (state, action) => {
            state.isLoading = false;
            state.hasError = true;
            state.errorMessage = action.payload;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessage: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessage: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    },
});

export const {
    fetchClassData,
    fetchClassDataSuccess,
    fetchClassDataFailure,
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
} = slice.actions;

export const fetchClassDataRequest = (ids, teacherId) => async (dispatch) => {
    dispatch(fetchClassData());

    const tokenId = token();
    try {
        const url = `${API_PATH}/franchisee-school/${ids}/teachers/${teacherId}/classes`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${tokenId}`,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.errors || 'Failed to fetch class data');
        }

        const result = await response.json();
        dispatch(fetchClassDataSuccess(result));
        dispatch(setSuccessMessage("Data fetched successfully!"));
        dispatch(setShowSuccessMessage(true));
    } catch (error) {
        const errMessage = responseFormat({ error });
        dispatch(fetchClassDataFailure(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};

export default slice.reducer;
