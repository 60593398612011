import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
// import { fetchDataOnce } from "./slice/viewfeeDetailsSlice";

function ViewFeeDetails() {
  const [data, setData] = useState([]);
  const data1 = [
    {
      id: 1,
      date: "2023-10-17",
      studentId: "S001",
      name: "John Doe",
      className: "2nd Class",
      section: "A",
      "1stInstallment": 15000,
      "2ndInstallment": 15000,
      "3rdInstallment": 5000,
    },
    {
      id: 2,
      date: "2023-10-18",
      studentId: "S002",
      name: "Jane Smith",
      className: "1st Class",
      section: "B",
      "1stInstallment": 10000,
      "2ndInstallment": 10000,
      "3rdInstallment": 5000,
    },
  ];
  const columns = [
    { field: "id", header: "Sr.no" },
    { field: "date", header: "Date" },
    { field: "studentId", header: "Student Id" },
    { field: "name", header: "Name" },
    { field: "className", header: "class" },
    { field: "section", header: "Section" },
    { field: "1stInstallment", header: "1st Installment" },
    { field: "2ndInstallment", header: "2nd Installment" },
    { field: "3rdInstallment", header: "3rd Installment" },
  ];

  const isLoading =
    useSelector((state) => state.ViewFeeDetails.isLoading) || [];
  const hasError = useSelector((state) => state.ViewFeeDetails.hasError);

  const apiData = useSelector((state) => state.ViewFeeDetails.data);
  const postData = useSelector((state) => state.testPostAPI.data);


  let postDataArray = [];
  if (postData !== null) {
    postDataArray = [postData];
  }

  const combinedData = [...(apiData || []), ...postDataArray];


  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchDataOnce());
  }, [dispatch]);
  return (
    <>
      <div>View Fee Details</div>
      {data1 === null ? ( // Check if data is null
        <center>
          <p>Loading...</p>
        </center>
      ) : data1.length === 0 ? ( // Check if data array is empty
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table data={data1} columns={columns} hover={true} striped={true} />
      )}
    </>
  );
}
export default ViewFeeDetails;
