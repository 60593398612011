import { createSlice } from '@reduxjs/toolkit';
import { fetchWithAuthorization } from '../../Auth/dependency-get';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { setFetchPictureUpload } from './picturesUploadSlice';




const slice = createSlice(
    {
        name: 'dailyUpdates',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            }
        },
    },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = slice.actions;

export const FetchDailyUpdates = (id, pictureType) => async (dispatch, getState) => {

    const { data, isLoading } = getState().dailyUpdates;
    const tokenId = token();

    // if (data === null && !isLoading) {
    //     dispatch(fetchDataStart());

     
    // }

    try {

        const response = await fetchWithAuthorization(API_PATH + `/pictureUploadings?isActive=true&studentId=${id}&pictureType=${pictureType}`, "GET", tokenId);

        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(setFetchPictureUpload([]))
            dispatch(fetchDataSuccess(responseData));
        }
    } catch (error) {
        dispatch(fetchDataFailure());
    }

}

export default slice.reducer;
