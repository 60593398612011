/*
    authors: 
    date: 23-10-2023
    update:23-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../StudentDetails/Student.css";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexAlphaNumeric, validationRegexOnlyTextarea } from "../reUsableForm/regex";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import SelectDropdown from "../SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { PostAssessmentRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addAssessmentSlicer";
import { fetchFranchiseClassSectionsDataOnce } from "../Franchise/slice/viewfranchiseclasssections";
import { fetchDataOnce } from "../Classes/slice/viewClassSlice";
import AssessmentSkillView from "./assessmentSkillView";


const Assessment = () => {
  const [validated, setValidated] = useState(false);

  const [dropDowns, setDropDowns] = useState({});

  const [formData, setFormData] = useState({});
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const selectedFranchiseeSchoolId = Number(franchiseeSchoolId);
  const [selectedClassSection, setSelectedClassSection] = useState(null);
  const [classSections, setClassSections] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({
    class: false,
    name: false,
  });
  const classData = useSelector((state) => state.viewClass.data) || [];


  useEffect(() => {
    if (classData.length === 0) {
      dispatch(fetchDataOnce());
    }
  }, [classData, dispatch]);

  const showSuccessMsg = useSelector((state) => state.addAssessment.showSuccessMessage);
  const successMsg = useSelector((state) => state.addAssessment.successMessage);

  const showErrorMsg = useSelector((state) => state.addAssessment.showErrorMessage);
  const errorMsg = useSelector((state) => state.addAssessment.errorMessage);

  const handleSubmit = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    const newFormErrors = {
      class: !dropDowns.class,
      name: !formData.name,
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }

    const sendData = {
      ...formData,
    };


    dispatch(PostAssessmentRequest(formData));

    setFormData({});
    setDropDowns({ dropDowns: "" });
    setSelectedClassId("");
    setSelectedSectionId("");
    setSelectedSubjectId("");
  };
  const handleClear = () => {
    setFormData({});
    setValidated(false);
    setDropDowns({});
    setFormErrors({});
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Assessment Skill</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={dropDowns.class ? { value: dropDowns.class, label: dropDowns.class } : null}

              options={classData.map(item => ({
                value: item.id,
                label: item.name,
                classId: item.id,  // Assuming classId is the property you want to set in setFormData
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  classId: selectedOption.classId,
                });

                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              placeholder="Select class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>


          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Skill"
              placeholder="Skill"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={formData.name || ""}
              onChange={(event) => {
                setFormData({ ...formData, name: event.target.value });
              }}
              feedbackMessage="Please enter skill."
              validationRegex={validationRegexOnlyTextarea}
              validationErrorMessage="Please enter valid skill."
            />
          </Col>
        </Row>

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <div>
        <AssessmentSkillView />
      </div>
    </>
  );
};

export default Assessment;
