import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';
import { FitnessParameterPost } from './fitnessparameterSlice';

const slice = createSlice(
    {
        name: 'fitnessParameterView',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setFitnessParameterData: (state, action) => {
                state.isLoading = false;
                const newData = action.payload;

                const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

                if (existingObjectIndex !== -1) {
                    state.data[existingObjectIndex] = newData;
                }

            },
        },
    }
);

export const { fetchDataFailure, fetchDataStart, fetchDataSuccess, setFitnessParameterData } = slice.actions;

export const FitnessParameterView = () => async (dispatch, getFitnessParameterView) => {
    const { data, isLoading } = getFitnessParameterView().fitnessParameterView;
    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart())

        try {
            const franchiseeSchoolId = (sessionStorage.getItem("franchiseeSchoolId"));
            const response = await fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId}/fitness-parameters`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data = await response.json();
            // dispatch(FitnessParameterPost([]));
            dispatch(fetchDataSuccess(data));
            dispatch(setFitnessParameterData(data));
        } catch (error) {
            dispatch(fetchDataFailure())
        }
    }
}
export default slice.reducer;