import { createSlice } from "@reduxjs/toolkit";
import { competetionInitialState } from "./intialStateCompetetion";
import { postCompetetionAwards,getCompetetionAwards,putCompetetionAward,postCompetetionName,getCompetetionNames } from "./requestsCompetetion";
const competitionSlicer = createSlice({
    name:'competetion',
    initialState:competetionInitialState,
    reducers:{
        postAllCompetetions:(state,action)=>{
            state.getCompetetionAwards.data = action.payload;
            state.postCompetetionAwards.data={};
        },
        putAllCompetetions:(state,action)=>{
            state.getCompetetionAwards.data = [...action.payload];
            state.putCompetetionAward.data={};
        },
        getDetails:(state,action)=>{
            const roll = action.payload;
            const requiredStudentIndex = state.students.findIndex((e)=>e.rollNumber===roll)
            const studentObject=state.students[requiredStudentIndex]
            state.requiredStudent={...studentObject}
            
        },
        CLSTICompetetionName:(state)=>{
            state.postCompetetionName.loading = 'idle'
        },
        CLSTIPostCompetetion:(state)=>{
            state.postCompetetionAwards.loading = 'idle'
        },
        CLSTIEditCompetetionAward:(state)=>{
            state.putCompetetionAward.loading = 'idle'
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(postCompetetionAwards.pending,(state,action)=>{
            state.postCompetetionAwards.loading = "pending";
        })
        .addCase(postCompetetionAwards.fulfilled,(state,action)=>{
            if(action.payload.hasOwnProperty("errors") || action.payload.hasOwnProperty("error")){
                state.postCompetetionAwards.loading = "rejected";
                state.postCompetetionAwards.error = action.payload.errors?action.payload.errors:action.payload.error;
            }else{
                state.postCompetetionAwards.loading = "fulfilled";
                state.postCompetetionAwards.data = action.payload['data'];
                state.postCompetetionAwards.error = null;
            }
        })
        .addCase(postCompetetionAwards.rejected,(state,action)=>{
                state.postCompetetionAwards.loading = "rejected";
                state.postCompetetionAwards.error = action.payload.errors?action.payload.errors:action.payload.error;
        })
        .addCase(getCompetetionAwards.pending,(state,action)=>{
            state.getCompetetionAwards.loading = "pending";
        })
        .addCase(getCompetetionAwards.fulfilled,(state,action)=>{
            const data = action.payload;
            const modified = data?.length>0?data?.map((each,index)=>{return {...each,srNo:index+1}}):[];
            if(action.payload.hasOwnProperty("errors")||action.payload.hasOwnProperty("error")){
                state.getCompetetionAwards.loading = "rejected";
                state.getCompetetionAwards.error = action.payload.error?action.payload.error:action.payload.errors;
            }else{
                state.getCompetetionAwards.loading = "fulfilled";
                state.getCompetetionAwards.data = modified;
                state.getCompetetionAwards.error = null;
            }
            
        })
        .addCase(getCompetetionAwards.rejected,(state,action)=>{
                state.getCompetetionAwards.loading = "rejected";
                state.getCompetetionAwards.error = action.payload.error?action.payload.error:action.payload.errors;
        })
        .addCase(postCompetetionName.pending,(state,action)=>{
            state.postCompetetionName.loading = "pending";
        })
        .addCase(postCompetetionName.fulfilled,(state,action)=>{

            if(action.payload.hasOwnProperty("errors")||action.payload.hasOwnProperty("error")){
                state.postCompetetionName.loading = "rejected";
                state.postCompetetionName.error = action.payload.errors?action.payload.errors:action.payload.error;
            }else{
                state.postCompetetionName.loading = "fulfilled";
                state.postCompetetionName.data = action.payload;
                state.postCompetetionName.error = null;
            }
           
        })
        .addCase(postCompetetionName.rejected,(state,action)=>{
                state.postCompetetionName.loading = "rejected";
                state.postCompetetionName.error = action.payload.errors?action.payload.errors:action.payload.error;
        })
        .addCase(getCompetetionNames.pending,(state,action)=>{
            state.getCompetetionNames.loading = "pending";
        })
        .addCase(getCompetetionNames.fulfilled,(state,action)=>{
            const data = action.payload;
            if(data.hasOwnProperty("errors")||data.hasOwnProperty("error")){
                state.getCompetetionNames.loading = "rejected";
                state.getCompetetionNames.error = action.payload.errors?action.payload.errors:action.payload.error;
                return;
            }else{
                state.getCompetetionNames.loading = "fulfilled";
                state.getCompetetionNames.data = action.payload;
                state.getCompetetionNames.error = null;
            }
        })
        .addCase(getCompetetionNames.rejected,(state,action)=>{
                state.getCompetetionNames.loading = "rejected";
                state.getCompetetionNames.error = action.payload.errors?action.payload.errors:action.payload.error;
        })
        .addCase(putCompetetionAward.pending,(state,action)=>{
            state.putCompetetionAward.loading = 'pending'
        })
        .addCase(putCompetetionAward.fulfilled,(state,action)=>{
            const data = action.payload;
            if(data.hasOwnProperty('errors')||data.hasOwnProperty('error')){
                state.putCompetetionAward.loading = 'rejected';
                state.putCompetetionAward.error = data.errors?data.errors:data.error
            }else{
                state.putCompetetionAward.loading = 'fulfilled';
                state.putCompetetionAward.data = data;
                state.putCompetetionAward.error=null
            }
        })
        .addCase(putCompetetionAward.rejected,(state,action)=>{
            state.putCompetetionAward.loading = 'rejected';
            state.putCompetetionAward.error = action.payload.errors?action.payload.errors:action.payload.error
        })
    }
})

export const {postAllCompetetions,putAllCompetetions,getDetails,CLSTIPostCompetetion,CLSTICompetetionName,CLSTIEditCompetetionAward} = competitionSlicer.actions;
export default competitionSlicer.reducer;