import React, { useEffect } from 'react'
// import { columns } from './staticData';
import AwardsTable from './AwardsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetetionAwards } from './slicerCompetetion/requestsCompetetion';
import { postAllCompetetions, putAllCompetetions } from './slicerCompetetion/competitionAwardsSlicer';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import { getParentLoginData } from '../parent/parentLoginSlicer/parentLoginRequests';

const CompetetionAwardsView = () => {
  const roleId = sessionStorage.getItem('roleId')
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');

  const columns = [
    { field: "srNo", header: "Sl.No" },
    {
      field: "date",
      header: "Date",
      Cell: ({ value }) => formatDate(value)
    },
    { field: "studentName", header: "Student Name" },
    { field: "class", header: "Class" },
    { field: "section", header: "Section" },
    { field: "competitionId", header: "Competetion Name" },
    { field: "award", header: "Award Name" },
    { field: "result", header: "Result status" },
    { field: "imageUrls", header: "Certificate" },
    roleId !== "5" ? { field: "edit", header: "edit" } : {},
  ];


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatData = (data) => {

    return data.map(item => ({
      ...item,
      date: formatDate(item.date),
      createdAt: formatDate(item.createdAt),
      updatedAt: formatDate(item.updatedAt),
      student: {
        ...item.student,
        dateOfBirth: formatDate(item.student.dateOfBirth)
      }
    }));
  };


  const getCompetetionAwardsObject = useSelector((state) => state.competetionAwardsReducer.getCompetetionAwards);
  const postCompetetionAwardsObj = useSelector(state => state.competetionAwardsReducer.postCompetetionAwards);
  const putCompetetionAwardObj = useSelector(state => state.competetionAwardsReducer.putCompetetionAward);
  const studentData = useSelector((state) => state.viewStudent.data) || [];

  const postedData = postCompetetionAwardsObj.data;
  const putData = putCompetetionAwardObj.data;
  const dispatch = useDispatch();

  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const parent_data = parentLoginObject?.data;
  useEffect(() => {
    if (parentLoginObject?.loading === "idle") {
      const parentId = sessionStorage.getItem('userId');
      if (roleId === "5") {
        dispatch(getParentLoginData(parentId));
      }
    }
  }, [dispatch, parentLoginObject?.loading]);
  const children = parent_data?.childrens
  useEffect(() => {
    if (children?.length > 0) {
      const studentIds = parent_data.childrens.map(child => child.id);
      let ids = "";
      if (studentIds !== undefined && studentIds !== null) {
        const queryString = studentIds.map(id => 'studentId=' + id).join('&');
        ids += queryString
      }
      const hasWon = true
      const query = { franchiseeSchoolId: franchiseeSchoolId, hasWonAndStudentIds: `hasWon=${hasWon}&${ids}`}
      dispatch(getCompetetionAwards(query));
    }
  }, [dispatch, children, parent_data]);


  const loading = getCompetetionAwardsObject.loading;
  const data = getCompetetionAwardsObject.data;
  useEffect(() => {
    if (loading === 'idle' && roleId !== "5") {
      const query = { franchiseeSchoolId: franchiseeSchoolId }
      dispatch(getCompetetionAwards(query))
    }
  }, [loading])
  useEffect(() => {
    dispatch(fetchStudentDataOnce(+franchiseeSchoolId));
  }, [dispatch]);
  useEffect(() => {
    if (Object.keys(postedData).length > 0) {
      const srNo = data.length + 1;

      const particularStudent = studentData?.find((each) => { return +each?.id === postedData?.studentId });
      // const studentEnrollments = particularStudent?.studentEnrollments
      // const name = particularStudent?.name;
      // const student = {name,studentEnrollments};
      const studentEnrollmentsObj = particularStudent?.studentEnrollments[0]
      const name = particularStudent?.name;
      const studentEnrollments = {
        id: studentEnrollmentsObj?.id,
        studentRollNumber: studentEnrollmentsObj?.studentRollNumber,
        class: studentEnrollmentsObj?.classesClassSection?.class ? studentEnrollmentsObj?.classesClassSection?.class?.name : 'NA',
        section: studentEnrollmentsObj?.classesClassSection?.classSection ? (studentEnrollmentsObj?.classesClassSection?.classSection?.name) : 'NA'
      }
      const student = { name, studentEnrollments };
      const post = { ...postedData, srNo, student, date: postedData.date };
      const postModifiedData = [...data, post];
      dispatch(postAllCompetetions(postModifiedData));
      return;
    }
    if (Object.keys(putData).length > 0) {
      const data = putModifyFunction(putData);
      dispatch(putAllCompetetions(data));
      return;
    }
  }, [JSON.stringify(postedData), JSON.stringify(putData)])

  const putModifyFunction = (receivedPut) => {
    const obj = data?.find(each => +each?.id === +receivedPut?.id);
    const particularStudent = studentData?.find((each) => { return +each?.id === receivedPut?.studentId });
    const studentEnrollmentsObj = particularStudent?.studentEnrollments[0]
    const name = particularStudent?.name;
    const studentEnrollments = {
      id: studentEnrollmentsObj?.id,
      studentRollNumber: studentEnrollmentsObj?.studentRollNumber,
      class: studentEnrollmentsObj?.classesClassSection?.class?.name,
      section: studentEnrollmentsObj?.classesClassSection?.classSection ? (studentEnrollmentsObj?.classesClassSection?.classSection?.name) : 'NA'
    }
    const student = { name, studentEnrollments };
    const put = { ...receivedPut, srNo: obj.srNo, student, date: receivedPut.date };
    const otherData = data.filter(each => { return +each?.id !== +receivedPut?.id });
    const putModifiedData = [...otherData, put];
    return putModifiedData;
  }
  const formattedData = formatData(data);


  return (
    <>
      <div className='content-header'>Competetion Awards</div>
      <AwardsTable columns={columns} data={formattedData.length > 0 ? formattedData : []} />
    </>
  )
}

export default CompetetionAwardsView;
