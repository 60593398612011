import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

const slice = createSlice({
  name: "frequencySlice",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchAddFrequencyData: (state, action) => {
      state.isLoading = action.payload;
      state.hasError = false;
    },
    fetchAddFrequencySuccess: (state, action) => {
      state.isLoading = true;
      state.data = state.data ? [...state.data, ...action.payload] : action.payload;
    },
    fetchAddFrequencyFailure: (state, action) => {
      state.isLoading = false;
      state.hasError = action.payload;
    },
  },
});

export const { fetchAddFrequencyData, fetchAddFrequencySuccess, fetchAddFrequencyFailure } = slice.actions;

export const postAddFrequencyRequest = (postData) => async (dispatch) => {
  dispatch(fetchAddFrequencyData());
  const tokenId = token();
  try {
    const response = await fetch(API_PATH + "/frequency", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenId}`
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      throw new Error("Request failed");
    }
    const responseData = await response.json();
    dispatch(fetchAddFrequencySuccess([responseData.dayFrequency]));
  } catch (error) {
    dispatch(fetchAddFrequencyFailure());
  }
};

export default slice.reducer;
