/*
    authors: Revanth
    date: 21-10-2023
    update:21-10-2023 
    version: 1.0
*/

import React, { useEffect } from 'react';
import "./enquiryView.css"
import Table from '../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { followUpListSlicer } from './slice/entryFollowUpListSlicer';
import { fetchPlaySchool } from './slice/playSchoolSlicer';
import { fetchClasses } from './slice/classesSlicer';
import { Link } from 'react-router-dom';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
const EnquiryView = () => {
    const playSchool = useSelector((state) => state.playSchool.data) || [];
    const classes = useSelector((state) => state.classes.data) || [];
    const apiData = useSelector((state) => state.enquiryViewList.data) || [];
    const putData = useSelector((state) => state.enquiryFollowUp.data) || [];

    const dispatch = useDispatch();
    const getBackgroundColor = (isAdmissionComplete, followUps) => {
        if (isAdmissionComplete) {
            return 'rgb(0, 255, 26)'; // Green
        }

        const latestFollowUp = followUps.reduce((latest, followUp) => {
            if (!latest || new Date(followUp.updatedAt) > new Date(latest.updatedAt)) {
                return followUp;
            }
            return latest;
        }, null);

        if (!latestFollowUp) {
            return 'rgb(240, 102, 102)'; // Red
        }

        if (latestFollowUp.mindSet === 'Positive') {
            return 'rgb(0, 255, 26)'; // Yellow
        }

        if (latestFollowUp.mindSet === 'Negative') {
            return 'rgb(240, 102, 102)'; // Yellow
        }

        if (latestFollowUp.mindSet === 'Follow-Up') {
            return 'rgb(255, 255, 0)'; // Yellow
        }

        return 'rgb(240, 102, 102)'; // Red by default
    };

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "dateOfBirth", header: "Date Of Birth" },
        { field: "studentName", header: "Student Name" },
        { field: "fatherName", header: "Father Name" },
        { field: "fatherContactNumber", header: "Father Contact No" },
        { field: "fatherOccupation", header: "Father Occupation" },
        { field: "motherName", header: "Mother Name" },
        { field: "motherContactNumber", header: "Mother Contact No" },
        { field: "motherOccupation", header: "Mother Occupation" },
        { field: "address", header: "Address" },
        { field: "expectedDateOfJoining", header: "Expected Date Of Joining" },
        { field: "referral", header: "Referral" },
        { field: "specialComments", header: "Special Comments" },
        { field: "program", header: "Play School" },
        { field: "className", header: "Classes" },
        { field: "description", header: "Remarks" },
        { field: "StudentFollowUps", header: "Follow Ups" },
        { field: "arrow", header: "Create Student" },
    ]
    const combinedData = apiData.map(apiItem => {
        if (putData.length > 0) {
            const matchingPutItem = putData[0].id === apiItem.id ? putData[0] : null;
            if (matchingPutItem) {
                return {
                    ...apiItem,
                    studentEnquiryFollowUps: [
                        ...(apiItem.studentEnquiryFollowUps || []), // Existing follow-ups
                        matchingPutItem.studentEnquiryFollowUps // New follow-up from putData
                    ]
                };
            } else {
                return apiItem;
            }
        } else {
            return apiItem;
        }
    });



    const newModifiedData = combinedData.map((postItem, index) => {
        const findObjectProgram = playSchool.find((obj) => obj.id === postItem?.programId);
        const findObjectClass = classes.find((obj) => obj.id === postItem?.classId);

        const formattedDate = postItem?.date ? new Date(postItem?.date) : null;

        const dateString = formattedDate ? formattedDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }) : '';

        const formattedDateOfBirth = new Date(postItem.dateOfBirth).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const formattedExpectedDateOfJoining = new Date(postItem.expectedDateOfJoining).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const latestFollowUp = postItem.studentEnquiryFollowUps.reduce((latest, followUp) => {
            if (!latest || new Date(followUp.updatedAt) > new Date(latest.updatedAt)) {
                return followUp;
            }
            return latest;
        }, null);

        const renderArrow = () => {
            if (postItem.isAdmissionComplete === false) {
                if (latestFollowUp && latestFollowUp.mindSet === 'Positive') {
                    return (
                        <Link to={`/addStudent?studentName=${encodeURIComponent(postItem.studentName)}&address=${encodeURIComponent(postItem.address)}&dateOfBirth=${encodeURIComponent(postItem.dateOfBirth)}
                        &studentEnquiryId=${encodeURIComponent(postItem.id)}&program=${encodeURIComponent(findObjectProgram?.name)}&class=${encodeURIComponent(findObjectClass?.name)}`}>
                            <PersonAddAlt1Icon style={{ fontSize: "28px", color: "white" }} />
                        </Link>
                    );
                } else {
                    return "-";
                }
            } else {
                return "Created";
            }
        };

        return {
            index: index + 1,
            id: postItem.id,
            date: dateString,
            dateOfBirth: formattedDateOfBirth,
            studentName: postItem.studentName ? postItem.studentName : "N/A",
            fatherName: postItem.fatherName ? postItem.fatherName : "N/A",
            fatherContactNumber: postItem.fatherContactNumber ? postItem.fatherContactNumber : "N/A",
            fatherOccupation: postItem.fatherOccupation ? postItem.fatherOccupation : "N/A",
            motherName: postItem.motherName ? postItem.motherName : "N/A",
            motherContactNumber: postItem.motherContactNumber ? postItem.motherContactNumber : "N/A",
            motherOccupation: postItem.motherOccupation ? postItem.motherOccupation : "N/A",
            address: postItem.address ? postItem.address : "N/A",
            expectedDateOfJoining: formattedExpectedDateOfJoining,
            referral: postItem.referral ? postItem.referral : "N/A",
            specialComments: postItem.specialComments ? postItem.specialComments : "N/A",
            program: findObjectProgram ? findObjectProgram.name : '',
            className: findObjectClass ? findObjectClass.name : '',
            description: postItem.description ? postItem.description : "N/A",
            studentEnquiryFollowUps: postItem.studentEnquiryFollowUps ? postItem.studentEnquiryFollowUps : "N/A",
            backgroundColor: getBackgroundColor(postItem.isAdmissionComplete, postItem.studentEnquiryFollowUps),
            arrow: renderArrow()

        };
    });

    const uniqueNewModifiedData = newModifiedData.filter(
        (obj, index, self) => self.findIndex(item => item.id === obj.id) === index
    );


    useEffect(() => {
        dispatch(fetchPlaySchool());
        dispatch(fetchClasses());
        dispatch(followUpListSlicer());
    }, [dispatch]);

    return (
        <div>
            <h4 className='enquiryView'>Enquiry Follow Up View</h4>
            {uniqueNewModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : uniqueNewModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={uniqueNewModifiedData} columns={columns} hover={true} />
            )}
        </div>
    );
};

export default EnquiryView;

