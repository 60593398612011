import React, { useState } from "react";
import { Form, Modal, Image } from "react-bootstrap";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, Button, TextField, MenuItem, Select, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Image as ImageView } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CloudDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeRow } from "./teacherSlicers/employeeViewSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import useCitiesState from "./customHooks/useCitiesState";
import { calculateAge } from "./teacherSlicers/newDataFilterFunctions";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { DownloadOutlined } from "@ant-design/icons";


const TeachersTable = ({
    data = null,
    columns = null,

}) => {
    const dispatch = useDispatch();

    const editedRowValue = useSelector((state) => state.employeeSlicer?.teachers?.employeeRow)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState(columns && columns[0] ? columns[0].field : "");

    const [showImageView, setShowImageView] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDocumentPopup, setShowDocumentPopup] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);

    const [editedData, setEditedData] = useState(editedRowValue);

    const [qualificationsOptions, statesOptions, citiesOptions, designationOptions] = useCitiesState();
    const navigate = useNavigate();
    const getCaps = (head, field) => (head ? head.toUpperCase() : field.toUpperCase());

    const handleEditClick = (row) => {
        dispatch(getEmployeeRow(row))
        navigate(`/editEmployee/${row.id}`)
    };
    const handleEditSave = () => {
        setShowEditModal(false);
    };

    const handleViewClick = (row) => {
        setSelectedRow(row);
        setShowViewModal(true);
    };

    const handleDownloadImages = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'images.jpg'; // You can customize the downloaded file name here
        link.click();
    };

    const downloadDocument = (docData) => {
        const stringForm = bufferToBase64(docData?.data?.data)
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:${docData?.type};base64,${stringForm}`;
        downloadLink.download = docData?.name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownload = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'document.jpg'; // You can customize the downloaded file name here
        link.click();
    };


    function bufferToBase64(buffer) {
        return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    };
    const handleImageViewClick = async (image) => {
        setSelectedImage(image);
        setShowImageView(true)
    };

    const handleDocumentIconClick = async (docList) => {
        setShowDocumentPopup(true);
        setDocuments(docList);
    };


    const handleCloseDocumentPopup = () => {
        setShowDocumentPopup(false);
    };
    const handleSortClick = (column) => {
        if (column === "edit") {
            return;
        }

        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };


    const filteredData = data ? data.filter((row) => {
        const searchValue = searchQuery.toLowerCase();

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnValue = row[column.field];

            if (typeof columnValue === "string") {
                if (columnValue.toLowerCase().includes(searchValue)) {
                    return true;
                }
            } else if (typeof columnValue === "number") {
                if (columnValue.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    }) : [];

    const sortedData = filteredData && filteredData.slice().sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (typeof valueA === "string" && typeof valueB === "string") {
            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        } else {
            if (sortOrder === "asc") {
                return valueA < valueB ? -1 : 1;
            } else {
                return valueB < valueA ? -1 : 1;
            }
        }
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsBeforeAge = sortedData && sortedData.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = currentItemsBeforeAge?.map((each) => { return { ...each, age: calculateAge(each.dateOfBirth) } })
    const filteredColumns = columns.filter(
        (col) => !["id", "view", "edit", "delete"].includes(col.field)
    );

    return (
        <div className="table-Maindiv">
            <TextField
                placeholder="Search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "10px" }}
            />
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns &&
                                columns.map((head, rowIndex) => (
                                    <TableCell sx={{ backgroundColor: "#E2E8F0", color: "#464E5F", fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", textAlign: "center", whiteSpace: "nowrap" }} key={rowIndex} className="tablehead">
                                        <TableSortLabel
                                            active={sortColumn === head.field}
                                            direction={sortOrder}
                                            onClick={() => handleSortClick(head.field)}
                                        >
                                            {(head.field, head.header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems && currentItems.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((col) => (
                                    <TableCell key={col.field} className="tablecell">
                                        {col.field === "profilePictureUrl" ? (
                                            <IconButton
                                                onClick={() => {

                                                    handleImageViewClick(row.profilePictureUrl)

                                                }
                                                }
                                                color="primary"
                                                size="small"
                                            >
                                                <ImageView />
                                            </IconButton>
                                        )
                                            : col.field === "documentUrls" ? (
                                                <IconButton
                                                    onClick={() => {

                                                        handleDocumentIconClick(row.documentUrls)
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <UploadFileIcon />
                                                </IconButton>
                                            )
                                                : col.field === "age" ? (
                                                    <p>{calculateAge(row.dateOfBirth)}</p>
                                                )
                                                    :
                                                    col.field === "edit" ? (
                                                        <IconButton
                                                            onClick={() => {
                                                                handleEditClick(row)
                                                            }}
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    )
                                                        : col.field === "view" ? (
                                                            <IconButton
                                                                onClick={() => { handleViewClick(row) }}
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        )
                                                            : (row[col.field])}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Table Completed */}
            {data ? null : <p>No data found</p>}

            <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData && sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            />
            {/* Start of Modals */}

            <Modal show={showImageView} onHide={() => setShowImageView(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && (
                        <div>
                            <Image
                                src={selectedImage}
                                alt="Damage Picture"
                                style={{ width: "10rem", height: '12rem' }}
                            />
                            <Button style={{ marginLeft: '2rem', color: "blue" }} onClick={() => handleDownloadImages(selectedImage)}>
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}
                    {/* {<object type='image/jpeg' data={`data:image/png;base64,${selectedImage}`} alt="Buffer Image" />} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowImageView(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDocumentPopup} onHide={handleCloseDocumentPopup} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <ul>
                        {documents &&
                            documents.map((imageURL, index) => (
                                <div key={index}>
                                    <Image
                                        src={imageURL}
                                        alt={`Document ${index + 1}`}
                                        style={{ width: '10rem', height: '12rem' }}
                                    />
                                    <Button style={{ marginLeft: '2rem', color: "blue" }} onClick={() => handleDownload(imageURL)}>
                                        <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                                    </Button>
                                </div>
                            ))}
                    </ul>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {selectedRow &&
                            filteredColumns.map((col) => {
                                if (col.field === 'profilePictureUrl') {
                                    return ''
                                } else if (col.field === 'documentUrls') {
                                    return ''
                                } else {
                                    return (
                                        <Form.Group key={col.field} className="mb-3">
                                            <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                            <Form.Control type="text" value={selectedRow[col.field]} readOnly />
                                        </Form.Group>
                                    )
                                }
                            })}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowViewModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {editedData ?
                            filteredColumns.map((col) => (

                                <Form.Group key={col.field} className="mb-3">
                                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                    {col.field === "profilePictureUrl" ? (
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;

                                                const imageURL = URL.createObjectURL(files[0]);
                                                setEditedData({ ...editedData, image: imageURL })
                                            }}
                                        />
                                    ) : col.field === "documentUrls" ? (
                                        <input
                                            type="file"
                                            multiple
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;
                                                let editDocuments = []
                                                for (let i = 0; i < files.length; i++) {
                                                    const imageURL = URL.createObjectURL(files[i]);
                                                    editDocuments.push(imageURL)
                                                }
                                                setEditedData({ ...editedData, documents: editDocuments })
                                            }}
                                        />
                                    ) : col.field === 'qualificationId' ? (
                                        <SearchableDropDownField
                                            name='qualificationId'
                                            value={editedData[col.field]}
                                            placeholder='Select Qualification'
                                            options={qualificationsOptions}
                                            onChange={(selectedOption) => {
                                                setEditedData({ ...editedData, qualificationId: selectedOption.value })
                                            }}
                                            required={true}
                                            feedbackMessage='Select Qualification'
                                        />
                                    )
                                        : col.field === 'designation' ? (
                                            <SearchableDropDownField
                                                name='designation'
                                                placeholder='Select Designation'
                                                value={editedData[col.field]}
                                                options={designationOptions}
                                                onChange={(selectedOption) => {
                                                    setEditedData({ ...editedData, designation: selectedOption.value })
                                                }}
                                                required={true}
                                                feedbackMessage='Select Designation'
                                            />
                                        )
                                            : col.field === "Pcity" ? (
                                                <SearchableDropDownField
                                                    name='Pcity'
                                                    placeholder='Permanent City'
                                                    value={editedData[col.field]}
                                                    options={citiesOptions}
                                                    onChange={(selectedOption) => {
                                                        setEditedData({ ...editedData, Pcity: selectedOption.value })
                                                    }}
                                                    required={true}
                                                    feedbackMessage='Permanent City is Required!'
                                                />
                                            ) : col.field === "Pstate" ?
                                                (
                                                    <SearchableDropDownField
                                                        name='Pstate'
                                                        value={editedData[col.field]}
                                                        placeholder='Permanenet State'
                                                        options={statesOptions}
                                                        onChange={(selectedOption) => {
                                                            setEditedData({ ...editedData, Pstate: selectedOption.value })
                                                        }}
                                                        required={true}
                                                        feedbackMessage='Permanent State is Required!'

                                                    />
                                                ) : col.field === "Ccity" ? (
                                                    <SearchableDropDownField
                                                        name='Ccity'
                                                        placeholder='Current City'
                                                        value={editedData[col.field]}
                                                        options={citiesOptions}
                                                        onChange={(selectedOption) => {
                                                            setEditedData({ ...editedData, Ccity: selectedOption.value })
                                                        }}
                                                        required={true}
                                                        feedbackMessage='Permanent City is Required!'

                                                    />
                                                ) : col.field === "Cstate" ? (
                                                    <SearchableDropDownField
                                                        name='Cstate'
                                                        value={editedData[col.field]}
                                                        placeholder='Current State'
                                                        options={statesOptions}
                                                        onChange={(selectedOption) => {
                                                            setEditedData({ ...editedData, Cstate: selectedOption.value })
                                                        }}
                                                        required={true}
                                                        feedbackMessage='Current State is Required!'

                                                    />
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        value={editedData[col.field]}
                                                        onChange={(e) => {
                                                            setEditedData({
                                                                ...editedData,
                                                                [col.field]: e.target.value,
                                                            })
                                                        }
                                                        }
                                                    />
                                                )}
                                </Form.Group>
                            )) : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowEditModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                        style={{
                            color: "white",
                            backgroundColor: "#007bff",
                            fontSize: "12px",
                            borderRadius: "20px",
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TeachersTable;

