import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TextInputField from "../reUsableForm/textInputField";
import { passwordRegex } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentDataOnce } from '../parent/viewParentSlicer';
import { SelectingValuesDropDownField } from '../../ReusableFields/DropDown/multipleSelectDropDown';
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';
import { fetchWithAuthPost } from '../Auth/dependency_post';
import { responseFormat } from "../Auth/errorResponse";
import { Space } from 'antd';

const UpdatePassword = () => {
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({ guardianIds: false, password: false });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const [selectedParents, setSelectedParents] = useState([]);
  const [selectedCheckedParents, setSelectedCheckedParents] = useState([]);

  const [submit, setSubmit] = useState(false);

  const apiData = useSelector((state) => state.viewParent.data) || [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchParentDataOnce());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true)
    // Validate form fields
    const newFormErrors = {
      // guardianIds: !formData.guardianIds || formData.guardianIds.length === 0,
      password: !formData.password
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some(error => error);
    if (hasErrors) {
      setValidated(true);
      setSubmit(false);
      return;
    }

    const selectedParentIds = (selectedParents.length > 0 ? selectedParents : selectedCheckedParents).filter(parent => parent.email !== null).map(parent => parent.id);


    const sendData = {
      "guardianIds": selectedParentIds,
      "password": formData.password
    };

    try {
      // Send request
      const response = await fetchWithAuthPost(API_PATH + "/guardian-users/password", "PUT", token(), sendData);
      if (!response.ok) {
        let errorData;
      }
      setShowSuccessMessage(true);
      setSubmit(false);
    } catch (error) {
      console.error('Error:', error);
      const errMessage = responseFormat({ error });
      setApiErrorMessage(errMessage.toString());
      setSubmit(false);
    }
  };


  const handleClear = () => {
    setFormData({
      guardianIds: '',
      password: ''
    });
    setFormErrors({
      guardianIds: false,
      password: false
    });
    setValidated(false);
    setShowSuccessMessage(false);
    setSubmit(false);
    setSelectAll(false);
    setSelectedParents([]);
    setSelectedCheckedParents([]);

  };

  const parentOptions = apiData.map((parent) => ({
    id: parent.id,
    value: parent.email || parent.name,
    label: parent.name,
    email: parent?.email || null
  }));

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedParents([]);
      setSelectedCheckedParents(parentOptions);
    } else {
      setSelectedParents([]);
      setSelectedCheckedParents([]);
    }
    setSelectAll(event.target.checked);
  };

  const handleParentsChange = (selectedOptions) => {
    setSelectedParents(selectedOptions || []);
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Update Password</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={2} xs={2} md={2} lg={2} className="rowCss">
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectAll}
              onChange={handleSelectAllChange}
              style={{ marginTop: "10px" }}
            />
          </Col>
          {!selectAll && (
            <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
              <SelectingValuesDropDownField
                isMulti
                value={selectedParents}
                onChange={handleParentsChange}
                options={parentOptions}
                placeholder="Select Parents"
              />

            </Col>
          )}


          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <TextInputField
                className="fcontrol"
                type="text"
                name="password"
                placeholder='Password'
                required
                value={formData.password || ''}
                onChange={(event) => {
                  setFormData({ ...formData, password: event.target.value });
                  setFormErrors({ ...formErrors, password: !event.target.value });
                }}
                validated={validated}
                validationRegex={passwordRegex}
                maxLength={20}
                feedbackMessage="Please enter Password."
                validationErrorMessage="Please enter a valid Password."
              />
            </Form.Group>
          </Col>
        </Row>
        {/* <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} /> */}
        <SuccessMessagePopup show={showSuccessMessage}
          onClose={() => {
            setShowSuccessMessage(false)
            handleClear()
          }} successMessage={"successfully updated"} />

        <ErrorMessagePopup show={apiErrorMessage}
          onClose={() => {
            setApiErrorMessage(false)
            handleClear()
          }} errorMessage={apiErrorMessage} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" disabled={submit}>
            {submit ? "Updating..." : "Update"}
          </button>
        </div>
      </Form >
    </>
  );
};
export default UpdatePassword;
