import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postRequestOptions } from "../../Teachers/teacherSlicers/allRequests";
import { API_PATH } from "../../../Api/api";
import { token } from '../../../Api/token';

const initialState = {
    expenseData:[],
    postExpensesStates:{
        loading:'idle',
        data:[],
        error:null
    }
}
export const postExpensesEntries = createAsyncThunk('postExpenses',async(payload,thunkApi)=>{
    try{
        
        const postingData = await fetch(API_PATH + '/expenses',postRequestOptions(payload));
        const data = await postingData.json();
        return data
    }catch(error){
        return thunkApi.rejectWithValue(error.message);
    }
})
const expenseSlicer = createSlice({
    name:'expense Entry',
    initialState,
    reducers:{
        addExpenses:(state,action)=>{
            const expense = action.payload;
            const addedId = {...expense,id:state.expenseData.length+1}
            state.expenseData.push(addedId)
        },
        clearTable:(state)=>{
            state.expenseData=[]
        },
        CLSTIExpenseEntry:(state)=>{
            state.postExpensesStates.loading = 'idle'
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(postExpensesEntries.pending,(state)=>{
            state.postExpensesStates.loading='pending'
        })
        .addCase(postExpensesEntries.fulfilled,(state,action)=>{
            state.postExpensesStates.loading='fulfilled';
            state.postExpensesStates.data=action.payload;
        })
        .addCase(postExpensesEntries.rejected,(state,action)=>{
            state.postExpensesStates.loading='rejected';
            state.postExpensesStates.error=action.error.message
        })
    }
})

export const {addExpenses,clearTable,CLSTIExpenseEntry} = expenseSlicer.actions;
export default expenseSlicer.reducer;