import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';

const slice = createSlice(
    {
        name: 'postTransportationFess',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            successMessage: '',
            showSuccessMessage: false,
            errorMessage: '',
            showErrorMessage: false,
        },
        reducers: {
            fetchTransportationData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchTransportationSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchTransportationFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setTransportationFee: (state, action) => {
                state.isLoading = false
                state.data = action.payload;
            }
        },
    }


);

export const { fetchTransportationData, fetchTransportationSuccess, fetchTransportationFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage,setTransportationFee} = slice.actions;


export const PostTransportationRequest = (postData) => async (dispatch) => {
    dispatch(fetchTransportationData());

    try {
        const response = await fetchWithAuthPost(API_PATH + "/transportation_fee", "POST", token(), postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchTransportationSuccess([responseData.transportationFees]));
            dispatch(setSuccessMessage("SuccessfulI Updated!"));
            dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));

    }
};


export default slice.reducer;
