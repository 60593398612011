/*
    authors: Naveen
    date: 17-10-2023
    update:17-10-2023 
    version: 1.0
*/

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SuccessMessagePopup = ({ show, onClose, successMessage }) => {

    return (
        <Modal show={show} onHide={onClose} size="sm" centered style={{ fontSize: "1rem", color: "green", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Modal.Body>
                <center><p>{successMessage}</p></center>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <center>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </center>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessMessagePopup;
