import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown";
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import DateInputField from '../../ReusableFields/DateFields/DateField';
import { postPtmDates, setShowSuccessMessage, setShowErrorMessage } from './slice/ptmDatesSlicer';
import PtmDatesView from './ptmDatesView'

const PtmDates = () => {
    const [formData, setFormData] = useState({ date: '', classId: '' });
    const [validated, setValidated] = useState(false);
    const [dropDowns, setDropDowns] = useState({ class: '', sections: '' });
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedClassId, setSelectedClassId] = useState();
    const [selectedSection, setSelectedSection] = useState([]);
    const [selectedClassData, setSelectedClassData] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState("");
    const roleIds = Number(sessionStorage.getItem("roleId"))
    const [selectedDate2] = useState(new Date());
    const [formErrors, setFormErrors] = useState({ ClassActivity: false, class: false, sections: false });
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
    const Ids = Number(franchiseeSchoolId);
    const dispatch = useDispatch();
    const showSuccessMsg = useSelector((state) => state.ptmDates.showSuccessMessage);
    const successMsg = useSelector((state) => state.ptmDates.successMessage);
    const showErrorMsg = useSelector((state) => state.ptmDates.showErrorMessage);
    const errorMsg = useSelector((state) => state.ptmDates.errorMessage);
    const tokenId = token();
    const [classData, setClassData] = useState([]);



    const formatDate2 = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const classesClassSectionData =
        useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

    useEffect(() => {
        if (classesClassSectionData.length === 0) {
            dispatch(fetchFranchiseClasswiseSectionsDataOnce());
        }
    }, [classesClassSectionData, dispatch]);


    const handleChange = (name, value) => {
        if (name === "ptm") {
            setFormData({ ...formData, [name]: value.value || value });
            // Optionally, update other state or perform additional actions here
        } else if (name === "classId") {
            const classIdValue = value.value || value;
            setFormData({ ...formData, [name]: classIdValue });
            handleClassChange(classIdValue);
            setDropDowns({ ...dropDowns, class: value.label });
        } else if (name === "sectionId") {
            setSelectedSectionId(value.value);
            setDropDowns(prev => ({ ...prev, sections: value.label }));
        }
    }


    const handleClassChange = (selectedClassId) => {
        let classSectionData;
        if (roleIds === 6) {
            classSectionData = classData.find((c) => c.id === selectedClassId);
        } else {
            classSectionData = classesClassSectionData.find((c) => c.id === selectedClassId);
        }

        if (classSectionData) {
            setSelectedClassData(classSectionData);
            if (classSectionData.classSections.length > 0) {
                setSelectedSectionId(classSectionData.classSections[0].classesclassSection);
            } else if (classSectionData.classSections.length === 0) {
                setSelectedClassId(classSectionData.classesclassSection);
            } else {
                setSelectedSectionId('');
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newFormErrors = {
            class: !dropDowns.class,
            sections: !dropDowns.sections,
        };

        setFormErrors(newFormErrors);
        const hasErrors = Object.values(newFormErrors).some((error) => error);

        if (hasErrors) {
            setValidated(true);
            // return
        }

        const sendData = {
            date: (formData.date),
            classSectionId: selectedSectionId ? selectedSectionId : selectedClassId,
            ptm: formData.ptm
        };

        let apiParams = roleIds === 6 ? [Ids, sendData] : [Ids, sendData];
        dispatch(postPtmDates(...apiParams));
    };

    const handleClear = () => {
        setFormData({ name: '' });
        setFormErrors({ programId: false });
        setValidated(false);
        setDropDowns({});
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    useEffect(() => {
        if (selectedClassData?.classSections?.length == 0) {
            setSelectedSectionId(null)
        }
    }, [selectedClassData])

    const handleDateChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, "date": value })
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <div className="content-header">PTM Dates</div>
                <Row style={{ justifyContent: "center" }}>

                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <SelectDropdown
                            name="ptm"
                            placeholder="Select PTM"
                            label="PTM Date"
                            options={[
                                { value: "PTM 1", label: "PTM 1" },
                                { value: "PTM 2", label: "PTM 2" },
                                { value: "PTM 3", label: "PTM 3" },
                                { value: "PTM 4", label: "PTM 4" }
                            ]}
                            value={formData.ptm ? { value: formData.ptm, label: formData.ptm } : null}
                            onChange={(e) => handleChange("ptm", e)}
                            isClearable
                        />
                        {formErrors.ptm && <div className="error">PTM Date is required</div>}
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <DateInputField
                            name='date'
                            value={formData.date}
                            onChange={handleDateChange}
                            feedbackMessage="date is required"
                            validated={validated}
                            placeholder='Today Date'
                        />
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <SelectDropdown
                            label="Class"
                            value={dropDowns.class ? { value: dropDowns.class, label: dropDowns.class } : null}
                            placeholder="Select class"
                            onChange={(selectedOption) => {
                                handleChange("classId", selectedOption);
                            }}
                            options={roleIds === 6 && classData
                                ? classData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                                : classesClassSectionData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                            }
                            required
                            feedbackMessage="Please select a valid class"
                        />
                        {formErrors.class && (
                            <div className="error-message">Please select a valid class.</div>
                        )}
                    </Col>

                    {formData.classId &&
                        selectedClassData &&
                        selectedClassData?.classSections?.length > 0 && (
                            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                <SelectDropdown
                                    label="Section"
                                    value={
                                        dropDowns.sections
                                            ? {
                                                value: dropDowns.sections,
                                                label: dropDowns.sections,
                                            }
                                            : null
                                    }
                                    placeholder="Select section"
                                    onChange={(selectedOption) => {
                                        handleChange("sectionId", selectedOption);
                                    }}
                                    options={selectedClassData.classSections?.map((section) => ({
                                        key: section.id,
                                        value: section.classesclassSection,
                                        label: section.name,
                                    }))}
                                    required
                                    feedbackMessage="Please select a valid section"
                                />
                                {formErrors.sections && (
                                    <div className="error-message">Please Select Section.</div>
                                )}
                            </Col>
                        )}
                </Row>

                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0.5%",
                        marginTop: "0%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>
            <br></br>
            <br></br>
            <p className="content-header">View</p>
            <PtmDatesView />
        </>
    );
};

export default PtmDates;
