/*
    authors: Rajeev
    date: 23-10-2023
    update:23-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";
import "../StudentDetails/Student.css"; // Import your custom CSS file
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SelectDropdown from "../SelectDropdown";
import ExpenseEntryTable from "../Franchise/expenseEntryData/ExpenseEntryTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimingDataOnce } from "./slicer/viewTimingSlicer";
import { postTimeTableRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addTimeTableslicer";
import { fetchFranchiseClassSectionOnce } from "./slicer/franchiseClassSectionSlicer";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchClassSchoolDataOnce } from "../Franchise/slice/viewSchoolSlice";
import { SelectingValuesDropDownField } from "../../ReusableFields/DropDown/multipleSelectDropDown";
import { fetchviewClassSectionDataOnce } from "./slicer/getClassSectionsSlicer";
const TimeTable = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [dropDowns, setDropDowns] = useState({});
  const [dropDowns1, setDropDowns1] = useState({});
  const [formErrors, setFormErrors] = useState({
    class: false,
    day: false,
    classesSectionsSubject: false,
    title: false,
    classesId: false,
    schoolName: false
  });
  const [subjects, setSubjects] = useState([]);
  const [data, setData] = useState([]);
  const [selectedClassSection, setSelectedClassSection] = useState(null);
  const [classSections, setClassSections] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [sendingData, setSendingData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [showTable, setShowTable] = useState(false);

  const showSuccessMsg = useSelector((state) => state.addTimeTable.showSuccessMessage);
  const successMsg = useSelector((state) => state.addTimeTable.successMessage);

  const showErrorMsg = useSelector((state) => state.addTimeTable.showErrorMessage);
  const errorMsg = useSelector((state) => state.addTimeTable.errorMessage);
  const days = [
    { id: 1, day: "Monday" },
    { id: 2, day: "Tuesday" },
    { id: 3, day: "Wednesday" },
    { id: 4, day: "Thursday" },
    { id: 5, day: "Friday" },
    { id: 6, day: "Saturday" },
    { id: 7, day: "Sunday" },
  ];

  const months = {
    1: { name: "January", index: 0 },
    2: { name: "February", index: 1 },
    3: { name: "March", index: 2 },
    4: { name: "April", index: 3 },
    5: { name: "May", index: 4 },
    6: { name: "June", index: 5 },
    7: { name: "July", index: 6 },
    8: { name: "August", index: 7 },
    9: { name: "September", index: 8 },
    10: { name: "October", index: 9 },
    11: { name: "November", index: 10 },
    12: { name: "December", index: 11 },
  };

  const columns = [
    { field: "day", header: "Day" },
    { field: "title", header: "Time" },
    { field: "class", header: "Class" },
    { field: "section", header: "Section" },
    { field: "classesSectionsSubject", header: "Subject" },
  ];

  const monthOptions = Object.keys(months).map((key) => ({
    id: parseInt(key),
    value: months[key].name,
    label: months[key].name
  }));

  const apiData = useSelector((state) => state.viewTimings.data) || [];
  const viewSchoolData = useSelector((state) => state.viewSchoolData.data) || [];
  const dispatch = useDispatch();


  const classesClassSectionData = useSelector((state) => state.viewClassSectionData.data) || [];

  useEffect(() => {
    if (formData.schoolId) {
      dispatch(fetchviewClassSectionDataOnce(formData.schoolId));
    }
  }, [formData, dispatch]);

  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchTimingDataOnce());
    }

  }, [apiData, dispatch]);


  useEffect(() => {
    if (viewSchoolData.length == 0) {
      dispatch(fetchClassSchoolDataOnce());
    }
  }, [viewSchoolData, dispatch]);

  const tokenId = token();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          classId: selectedClass,
        });
        const url = `${API_PATH}/class/${selectedClass}/subjects`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setSubjects(responseData);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed
      }
    };
    if (selectedClass) {
      fetchData();
    }
  }, [selectedClass, tokenId]);

  const handleAddRow = () => {


    const newFormErrors = {
      // monthName: !dropDowns.monthName,
      classesId: !formData.classesId,
      schoolName: !dropDowns.schoolName,
      class: !dropDowns.class,
      day: !dropDowns.day,
      classesSectionsSubject: !dropDowns1.classesSectionsSubject,
      title: !dropDowns1.title,
    };

    setFormErrors(newFormErrors);

    const hasErrors = Object.values(newFormErrors).some(error => error);


    if (hasErrors) {

      setValidated(true);
      return;
    }


    if (
      !newFormErrors.class &&
      !newFormErrors.day &&
      !newFormErrors.classesSectionsSubject
    ) {

      const newData = {
        title: dropDowns1.title,
        day: dropDowns.day,
        classesSectionsSubject: dropDowns1.classesSectionsSubject.label,
        class: dropDowns.class,
        section: dropDowns.sections || "--",
      };

      setData([...data, newData]);

      const sendApiData = {
        classesClassSectionId: selectedSection,
        subjectId: formData.subjectId,
        day: formData.dayId,
        timingId: formData.titleId,
      };

      setSendingData([...sendingData, sendApiData]);


      setDropDowns1({});
    }
  };
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);

    setShowTable(false);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);

    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
    setDropDowns({ ...dropDowns, sections: "" });

  }, [selectedClass]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const newFormErrors = {
      class: !dropDowns.class,
      day: !dropDowns.day,
      classesSectionsSubject: !dropDowns1.subjectId,
      title: !dropDowns1.title,
    };

    setFormErrors(newFormErrors);
    const sendData = {
      franchiseeSchoolId: formData.schoolId,
      month: formData.monthName,
      tableData: sendingData,
    };
    if (sendData.tableData.length !== 0) {
      dispatch(postTimeTableRequest(sendData));


      setData([]);
      setFormData({});
      setDropDowns1({});
      setDropDowns({ class: "" })
      setSelectedClassSection({});
    };
  }

  const handleClear = () => {
    setDropDowns({ class: false });

    setDropDowns1({});
    setClassSections({});
    setFormErrors({
      day: false,
      classesSectionsSubject: false,
      title: false,
    });
    setFormData({});
    setSelectedClassId("");
    setSelectedSubjectId("");
    setData([]);
  };

  const handleCancel1 = () => {
    setDropDowns({ class: '', sections: '' });
    setDropDowns1({});
    setClassSections({});
    setFormErrors({
      class: false,
      day: false,
      classesSectionsSubject: false,
      fromTime: false,
      toTime: false,
    });
    setSelectedClassId("");
    setSelectedSubjectId("");
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };
  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };


  return (
    <>
      <div className="content-header">TimeTable</div>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>


          <Col xs={12} md={6} lg={4} className="rowCss">
            <SelectingValuesDropDownField
              isMulti={true}
              className="input-style"
              value={
                dropDowns.schoolName
                  ? { value: dropDowns.schoolName, label: dropDowns.schoolName }
                  : null
              }
              options={viewSchoolData.map(option => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              onChange={(selectedOptions) => {
                setFormData({ ...formData, schoolId: selectedOptions.map(option => option.id) });
                setDropDowns({ ...dropDowns, schoolName: selectedOptions.map(option => option.value) });
                setFormErrors({ ...formErrors, schoolName: false });
              }}
              placeholder="School Name"
            />
            {formErrors.schoolName && (
              <div className="error-message">
                Please Select School Name .
              </div>
            )}
          </Col>


          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectingValuesDropDownField
              isMulti={true}
              value={formData.monthName}
              className="input-style"
              onChange={(selectedOptions) => {
                const selectedIds = selectedOptions.map(
                  (selectedOption) => selectedOption.id
                );

                const selectMonths = selectedOptions.map(
                  (selectedOption) => selectedOption.value
                );
                setFormData({
                  ...formData,
                  classesId: selectedIds,
                  monthName: selectMonths,
                });
                setFormErrors({ ...formErrors, classesId: false });
              }}
              placeholder="Select months"
              options={monthOptions}
              feedbackMessage="Please select months."
            />
            {formErrors.classesId && (
              <div className="error-message">Please Select months.</div>
            )}
          </Col>
          <Col xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.day
                  ? { value: dropDowns.day, label: dropDowns.day }
                  : null
              }
              options={days.map((option, index) => ({
                id: option.id,
                value: option.day,
                label: option.day,
              }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, dayId: selectedOption.value });
                setDropDowns({ ...dropDowns, day: selectedOption.value });
                setFormErrors({ ...formErrors, day: false });
              }}
              placeholder="Select a day"
            />
            {formErrors.day && (
              <div className="error-message">Please Select day .</div>
            )}
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              label="Class"
              // value={formData.classId || ""}
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : dropDowns.class
              }
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              options={classesClassSectionData.map((classItem) => ({
                value: classItem.id,
                label: classItem.name,

              }))}
              required
              feedbackMessage="Please select a valid class"

            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {formData.classId &&
            selectedClassData &&
            selectedClassData.classSections.length > 0 && (
              <Col xs={12} md={6} lg={4} className="rowCss">
                <SelectDropdown
                  label="Section"
                  // value={formData.sectionId || ""}
                  value={
                    dropDowns.sections
                      ? { value: dropDowns.sections, label: dropDowns.sections }
                      : dropDowns.sections
                  }
                  placeholder="Select section"
                  onChange={(selectedOption) => {
                    handleChange("sectionId", selectedOption);
                    setSelectedSection(selectedOption.value);
                    setDropDowns({
                      ...dropDowns,
                      sections: selectedOption.label,
                    });
                    setFormErrors({ ...formErrors, sections: false });
                  }}
                  options={selectedClassData.classSections.map((section) => ({
                    key: section.id,
                    value: section.classesclassSection,
                    label: section.name,
                  }))}
                  required
                  feedbackMessage="Please select a valid section"
                />
                {formErrors.sections && (
                  <div className="error-message">Please Select Section.</div>
                )}
              </Col>
            )}


          <Col xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              label="Subject"
              value={
                dropDowns1.classesSectionsSubject || ""
              }
              placeholder="select subject"

              options={subjects.map((subject) => ({
                value: subject.id,
                label: subject.name,
              }))}
              onChange={(selectedOption) => {
                handleChange("subjectId", selectedOption)
                setFormData({ ...formData, subjectId: selectedOption.value });
                setDropDowns1({ ...dropDowns1, classesSectionsSubject: selectedOption });
              }}
              required
              feedbackMessage="Please select a valid subject"
            />
            {formErrors.classesSectionsSubject && (
              <div className="error-message">Please Select subject.</div>
            )}
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns1.title
                  ? { value: dropDowns1.title, label: dropDowns1.title }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.title,
                label: option.title,
              }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, titleId: selectedOption.id });
                setDropDowns1({ ...dropDowns1, title: selectedOption.value });
                setFormErrors({ ...formErrors, title: false });
              }}
              placeholder="Select  Time"
            // validated={validated}
            />
            {formErrors.title && (
              <div className="error-message">Please Select Time .</div>
            )}
          </Col>
        </Row>
        <Row className="rowCss">

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

            }}
          >
            <button
              className="clear-button-figma-div"
              type="button"
              onClick={handleCancel1}
            >
              {" "}
              Clear{" "}
            </button>
            <button
              className="save-button-figma-div"
              type="button" // Change to type="button" to prevent form submission
              onClick={handleAddRow} // Call handleAddRow when the button is clicked
            >
              Add
            </button>
          </div>

        </Row>

        <Row style={{ marginTop: "60px" }}>
          <ExpenseEntryTable columns={columns} data={data} />
        </Row>


        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>

    </>
  );
};

export default TimeTable;