import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice(
    {
        name: 'currentPage',
        initialState: {
            data: '0',
            isLoading: false,
            hasError: false,
        },
        reducers: {

            currentPage: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }
        },
    },



);

export const { currentPage } = slice.actions;


export default slice.reducer;