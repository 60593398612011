import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import DateInputField from '../../ReusableFields/DateFields/DateField';
import InputField from '../../ReusableFields/TextFields/TextInputField';
import { amountWithDecimalPattern, validationRegexAlphaNumeric, validationRegexOnlyAlpha } from '../reUsableForm/regex';
import AmountField from '../../ReusableFields/NumberFields/AmountField';
import AddressField from '../../ReusableFields/DescriptionFields/AddressField';
import ExpenseEntryTable from '../Franchise/expenseEntryData/ExpenseEntryTable';
// import { columns, data } from './expenseEntryData/staticData';
import { validationRegexAmount } from '../../ReusableFields/Rejex';
import { useDispatch, useSelector } from 'react-redux';
import { addExpenses, clearTable, CLSTIExpenseEntry } from './slice/expenseEntrySlicer';
import { postExpensesEntries } from './slice/expenseEntrySlicer';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import SelectDropdown from "../SelectDropdown";
import { fetchExpensesTypeView } from "./slice/expensesTypeViewSlicer";

const ExpenseEntry = () => {
    const FranchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
    const franchiseeSchoolId = Number(FranchiseeSchoolId);
    const defaultData = {
        date: new Date().toISOString().slice(0, 10),
        amount: '',
        description: '',
        expenseTypeId: null,
    }
    const [validated, setValidated] = useState(false);
    const [expense, setExpense] = useState(defaultData);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const dispatch = useDispatch();
    const addedExpenses = useSelector((state) => state.expenseEntrySlicer.expenseData);
    const postExpensesEntriesObj = useSelector(state => state.expenseEntrySlicer.postExpensesStates);
    const loading = postExpensesEntriesObj.loading;
    const error = postExpensesEntriesObj.error;
    const apiDataa = useSelector((state) => state.expensesTypeViewSlicer.data) || [];
    const postData = useSelector((state) => state.expensesTypeSlicer.data) || [];
    const combinedData = [...(apiDataa || []), ...postData];

    const handleChangeExpenseType = (selectedOption) => {
        setExpense(prevFormData => ({
            ...prevFormData,
            expenseTypeId: selectedOption.id, label: selectedOption.label
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setExpense({ ...expense, [name]: value });
    };

    const postTableData = (e) => {
        e.preventDefault();

        // Ensure that the expenseTypeId is correctly set from the form state
        const required = addedExpenses.map((each) => ({
            ...each,
            // expenseTypeId: expense.expenseTypeId?.id || each.expenseTypeId?.id || null, // Use the ID from the form or fall back to the existing one in the addedExpenses
        }));

        const requiredData = {
            franchiseeSchoolId, // Add franchiseeSchoolId to the payload
            expenseData: required, // Array of expenses with expenseTypeId
        };


        if (requiredData.expenseData.length > 0) { // Ensure every item has an expenseTypeId before dispatching

            dispatch(postExpensesEntries(requiredData));
        } else {
            setShowErrorMsg(true); // Show error if any item is missing expenseTypeId
        }
    };



    const storeResponse = () => {
        if (loading === 'fulfilled') {
            setShowSuccessMsg(true)
        } else if (loading === 'rejected') {
            setShowErrorMsg(true);
        }
    }
    useEffect(() => {
        storeResponse()
    }, [loading])
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        

        const submissionData = {
            ...expense,
            franchiseeSchoolId: franchiseeSchoolId,
        };
        dispatch(addExpenses(submissionData));
        handleClearForm();
    }
    const handleClearForm = () => {
        setValidated(false);
        setExpense(defaultData)
    }
    const handleClearTable = () => {
        dispatch(clearTable())
    }
    useEffect(() => {
        dispatch(fetchExpensesTypeView());
    }, [dispatch]);

    const [formErrors, setFormErrors] = useState({
        fromDate: false,
        toDate: false,
        expenseTypeId: false,
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    const columns = [
        { field: 'id', header: 'Sr.No' },
        { field: 'date', header: 'Date' },
        { field: 'expenseTypeId', header: 'Expense Type' },
        { field: 'amount', header: 'Amount' },
        { field: 'description', header: 'Description' },
    ];

    const getExpenseWithNames = (expenses, types) => {
        const typeMap = new Map(types.map(type => [type.id, type.name]));
        return expenses.map(expense => ({
            ...expense,
            expenseTypeId: typeMap.get(expense.expenseTypeId) || 'Unknown', // Map ID to name
            date: formatDate(expense.date)
        }));
    };

    return (
        <>
            <div className='content-header'>Expenditure Entry</div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3}>
                        <DateInputField
                            name='date'
                            value={expense.date}
                            onChange={handleChange}
                            feedbackMessage="date is required"
                            validated={validated}
                            placeholder='Today Date'
                        />
                    </Col>

                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={expense.expenseTypeId ? { id: expense.expenseTypeId, label: expense.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            // onChange={(selectedOption) => {
                            //     setExpense(prevFormData => ({
                            //         ...prevFormData,
                            //         expenseTypeId: selectedOption
                            //     }));
                            //     setFormErrors(prevFormErrors => ({ ...prevFormErrors, expenseTypeId: !selectedOption }));
                            // }}
                            onChange={handleChangeExpenseType} F
                            placeholder="Select expenses"
                        />
                        {formErrors.expenseTypeId && (
                            <div className="error-message">
                                Please Select expenses.
                            </div>
                        )}
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3}>
                        <AmountField
                            name='amount'
                            placeholder='Expense Amount'
                            value={expense.amount}
                            onChange={handleChange}
                            feedbackMessage='Expense Amount is Required!'
                            validated={validated}
                            validationRegex={amountWithDecimalPattern}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3}>
                        <AddressField
                            name='description'
                            address={expense.description}
                            onChange={handleChange}
                            placeholder='Add Description for expense'
                            validated={validated}
                            feedbackMessage='Expense Description is Required!'
                            validationRegex={validationRegexAlphaNumeric}
                        />
                    </Col>
                    <Col >
                        <button type='submit' className="save-button-figma-div">ADD</button>
                    </Col>
                    <Col>
                        <button onClick={handleClearForm} className="clear-button-figma-div">CLEAR</button>
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3}></Col>
                </Row>
            </Form>
            <ExpenseEntryTable columns={columns} data={getExpenseWithNames(addedExpenses, combinedData)} />
            <Row>
                <Col sm={12} xs={12} md={6} lg={3}></Col>
                <Col sm={12} xs={12} md={6} lg={3}></Col>
                <Col><button className="save-button-figma-div" onClick={postTableData} type='submit'>Submit</button></Col>
                {/* <Col><button className="clear-button-figma-div" onClick={handleClearTable}>Clear Table</button></Col> */}
            </Row>
            {
                postExpensesEntriesObj?.loading === 'fulfilled' &&
                showSuccessMsg &&
                <SuccessMessagePopup show={showSuccessMsg} onClose={() => { dispatch(CLSTIExpenseEntry()); setShowSuccessMsg(false); dispatch(clearTable()) }} successMessage="Expenses submitted successfully" />

                // add handleClear function to successpopup in the last!
            }
            {

                postExpensesEntriesObj?.loading === 'rejected' && showErrorMsg ||
                showErrorMsg &&
                <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(CLSTIExpenseEntry()); setShowErrorMsg(false) }}
                    errorMessage={loading === "rejected" ?
                        (Array.isArray(error) ?
                            error?.map(e => { return <li>{e.message}</li> }) :
                            error) : "Enter Expense Data!"
                    } />
            }
        </>
    )
}

export default ExpenseEntry;