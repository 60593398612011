import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFetchFunction } from "../../parent/parentLoginSlicer/parentLoginRequests";
import { postRequestOptions } from "../../Teachers/teacherSlicers/allRequests";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

export const postCompetetionAwards = createAsyncThunk('competetionPost',async(payload,thunkAPI)=>{
    try{
        const tokenId = token();
    const fetched = await fetch(`${API_PATH}/competitionsAndAward`,{
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${tokenId}`
        },
        body: JSON.stringify(payload)
    });
    const data = fetched.json();
    return data;
    }catch(err){
        return thunkAPI.rejectWithValue(err.message);
    }
})

export const getCompetetionAwards = createAsyncThunk('competitionGet',async (query_param, thunkAPI)=>{
    try{
        // const fetched = await getFetchFunction('competitionsAndAwards');
        const fetched = await getFetchFunction(`competitionsAndAwards?isActive=true&franchiseeSchoolId=${query_param.franchiseeSchoolId}&${query_param?.hasWonAndStudentIds}`);
        return fetched;
    }catch(err){
        return thunkAPI.rejectWithValue(err.message)
    }
})
export const putCompetetionAward = createAsyncThunk('putCompetetion',async (payload,thunkApi)=>{
    const tokenId = token();

    const putRequestOptions = {
        method:'PUT',
        headers:{'Content-type':'application/json','Authorization':`Bearer ${tokenId}`},
        body:JSON.stringify(payload.payload)
    }
    try{
        const fetched = await fetch(`${API_PATH}/competitionsAndAward/${payload.id}`,putRequestOptions);
        const data = fetched.json();
        return data;
    }catch(err){
        return thunkApi.rejectWithValue(err.message)
    }
})
export const postCompetetionName = createAsyncThunk('competetionNamePost',async(payload,thunkAPI)=>{
    try{
    const fetched = await fetch(`${API_PATH}/competition`,postRequestOptions(payload));
    const data = fetched.json();
    return data;
    }catch(err){
        return thunkAPI.rejectWithValue(err.message);
    }
})

export const getCompetetionNames = createAsyncThunk('competitionNamesGet',async (_,thunkAPI)=>{
    try{
        const fetched = await getFetchFunction('competitions');
        
        // const data = await fetched.json();
        return fetched;
    }catch(err){
        return thunkAPI.rejectWithValue(err.message)
    }
})
