/*
    authors: Revanth
    date: 21-10-2023
    update:21-10-2023 
    version: 1.0
*/

import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import './franchiseEnquiry.css'
import TextInputField from '../reUsableForm/textInputField'
import { emailRegex, regexOnly, validationRegexAddress, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea } from '../reUsableForm/regex'
import { useDispatch, useSelector } from 'react-redux'
import { franchiseEnquiryPost, setShowErrorMessage, setShowSuccessMessage } from './franchiseSlice/franchiseEnquirySlicer'
import { fetchDataOnce } from '../city/slice/viewCitySlice'
import { fetchDataOnceProgram } from '../programs/slice/viewProgramSlice'
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField'
import EmailInputField from '../../ReusableFields/EmailFields/EmailField'
import { fetchAreaData, fetchAreaGet } from '../EnquiryDetails/slice/areaSlicer'
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage'
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage'
import { SelectingValuesDropDownField } from '../../ReusableFields/DropDown/multipleSelectDropDown'

const FranchiseEntry = () => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [validated, setValidated] = useState(false);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedInvestment, setSelectedInvestment] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');

    const [formErrors, setFormErrors] = useState({
        name: false, occupation: false, address: false, cityId: false, contactNumber: false, email: false, areaId: false, investmentCapacity: false, programId: false, reference: false,
    })
    const showSuccessMsg = useSelector((state) => state.franchiseEnquirySlicer.showSuccessMessage);
    const successMsg = useSelector((state) => state.franchiseEnquirySlicer.successMessage);

    const showErrorMsg = useSelector((state) => state.franchiseEnquirySlicer.showErrorMessage);
    const errorMsg = useSelector((state) => state.franchiseEnquirySlicer.errorMessage);



    const investmentCapacityOptions = [
        { id: 1, value: '100000-200000', label: '1,00,000 - 2,00,000' },
        { id: 2, value: '200000-300000', label: '2,00,000 - 3,00,000' },
        { id: 3, value: '300000-400000', label: '3,00,000 - 4,00,000' },
        { id: 4, value: '400000-500000', label: '4,00,000 - 5,00,000' },

    ];


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`
    };

    const formatDate1 = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `Date : ${day}-${month}-${year}`
    };

    const selectDate = formatDate(selectedDate)
    const [formData, setFormData] = useState({
        date: selectDate,
        name: '',
        contactNumber: '',
        email: '',
        reference: '',
        occupation: '',
        address: '',
        cityId: '',
        areaId: '',
        investmentCapacity: '',
        programId: '',

    })
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        const newFormErrors = {
            // date: selectDate,
            name: !formData.name,
            cityId: !formData.cityId,
            occupation: !formData.occupation,
            address: !formData.address,
            contactNumber: !formData.contactNumber,
            email: !formData.email,
            areaId: !formData.areaId,
            programId: formData.programId.length === 0,
            investmentCapacity: !formData.investmentCapacity,
            reference: !formData.reference,
        };

        const hasErrors = Object.values(newFormErrors).some((error) => error);
        setFormErrors(newFormErrors);


        if (hasErrors) {
            setValidated(true);
            return;
        }

        setSelectedDate(new Date());


        const cityId = formData.cityId.id;
        formData.cityId = cityId;

        const areaId = formData.areaId.id;
        formData.areaId = areaId;

        const investmentCapacity = formData.investmentCapacity.id;
        formData.investmentCapacity = investmentCapacity;

        dispatch(franchiseEnquiryPost({ date: selectDate, name: formData.name, contactNumber: formData.contactNumber, email: formData.email, reference: formData.reference, occupation: formData.occupation, address: formData.address, cityId: formData.cityId, areaId: formData.areaId, investmentCapacity: formData.investmentCapacity, programId: formData.programId, }));
    }

    const handleCancel = () => {
        setFormData({
            contactNumber: "",
            studentName: "",
            name: "",
            email: '',
        });
        setFormErrors({})
        setValidated(false);
    }

    const apiData = useSelector((state) => state.ViewCity.data) || [];
    const areaApiData = useSelector((state) => state.areaDetails.data) || [];
    const ModelOptedApiData = useSelector((state) => state.viewProgram.data) || [];

    useEffect(() => {
        dispatch(fetchAreaData());
        dispatch(fetchDataOnceProgram());
        dispatch(fetchDataOnce());
    }, [dispatch]);


    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div>
                <div className='content-header'>Franchise Enquiry</div>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss" >
                        <TextInputField value={formatDate1(selectedDate)} />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="name"
                            placeholder="Name"
                            textType="Alphabet"
                            required
                            validated={validated}
                            validationRegex={validationRegexOnlyAlpha}
                            onChange={(event) => { setFormData({ ...formData, name: event.target.value }) }}
                            value={formData.name}
                            feedbackMessage="Please enter name."
                            validationErrorMessage="Please enter a valid name."
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="occupation "
                            placeholder="Occupation "
                            textType="Alphabet"
                            required
                            validated={validated}
                            validationRegex={validationRegexOnlyAlpha}
                            onChange={(event) => { setFormData({ ...formData, occupation: event.target.value }) }}
                            value={formData.occupation}
                            feedbackMessage="Please enter occupation."
                            validationErrorMessage="Please enter a valid occupation."
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="address "
                            placeholder="Address"
                            textType="Alphabet"
                            required
                            validated={validated}
                            validationRegex={validationRegexAddress}
                            onChange={(event) => { setFormData({ ...formData, address: event.target.value }) }}
                            value={formData.address}
                            feedbackMessage="Please enter address."
                            validationErrorMessage="Please enter a valid address."
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            // value={selectedCity}
                            value={formData.cityId ? { id: formData.cityId.id, label: formData.cityId.label } : null}

                            options={apiData.map((city) => ({
                                id: city.id,
                                label: city.name,
                                value: city.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, cityId: selectedOption });
                                // setSelectedCity(selectedOption.name)
                                setFormErrors({ ...formErrors, cityId: !selectedOption });
                            }}
                            placeholder="Select city"
                        />
                        {formErrors.cityId && (
                            <div className="error-message">
                                Please select city .
                            </div>
                        )}
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="contact Number"
                            textType="Alphabet"
                            value={formData.contactNumber}
                            onChange={(event) => { setFormData({ ...formData, contactNumber: Number(event.target.value) }) }}
                            required
                            placeholder="Mobile Number*"
                            validated={validated}
                            feedbackMessage="Please enter contact number."
                            validationRegex={validationRegexMobileNo}
                            validationErrorMessage="Please enter a 10 digits contact number."
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <EmailInputField
                            label="Email"
                            name="email"
                            required
                            value={formData.email}
                            onChange={(event) => { setFormData({ ...formData, email: event.target.value }) }}
                            placeholder="Email"
                            validated={validated}
                            feedbackMessage="Please enter email"
                            validationRegex={emailRegex}
                            validationErrorMessage="Please enter valid email."
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                        <SearchableDropDownField
                            isSearchable
                            // value={selectedArea}
                            value={formData.areaId ? { id: formData.areaId.id, label: formData.areaId.label } : null}
                            options={areaApiData.map((location) => ({ id: location.id, label: location.name, value: location.id }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, areaId: selectedOption });
                                // setSelectedArea(selectedOption.name)
                                setFormErrors({ ...formErrors, areaId: !selectedOption });
                            }}
                            placeholder="Select Location Interested"
                        />
                        {formErrors.areaId && (
                            <div className="error-message">
                                Please select location interested.
                            </div>
                        )}
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            // value={selectedInvestment}
                            value={formData.investmentCapacity ? { id: formData.investmentCapacity.id, label: formData.investmentCapacity.label } : null}
                            options={investmentCapacityOptions.map((investment) => ({ id: investment.id, label: investment.label, value: investment.id }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, investmentCapacity: selectedOption });
                                // setSelectedInvestment(selectedOption.name)
                                setFormErrors({ ...formErrors, investmentCapacity: !selectedOption });
                            }}
                            placeholder="Investment Capacity"
                        />
                        {formErrors.investmentCapacity && (
                            <div className="error-message">
                                Please select investment capacity.
                            </div>
                        )}
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <SelectingValuesDropDownField
                            isMulti={true}
                            value={formData.programNames}
                            options={ModelOptedApiData.map((program) => ({ id: program.id, label: program.name, value: program.id }))}
                            onChange={(selectedOption) => {
                                const selectedIds = selectedOption.map(
                                    (selectedOption) => selectedOption.id
                                );
                                setFormData({ ...formData, programId: selectedIds, programNames: selectedOption });
                                // setSelectedProgram(selectedOption.name)
                                setFormErrors({ ...formErrors, programId: !selectedIds });
                            }}
                            placeholder="Model Opted"
                        />
                        {formErrors.programId && (
                            <div className="error-message">
                                Please select model opted .
                            </div>
                        )}
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="Reference"
                            textType="Alphabet"
                            value={formData.reference}
                            onChange={(event) => { setFormData({ ...formData, reference: event.target.value }) }}
                            required
                            placeholder="Reference*"
                            validated={validated}
                            feedbackMessage="Please enter reference."
                            validationRegex={validationRegexOnlyAlpha}
                            validationErrorMessage="Please enter a valid reference."
                        />
                    </Col>
                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div style={{ display: "flex", justifyContent: "center", marginLeft: "-14%" }}>
                    <button className='clear-button-figma-div' type='button' onClick={handleCancel}>Clear</button>
                    <button className='save-button-figma-div' type='submit'>Submit</button>
                </div>
            </div>
        </Form>
    )
}

export default FranchiseEntry;
