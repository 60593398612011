
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

export const postLane = createAsyncThunk('postlane', async (payload, thunkApi) => {


    try {
        const posting = await fetch(`${API_PATH}/lane`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token()}`
            },
            body: JSON.stringify(payload)
        });
        const data = posting.json();
        return data;
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
})