import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { responseFormat } from "../../Auth/errorResponse";
import { fetchWithAuthPost } from "../../Auth/dependency_post";

const slice = createSlice(
    {
        name: "royaltyAmount",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            successMessage: '',
            showSuccessMessage: false,
            errorMessage: '',
            showErrorMessage: false,
        },
        reducers: {
            fetchSchoolData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchSchoolSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchSchoolFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },
);

export const { fetchSchoolData, fetchSchoolSuccess, fetchSchoolFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;


export const postSchoolRequest = (postData) => async (dispatch) => {
    dispatch(fetchSchoolData());
    const tokenId = token();
    try {
        const response = await fetchWithAuthPost (API_PATH + "/royality","POST", tokenId, postData); 
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else{
        const responseData = await response;
        dispatch(fetchSchoolSuccess([responseData.franchiseeRoyalty]));
        dispatch(setSuccessMessage("Successfully Updated!"));
        dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;