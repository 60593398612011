import React, { useEffect, useState } from 'react'
import TextInputField from '../reUsableForm/textInputField';
import ReadOnlyTextField from '../../ReusableFields/TextFields/ReadOnlyTextField';
import { Col, Form, Row } from 'react-bootstrap';
import { regexOnly, schoolCode, validationRegexAlphaNumeric, validationRegexClass } from '../reUsableForm/regex';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { useSelector } from 'react-redux';
import { CLSTIPostLane } from './slice/postLaneSlicer';
import { useDispatch } from 'react-redux';
import { postLane } from './slice/laneRequests';
const CreateLane = () => {
    // const defaultData = {
    //     from: '',
    //     to: '',
    //     title: function () {
    //         return this.from ? this.from + ' to ' + this.to : ''
    //     }
    // }
    const [formData, setFormData] = useState({
        title: '',
        from: '',
        stops: "",
        to: "",
    });
    const [validated, setValidated] = useState(false);
    const postLaneObj = useSelector(state => state.postLaneSlicer.postLane);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const dispatch = useDispatch();

    const loading = postLaneObj.loading;
    const errors = postLaneObj.error;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        const requiredData = { ...formData };
        dispatch(postLane(requiredData))
        handleClear()
    }
    const handleClear = () => {
        setFormData({
            from: "",
            stops: "",
            to: "",
            title: "",
        });
        setValidated(false)
    };
    const storeResponse = () => {
        if (loading === 'fulfilled') {
            setShowSuccessMsg(true);
            setShowErrorMsg(false);
        } else if (loading === 'rejected') {
            setShowErrorMsg(true);
            setShowSuccessMsg(false);
        }
    }
    useEffect(() => {
        storeResponse()
    }, [loading])
    return (
        <>
            <h4 className="content-header ">Create Lane</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='from'
                            placeholder='Title'
                            textType='Alphabet'
                            validated={validated}
                            required='true'
                            maxLength={25}
                            feedbackMessage='Title is required!'
                            validationErrorMessage='Give valid from lane!'
                            value={formData.title}
                            validationRegex={schoolCode}
                            onChange={(event) => {
                                setFormData({ ...formData, title: event.target.value })
                            }}
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='to'
                            placeholder='From Lane'
                            textType='Alphabet'
                            validated={validated}
                            required='true'
                            maxLength={25}
                            feedbackMessage='From Lane is required!'
                            validationErrorMessage='Give valid to lane!'
                            value={formData.from}
                            validationRegex={validationRegexClass}
                            onChange={(event) => {
                                setFormData({ ...formData, from: event.target.value })
                            }}
                        />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='from'
                            placeholder='Stops'
                            textType='Alphabet'
                            validated={validated}
                            required='true'
                            maxLength={250}
                            feedbackMessage='Stops is required!'
                            validationErrorMessage='Give valid from lane!'
                            value={formData.stops}
                            validationRegex={validationRegexClass}
                            onChange={(event) => {
                                setFormData({ ...formData, stops: event.target.value })
                            }}
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='to'
                            placeholder='To Lane'
                            textType='Alphabet'
                            validated={validated}
                            required='true'
                            maxLength={25}
                            feedbackMessage='To Lane is required!'
                            validationErrorMessage='Give valid to lane!'
                            value={formData.to}
                            validationRegex={validationRegexClass}
                            onChange={(event) => {
                                setFormData({ ...formData, to: event.target.value })
                            }}
                        />
                    </Col>
                </Row>
                {
                    loading === 'fulfilled' &&
                    showSuccessMsg &&
                    <SuccessMessagePopup show={showSuccessMsg} onClose={() => { dispatch(CLSTIPostLane()); setShowSuccessMsg(false) }} successMessage="Submitted successfully!" />

                    //add handleClear function to successpopup in the last!
                }
                {

                    loading === 'rejected' &&
                    showErrorMsg &&
                    <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(CLSTIPostLane()); setShowErrorMsg(false) }}
                        errorMessage={Array.isArray(errors) ?
                            errors?.map(e => { return <li>{e.message}</li> }) :
                            errors} />}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>
        </>
    )
}

export default CreateLane;