/*
    authors: Rajeev
    date: 25-01-2024
    update:25-01-2024 
    version: 1.0
*/ import React, { useEffect, useState } from "react";
import TextInputField from "../../reUsableForm/textInputField";
import { Col, Form, Row } from "react-bootstrap";
import {
  validationRegexAlphaNumeric,
  validationRegexOnlyAlpha,
} from "../../reUsableForm/regex";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";
import { postQualification } from "./slicer/addQualRequests";
import { changeQualLoadingState } from "./slicer/addQualSlicer";
import Table from "../../../components/reUsableForm/table/table";
import { getEmployeeQualifications } from "../../Teachers/teacherSlicers/allRequests";
const AddQualification = () => {
  const defaultData = { name: "" };
  const [qual, setQual] = useState(defaultData);
  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const dispatch = useDispatch();
  const postQualObj = useSelector(
    (state) => state?.postQualificationSlicer?.postQualification
  );
  const postQualLoading = postQualObj?.loading;
  const postQualError = postQualObj?.error;

  const postData = postQualObj?.data;

  const modifiedArray = postData.map((item) => item.data);

  const handleClear = () => {
    setValidated(false);
    setQual(defaultData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form?.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    dispatch(postQualification(qual));
  };

  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "name", header: "Qualification" },
  ];

  const employeeQualificationsObject = useSelector(
    (state) => state.employeeSlicer?.teachers?.getEmployeeQualifications
  );

  const QualificationData = employeeQualificationsObject?.data || [];

  const employeeQualificationsLoading = employeeQualificationsObject?.loading;
  useEffect(() => {
    if (employeeQualificationsLoading === "idle") {
      dispatch(getEmployeeQualifications());
    }
  }, [dispatch, employeeQualificationsLoading]);

  const combinedData = [...(modifiedArray || []), ...QualificationData];
  const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1,
  }));

  useEffect(() => {
    if (postQualLoading === "fulfilled") {
      setShowSuccessMsg(true);
      setShowErrorMsg(false);
    }
    if (postQualLoading === "rejected") {
      setShowErrorMsg(true);
      setShowSuccessMsg(false);
    }
  }, [postQualLoading]);

  return (
    <>
      <div style={{ height: "100vh" }}>
        <h4 className="content-header ">Add Qualification</h4>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <TextInputField
                name="name"
                placeholder="Enter Qualification"
                textType="Alphabet"
                validated={validated}
                required="true"
                maxLength={25}
                feedbackMessage="Qualification is Required!"
                validationErrorMessage="Give valid Qualification"
                value={qual?.name}
                //validationRegex={validationRegexAlphaNumeric}
                validationRegex={validationRegexOnlyAlpha}
                onChange={(e) => setQual({ ...qual, name: e.target.value })}
              />
            </Col>
          </Row>
          <Row>
            {
              showSuccessMsg && (
                <SuccessMessagePopup
                  show={showSuccessMsg}
                  onClose={() => {
                    setShowSuccessMsg(false);
                    dispatch(changeQualLoadingState());
                    handleClear();
                  }}
                  successMessage="Qualification submitted successfully"
                />
              )

              //add handleClear function to successpopup in the last!
            }
            {showErrorMsg && (
              <ErrorMessagePopup
                show={showErrorMsg}
                onClose={() => {
                  setShowErrorMsg(false);
                  dispatch(changeQualLoadingState());
                }}
                errorMessage={postQualError?.map((e, index) => {
                  return <li key={index}>{e.message}</li>;
                })}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1%",
              }}
            >
              <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
              >
                Clear
              </button>
              <button className="save-button-figma-div" type="submit">
                Submit
              </button>
            </div>
          </Row>
        </Form>
        <div className="App">
          {processedData === null ? (
            <center>
              <p>Loading...</p>
            </center>
          ) : processedData.length === 0 ? (
            <center style={{ color: "blue", margin: "1rem" }}>
              <p>No records available</p>
            </center>
          ) : (
            <Table
              data={processedData}
              columns={columns}
              hover={true}
              striped={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddQualification;
