import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
};

const slice = createSlice({
  name: 'viewParentTeacherMeeting',
  initialState,
  reducers: {
    fetchParentTeacherMeetingData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchParentTeacherMeetingSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchParentTeacherMeetingFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchParentTeacherMeetingData,
  fetchParentTeacherMeetingSuccess,
  fetchParentTeacherMeetingFailure,
} = slice.actions;

export const fetchParentTeacherMeetingDataOnce = (id, ptm, studentId, hasParentAttend) => async (dispatch, getState) => {
  const tokenId = token();
  const { data, isLoading } = getState().viewParentTeacherMeeting;

  if (!isLoading) {
    dispatch(fetchParentTeacherMeetingData());

    try {
      const response = await fetch(`${API_PATH}/franchisee-school/${id}/parent-teacher-meets?ptm=${ptm}&studentId=${studentId}&hasParentAttend=${hasParentAttend}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const responseData = await response.json();
      dispatch(fetchParentTeacherMeetingSuccess(responseData));
    } catch (error) {
      dispatch(fetchParentTeacherMeetingFailure());
      console.error('Error fetching parent teacher meeting data:', error);
    }
  }
};

export default slice.reducer;
