import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField';
import InputField from '../../ReusableFields/TextFields/ReadOnlyTextField';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import TextInputField from "../reUsableForm/textInputField";
import { useDispatch, useSelector } from 'react-redux';
import { validationRegexAlphaNumeric } from '../reUsableForm/regex';
import PastDateField from '../../ReusableFields/DateFields/PastDateField';
import { getCompetetionNames, getCompetetionAwards, putCompetetionAward } from './slicerCompetetion/requestsCompetetion';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { CLSTIEditCompetetionAward } from '../CoCurriculumActivities/slicerCompetetion/competitionAwardsSlicer';
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';
import { uploadFiles } from '../reUsableForm/uploadImage-docs';

const EditCompetetion = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  // const [documentFiles,setDocumentFiles] = useState([]);
  const backendIds = { studentId: null, competitionId: null };
  const getCompetetionNamesObj = useSelector(state => state.competetionAwardsReducer.getCompetetionNames);
  const getCompetetionAwardsObj = useSelector(state => state.competetionAwardsReducer.getCompetetionAwards);
  const putCompetetionAwardObj = useSelector(state => state.competetionAwardsReducer.putCompetetionAward);
  const putLoading = putCompetetionAwardObj.loading;
  const putErrors = putCompetetionAwardObj.error;
  const getAwardsLoading = getCompetetionAwardsObj.loading;
  const getAwardsData = getCompetetionAwardsObj.data;
  const editedDataObj = getAwardsData.find(each => +each.id === +id);

  const navigate = useNavigate();
  const competetionNames = getCompetetionNamesObj.data;

  const dropDownNames = competetionNames?.map((each) => {
    return { id: each.id, label: each.name, value: each.name }
  })


  const studentData = useSelector((state) => state.viewStudent.data) || [];

  const activeRollNumbers = studentData.length > 0 ? studentData?.map(each => {
    const active = each?.studentEnrollments?.find(e => e?.isActive === true);
    return active;
  }) : []
  const rollNumbers = activeRollNumbers.length > 0 ? activeRollNumbers?.map(each => {
    return {
      studentId: each?.studentId,
      label: each?.studentRollNumber, value: each?.studentRollNumber,
      class: each?.classesClassSection?.class?.name,
      section: each?.classesClassSection?.classSection?.name
    }
  }
  ) : [];
  const activeStudent = (studentEnrollments) => {
    return studentEnrollments?.length > 0 ? studentEnrollments?.find(e => e.isActive === true) : [];
  }
  const editData = {
    date: editedDataObj?.date,
    name: editedDataObj?.student?.name,
    studentId: editedDataObj?.student?.studentEnrollments?.studentRollNumber,
    class: editedDataObj?.student?.studentEnrollments?.class,
    section: editedDataObj?.student?.studentEnrollments?.section,
    // studentId:activeStudent(editedDataObj?.student?.studentEnrollments)?.studentRollNumber,
    // class:activeStudent(editedDataObj?.student?.studentEnrollments)?.classesClassSection?.class?.name,
    // section:activeStudent(editedDataObj?.student?.studentEnrollments)?.classesClassSection?.classSection?.nam,
    competitionId: competetionNames?.find(e => +editedDataObj?.competitionId === +e.id)?.name,
    award: editedDataObj?.award,
    imageUrls: editedDataObj?.imageUrls,
    result: editedDataObj?.result
  }

  const loading = getCompetetionNamesObj.loading;
  const insertBackendIds = () => {
    const data = [...getAwardsData];
    const obj = data.find(e => +e.id === +id);
    backendIds.studentId = obj.studentId;
    backendIds.competitionId = obj.competitionId
  }

  useEffect(() => {
    if (loading === 'idle') {
      dispatch(getCompetetionNames())
    }
  }, [loading]);
  useEffect(() => {
    if (getAwardsLoading === 'idle') {
      dispatch(getCompetetionAwards())
    }
  }, [getAwardsLoading, dispatch])
  useEffect(() => {
    if (getAwardsData?.length > 0) {
      insertBackendIds()
    }
  }, [getAwardsData?.length])
  const defaultData = {
    date: '',
    studentId: '',
    name: '',
    class: '',
    section: '',
    competitionId: '',
    award: '',
    imageUrls: [],
    result: ""
  }
  const allFalse = {
    validatedDate: false,
    validatedCompetetion: false,
    validatedStudent: false,
    validatedAward: false,
    validatedResult: false,
    validatedImageUrls: false
  }
  const allTrue = useMemo(() => {
    const trueObj = { ...allFalse };
    for (const i in trueObj) {
      trueObj[i] = true
    }
    return trueObj;
  }, [allFalse]);

  const [formData, setFormData] = useState(editData);
  const [errors, setErrors] = useState(allFalse);
  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [ids, setIds] = useState(backendIds);
  const [docErrorMsg, setDocErrorMsg] = useState('');
  const [docError, setDocError] = useState(false);
  const storeResponse = () => {
    if (putLoading === 'fulfilled') {
      setShowSuccessMsg(true);
      setShowErrorMsg(false);
    } else if (putLoading === 'rejected') {
      setShowErrorMsg(true);
      setShowSuccessMsg(false);
    }
  }
  useEffect(() => {
    storeResponse()
  }, [putLoading])

  const [documents, setDocuments] = useState([]);
  const [docUploadError, setDocUploadError] = useState('');

  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );
  const handleDocumentChange = async (event) => {
    try {
      const files = event.target.files;
      setDocuments(files);
      setFormData({ ...formData, imageUrls: files });
    } catch (error) {
      setDocUploadError('Failed to upload files');
      console.error('File upload error:', error);
    }
  };

  // const handleDocumentChange = async (event) => {
  //   const files = event.target.files;
  //   const data = await multipleDocsUpload([...files]);
  //    if(data?.files === undefined){
  //     setShowErrorMsg(true);
  //     setDocError(true);
  //     setDocErrorMsg(data)
  //     return;
  //   }
  //   const fileIds = data?.files?.map((e)=>{return String(e.fileId)});
  //   setFormData({...formData,imageUrls:[...fileIds]});
  //   setErrors({ ...errors, validatedImageUrls: false })
  // };
  // useEffect(()=>{
  //   if(uploadTrigger){
  //     setUploadTrigger(false);
  //   }

  // },[uploadTrigger]);

  // const multipleDocsUpload =async (files)=>{
  //   try{
  //   const tokenId = token();
  //   const docData = new FormData();
  //   //const files = formData.imageUrls
  //   for(let i=0;i<files?.length;i++){
  //     docData.append('file',files[i])
  //   }
  //   const response = await fetch(API_PATH+'/uploadFiles',{
  //     method:'POST',
  //     body:docData,
  //     headers: {
  //       'Authorization': `Bearer ${tokenId}`
  //     }
  //   });
  //   const data =await response.json();
  //   if(!response.ok){
  //     return data.error
  //   }
  //   return data;
  //   }catch(err){
  //     return err
  //   }
  // }

  const handleClear = () => {
    setFormData(defaultData);
    setErrors(allFalse);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false ||
      !formData.competitionId || !formData.award || !formData.date ||
      !formData.studentId || !formData.result
    ) {

      e.stopPropagation();
      setErrors(allTrue);
      return;
    }
    // const data = await multipleDocsUpload();

    // if(data?.files === undefined){
    //   setShowErrorMsg(true);
    //   setDocError(true);
    //   setDocErrorMsg(data)
    //   return;
    // }
    // const fileIds = data?.files?.map((e)=>{return String(e.fileId)});

    if (documents.length > 0) {
      var uploadResponses = await uploadFiles(documents, "gallery");
    }

    const uploadLocations = uploadResponses?.map((response) => response.Location);
    const requiredData = {
      date: formData.date,
      studentId: ids.studentId,
      competitionId: ids.competitionId,
      imageUrls: uploadLocations,
      award: formData.award,
      result: formData.result
    }
    dispatch(putCompetetionAward({ payload: requiredData, id }))

    setShowSuccessMsg(true);
    setIds(backendIds);

    setErrors(allFalse);
  }
  const displayErrorMsg = () => {
    if (Array.isArray(putErrors) || typeof putErrors === 'string') {
      return Array.isArray(putErrors) ?
        putErrors?.map(e => { return <li>{e.message}</li> }) :
        putErrors
    } else if (docError) {
      return docErrorMsg;
    }
  }
  return (
    <>
      <div className='content-header' style={{ margin: '1rem' }}>Edit Competition Awards</div>
      <Form noValidate onSubmit={handleSubmit}>

        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>

            <PastDateField

              name="date"
              placeholder="date"
              required
              value={formData.date}
              onChange={(e) => {
                setFormData({ ...formData, date: e.target.value });
                setErrors({ ...errors, validatedDate: false })
              }}
            />
            {
              errors.validatedDate && !formData.date ? <div className="error-message" style={{ marginTop: "-25px" }}>Date is Required!</div> : null
            }
          </Col>

          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <InputField
              name="name"
              placeholder="Student Name"
              type='text'
              value={formData.name}
              disabled={true}
            />
            {
              errors.validatedStudent && !formData.studentId ? <div className="error-message" style={{ marginTop: "-25px" }}>Enter rool number</div> : null
            }
          </Col>

        </Row>
        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <InputField
              name="class"
              placeholder="Class"
              type='text'
              value={formData.class}
              disabled={true}
            />
            {
              errors.validatedStudent && !formData.studentId ? <div className="error-message" style={{ marginTop: "-25px" }}>Enter rool number</div> : null
            }
          </Col>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <InputField
              name="section"
              placeholder="Section"
              type='text'
              value={formData.section}
              disabled={true}
            />
            {
              errors.validatedStudent && !formData.studentId ? <div className="error-message" style={{ marginTop: "-25px" }}>Enter rool number</div> : null
            }
          </Col>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <Form.Group >
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: '248px',
                  height: '42px',
                  padding: '16px',
                  alignItems: 'center',
                  borderRadius: '4px',
                  border: '0.5px solid #A7A7A7',
                  background: '#FFF',
                }}
              >
                <div className='layout1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='24'
                    viewBox='0 0 25 24'
                    fill='none'
                  >
                    <path
                      d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
                      fill='#636363'
                    />
                  </svg>
                  <div className='title1'>Upload Documents</div>
                </div>
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                  // required
                  multiple
                  name='imageUrls'
                  //onChange={multipleDocsUpload}
                  onChange={handleDocumentChange}
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />

              </div>
              {
                !errors.validatedImageUrls && formData?.imageUrls?.length > 0 ? <p>{formData?.imageUrls?.length} certificates are selected</p> : ''
              }
              {/* {
                errors?.validatedImageUrls && formData?.imageUrls?.length === 0 ? <div className="error-message">certificates are Required!</div> : null
              } */}
              <div>
                {fileNamesArray?.map((name, index) => {
                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>

          </Col>
        </Row>
        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <SearchableDropDownField
              name='competitionId'
              value={formData.competitionId ? { label: formData.competitionId } : null}
              onChange={(e) => {
                setFormData({ ...formData, competitionId: e.value });
                setIds({ ...ids, competitionId: e.id });
                setErrors({ ...errors, validatedCompetetion: false })

              }}
              placeholder='Competetion'
              options={dropDownNames ? dropDownNames : []}
              feedbackMessage='Competetion is Required!'
              validated={validated}
              required={true}
            />
            {
              errors.validatedCompetetion && !formData.competitionId ? <div className="error-message">Enter Competetion</div> : null
            }
          </Col>

          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <SearchableDropDownField
              name='award'
              value={formData.award ? { label: formData.award } : null}
              onChange={(e) => {
                setFormData({ ...formData, award: e.value });
                setErrors({ ...errors, validatedAward: false })

              }}
              placeholder='Award'
              options={[{ id: 1, label: '1st Prize', value: '1st Prize' }, { id: 2, label: '2nd Prize', value: '2nd Prize' }, { id: 3, label: '3rd prize', value: '3rd prize' }, { id: 4, label: 'Consolation', value: 'Consolation' },]}
              feedbackMessage='Award is Required!'
              validated={validated}
              required={true}
            />
            {
              errors.validatedAward && !formData.award ? <div className="error-message">Award is Required!</div> : null
            }
          </Col>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>

            <TextInputField
              name="result"
              placeholder="Result"
              textType="Alphabet"
              validated={errors.validatedResult}
              required={true}
              maxLength={25}
              feedbackMessage="Result is Required!"
              validationErrorMessage="Result should contain either alphabets or numbers!"
              value={formData.result}
              validationRegex={validationRegexAlphaNumeric}
              onChange={(e) => {
                setFormData({ ...formData, result: e.target.value });
                setErrors({ ...errors, validatedResult: false })
              }}
            />
            {
              errors.validatedResult && !formData.result ? <div className="error-message" style={{ marginTop: "-25px" }}>Result is Required!</div> : null
            }
          </Col>
        </Row>
        {
          putLoading === 'fulfilled' && showSuccessMsg &&
          <SuccessMessagePopup show={showSuccessMsg} onClose={() => { setShowSuccessMsg(false); dispatch(CLSTIEditCompetetionAward()); navigate(-1) }} successMessage='Edited Successfully!' />
        }
        {
          //putLoading === 'rejected' && showErrorMsg &&
          <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(CLSTIEditCompetetionAward()); setShowErrorMsg(false) }}
            errorMessage={displayErrorMsg()} />
        }
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" >
            Submit
          </button>
        </div>
      </Form>

    </>
  )
}

export default EditCompetetion;