
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { fetchSchoolCreation } from './creationSchool';

// import { setSectionAdd } from './addSectionSlice';

const slice = createSlice(
  {
    name: 'viewSchoolData',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchSchoolData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchSchoolSuccess: (state, action) => {
        state.isLoading = false
        state.data = action.payload;
      },
      fetchSchoolFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },
);
export const { fetchSchoolData, fetchSchoolSuccess, fetchSchoolFailure } = slice.actions;

export const fetchClassSchoolDataOnce = () => async (dispatch, getState) => {
    const tokenId = token();
  
    const { data, isLoading } = getState().viewSchoolData;
    if (data === null && !isLoading) {
      dispatch(fetchSchoolData()); // Dispatching the action here
  
      try {
        const response = await fetch(API_PATH + "/franchiseSchools", {
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${tokenId}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Request failed');
        }
        const responseData = await response.json();
        dispatch(fetchSchoolSuccess(responseData));
      } catch (error) {
        dispatch(fetchSchoolFailure());
      }
    }
  }
  
export default slice.reducer;
