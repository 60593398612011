import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthorization } from '../../Auth/dependency-get';
import { fetchSchoolCreationPost } from './creationSchool';


const slice = createSlice(
    {
        name: 'franchiseSchools',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setSuccessMessage,
    setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const FetchFranchiseSchools = () => async (dispatch, getState) => {

    const { data, isLoading } = getState().franchiseSchools;
    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart());

        try {

            const response = await fetchWithAuthorization(API_PATH + '/franchiseSchools', "GET", tokenId);
            if (!response) {
                const errorData = await response;
                throw new Error(errorData.errors);
            } else {
                const responseData = await response;
                dispatch(fetchSchoolCreationPost([]))
                dispatch(fetchDataSuccess(responseData));
            }
        }
        catch (error) {

            console.error("....error", error)
            const errMessage = responseFormat({ error })
            dispatch(setErrorMessage(errMessage));
            dispatch(setShowErrorMessage(true));
        }
    }

}

export default slice.reducer;
