/*
    authors: 
    date: 23-10-2023
    update:23-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import DatePickerPast from "../reUsableForm/DatePicketPast_present";
import {
  Card,
  CardDeck,
  Container,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "../StudentDetails/Student.css"; // Import your custom CSS file
import TextInputField from "../reUsableForm/textInputField";
import {
  validationRegexAlphaNumeric,
  validationRegexOnlyPincode,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  validationRegexOnlyAlpha1,
  validationRegexOnlyTextarea,
  emailRegex,
} from "../reUsableForm/regex";

import DatePicker from "../reUsableForm/DatePickerPresent_future";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import FutureDateField from "../../ReusableFields/DateFields/FutureDateField";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import SelectDropdown from "../SelectDropdown";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { PostAssessmentRequest } from "./slicer/addAssessmentSlicer";
import Table from "../reUsableForm/table/table2";
import { fetchFranchiseClassSectionsDataOnce } from "../Franchise/slice/viewfranchiseclasssections";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchFranchiseClasswiseSectionsDataOnce } from "./slicer/viewfranchiseclasswisesectionsSlicer";
import { fetchAssessmentParameterOnce } from "./slicer/viewAssessmentParametSlicer";
// import { PostAssessmentReportRequest } from "./slicer/addAssessmentReportSlicer";
import { PostMonthlyAssessmentRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addMonthlyAssessmentSlicer";

const MonthlyAssessment = () => {
  const [validated, setValidated] = useState(false);

  const [dropDowns, setDropDowns] = useState({});

  const [formData, setFormData] = useState({
    classId: "",
    sectionId: "",
  });
  const showSuccessMsg = useSelector((state) => state.addMonthlyAssessment.showSuccessMessage);
  const successMsg = useSelector((state) => state.addMonthlyAssessment.successMessage);

  const showErrorMsg = useSelector((state) => state.addMonthlyAssessment.showErrorMessage);
  const errorMsg = useSelector((state) => state.addMonthlyAssessment.errorMessage);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassSection, setSelectedClassSection] = useState(null);
  const [selectedClassData, setSelectedClassData] = useState([]);
  // const [selectedClass, setSelectedClass] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [classSections, setClassSections] = useState(false);
  const [filteredStudentNames, setFilteredStudentNames] = useState([]);
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const [showErrorMsgs, setShowErrorMsgs] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState("");

  const monthOptions = [
    { id: 1, month: "January" },
    { id: 2, month: "February" },
    { id: 3, month: "March" },
    { id: 4, month: "April" },
    { id: 5, month: "May" },
    { id: 6, month: "June" },
    { id: 7, month: "July" },
    { id: 8, month: "August" },
    { id: 9, month: "September" },
    { id: 10, month: "October" },
    { id: 11, month: "November" },
    { id: 12, month: "December" },
  ];

  const [formErrors, setFormErrors] = useState({
    class: false,
    studentName: false,
    month: false,
  });

  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [dispatch, classesClassSectionData]);
  const apiData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (apiData.length == 0) {
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }
  }, [dispatch, apiData]);

  const viewAssessmentParameterData = useSelector((state) => state.viewAssessmentParameter.data) || [];

  useEffect(() => {
    if (viewAssessmentParameterData.length == 0) {
      dispatch(fetchAssessmentParameterOnce());
    }
  }, [dispatch, viewAssessmentParameterData]);

  const columns = [
    // {field :"id",header:"S.no"},
    { field: "parameter", header: "Parameter" },
    { field: "a_Proficiency", header: "A – Proficiency" },
    { field: "b_Developing", header: "B – Developing" },
    { field: "c_Beginning", header: "C – Beginning" },
    { field: "notApplicable", header: "NA/Yet to teach" },
    { field: "remarks", header: "Remarks" },
  ];
  const sampleData = [
    { id: 1, classId: 1, parameter: "football" },
    { id: 2, classId: 1, parameter: "cricket" },
    { id: 3, classId: 1, parameter: "Wallyball" },
    // Add more rows as needed
  ];

  const updatedSampleData = sampleData.map((item) => ({
    ...item,
    a_Proficiency: false,
    b_Developing: false,
    c_Beginning: false,
    notApplicable: false,
  }));


  const handleCheckboxChange = (parameter, field, checked) => {
    const updatedData = data.map((row) => {
      if (row.parameter === parameter) {
        const newRow = { ...row };

        // Update the checkbox values based on which one is checked
        if (checked) {
          newRow.a_Proficiency = field === "a_Proficiency";
          newRow.b_Developing = field === "b_Developing";
          newRow.c_Beginning = field === "c_Beginning";
          newRow.notApplicable = field === "notApplicable";
        } else {
          // If unchecked, set all checkboxes to false
          newRow.a_Proficiency = false;
          newRow.b_Developing = false;
          newRow.c_Beginning = false;
          newRow.notApplicable = false;
        }

        // Set assessmentParameterId based on the selected parameter
        const selectedParameter = viewAssessmentParameterData.find(param => param.name === parameter);
        newRow.assessmentParameterId = selectedParameter ? selectedParameter.id : undefined;

        return newRow;
      }
      return row;
    });

    setData(updatedData);
  };


  const handleEntryInputChange = (parameter, value) => {
    const updatedData = data.map((row) => {
      if (row.parameter === parameter) {
        return {
          ...row,
          remarks: value, // Update the entryInput value for the specific row
        };
      }
      return row;
    });

    setData(updatedData);
  };

  const transformedData2 = apiData
    .filter(
      (student) =>
        student.studentEnrollments &&
        student.studentEnrollments.length > 0 &&
        student.studentEnrollments.some(
          (enrollment) => enrollment.classesClassSectionId === selectedSection
        )
    )
    .map((student) => ({
      value: student.id,
      label: student.name,
    }));



  const handleSearchClick = (e) => {
    e.preventDefault();


    if (!dropDowns.class) {
      setShowErrorMsgs(true);
      setErrorMsgs("Please select a class.");
      return;
    }

    const filteredAssessmentParameters = viewAssessmentParameterData.filter(
      (parameter) => {
        return parameter.assessmentSkill?.classId === selectedClass;
      }
    );


    const parameterNames = filteredAssessmentParameters.map(
      (parameter) => parameter.name
    );


    const tableData = parameterNames.map((parameterName, index) => ({
      id: index + 1, // Assuming you want to assign a unique ID
      parameter: parameterName,
      a_Proficiency: false,
      b_Developing: false,
      c_Beginning: false,
      notApplicable: false,
      remarks: "", // You can set a default value for entryInput if needed
    }));


    setData(tableData);


    setShowTable(true);
    setShowAllFields(true)

  };


  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });

    if (name === "studentNameId") {
      const selectedStudent = apiData.find(student => student[0].id === value);
      setFormData({ ...formData, studentId: selectedStudent ? selectedStudent.id : "" });
    } else if (name === "month") {
      setFormData({ ...formData, month: value });
    }

  };
  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);
    setFilteredStudentNames([]);
    setFormData({ ...formData, studentNameId: "" });
    setDropDowns({ ...dropDowns, studentName: "" });
    setFormErrors({ ...formErrors, studentName: false });
    setShowTable(false);
    setShowAllFields(false);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };



  useEffect(() => {
    handleClassChange(selectedClass);
  }, [selectedClass]);

  const filterAndCreateArray = (inputArray) => {
    const newArray = inputArray.map((item, index) => {
      const filteredObject = {
        id: index + 1, // Assuming you want to assign a unique ID
        ...item,
      };

      return filteredObject;
    });

    return newArray;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {
      class: !dropDowns.class,
      studentName: !dropDowns.studentName,
      month: !dropDowns.month,
      sections: !dropDowns.sections
    };
    setFormErrors(newFormErrors);


    const resultArray = filterAndCreateArray(data);

    const transformedData = resultArray.map((item) => ({
      assessmentParameterId: item.assessmentParameterId,
      grade: item.a_Proficiency ? "Proficiency" :
        item.b_Developing ? "Developing" :
          item.c_Beginning ? "Beginning" :
            item.notApplicable ? "NA/Yet to teach" : null,
      remark: item.remarks || "",
    }));

    const sendData = {
      studentId: formData.studentNameId,
      month: formData.month,
      preSchoolData: transformedData,
    };

    if (sendData.preSchoolData.length > 0) {
      dispatch(PostMonthlyAssessmentRequest(sendData));
      setShowAllFields(false)
    }

    setFormData({});
    setData([]);
    setFormData({ ...formData, sectionId: "" });
    setDropDowns({ dropDowns: "", month: "" });

  };
  const handleClear = () => {
    setFormData({});
    setValidated(false);
    setDropDowns({});
    setFormErrors({});
    setData([]);
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Monthly Assessment</div>
        <Row>


          <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >

            <SelectDropdown
              label="Class"
              // value={formData.classId || ""}
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : dropDowns.class
              }
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              options={classesClassSectionData.map((classItem) => ({
                value: classItem.id,
                label: classItem.name,
                // classesClassSectionDataId:classItem.classSections
              }))}
              required
              feedbackMessage="Please select a valid class"
            // placeholder="Select class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {formData.classId && selectedClassData && selectedClassData.classSections.length > 0 && (

            <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >

              <SelectDropdown
                label="Section"
                // value={formData.sectionId || ""}
                value={
                  dropDowns.sections
                    ? { value: dropDowns.sections, label: dropDowns.sections }
                    : dropDowns.sections
                }
                placeholder="Select section"
                onChange={(selectedOption) => {
                  handleChange("sectionId", selectedOption);
                  setSelectedSection(selectedOption.value);
                  setDropDowns({ ...dropDowns, sections: selectedOption.label });
                  setFormErrors({ ...formErrors, sections: false });
                }}
                options={selectedClassData.classSections.map((section) => ({
                  key: section.id,
                  value: section.classesclassSection,
                  label: section.name,
                }))}
                required
                feedbackMessage="Please select a valid section"
              />
              {formErrors.sections && (
                <div className="error-message">Please Select Section.</div>
              )}
            </Col>
          )}

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <div style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}>

              <button
                className="save-button-figma-div"
                onClick={handleSearchClick}
              >
                Search
              </button>
            </div>
          </Col>
        </Row>

        {showAllFields && (
          <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.studentName
                    ? {
                      value: dropDowns.studentName,
                      label: dropDowns.studentName,
                    }
                    : null
                }
                className="input-style"
                options={transformedData2}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    studentNameId: selectedOption.value,
                    class: selectedOption.class,
                  });
                  setDropDowns({
                    ...dropDowns,
                    studentName: selectedOption.label,
                  });
                  setFormErrors({
                    ...formErrors,
                    studentName: false,
                    class: false,
                  });
                }}
                placeholder="Select a student name"
              />
              {formErrors.studentName && (
                <div className="error-message">Please Select a studentName.</div>
              )}
            </Col>

            <Col xs={12} md={6} lg={3} n className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.month
                    ? { value: dropDowns.month, label: dropDowns.month }
                    : null
                }
                className="input-style"
                options={monthOptions.map((option, index) => ({
                  id: option.id,
                  value: option.month,
                  label: option.month,
                }))}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, month: selectedOption.value });
                  setDropDowns({ ...dropDowns, month: selectedOption.value });
                  setFormErrors({ ...formErrors, month: false });
                }}
                placeholder="Select a month"
              />
              {formErrors.month && (
                <div className="error-message">Please Select month .</div>
              )}
            </Col>
          </Row>
        )}

        {showTable && (
          <Row>
            <Col sm={12} xs={12} md={6} lg={12} className="rowCss">
              {data === null ? (
                <center>
                  <p>Loading...</p>
                </center>
              ) : data.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                  <p>No records available</p>
                </center>
              ) : (
                <Table
                  data={data}
                  columns={columns}
                  hover={true}
                  handleCheckboxChange={handleCheckboxChange}
                  handleEntryInputChange={handleEntryInputChange}
                />
              )}
            </Col>
          </Row>
        )}

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        {showAllFields && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <button
              className="clear-button-figma-div"
              type="button"
              onClick={handleClear}
            >
              Clear
            </button>
            <button className="save-button-figma-div" type="submit">
              Submit
            </button>
          </div>
        )}
      </Form>
    </>
  );
};

export default MonthlyAssessment;
