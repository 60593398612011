/*
    authors: Rajeev
    date: 03-11-2023
    update:03-11-2023 
    version: 1.0
*/

import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../Api/token";
import { API_PATH } from "../../Api/api";
import { responseFormat } from "../Auth/errorResponse";

const slice = createSlice({
  name: "addAttendance",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchAttendanceData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchAttendanceSuccess: (state, action) => {
      state.isLoading = false;
      state.data = state.data
        ? [...state.data, ...action.payload]
        : action.payload;
    },
    fetchAttendanceFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
    setPostAttendanceData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  fetchAttendanceData,
  fetchAttendanceSuccess,
  fetchAttendanceFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
  setPostAttendanceData,
} = slice.actions;

export const postAttendanceRequest = (id, postData) => async (dispatch) => {
  dispatch(fetchAttendanceData());

  const tokenId = token();
  try {
    // Make your POST request here using the fetch function or an HTTP library like Axios
    const response = await fetch(API_PATH + `/franchisee-school/${id}/studentAttendance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenId}`,
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      throw new Error("Request failed"); // You can customize error handling
    } else {
      const responseData = await response.json();
      dispatch(fetchAttendanceSuccess(responseData.data));
      dispatch(setSuccessMessage("Successful!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    dispatch(fetchAttendanceFailure());
    const errMessage = responseFormat({ error });
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
