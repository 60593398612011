import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpensesTypeView } from "./slice/expensesTypeViewSlicer"
import { updateExpensesType, setShowErrorMessageVehicle, setShowSuccessMessageVehicle, } from "./slice/expensesTypePutSlicer"
import { setShowErrorMessageExpenses, setShowSuccessMessageExpenses, } from "./slice/pettyExpensesDeleteSlicer"
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { deleteExpensesType } from "./slice/pettyExpensesDeleteSlicer";
import ConfirmationPopup from '../reUsableForm/pop-card/confirmationPopUp'; // Import the new ConfirmationPopup component

const ExpensesTypeView = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [expensesTypeEditModal, setExpensesTypeEditModal] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const columns = [
        { field: 'index', header: 'Sr.No' },
        { field: 'name', header: 'Expense Type' },
        { field: 'expensesEdit', header: 'Edit' },
        { field: 'expensesDelete', header: 'Delete' },
    ]
    const apiData = useSelector((state) => state.expensesTypeViewSlicer.data) || [];
    const postData = useSelector((state) => state.expensesTypeSlicer.data) || [];
    const putData = useSelector((state) => state.expensesTypeEdit.data) || [];
    const deleteData = useSelector((state) => state.expensesTypeDelete.data) || [];

    const showSuccessMsg = useSelector((state) => state.expensesTypeEdit.showSuccessMessage);
    const successMsg = useSelector((state) => state.expensesTypeEdit.successMessage);

    const showErrorMsg = useSelector((state) => state.expensesTypeEdit.showErrorMessage);
    const errorMsg = useSelector((state) => state.expensesTypeEdit.errorMessage);

    const showSuccessMsgg = useSelector((state) => state.expensesTypeDelete.showSuccessMessage);
    const successMsgg = useSelector((state) => state.expensesTypeDelete.successMessage);

    const showErrorMsgg = useSelector((state) => state.expensesTypeDelete.showErrorMessage);
    const errorMsgg = useSelector((state) => state.expensesTypeDelete.errorMessage);

    const combinedData = [...(apiData || []), ...postData];

    let updatedCombinedData = [...combinedData];


    putData.forEach((putItem) => {
        const index = updatedCombinedData.findIndex((item) => item.id === putItem.id);
        if (index !== -1) {
            updatedCombinedData[index] = { ...updatedCombinedData[index], ...putItem };
        }
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchExpensesTypeView());
    }, [dispatch]);

    const updatedData = updatedCombinedData.filter(item => !deleteData.includes(item.id));

    const newModifiedData = updatedData.map((postItem, index) => {
        return {
            index: index + 1,
            id: postItem.id,
            name: postItem.name,
        };
    });

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessageVehicle(false));
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessageVehicle(false));
    };

    const handleDeleteClickExpenses = (id) => {
        setDeleteId(id);
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        if (deleteId) {
            dispatch(deleteExpensesType(deleteId));
            setShowConfirmDelete(false);
            setDeleteId(null);
        }
    };

    const handleCloseConfirmDelete = () => {
        setShowConfirmDelete(false);
        setDeleteId(null);
    };

    const handleExpensesEditSave = () => {
        if (editedData && editedData.id) {
            const updatedData = {
                id: editedData.id,
                name: editedData.name,
                expensesEdit: editedData.expensesEdit,
            };
            dispatch(updateExpensesType(editedData.id, updatedData));
            setTimeout(() => {
                setExpensesTypeEditModal(false);
            }, 3000);
        } else {
            console.error("Row ID or edited data ID is missing.");
        }
    };

    
    const handleCloseErrorPopupDelete = () => {
        dispatch(setShowErrorMessageExpenses(false));
    };

    const handleCloseSuccessPopupDelete = () => {
        dispatch(setShowSuccessMessageExpenses(false));
    };


    return (
        <>
            {/* <div className="content-header">View Program</div> */}
            {newModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) :
                (
                    <Table data={newModifiedData} columns={columns} hover={true} showEditModal={showEditModal}
                        setShowEditModal={setShowEditModal}
                        editedData={editedData}
                        setEditedData={setEditedData}
                        handleDeleteClickExpenses={handleDeleteClickExpenses}
                        handleExpensesEditSave={handleExpensesEditSave}
                        setExpensesTypeEditModal={setExpensesTypeEditModal}
                        expensesTypeEditModal={expensesTypeEditModal}

                    />
                )}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

            <SuccessMessagePopup show={showSuccessMsgg} onClose={handleCloseSuccessPopupDelete} successMessage={successMsgg} />
            <ErrorMessagePopup show={showErrorMsgg} onClose={handleCloseErrorPopupDelete} errorMessage={errorMsgg} />

            <ConfirmationPopup
                show={showConfirmDelete}
                onClose={handleCloseConfirmDelete}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this item?"
            />
        </>
    );
}
export default ExpensesTypeView;