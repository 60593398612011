import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { fetchWithAuthPost } from '../../../components/Auth/dependency_post';
import { responseFormat } from '../../../components/Auth/errorResponse';

const slice = createSlice({
    name: 'FoodItemPost',
    initialState: {
        data: null,  // Adjusted the initial state to include data
        isLoading: false,
        hasError: false,
        successMessage: '',
        showSuccessMessage: false,
        errorMessage: '',
        showErrorMessage: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessage: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessage: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    }
})

export const {
    fetchDataStart,
    fetchDataFailure,
    fetchDataSuccess,
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage
} = slice.actions;

export const FoodItemsPost = (postData) => async (dispatch) => {

    const tokenId = token();
    try {
        dispatch(fetchDataStart());

        const response = await fetchWithAuthPost(API_PATH + '/fooditem', 'POST', tokenId, postData);
        
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchDataSuccess([responseData.foodItem]));

            dispatch(setSuccessMessage("Food Item created successfully!"));
            dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error });
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
}

export default slice.reducer;
