import React, { useEffect } from "react"; // Importing necessary modules
import { useDispatch, useSelector } from "react-redux"; // Importing useDispatch and useSelector hooks
import { fetchFeeHead } from "../FeeStructureSlice/feeHeadGet"; // Importing fetchFeeHead action creator
import TableView from "../../reUsableForm/table/table";
import { PostFeeHead } from "../FeeStructureSlice/feeHeadPost";

function FeeHeadDetails() {
  const dispatch = useDispatch();
  const feeHeadData = useSelector((state) => state.feeHeadDetails.data);
  const postData = useSelector((state) => state.feeHead.data) || [];
  const isLoading = useSelector((state) => state.feeHeadDetails.isLoading);
  const hasError = useSelector((state) => state.feeHeadDetails.hasError);

  const combinedData = [...(feeHeadData || []), ...postData];

  useEffect(() => {
    dispatch(fetchFeeHead());
  }, [dispatch, PostFeeHead]);

  // Reformatting data to include only necessary fields
  const reformattedData = combinedData?.map((item) => ({
    name: item.name,
    paymentType: (() => {
      if (item.biMonthly) return "Bi-Monthly";
      if (item.halfYearly) return "Half-Yearly";
      if (item.monthly) return "Monthly";
      if (item.oneTimeAtAdmission) return "One-Time At Admission";
      if (item.refundable) return "Refundable";
      if (item.term) return "Term";
      if (item.yearly) return "Yearly";
      return "Not Specified";
    })(),
    Refundable: (() => {
      if (item.refundable) return "Refundable";
      else return "Non-Refundable";
    })(),
  }));

  const columns = [
    // Column definition for the table
    { field: "name", header: "Name" }, // Name column
    { field: "paymentType", header: "Payment Type" }, // Payment Type column
    { field: "Refundable", header: "Refundable Type" }, // Payment Type column
  ];

  return (
    <div>
      <h2>Fee Head Details</h2> {/* Displaying title */}
      {isLoading ? ( // Checking if data is loading
        <p>Loading...</p> // Displaying loading message
      ) : hasError ? ( // Checking if error occurred
        <p>Error occurred while fetching data.</p> // Displaying error message
      ) : feeHeadData === null ? ( // Checking if fee head data is null
        <p>No fee head details available.</p> // Displaying message for no data
      ) : (
        <TableView
          data={reformattedData}
          columns={columns}
          hover={true}
          striped={true}
        /> // Rendering table component with reformatted fee head data
      )}
    </div>
  );
}

export default FeeHeadDetails; // Exporting FeeHeadDetails component
