import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';


const slice = createSlice(
    {
        name: 'ViewClassTimetableByStudentId',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchClassTimetableByStudentIdData: (state, action) => {
                state.isLoading = action.payload;
                state.hasError = false;
            },
            fetchClassTimetableByStudentIdSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchClassTimetableByStudentIdFailure: (state, action) => {
                state.isLoading = false;
                state.hasError = action.payload;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },

);

export const { fetchClassTimetableByStudentIdData, fetchClassTimetableByStudentIdSuccess, fetchClassTimetableByStudentIdFailure } = slice.actions;

export const fetchClassTimetableByStudentId = (studentId,month, day) => async (dispatch, getState) => {

    const { data, isLoading } = getState().ViewClassTimetableByStudentId;
    const tokenId = token();

    if (data === null && !isLoading) {
        dispatch(fetchClassTimetableByStudentIdData());

        try {
            const response = await fetch(API_PATH + `/student/${studentId}/timeTables?month=${month}&day=${day}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`
                }
            })
            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data1 = await response.json();
            dispatch(fetchClassTimetableByStudentIdSuccess(data1));
        } catch (error) {
            dispatch(fetchClassTimetableByStudentIdFailure());
        }
    }

}

export default slice.reducer;
