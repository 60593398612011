import React from "react";
import { Modal, Table, Button } from "react-bootstrap";

const DueDatesModal = ({ show, handleClose, dueDates }) => {
  return (
    <Modal show={show} onHide={handleClose} className="modal">
      <Modal.Header closeButton>
        <Modal.Title>Due Dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Term</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {dueDates &&
              dueDates.map((dueDate, index) => (
                // Check if dueDates is not null before mapping
                <tr key={index}>
                  <td>{dueDate.term}</td>
                  <td>{dueDate.date}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DueDatesModal;
