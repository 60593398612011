import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';

const slice = createSlice({
    name: 'ComplaintsSuggestions',
    initialState: {
        date: null,
        isLoading: false,
        hasError: false,
        successMessage: '',
        showSuccessMessage: false,
        errorMessage: '',
        showErrorMessage: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessage: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessage: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    }
})

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const ComplaintsSuggestionsPost = (ComplaintsSuggestionsPost) => async (dispatch) => {
    dispatch(fetchDataStart());
    const tokenId = token();
    try {
        const response = await fetchWithAuthPost(API_PATH + '/guardian-complaint-suggestion',  'POST', tokenId, ComplaintsSuggestionsPost)
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchDataSuccess([responseData.data]));

            dispatch(setSuccessMessage("Successfully saved!"));
            dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        console.error("....error", errMessage)
        dispatch(fetchDataFailure())
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
}

export default slice.reducer;