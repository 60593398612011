import React, { useState } from "react";
import { Form, Image, Modal } from "react-bootstrap";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, Button, TextField, MenuItem, Select, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Image as ImageView } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CloudDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeRow } from "../Teachers/teacherSlicers/employeeViewSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import useCitiesState from "../Teachers/customHooks/useCitiesState";
import { calculateAge } from "../Teachers/teacherSlicers/newDataFilterFunctions";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchStudentDataSuccess } from "../StudentDetails/viewStudentSlicer"
import Tooltip from '@mui/material/Tooltip';
import { DownloadOutlined } from "@ant-design/icons";


const StudentViewTable = ({
    data = null,
    columns = null,

}) => {
    const dispatch = useDispatch();

    const editedRowValue = useSelector((state) => state.employeeSlicer?.teachers?.employeeRow)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState(columns && columns[0] ? columns[0].field : "");
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [selectedDocument1, setSelectedDocument1] = useState([]);
    const [selectedDocument2, setSelectedDocument2] = useState([]);
    const [showImageView, setShowImageView] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [showDocumentPopup, setShowDocumentPopup] = useState(false);
    const [showDocumentPopup1, setShowDocumentPopup1] = useState(false);
    const [showDocumentPopup2, setShowDocumentPopup2] = useState(false);
    const [showDocumentPopup3, setShowDocumentPopup3] = useState(false);
    const [selectedRowParentsRelations, setSelectedRowParentsRelations] = useState(null);


    const [documents, setDocuments] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);

    const [editedData, setEditedData] = useState(editedRowValue);

    const [qualificationsOptions, statesOptions, citiesOptions, designationOptions] = useCitiesState();
    const navigate = useNavigate();
    const getCaps = (head, field) => (head ? head.toUpperCase() : field.toUpperCase());

    const handleEditClick = (row) => {
        dispatch(getEmployeeRow(row))
        navigate(`/editEmployee/${row.id}`)
    };

    const handleEditClick1 = (row) => {
        navigate(`/student/${row.id}`)
    };

    const handleEditSave = () => {
        setShowEditModal(false);
    };

    const handleViewClick = (row) => {

        setSelectedRow(row);
        setShowViewModal(true);
    };
    const downloadDocument = (docData) => {
        if (!docData || !docData.data || !docData.data.data || !docData.type || !docData.name) {
            console.error("Invalid document data");
            return;
        }

        const stringForm = bufferToBase64(docData.data.data);
        const downloadLink = document.createElement('a');

        downloadLink.href = `data:${docData.type};base64,${stringForm}`;
        downloadLink.download = docData.name;

        // Append the link to the body, trigger the download, and remove the link after download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    const bufferToBase64 = (buffer) => {
        return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    };
    const handleImageViewClick = (image) => {

        setSelectedImage(image);
        setShowImageView(true);

    };
    const handleDownloadImages = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'images.jpg'; // You can customize the downloaded file name here
        link.click();
    };

    const handleBonafideDocViewClick = (doc1) => {
        setSelectedDocument(doc1);
         setShowDocumentPopup1(true);

    };

    const handleTransferDocViewClick = (doc1) => {

        setSelectedDocument1(doc1);
        setShowDocumentPopup2(true);

    };

    const handleReportCardDocViewClick = (doc1) => {
        setSelectedDocument2(doc1);
        setShowDocumentPopup3(true);

    };


    const handleDocumentIconClick = (docList) => {
        setDocuments(docList);
        setShowDocumentPopup(true);

    };

    const handleParentsViewClick = (row) => {
        setSelectedRowParentsRelations(row.parents);
        setShowViewModal(true);
    };

    const handleDownload = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'document.jpg'; // You can customize the downloaded file name here
        link.click();
    };
    const handleDownload1 = (selectedDocument) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = selectedDocument;
        link.download = 'document.pdf'; // You can customize the downloaded file name here
        link.click();
    };

    const handleCloseDocumentPopup = () => {
        setShowDocumentPopup(false);

    };
    const handleCloseDocumentPopup1 = () => {

        setShowDocumentPopup1(false);
    };
    const handleCloseDocumentPopup2 = () => {

        setShowDocumentPopup2(false);
    };
    const handleCloseDocumentPopup3 = () => {

        setShowDocumentPopup3(false);
    };
    const handleSortClick = (column) => {
        if (column === "edit") {
            return;
        }
        if (column === "studentEdit") {
            return;
        }

        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    // const docsPaths = documents && documents.map(docPath => docPath.replace(/\\/g, '/'));
    // const docs = docsPaths && docsPaths.map((docPath) => {
    //     const fullPath = docPath;
    //     const pathParts = fullPath.split('kidhood-images-and-docs');
    //     const docsPaths = pathParts[1];
    //     return docsPaths;
    // })



    const filteredData = data ? data.filter((row) => {
        const searchValue = searchQuery.toLowerCase();

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnValue = row[column.field];

            if (typeof columnValue === "string") {
                if (columnValue.toLowerCase().includes(searchValue)) {
                    return true;
                }
            } else if (typeof columnValue === "number") {
                if (columnValue.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    }) : [];

    const sortedData = filteredData && filteredData.slice().sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (typeof valueA === "string" && typeof valueB === "string") {
            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        } else {
            if (sortOrder === "asc") {
                return valueA < valueB ? -1 : 1;
            } else {
                return valueB < valueA ? -1 : 1;
            }
        }
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsBeforeAge = sortedData && sortedData.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = currentItemsBeforeAge?.map((each) => { return { ...each, age: calculateAge(each.dateOfBirth) } })
    const filteredColumns = columns.filter(
        (col) => !["id", "view", "edit", "studentEdit", "delete"].includes(col.field)
    );
    
    return (
        <div className="table-Maindiv">
            <TextField
                placeholder="Search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "10px" }}
            />
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns &&
                                columns.map((head) => (
                                    <TableCell sx={{ backgroundColor: "#E2E8F0", color: "#464E5F", fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", textAlign: "center", whiteSpace: "nowrap" }} key={head.field} className={`tablehead ${head.field === 'address' ? 'long-address' : ''}`}>
                                        <TableSortLabel
                                            active={sortColumn === head.field}
                                            direction={sortOrder}
                                            onClick={() => handleSortClick(head.field)}
                                        >
                                            {head.header}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems && currentItems.map((row, rowIndex) => (
                            <TableRow key={row.id}>
                                {columns.map((col) => (
                                    <TableCell key={col.field} className={`tablecell ${col.field === 'address' ? 'long-address' : ''}`}>
                                        {
                                            col.field === "profilePictureUrl" ? (
                                                <IconButton
                                                    onClick={() => {

                                                        handleImageViewClick(row.profilePictureUrl)

                                                    }
                                                    }
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ImageView />
                                                </IconButton>
                                            ) :
                                                col.field === "previousBonafideCertifiedUrl" ? (
                                                    <IconButton
                                                        onClick={() => {

                                                            handleBonafideDocViewClick(row.previousBonafideCertifiedUrl)

                                                        }
                                                        }
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <UploadFileIcon />
                                                    </IconButton>
                                                )
                                                    :
                                                    col.field === "previousTransferCertificateUrl" ? (
                                                        <IconButton
                                                            onClick={() => {

                                                                handleTransferDocViewClick(row.previousTransferCertificateUrl)

                                                            }
                                                            }
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <UploadFileIcon />
                                                        </IconButton>
                                                    )
                                                        :
                                                        col.field === "previousReportCardUrl" ? (
                                                            <IconButton
                                                                onClick={() => {

                                                                    handleReportCardDocViewClick(row.previousReportCardUrl)

                                                                }
                                                                }
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                <UploadFileIcon />
                                                            </IconButton>
                                                        )
                                                            : col.field === "documentUrls" ? (
                                                                <IconButton
                                                                    onClick={() => {

                                                                        handleDocumentIconClick(row.documentUrls)
                                                                    }}
                                                                    color="primary"
                                                                    size="small"
                                                                >
                                                                    <UploadFileIcon />
                                                                </IconButton>
                                                            )
                                                                : col.field === "age" ? (
                                                                    <p>{calculateAge(row.dateOfBirth)}</p>
                                                                )
                                                                    :
                                                                    col.field === "edit" ? (
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                handleEditClick(row)
                                                                            }}
                                                                            color="primary"
                                                                            size="small"
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    ) :
                                                                        col.field === "studentEdit" ? (
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    handleEditClick1(row)
                                                                                }}
                                                                                color="primary"
                                                                                size="small"
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        )
                                                                            : col.field === "view" ? (
                                                                                <IconButton
                                                                                    onClick={() => { handleViewClick(row) }}
                                                                                    color="primary"
                                                                                    size="small"
                                                                                >
                                                                                    <VisibilityIcon />
                                                                                </IconButton>
                                                                            ) : col.field === "parents" ? (
                                                                                <IconButton
                                                                                    onClick={() => handleParentsViewClick(row)}
                                                                                    color="primary"
                                                                                    size="small"
                                                                                >
                                                                                    <VisibilityIcon />
                                                                                </IconButton>
                                                                            ): col.field === "address" ? (
                                                                                <Tooltip title={row[col.field]} arrow>
                                                                                    <span>{row[col.field]}</span>
                                                                                </Tooltip>
                                                                            )
                                                                                : (row[col.field])}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Table Completed */}
            {data ? null : <p>No data found</p>}

            <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData && sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            />
            {/* Start of Modals */}

            <Modal show={showImageView} onHide={() => setShowImageView(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        // <object
                        //     data={`data:image/png;base64,${selectedImage}`}
                        //     // type="application/pdf"
                        //     type="image/jpeg"
                        //     width='100%'
                        //     height='100%'
                        // >
                        //     <p>Unable to display Profile Picture!</p>
                        // </object>

                    }
                    {selectedImage && (
                        <div>
                            <Image
                                src={selectedImage}
                                alt="Damage Picture"
                                style={{ width: "10rem", height: '12rem' }}
                            />
                            <Button style={{ marginLeft: '2rem', color: "blue" }} onClick={() => handleDownloadImages(selectedImage)}>
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}


                    {/* {<object type='image/jpeg' data={`data:image/png;base64,${selectedImage}`} alt="Buffer Image" />} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowImageView(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDocumentPopup1} onHide={handleCloseDocumentPopup1} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Bonafide doc</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedDocument && (
                        <div>
                            <Image
                                src={selectedDocument}
                                alt="Document"
                                style={{ width: '10rem', height: '12rem' }}
                            />
                            <Button
                                style={{ marginLeft: '2rem', color: 'blue' }}
                                onClick={() => handleDownload1(selectedDocument)}
                            >
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup1}
                        style={{
                            color: 'white',
                            backgroundColor: '#b0aeac',
                            fontSize: '12px',
                            marginRight: '10px',
                            borderRadius: '20px',
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDocumentPopup2} onHide={handleCloseDocumentPopup2} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Transfer doc</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedDocument1 && (
                        <div>
                            <Image
                                src={selectedDocument1}
                                alt="Document"
                                style={{ width: '10rem', height: '12rem' }}
                            />
                            <Button
                                style={{ marginLeft: '2rem', color: 'blue' }}
                                onClick={() => handleDownload1(selectedDocument1)}
                            >
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup2}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDocumentPopup3} onHide={handleCloseDocumentPopup3} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View ReportCard document</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedDocument2 && (
                        <div>
                            <Image
                                src={selectedDocument2}
                                alt="Document"
                                style={{ width: '10rem', height: '12rem' }}
                            />
                            <Button
                                style={{ marginLeft: '2rem', color: 'blue' }}
                                onClick={() => handleDownload1(selectedDocument2)}
                            >
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup3}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDocumentPopup} onHide={handleCloseDocumentPopup} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {documents &&
                        documents.map((imageURL, index) => (
                            <div key={index}>
                                <Image
                                    src={imageURL}
                                    alt={`Document ${index + 1}`}
                                    style={{ width: '10rem', height: '12rem' }}
                                />
                                <Button style={{ marginLeft: '2rem', color: "blue" }} onClick={() => handleDownload(imageURL)}>
                                    <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                                </Button>
                            </div>
                        ))}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {selectedRow &&
                            filteredColumns.map((col) => {
                                if (col.field === 'profilePictureUrl') {
                                    return ''
                                } else if (col.field === 'documentUrls') {
                                    return ''
                                } else if (col.field === 'previousBonafideCertifiedUrl') {
                                    return ''
                                } else {
                                    return (
                                        <Form.Group key={col.field} className="mb-3">
                                            <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                            <Form.Control type="text" value={selectedRow[col.field]} readOnly />
                                        </Form.Group>
                                    )
                                }
                            })}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowViewModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {editedData ?
                            filteredColumns.map((col) => (

                                <Form.Group key={col.field} className="mb-3">
                                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                    {col.field === "profilePictureUrl" ? (
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;

                                                const imageURL = URL.createObjectURL(files[0]);
                                                setEditedData({ ...editedData, image: imageURL })
                                            }}
                                        />
                                    ) :
                                        col.field === "previousBonafideCertifiedUrl" ? (
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    // Handle multiple document upload logic here
                                                    const files = e.target.files;

                                                    const imageURL = URL.createObjectURL(files[0]);
                                                    setEditedData({ ...editedData, selectedDocument: imageURL })
                                                }}
                                            />
                                        ) : col.field === "documentUrls" ? (
                                            <input
                                                type="file"
                                                multiple
                                                onChange={(e) => {
                                                    // Handle multiple document upload logic here
                                                    const files = e.target.files;
                                                    let editDocuments = []
                                                    for (let i = 0; i < files.length; i++) {
                                                        const imageURL = URL.createObjectURL(files[i]);
                                                        editDocuments.push(imageURL)
                                                    }
                                                    setEditedData({ ...editedData, documents: editDocuments })
                                                }}
                                            />
                                        ) : col.field === 'qualificationId' ? (
                                            <SearchableDropDownField
                                                name='qualificationId'
                                                value={editedData[col.field]}
                                                placeholder='Select Qualification'
                                                options={qualificationsOptions}
                                                onChange={(selectedOption) => {
                                                    setEditedData({ ...editedData, qualificationId: selectedOption.value })
                                                }}
                                                required={true}
                                                feedbackMessage='Select Qualification'
                                            />
                                        )
                                            : col.field === 'designation' ? (
                                                <SearchableDropDownField
                                                    name='designation'
                                                    placeholder='Select Designation'
                                                    value={editedData[col.field]}
                                                    options={designationOptions}
                                                    onChange={(selectedOption) => {
                                                        setEditedData({ ...editedData, designation: selectedOption.value })
                                                    }}
                                                    required={true}
                                                    feedbackMessage='Select Designation'
                                                />
                                            )
                                                : col.field === "Pcity" ? (
                                                    <SearchableDropDownField
                                                        name='Pcity'
                                                        placeholder='Permanent City'
                                                        value={editedData[col.field]}
                                                        options={citiesOptions}
                                                        onChange={(selectedOption) => {
                                                            setEditedData({ ...editedData, Pcity: selectedOption.value })
                                                        }}
                                                        required={true}
                                                        feedbackMessage='Permanent City is Required!'
                                                    />
                                                ) : col.field === "Pstate" ?
                                                    (
                                                        <SearchableDropDownField
                                                            name='Pstate'
                                                            value={editedData[col.field]}
                                                            placeholder='Permanenet State'
                                                            options={statesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Pstate: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Permanent State is Required!'

                                                        />
                                                    ) : col.field === "Ccity" ? (
                                                        <SearchableDropDownField
                                                            name='Ccity'
                                                            placeholder='Current City'
                                                            value={editedData[col.field]}
                                                            options={citiesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Ccity: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Permanent City is Required!'

                                                        />
                                                    ) : col.field === "Cstate" ? (
                                                        <SearchableDropDownField
                                                            name='Cstate'
                                                            value={editedData[col.field]}
                                                            placeholder='Current State'
                                                            options={statesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Cstate: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Current State is Required!'

                                                        />
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            value={editedData[col.field]}
                                                            onChange={(e) => {
                                                                setEditedData({
                                                                    ...editedData,
                                                                    [col.field]: e.target.value,
                                                                })
                                                            }
                                                            }
                                                        />
                                                    )}
                                </Form.Group>
                            )) : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowEditModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                        style={{
                            color: "white",
                            backgroundColor: "#007bff",
                            fontSize: "12px",
                            borderRadius: "20px",
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showViewModal}
                onHide={() => setShowViewModal(false)}
                // className="modal"
                style={{ '--bs-modal-width': '1500px' }}
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title> 2nd parents</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover className="table-bordered yash-table" >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date Of Birth</th>
                                <th>relation</th>
                                <th>Gender</th>
                                <th>Email</th>
                                <th>Primary Number</th>
                                <th>Secondary Number</th>
                                <th>Profile Image</th>
                                <th>Documents</th>
                                <th>Address</th>
                                <th>Area</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Pincode</th>

                            </tr>
                        </thead>
                        <tbody>
                            {selectedRowParentsRelations && selectedRowParentsRelations.length> 0 ?
                                (selectedRowParentsRelations.map((item, index) => {

                                    const dob = new Date(item.guardian.dateOfBirth);
                                    // Format date as dd-mm-yyyy
                                    const formattedDob = `${dob.getDate().toString().padStart(2, '0')}-${(dob.getMonth() + 1).toString().padStart(2, '0')}-${dob.getFullYear()}`;
                                    return (
                                        <tr key={index}>
                                            <td>{item.guardian.name}</td>
                                            <td>{formattedDob}</td>
                                            <td>{item.guardian.relation}</td>
                                            <td>{item.guardian.gender}</td>
                                            <td>{item.guardian.email}</td>
                                            <td>{item.guardian.primaryContactNumber}</td>
                                            <td>{item.guardian.secondaryContactNumber}</td>
                                            <td>
                                                <IconButton
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => handleImageViewClick(item.guardian.profilePictureUrl)}
                                                >
                                                    <ImageView />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <IconButton
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => handleDocumentIconClick(item.guardian.documentUrls)}
                                                >
                                                    <UploadFileIcon />
                                                </IconButton>
                                            </td>
                                            <td>{item.guardian.currentAddress.address}</td>
                                            <td>{item.guardian.currentAddress.area.name}</td>
                                            <td>{item.guardian.currentAddress.city.name}</td>
                                            <td>{item.guardian.currentAddress.state.name}</td>
                                            <td>{item.guardian.currentAddress.pincode}</td>
                                        </tr>
                                    );
                                })): (null)}
                        </tbody>

                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowViewModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default StudentViewTable;

