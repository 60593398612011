// TableRowComponent.js
import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import TextInputField from '../../reUsableForm/textInputField';
import { amountPattern } from '../../reUsableForm/regex';
  // Import your TextInputField component

const CustomTableRow = ({ dueDateValue, amountValue, onChangeDueDate, onChangeAmount }) => {
    return (
        <TableRow>
            <TableCell>
                <TextInputField
                    name="Fee Name"
                    placeholder="Fee Name"
                    textType="Alphabet"
                    disabled
                    value={dueDateValue}
                />
            </TableCell>
            <TableCell>
                <TextInputField
                    name="Amount"
                    placeholder="Amount"
                    textType="Alphabet"
                    value={amountValue}
                    onChange={onChangeAmount}
                    feedbackMessage="Please enter Amount"
                    validationRegex={amountPattern}  // Replace with your validationRegexAmount
                    validationErrorMessage="Please enter Amount (only numbers)."
                />
            </TableCell>
        </TableRow>
    );
};

export default CustomTableRow;
