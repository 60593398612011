import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { franchiseeSchoolId, token } from "../../../Api/token";
import { fetchParentSuccess, setFormData } from "../addParentsSlicer";

const slice = createSlice({
  name: "viewParentRelation",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchParentRelationDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchParentRelationDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchParentRelationDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchParentRelationDataStart,
  fetchParentRelationDataSuccess,
  fetchParentRelationDataFailure,
} = slice.actions;


export const fetchParentRelationDataOnce = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewParentRelation;

  const tokenId = token();

  const FranchiseeSchoolId = franchiseeSchoolId()
  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchParentRelationDataStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/guardian-relationships?isActive=true&franchiseeSchoolId=${FranchiseeSchoolId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error("Request failed"); // You can customize error handling
      }

      const data1 = await response.json();

      dispatch(fetchParentRelationDataSuccess(data1));
      dispatch(setFormData([]));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchParentRelationDataFailure());
    }
  }
};

export default slice.reducer;
