import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectionDataOnce } from "./slice/viewSectionSlice";
import { fetchDataOnce } from "../Classes/slice/viewClassSlice";

function ViewSection() {
  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "name", header: "Section" },
    { field: "className", header: "Class Name" },
  ];

  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.viewSection.data) || [];
  const postData = useSelector((state) => state.addSection.data) || [];
  const getClassData = useSelector((state) => state.viewClass.data) || [];
  const postClassData = useSelector((state) => state.addClass.data);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchSectionDataOnce());
    dispatch(fetchDataOnce());
  }, [dispatch]);

  useEffect(() => {
    if (getClassData.length > 0) {
      setLoading(false); // Set loading to false once getClassData is available
    }
  }, [getClassData]);

  const combinedData = [...apiData, ...postData];

  const classData = [...(postClassData || []), ...getClassData];

  const processedData = combinedData.map((row, index) => {
    const classNameObj = classData.find((cls) => cls.id === row.classId);
    const className = classNameObj ? classNameObj.name : "";
    return {
      ...row,
      className: className,
      index: index + 1,
    };
  });

  return (
    <>
      {/* <div className="content-header">View Section</div> */}
      {processedData  === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : processedData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table
          data={processedData}
          columns={columns}
          hover={true}
          striped={true}
        />
      )}
    </>
  );
}

export default ViewSection;
