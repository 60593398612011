import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./textInputField.css";

const TimeInputField = ({
  label,
  name,
  required,
  value = '',
  type,
  onChange,
  placeholder,
  feedbackMessage,
  colSize,
  validated,
  validationRegex,
  validationErrorMessage,
  maxLength,
  textType,
  disabled,
  mt4
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
  
    // Check for empty value
    if (required && inputValue.trim() === "") {
      setError(feedbackMessage || "This field is required.");
    } else {
      // Validate input based on regex
      if (maxLength && inputValue.length > maxLength) {
        inputValue = inputValue.substring(0, maxLength);
      }
  
      const isValidInput = validationRegex.test(inputValue);
  
      if (isValidInput) {
        setError(null);
        onChange({ target: { name, value: inputValue } });
      } else {
        setError(validationErrorMessage);
      }
    }
  
    // Check for validation only when the form is submitted (validated prop is true)
    if (validated) {
      setError((prevError) => prevError || feedbackMessage || validationErrorMessage);
    }
  };
  

  return (
    <Form.Group className={`mb-4 ${mt4}`}>
      {label && <Form.Label className="label-style">{label}</Form.Label>}
      <Form.Control
        name={name}
        className="textInput_Field"
        required={required}
        placeholder={placeholder}
        value={value}
        type={type}
        disabled={disabled}
        onChange={handleInputChange}
        maxLength={maxLength}
        isInvalid={!!error}
      />

      <Form.Control.Feedback type="invalid" className="error-message">
        {error ||
          (validated && !validationRegex.test(value) && error) ||
          (validated && value.length <= 0 && feedbackMessage)
        }
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TimeInputField;
