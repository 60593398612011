import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import '../addTeacher.css';
import { validationRegexAlphaNumeric, validationRegexOnlyPincode, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea, emailRegex, decimalRegex, textCommaRegex, validationRegexAmount, commaAlpha, decimalTest, distancePattern, amountWithDecimalPattern, validationRegexSalary } from '../../reUsableForm/regex'
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from '../../reUsableForm/pop-card/errorMessage';
import TextInputField from '../../reUsableForm/textInputField';
import DateInputField from '../../../ReusableFields/DateFields/DateField';
import SearchableDropDownField from '../../../ReusableFields/DropDown/SearchableDropDownField';
import { postEmployeeData } from '../teacherSlicers/allRequests'
import EmailInputField from '../../../ReusableFields/EmailFields/EmailField';
import { changeLoadingStateToIdle } from '../teacherSlicers/employeeViewSlicer';
import useCitiesState from './useCitiesState';
import ReadOnlyTextField from '../../../ReusableFields/TextFields/ReadOnlyTextField';
import { calculateAge } from '../teacherSlicers/newDataFilterFunctions';
import { uploadFiles } from '../../reUsableForm/uploadImage-docs';
const useEmployeeForm = (defaultData) => {

  const [formData, setFormData] = useState(defaultData);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const postEmployeeStatusObject = useSelector((state) => state.employeeSlicer?.teachers?.postEmployeeData);
  const backendAreas = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeAreas?.data);
  const [qualificationsOptions, statesOptions, citiesOptions, designationOptions, areasOptions] = useCitiesState();
  const [dropdownIds, setDropDownIds] = useState({})
  const [imageUploadError, setImageUploadError] = useState(false);
  const [docUploadError, setDocUploadError] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [docErrorMsg, setDocErrorMsg] = useState('');
  const [uploadDoc, setUploadDoc] = useState(null)
  const [uploadImage, setUploadImage] = useState(null)

  const storeResponse = () => {
    const loading = postEmployeeStatusObject?.loading;
    if (loading === 'fulfilled') {
      setSuccessMsg('Form Submitted SuccessFully! ')
      setShowSuccessMsg(true);
    } else if (loading === 'rejected' || imageUploadError || docUploadError) {
      setShowErrorMsg(true);
      setErrorMsg(postEmployeeStatusObject?.error);
    }
  }

  useEffect(() => {
    storeResponse();

  }, [postEmployeeStatusObject?.loading, imageUploadError, docUploadError])

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setUploadImage(file)

    const imageData = new FormData();
    imageData.append('file', file);
    setFormData({ ...formData, profilePictureUrl: imageData });
  }

  const handleDocumentChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadDoc(files);  // Set all files
    setFormData({ ...formData, documentUrls: files.map(file => file.name) });
  };

  const handleClear = () => {
    setValidated(false);
    setFormData(defaultData);
    setUploadDoc(null)

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form?.checkValidity() === false ||
      !formData?.gender || !formData?.profilePictureUrl || formData?.documentUrls?.length < 1 ||
      !formData?.qualificationId || !formData?.designation || !formData?.Parea || !formData?.Carea
    ) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

  let profilePictureUrl = '';
  let documentUrls = [];

  if (uploadImage) {
    const imageResponse = await uploadFiles([uploadImage], "employees/profile-image");
    profilePictureUrl = imageResponse[0]?.Location || '';
  }

  if (uploadDoc) {
    const docResponse = await uploadFiles(uploadDoc, "employees/docs");
    documentUrls = docResponse.map(file => file.Location);
  }

  // If profilePictureUrl is still empty, set an error message and return
  if (!profilePictureUrl) {
    setImageUploadError(true);
    setImageErrorMsg("Profile picture upload failed. Please try again.");
    return;
  }

    const { Caddress, Cpin, Paddress, Ppin } = formData;
    const permanentAddress = {
      address: Paddress,
      stateId: dropdownIds?.Pstate,
      cityId: dropdownIds?.Pcity,
      pincode: Ppin,
      areaId: dropdownIds?.Parea,
      type: 'permanent'
    }
    const currentAddress = {
      address: Caddress,
      stateId: dropdownIds?.Cstate,
      cityId: dropdownIds?.Ccity,
      pincode: Cpin,
      areaId: dropdownIds?.Carea,
      type: "current"
    }


    const clone = (({ Paddress, age, Pcity, Parea, Carea, Pstate, Ppin, Caddress, Ccity, Cpin, Cstate, ...o }) => o)(formData) // remove b and c
    const requiredData = {
      ...clone, permanentAddress, currentAddress,
      
      qualificationId: dropdownIds?.qualificationId,
      profilePictureUrl,
      documentUrls,

    }
    dispatch(postEmployeeData(requiredData));
  }

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (name === "dateOfBirth") {
      const age = calculateAge(value);
      setFormData({ ...formData, [name]: value, age: age })
    } else if (name === 'primaryContactNumber' || name === 'secondaryContactNumber' || name === 'referenceContactNumber') {
      setFormData({ ...formData, [name]: Number(value) })
    }
    else {
      setFormData({ ...formData, [name]: value })
    }
  }
  const displayErrorMsg = () => {
    if (showErrorMsg && (!imageUploadError && !docUploadError)) {
      return Array.isArray(postEmployeeStatusObject?.error) ?
        postEmployeeStatusObject?.error?.map(e => { return <li>{e?.message}</li> }) :
        postEmployeeStatusObject?.error
    } else if (imageUploadError) {
      return imageErrorMsg
    } else if (docUploadError) {
      return docErrorMsg;
    }
  }
  const getBorderColor = () => {
  };
  return (
    <div style={{ height: "100vh" }}>
      <h4 className="content-header ">Add Employee</h4>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <DateInputField
                  placeholder='Joining Date'
                  name='dateOfJoining'
                  value={formData?.dateOfJoining}
                  // label='Joining Date'
                  required={true}
                  validated={validated}
                  feedbackMessage='Joining date is required'
                  onChange={handleChange}
                />
              </Col>
              {/* </Row>
            <Row> */}
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name='name'
                  placeholder='Enter your Name'
                  textType='Alphabet'
                  validated={validated}
                  required='true'
                  maxLength={25}
                  feedbackMessage='Name is Required!'
                  validationErrorMessage='Give valid Name!'
                  value={formData?.name}
                  validationRegex={validationRegexAlphaNumeric}
                  onChange={handleChange}
                />

              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="code"
                  placeholder="Employee Code"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  maxLength={25}
                  required={true}
                  value={formData?.code}
                  onChange={handleChange}
                  feedbackMessage="Please enter employee code."
                  validationRegex={/^(?![\s])[\w]*$/}
                  validationErrorMessage="Please enter a valid employee code."
                />
              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <Form.Label className='lableName'>Gender</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Male"
                      type="radio"
                      name="gender"
                      value="male"
                      className='malebutton'
                      checked={formData?.gender === "male"}
                      onChange={(event) => setFormData({ ...formData, gender: event.target.value })}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      type="radio"
                      name="gender"
                      value="female"
                      className='malebutton'
                      checked={formData?.gender === "female"}
                      onChange={(event) => setFormData({ ...formData, gender: event.target.value })}
                    />
                  </div>
                </Form.Group>
                {
                  validated && !formData?.gender ? <p className='add-employee-error'>Select Gender</p> : ''
                }
              </Col>

              <Col xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <div
                    className="uploadDoc"
                    style={{
                      border: `1px solid ${getBorderColor()}`,
                    }}
                  >
                    <div className="layout1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                          fill="#636363"
                        />
                      </svg>
                      <div className="title1">Upload&nbsp;Image</div>
                    </div>
                    <input
                      type="file"
                      // name='profilePictureUrl'
                      accept='.jpg, .jpeg'
                      onChange={handleImageChange}
                      // className='uploadDocInput'
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 1,
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>
                  <div>
                    {
                      validated && !formData?.profilePictureUrl ? <p className="add-employee-error">Upload profile picture</p> : ''
                    }

                  </div>
                </Form.Group>
                {
                  formData?.profilePictureUrl ? <p>(1) Profile image selected</p> : ''
                }
              </Col>
              {/* </Row>

            <Row> */}
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <DateInputField
                  placeholder='Date of Birth'
                  name='dateOfBirth'
                  value={formData?.dateOfBirth}
                  required={true}
                  validated={validated}
                  feedbackMessage='Date of birth is required'
                  onChange={handleChange}
                />

              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name="age"
                  placeholder="age"
                  value={formData?.age}
                  required={true}
                  feedbackMessage='Date of birth is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.dateOfBirth ? <p className='add-employee-error' style={{ marginTop: '-25px' }}>Date of birth is Required!</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <EmailInputField
                  name="email"
                  placeholder="Email"
                  className="fcontrol"
                  required={true}
                  validated={validated}
                  value={formData?.email}
                  feedbackMessage="Please enter Email ."
                  validationRegex={emailRegex}
                  validationErrorMessage="Please enter valid Email ."
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group >
                  <div className="uploadDoc"

                    style={{
                      border: `1px solid ${getBorderColor()}`, // Dynamic border color
                    }}
                  >
                    <div className="layout1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                          fill="#636363"
                        />
                      </svg>
                      <div className="title1">Upload&nbsp;Docu</div>
                    </div>
                    <input
                      type="file"
                      multiple
                      accept=".pdf, .doc,.docx"
                      onChange={handleDocumentChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 1,
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>

                </Form.Group>
                {
                  validated && formData?.documentUrls?.length < 1 ? <p className="add-employee-error">Upload Documents</p> : ''
                }

                {
                  formData?.documentUrls?.length > 0 ? <p>{formData?.documentUrls?.length} documents are selected</p> : ''
                }

              </Col>
              {/* </Row>
            <Row> */}
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="primaryContactNumber"
                  placeholder="Mobile Number"
                  className="fcontrol"
                  type='text'
                  required={true}
                  validated={validated}
                  value={formData?.primaryContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Mobile number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Number should contain 10 digits!"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="secondaryContactNumber"
                  placeholder="Secondary Mobile Number"
                  className="fcontrol"
                  type='text'
                  required={true}
                  validated={validated}
                  value={formData?.secondaryContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Secondary Mobile number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Number should contain 10 digits!"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDownField
                  name='qualificationId'
                  value={formData?.qualificationId ? { label: formData?.qualificationId } : null}
                  isSearchable
                  placeholder='Select Qualification'
                  options={qualificationsOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...formData, qualificationId: selectedOption?.id })
                    setFormData({ ...formData, qualificationId: selectedOption?.value })
                  }}
                  required={true}
                  feedbackMessage='Select Qualification'
                  validated={validated}
                />
                {
                  validated && !formData?.qualificationId ? <p className='add-employee-error'>Select Qualification</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <SearchableDropDownField
                  name='designation'
                  placeholder='Select Designation'
                  value={formData?.designation ? { label: formData?.designation } : null}
                  isSearchable
                  options={designationOptions}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, designation: selectedOption?.value })
                  }}
                  required={true}
                  feedbackMessage='Select Designation'
                  validated={validated}
                />
                {
                  validated && !formData?.designation ? <p className='add-employee-error'>Select Designation</p> : ''
                }
              </Col>
              {/* </Row>
            <Row> */}

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="religion"
                  placeholder="Religion"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.religion}
                  onChange={handleChange}
                  feedbackMessage="Religion is Required!"
                  //validationRegex={validationRegexOnlyAlpha}
                  validationRegex={/^(?![\s])[a-zA-Z]*$/}
                  validationErrorMessage="Enter valid Religion"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="languagesKnown"
                  placeholder="Languages Known"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.languagesKnown}
                  onChange={handleChange}
                  feedbackMessage="Known Languages are Required!"
                  //validationRegex={commaAlpha}
                  //validationRegex={/^(?:,?[a-zA-Z][a-zA-Z]*,?)+$/}
                  validationRegex={/^(?![\s])^[a-zA-Z,]*$/}
                  validationErrorMessage="Enter valid Known Languages"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="salary"
                  placeholder="Present Organisation Salary"
                  className="fcontrol"
                  validated={validated}
                  required={true}
                  value={formData?.salary}
                  onChange={handleChange}
                  feedbackMessage="Present Organisation salary is Required!"
                  validationRegex={validationRegexAmount}
                  validationErrorMessage="salary should consist digits only"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="remark"
                  placeholder="Remarks"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.remark}
                  onChange={handleChange}
                  feedbackMessage="Remarks are Required!"
                  validationRegex={validationRegexOnlyTextarea}
                  validationErrorMessage="Enter valid textarea"
                />
              </Col>
              {/* </Row>
            <Row> */}
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="workExperience"
                  placeholder="Work Experience"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.workExperience}
                  onChange={handleChange}
                  feedbackMessage="Work experience is Required!"
                  validationRegex={distancePattern}
                  validationErrorMessage="Work experience should be in decimals"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="previousEmployment"
                  placeholder="Previous Organisation Name"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.previousEmployment}
                  onChange={handleChange}
                  feedbackMessage="Previous Employment Name is Required!"
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Previous Employment Name should be in decimals"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="referenceContactName"
                  placeholder="Reference Name"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.referenceContactName}
                  onChange={handleChange}
                  feedbackMessage="Reference Name is Required!"
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Reference Name should be in alphabets"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="referenceContactNumber"
                  placeholder="Reference Number"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.referenceContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Reference Number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Reference Number should be 0-10 digits"
                />
              </Col>
              {/* </Row>
            <Row> */}
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <DateInputField
                  placeholder='previous organisation date of join'
                  name='previousEmploymentJoiningDate'
                  value={formData?.previousEmploymentJoiningDate}
                  required={true}
                  // label='Join date of previous organisation'
                  validated={validated}
                  feedbackMessage='Previous organisation Date of joining is required'
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <DateInputField
                  placeholder='previous organisation last working day'
                  name='previousEmploymentLeavingDate'
                  value={formData?.previousEmploymentLeavingDate}
                  // label='Last working date of previous org...'
                  required={true}
                  validated={validated}
                  feedbackMessage='Previous organisation last working day is required'
                  onChange={handleChange}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="previousEmploymentLastDrawnSalary"
                  placeholder="Previous Organisation Salary"
                  className="fcontrol"
                  validated={validated}
                  required={true}
                  // label='    '
                  value={formData?.previousEmploymentLastDrawnSalary}
                  onChange={handleChange}
                  feedbackMessage="previous Organisation salary is Required!"
                  validationRegex={validationRegexAmount}
                  validationErrorMessage="salary should consist digits only"
                />
              </Col>

            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={4} className="rowCss">
                <TextInputField
                  name="Paddress"
                  placeholder="Permanent Address"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.Paddress}
                  onChange={handleChange}
                  feedbackMessage="Permanent Address is Required!"
                  validationRegex={/^(?![\s])^[a-zA-Z0-9,\s-/]*$/}
                  validationErrorMessage="Enter valid Permanent address"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <SearchableDropDownField
                  name='Parea'
                  placeholder='Select Area'
                  value={formData?.Parea ? { label: formData?.Parea } : null}
                  isSearchable
                  options={areasOptions}
                  onChange={(selectedOption) => {
                    const requiredObj = backendAreas?.find((each) => each?.id === selectedOption?.id);
                    const state = requiredObj?.city?.state?.name;
                    const city = requiredObj?.city?.name;
                    setFormData({ ...formData, Parea: selectedOption?.value, Pcity: city, Pstate: state })
                    setDropDownIds({ ...dropdownIds, Parea: selectedOption?.id, Pcity: requiredObj?.city?.id, Pstate: requiredObj['city']['stateId'] })
                  }}
                  required={true}
                  feedbackMessage='Permanent Area is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Parea ? <p className='add-employee-error'>Permanent Area is Required!</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <ReadOnlyTextField
                  name='Pcity'
                  placeholder='Permanent city'
                  value={formData?.Pcity}
                  required={true}
                  feedbackMessage='Permanent city is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Parea ? <p className='add-employee-error' style={{ marginTop: '-25px' }}>Permanent Area is Required!</p> : ''
                }
              </Col>

              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <ReadOnlyTextField
                  name='Pstate'
                  placeholder='Permanent state'
                  value={formData?.Pstate}
                  required={true}
                  feedbackMessage='Permanent state is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Parea ? <p className='add-employee-error' style={{ marginTop: '-25px' }}>Permanent Area is Required!</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <TextInputField
                  name="Ppin"
                  placeholder="Permanent Pincode"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.Ppin}
                  onChange={handleChange}
                  feedbackMessage="Permanent Pincode is Required!"
                  validationRegex={validationRegexOnlyPincode}
                  validationErrorMessage="Enter valid Pincode"
                />
              </Col>

            </Row>

            <Row>
              <Col sm={12} xs={12} md={6} lg={4} className="rowCss">

                <TextInputField
                  name="Caddress"
                  placeholder="Current Address"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.Caddress}
                  onChange={handleChange}
                  feedbackMessage="Current Address is Required!"
                  //validationRegex={validationRegexAlphaNumeric}
                  validationRegex={/^(?![\s])^[a-zA-Z0-9,\s-/]*$/}
                  validationErrorMessage="Enter valid Current address"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <SearchableDropDownField
                  name='Carea'
                  placeholder='Select Area'
                  value={formData?.Carea ? { label: formData?.Carea } : null}
                  options={areasOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Carea: selectedOption?.id })
                    const requiredObj = backendAreas?.find((each) => each?.id === selectedOption?.id);
                    const state = requiredObj?.city?.state?.name;
                    const city = requiredObj?.city?.name;
                    setFormData({ ...formData, Carea: selectedOption?.value, Ccity: city, Cstate: state })
                    setDropDownIds({ ...dropdownIds, Carea: selectedOption?.id, Ccity: requiredObj?.city?.id, Cstate: requiredObj['city']['stateId'] })

                  }}
                  required={true}
                  feedbackMessage='Current Area is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Carea ? <p className='add-employee-error'>Current Area is Required!</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <ReadOnlyTextField
                  name='Ccity'
                  placeholder='Current City'
                  value={formData?.Ccity}
                  required={true}
                  feedbackMessage='Current City is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Carea ? <p className='add-employee-error' style={{ marginTop: '-25px' }}>Current Area is Required!</p> : ''
                }
              </Col>

              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <ReadOnlyTextField
                  name='Cstate'
                  placeholder='Current State'
                  value={formData?.Cstate}
                  required={true}
                  feedbackMessage='Current City is Required!'
                  validated={validated}
                />
                {
                  validated && !formData?.Carea ? <p className='add-employee-error' style={{ marginTop: '-25px' }}>Current Area is Required!</p> : ''
                }
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
                <TextInputField
                  name="Cpin"
                  placeholder="Current Pincode"
                  className="fcontrol"
                  type='text'
                  required={true}
                  value={formData?.Cpin}
                  validated={validated}
                  onChange={handleChange}
                  feedbackMessage="Current Pincode is Required!"
                  validationRegex={validationRegexOnlyPincode}
                  validationErrorMessage="Enter valid Pincode"
                />
              </Col>
            </Row>
            {
              postEmployeeStatusObject?.loading === 'fulfilled' &&
              showSuccessMsg &&
              <SuccessMessagePopup show={showSuccessMsg} onClose={() => { dispatch(changeLoadingStateToIdle()); handleClear(); setShowSuccessMsg(false) }} successMessage="Form submitted successfully" />

              //add handleClear function to successpopup in the last!
            }
            {

              // (postEmployeeStatusObject?.loading === 'rejected' &&
              // showErrorMsg)||(imageUploadError) || (docUploadError) &&
              <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(changeLoadingStateToIdle()); setShowErrorMsg(false); setImageUploadError(false); setDocUploadError(false) }}
                errorMessage={displayErrorMsg()} />}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1%",
                marginTop: "5%"
              }}
            >
              <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
              >
                Clear
              </button>
              <button className="save-button-figma-div" type="submit">
                Submit
              </button>
            </div>
          </Form>

        </Col>
      </Row>
    </div>
  );
};

export default useEmployeeForm;