import React, { useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewCitySlice";
import { fetchStateDataOnce } from "../../components/state/slice/viewStateSlice";

const ViewCity = () => {
  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "stateName", header: "State" },
    { field: "name", header: "City" },
  ];

  const apiData = useSelector((state) => state.ViewCity.data) || [];
  const postData = useSelector((state) => state.city.data) || [];
  const data = useSelector((state) => state.viewState.data) || [];
  const getData = useSelector((state) => state.state.data) || [];

  const addData = [...(data||[]),...getData]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataOnce());
    dispatch(fetchStateDataOnce());
  }, [dispatch])

  let newModifiedData = []

  if (postData && postData.length > 0) {
    postData.forEach((postItem) => {
      const modifiedData = postItem;
      const findObject = addData.find((obj) => obj.id == postItem.stateId);
      const newData = {
        ...modifiedData,
        state: findObject,
        stateName: findObject?.name
      };
      newModifiedData.push(newData);
    });
  }

  const combinedData = [...(apiData || []), ...(newModifiedData)];
  const processedData = combinedData.map((row,index) => ({
    ...row,
    index:index+1,
    stateName: row.state ? row.state.name : "",
  }));

  return (
    <>
      {/* <div className="content-header">View City</div> */}
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={processedData} columns={columns} hover={true} striped={true} />
        )
      }
    </>
  );
}
export default ViewCity;
