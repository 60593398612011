import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
export const getFetchFunction = async (tailUrl) => {
    const tokenId = token();
    
    const baseUrl = `${API_PATH}/${tailUrl}`;
    const fetched = await fetch(baseUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${tokenId}`
        }
    });
    const data = await fetched.json();
    return data;
}

export const getParentLoginData = createAsyncThunk("getParentLogin", async (payload, thunkApi) => {
    try {
        const data = await getFetchFunction(`guardian-profile/${payload}`);
        return data;
    } catch (error) {
        return thunkApi.rejectWithValue(error.message);
    }
})