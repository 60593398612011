import React, { useEffect, useState } from "react";
import { Form, Modal,Image } from "react-bootstrap";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, Button, TextField, MenuItem, Select, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Image as ImageView } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CloudDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeRow } from "../Teachers/teacherSlicers/employeeViewSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import useCitiesState from "../Teachers/customHooks/useCitiesState";
import { calculateAge } from "../Teachers/teacherSlicers/newDataFilterFunctions";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchParentRelationDataOnce } from "../parent/slice/viewParentRelationSlice";
import "./parentViewTable.css";
import Tooltip from '@mui/material/Tooltip';
import { DownloadOutlined } from "@ant-design/icons";
const ParentViewTable = ({
    data = null,
    columns = null,

}) => {
    const dispatch = useDispatch();

    const editedRowValue = useSelector((state) => state.employeeSlicer?.teachers?.employeeRow)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRowGuardianRelations, setSelectedRowGuardianRelations] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState(columns && columns[0] ? columns[0].field : "");
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [selectedDocument1, setSelectedDocument1] = useState([]);
    const [selectedDocument2, setSelectedDocument2] = useState([]);
    const [showImageView, setShowImageView] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDocumentPopup, setShowDocumentPopup] = useState(false);
    const [showDocumentPopup1, setShowDocumentPopup1] = useState(false);
    const [showDocumentPopup2, setShowDocumentPopup2] = useState(false);
    const [showDocumentPopup3, setShowDocumentPopup3] = useState(false);

    const [documents, setDocuments] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);

    const [editedData, setEditedData] = useState(editedRowValue);

    const getData = useSelector((state) => state.viewParentRelation.data) || [];

    useEffect(() => {
        dispatch(fetchParentRelationDataOnce());
    }, [dispatch]);



    const [qualificationsOptions, statesOptions, citiesOptions, designationOptions] = useCitiesState();
    const navigate = useNavigate();
    const getCaps = (head, field) => (head ? head.toUpperCase() : field.toUpperCase());



    const handleEditClick = (row) => {
        // dispatch(fetchStudentDataSuccess(row))
        navigate(`/guardian/${row.id}`)
    };

    const handleEditSave = () => {
        setShowEditModal(false);
    };

    const handleRelationsViewClick = (row) => {
        setSelectedRowGuardianRelations({ relations: row.relations });
        setShowViewModal(true);
    };

    const downloadDocument = (docData) => {
        if (!docData || !docData.data || !docData.data.data || !docData.type || !docData.name) {
            console.error("Invalid document data");
            return;
        }

        const stringForm = bufferToBase64(docData.data.data);
        const downloadLink = document.createElement('a');

        downloadLink.href = `data:${docData.type};base64,${stringForm}`;
        downloadLink.download = docData.name;

        // Append the link to the body, trigger the download, and remove the link after download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    const bufferToBase64 = (buffer) => {
        return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    };
    const handleImageViewClick = async (image) => {
        setSelectedImage(image);
        setShowImageView(true);

    };
    const handleDownloadImages = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'images.jpg'; // You can customize the downloaded file name here
        link.click();
    };
    const handleBonafideDocViewClick = async (doc1) => {
        try {
            const tokenId = token();
            const response = await fetch(API_PATH + `/uploadFiles/?fileId=${doc1}`, {
                method: 'GET',
                //   body: formData.profilePictureUrl,
                headers: {
                    'Authorization': `Bearer ${tokenId}`
                }
            });
            const data = await response.json();

            const base64Documents = data?.length > 0 ? data?.map(e => {
                const buffer = bufferToBase64(e.data.data)
                return { ...e, [e.data.data]: buffer }
            }) : [];
            setSelectedDocument(base64Documents);
            setShowDocumentPopup1(true);
        } catch (err) {
            console.error(err);
        }

    };

    const handleTransferDocViewClick = async (doc1) => {
        try {
            const tokenId = token();
            const response = await fetch(API_PATH + `/uploadFiles/?fileId=${doc1}`, {
                method: 'GET',
                //   body: formData.profilePictureUrl,
                headers: {
                    'Authorization': `Bearer ${tokenId}`
                }
            });
            const data = await response.json();

            const base64Documents = data?.length > 0 ? data?.map(e => {
                const buffer = bufferToBase64(e.data.data)
                return { ...e, [e.data.data]: buffer }
            }) : [];
            setSelectedDocument1(base64Documents);
            setShowDocumentPopup2(true);
        } catch (err) {
            console.error(err);
        }

    };

    const handleReportCardDocViewClick = async (doc1) => {
        try {
            const tokenId = token();
            const response = await fetch(API_PATH + `/uploadFiles/?fileId=${doc1}`, {
                method: 'GET',
                //   body: formData.profilePictureUrl,
                headers: {
                    'Authorization': `Bearer ${tokenId}`
                }
            });
            const data = await response.json();

            const base64Documents = data?.length > 0 ? data?.map(e => {
                const buffer = bufferToBase64(e.data.data)
                return { ...e, [e.data.data]: buffer }
            }) : [];
            setSelectedDocument2(base64Documents);
            setShowDocumentPopup3(true);
        } catch (err) {
            console.error(err);
        }

    };



    const handleDocumentIconClick =  (docList) => {
        setDocuments(docList);
        setShowDocumentPopup(true);

    };
    const handleDownload = (imageURL) => {
        // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = 'document.pdf'; // You can customize the downloaded file name here
        link.click();
      };


    const handleCloseDocumentPopup = () => {
        setShowDocumentPopup(false);

    };
    const handleCloseDocumentPopup1 = () => {

        setShowDocumentPopup1(false);
    };
    const handleCloseDocumentPopup2 = () => {

        setShowDocumentPopup2(false);
    };
    const handleCloseDocumentPopup3 = () => {

        setShowDocumentPopup3(false);
    };
    const handleSortClick = (column) => {
        if (column === "edit") {
            return;
        }
        if (column === "studentEdit") {
            return;
        }

        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };


    function filterDataById(getData, data) {
        // Extracting IDs from the data array
        const ids = data.map(item => item.id);
        // Filtering getData array based on matching IDs
        const filteredData1 = getData.filter(item => {
            return ids.includes(item.fatherId) || ids.includes(item.motherId) || ids.includes(item.guardianId);
        });
        // Extracting IDs of filtered getData objects
        const filteredIds = filteredData1.map(item => item.id);


        return filteredIds;
    }



    const filteredIds = filterDataById(getData, data);

    const firstFilteredObject = getData?.find(item => item.id === filteredIds[0]);

    const filteredData = data ? data.filter((row) => {
        const searchValue = searchQuery.toLowerCase();
        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnValue = row[column.field];

            if (typeof columnValue === "string") {
                if (columnValue.toLowerCase().includes(searchValue)) {
                    return true;
                }
            } else if (typeof columnValue === "number") {
                if (columnValue.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    }) : [];
 
    const filteredData2 = firstFilteredObject ? Object.keys(firstFilteredObject).map((key) => {
        const person = firstFilteredObject[key];
        const currentAddress = person?.currentAddress;

        // Check if currentAddress is defined before accessing its properties
        if (currentAddress) {
            return {
                id: person.id,
                address: currentAddress.address,
                area: currentAddress.area ? currentAddress.area.name : '', // Check if area is defined
                city: currentAddress.city ? currentAddress.city.name : '', // Check if city is defined
                state: currentAddress.state ? currentAddress.state.name : '', // Check if state is defined
                pincode: currentAddress.pincode,
                // Assuming 'srNo' is an incremental value based on the order of persons
                srNo: parseInt(key) + 1
            };
        } else {
            // Handle case where currentAddress is undefined
            return null;
        }
    }).filter(Boolean) : [];


    const sortedData = filteredData && filteredData.slice().sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (typeof valueA === "string" && typeof valueB === "string") {
            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        } else {
            if (sortOrder === "asc") {
                return valueA < valueB ? -1 : 1;
            } else {
                return valueB < valueA ? -1 : 1;
            }
        }
    });
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsBeforeAge = sortedData && sortedData.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = currentItemsBeforeAge?.map((each) => { return { ...each, age: calculateAge(each.dateOfBirth) } })
    const filteredColumns = columns.filter(
        (col) => !["id", "view", "edit", "studentEdit", "delete", "parentEdit"].includes(col.field)
    );
  


    return (
        <div className="table-Maindiv">
            <TextField
                placeholder="Search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "10px" }}
            />
         <TableContainer className="table-container">
    <Table stickyHeader>
        <TableHead>
            <TableRow>
                {columns &&
                    columns.map((head) => (
                        <TableCell 
                            sx={{ 
                                backgroundColor: "#E2E8F0", 
                                color: "#464E5F", 
                                fontFamily: "Poppins", 
                                fontSize: "14px", 
                                fontStyle: "normal", 
                                fontWeight: "600", 
                                lineHeight: "20px", 
                                textAlign: "center", 
                                whiteSpace: "nowrap" 
                            }} 
                            key={head.field} 
                            className={`tablehead ${head.field === 'address' ? 'long-address' : ''}`}
                        >
                            <TableSortLabel
                                active={sortColumn === head.field}
                                direction={sortOrder}
                                onClick={() => handleSortClick(head.field)}
                            >
                                {head.header}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {currentItems && currentItems.map((row, rowIndex) => (
                <TableRow key={row.id}>
                    {columns.map((col) => (
                        <TableCell 
                            key={col.field} 
                            className={`tablecell ${col.field === 'address' ? 'long-address' : ''}`}
                        >
                            {
                                col.field === "profilePictureUrl" ? (
                                    <IconButton
                                        onClick={() => handleImageViewClick(row.profilePictureUrl)}
                                        color="primary"
                                        size="small"
                                    >
                                        <ImageView />
                                    </IconButton>
                                ) : col.field === "previousBonafideCertifiedUrl" ? (
                                    <IconButton
                                        onClick={() => handleBonafideDocViewClick(row.previousBonafideCertifiedUrl)}
                                        color="primary"
                                        size="small"
                                    >
                                        <UploadFileIcon />
                                    </IconButton>
                                ) : col.field === "previousTransferCertificateUrl" ? (
                                    <IconButton
                                        onClick={() => handleTransferDocViewClick(row.previousTransferCertificateUrl)}
                                        color="primary"
                                        size="small"
                                    >
                                        <UploadFileIcon />
                                    </IconButton>
                                ) : col.field === "previousReportCardUrl" ? (
                                    <IconButton
                                        onClick={() => handleReportCardDocViewClick(row.previousReportCardUrl)}
                                        color="primary"
                                        size="small"
                                    >
                                        <UploadFileIcon />
                                    </IconButton>
                                ) : col.field === "documentUrls" ? (
                                    <IconButton
                                        onClick={() => handleDocumentIconClick(row.documentUrls)}
                                        color="primary"
                                        size="small"
                                    >
                                        <UploadFileIcon />
                                    </IconButton>
                                ) : col.field === "age" ? (
                                    <p>{calculateAge(row.dateOfBirth)}</p>
                                ) : col.field === "edit" ? (
                                    <IconButton
                                        onClick={() => handleEditClick(row)}
                                        color="primary"
                                        size="small"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                ) : col.field === "relations" ? (
                                    <IconButton
                                        onClick={() => handleRelationsViewClick(row)}
                                        color="primary"
                                        size="small"
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                ) : col.field === "address" ? (
                                    <Tooltip title={row[col.field]} arrow>
                                        <span>{row[col.field]}</span>
                                    </Tooltip>
                                ) : (
                                    row[col.field]
                                )
                            }
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>

            {/* Table Completed */}
            {data ? null : <p>No data found</p>}

            <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData && sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            />
            {/* Start of Modals */}

            <Modal show={showImageView} onHide={() => setShowImageView(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {
                        <object
                            data={`data:image/png;base64,${selectedImage}`}
                            // type="application/pdf"
                            type="image/jpeg"
                            width='100%'
                            height='100%'
                        >
                            <p>Unable to display Profile Picture!</p>
                        </object>
                    } */}
                   {selectedImage && (
                        <div>
                            <Image
                                src={selectedImage}
                                alt="Damage Picture"
                                style={{ width: "10rem", height: '12rem' }}
                            />
                            <Button style={{ marginLeft: '1rem', color: "blue" }} onClick={() => handleDownloadImages(selectedImage)}>
                                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                            </Button>
                        </div>
                    )}

                    {/* {<object type='image/jpeg' data={`data:image/png;base64,${selectedImage}`} alt="Buffer Image" />} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowImageView(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDocumentPopup1} onHide={handleCloseDocumentPopup1} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Bonafide doc</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedDocument && selectedDocument.length > 0 ? (


                        <ul>

                            {selectedDocument.map((document, index) => {
                                return <li key={index} style={{ margin: '5px' }}>

                                    <object
                                        //src={`data:${src.type};base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(src.data.data)))}`}
                                        data={`data:${document.type};base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(document.data.data)))}`}
                                        //data={require(`../../kidhood-images-and-docs${document}`)}
                                        //src={document}
                                        //data={`data:image/png;base64,${document.data.data}`}
                                        //type="application/pdf"
                                        type={`${document.type}`}
                                        width="200"
                                        height="200"
                                    >
                                        <p>Unable to display Image/PDF. Please download it instead.</p>
                                    </object>
                                    <Button
                                        onClick={() => {
                                            //downloadDocument(require(`../../kidhood-images-and-docs${document}`))
                                            downloadDocument(document);
                                            // downloadDocument(document)
                                        }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CloudDownload />}
                                        size="small"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download
                                    </Button>
                                </li>
                            })}
                        </ul>
                    ) : (
                        <p>No documents available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup1}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDocumentPopup2} onHide={handleCloseDocumentPopup2} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Transfer doc</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedDocument1 && selectedDocument1.length > 0 ? (
                        <ul>
                            {selectedDocument1.map((document, index) => {
                                return <li key={index} style={{ margin: '5px' }}>

                                    <object
                                        //src={`data:${src.type};base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(src.data.data)))}`}
                                        data={`data:${document.type};base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(document.data.data)))}`}
                                        //data={require(`../../kidhood-images-and-docs${document}`)}
                                        //src={document}
                                        //data={`data:image/png;base64,${document.data.data}`}
                                        //type="application/pdf"
                                        type={`${document.type}`}
                                        width="200"
                                        height="200"
                                    >
                                        <p>Unable to display Image/PDF. Please download it instead.</p>
                                    </object>
                                    <Button
                                        onClick={() => {
                                            //downloadDocument(require(`../../kidhood-images-and-docs${document}`))
                                            downloadDocument(document);
                                            // downloadDocument(document)
                                        }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CloudDownload />}
                                        size="small"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download
                                    </Button>
                                </li>
                            })}
                        </ul>
                    ) : (
                        <p>No documents available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup2}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDocumentPopup3} onHide={handleCloseDocumentPopup3} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View ReportCard document</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedDocument2 && selectedDocument2.length > 0 ? (
                        <ul>
                            {selectedDocument2.map((document, index) => {
                                return <li key={index} style={{ margin: '5px' }}>

                                    <object
                                        data={`data:${document.type};base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(document.data.data)))}`}
                                        type={`${document.type}`}
                                        width="200"
                                        height="200"
                                    >
                                        <p>Unable to display Image/PDF. Please download it instead.</p>
                                    </object>
                                    <Button
                                        onClick={() => {
                                            //downloadDocument(require(`../../kidhood-images-and-docs${document}`))
                                            downloadDocument(document);
                                            // downloadDocument(document)
                                        }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CloudDownload />}
                                        size="small"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download
                                    </Button>
                                </li>
                            })}
                        </ul>
                    ) : (
                        <p>No documents available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup3}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDocumentPopup} onHide={handleCloseDocumentPopup} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {documents &&  documents.map((imageURL, index) => (
                            <div key={index}>
                                <Image
                                    src={imageURL}
                                    alt={`Download for Document ${index + 1}`}
                                    style={{ width: '10rem', height: '12rem' }}
                                />
                                <Button style={{ marginLeft: '2rem', color: "blue" }} onClick={() => handleDownload(imageURL)}>
                                    <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
                                </Button>
                            </div>
                        ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showViewModal}
                onHide={() => setShowViewModal(false)}
                // className="modal"
                style={{ '--bs-modal-width': '1500px' }}
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title> 2nd parents</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover className="table-bordered yash-table" >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date Of Birth</th>
                                <th>relation</th>
                                <th>Gender</th>
                                <th>Email</th>
                                <th>Primary Number</th>
                                <th>Secondary Number</th>
                                <th>Profile Image</th>
                                <th>Documents</th>
                                <th>Address</th>
                                <th>Area</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Pincode</th>

                            </tr>
                        </thead>
                        <tbody>
                            {selectedRowGuardianRelations &&
                                selectedRowGuardianRelations.relations.map((item, index) => {

                                    const dob = new Date(item.dateOfBirth);
                                    // Format date as dd-mm-yyyy
                                    const formattedDob = `${dob.getDate().toString().padStart(2, '0')}-${(dob.getMonth() + 1).toString().padStart(2, '0')}-${dob.getFullYear()}`;
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{formattedDob}</td>
                                            <td>{item.relation}</td>
                                            <td>{item.gender}</td>
                                            <td>{item.email}</td>
                                            <td>{item.primaryContactNumber}</td>
                                            <td>{item.secondaryContactNumber}</td>
                                            <td>
                                                <IconButton
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => handleImageViewClick(item.profilePictureUrl)}
                                                >
                                                    <ImageView />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <IconButton
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => handleDocumentIconClick(item.documentUrls)}
                                                >
                                                    <UploadFileIcon />
                                                </IconButton>
                                            </td>
                                            <td>{item.currentAddress.address}</td>
                                            <td>{item.currentAddress.area.name}</td>
                                            <td>{item.currentAddress.city.name}</td>
                                            <td>{item.currentAddress.state.name}</td>
                                            <td>{item.currentAddress.pincode}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>

                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowViewModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {editedData ?
                            filteredColumns.map((col) => (

                                <Form.Group key={col.field} className="mb-3">
                                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                    {col.field === "profilePictureUrl" ? (
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;

                                                const imageURL = URL.createObjectURL(files[0]);
                                                setEditedData({ ...editedData, image: imageURL })
                                            }}
                                        />
                                    ) :
                                        col.field === "previousBonafideCertifiedUrl" ? (
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    // Handle multiple document upload logic here
                                                    const files = e.target.files;

                                                    const imageURL = URL.createObjectURL(files[0]);
                                                    setEditedData({ ...editedData, selectedDocument: imageURL })
                                                }}
                                            />
                                        ) : col.field === "documentUrls" ? (
                                            <input
                                                type="file"
                                                multiple
                                                onChange={(e) => {
                                                    // Handle multiple document upload logic here
                                                    const files = e.target.files;
                                                    let editDocuments = []
                                                    for (let i = 0; i < files.length; i++) {
                                                        const imageURL = URL.createObjectURL(files[i]);
                                                        editDocuments.push(imageURL)
                                                    }
                                                    setEditedData({ ...editedData, documents: editDocuments })
                                                }}
                                            />
                                        ) : col.field === 'qualificationId' ? (
                                            <SearchableDropDownField
                                                name='qualificationId'
                                                value={editedData[col.field]}
                                                placeholder='Select Qualification'
                                                options={qualificationsOptions}
                                                onChange={(selectedOption) => {
                                                    setEditedData({ ...editedData, qualificationId: selectedOption.value })
                                                }}
                                                required={true}
                                                feedbackMessage='Select Qualification'
                                            />
                                        )
                                            : col.field === 'designation' ? (
                                                <SearchableDropDownField
                                                    name='designation'
                                                    placeholder='Select Designation'
                                                    value={editedData[col.field]}
                                                    options={designationOptions}
                                                    onChange={(selectedOption) => {
                                                        setEditedData({ ...editedData, designation: selectedOption.value })
                                                    }}
                                                    required={true}
                                                    feedbackMessage='Select Designation'
                                                />
                                            )
                                                : col.field === "Pcity" ? (
                                                    <SearchableDropDownField
                                                        name='Pcity'
                                                        placeholder='Permanent City'
                                                        value={editedData[col.field]}
                                                        options={citiesOptions}
                                                        onChange={(selectedOption) => {
                                                            setEditedData({ ...editedData, Pcity: selectedOption.value })
                                                        }}
                                                        required={true}
                                                        feedbackMessage='Permanent City is Required!'
                                                    />
                                                ) : col.field === "Pstate" ?
                                                    (
                                                        <SearchableDropDownField
                                                            name='Pstate'
                                                            value={editedData[col.field]}
                                                            placeholder='Permanenet State'
                                                            options={statesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Pstate: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Permanent State is Required!'

                                                        />
                                                    ) : col.field === "Ccity" ? (
                                                        <SearchableDropDownField
                                                            name='Ccity'
                                                            placeholder='Current City'
                                                            value={editedData[col.field]}
                                                            options={citiesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Ccity: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Permanent City is Required!'

                                                        />
                                                    ) : col.field === "Cstate" ? (
                                                        <SearchableDropDownField
                                                            name='Cstate'
                                                            value={editedData[col.field]}
                                                            placeholder='Current State'
                                                            options={statesOptions}
                                                            onChange={(selectedOption) => {
                                                                setEditedData({ ...editedData, Cstate: selectedOption.value })
                                                            }}
                                                            required={true}
                                                            feedbackMessage='Current State is Required!'

                                                        />
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            value={editedData[col.field]}
                                                            onChange={(e) => {
                                                                setEditedData({
                                                                    ...editedData,
                                                                    [col.field]: e.target.value,
                                                                })
                                                            }
                                                            }
                                                        />
                                                    )}
                                </Form.Group>
                            )) : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowEditModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                        style={{
                            color: "white",
                            backgroundColor: "#007bff",
                            fontSize: "12px",
                            borderRadius: "20px",
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ParentViewTable;

