import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

export const url = 'http://localhost:5000';


export const franchiseId = ()=>{
    const id = sessionStorage.getItem('franchiseeSchoolId')
    return id;
}

const tokenId = token()
export const getRequestOptions = {

    method: 'GET',
    headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
    }

}
export const postRequestOptions = (payload) => {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${tokenId}`
        },
        body: JSON.stringify(payload)
    }
    return requestOptions;
}
export const putRequestOptions = (payload) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${tokenId}`
        },
        body: JSON.stringify(payload)
    }
    return requestOptions;
}

export const getEmployeeList = createAsyncThunk('getEmployeeList', async (_, thunkAPI) => {
    const tokenId = token();
    const id = franchiseId();
    try {
        const response = await fetch(`${API_PATH}/employees?isActive=true&franchiseeSchoolId=${+id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            }
        });
        const data = await response.json();
        if(!response.ok){
            return thunkAPI.rejectWithValue(data)
        }
        return data;

    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const postEmployeeData = createAsyncThunk('postEmployee', async (payload, thunkAPI) => {
    const tokenId = token()
    const id = franchiseId();
    try {
        const postUrl = await fetch(`${API_PATH}/franchiseeSchool/${+id}/employee`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            },
            body: JSON.stringify(payload)
        });
        const response = await postUrl.json();
        return response;
    } catch (error) {
        console.error(error);
        return thunkAPI.rejectWithValue(error.message);
    }
})
export const getEmployeeQualifications = createAsyncThunk('getQualifications', async (_, thunkAPI) => {
    const tokenId = token()
    try {
        const fetchEmployees = await fetch(`${API_PATH}/Qualifications`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            }

        });
        const fetchedData = await fetchEmployees.json();
        return fetchedData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

export const getEmployeeStates = createAsyncThunk('getStates', async (_, thunkAPI) => {
    const tokenId = token()
    try {
        const fetchingData = await fetch(`${API_PATH}/states`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            }
        });
        const data = await fetchingData.json();
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})
export const getEmployeeCities = createAsyncThunk('getCities', async (_, thunkAPI) => {
    const tokenId = token()
    try {
        const fetchingData = await fetch(`${API_PATH}/cities`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            }
        });
        const data = await fetchingData.json();
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})
export const getEmployeeAreas = createAsyncThunk('getAreas', async (_, thunkAPI) => {
    const tokenId = token()
    try {
        const fetchingData = await fetch(`${API_PATH}/areas`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            }
        });
        const data = await fetchingData.json();
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

export const putEmployeeData = createAsyncThunk('put/employee', async (payload, thunkAPI) => {
    const tokenId = token()

    try {
        const fetchData = await fetch(`${API_PATH}/employee/${payload.id}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${tokenId}`
            },
            body: JSON.stringify(payload.payload)
        });
        const data = await fetchData.json();
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }

})