import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import "../table/table.css";


const TableView1 = ({
  data,
  columns,
  editedData,
  setEditedData,
  handleUpdate,
  showEditModal,
  setShowEditModal,
  checkboxState,
  setCheckboxState,
  selectAllPresent, // Pass the prop here
  renderGradeDropdown,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [searchQuery, setSearchQuery] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(
    columns && columns[0] ? columns[0].field : ""
  );
  // const [checkboxState, setCheckboxState] = useState({});

  const getCaps = (head, field) =>
    head ? head.toUpperCase() : field.toUpperCase();

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setShowViewModal(true);
  };

  const handleSortClick = (column) => {
    if (column === "edit") {
      return;
    }

    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const handleEditClick = (row) => {
    setShowEditModal(true);
    setEditedData({ ...row });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (rowId, newValue) => {
    const updatedCheckboxState = [...checkboxState];

    const studentIndex = updatedCheckboxState.findIndex(
      (item) => item.studentId === rowId
    );

    if (studentIndex !== -1) {
      updatedCheckboxState[studentIndex].status = newValue;
    } else {
      updatedCheckboxState.push({ studentId: rowId, status: newValue });
    }

    setCheckboxState(updatedCheckboxState);

  };

  const renderPresent = (row) => (
    <Checkbox
      checked={
        selectAllPresent
          ? true
          : checkboxState.some(
            (item) => item.studentId === row.studentId && item.status === true
          )
      }
      onChange={(e) => {
        handleCheckboxChange(row.studentId, e.target.checked ? true : false);
      }}
    />
  );

  const renderAbsent = (row) => (
    <Checkbox
      checked={checkboxState.some(
        (item) => item.studentId === row.studentId && item.status === false
      )}
      onChange={(e) =>
        handleCheckboxChange(row.studentId, e.target.checked ? false : true)
      }
    />
  );

  const filteredData = data.filter((row) => {
    const searchValue = searchQuery.toLowerCase();

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const columnValue = row[column.field];

      if (typeof columnValue === "string") {
        if (columnValue.toLowerCase().includes(searchValue)) {
          return true;
        }
      } else if (typeof columnValue === "number") {
        if (columnValue.toString().includes(searchValue)) {
          return true;
        }
      }
    }

    return false;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (typeof valueA === "string" && typeof valueB === "string") {
      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    } else {
      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueB < valueA ? -1 : 1;
      }
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const filteredColumns = columns.filter(
    (col) => !["id", "view", "edit", "delete"].includes(col.field)
  );

  const handleEditSave = () => {
    if (editedData.id) {
      handleUpdate(editedData.id, editedData);
      setShowEditModal(false);
    }
  };

  return (
    <div>

      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((head) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#E2E8F0",
                      color: "#464E5F",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                    }}
                    key={head.field}
                    // className="tablecell4"
                  >
                    <TableSortLabel
                      active={sortColumn === head.field}
                      direction={sortOrder}
                      onClick={() => handleSortClick(head.field)}
                    >
                      {(head.field, head.header)}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((row, rowIndex) => (
              <TableRow key={row.id}>
                {columns.map((col) => (
                  <TableCell key={col.field} >
                    {col.field === "view" ? (
                      <IconButton
                        onClick={() => handleViewClick(row)}
                        color="primary"
                        size="small"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ) : col.field === "edit" ? (
                      <IconButton
                        onClick={() => handleEditClick(row)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    ) : col.field === "present" ? (
                      renderPresent(row)
                    ) : col.field === "absent" ? (
                      renderAbsent(row)
                    ) : col.field === "grade" ? (
                      renderGradeDropdown(row)
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data ? null : <p>No data found</p>}

      {/* <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            /> */}
      {/* </Box> */}

      <Modal
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {selectedRow &&
              filteredColumns.map((col) => (
                <Form.Group key={col.field} className="mb-3">
                  <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedRow[col.field]}
                    readOnly
                  />
                </Form.Group>
              ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowViewModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData &&
              filteredColumns.map((col) => (
                <Form.Group key={col.field} className="mb-3">
                  {col.field === "Srno" ? null : (
                    <>
                      <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        onChange={(e) =>
                          setEditedData((prevData) => ({
                            ...prevData,
                            [col.field]: e.target.value,
                          }))
                        }
                      />
                    </>
                  )}
                </Form.Group>
              ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{
              color: "white",
              backgroundColor: "#1976D2",
              fontSize: "12px",
              borderRadius: "20px",
            }}
            onClick={handleEditSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TableView1;
