/*
    authors: Mamatha
    date: 14-11-2023
    version: 1.0
*/

import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './FoodUpdates.css'; // Import your custom CSS file
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import Table from "../reUsableForm/table/FoodUpdatesTable";
import ReadOnlyDateInputField from '../../ReusableFields/DateFields/ReadOnlyDateField';
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField';
import { useDispatch, useSelector } from "react-redux";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import SelectDropdown from '../SelectDropdown';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import { fetchFoodItems } from './slice/getFoodItems';
import { RememberMe } from '@mui/icons-material';
import { PostFoodUpdates, setErrorMessage, setShowErrorMessage, setShowSuccessMessage } from './slice/foodUpdateSlice';
import { responseFormat } from '../Auth/errorResponse';
import { responseFormatField } from '../Auth/filedErrorMsg';
// import { postAttendanceRequest } from "./addAttendanceSlicer";

const FoodUpdates = () => {

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [checkboxState, setCheckboxState] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0])
  const [formErrors, setFormErrors] = useState({
    classId: false, sectionId: false
  });
  const [dropDowns, setDropDowns] = useState({});
  const [selectedClassId, setSelectedClassId] = useState('')
  const [section, setSection] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);


  const mealsOptions = [
    { id: 1, value: "BreakFast" }, { id: 2, value: "Mid Meal" }, { id: 3, value: "Lunch" }, { id: 4, value: "Snacks" },
  ]
  const dispatch = useDispatch();
  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "rollNumber", header: "Roll Number" },
    { field: "studentName", header: "Student Name" },
    { field: "foodTime", header: "Time" },
    { field: "mealType", header: "Meal Type" },
    { field: "foodItems", header: "Food Items" },
    { field: "quantity", header: "Quantity" },
    { field: "test", header: "Remarks" },
  ];
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
  const FranchiseeSchoolId = Number(franchiseeSchoolId);


  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
  const apiData = useSelector((state) => state.viewStudent.data) || [];


  const showSuccessMsg = useSelector((state) => state.FoodUpdates.showSuccessMessage);
  const successMsg = useSelector((state) => state.FoodUpdates.successMessage);

  const showErrorMsg = useSelector((state) => state.FoodUpdates.showErrorMessage);
  const errorMsg = useSelector((state) => state.FoodUpdates.errorMessage);



  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [dispatch, classesClassSectionData]);

  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }


  }, [dispatch, apiData]);


  useEffect(() => {
    if (classesClassSectionData.length === 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [dispatch, classesClassSectionData]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };


  const handleClassChange = () => {
    setSelectedSection("");
    setSection("");
    handleChange("sectionId", "");

    const classSectionData = classesClassSectionData.find(
      (c) => c.id === selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
  }, [selectedClass]);

  const handleSearchClick = () => {

    const newFormErrors = {
      classId: !formData.classId,
      sectionId: !formData.sectionId,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    setValidated(true);

    if (hasErrors) {
      return;
    }

    const filteredStudents = apiData.filter((student) => {
      const enrollments = student?.studentEnrollments?.[0].classesClassSection?.class?.id === selectedClass;
      return enrollments;
    });

    const filterSectionWiseData = filteredStudents && filteredStudents.filter((students) => {
      const newData = students.studentEnrollments?.[0].classesClassSection?.classSection?.id === formData.sectionId;
      return newData
    })

    setData(filterSectionWiseData);
    setShowTable(true);
  };

  let newModifiedData = data.map((item, index) => {
    return {
      id: item.id,
      index: index + 1,
      rollNumber: item.studentEnrollments?.[0].studentRollNumber,
      studentName: item.name,
      studentId: item.studentEnrollments?.[0].studentId,

    }
  })
  const [selectFoodItems, setSelectFoodItems] = useState({})
  const [mealsSection, setMealsSection] = useState({});
  const [toTime, setToTime] = useState({});
  const [remarks, setRemarks] = useState({});
  const [quantity, setQuantity] = useState({});

  const handleFoodItems = (rowId, event) => {
    setSelectFoodItems((food) => ({
      ...food, [`${rowId}`]: event.target.value
    }))
  }

  const handleMealsType = (rowId, event) => {
    setMealsSection((mealsSection) => ({
      ...mealsSection, [`${rowId}`]: event.target.value
    }))
  }


  const onChangeToTime = (rowId, event) => {
    setToTime((prevData) => ({
      ...prevData, [rowId]: event.target.value
    }))

  };

  const renderRemarksInput = (rowId, e) => {
    setRemarks((prevData) => ({
      ...prevData, [rowId]: e.target.value
    }))
  }

  const renderQuantity = (rowId, e) => {
    setQuantity((prevData) => ({
      ...prevData, [rowId]: e.target.value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const errorMessages = [];

    const newData = newModifiedData.map(item => {
      const foodItemValue = selectFoodItems[item.id] || null;
      const mealTypeValue = mealsSection[item.id] || '';
      const time = toTime[item.id] || '';
      const remark = remarks[item.id] || '';
      const qua = quantity[item.id] || '';

      const updatedItem = { ...item, foodItemId: foodItemValue, mealType: mealTypeValue, time, remark, quantity: qua };

      // Check for missing keys
      const missingKeys = Object.keys(updatedItem).filter(key => updatedItem[key] === '');
      if (missingKeys.length > 0) {
        errorMessages.push({ errors: [{ "message": `Missing key(s) [${missingKeys.join(', ')}]. Please fill in the data.` }] });
      }

      return updatedItem;
    });

    if (errorMessages.length > 0) {
      const errorMsg = errorMessages[0]
      const errMessage = responseFormatField({ error: JSON.stringify(errorMsg) });
      dispatch(setErrorMessage(errMessage));
      dispatch(setShowErrorMessage(true));
    } else {
      const sendData = {
        "franchiseeSchoolId": FranchiseeSchoolId,
        "date": currentDate,
        "classesClassSectionId": selectedSection,
        "foodsUpdate": newData
      };

      if (newData.length > 0) {
        dispatch(PostFoodUpdates(sendData));
      }
    }
  };


  const handleCloseErrorPopup = () => { dispatch(setShowErrorMessage(false)); };
  const handleCloseSuccessPopup = (e) => { e.preventDefault(); dispatch(setShowSuccessMessage(false)); handleCancel(); };

  const handleCancel = () => {
    setFormData({});
    setDropDowns({
      class: '',
      sections: ''
    })
    setData([])
    newModifiedData = []
    setSelectFoodItems({});
    setMealsSection({});
    setToTime({});
    setRemarks({});
    setQuantity({});
  }


  return (
    <>
      <div className="content-header">Food Updates</div>

      <Row style={{justifyContent:"center"}}>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">

          <ReadOnlyDateInputField
            name="Date Of Birth"
            required
            value={currentDate}
            validated={validated}
          />
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">

          <SelectDropdown
            label="Class"
            // value={formData.classId || ""}
            value={
              dropDowns.class
                ? { value: dropDowns.class, label: dropDowns.class }
                : dropDowns.class
            }
            placeholder="select class"
            onChange={(selectedOption) => {
              handleChange("classId", selectedOption.value);
              setSelectedClass(selectedOption.value);
              setDropDowns({ ...dropDowns, class: selectedOption.label });
              setFormErrors({ ...formErrors, classId: false });
            }}
            options={classesClassSectionData.map((classItem) => ({
              value: classItem.id,
              label: classItem.name,
              // classesClassSectionDataId:classItem.classSections
            }))}
            required
            feedbackMessage="Please select a valid class"
          // placeholder="Select class"
          />
          {formErrors.classId && (
            <div className="error-message">Please Select class.</div>
          )}
        </Col>

        {formData.classId && selectedClassData && selectedClassData.classSections.length > 0 && (

          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">

            <SelectDropdown
              label="Section"
              // value={formData.sectionId || ""}
              value={
                dropDowns.sections
                  ? { value: dropDowns.sections, label: dropDowns.sections }
                  : dropDowns.sections
              }
              placeholder="Select section"
              onChange={(selectedOption) => {
                handleChange("sectionId", selectedOption.key);
                setSelectedSection(selectedOption.value);
                setDropDowns({ ...dropDowns, sections: selectedOption.label });
                setFormErrors({ ...formErrors, sectionId: false });
              }}
              options={selectedClassData.classSections.map((section) => ({
                key: section.id,
                value: section.classesclassSection,
                label: section.name,
              }))}
              required
              feedbackMessage="Please select a valid section"
            />
            {formErrors.sectionId && (
              <div className="error-message">Please Select Section.</div>
            )}
          </Col>
        )}

        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <div 
         style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1%",
        }}>

          <button
            className="clear-button-figma-div" // Apply appropriate styling
            onClick={handleSearchClick} // Attach the click event handler
          >
            Search
          </button>
          </div>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>


        <Row>
          {data === null ? (
            <center>
              <p>Loading...</p>
            </center>
          ) : data.length === 0 ? (
            <center style={{ color: "blue", margin: "1rem" }}>
              <p>No records available</p>
            </center>
          ) : (
            <Table
              data={newModifiedData}
              columns={columns}
              selectFoodItems={selectFoodItems}
              handleFoodItems={handleFoodItems}
              mealsOptions={mealsOptions}
              mealsSection={mealsSection}
              toTime={toTime}
              handleMealsType={handleMealsType}
              onChangeToTime={onChangeToTime}
              foodRemarks={remarks}
              renderRemarksInput={renderRemarksInput}
              quantity={quantity}
              renderQuantity={renderQuantity}
              hover={true} checkboxState={checkboxState} setCheckboxState={setCheckboxState} />
          )}
        </Row>




        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      {/* ... Displaying school kids in cards */}

      {/* </Container> */}
    </>
  );
};

export default FoodUpdates;