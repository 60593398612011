/*
    authors: Rajeev
    date: 04-11-2023
    update:04-11-2023 
    version: 1.0
*/

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../Api/api";
import { token } from '../../Api/token';


const studentAttendanceData = [
    { id: 1, date: "2023-10-01", present1: "Yes", absent1: "No" },
    { id: 2, date: "2023-10-02", present1: "Yes", absent1: "No" },
    { id: 3, date: "2023-10-03", present1: "No", absent1: "Yes" },
    { id: 4, date: "2023-10-04", present1: "Yes", absent1: "No" },
    { id: 5, date: "2023-10-05", present1: "Yes", absent1: "No" },
    { id: 6, date: "2023-10-06", present1: "Yes", absent1: "No" },
];


const slice = createSlice(
    {
        name: "viewStudentAttendance",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchStudentAttendanceDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchStudentAttendanceDataSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchStudentAttendanceDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            }
        },
    },

);

export const { fetchStudentAttendanceDataStart, fetchStudentAttendanceDataSuccess, fetchStudentAttendanceDataFailure } = slice.actions;

export const fetchStudentAttendanceDataOnce = () => async (dispatch, getState) => {

    const { data, isLoading } = getState().viewStudentAttendance;



    if (data === null && !isLoading) {

        dispatch(fetchStudentAttendanceDataStart());

        dispatch(fetchStudentAttendanceDataSuccess(studentAttendanceData));
    }

}

export default slice.reducer;
