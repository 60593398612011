import React, { useState } from 'react'
import ViewAcademicDetails from './ViewAcademicDetails'
import ChildrenProfile from './ChildrenProfile';

const ChildrenData = (props) => {
  const {eachChildData} = props;
    const [view,setView] = useState(false);
  try{
    return (
      <div className='childrenDataWrapper'>
      <ChildrenProfile eachChildData={eachChildData}/>
      <div className='viewButtonDetails'>
      <button onClick={()=>setView(!view)} >{!view?"View Academic Details":"Hide Details!"}</button>
      </div>
      {
              view === true?<ViewAcademicDetails eachChildData={eachChildData}/>:null
      }
      </div>
    )
  }catch(err){
    return <>Error occured!</>
  }
}

export default ChildrenData