import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchDailyUpdates } from "./slice/getPcturesUploads";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";
import { Button, Col, Empty, Form, Image, Row, Space, Spin } from "antd";
import SearchableDropDown from "../../ReusableFields/DropDown/searchableDroDown";
import "./GalleryView.css";
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";

const MediaRenderer = ({ src, onDownload, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, previewVisible }) => {
    const isVideo = /\.(mp4|mov|ogg|webm)$/.test(src);

    return (
        <div className="gallery-item">
            {isVideo ? (
                <video
                    width="100%"
                    height="100%"
                    controls
                    src={src}
                >
                    Your browser does not support the video tag.
                </video>
            ) : (
                <Image
                    width="100%"
                    height="100%"
                    src={src}
                    preview={false} // Disable default Ant Design image preview
                />
            )}
            {!previewVisible && !isVideo && (
                <div className="overlay">
                    <Image
                        width="100%"
                        height="100%"
                        src={src}
                        preview={{
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                },
                            ) => (
                                <Space size={12} className="toolbar-wrapper">
                                    <DownloadOutlined onClick={() => onDownload(src)} style={{ fontSize: "25px" }} />
                                    <SwapOutlined rotate={90} onClick={onFlipY} style={{ fontSize: "25px" }} />
                                    <SwapOutlined onClick={onFlipX} style={{ fontSize: "25px" }} />
                                    <RotateLeftOutlined onClick={onRotateLeft} style={{ fontSize: "25px" }} />
                                    <RotateRightOutlined onClick={onRotateRight} style={{ fontSize: "25px" }} />
                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} style={{ fontSize: "25px" }} />
                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} style={{ fontSize: "25px" }} />
                                </Space>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export const ViewGallery = () => {
    const [formData, setFormData] = useState({ studentId: "", studentName: "", pictureType: "" });
    const [imageData, setImageData] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const dispatch = useDispatch();
    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
    const data = parentLoginObject?.data;

    useEffect(() => {
        if (parentLoginObject?.loading === "idle") {
            const parentId = sessionStorage.getItem('userId');
            dispatch(getParentLoginData(parentId));
        }
    }, [dispatch, parentLoginObject?.loading]);

    const apiData = useSelector((state) => state.dailyUpdates.data) || [];

    useEffect(() => {
        const fetchImages = async () => {
            if (!formData.studentId || !formData.pictureType) return;
            setLoading(true);
            const images = [];
            apiData.forEach(item => {
                if (item.studentId === formData.studentId) {
                    images.push(...item.pictures);
                }
            });
            setImageData(images);
            setLoading(false);
        };

        fetchImages();
    }, [formData.studentId, formData.pictureType, apiData]);

    const onDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        const parts = url.split('/');
        const filename = parts[parts.length - 1];
        link.download = filename;
        link.click();
    };

    return (
        <>
            <div className="content-header">Pictures</div>
            {data?.childrens?.length > 0 ? (
                <Form noValidate validated={validated}>
                    <Row>
                        <Space>
                            <Col>
                                <SearchableDropDown
                                    value={formData.studentName || ''}
                                    className="input-style"
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, "studentId": Number(selectedOption.id), "studentName": selectedOption.value });
                                    }}
                                    placeholder="Select child"
                                    options={data?.childrens?.map((option) => ({
                                        id: option.id,
                                        value: option.name,
                                        label: option.name,
                                    }))}
                                    feedbackMessage="Please select Fee Head."
                                    validated={validated}
                                />
                            </Col>
                            <Col>
                                <SearchableDropDown
                                    value={formData.pictureType || ''}
                                    className="input-style"
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, "pictureType": selectedOption.value });
                                        dispatch(FetchDailyUpdates(formData.studentId, selectedOption.id));
                                    }}
                                    placeholder="Select view type"
                                    options={[
                                        { id: "Individual", value: "Individual", label: "Individual" },
                                        { id: "Group", value: "Group", label: "Group" },
                                    ]}
                                    feedbackMessage="Please select View Type."
                                    validated={validated}

                                />
                            </Col>
                        </Space>
                    </Row>
                </Form>
            ) : null}

            <div className="gallery-container">
                {loading && formData.studentId ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    Array.isArray(imageData) && imageData.length > 0 ? (
                        imageData.map((src, index) => (
                            <MediaRenderer key={index} src={src} onDownload={onDownload} />
                        ))
                    ) : (
                        <Empty description="No images available" />
                    )
                )}
            </div>
        </>
    );
};
