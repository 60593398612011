import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice(
  {
    name: 'viewRoyaltyAmount',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchRoyaltyAmountData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchRoyaltyAmountSuccess: (state, action) => {
        state.isLoading = true;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchRoyaltyAmountFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      },
      setRoyaltyAmountData: (state, action) => {
        state.isLoading = false;
        const newData = action.payload;

        const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

        if (existingObjectIndex !== -1) {
          state.data[existingObjectIndex] = newData;
        }

      },
    },
  },
);
export const { fetchRoyaltyAmountData, fetchRoyaltyAmountSuccess, fetchRoyaltyAmountFailure, setRoyaltyAmountData } = slice.actions;

export const ViewRoyaltyAmountDetails = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewRoyaltyAmount;
  const tokenId = token();
  if (data === null && !isLoading) {
    dispatch(fetchRoyaltyAmountData());

    try {
      const response = await fetch(API_PATH + '/royalities', {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchRoyaltyAmountSuccess(data1));
      dispatch(setRoyaltyAmountData(data1))
    } catch (error) {
      dispatch(fetchRoyaltyAmountFailure());
    }
  }
}
export default slice.reducer;
