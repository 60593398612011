/*
    authors: Mamatha
    date: 22-11-2023
    update:22-11-2023 
    version: 1.0
*/
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import "./DatePicker.css"

const DatePickerFuture = ({ label, value, onChange, placeholder }) => {

    // const currentDate = new Date().toISOString().split('T')[0];
    const currentDate = new Date(); // Get the current date
    // currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
  
  return (
    <Form.Group controlId="dob">
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        dateFormat="dd-MM-yyyy"
        className="DatePicker_field"
        minDate={currentDate}
      />
    </Form.Group>
  );
};

export default DatePickerFuture;