import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FitnessAnalysisView } from './slice/fitnessAnalysisSlice';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import SelectDropdown from '../SelectDropdown';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getParentLoginData } from '../parent/parentLoginSlicer/parentLoginRequests';

function FitnessAnalysis() {
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.fitnessAnalysisView.data) || [];
  const isLoading = useSelector((state) => state.fitnessAnalysisView.isLoading);
  const hasError = useSelector((state) => state.fitnessAnalysisView.hasError);
  const [selectedParameter, setSelectedParameter] = useState('height');
  const [formData, setFormData] = useState({
    studentId: "",
  });

  const getStudentData = useSelector((state) => state.viewStudent.data) || [];
  const studentData = [...getStudentData];

  const roleId = sessionStorage.getItem("roleId");
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const data = parentLoginObject?.data;

  useEffect(() => {
    if (parentLoginObject?.loading === "idle") {
      const parentId = sessionStorage.getItem('userId');
      if (roleId === "5") {
        dispatch(getParentLoginData(parentId));
      }
    }
  }, [dispatch, parentLoginObject?.loading]);

  useEffect(() => {
    if (roleId !== "5") {
      dispatch(fetchStudentDataOnce());
    }
  }, [dispatch]);

  useEffect(() => {
    if (formData.studentId) {
      dispatch(FitnessAnalysisView(formData.franchiseeSchoolId || franchiseeSchoolId, formData.studentId));
    }
  }, [formData.studentId]);

  const handleParameterChange = (parameter) => {
    setSelectedParameter(parameter);
  };

  // Define colors for each data key
  const getBarColor = (dataKey) => {
    if (dataKey === 'height') {
      return '#79f299'; // Color for height
    } else if (dataKey === 'weight') {
      return '#006AB0'; // Color for weight
    } else if (dataKey === 'runningIn1mint') {
      return '#f291d7'; // Color for running
    } else {
      return '#000'; // Default color
    }
  };

  const getBarWidth = () => {
    const numBars = selectedParameter === 'runningIn1mint' ? 1 : 2;
    const width = 100 / (numBars * (apiData.length + 1)); // Adjust width based on the number of bars and data length
    return 20;
  };

  const capitalizeFirstLetter = (tick) => {
    if (!tick) return '';
    return tick.charAt(0).toUpperCase() + tick.slice(1);
  };
  const capitalizeText = (text) => {
    if (typeof text !== 'string') return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

const formatLabel = (label) => {
  if (label == null || typeof label !== 'string') {
    return label;
  }

  // Handle specific cases
  if (label.toLowerCase() === "runningin1mint") {
    return "Running In 1 Minute";
  }

  // Default formatting for other labels
  const formattedLabel = label.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).trim();
  return formattedLabel;
};

  // Custom Tooltip component
  const CustomTooltip = ({ payload, label }) => {
    if (!payload || !payload.length) return null;
    return (
      <div className="custom-tooltip">
        <p style={{ fontWeight: "bolder" }} className="label">{capitalizeFirstLetter(label || '')}</p>
        {payload.map((item, index) => (
          <p key={index} style={{ color: item.stroke, fontWeight: "bolder" }}>
            {formatLabel(item.name || '')}: {item.value}
          </p>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Row>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          {roleId === "5" ? (
            <SelectDropdown
              isSearchable
              required
              value={formData.studentId ? { label: formData.studentName } : null}
              options={data?.childrens?.map((option) => ({
                id: option.id,
                value: option.studentEnrollments[0].studentRollNumber,
                label: option.name,
                franchiseeSchoolId: option.studentEnrollments[0].franchiseeSchoolId
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  studentId: Number(selectedOption.id),
                  studentName: selectedOption.label,
                  franchiseeSchoolId: selectedOption.franchiseeSchoolId
                });
              }}
              placeholder="Child Name"
              feedbackMessage="Please select child name."
            />
          ) : (
            <SelectDropdown
              isSearchable
              required
              value={formData.studentId ? { label: formData.studentName } : null}
              options={studentData.map((option) => ({
                id: option.id,
                value: option.studentEnrollments[0].studentRollNumber,
                label: option.name,
                classId: option.studentEnrollments[0].classesClassSection.classId
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  studentId: Number(selectedOption.id),
                  studentName: selectedOption.label,
                  classId: selectedOption.classId
                });
              }}
              placeholder="Child Name"
              feedbackMessage="Please select child name."
            />
          )}
        </Col>
        <Col sm={6} xs={12} md={6} lg={9} className="rowCss">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '100px' }}>
            <button className='save-button-figma-div' style={{ marginRight: '10px', height: "40px", backgroundColor: '#006AB0', color: 'white', width: "18%" }} onClick={() => handleParameterChange('height')}>Height&nbsp;&&nbsp;Weight</button>
            {/* <button className='save-button-figma-div' style={{ marginRight: '10px', backgroundColor: '#79f299', border: "#79f299", color: 'white' }} onClick={() => handleParameterChange('weight')}>Weight</button> */}
            <button className='save-button-figma-div' style={{ backgroundColor: '#f291d7', border: "#f291d7", color: 'white' }} onClick={() => handleParameterChange('runningIn1mint')}>Running</button>
          </div>
        </Col>
      </Row>
      <div style={{ height: '400px' }}>
        <ResponsiveContainer width="60%" height={350}>
          <BarChart data={apiData}>
            <XAxis tickFormatter={capitalizeFirstLetter} dataKey="month" tick={{ fontSize: 14, fontFamily: 'Montserrat', fill: '#000' }} interval={0} angle={-45} textAnchor="end" />
            <YAxis label={{
              value: selectedParameter === 'height' ? "Height In Meters and Weight In Kg's" : selectedParameter === 'weight' ? "Weight In Kg's" : "Running In 1 Minute",
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle', fontSize: 14, fontFamily: 'Montserrat', fill: '#000' }
            }} tick={{ fontSize: 14, fontFamily: 'Montserrat', fill: '#000' }} />
            <Tooltip content={<CustomTooltip />} contentStyle={{ fontSize: 14, fontFamily: 'Montserrat', color: '#000', fontWeight:"bolder"  }} itemStyle={{ color: '#000', fontWeight:"bolder" }} />
            <Legend wrapperStyle={{ fontSize: '14px', fontFamily: 'Montserrat', color: '#000' }} formatter={formatLabel} />

            {/* Render grouped bars for height and weight */}
            {selectedParameter !== 'runningIn1mint' && (
              <>
                <Bar dataKey="height" fill={getBarColor('height')} radius={[10, 10, 0, 0]} barSize={getBarWidth()} />
                <Bar dataKey="weight" fill={getBarColor('weight')} radius={[10, 10, 0, 0]} barSize={getBarWidth()} />
              </>
            )}
            {/* Render runningIn1mint as a single bar if selected */}
            {selectedParameter === 'runningIn1mint' && (
              <Bar dataKey="runningIn1mint" fill={getBarColor('runningIn1mint')} radius={[10, 10, 0, 0]} barSize={getBarWidth()} />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {isLoading && <div>Loading...</div>}
      {hasError && <div>Error loading data</div>}
    </div>
  );
}

export default FitnessAnalysis;
