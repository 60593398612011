import React, { useEffect } from 'react';
import Table from '../reUsableForm/table/table';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchFranchiseFollowUpView } from '../Franchise/franchiseSlice/franchiseFollowUpUpdate';
import { FranchiseGetView } from '../Franchise/franchiseSlice/franchiseViewSlicer';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { setShowErrorMessageFranchise, setShowSuccessMessageFranchise } from './franchiseSlice/franchiseFollowUpUpdate';

const FranchiseFollowUp = () => {
  const data = useSelector((state) => state.franchiseView.data) || [];
  const postData = useSelector((state) => state.franchiseEnquirySlicer.data) || [];
  const putData = useSelector((state) => state.franchiseFollowUp.data) || [];

  const showSuccessMsgFranchise = useSelector((state) => state.franchiseFollowUp.showSuccessMessageFranchise);
  const successMsgFranchise = useSelector((state) => state.franchiseFollowUp.successMessageFranchise);

  const showErrorMsgFranchise = useSelector((state) => state.franchiseFollowUp.showErrorMessageFranchise);
  const errorMsgFranchise = useSelector((state) => state.franchiseFollowUp.errorMessageFranchise);

  const handleCloseErrorPopupFranchise = () => {
    dispatch(setShowErrorMessageFranchise(false));
  };

  const dispatch = useDispatch();

  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "date", header: "Date" },
    { field: "name", header: "Name" },
    { field: "contactNumber", header: "Contact Number" },
    { field: "reference", header: "Reference" },
    { field: "statusDropdown", header: "Status" },
    { field: "franchiseComments", header: "Comments" },
    { field: "franchiseSave", header: "Save" },
  ];

  const combinedData = [...(data || []), ...postData];

  const newModifiedData = combinedData.map((postItem, index) => {
    const formattedDate = new Date(postItem.date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });

    return {
      index: index + 1,
      id: postItem.id,
      date: formattedDate,
      name: postItem.name,
      occupation: postItem.occupation,
      address: postItem.address,
      cityId: postItem.cityId,
      contactNumber: postItem.contactNumber,
      email: postItem.email,
      areaId: postItem.areaId,
      investmentCapacity: postItem.investmentCapacity,
      programId: postItem.programId,
      reference: postItem.reference,
      status: postItem.status,
      comments: postItem.comments,
    };
  });

  let newUpdatedData
  newUpdatedData = newModifiedData.filter(obj => !putData.some(postObj => postObj.id === obj.id));

  const statusOptions = [
    { value: "interested", label: "Interested" },
    { value: "notInterested", label: "Not Interested" },
  ];

  const handleCloseSuccessPopupFranchise = () => {
    dispatch(setShowSuccessMessageFranchise(false));
  };

  useEffect(() => {
    dispatch(FranchiseGetView());
  }, [dispatch]);

  return (
    <div>
      <h4 className='enquiryView'>Franchise Follow Up</h4>
      {newModifiedData === null ? (
        <center><p>Loading...</p></center>
      ) : newModifiedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) : (
        <Table data={newModifiedData} columns={columns} hover={true} statusOptions={statusOptions} />
      )}
      <SuccessMessagePopup show={showSuccessMsgFranchise} onClose={handleCloseSuccessPopupFranchise} successMessage={successMsgFranchise} />
      <ErrorMessagePopup show={showErrorMsgFranchise} onClose={handleCloseErrorPopupFranchise} errorMessage={errorMsgFranchise} />
    </div>
  );
}
export default FranchiseFollowUp;
