import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StudentReportTable = ({ columns, data, handleVisibilityIconClick }) => {
  return (
    <TableContainer className="table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell
                sx={{
                  backgroundColor: "#E2E8F0",
                  color: "#464E5F",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                key={col.field}
                className="tablehead"
              >
                {col.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              {columns.map((col) => (
                <TableCell key={col.id} className="table-data-cell">
                  {col.field === "subjectsAssessments" ||
                  col.field === "skillsAssessments" ||
                  col.field === "activitiesAssessments" ||
                  col.field === "foodUpdates" ? (
                    <center>
                      <Button
                        onClick={() => handleVisibilityIconClick(col.field,index)}
                      >
                        <VisibilityIcon />
                      </Button>
                    </center>
                  ) : (
                  <center>{item[col.field]}</center>
                      
                    
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentReportTable;
