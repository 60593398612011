import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';


const slice = createSlice(
  {
    name: 'viewMonthlyAssessment',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchMonthlyAssessmentDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchMonthlyAssessmentDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchMonthlyAssessmentDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchMonthlyAssessmentDataStart, fetchMonthlyAssessmentDataSuccess, fetchMonthlyAssessmentDataFailure } = slice.actions;

export const fetchMonthlyAssessmentDataOnce = () => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewAssessment;

  const tokenId = token();

  if (data === null && !isLoading) {

    dispatch(fetchMonthlyAssessmentDataStart());

    try {
    
      const response = await fetch(API_PATH + '/pre-school-assessments',
      {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${tokenId}`
        }
    });
      
      if (!response.ok) {
        throw new Error('Request failed'); 
      }

      const data1 = await response.json();
      dispatch(fetchMonthlyAssessmentDataSuccess(data1));
    } catch (error) {
    
      dispatch(fetchMonthlyAssessmentDataFailure());
    }
  } 

}

export default slice.reducer;
