import React from 'react'
import ProgressComponent from './ProgressComponent';


const ViewAcademicDetails = (props) => {
  const {eachChildData} = props;
  const studentDetails = eachChildData?.studentEnrollments;
  const activeClassIndex = studentDetails?.findIndex((each)=>{return each.isActive===true});
  
  try{
    return (
      <div className='view-data-wrapper'>
         <div className='view-data'>
              <div className='each-prop'><span className='view-key'>Class  </span><span className='each-colon'>:</span><span className='view-value'><strong> {eachChildData?.studentEnrollments[activeClassIndex]?.class}</strong></span></div>
              <div className='each-prop'><span className='view-key'>Section  </span><span className='each-colon'>:</span><span className='view-value'><strong> {eachChildData?.studentEnrollments[activeClassIndex]?.section}</strong></span></div>
              <div className='each-prop'><span className='view-key'>Roll Number  </span><span className='each-colon'>:</span><span className='view-value'><strong> {eachChildData?.studentEnrollments[activeClassIndex]?.studentRollNumber}</strong></span></div>
         </div>
         <div className='progress-wrapper-data'>
          <ProgressComponent eachChildData={eachChildData}/>
          </div>
      </div>
    )
  }catch(err){
    return <div>Error Occured {err}</div>
  }
}

export default ViewAcademicDetails;