import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import AddIcon from "@mui/icons-material/Add";
import {
  validationRegexOnlyPincode,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  validationRegexAddress,
} from "../reUsableForm/regex";
import SelectDropdown from "../SelectDropdown";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import FileUpload from "../../ReusableFields/FileUpload/fileUpload";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchUpdateParentDataOnce, setShowSuccessMessage, setShowErrorMessage } from "./slice/editParentSlice";
import { uploadFiles } from "../reUsableForm/uploadImage-docs";
const EditParent = () => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const defaultAddress = queryParams.get("address") || "";
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    area: false,
    selectedFatherDocuments: false,
    pincode: false,
    address: false,
  });

  const [currentAddressErrors, setCurrentAddressErrors] = useState({
    area: false,
    state: false,
    city: false,
    pincode: false,
    address: false,
  });
  const navigateToAnotherScreen = () => {
    navigate("/AddArea");
  };
  const [parentShowErrorMsg, setParentShowErrorMsg] = useState(false);
  const [parentErrorMsg, setParentErrorMsg] = useState("");
  const [permanentArea, setPermanentArea] = useState({});
  const [currentArea, setCurrentArea] = useState({});

  const [selectedMotherDocuments, setSelectedMotherDocuments] = useState([]);

  const [guardianId, setGuardianId] = useState();
  const [selectedGuardianDocuments, setSelectedGuardianDocuments] = useState(
    []
  );
  const [saving, setSaving] = useState(false);

  const putData = useSelector((state) => state.updateParent.data) || [];
  const getParentData = useSelector((state) => state.viewParentRelation.data) || [];
  const { editId } = useParams();
  const areasIds = useSelector((state) => state.areaDetails.data) || [];
  useEffect(() => {
    if (areasIds.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [areasIds]);

  let putDataArray = [];
  let putCurrentAddress = null;
  let putPermanentAddress = null;
  if (putData !== null && putData?.guardian && typeof putData?.guardian === 'object') {
    putCurrentAddress = putData.guardian.currentAddress;
    putPermanentAddress = putData.guardian.permanentAddress;

    const item = [putData?.guardian];

    item.forEach((guardian) => {

      if (guardian) {
        const putAreaID = guardian?.currentAddress?.areaId;
        const putAreaObject = areasIds.find((item) => item.id === putAreaID);
        putDataArray.push({
          id: guardian?.id,
          name: guardian?.name,
          dateOfBirth: guardian?.dateOfBirth,
          email: guardian?.email,
          gender: guardian?.gender,
          primaryContactNumber: guardian?.primaryContactNumber,
          secondaryContactNumber: guardian?.secondaryContactNumber,
          relation: guardian?.relation,
          occupation: guardian?.occupation,
          remark: guardian?.remark,
          permanentAddressId: guardian?.permanentAddressId,
          currentAddressId: guardian?.currentAddressId,
          profilePictureUrl: guardian?.profilePictureUrl,
          documentUrls: guardian?.documentUrls,
          isActive: guardian.isActive,
          createdBy: guardian.createdBy,
          updatedBy: guardian.updatedBy,
          createdAt: guardian.createdAt,
          updatedAt: guardian.updatedAt,
          permanentAddress: {
            id: guardian?.id,
            address: putPermanentAddress?.address,
            areaId: putPermanentAddress?.areaId,
            cityId: putPermanentAddress?.cityId,
            stateId: putPermanentAddress?.stateId,
            pincode: putPermanentAddress?.pincode,
            type: putPermanentAddress?.type,
            createdBy: putPermanentAddress?.createdBy,
            updatedBy: putPermanentAddress?.updatedBy,
            createdAt: putPermanentAddress?.createdAt,
            updatedAt: putPermanentAddress?.updatedAt,
            area: {
              id: putPermanentAddress?.areaId,
              cityId: putPermanentAddress?.cityId,
              name: putAreaObject?.name,
            },
            city: {
              id: putPermanentAddress?.cityId,
              stateId: putPermanentAddress?.stateId,
              name: putAreaObject?.city?.name,
            },
            state: {
              id: putPermanentAddress?.stateId,
              name: putAreaObject?.city?.state?.name,
            },
          },
          currentAddress: {
            id: guardian.id,
            address: putCurrentAddress?.address,
            areaId: putCurrentAddress?.areaId,
            cityId: putCurrentAddress?.cityId,
            stateId: putCurrentAddress?.stateId,
            pincode: putCurrentAddress?.pincode,
            type: putCurrentAddress?.type,
            createdBy: putCurrentAddress?.createdBy,
            updatedBy: putCurrentAddress?.updatedBy,
            createdAt: putCurrentAddress?.createdAt,
            updatedAt: putCurrentAddress?.updatedAt,
            area: {
              id: putCurrentAddress?.areaId,
              cityId: putCurrentAddress?.cityId,
              name: putAreaObject?.name,
            },
            city: {
              id: putCurrentAddress?.cityId,
              stateId: putCurrentAddress?.stateId,
              name: putAreaObject?.city?.name,
            },
            state: {
              id: putCurrentAddress?.stateId,
              name: putAreaObject?.city?.state?.name,
            },
          },
        });
      }
    });
  }


  const accc = putDataArray[0]

  const [updatedParentData, setUpdatedParentData] = useState([]);

  useEffect(() => {
    if (getParentData) {
      const updatedData = getParentData.map(parent => {
        if (parent.father && parent.father.id === accc?.id) {
          return {
            ...parent,
            father: { ...parent.father, ...accc }
          };
        }
        if (parent.mother && parent.mother.id === accc?.id) {
          return {
            ...parent,
            mother: { ...parent.mother, ...accc }
          };
        }
        if (parent.guardian && parent.guardian.id === accc?.id) {
          return {
            ...parent,
            guardian: { ...parent.guardian, ...accc }
          };
        }
        return parent;
      });
      setUpdatedParentData(updatedData);
    }
  }, [getParentData, accc]);


  let firstData = {}
  if ((updatedParentData && updatedParentData.length > 0)) {


    const findIdObj = () => {
      const data = updatedParentData;
      return data.find(parent => parent.fatherId === parseInt(editId));
    };

    firstData = findIdObj();
  }



  const [relation, setRelation] = useState();

  const formattedDateOfBirth = firstData?.father?.dateOfBirth
    ? new Date(firstData?.father?.dateOfBirth)
    : null;



  const [fatherData, setFatherData] = useState({});

  useEffect(() => {
    if (firstData) {
      const data = {
        name: firstData?.father?.name || "",
        dateOfBirth: formattedDateOfBirth || null,
        gender: firstData?.father?.gender || "",
        primaryContactNumber: firstData?.father?.primaryContactNumber || "",
        secondaryContactNumber: firstData?.father?.secondaryContactNumber || null,
        relation: firstData?.father?.relation || "",
        occupation: firstData?.father?.occupation || "",
        remark: firstData?.father?.remark || null,
        email: firstData?.father?.email || "",
        profilePictureUrl: firstData?.father?.profilePictureUrl || null,
        documentUrls: firstData?.father?.documentUrls || null,
      }
      setFatherData(data)
    }
  }, [firstData])


  const formattedDateOfBirth1 = firstData?.mother?.dateOfBirth
    ? new Date(firstData?.mother?.dateOfBirth)
    : null;

  const [motherData, setMotherData] = useState({});

  useEffect(() => {
    if (firstData) {
      const data = {
        name: firstData?.mother?.name || "",
        dateOfBirth: formattedDateOfBirth1 || null,
        gender: firstData?.mother?.gender || "",
        primaryContactNumber: firstData?.mother?.primaryContactNumber || "",
        secondaryContactNumber: firstData?.mother?.secondaryContactNumber || null,
        relation: firstData?.mother?.relation || "",
        occupation: firstData?.mother?.occupation || "",
        remark: firstData?.mother?.remark || null,
        email: firstData?.mother?.email || "",
        profilePictureUrl: firstData?.mother?.profilePictureUrl || null,
        documentUrls: firstData?.mother?.documentUrls || null,
      }
      setMotherData(data)
    }
  }, [firstData])




  const formattedDateOfBirth2 = firstData?.guardian?.dateOfBirth
    ? new Date(firstData?.guardian?.dateOfBirth)
    : null;
  const [guardianData, setGuardianData] = useState({

  });
  useEffect(() => {
    if (firstData) {
      const data = {
        name: firstData?.guardian?.name || "",
        dateOfBirth: formattedDateOfBirth2 || null,
        gender: firstData?.guardian?.gender || "",
        primaryContactNumber: firstData?.guardian?.primaryContactNumber || "",
        secondaryContactNumber: firstData?.guardian?.secondaryContactNumber || null,
        relation: firstData?.guardian?.relation || "",
        occupation: firstData?.guardian?.occupation || "",
        remark: firstData?.guardian?.remark || null,
        email: firstData?.guardian?.email || "",
        profilePictureUrl: firstData?.guardian?.profilePictureUrl || null,
        documentUrls: firstData?.guardian?.documentUrls || null,
      }
      setGuardianData(data)
    }
  }, [firstData])


  const [currentAreaId, setCurrentAreaId] = useState({

  });



  useEffect(() => {
    if (firstData) {
      const data = {
        city:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.cityId || "",
        state:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.stateId || "",
      }

      setCurrentAreaId(data)
    }
  }, [firstData])



  const [currentAddress, setCurrentAddress] = useState({

  });

  useEffect(() => {
    if (firstData) {
      const data = {
        address: defaultAddress,
        type: "current",
        address:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.address || "",
        pincode:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.pincode || "",
        areaId:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.areaId || "",


      }

      setCurrentAddress(data)

    }
  }, [firstData, defaultAddress])










  const [permanentAreaId, setPermanentAreaId] = useState({

  });

  useEffect(() => {
    if (firstData) {
      const data = {
        city:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.cityId || "",
        state:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.stateId || "",
      }

      setPermanentAreaId(data)
    }
  }, [firstData])

  const [permanentAddress, setPermanentAddress] = useState({

  });

  useEffect(() => {
    if (firstData) {
      const data = {
        address: defaultAddress,
        type: "permanent",
        address:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.address || "",
        pincode:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.pincode || "",
        areaId:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.areaId || "",

      }

      setPermanentAddress(data)
    }
  }, [firstData, defaultAddress])

  const [dropDowns, setDropDowns] = useState({

  });

  useEffect(() => {
    if (firstData) {
      const data = {
        area:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.permanentAddress?.area?.name || "",
        area1:
          (firstData?.mother || firstData?.father || firstData?.guardian)
            ?.currentAddress?.area?.name || "",
      }

      setDropDowns(data)
    }
  }, [firstData])



  const [fatherImage, setFatherImage] = useState(null);
  if (typeof fatherData?.profilePictureUrl === 'string' && fatherData?.profilePictureUrl) {
    const lastPart = fatherData?.profilePictureUrl.substring(fatherData?.profilePictureUrl.lastIndexOf('/') + 1);
    const regex = /\d+_(.+)/;
    const match = lastPart.match(regex);
    var desiredFileName = "";
    if (match && match[1]) {
      desiredFileName = match[1];
    }
  }

  const [motherImage, setMotherImage] = useState(null);

  if (typeof motherData?.profilePictureUrl === 'string' && motherData?.profilePictureUrl) {
    const lastPart1 = motherData?.profilePictureUrl.substring(motherData?.profilePictureUrl.lastIndexOf('/') + 1);
    const regex1 = /\d+_(.+)/;
    const match1 = lastPart1.match(regex1);

    var desiredFileName1 = "";
    if (match1 && match1[1]) {
      desiredFileName1 = match1[1];
    }

  }
  const [guardianImage, setGuardianImage] = useState(null);

  if (typeof guardianData?.profilePictureUrl === 'string' && guardianData?.profilePictureUrl) {
    const lastPart2 = guardianData?.profilePictureUrl.substring(guardianData?.profilePictureUrl.lastIndexOf('/') + 1);
    const regex2 = /\d+_(.+)/;
    const match2 = lastPart2.match(regex2);

    var desiredFileName2 = "";
    if (match2 && match2[1]) {
      desiredFileName2 = match2[1];
    }
  }

  const [fatherDocuments, setFatherDocuments] = useState([]);
  const [desiredFileName6, setDesiredFileName6] = useState('');

  useEffect(() => {
    const extractFileName = (url) => {
      const lastPart = url.substring(url.lastIndexOf('/') + 1);
      const regex = /\d+_(.+)/;
      const match = lastPart.match(regex);
      return match && match[1] ? match[1] : '';
    };

    if (typeof fatherData?.documentUrls === 'string' && fatherData?.documentUrls) {
      const filename = extractFileName(fatherData.documentUrls);
      setDesiredFileName6(filename);
    } else if (Array.isArray(fatherData?.documentUrls)) {
      const filenames = fatherData.documentUrls.map(extractFileName);
      setFatherDocuments(filenames);

      // Combine all filenames into a single string separated by commas
      const combinedFilenames = filenames.filter(Boolean).join(', ');
      setDesiredFileName6(combinedFilenames);
    }
  }, [fatherData]);


  const [motherDocuments, setMotherDocuments] = useState([]);
  const [desiredFileName4, setDesiredFileName4] = useState('');

  useEffect(() => {
    const extractFileName = (url) => {
      const lastPart = url.substring(url.lastIndexOf('/') + 1);
      const regex = /\d+_(.+)/;
      const match = lastPart.match(regex);
      return match && match[1] ? match[1] : '';
    };

    if (typeof motherData?.documentUrls === 'string' && motherData?.documentUrls) {
      const filename = extractFileName(motherData.documentUrls);
      setDesiredFileName4(filename);
    } else if (Array.isArray(motherData?.documentUrls)) {
      const filenames = motherData.documentUrls.map(extractFileName);
      setMotherDocuments(filenames);

      // Combine all filenames into a single string separated by commas
      const combinedFilenames = filenames.filter(Boolean).join(', ');
      setDesiredFileName4(combinedFilenames);
    }
  }, [motherData]);




  const fileNamesArray = Array.from(
    { length: fatherDocuments.length },
    (_, index) => fatherDocuments[index].name
  );
  const fileNamesArray1 = Array.from(
    { length: motherDocuments.length },
    (_, index) => motherDocuments[index].name
  );




  const [guardianDocument, setGuardianDocument] = useState([]);

  const [desiredFileName5, setDesiredFileName5] = useState('');


  useEffect(() => {
    const extractFileName = (url) => {
      const lastPart = url.substring(url.lastIndexOf('/') + 1);
      const regex = /\d+_(.+)/;
      const match = lastPart.match(regex);
      return match && match[1] ? match[1] : '';
    };

    if (typeof guardianData?.documentUrls === 'string' && guardianData?.documentUrls) {
      const filename = extractFileName(guardianData.documentUrls);
      setDesiredFileName5(filename);
    } else if (Array.isArray(guardianData?.documentUrls)) {
      const filenames = guardianData.documentUrls.map(extractFileName);
      setGuardianDocument(filenames);

      // Combine all filenames into a single string separated by commas
      const combinedFilenames = filenames.filter(Boolean).join(', ');
      setDesiredFileName5(combinedFilenames);
    }
  }, [guardianData]);



  const fileNamesArray2 = Array.from(
    { length: guardianDocument.length },
    (_, index) => guardianDocument[index].name
  );


  const handleUploadFatherImage = (event) => {
    const file = event.target.files;
    setFatherImage(file);
  };

  const handleUploadMotherImage = (event) => {
    const file = event.target.files;
    setMotherImage(file);
  };

  const handleUploadGuardianImage = (event) => {
    const file = event.target.files;
    setGuardianImage(file);
  };


  const handleFatherDocumentChange = (event) => {
    const files = event.target.files;
    setFatherDocuments(files);
  };



  const handleMotherDocumentChange = (event) => {
    const files = event.target.files;
    setMotherDocuments(files);
  };

  const handleGuardianDocumentChange = (event) => {
    const files = event.target.files;
    setGuardianDocument(files);
  };


  useEffect(() => {
    if (Object.keys(currentAreaId).length > 0 && currentAreaId.city && currentAreaId.state) {
      setCurrentAddress({
        ...currentAddress,
        cityId: currentAreaId.city,
        stateId: currentAreaId.state,
      });
    }
  }, [currentAreaId]);
  useEffect(() => {
    if (Object.keys(permanentAreaId).length > 0 && permanentAreaId.city && permanentAreaId.state) {
      setPermanentAddress({
        ...permanentAddress,
        cityId: permanentAreaId.city,
        stateId: permanentAreaId.state,
      });
    }
  }, [permanentAreaId]);


  const apiData = useSelector((state) => state.areaDetails.data) || [];

  const dispatch = useDispatch();

  const showSuccessMsg = useSelector(
    (state) => state.updateParent.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.updateParent.successMessage);
  const showErrorMsg = useSelector((state) => state.updateParent.showErrorMessage);
  const errorMsg = useSelector((state) => state.updateParent.errorMessage);

  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [apiData, dispatch]);

  const fatherRowData = (
    <Row>
      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Father Name"
          placeholder="Father Name"
          textType="Alphabet"
          value={fatherData.name}
          onChange={(event) =>
            setFatherData({
              ...fatherData,
              name: event.target.value,
              relation: "father",
              gender: "male",
            })
          }
          feedbackMessage="Please enter fatherName."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter valid fatherName."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Relation Ship"
          textType="Alphabet"
          value={"Father"}
          disabled
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <FileUpload
            title="Upload&nbsp;Image"
            onChangeHandler={handleUploadFatherImage}
          />


          {fatherImage?.[0] ? (
            <div className="file-names">
              {typeof fatherImage[0].name === "string" && (
                <p>{fatherImage[0].name}</p>
              )}
            </div>

          ) : (
            desiredFileName && (
              <div className="file-names">
                {typeof desiredFileName === "string" && (
                  <p>{desiredFileName}</p>)}
              </div>
            )
          )}
          {formErrors.selectedFatherImage && (
            <div className="error-message">Please Select Father Image.</div>
          )}
        </Form.Group>
      </Col>


      <Col xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <div className="uploadDoc">
            <div className="layout1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                  fill="#636363"
                />
              </svg>
              <div className="title1">Upload&nbsp;Docu</div>
            </div>
            <input
              type="file"
              accept=".pdf,.jpg"
              required
              multiple
              onChange={handleFatherDocumentChange}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
              }}
            />
          </div> 
          <div>

            {fileNamesArray[0] !== undefined ? fileNamesArray?.map((name, index) => {
                return <p key={index}>{name}</p>;
              })
              : (desiredFileName6 && (
                <div>
                  {typeof desiredFileName6 === "string" && <p>{desiredFileName6}</p>}
                </div>
              )
            )}
          </div>
        </Form.Group>
        {formErrors.selectedFatherDocuments && (
          <div className="error-message">Please Select selectedDocuments .</div>
        )}
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <DatePickerPast
          name="Date Of Birth"
          placeholder="DOB*"
          // label="DOB*"
          value={fatherData.dateOfBirth || ""}
          onChange={(name, date, selectedDate) =>
            setFatherData({
              ...fatherData,
              dateOfBirth: date,
            })
          }
          feedbackMessage="Please select date of birth."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

        <TextInputField
          name="Relation Ship"
          textType="Alphabet"
          value={fatherData.email || ""}
          disabled
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <Form.Label className="lableName">Gender</Form.Label>
          <div>
            <Form.Check
              inline
              label="Male"
              type="radio"
              name="gender1"
              value="male"
              className="malebutton"
              defaultChecked
              checked={fatherData.gender === "male"}
              onChange={(event) =>
                setFatherData({ ...fatherData, gender: event.target.value })
              }
            />
            <Form.Check
              inline
              label="Female"
              type="radio"
              name="gender1"
              value="female"
              className="malebutton"
              checked={fatherData.gender === "female"}
              onChange={(event) =>
                setFatherData({ ...fatherData, gender: event.target.value })
              }
            />
          </div>
        </Form.Group>
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="primary Contact Number"
          placeholder="primary Contact Number*"
          textType="Alphabet"
          value={fatherData.primaryContactNumber}
          onChange={(event) =>
            setFatherData({
              ...fatherData,
              primaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter primary contact number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid primary contact number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="secondary Contact Number"
          placeholder="secondary Contact Number*"
          textType="Alphabet"
          value={fatherData.secondaryContactNumber}
          onChange={(event) =>
            setFatherData({
              ...fatherData,
              secondaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter secondary Contact Number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid secondary Contact Number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Occupation "
          placeholder="Occupation *"
          textType="Alphabet"
          value={fatherData.occupation}
          onChange={(event) =>
            setFatherData({ ...fatherData, occupation: event.target.value })
          }
          feedbackMessage="Please enter Occupation ."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid Occupation ."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Remark"
          placeholder="Remark*"
          textType="Alphabet"
          value={fatherData.remark}
          onChange={(event) =>
            setFatherData({ ...fatherData, remark: event.target.value })
          }
          feedbackMessage="Please enter  Remark."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid  Remark."
        />
      </Col>
    </Row>
  );

  const motherRowData = (
    <Row>
      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Mother Name"
          placeholder="Mother Name"
          textType="Alphabet"
          value={motherData.name}
          onChange={(event) =>
            setMotherData({
              ...motherData,
              name: event.target.value,
              relation: "mother",
              gender: "female",
            })
          }
          feedbackMessage="Please enter motherName."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter valid motherName."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Relation Ship"
          placeholder="Relation Ship"
          textType="Alphabet"
          value="Mother"
          disabled
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <FileUpload
            title="Upload&nbsp;Image"
            onChangeHandler={handleUploadMotherImage}
          />

          {motherImage?.[0] ? (
            <div className="file-names">
              {typeof motherImage[0].name === "string" && (
                <p>{motherImage[0].name}</p>
              )}
            </div>

          ) : (
            desiredFileName1 && (
              <div className="file-names">
                {typeof desiredFileName1 === "string" && (
                  <p>{desiredFileName1}</p>)}
              </div>
            )
          )}
          {formErrors.selectedMotherImage && (
            <div className="error-message">Please mother Select Image .</div>
          )}
        </Form.Group>
      </Col>

      <Col xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <div className="uploadDoc">
            <div className="layout1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                  fill="#636363"
                />
              </svg>
              <div className="title1">Upload&nbsp;Docu</div>
            </div>
            <input
              type="file"
              accept=".pdf,.jpg"
              multiple
              onChange={handleMotherDocumentChange}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
              }}
            />
          </div>
          <div>
            {fileNamesArray1[0] !== undefined ? (
              fileNamesArray1.map((name, index) => (
                <p key={index}>{name}</p>
              ))
            ) : (
              desiredFileName4 && (
                <div>
                  {typeof desiredFileName4 === "string" && <p>{desiredFileName4}</p>}
                </div>
              )
            )}
          </div>
        </Form.Group>
        {formErrors.selectedMotherDocuments && (
          <div className="error-message">Please Select selectedDocuments .</div>
        )}
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <DatePickerPast
          name="Date Of Birth"
          placeholder="DOB*"
          value={motherData.dateOfBirth || ""}
          onChange={(name, date, selectedDate) =>
            setMotherData({
              ...motherData,
              dateOfBirth: date,
            })
          }
          feedbackMessage="Please select date of birth."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">


        <TextInputField
          name="Relation Ship"
          textType="Alphabet"
          value={motherData.email || ""}
          disabled
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <Form.Label className="lableName">Gender</Form.Label>
          <div>
            <Form.Check
              inline
              label="Male"
              type="radio"
              name="gender2"
              value="male"
              className="malebutton"
              onChange={(event) =>
                setMotherData({ ...motherData, gender: event.target.value })
              }
            />
            <Form.Check
              inline
              label="Female"
              type="radio"
              name="gender2"
              value="female"
              className="malebutton"
              // defaultChecked
              checked={motherData.gender === "female"}
              onChange={(event) =>
                setMotherData({ ...motherData, gender: event.target.value })
              }
            />
          </div>
        </Form.Group>
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name=" primary Contact Number"
          placeholder="primary Contact Number*"
          textType="Alphabet"
          value={motherData.primaryContactNumber}
          onChange={(event) =>
            setMotherData({
              ...motherData,
              primaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter primary Contact Number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid primary Contact Number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="secondary Contact Number"
          placeholder="secondary Contact Number*"
          textType="Alphabet"
          value={motherData.secondaryContactNumber}
          onChange={(event) =>
            setMotherData({
              ...motherData,
              secondaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter secondary Contact Number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid secondary Contact Number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Occupation "
          placeholder="Occupation *"
          textType="Alphabet"
          value={motherData.occupation}
          onChange={(event) =>
            setMotherData({ ...motherData, occupation: event.target.value })
          }
          feedbackMessage="Please enter Occupation ."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid Occupation ."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Remark"
          placeholder="Remark*"
          textType="Alphabet"
          value={motherData.remark}
          onChange={(event) =>
            setMotherData({ ...motherData, remark: event.target.value })
          }
          feedbackMessage="Please enter  Remark."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid  Remark."
        />
      </Col>
    </Row>
  );

  const guardianRowData = (
    <Row>
      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Guardian Name"
          placeholder="Guardian Name"
          textType="Alphabet"
          value={guardianData.name}
          onChange={(event) =>
            setGuardianData({ ...guardianData, name: event.target.value })
          }
          feedbackMessage="Please enter guardian name."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter valid guardian name."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Select
          as="select"
          className="textInput_Field"
          value={guardianData.relation}
          onChange={(event) =>
            setGuardianData({
              ...guardianData,
              relation: event.target.value,
            })
          }
        >
          <option value="RelationShip">RelationShip</option>
          <option value="grand mother">Grand Mother</option>
          <option value="grand father">Grand Father</option>
        </Form.Select>
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <FileUpload
            title="Upload&nbsp;Image"
            onChangeHandler={handleUploadGuardianImage}
          />
          {guardianImage?.[0] ? (
            <div className="file-names">
              {typeof guardianImage[0].name === "string" && (
                <p>{guardianImage[0].name}</p>
              )}
            </div>

          ) : (
            desiredFileName2 && (
              <div className="file-names">
                {typeof desiredFileName2 === "string" && (
                  <p>{desiredFileName2}</p>)}
              </div>
            )
          )}
        </Form.Group>
      </Col>

      <Col xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <div className="uploadDoc">
            <div className="layout1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                  fill="#636363"
                />
              </svg>
              <div className="title1">Upload&nbsp;Docu</div>
            </div>
            <input
              type="file"
              accept=".pdf,.jpg"
              multiple
              onChange={handleGuardianDocumentChange}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
              }}
            />
          </div>
          <div>
            {fileNamesArray2[0] !== undefined ? (
              fileNamesArray2.map((name, index) => (
                <p key={index}>{name}</p>
              ))
            ) : (
              desiredFileName5 && (
                <div>
                  {typeof desiredFileName5 === "string" && <p>{desiredFileName5}</p>}
                </div>
              )
            )}
          </div>
        </Form.Group>
        {formErrors.selectedGuardianDocuments && (
          <div className="error-message">Please Select selectedDocuments .</div>
        )}
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <DatePickerPast
          name="Date Of Birth"
          placeholder="DOB*"
          value={guardianData.dateOfBirth || ""}
          onChange={(name, date, selectedDate) =>
            setGuardianData({
              ...guardianData,
              dateOfBirth: date,
            })
          }
          feedbackMessage="Please select date of birth."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

        <TextInputField
          name="Relation Ship"
          textType="Alphabet"
          value={guardianData.email || ""}
          disabled
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <Form.Group>
          <Form.Label className="lableName">Gender</Form.Label>
          <div>
            <Form.Check
              inline
              label="Male"
              type="radio"
              name="gender"
              value="male"
              className="malebutton"
              checked={guardianData.gender === "male"}
              onChange={(event) =>
                setGuardianData({
                  ...guardianData,
                  gender: event.target.value,
                })
              }
            />
            <Form.Check
              inline
              label="Female"
              type="radio"
              name="gender"
              value="female"
              className="malebutton"
              checked={guardianData.gender === "female"}
              onChange={(event) =>
                setGuardianData({
                  ...guardianData,
                  gender: event.target.value,
                })
              }
            />
          </div>
        </Form.Group>
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="primary Contact Number"
          placeholder="primary Contact Number*"
          textType="Alphabet"
          value={guardianData.primaryContactNumber}
          onChange={(event) =>
            setGuardianData({
              ...guardianData,
              primaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter primary Contact Number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid primary Contact Number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="secondary Contact Number"
          placeholder="secondary Contact Number*"
          textType="Alphabet"
          value={guardianData.secondaryContactNumber}
          onChange={(event) =>
            setGuardianData({
              ...guardianData,
              secondaryContactNumber: Number(event.target.value),
            })
          }
          feedbackMessage="Please enter secondary Contact Number."
          validationRegex={validationRegexMobileNo}
          validationErrorMessage="Please enter a valid secondary Contact Number."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Occupation "
          placeholder="Occupation *"
          textType="Alphabet"
          value={guardianData.occupation}
          onChange={(event) =>
            setGuardianData({
              ...guardianData,
              occupation: event.target.value,
            })
          }
          feedbackMessage="Please enter Occupation ."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid Occupation ."
        />
      </Col>

      <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
        <TextInputField
          name="Remark"
          placeholder="Remark*"
          textType="Alphabet"
          value={guardianData.remark}
          onChange={(event) =>
            setGuardianData({ ...guardianData, remark: event.target.value })
          }
          feedbackMessage="Please enter  Remark."
          validationRegex={validationRegexOnlyAlpha}
          validationErrorMessage="Please enter a valid  Remark."
        />
      </Col>
    </Row>
  );

  const [defaultRelation, setDefaultRelation] = useState("");
  useEffect(() => {
    if (firstData) {
      if (firstData.father) {
        const object = {
          id: firstData.fatherId,
          label: fatherName,
          value: fatherName,
        };
        setDefaultRelation(object);
      } else if (firstData.mother) {
        const object = {
          id: firstData.motherId,
          label: motherName,
          value: motherName,
        };
        setDefaultRelation(object);
      } else {
        const object = {
          id: firstData.guardianId,
          label: guardianName,
          value: guardianName,
        };
        setDefaultRelation(object);
      }
    }
  }, [firstData]);

  let rowData;
  if (defaultRelation.value && !relation) {
    const defaultRelationValue = defaultRelation.value;
    const match = defaultRelationValue.match(/\((.*?)\)/);
    const valueInsideParentheses = match ? match[1] : null;

    if (valueInsideParentheses) {
      switch (valueInsideParentheses) {
        case "father":
          rowData = fatherRowData;
          break;
        case "mother":
          rowData = motherRowData;
          break;
        case "grand mother":
          rowData = guardianRowData;
          break;
        case "grand father":
          rowData = guardianRowData;
          break;
        default:
          rowData = null;
      }
    } else {
      rowData = null;
    }
  } else if (relation) {
    const RelationValue = relation;
    const match = RelationValue.match(/\((.*?)\)/);
    const valueInsideParentheses2 = match ? match[1] : null;

    if (valueInsideParentheses2) {
      switch (valueInsideParentheses2) {
        case "father":
          rowData = fatherRowData;
          break;
        case "mother":
          rowData = motherRowData;
          break;
        case "grand mother":
          rowData = guardianRowData;
          break;
        case "grand father":
          rowData = guardianRowData;
          break;
        default:
          rowData = null;
      }
    } else {
      rowData = null;
    }
  } else {
    rowData = null;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true);
    const errorPopup = {};

    if (fatherImage) {
      const uploadResponses1 = await uploadFiles((fatherImage), "guardians/profile-images");

      var imageId = uploadResponses1.map((response) => response.Location) || null;
    }

    if (motherImage) {
      const uploadResponses2 = await uploadFiles((motherImage), "guardians/profile-images");
      var imageId1 = uploadResponses2.map((response) => response.Location) || null;

    }
    if (guardianImage) {
      const uploadResponses3 = await uploadFiles((guardianImage), "guardians/profile-images");
      var imageId2 = uploadResponses3.map((response) => response.Location) || null;
    }



    if (fatherDocuments.length > 0) {
      const uploadResponses1 = await uploadFiles((fatherDocuments), "guardians/documents");
      var fileId = uploadResponses1.map((response) => response.Location) || null;
    }



    var fatherUpdatedData;
    if (fatherData && Object.keys(fatherData).length > 0) {
      fatherUpdatedData = {
        ...fatherData,
        primaryContactNumber: Number(fatherData.primaryContactNumber),
        secondaryContactNumber: Number(fatherData.secondaryContactNumber),
        profilePictureUrl: (imageId ? imageId[0] : imageId) || (fatherData?.profilePictureUrl),
        documentUrls: fileId,
      };
    } else {
      fatherUpdatedData = {};
    }



    if (motherDocuments.length > 0) {
      const uploadResponses1 = await uploadFiles((motherDocuments), "guardians/documents");
      var fileId1 = uploadResponses1.map((response) => response.Location) || null;
    }
    var motherUpdatedData;

    if (motherData && Object.keys(motherData).length > 0) {
      motherUpdatedData = {
        ...motherData,
        primaryContactNumber: Number(motherData.primaryContactNumber),
        secondaryContactNumber: Number(motherData.secondaryContactNumber),
        profilePictureUrl: (imageId1 ? imageId1[0] : imageId1) || (motherData?.profilePictureUrl),
        documentUrls: fileId1,
      };
    } else {
      motherUpdatedData = {};
    }





    if (guardianDocument.length > 0) {
      const uploadResponses1 = await uploadFiles((guardianDocument), "guardians/documents");
      var fileId2 = uploadResponses1.map((response) => response.Location) || null;
    }
    var guardianUpdatedData;

    if (guardianData && Object.keys(guardianData).length > 0) {
      guardianUpdatedData = {
        ...guardianData,
        primaryContactNumber: Number(guardianData.primaryContactNumber),
        secondaryContactNumber: Number(guardianData.secondaryContactNumber),
        profilePictureUrl: (imageId2 ? imageId2[0] : imageId2) || (guardianData?.profilePictureUrl),
        documentUrls: fileId2,
      };
    } else {
      guardianUpdatedData = {};
    }

    if (
      Object.keys(fatherUpdatedData).length === 0 &&
      Object.keys(motherUpdatedData).length === 0 &&
      Object.keys(guardianUpdatedData).length === 0
    ) {
      setParentShowErrorMsg(true);
      setParentErrorMsg("select at least one parent");
    } else {
      if (Object.keys(fatherUpdatedData).length > 0) {
        if (Object.keys(fatherUpdatedData).length < 7) {
          errorPopup.father = "father";
        }
      }
      if (Object.keys(motherUpdatedData).length > 0) {
        if (Object.keys(motherUpdatedData).length < 7) {
          errorPopup.mother = "mother";
        }
      }
      if (Object.keys(guardianUpdatedData).length > 0) {
        if (Object.keys(guardianUpdatedData).length < 7) {
          errorPopup.guardian = "guardian";
        }
      }
    }

    if (Object.keys(errorPopup).length > 0) {
      setParentShowErrorMsg(true);
      setParentErrorMsg(
        `please enter required fields in ${Object.values(errorPopup)}.`
      );
    } else {
      const newFormErrors = {
        area1: !dropDowns.area1,
      };

      setFormErrors(newFormErrors);

      const updateAddress = {
        address: !currentAddress.address,
        pincode: !currentAddress.pincode,
      };

      setCurrentAddressErrors(updateAddress);

      const formErrorsValues = Object.values(newFormErrors);
      const addressErrorsValues = Object.values(updateAddress);

      const hasErrors = formErrorsValues
        .concat(addressErrorsValues)
        .some((error) => error);

      setFormErrors(newFormErrors);
      setCurrentAddressErrors(updateAddress);

      if (hasErrors) {
        setValidated(true);
        setSaving(false);
        return;
      }



      const sendData = {
        guardian: [],
        currentAddress: currentAddress,
        permanentAddress: permanentAddress,
      };

      if (Object.keys(fatherUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(fatherUpdatedData);
        }
      }
      if (Object.keys(motherUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(motherUpdatedData);
        }
      }
      if (Object.keys(guardianUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(guardianUpdatedData);
        }
      }

      const updatedData = sendData.guardian;


      const defaultRelationValue = defaultRelation.value;

      if (defaultRelationValue) {
        const match1 = defaultRelationValue.match(/\((.*?)\)/);
        var valueInsideParentheses = match1 ? match1[1] : null;

      }
      else {
        console.error("defaultRelationValue is undefined or null.");
      }

      if (relation) {
        const match2 = relation.match(/\((.*?)\)/);
        var valueInsideParentheses2 = match2 ? match2[1] : null;
      } else {
        console.error("RelationValue is undefined or null.");
      }


      const filteredGuardians = updatedData.filter((data) => {
        return data.relation === (valueInsideParentheses || valueInsideParentheses2);
      });

      const filteredGuardians1 = filteredGuardians[0];
      const newData = {
        ...filteredGuardians1,
        currentAddress,
        permanentAddress,
      };
      dispatch(fetchUpdateParentDataOnce((guardianId || defaultRelation.id), newData));
    }
    setSaving(false);
  };



  const handleCancel = (e) => {

    setSelectedMotherDocuments([]);

    setSelectedGuardianDocuments([]);
    setCurrentAddress({});
    setPermanentAddress({});
    setCurrentArea({});
    setPermanentArea({});
    setValidated(false);
    setDropDowns({});
    setFatherImage(null);
    setMotherImage(null);
    setGuardianImage(null);
    setFatherDocuments([]);
    setMotherDocuments([]);
    setGuardianDocument([]);
    setFormErrors("");
    setCurrentAddressErrors("");
    setMotherData({});
    setFatherData({});
    setGuardianData({});
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    navigate("/viewParent");
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  const fatherName = `${firstData?.father?.name} (${firstData?.father?.relation})`;
  const motherName = `${firstData?.mother?.name} (${firstData?.mother?.relation})`;
  const guardianName = `${firstData?.guardian?.name} (${firstData?.guardian?.relation})`;

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header ">Edit Parents</div>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.relation
                  ? { value: dropDowns.relation, label: dropDowns.relation }
                  : defaultRelation
              }
              options={[
                {
                  id: firstData.fatherId,
                  value: fatherName,
                  label: fatherName,
                },
                {
                  id: firstData.motherId,
                  value: motherName,
                  label: motherName,
                },
                {
                  id: firstData.guardianId,
                  value: guardianName,
                  label: guardianName,
                },
              ]}
              onChange={(selectedOption) => {
                setDefaultRelation("");
                setGuardianId();
                setGuardianId(selectedOption.id);
                setRelation(selectedOption.value);
                setDropDowns({
                  ...dropDowns,
                  relation: selectedOption.value,
                });
              }}
              placeholder="Select RelationShip"
            />
          </Col>
        </Row>
        {rowData}

        <hr style={{ margin: "30px" }}></hr>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Permanent Address"
              placeholder="Permanent Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.address}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter permanent address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid permanent address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area
                  ? { value: dropDowns.area, label: dropDowns.area }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setPermanentAddress({
                  ...permanentAddress,
                  areaId: selectedOption.id,
                });
                setPermanentArea({
                  ...permanentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setPermanentAreaId({
                  ...permanentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });

                setDropDowns({ ...dropDowns, area: selectedOption.value });
                setFormErrors({ ...formErrors, area: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={
                permanentArea?.city?.name ||
                (firstData?.mother || firstData?.father || firstData?.guardian)
                  ?.permanentAddress?.city?.name
              }
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={
                permanentArea?.state?.name ||
                (firstData?.mother || firstData?.father || firstData?.guardian)
                  ?.permanentAddress?.state?.name
              }
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.pincode || ""}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Current Address"
              placeholder="Current Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.address}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area1
                  ? { value: dropDowns.area1, label: dropDowns.area1 }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setCurrentAddress({
                  ...currentAddress,
                  areaId: selectedOption.id,
                });
                setCurrentArea({
                  ...currentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setCurrentAreaId({
                  ...currentAreaId,
                  city:
                    selectedOption.city.id,
                  state:
                    selectedOption.state.id,
                });
                setDropDowns({ ...dropDowns, area1: selectedOption.value });
                setFormErrors({ ...formErrors, area1: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area1 && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={
                currentArea?.city?.name ||
                (firstData?.mother || firstData?.father || firstData?.guardian)
                  ?.currentAddress?.city?.name
              }
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={
                currentArea?.state?.name ||
                (firstData?.mother || firstData?.father || firstData?.guardian)
                  ?.currentAddress?.state?.name
              }
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.pincode || ""}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <ErrorMessagePopup
          show={parentShowErrorMsg}
          onClose={() => {
            setParentShowErrorMsg(false);
          }}
          errorMessage={parentErrorMsg}
        />

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            {saving ? "Saving..." : "save"}
          </button>
        </div>
      </Form>
      {/*  */}
    </div>
  );
};

export default EditParent;
