import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { validationRegexAmount } from "../reUsableForm/regex";
import { postSchoolRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/royaltyAmountSlice";
import { FetchFranchiseSchools } from "../Franchise/slice/schoolDataGet";
import SelectDropdown from "../SelectDropdown";
import ViewRoyaltyAmount from "./viewRoyaltyAmount";



const RoyaltyAmount = () => {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [formErrors, setFormErrors] = useState({ franchiseeSchoolId: false })

  const getBranch = useSelector((state) => state.franchiseSchools.data) || [];
  const postBranch = useSelector((state) => state.schoolCreation.data) || [];

  const data = [...(getBranch || []), ...postBranch]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchFranchiseSchools());

  }, [dispatch])

  const showSuccessMsg = useSelector((state) => state.royaltyAmount.showSuccessMessage);
  const successMsg = useSelector((state) => state.royaltyAmount.successMessage);

  const showErrorMsg = useSelector((state) => state.royaltyAmount.showErrorMessage);
  const errorMsg = useSelector((state) => state.royaltyAmount.errorMessage);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormErrors = {
      franchiseeSchoolId: !formData.franchiseeSchoolId,
      amount: !formData.amount
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some(error => error);


    if (hasErrors) {
      setValidated(true);
      return;
    }
    const schoolData = {
      ...formData,
      franchiseeSchoolId: Number(selectedSchool),
    };
    const franchiseeSchoolId = formData.franchiseeSchoolId.id

    formData.franchiseeSchoolId = franchiseeSchoolId;

    dispatch(postSchoolRequest(schoolData));

    setSelectedSchool('');
    setFormData({});
  };
  const handleClear = () => {
    setFormData({
      amount: "",
    });
    setFormErrors({
      schoolId: false,
    });
    setValidated(false);
    setSelectedSchool('');
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Add Royalty Amount </div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.franchiseeSchoolId ? { id: formData.franchiseeSchoolId.id, label: formData.franchiseeSchoolId.label } : null}
              options={data.map((school) => ({ id: school.id, label: school.name, value: school.name }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, franchiseeSchoolId: selectedOption });
                setSelectedSchool(selectedOption.id)
                setFormErrors({ ...formErrors, franchiseeSchoolId: !selectedOption });
              }}
              placeholder="Select school"
            />
            {formErrors.franchiseeSchoolId && (
              <div className="error-message">
                Please Select school .
              </div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <Form.Group>
              <TextInputField
                type="text"
                className="input-style"
                required
                placeholder="Amount"
                value={formData.amount}
                onChange={(event) => {
                  setFormData({ ...formData, amount: event.target.value });
                }}
                validated={validated}
                feedbackMessage="Please enter Amount"
                validationRegex={validationRegexAmount}
                validationErrorMessage="Please enter a valid Amount"
              />
            </Form.Group>
          </Col>
        </Row>

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
            style={{ width: '6rem', height: '2rem' }}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
            Submit
          </button>
        </div>
      </Form>
      <div>
        <ViewRoyaltyAmount />
      </div>
    </>

  );
};

export default RoyaltyAmount;
