import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchRevenueTypeView } from "./slice/revenueTypeViewSlicer";
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { setShowErrorMessageVehicle, setShowSuccessMessageVehicle, updateRevenueType } from "./slice/revenueTypeViewPutSlicer";
import { setShowErrorMessageRevenue, setShowSuccessMessageRevenue } from "./slice/revenueTypeViewDeleteSlicer";
import { deleteRevenueType } from "./slice/revenueTypeViewDeleteSlicer";
import ConfirmationPopup from '../reUsableForm/pop-card/confirmationPopUp'; // Import the new ConfirmationPopup component

const RevenueTypeViewProgram = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [revenueTypeEditModal, setRevenueTypeEditModal] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const columns = [
        { field: 'index', header: 'Sr.No' },
        { field: 'name', header: 'Revenue Type' },
        { field: 'revenueEdit', header: 'Edit' },
        { field: 'revenueDelete', header: 'Delete' },
    ];

    const apiData = useSelector((state) => state.revenueTypeView.data) || [];
    const postData = useSelector((state) => state.revenueType.data) || [];
    const putData = useSelector((state) => state.revenueTypeEdit.data) || [];
    const deleteData = useSelector((state) => state.revenueTypeDelete.data) || [];

    const showSuccessMsg = useSelector((state) => state.revenueTypeEdit.showSuccessMessage);
    const successMsg = useSelector((state) => state.revenueTypeEdit.successMessage);

    const showErrorMsg = useSelector((state) => state.revenueTypeEdit.showErrorMessage);
    const errorMsg = useSelector((state) => state.revenueTypeEdit.errorMessage);

    const showSuccessMsgg = useSelector((state) => state.revenueTypeDelete.showSuccessMessage);
    const successMsgg = useSelector((state) => state.revenueTypeDelete.successMessage);
    const showErrorMsgg = useSelector((state) => state.revenueTypeDelete.showErrorMessage);
    const errorMsgg = useSelector((state) => state.revenueTypeDelete.errorMessage);

    const combinedData = [...(apiData || []), ...postData];

    let updatedCombinedData = [...combinedData];

    putData.forEach((putItem) => {
        const index = updatedCombinedData.findIndex((item) => item.id === putItem.id);
        if (index !== -1) {
            updatedCombinedData[index] = { ...updatedCombinedData[index], ...putItem };
        }
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchRevenueTypeView());
    }, [dispatch]);

    const updatedData = updatedCombinedData.filter(item => !deleteData.includes(item.id));

    const newModifiedData = updatedData.map((postItem, index) => ({
        index: index + 1,
        id: postItem.id,
        name: postItem.name,
    }));

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessageVehicle(false));
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessageVehicle(false));
    };

    const handleCloseErrorPopupDelete = () => {
        dispatch(setShowErrorMessageRevenue(false));
    };

    const handleCloseSuccessPopupDelete = () => {
        dispatch(setShowSuccessMessageRevenue(false));
    };

    const handleRevenueEditSave = () => {
        if (editedData && editedData.id) {
            const updatedData = {
                id: editedData.id,
                name: editedData.name,
                revenueEdit: editedData.revenueEdit,
            };
            dispatch(updateRevenueType(editedData.id, updatedData));
            setTimeout(() => {
                setRevenueTypeEditModal(false);
            }, 3000);
        } else {
            console.error("Row ID or edited data ID is missing.");
        }
    };

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        if (deleteId) {
            dispatch(deleteRevenueType(deleteId));
            setShowConfirmDelete(false);
            setDeleteId(null);
        }
    };

      const handleCloseConfirmDelete = () => {
        setShowConfirmDelete(false);
        setDeleteId(null);
    };

    return (
        <>
            {newModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table
                    data={newModifiedData}
                    columns={columns}
                    hover={true}
                    striped={true}
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    handleDeleteClick={handleDeleteClick}
                    handleRevenueEditSave={handleRevenueEditSave}
                    revenueTypeEditModal={revenueTypeEditModal}
                    setRevenueTypeEditModal={setRevenueTypeEditModal}
                />
            )}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

            <SuccessMessagePopup show={showSuccessMsgg} onClose={handleCloseSuccessPopupDelete} successMessage={successMsgg} />
            <ErrorMessagePopup show={showErrorMsgg} onClose={handleCloseErrorPopupDelete} errorMessage={errorMsgg} />
            <ConfirmationPopup
                show={showConfirmDelete}
                onClose={handleCloseConfirmDelete}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this item?"
            />
        </>
    );
};

export default RevenueTypeViewProgram;
