import { createSlice } from '@reduxjs/toolkit';
import { fetchWithAuthorization } from '../../Auth/dependency-get';
import { API_PATH } from '../../../Api/api';
import { franchiseeSchoolId, token } from '../../../Api/token';

const slice = createSlice({
    name: 'FoodUpdatedData',
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
    },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = slice.actions;

export const FetchFoodUpdatesData = (studentIds) => async (dispatch, getState) => {
    let ids = '';
    if (Array.isArray(studentIds) && studentIds.length > 0) {
        const queryString = studentIds.map((id) => 'studentId=' + id).join('&');
        ids += queryString;
    }

    const { data, isLoading } = getState().FoodUpdatedData;
    const tokenId = token();

    if ((data === null) && !isLoading) {
        dispatch(fetchDataStart());

        try {


            const apiUrl = API_PATH + `/foodupdates?isActive=true&franchiseeSchoolId=${franchiseeSchoolId()}&${ids}`;
            

            const response = await fetchWithAuthorization(apiUrl, 'GET', tokenId);
            if (!response) {
                const errorData = await response;
                throw new Error(errorData.errors);
            } else {
                const responseData = await response;
                dispatch(fetchDataSuccess(responseData));
            }
        } catch (error) {
            dispatch(fetchDataFailure());
        }
    } else {
        console.error("Data is already loaded or a request is in progress.");
    }
};

export default slice.reducer;
