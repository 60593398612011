import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "pettyExpensesView",
    initialState: {
        data: [],
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setRevenueData: (state, action) => {
            state.isLoading = false;
            const newData = action.payload;
            const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

            if (existingObjectIndex !== -1) {
                state.data[existingObjectIndex] = newData;
            }
        },
    }
});

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess, setRevenueData } = slice.actions;

export const pettyExpensesViewSlicer = (fromDate, toDate, expenseTypeId) => async (dispatch, getState) => {
    const { data, isLoading } = getState().pettyExpensesView;
    const tokenId = token();
    const franchiseeSchoolId = Number(sessionStorage.getItem("franchiseeSchoolId"));
    const roleId = Number(sessionStorage.getItem("roleId"));  // Get roleId from sessionStorage

    if (!data.length && !isLoading) {
        dispatch(fetchDataStart());
        try {

            let url;
            if (roleId === 1) {
                url = fromDate && toDate
                    ? `${API_PATH}/petty-expenses?isActive=true&fromDate=${fromDate}&toDate=${toDate}&expenseTypeId=${expenseTypeId}`
                    : `${API_PATH}/petty-expenses?isActive=true`;
            } else {
                url = fromDate && toDate
                    ? `${API_PATH}/petty-expenses?isActive=true&fromDate=${fromDate}&toDate=${toDate}&franchiseeSchoolId=${franchiseeSchoolId}&expenseTypeId=${expenseTypeId}`
                    : `${API_PATH}/petty-expenses?isActive=true&franchiseeSchoolId=${franchiseeSchoolId}`;
            }


            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data = await response.json();
            dispatch(fetchDataSuccess(data));
        } catch (error) {
            dispatch(fetchDataFailure());
        }
    }
};

export default slice.reducer;
