import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "franchiseFollowUpListView",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state, action) => {
            state.isLoading = action.payload;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state, action) => {  
            state.isLoading = false;
            state.hasError = action.payload;
        },
        setEnquiryData: (state, action) => {
            state.isLoading = false;
            const newData = action.payload;
            
            const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

            if (existingObjectIndex !== -1) {
                state.data[existingObjectIndex] = newData;
            } 

        },
    }
});

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess, setEnquiryData } = slice.actions;

export const followUpListSlicer= () => async (dispatch, getFranchiseFollowUpListView) => {
    const { data, isLoading } = getFranchiseFollowUpListView().franchiseFollowUpListView;
    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart());

        try {
            const response = await fetch(API_PATH + '/franchise-enquires', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            })
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data1 = await response.json();
            dispatch(fetchDataSuccess(data1));
        } catch (error) {
            dispatch(fetchDataFailure())
        }
    }

}

export default slice.reducer;
