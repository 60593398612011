import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexAddress, validationRegexOnlyTextarea } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown";
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { postAddClassActivityRequest, setShowSuccessMessage, setShowErrorMessage } from './slicers/AddClassActivitySlicer';
import ViewActivityUpdate from './viewActivityUpdate'

const ClassActivityUpdates = () => {
  const [formData, setFormData] = useState({ date: '', classId: '', subjectId: '', update: '' });
  const [validated, setValidated] = useState(false);
  const [dropDowns, setDropDowns] = useState({});
  const [dropDowns1, setDropDowns1] = useState({});
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedDate] = useState(new Date());
  const [classData, setClassData] = useState([]);
  const roleIds = Number(sessionStorage.getItem("roleId"))
  const teacherId = Number(sessionStorage.getItem('userId'))
  const [error, setError] = useState(null);
  const [teacherSubject, setTeacherSubject] = useState(null);
  const [selectedDate2] = useState(new Date());
  const [formErrors, setFormErrors] = useState({ ClassActivity: false, class: false, sections: false, classesSectionsSubject: false, update: false });
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
  const Ids = Number(franchiseeSchoolId);
  const dispatch = useDispatch();
  const showSuccessMsg = useSelector((state) => state.addClassActivityUpdates.showSuccessMessage);
  const successMsg = useSelector((state) => state.addClassActivityUpdates.successMessage);
  const showErrorMsg = useSelector((state) => state.addClassActivityUpdates.showErrorMessage);
  const errorMsg = useSelector((state) => state.addClassActivityUpdates.errorMessage);
  const [submittedUpdate, setSubmittedUpdate] = useState("");
  const tokenId = token();

  const formatDate1 = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDate2 = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const classesClassSectionData =
    useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length === 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [classesClassSectionData, dispatch]);

  const handleChange = async (name, value) => {
    if (name === "classId") {
      const classIdValue = value.value || value;
      setSelectedClass(classIdValue);
      setSelectedClassId(value.classSectionId);
      setFormData({ ...formData, [name]: classIdValue });
      setFormErrors({ ...formErrors, class: false });

      if (roleIds === 6 && selectedClassData) {
        setTeacherSubject(selectedClassData.subject);
      }
    }
  };

  useEffect(() => {
    if (roleIds === 6 && selectedClassData) {
      setTeacherSubject(selectedClassData.subject);
      setFormData({ ...formData, subjectId: selectedClassData?.subject?.id })
    }
  }, [selectedClassData])

  const handleClassChange = (selectedClassId) => {
    if (roleIds === 6) {
      const classSectionData = classData.find((c) => c.id === selectedClassId);
      setSelectedClassData(classSectionData);
      if (classSectionData && classSectionData.classSections.length == 1) {
        setSelectedSection(classSectionData.classSections[0].id);
      }
    } else {
      const classSectionData = classesClassSectionData.find((c) => c.id === selectedClassId);
      setSelectedClassData(classSectionData);
      if (classSectionData && classSectionData.classSections.length == 1) {
        setSelectedSection(classSectionData.classSections[0].id);
      }
    }
  };

  useEffect(() => {
    if (selectedClass) {
      handleClassChange(selectedClass);
      setDropDowns({ ...dropDowns, sections: "" });
    }
  }, [selectedClass]);

  useEffect(() => {
    if (roleIds === 6) {
      const fetchData = async () => {
        try {
          const url = `${API_PATH}/franchisee-school/${Ids}/teachers/${teacherId}/classes`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch classes data. Status: ${response.status}`);
          }
          const result = await response.json();
          setClassData(result);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    }
  }, [Ids, teacherId, tokenId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedClass) {
          let classIds = Array.isArray(selectedClass) ? selectedClass : [selectedClass];
          const promises = classIds.map(async (classId) => {
            const url = `${API_PATH}/class/${classId}/subjects`;
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch data for class ID: ${classId}`);
            }

            return response.json();
          });

          const responses = await Promise.all(promises);
          const allSubjects = responses.flatMap((responseData) => responseData);
          setSubjects(allSubjects);
        }
      } catch (error) {
        console.error("Error fetching subjects data:", error);
      }
    };
    if (roleIds === 6) {
      handleChange();
    } else {
      fetchData();
    }
  }, [selectedClass, roleIds, tokenId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newFormErrors = {
      class: !dropDowns.class,
      sections: !dropDowns.sections,
      classesSectionsSubject: !dropDowns1.classesSectionsSubject,
      update: !formData.update,
      subjectId: formData.subjectId,
    };

    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some((error) => error);

    if (hasErrors) {
      setValidated(true);
      // return
    }

    const sendData = {
      date: formatDate2(selectedDate2),
      classSectionId: selectedSectionId ? selectedSectionId : selectedClassId,
      subjectId: formData.subjectId,
      update: formData.update,
    };

    let apiParams = roleIds === 6 ? [Ids, sendData] : [Ids, sendData];
    dispatch(postAddClassActivityRequest(...apiParams));
    setSubmittedUpdate(formData.update);
  };

  const handleClear = () => {
    setFormData({ name: '' });
    setFormErrors({ programId: false });
    setValidated(false);
    setDropDowns({});
    setDropDowns1({});
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  useEffect(() => {
    if (selectedClassData?.classSections?.length == 0) {
      setSelectedSectionId(null)
    }
  }, [selectedClassData])

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <div className="content-header">Class Activity Updates</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              className='enquiryEntryDate'
              value={formatDate1(selectedDate)}
            >
            </TextInputField>
          </Col>

          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              label="Class"
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : null
              }
              placeholder="Select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              options={roleIds === 6 && classData
                ? classData.map((classItem) => ({
                  value: classItem.id,
                  label: classItem.name,
                  classSectionId: classItem?.classesclassSection ? classItem?.classesclassSection : null
                }))
                : classesClassSectionData.map((classItem) => ({
                  value: classItem.id,
                  label: classItem.name,
                  classSectionId: classItem?.classesclassSection ? classItem?.classesclassSection : null
                }))
              }
              required
              feedbackMessage="Please select a valid class"
            />
            {formErrors.class && (
              <div className="error-message">Please select a valid class.</div>
            )}
          </Col>

          {formData.classId &&
            selectedClassData &&
            selectedClassData?.classSections?.length > 0 && (
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <SelectDropdown
                  label="Section"
                  value={
                    dropDowns.sections
                      ? {
                        value: dropDowns.sections,
                        label: dropDowns.sections,
                      }
                      : dropDowns.sections
                  }
                  placeholder="Select section"
                  onChange={(selectedOption) => {
                    handleChange("sectionId", selectedOption);
                    setSelectedSectionId(selectedOption.value)
                    setSelectedSection(selectedOption.value);
                    setDropDowns({
                      ...dropDowns,
                      sections: selectedOption.label,
                    });
                    setFormErrors({ ...formErrors, sections: false });
                  }}
                  options={selectedClassData.classSections?.map((section) => ({
                    key: section.id,
                    value: section.classesclassSection,
                    label: section.name,
                  }))}
                  required
                  feedbackMessage="Please select a valid section"
                />
                {formErrors.sections && (
                  <div className="error-message">Please Select Section.</div>
                )}
              </Col>
            )
          }

          {roleIds !== 6 && (
            <Col xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                label="Subject"
                value={dropDowns1.classesSectionsSubject}
                placeholder="Select subject"
                options={subjects.map((subject) => ({
                  value: subject.id,
                  label: subject.name,
                }))}
                onChange={(selectedOption) => {
                  handleChange("subjectId", selectedOption);
                  setFormData({
                    ...formData,
                    subjectId: selectedOption.value,
                  });
                  setDropDowns1({ ...dropDowns1, classesSectionsSubject: selectedOption });
                  setFormErrors({ ...formErrors, classesSectionsSubject: false });
                }}
                required
                feedbackMessage="Please select a valid subject"
              />
              {formErrors.classesSectionsSubject && (
                <div className="error-message">Please Select subject.</div>
              )}
            </Col>
          )}

          {roleIds == 6 && (
            <Col xs={12} md={6} lg={4} className="rowCss">
              <TextInputField
                name="subject"
                placeholder="subject"
                textType="Alphabet"
                disabled={roleIds === 6 ? true : false}
                value={teacherSubject ? teacherSubject.name : ''}
              />
            </Col>
          )}

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="update"
              placeholder="Update*"
              textType="Alphabet"
              required
              value={formData.update}
              onChange={(event) => {
                setFormData({ ...formData, update: event.target.value });
                setFormErrors({ ...formErrors, update: false });
              }}
              feedbackMessage="Please enter update here."
              validationRegex={validationRegexAddress}
            />
            {formErrors.update && (
              <div className="error-message">Please enter update here..</div>
            )}
          </Col>
        </Row>

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <br></br>
      <br></br>
        <p className="content-header">View</p>
        <ViewActivityUpdate />
    </>
  );
};

export default ClassActivityUpdates;
