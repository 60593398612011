import React, { useEffect, useState } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { ViewRoyaltyAmountDetails, fetchSchoolDataOnce } from "./slice/viewRoyaltyAmountSlice";
import { FetchFranchiseSchools } from "../Franchise/slice/schoolDataGet";
import { postSchoolRequest } from "./slice/royaltyAmountSlice";
import { editSlice } from "./slice/editSlice";

import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { setShowErrorMessage, setShowSuccessMessage } from './slice/editSlice'

const ViewRoyaltyAmount = () => {

  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});

  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "franchiseeSchool", header: "School Name" },
    { field: "amount", header: "Amount" },
    { field: "edit", header: "Edit" },
  ];


  const apiData = useSelector((state) => state.viewRoyaltyAmount.data) || [];
  const postData = useSelector((state) => state.royaltyAmount.data) || [];



  const data = useSelector((state) => state.franchiseSchools.data) || [];
  const editData = useSelector((state) => state.editRoyalAmount.data) || [];


  const showSuccessMsg = useSelector((state) => state.editRoyalAmount.showSuccessMessage);
  const successMsg = useSelector((state) => state.editRoyalAmount.successMessage);

  const showErrorMsg = useSelector((state) => state.editRoyalAmount.showErrorMessage);
  const errorMsg = useSelector((state) => state.editRoyalAmount.errorMessage);

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ViewRoyaltyAmountDetails());
    dispatch(FetchFranchiseSchools())
  }, [dispatch])

  let newModifiedData = []

  if (postData && postData.length > 0) {
    postData.forEach((postItem) => {
      if (postItem && postItem.franchiseeSchoolId) {
        const modifiedData = postItem;
        const findObject = data.find((obj) => obj.id == postItem.franchiseeSchoolId);
        const newData = {
          ...modifiedData,
          franchiseeSchool: findObject,
        };
        newModifiedData.push(newData);
      }
    });
  }

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
  };


  const combinedData = [...(apiData || []), ...(newModifiedData)];

  const newObj = editData;

  function replaceObjects(array, newObjects) {
    return array.map(obj => {
      const replacement = newObjects.find(newObj => newObj.id === obj.id);
      return replacement ? replacement : obj;
    });
  }

  const modifyArray = []

  if (newObj && newObj.length > 0) {
    newObj.forEach((postItem) => {
      if (postItem && postItem.franchiseeSchoolId) {
        const modifiedData = postItem;
        const findObject = data.find((obj) => obj.id == postItem.franchiseeSchoolId);
        const newData = {
          ...modifiedData,
          franchiseeSchool: findObject,
        };
        modifyArray.push(newData);
      }
    });
  }

  const combinedData1 = replaceObjects(combinedData, modifyArray);


  const processedData = combinedData1.map((row, index) => ({
    ...row,
    index: index + 1,
    franchiseeSchool: row.franchiseeSchool ? row.franchiseeSchool.name : "",
  }));


  const handleUpdatedTable = (id, data) => {
    dispatch(editSlice(id, data))
  }







  return (
    <>
      {/* <div className="content-header">View Royalty Amount </div> */}
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={processedData} columns={columns}
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            editedData={editedData}
            setEditedData={setEditedData}
            handleUpdate={handleUpdatedTable}
          />

        )
      }
      <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
    </>
  );
}
export default ViewRoyaltyAmount;
