/*
    authors: Rajeev
    date: 20-12-2023
    update:20-12-2023 
    version: 1.0
*/

import { createSlice } from '@reduxjs/toolkit';
import { token } from "../../../Api/token";
import { API_PATH } from "../../../Api/api";
import { responseFormat } from '../../Auth/errorResponse';




const slice = createSlice(
    {
        name: 'addTimings',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            successMessage: '',
            showSuccessMessage: false,
            errorMessage: '',
            showErrorMessage: false,
        },
        reducers: {
            fetchTimingData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchTimingSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchTimingFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setEmptyTimingsData: (state, action) => {
                state.data = action.payload;
            },
        },
    }


);

export const { fetchTimingData, fetchTimingSuccess, fetchTimingFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage, setEmptyTimingsData } = slice.actions;


export const postTimingRequest = (postData) => async (dispatch) => {
    dispatch(fetchTimingData());
    const tokenId = token();
    try {
        // Make your POST request here using the fetch function or an HTTP library like Axios
        const response = await fetch(API_PATH + "/timing", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${tokenId}`
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
            throw new Error('Request failed'); // You can customize error handling
        } else {
            const responseData = await response.json();
            dispatch(fetchTimingSuccess([responseData.data]));
            dispatch(setSuccessMessage("Successfull!"));
            dispatch(setShowSuccessMessage(true));
        }

    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};


export default slice.reducer;
