import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { validationRegexOnlyAlpha } from "../../ReusableFields/Rejex";
import TextInputField from "../../ReusableFields/TextFields/TextInputField"
import { useSelector, useDispatch } from 'react-redux';
import SuccessMessagePopup from "../../components/reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../components/reUsableForm/pop-card/errorMessage";
import {FoodItemsPost, setShowSuccessMessage, setShowErrorMessage } from './slice/FoodItemPostSlice';


const FoodItems = () => {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        itemName: '',
    })
    const [formErrors, setFormErrors] = useState({
        itemName: false,
    });

    const dispatch = useDispatch();

    const showSuccessMsg = useSelector((state) => state.FoodItemPost.showSuccessMessage);
    const successMsg = useSelector((state) => state.FoodItemPost.successMessage);

    const showErrorMsg = useSelector((state) => state.FoodItemPost.showErrorMessage);
    const errorMsg = useSelector((state) => state.FoodItemPost.errorMessage);

    const handleSubmit = (event) => {
        event.preventDefault();
        const newFormErrors = {
   
            password: !formData.itemName,
        };
        // Check if there are any errors
        const hasErrors = Object.values(newFormErrors).some(error => error);

        // Set the formErrors state
        setFormErrors(newFormErrors);

        // If there are errors, return without dispatching the action
        if (hasErrors) {
            setValidated(true);
            return;
        }

        dispatch(FoodItemsPost(formData));

    }

    const handleCancel = () => {
        setFormData({
            password: '',
        });

        setFormErrors({
            password: false,
        });

        setValidated(false);

    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };


    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='content-header'>Food Items</div>
                <Row className='mt-4'>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            className="input-outline"
                            placeholder="Food Item"
                            name="itemName"
                            textType="Alphabet"
                            value={formData.itemName}
                            onChange={(event) => {
                                const inputValue = event.target.value;
                                setFormData({ ...formData, itemName: inputValue })
                                setFormErrors({ ...formErrors, itemName: false });
                            }}
                            required
                            colSize={4}
                            validated={validated}
                            feedbackMessage="Please enter item name."
                            validationRegex={validationRegexOnlyAlpha}
                            validationErrorMessage="Please enter a valid item name."
                        />

                    </Col>

                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div style={{ display: "flex", justifyContent: "center", marginLeft: "-14%" }}>
                    <button className='clear-button-figma-div' type='button' onClick={handleCancel}>Clear</button>
                    <button className='save-button-figma-div' type='submit'>Submit</button>
                </div>
            </Form>
        </div>
    )
};
export default FoodItems;