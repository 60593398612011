import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";

const slice = createSlice({
    name: "createLane",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;

        },
        setLineViewData: (state, action) => {
            state.isLoading = false;
            const newData = action.payload;

            const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

            if (existingObjectIndex !== -1) {
                state.data[existingObjectIndex] = newData;
            }

        },
    }
});

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess, setLineViewData, } = slice.actions;

export const fetchCreateLaneView = () => async (dispatch, getLaneView) => {
    const { data, isLoading } = getLaneView().createLane;
    const tokenId = token();

    if (data === null && !isLoading) {
        dispatch(fetchDataStart());

        try {
            const response = await fetch(API_PATH + '/lanes', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data = await response.json();
            dispatch(fetchDataSuccess(data));
            dispatch(setLineViewData(data))

        } catch (error) {

            dispatch(fetchDataFailure())
        }
    }
}
export default slice.reducer;
