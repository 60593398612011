// SkillsAssessmentModal.js
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const SkillsAssessmentModal = ({ show, onHide, skillsAssessments }) => {
  return (
    <Modal show={show} onHide={onHide} className="modal" size="x2" style={{ marginTop: '5rem' }}>
      <Modal.Header closeButton>
        <Modal.Title>Skills Assessments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", overflowX: "auto" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Skill Name</th>
              <th>Grade</th>
            </tr>
          </thead>
          {skillsAssessments.length > 0 ? (
            <tbody>
              {skillsAssessments && skillsAssessments.map((assessment, index) => (
                <tr key={index}>
                  <td>{assessment.assessmentSkill.name}</td>
                  <td>{assessment.grade}</td>
                </tr>
              ))}

            </tbody>
          ) : (
            <center style={{marginLeft: '75px'}}>
              <p>No Data</p>
            </center>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SkillsAssessmentModal;
