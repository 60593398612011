import React, { useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import {
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    Button,
    TextField,
    MenuItem,
    Select,
    ButtonBase,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Image as ImageView } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { DownloadOutlined } from "@ant-design/icons";
import { Image } from "@mui/icons-material";
import { CloudDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';



const ExpenseEntryTable = ({
    data = null,
    columns = null,
}) => {
    const dispatch = useDispatch();

    // const employeeViewList = useSelector((state) => state.employeeSlicer.teachers.employee.employeeViewList)
    // const editedRowValue = useSelector((state) => state.employeeSlicer.teachers.employee.employeeRow)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState(columns && columns[0] ? columns[0].field : "");
    const [statusValues, setStatusValues] = useState({});
    const [commentsValues, setCommentsValues] = useState({});
    const [showImageView, setShowImageView] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDocumentPopup, setShowDocumentPopup] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedRow, setEditedRow] = useState(null);

    const [editedData, setEditedData] = useState({});
    const getCaps = (head, field) => (head ? head.toUpperCase() : field.toUpperCase());

    const handleEditClick = (row) => {
        setEditedData(row);
        setShowEditModal(true);
    };

    const handleEditSave = () => {

        setShowEditModal(false);

        // if (editedData.id) {
        //     handleUpdate(editedData.id, editedData);
        //     setShowEditModal(false);
        // }
    };

    const handleViewClick = (row) => {
        setSelectedRow(row);
        setShowViewModal(true);
    };
    const downloadDocument = (url, name) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
    };
    const handleImageViewClick = (image) => {
        setSelectedImage(image);
        setShowImageView(true);
    };

    const handleStatusChange = (rowId, event) => {
        setStatusValues((prevStatusValues) => ({
            ...prevStatusValues,
            [rowId]: event.target.value,
        }));
    };

    const handleCommentsChange = (rowId, event) => {
        setCommentsValues((prevCommentsValues) => ({
            ...prevCommentsValues,
            [rowId]: event.target.value,
        }));
    };
    const saveHandle = (row) => {
        if (row.id) {
            const updatedData = {
                id: row.id,
                date: row.date,
                guardianName: row.guardianName,
                studentName: row.studentName,
                contactNumber: row.contactNumber,
                reference: row.reference,
                classId: row.classId,
                status: statusValues[row.id],
                description: commentsValues[row.id],
            };

        }
    }

    const handleSaveClick = (rowId, date, guardianName, studentName, contactNumber, reference, classId) => {
        if (rowId) {
            const updatedData = {
                id: rowId,
                date: date,
                guardianName: guardianName,
                studentName: studentName,
                contactNumber: contactNumber,
                reference: reference,
                classId: classId,
                status: statusValues[rowId],
                description: commentsValues[rowId],
            };

            // Dispatch the updateEnquiry action here

            // Call your API function to update the data
        }
    };


    const handleDocumentIconClick = (docList) => {
        setDocuments(docList);
        setShowDocumentPopup(true);
    };

    const handleCloseDocumentPopup = () => {
        setShowDocumentPopup(false);
    };
    const handleSortClick = (column) => {
        if (column === "edit") {
            return;
        }

        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    // const handleSearchChange = (e) => {
    //     setSearchQuery(e.target.value);
    //     setCurrentPage(1);
    // };

    const filteredData = data.filter((row) => {
        const searchValue = searchQuery.toLowerCase();

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnValue = row[column.field];

            if (typeof columnValue === "string") {
                if (columnValue.toLowerCase().includes(searchValue)) {
                    return true;
                }
            } else if (typeof columnValue === "number") {
                if (columnValue.toString().includes(searchValue)) {
                    return true;
                }
            }
        }

        return false;
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (typeof valueA === "string" && typeof valueB === "string") {
            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        } else {
            if (sortOrder === "asc") {
                return valueA < valueB ? -1 : 1;
            } else {
                return valueB < valueA ? -1 : 1;
            }
        }
    });

    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = sortedData.slice(0, 10)
    const filteredColumns = columns.filter(
        (col) => !["id", "view", "edit", "delete"].includes(col.field)
    );

    const renderCommentInput = () => {
        return (
            <input
                placeholder="add comments"
            />

        )
    }

    const renderAction = () => {
        return (
            <button className="table-button">
                Submit
            </button>
        )
    }


    // const handleEditSave = () => {
    //     if (editedData.id) {
    //         handleUpdate(editedData.id, editedData);
    //         setShowEditModal(false);
    //     }
    // };
    return (
        <div className="table-Maindiv">
            {/* <TextField
                placeholder="Search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "10px" }}
            /> */}
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns &&
                                columns.map((head) => (
                                    <TableCell sx={{ backgroundColor: "#E2E8F0", color: "#464E5F", fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", textAlign: "left", whiteSpace: "nowrap",  padding: "8px", }} key={head.field} className="tablehead">
                                        <TableSortLabel
                                            active={sortColumn === head.field}
                                            direction={sortOrder}
                                            onClick={() => handleSortClick(head.field)}
                                        >
                                            {(head.field, head.header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map((row, rowIndex) => (
                            // {sortedData.map((row, rowIndex) => (

                            <TableRow key={row.id}>
                                {columns.map((col) => (
                                    <TableCell key={col.field} className="tablecell">
                                        {col.field === "view" ? (
                                            <button onClick={() => handleViewClick(row)}>
                                                View
                                            </button>
                                        ) : col.field === "save" ? (
                                            <Button onClick={() => saveHandle(row)} style={{ backgroundColor: "#112F6E", color: "white", borderRadius: "10px" }}>Save</Button>

                                        ) : col.field === "statusDropdown" ? (
                                            <Select
                                                value={statusValues[row.id] || 'interested'} // Set the selected value from statusValues
                                                onChange={(e) => handleStatusChange(row.id, e)}
                                                sx={{ minWidth: '150px' }}
                                            >
                                                <MenuItem value="Already joined Somewhere Else">Already joined Somewhere Else</MenuItem>
                                                <MenuItem value="On Hold">On Hold</MenuItem>
                                                <MenuItem value="Not Interested">Not Interested</MenuItem>
                                                <MenuItem value="interested">interested</MenuItem>
                                            </Select>
                                        ) : col.field === "comments" ? (
                                            <textarea
                                                value={commentsValues[row.id] || ""}
                                                onChange={(e) => handleCommentsChange(row.id, e)}
                                            />
                                        ) : col.field === "action" ? (
                                            <button onClick={() => handleSaveClick(row.id, row.date, row.guardianName, row.studentName, row.contactNumber, row.reference, row.classId)}>Save</button>

                                        ) : col.field === "image" ? (
                                            <IconButton
                                                onClick={() => handleImageViewClick(row.image)}
                                                color="primary"
                                                size="small"
                                            >
                                                <ImageView />
                                            </IconButton>
                                        )
                                            : col.field === "documents" ? (
                                                <IconButton
                                                    onClick={() => handleDocumentIconClick(row.documents)}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ImageView />
                                                </IconButton>
                                            ) :

                                                col.field === "edit" ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditClick(row)
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )
                                                    : col.field === "view" ? (
                                                        <IconButton
                                                            onClick={() => { handleViewClick(row) }}
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    ) : col.field === "image" ? (
                                                        <IconButton
                                                            onClick={() => handleImageViewClick(row.image)}
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <ImageView />
                                                        </IconButton>
                                                    )
                                                        : col.field === "documents" ? (
                                                            <IconButton
                                                                onClick={() => handleDocumentIconClick(row.documents)}
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                <UploadFileIcon />
                                                            </IconButton>
                                                        ) :
                                                            col.field === "commentInput" ? (renderCommentInput(row))
                                                                : col.field === "action" ? (renderAction(row))
                                                                    : (
                                                                        row[col.field]
                                                                    )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data ? null : <p>No data found</p>}

            {/* <Pagination
                className="pagination-css"
                count={Math.ceil(sortedData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
            /> */}
            {/* </Box> */}

            <Modal show={showImageView} onHide={() => setShowImageView(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && <img src={selectedImage} alt="Selected Image" style={{ width: "100%" }} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowImageView(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDocumentPopup} onHide={handleCloseDocumentPopup} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {documents && documents.length > 0 ? (
                        <ul>
                            {documents.map((document, index) => (
                                <li key={index} style={{ margin: '5px' }}>
                                    {/* <a href={document.url} target="_blank" rel="noopener noreferrer">
              {document.name}
            </a> */}
                                    <img src={document} height='80px' width='80px' />
                                    <Button
                                        onClick={() => downloadDocument(document, document.name)}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CloudDownload />}
                                        size="small"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Download
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No documents available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDocumentPopup}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>View Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {selectedRow &&
                            filteredColumns.map((col) => (
                                <Form.Group key={col.field} className="mb-3">
                                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                    <Form.Control type="text" value={selectedRow[col.field]} readOnly />
                                </Form.Group>
                            ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowViewModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} className="modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        {editedData ?
                            filteredColumns.map((col) => (
                                <Form.Group key={col.field} className="mb-3">
                                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                                    {col.field === "image" ? (
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;

                                                const imageURL = URL.createObjectURL(files[0]);
                                                // alert(<img src={imageURL}/>)
                                                setEditedData({ ...editedData, image: imageURL })
                                            }}
                                        />
                                    ) : col.field === "documents" ? (
                                        <input
                                            type="file"
                                            multiple
                                            onChange={(e) => {
                                                // Handle multiple document upload logic here
                                                const files = e.target.files;
                                                let editDocuments = []
                                                for (let i = 0; i < files.length; i++) {
                                                    const imageURL = URL.createObjectURL(files[i]);
                                                    editDocuments.push(imageURL)
                                                }

                                                // alert(<img src={imageURL}/>)
                                                setEditedData({ ...editedData, documents: editDocuments })

                                            }}
                                        />
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            value={editedData[col.field]}
                                            onChange={(e) => {
                                                setEditedData({
                                                    ...editedData,
                                                    [col.field]: e.target.value,
                                                })
                                            }
                                            }
                                        />
                                    )}
                                </Form.Group>
                            )) : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowEditModal(false)}
                        style={{
                            color: "white",
                            backgroundColor: "#b0aeac",
                            fontSize: "12px",
                            marginRight: "10px",
                            borderRadius: "20px",
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                        style={{
                            color: "white",
                            backgroundColor: "#007bff",
                            fontSize: "12px",
                            borderRadius: "20px",
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ExpenseEntryTable;

