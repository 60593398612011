import React, { useEffect, useState } from 'react'
import TextInputField from '../reUsableForm/textInputField';
import { validationRegexAlphaNumeric } from '../reUsableForm/regex';
import { Form } from 'react-bootstrap';
import { postCompetetionName } from './slicerCompetetion/requestsCompetetion';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { CLSTICompetetionName } from './slicerCompetetion/competitionAwardsSlicer'; 
const CompetetionName = () => {
  const dispatch = useDispatch();
  const postCompetetionNameObj = useSelector(state=>state.competetionAwardsReducer.postCompetetionName);
  const loading = postCompetetionNameObj.loading;

 
    const defaultData = {name:''}
    const [formData,setName] = useState(defaultData);
    const [validated,setValidated] = useState(false);
   const [showSuccessMsg,setShowSuccessMsg] = useState(false);
   const [showErrorMsg,setShowErrorMsg] = useState(false);

   const storeResponse = ()=>{
    if(loading === 'fulfilled'){
      setShowSuccessMsg(true)
    }else if(loading === 'rejected'){
      setShowErrorMsg(false)
    }
  }
  useEffect(()=>{
    storeResponse()
  },[loading])
    const handleClear = ()=>{
        setName(defaultData);
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    dispatch(postCompetetionName(formData))
    }
    return (
        <>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div style={{display:"flex",flexDirection:"column",height:"70vh",alignItems:"center",justifyContent:"center"}}>
            <div>   
            <TextInputField
                name='name'
                placeholder='Enter Competetion Name'
                textType='Alphabet'
                validated={validated}
                required='true'
                maxLength={25}
                feedbackMessage='Name is Required!'
                validationErrorMessage='Give valid Name!'
                value={formData.name}
                validationRegex={validationRegexAlphaNumeric}
                onChange={(e)=>setName({...formData,name:e.target.value})}
            />
            </div>
            {
              loading === 'fulfilled' &&
              showSuccessMsg &&
              <SuccessMessagePopup show={showSuccessMsg} onClose={() => { dispatch(CLSTICompetetionName()); setShowSuccessMsg(false) }} successMessage="Competetion Name submitted successfully" />

              //add handleClear function to successpopup in the last!
            }
            {

              loading === 'rejected' &&
              showErrorMsg &&
              <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(CLSTICompetetionName()); setShowErrorMsg(false) }}
                errorMessage={Array.isArray(postCompetetionNameObj?.error) ?
                  postCompetetionNameObj?.error?.map(e => { return <li>{e.message}</li> }) :
                  postCompetetionNameObj?.error} />}
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
              >
                Clear
              </button>
            <button className="save-button-figma-div" type="submit">
                Submit
              </button>
            </div>
              </div>
            </Form>
        </>
    )
}

export default CompetetionName;