import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DropdownField from "../../reUsableForm/dropDownField";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnceProgram } from "../../programs/slice/viewProgramSlice";
import { Class } from "@mui/icons-material";
import { SelectingValuesDropDownField } from "../../../ReusableFields/DropDown/multipleSelectDropDown";
import { fetchDataOnce } from "../../Classes/slice/viewClassSlice";
import { fetchDueDateGet } from "../FeeStructureSlice/feeDueDateGet";
import { FetchFranchiseSchools } from "../../Franchise/slice/schoolDataGet";
import { fetchFrequencyDataOnce } from "../../Frequency/slice/viewFrequencySlice";
import SearchableDropDown from "../../../ReusableFields/DropDown/searchableDroDown";
import SearchableDropDownField from "../../../ReusableFields/DropDown/SearchableDropDownField";
import { fetchTimingDataOnce } from "../../TimeTableDetails/slicer/viewTimingSlicer";
import { fetchFeeHead } from "../FeeStructureSlice/feeHeadGet";
import TextInputField from "../../reUsableForm/textInputField";
import {
  validationRegexAmount,
  validationRegexOnlyAlpha,
} from "../../reUsableForm/regex";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";
import {
  ShoolFeeCreation,
  setShowErrorMessage,
  setShowSuccessMessage,
} from "../FeeStructureSlice/feeMasterPost";
import CustomTableRow from "./customTable";
import SchoolFeeDetails from "./SchoolFeeDetails";
import { PostDueDates } from "../FeeStructureSlice/feeDueDatePost";
import { fetchDataGetProgramBySchoolId } from "../../programs/slice/getProgramBySchoolId";
import { fetchDataGetClassesByProgramId } from "../../programs/slice/getClassByProgramId";

export const FeeStructure = () => {
  const [formData, setFormData] = useState({
    classesId: [],
    classesName: [],
    trueValues: {},
  });

  const [validated, setValidated] = useState(false);
  const [termDueAmount, setTermDueAmount] = useState("");
  const [oneTimeDueAmount, setOneTimeDueAmount] = useState("");
  const [monthlyDueAmount, setMonthlyDueAmount] = useState("");
  const [biMonthlyDueAmount, setBiMonthlyDueAmount] = useState("");
  const [halfYearlyDueAmount, setHalfYearlyDueAmount] = useState("");
  const [termFeeDates, setTermFeeDates] = useState([]);

  const [formErrors, setFormErrors] = useState({
    academicYearId: false,
    feeHeadId: false,
    franchiseeSchoolId: false,
    programId: false,
    dayFrequencyId: false,
    timingId: false,
    classesId: false,
    classesName: false,
  });

  let currentYear = new Date().getFullYear();
  let AcademicYear = [];

  AcademicYear.push({ id: 1, year: `${currentYear}-${currentYear + 1}` });
  for (let i = 2; i <= 6; i++) {
    currentYear++;
    AcademicYear.push({ id: i, year: `${currentYear}-${currentYear + 1}` });
  }


  const programsData = useSelector((state) => state.getProgramBySchoolId.data) || [];


  const classes = useSelector((state) => state.getClassesByProgramId.data) || [];



  const getBranch = useSelector((state) => state.franchiseSchools.data) || [];
  const postBranch = useSelector((state) => state.schoolCreation.data) || [];

  const Branch = [...(getBranch || []), ...postBranch];

  const getDayFrequency = useSelector((state) => state.dayFrequency.data) || [];
  const postDayFrequency =
    useSelector((state) => state.frequencySlice.data) || [];

  const DayFrequency = [...(getDayFrequency || []), ...postDayFrequency];

  const getTimings = useSelector((state) => state.viewTimings.data) || [];
  const postTimings = useSelector((state) => state.addTimings.data) || [];

  const timings = [...(getTimings || []), ...postTimings];

  const feeHeadDetails =
    useSelector((state) => state.feeHeadDetails.data) || [];
  const postFeeHead = useSelector((state) => state.feeHead.data) || [];

  const FeeHeadData = [...(feeHeadDetails || []), ...postFeeHead];

  const dueDateData = useSelector((state) => state.feeDueDates.data) || [];
  const PostDueDateData = useSelector((state) => state.feeDueDate.data) || [];

  const combinedData = [...(dueDateData || []), ...PostDueDateData];

  const SelectAcademicYear =
    AcademicYear.find((x) => x.id === formData.academicYearId) || [];
  const selectBranch =
    Branch.find((x) => x.id == formData.franchiseeSchoolId) || [];
  const selectProgram =
    programsData.find((x) => x.id == formData.programId) || [];
  const selectFrequency =
    DayFrequency.find((x) => x.id == formData.dayFrequencyId) || [];
  const selectTimings = timings.find((x) => x.id == formData.timingId) || [];

  useEffect(() => {
    if (formData.feeHeadId) {
      const selectFeeHead1 =
        combinedData.find((x) => x.dueDate.feeHeadId == formData.feeHeadId) ||
        [];
      setTermFeeDates(selectFeeHead1);
    }
  }, [formData.feeHeadId]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDataOnceProgram());
    dispatch(fetchDataOnce());
    dispatch(FetchFranchiseSchools());
    dispatch(fetchFrequencyDataOnce());
    dispatch(fetchTimingDataOnce());
    dispatch(fetchFeeHead());
    dispatch(fetchDueDateGet());
  }, [dispatch]);


  useEffect(() => {
    if (formData.franchiseeSchoolId) {

      dispatch(fetchDataGetProgramBySchoolId(formData.franchiseeSchoolId))
    }
  }, [formData.franchiseeSchoolId])

  useEffect(() => {
    if (formData.programId) {

      dispatch(fetchDataGetClassesByProgramId(formData.programId))
    }
  }, [formData.programId])


  useEffect(() => {
    if (formData.feeHeadId) {
      const result = FeeHeadData.find(
        (item) =>
          item.id == formData.feeHeadId &&
          Object.entries(item).some(
            ([key, value]) => key !== "isActive" && value === true
          )
      );
      const trueValues = Object.entries(result)
        .filter(([key, value]) => key !== "isActive" && value === true)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      setFormData({ ...formData, trueValues });
    }
  }, [formData.feeHeadId]);


  function formatDate(inputDate) {

    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (!dateFormatRegex.test(inputDate)) {
      return inputDate;
    }

    
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const newFormErrors = {
      academicYearId: !formData.academicYearId,
      feeHeadId: !formData.feeHeadId,
      programId: !formData.programId,
      classesId: !formData.classesId || formData.classesId.length === 0,
      dayFrequencyId: !formData.dayFrequencyId,
      timingId: !formData.timingId,
      franchiseeSchoolId: !formData.franchiseeSchoolId,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    if (hasErrors) {
      setValidated(true);
      return;
    }

    const value = Object.keys(formData.trueValues)[0];

    let sendData;
    if (value === "term") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: termDueAmount.termFirstDueAmount || null,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[1]?.date ?? "",
            amount: termDueAmount.termSecondDueAmount || null,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[2]?.date ?? "",
            amount: termDueAmount.termThirdDueAmount || null,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[3]?.date ?? "",
            amount: termDueAmount.termFourDueAmount || null,
          },
        ],
        feeDuration: "term",
      };
    } else if (value === "oneTimeAtAdmission") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: oneTimeDueAmount.oneTimeDueAmount,
          },
        ],
        feeDuration: "oneTimeAtAdmission",
      };
    } else if (value === "yearly") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: oneTimeDueAmount.oneTimeDueAmount,
          },
        ],
        feeDuration: "yearly",
      };
    } else if (value === "monthly") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: monthlyDueAmount.monthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[1]?.date ?? "",
            amount: monthlyDueAmount.secondMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[2]?.date ?? "",
            amount: monthlyDueAmount.thirdMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[3]?.date ?? "",
            amount: monthlyDueAmount.fourthMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[4]?.date ?? "",
            amount: monthlyDueAmount.fifthMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[5]?.date ?? "",
            amount: monthlyDueAmount.sixthMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[6]?.date ?? "",
            amount: monthlyDueAmount.seventhMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[7]?.date ?? "",
            amount: monthlyDueAmount.eightsMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[8]?.date ?? "",
            amount: monthlyDueAmount.ninthMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[9]?.date ?? "",
            amount: monthlyDueAmount.tenthMonthDueAmount,
          },
        ],

        feeDuration: "monthly",
      };
    } else if (value === "biMonthly") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: biMonthlyDueAmount.firstAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[1]?.date ?? "",
            amount: biMonthlyDueAmount.secondMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[2]?.date ?? "",
            amount: biMonthlyDueAmount.thirdMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[3]?.date ?? "",
            amount: biMonthlyDueAmount.fourthMonthDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[4]?.date ?? "",
            amount: biMonthlyDueAmount.fifthMonthDueAmount,
          },
        ],

        feeDuration: "biMonthly",
      };
    } else if (value === "halfYearly") {
      sendData = {
        ...formData,
        fees: [
          {
            dueDate: termFeeDates?.dueDate?.dueDates[0]?.date ?? "",
            amount: halfYearlyDueAmount.termFirstDueAmount,
          },
          {
            dueDate: termFeeDates?.dueDate?.dueDates[1]?.date ?? "",
            amount: halfYearlyDueAmount.termSecondDueAmount,
          },
        ],

        feeDuration: "halfYearly",
      };
    }
    dispatch(ShoolFeeCreation(sendData));
  };

  const showSuccessMsg = useSelector(
    (state) => state.schoolFee.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.schoolFee.successMessage);

  const showErrorMsg = useSelector((state) => state.schoolFee.showErrorMessage);
  const errorMsg = useSelector((state) => state.schoolFee.errorMessage);

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleCancel();
  };

  const handleCancel = () => {
    setFormData({
      ...formData,
      classesId: [],
      classesName: [],
      trueValues: {},
    });
    setTermDueAmount("");
    setOneTimeDueAmount("");
    setTermFeeDates([]);
    setFormErrors({ ...formErrors, classesId: false });

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header" style={{ margin: "1rem" }}>
          Fee Master
        </div>
        <Row>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              value={SelectAcademicYear.year}
              className="input-style"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  academicYearId: selectedOption.id,
                  academicYear: selectedOption.value,
                });
                setFormErrors({ ...formErrors, academicYearId: false });
              }}
              placeholder="Academic Year"
              options={AcademicYear.map((option, index) => ({
                id: option.id,
                value: option.year,
                label: option.year,
              }))}
              feedbackMessage="Please select a Academic Year."
              validated={validated}
            />
            {formErrors.academicYearId && (
              <div className="error-message">Please Select Academic Year .</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              className="input-style"
              value={selectBranch.name}
              validated={validated}
              placeholder="Branch"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  franchiseeSchoolId: Number(selectedOption.id),
                });
                setFormErrors({ ...formErrors, franchiseeSchoolId: false });
              }}
              // required
              options={Branch.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select Branch."
            />
            {formErrors.franchiseeSchoolId && (
              <div className="error-message">Please Select Branch .</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              className="input-style"
              value={selectProgram.name}
              validated={validated}
              placeholder="Program"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  programId: Number(selectedOption.id),
                });
                setFormErrors({ ...formErrors, programId: false });
              }}
              // required
              options={programsData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select Program."
            />
            {formErrors.programId && (
              <div className="error-message">Please Select Program .</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              className="input-style"
              value={selectFrequency.name}
              validated={validated}
              placeholder="Frequency"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  dayFrequencyId: Number(selectedOption.id),
                });
                setFormErrors({ ...formErrors, dayFrequencyId: false });
              }}
              // required
              options={DayFrequency.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select Frequency."
            />
            {formErrors.dayFrequencyId && (
              <div className="error-message">Please Select DayFrequency .</div>
            )}
          </Col>
          {/* </Row> */}
          {/* <Row> */}
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectingValuesDropDownField
              isMulti={true}
              value={formData.classesName}
              className="input-style"
              onChange={(selectedOptions) => {
                const selectedIds = selectedOptions.map(
                  (selectedOption) => selectedOption.id
                );
                setFormData({
                  ...formData,
                  classesId: selectedIds,
                  classesName: selectedOptions,
                });
                setFormErrors({ ...formErrors, classesId: false });
              }}
              placeholder="Select classes"
              options={classes.map((option) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select a class."
            />
            {formErrors.classesId && (
              <div className="error-message">Please Select classesName .</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              className="input-style"
              value={selectTimings.title}
              validated={validated}
              placeholder="Select Timings"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  timingId: Number(selectedOption.id),
                });
                setFormErrors({ ...formErrors, timingId: false });
              }}
              // required
              options={timings.map((option, index) => ({
                id: option.id,
                value: option.title,
                label: option.title,
              }))}
              feedbackMessage="Please select Timings."
            />
            {formErrors.timingId && (
              <div className="error-message">Please Select Timings .</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              className="input-style"
              value={termFeeDates?.dueDate?.feeHeadName}
              validated={validated}
              placeholder="Select FeeHead"
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  feeHeadId: Number(selectedOption.id),
                });
                setFormErrors({ ...formErrors, feeHeadId: false });
              }}
              // required
              options={FeeHeadData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select Fee Head."
            />
            {formErrors.feeHeadId && (
              <div className="error-message">Please Select Fee Head .</div>
            )}
          </Col>
        </Row>
        <Row>
          {formData.trueValues.term && (
            <>
              <center>
                <TableContainer
                  style={{ width: "50%", height: "80%", marginTop: "2rem" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* First Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[0]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={termDueAmount.termFirstDueAmount}
                              onChange={(event) =>
                                setTermDueAmount({
                                  ...termDueAmount,
                                  termFirstDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>

                      {/* Second Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[1]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={termDueAmount.termSecondDueAmount}
                              onChange={(event) =>
                                setTermDueAmount({
                                  ...termDueAmount,
                                  termSecondDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>

                      {/* Third Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[2]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={termDueAmount.termThirdDueAmount}
                              onChange={(event) =>
                                setTermDueAmount({
                                  ...termDueAmount,
                                  termThirdDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[3]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={termDueAmount.termFourDueAmount}
                              onChange={(event) =>
                                setTermDueAmount({
                                  ...termDueAmount,
                                  termFourDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}

          {formData.trueValues.oneTimeAtAdmission && (
            <>
              <center>
                <TableContainer style={{ width: "50%", marginTop: "2rem" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* First Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[0]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={oneTimeDueAmount.oneTimeDueAmount}
                              onChange={(event) =>
                                setOneTimeDueAmount({
                                  ...oneTimeDueAmount,
                                  oneTimeDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}

          {formData.trueValues.yearly && (
            <>
              <center>
                <TableContainer style={{ width: "50%", marginTop: "2rem" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* First Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[0]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={oneTimeDueAmount.oneTimeDueAmount}
                              onChange={(event) =>
                                setOneTimeDueAmount({
                                  ...oneTimeDueAmount,
                                  oneTimeDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}

          {formData.trueValues.monthly && (
            <>
              <center>
                <TableContainer style={{ width: "50%", marginTop: "2rem" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[0]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.monthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            monthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[1]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.secondMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            secondMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[2]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.thirdMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            thirdMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[3]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.fourthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            fourthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[4]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.fifthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            fifthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[5]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.sixthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            sixthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[6]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.seventhMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            seventhMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[7]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.eightsMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            eightsMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[8]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.ninthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            ninthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          formatDate(termFeeDates?.dueDate?.dueDates[9]?.date) ?? ""
                        }
                        amountValue={monthlyDueAmount.tenthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setMonthlyDueAmount({
                            ...monthlyDueAmount,
                            tenthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}

          {formData.trueValues.biMonthly && (
            <>
              <center>
                <TableContainer style={{ width: "50%", marginTop: "2rem" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <CustomTableRow
                        dueDateValue={
                          termFeeDates?.dueDate?.dueDates[0]?.date ?? ""
                        }
                        amountValue={biMonthlyDueAmount.firstAmount}
                        onChangeAmount={(event) =>
                          setBiMonthlyDueAmount({
                            ...biMonthlyDueAmount,
                            firstAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          termFeeDates?.dueDate?.dueDates[1]?.date ?? ""
                        }
                        amountValue={biMonthlyDueAmount.secondMonthDueAmount}
                        onChangeAmount={(event) =>
                          setBiMonthlyDueAmount({
                            ...biMonthlyDueAmount,
                            secondMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          termFeeDates?.dueDate?.dueDates[2]?.date ?? ""
                        }
                        amountValue={biMonthlyDueAmount.thirdMonthDueAmount}
                        onChangeAmount={(event) =>
                          setBiMonthlyDueAmount({
                            ...biMonthlyDueAmount,
                            thirdMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          termFeeDates?.dueDate?.dueDates[3]?.date ?? ""
                        }
                        amountValue={biMonthlyDueAmount.fourthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setBiMonthlyDueAmount({
                            ...biMonthlyDueAmount,
                            fourthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                      <CustomTableRow
                        dueDateValue={
                          termFeeDates?.dueDate?.dueDates[4]?.date ?? ""
                        }
                        amountValue={biMonthlyDueAmount.fifthMonthDueAmount}
                        onChangeAmount={(event) =>
                          setBiMonthlyDueAmount({
                            ...biMonthlyDueAmount,
                            fifthMonthDueAmount: event.target.value,
                          })
                        }
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}

          {formData.trueValues.halfYearly && (
            <>
              <center>
                <TableContainer
                  style={{ width: "50%", height: "80%", marginTop: "2rem" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* First Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[0]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={halfYearlyDueAmount.termFirstDueAmount}
                              onChange={(event) =>
                                setHalfYearlyDueAmount({
                                  ...halfYearlyDueAmount,
                                  termFirstDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>

                      {/* Second Row */}
                      <TableRow>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Fee Name"
                              placeholder="Fee Name"
                              textType="Alphabet"
                              disabled
                              value={
                                formatDate(termFeeDates?.dueDate?.dueDates[1]?.date) ?? ""
                              }
                            />
                          </Col>
                        </TableCell>
                        <TableCell>
                          <Col>
                            <TextInputField
                              name="Amount"
                              placeholder="Amount"
                              textType="Alphabet"
                              // required
                              validated={validated}
                              value={halfYearlyDueAmount.termSecondDueAmount}
                              onChange={(event) =>
                                setHalfYearlyDueAmount({
                                  ...halfYearlyDueAmount,
                                  termSecondDueAmount: event.target.value,
                                })
                              }
                              feedbackMessage="Please enter Amount"
                              validationRegex={validationRegexAmount}
                              validationErrorMessage="Please enter Amount (only numbers."
                            />
                          </Col>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </center>
            </>
          )}
        </Row>

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear{" "}
          </button>
          <button className="save-button-figma-div" type="submit">
            {" "}
            Submit
          </button>
        </div>
      </Form>
      <SchoolFeeDetails />
    </>
  );
};
