import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";

const slice = createSlice({
  name: "viewAssessmentReport",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchAssessmentReportDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchAssessmentReportDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchAssessmentReportDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchAssessmentReportDataStart,
  fetchAssessmentReportDataSuccess,
  fetchAssessmentReportDataFailure,
} = slice.actions;

export const fetchAssessmentReportDataOnce =
  (franchiseeSchoolId, studentId) => async (dispatch, getState) => {
    const { data, isLoading } = getState().viewAssessmentReport;

    const tokenId = token();

    if (data === null && !isLoading) {
      dispatch(fetchAssessmentReportDataStart());

      try {
        const response = await fetch(API_PATH + `franchisee-school/${franchiseeSchoolId}/assessments/${studentId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data1 = await response.json();
        dispatch(fetchAssessmentReportDataSuccess(data1));
      } catch (error) {
        dispatch(fetchAssessmentReportDataFailure());
      }
    }
  };

export default slice.reducer;
