import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice(
    {
        name: 'FranchiseAddClass',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            addClassData: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }
        },

    }


);

export const { addClassData } = slice.actions;


export default slice.reducer;