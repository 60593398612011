 /*
    authors: rajeev
    date: 17-10-2023
    update:18-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentDataOnce } from "./viewStudentSlicer";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import StudentViewTable from "./StudentViewTable";
import { fetchUpdateStudentDataOnce } from "./updateStudentSlice";
export const ViewStudent = () => {

  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
  const FranchiseeSchoolId = Number(franchiseeSchoolId);

  const columns = [
    { field: "srNo", header: "Sl.No" },
    
    { field: "name", header: "Student Name" },
    { field: "gender", header: "Gender" },
    { field: "parents", header: "Parents" },
    { field: "languagesKnown", header: "Language" },
    { field: "dateOfBirth", header: "DOB" },
    { field: "age", header: "Age" },
    { field: "profilePictureUrl", header: "Profile Picture" },
    { field: "documentUrls", header: "Documents" },
    { field: "address", header: "Address" },
    { field: "area", header: "Area" },
    { field: "city", header: "City" },
    { field: "state", header: "State" },
    { field: "pincode", header: "Pincode" },
    { field: "previousBonafideCertifiedUrl", header: "BonafideCertified" },
    { field: "previousTransferCertificateUrl", header: "TransferCertificate" },
    { field: "previousReportCardUrl", header: "ReportCard" },
    { field: "studentEdit", header: "Edit" },




  ]

  const getAge = (dob) => {
    const today = new Date();

    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  // const Age = getAge(formData.dateOfBirth);

  const areasIds = useSelector((state) => state.areaDetails.data) || [];
  const postData = useSelector((state) => state.addStudent.data) || [];
  const apiData = useSelector((state) => state.viewStudent.data) || [];
  const putData = useSelector((state) => state.updateStudent.data) || [];


  const putAreaID = putData?.data?.currentAddress?.areaId;


  const putAreaObject = areasIds && areasIds.find((item) => item.id === putAreaID);

  let putDataArray = [];

  if (putData !== null && putData.data && typeof putData.data === 'object' ) {
    // Assuming you only have one student object, you might not need a loop
    // If there can be multiple students, you may need to adjust the code accordingly
    const item = putData?.data;

    putDataArray.push({
      createdAt: item?.createdAt,
      createdBy: item?.createdBy,
      currentAddressId: item?.currentAddressId,
      dateOfBirth: item?.dateOfBirth,
      documentUrls: item?.documentUrls,
      gender: item?.gender,
      id: item?.id,
      isActive: item?.isActive,
      languagesKnown: item?.languagesKnown,
      name: item?.name,
      permanentAddressId: item?.permanentAddressId,
      profilePictureUrl: item?.profilePictureUrl,
      currentAddressId: item?.currentAddressId,
      documentUrls: item?.documentUrls,
      isActive: item?.isActive,
      createdBy: item?.createdBy,
      updatedBy: item?.updatedBy,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,

      permanentAddress: {
        id: item?.permanentAddressId,
        address: putData?.data?.permanentAddress,
        areaId: putData?.data?.permanentAddress.areaId,
        cityId: putData?.data?.permanentAddress.cityId,
        stateId: putData?.data?.permanentAddress.stateId,
        pincode: putData?.data?.permanentAddress.pincode,
        type: putData?.data?.permanentAddress.type,
        createdBy: putData?.data?.permanentAddress.createdBy,
        updatedBy: putData?.data?.permanentAddress.updatedBy,
        createdAt: putData?.data?.permanentAddress.createdAt,
        updatedAt: putData?.data?.permanentAddress.updatedAt,
        area: {
          id: putData?.data?.permanentAddress.areaId,
          cityId: putData?.data?.permanentAddress.cityId,
          name: putAreaObject?.name,
        },
        city: {
          id: putData?.data?.permanentAddress.cityId,
          stateId: putData?.data?.permanentAddress.stateId,
          name: putAreaObject?.city?.name,
        },
        state: {
          id: putData?.data?.permanentAddress.stateId,
          name: putAreaObject?.city?.state?.name,
        },
      },
      currentAddress: {
        id: item?.currentAddressId,
        address: putData?.data?.currentAddress.address,
        areaId: putData?.data?.currentAddress.areaId,
        cityId: putData?.data?.currentAddress.cityId,
        stateId: putData?.data?.currentAddress.stateId,
        pincode: putData?.data?.currentAddress.pincode,
        type: putData?.data?.currentAddress.type,
        createdBy: putData?.data?.currentAddress.createdBy,
        updatedBy: putData?.data?.currentAddress.updatedBy,
        createdAt: putData?.data?.currentAddress.createdAt,
        updatedAt: putData?.data?.currentAddress.updatedAt,
        area: {
          id: putData?.data?.currentAddress.areaId,
          cityId: putData?.data?.currentAddress.cityId,
          name: putAreaObject?.name,
        },
        city: {
          id: putData?.data?.currentAddress.cityId,
          stateId: putData?.data?.currentAddress.stateId,
          name: putAreaObject?.city?.name,
        },
        state: {
          id: putData?.data?.currentAddress.stateId,
          name: putAreaObject?.city?.state?.name,
        },
      },
    });
  }

  
  const areaID = postData?.data?.currentAddress_?.areaId;


  const areaObject = areasIds && areasIds.find((item) => item.id === areaID);

  let postDataArray = [];


  if (
    postData !== null
    &&
    postData.data &&
    postData.data.student &&
    typeof postData.data.student === 'object'  // Check if student is an object
  ) {
    // Assuming you only have one student object, you might not need a loop
    // If there can be multiple students, you may need to adjust the code accordingly
    const item = postData?.data?.student;

    postDataArray.push({
      createdAt: item?.createdAt,
      createdBy: item?.createdBy,
      currentAddressId: item?.currentAddressId,
      dateOfBirth: item?.dateOfBirth,
      documentUrls: item?.documentUrls,
      gender: item?.gender,
      id: item?.id,
      isActive: item?.isActive,
      languagesKnown: item?.languagesKnown,
      name: item?.name,
      permanentAddressId: item?.permanentAddressId,
      profilePictureUrl: item?.profilePictureUrl,
      currentAddressId: item?.currentAddressId,
      documentUrls: item?.documentUrls,
      isActive: item?.isActive,
      createdBy: item?.createdBy,
      updatedBy: item?.updatedBy,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,

      permanentAddress: {
        id: item?.permanentAddressId,
        address: postData?.data?.permanentAddress_,
        areaId: postData?.data?.permanentAddress_.areaId,
        cityId: postData?.data?.permanentAddress_.cityId,
        stateId: postData?.data?.permanentAddress_.stateId,
        pincode: postData?.data?.permanentAddress_.pincode,
        type: postData?.data?.permanentAddress_.type,
        createdBy: postData?.data?.permanentAddress_.createdBy,
        updatedBy: postData?.data?.permanentAddress_.updatedBy,
        createdAt: postData?.data?.permanentAddress_.createdAt,
        updatedAt: postData?.data?.permanentAddress_.updatedAt,
        area: {
          id: postData?.data?.permanentAddress_.areaId,
          cityId: postData?.data?.permanentAddress_.cityId,
          name: areaObject?.name,
        },
        city: {
          id: postData?.data?.permanentAddress_.cityId,
          stateId: postData?.data?.permanentAddress_.stateId,
          name: areaObject?.city?.name,
        },
        state: {
          id: postData?.data?.permanentAddress_.stateId,
          name: areaObject?.city?.state?.name,
        },
      },
      currentAddress: {
        id: item?.currentAddressId,
        address: postData?.data?.currentAddress_.address,
        areaId: postData?.data?.currentAddress_.areaId,
        cityId: postData?.data?.currentAddress_.cityId,
        stateId: postData?.data?.currentAddress_.stateId,
        pincode: postData?.data?.currentAddress_.pincode,
        type: postData?.data?.currentAddress_.type,
        createdBy: postData?.data?.currentAddress_.createdBy,
        updatedBy: postData?.data?.currentAddress_.updatedBy,
        createdAt: postData?.data?.currentAddress_.createdAt,
        updatedAt: postData?.data?.currentAddress_.updatedAt,
        area: {
          id: postData?.data?.currentAddress_.areaId,
          cityId: postData?.data?.currentAddress_.cityId,
          name: areaObject?.name,
        },
        city: {
          id: postData?.data?.currentAddress_.cityId,
          stateId: postData?.data?.currentAddress_.stateId,
          name: areaObject?.city?.name,
        },
        state: {
          id: postData?.data?.currentAddress_.stateId,
          name: areaObject?.city?.state?.name,
        },
      },
    });
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    dispatch(fetchAreaData());
  }, [dispatch, FranchiseeSchoolId]);
 
  const combinedData = [...(apiData || []), ...postDataArray];

  putDataArray.forEach(obj2Item => {
    // Find the corresponding object in obj1 based on id
    const indexToUpdate = combinedData.findIndex(obj1Item => obj1Item.id === obj2Item.id);
    
    // If object is found in combinedData, update its properties
    if (indexToUpdate !== -1) {
        const obj1ItemToUpdate = combinedData[indexToUpdate];
        // Create a new object with updated properties from obj2Item
        const updatedObj1Item = { ...obj1ItemToUpdate, ...obj2Item };
        // Retain certain fields from obj1ItemToUpdate
        updatedObj1Item.previousBonafideCertifiedUrl = obj1ItemToUpdate.previousBonafideCertifiedUrl;
        updatedObj1Item.previousTransferCertificateUrl = obj1ItemToUpdate.previousTransferCertificateUrl;
        updatedObj1Item.previousReportCardUrl = obj1ItemToUpdate.previousReportCardUrl;
        // Replace the existing object in combinedData with the updated object
        combinedData[indexToUpdate] = updatedObj1Item;
    } else {
        // If object is not found in combinedData, add it
        combinedData.push(obj2Item);
    }
});

  const modifiedData = combinedData.map((items) => {
    const rowData = {};
    columns.forEach((column) => {
      if (column.field === "area") {
        rowData[column.field] = items.currentAddress?.area?.name;
      } else if (column.field === "address") {
        rowData[column.field] = items.currentAddress?.address || "N/A";
      } else if (column.field === "city") {
        rowData[column.field] = items.currentAddress?.city?.name;
      } else if (column.field === "state") {
        rowData[column.field] = items.currentAddress?.state?.name;
      } else if (column.field === "pincode") {
        rowData[column.field] = items.currentAddress?.pincode;
      }
       else if (column.field === "age") {
        rowData[column.field] = getAge(items.dateOfBirth);
      }
       else {
        rowData[column.field] = items[column.field];
      }
      if ("id" === "id") {
        rowData["id"] = items.id;
      }
      if ("parents" === "parents") {
        rowData["parents"] = items.studentsGuardian;
      }
    });
    return rowData;
  });  
  const studentdata = modifiedData.map((data, index) => ({
    ...data,
    srNo: index + 1
  }));
  
  return (
    <>
      <div className="content-header ">View Students</div>
      {studentdata === null ? (
        <center><p>Loading...</p></center>
      ) : studentdata.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) : (
        <StudentViewTable data={studentdata} columns={columns}/>
      )}

    </>
  )
}