import React, { useState } from "react";
import { Col,Form } from "react-bootstrap";
import './TextInputField.css'

const ReadOnlyTextField = ({
  label,
  name,
  required,
  value,
  type = "text",
  onChange,
  placeholder,
  feedbackMessage,
  validation,
  validated,
  maxLength,
  mt4,
  colSize
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {

    const inputValue = event.target.value;
    if (maxLength && inputValue.length > maxLength) {
      event.target.value = inputValue.substring(0, maxLength);
    }

    if (validation) {
      const { regex, message } = validation;
      const isValidInput = regex.test(inputValue);
      if (!isValidInput) {
        setError(message);
        return;
      }
    }

    setError(null);
    onChange(event);
  };

  return (
    <Form.Group>
{label && <Form.Label className="label-style">{label}</Form.Label>}


      <Form.Control
        name={name}
        className="textInput_Field"
        required={required}
        placeholder={placeholder}
        value={value}
        type={type}
        validated={validated}
        readOnly
        onChange={handleInputChange}
        maxLength={maxLength}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {error || (required && !value && feedbackMessage)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ReadOnlyTextField;
