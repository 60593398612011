import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentsFeeCollections } from '../StudentDetails/slice/studentFeeGet';
import TableView from "../reUsableForm/table/table4";
import { Col, Form, Row } from "react-bootstrap";
import SelectDropdown from '../SelectDropdown';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import { FitnessParameterPost, setShowSuccessMessage, setShowErrorMessage } from './slice/fitnessparameterSlice';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { useNavigate } from "react-router-dom";
import TextInputField from '../reUsableForm/textInputField';
import { amountPattern, amountWithDecimalPattern } from '../reUsableForm/regex';


const Fitness = () => {
    const dispatch = useDispatch();
    const postFitnessParameterData = useSelector((state) => state.fitnessParameter.data) || [];
    const getStudentData = useSelector((state) => state.viewStudent.data) || [];

    const studentData = [...getStudentData]
    const [submit, setSubmit] = useState(false);
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')

    const showSuccessMsg = useSelector((state) => state.fitnessParameter.showSuccessMessage);
    const successMsg = useSelector((state) => state.fitnessParameter.successMessage);

    const showErrorMsg = useSelector((state) => state.fitnessParameter.showErrorMessage);
    const errorMsg = useSelector((state) => state.fitnessParameter.errorMessage);
    const [selectedMonth, setSelectedMonth] = useState('');

    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        month: '', studentId: "", height: '', weight: '', runningIn1mint: '', franchiseeSchoolId: parseFloat(franchiseeSchoolId),
    });

    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];

    useEffect(() => {
        dispatch(fetchStudentDataOnce());
    }, [dispatch]);

    const [selectedRows, setSelectedRows] = useState({});
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmit(true);
        const newFormErrors = {
            studentId: !formData.studentId,
            month: !formData.month,
            height: !formData.height,
            weight: !formData.weight,
            runningIn1mint: !formData.runningIn1mint,
        };
        const hasErrors = Object.values(newFormErrors).some((error) => error);
        if (hasErrors) {
            setValidated(true);
            return;
        }
        const formDataWithFranchiseeId = {
            month: formData.month,
            studentId: parseInt(formData.studentId),
            height: parseFloat(formData.height),
            weight: parseFloat(formData.weight),
            runningIn1mint: parseInt(formData.runningIn1mint)
        };
        dispatch(FitnessParameterPost(formDataWithFranchiseeId));
    };

    const handleClear = (e) => {
        setFormData({
        });
        setValidated(false);
    };

    const navigate = useNavigate()
    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
        navigate("/fitnessParameter")
        window.location.reload();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };
    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div>
                    <div className='content-header'>Fitness</div>
                    <Row>
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                            <SelectDropdown
                                isSearchable
                                required
                                value={selectedMonth ? { label: selectedMonth } : null}
                                options={months}
                                onChange={(selectedOption) => {
                                    setSelectedMonth(selectedOption.value);
                                    setFormData({ ...formData, month: selectedOption.value });
                                }}
                                placeholder="Select Month"
                                feedbackMessage="Please select a month."
                                validated={validated}
                                validationErrorMessage="Invalid height(in ft)."


                            />
                        </Col>
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                            <SelectDropdown
                                isSearchable
                                required
                                value={formData.studentId ? { label: formData.studentName } : null}
                                options={studentData.map((option) => ({
                                    id: option.id,
                                    value: option.studentEnrollments[0].studentRollNumber,
                                    label: option.name,
                                    classId: option.studentEnrollments[0].classesClassSection.classId
                                }))}
                                onChange={(selectedOption) => {
                                    setFormData({
                                        ...formData,
                                        studentId: Number(selectedOption.id),
                                        studentName: selectedOption.label,
                                        classId: selectedOption.classId
                                    });
                                    setSelectedRows({});
                                }}
                                placeholder="Child Name"
                                validated={validated}
                                feedbackMessage="Please select child name."
                                validationErrorMessage="Invalid height(in ft)."

                            />
                        </Col>

                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="height"
                                textType="number"
                                value={formData.height}
                                onChange={(event) => { setFormData({ ...formData, height: event.target.value }) }}
                                required
                                placeholder="Height(in ft)"
                                validated={validated}
                                maxLength={25}
                                feedbackMessage="Please enter height(in ft)"
                                validationRegex={amountWithDecimalPattern}
                                validationErrorMessage="Invalid height(in ft)."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="weight"
                                textType="number"
                                value={formData.weight}
                                onChange={(event) => { setFormData({ ...formData, weight: event.target.value }) }}
                                required
                                placeholder="Present Weight(kgs)"
                                validated={validated}
                                maxLength={25}
                                feedbackMessage="Please enter present weight(kgs)."
                                validationRegex={amountWithDecimalPattern}
                                validationErrorMessage="Invalid present weight(kgs)."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <TextInputField
                                name="running"
                                textType="text"
                                value={formData.runningIn1mint}
                                onChange={(event) => { setFormData({ ...formData, runningIn1mint: event.target.value }) }}
                                required
                                placeholder="Running in 1mint(Mtrs)"
                                validated={validated}
                                maxLength={25}
                                feedbackMessage="Please enter running in 1mint(Mtrs)."
                                validationRegex={amountPattern}
                                validationErrorMessage="Invalid running in 1mint(Mtrs)."
                            />
                        </Col>
                    </Row>
                </div>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className='save-button-figma-div' type='submit'>Submit</button>
                </div>
            </Form>
        </>
    );
};
export default Fitness;
