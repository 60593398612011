export const responseFormatField = ({error}) => {
    const response = error;
    const jsonStartIndex = response.indexOf('{');
    const jsonEndIndex = response.lastIndexOf('}');
    const jsonContent = response.substring(jsonStartIndex, jsonEndIndex + 1);

    const errorJSON = JSON.parse(jsonContent);
    const errorMessage = errorJSON.errors[0].message;
    return errorMessage
};
