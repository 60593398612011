/*
    authors: Rajeev
    date: 25-10-2023
    update:25-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../StudentDetails/Student.css"; // Import your custom CSS file
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import Table from "../reUsableForm/table/table1";
import ReadOnlyDateInputField from "../../ReusableFields/DateFields/ReadOnlyDateField";

import { postAttendanceRequest, setShowSuccessMessage, setShowErrorMessage } from "./addAttendanceSlicer";
import SelectDropdown from "../SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import DateInputField from "../../ReusableFields/DateFields/DateField";

const ClassAttendance = () => {
  const [validated, setValidated] = useState(false);
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId")
  const [formData, setFormData] = useState({
    prePostLunchStatus: "",
  });
  const [checkboxState, setCheckboxState] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [date, setDate] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [selectAllPresent, setSelectAllPresent] = useState(false);
  const [dropDowns, setDropDowns] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [formErrors, setFormErrors] = useState({
    class: false,
    section: false,
    name: false,
    sections: false
  });
  const showSuccessMsg = useSelector((state) => state.addAttendance.showSuccessMessage);
  const successMsg = useSelector((state) => state.addAttendance.successMessage);

  const showErrorMsg = useSelector((state) => state.addAttendance.showErrorMessage);
  const errorMsg = useSelector((state) => state.addAttendance.errorMessage);

  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const prePostOptions = [
    { id: "true", name: "Pre Lunch", value: "true" },
    { id: "false", name: "Post Lunch", value: "false" },
  ];

  const columns = [
    { field: "srNo", header: "Sr.no" },
    { field: "rollNumber", header: "Roll NUmber" },
    { field: "studentName", header: "Student Name" },
    { field: "present", header: "Present" },
    { field: "absent", header: "Absent" },
  ];


  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [classesClassSectionData, dispatch]);
  const studentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (studentData.length === 0) {
      dispatch(fetchStudentDataOnce());
    }
  }, [studentData, dispatch]);


  const handleSelectAllPresentChange = (e) => {
    const isChecked = e.target.checked;

    const updatedCheckboxState = data.map((student) => ({
      studentId: student.studentId,
      status: isChecked ? true : "",
    }));

    setSelectAllPresent(isChecked);
    setCheckboxState(updatedCheckboxState);
  };
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);

    setShowTable(false);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
  }, [selectedClass]);

  const handleSearchClick = () => {
    const newFormErrors = {
      class: !dropDowns.class,
      sections: !dropDowns.sections
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }
    if (!formData.classId) {
      console.error("Invalid class selected");
      return;
    }
    setFormErrors({ ...formErrors, prePostLunchStatus: false });
    let filteredData = [];

    if (selectedSection) {
      filteredData = studentData.filter(
        (item) =>
          item.studentEnrollments[0].classesClassSectionId == selectedSection
      );
    }

    const formattedData = filteredData.map((item) => ({
      id: item.id,
      studentId: item.id,
      rollNumber: item.studentEnrollments[0].studentRollNumber,
      studentName: item.name,
      // classesClassSectionId: item.studentEnrollments[0].classesClassSectionId,
    }));

    setData(formattedData);

    setShowTable(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const newFormErrors = {

      name: !dropDowns.name,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }

    const sendData = {
      ...formData,

      classesClassSectionId: selectedSection,
      date: date.date,
      classAttendance: checkboxState.map((item) => ({
        studentId: item.studentId,
        status: item.status,
      })),
    };

    if (sendData.classAttendance.length !== 0) {
      dispatch(postAttendanceRequest(franchiseeSchoolId, sendData));
      setFormData({});
    } else {
      console.error(
        "classAttendance is empty. Please select at least one student."
      );
    }
    handleClear()

  };

  const handleClear = () => {
    // setFormData({
    //   class: "",
    // });
    setSelectedSection("");
    setDropDowns({ class: "", sections: "" })
    setData([])

  };
  const classAttendanceData = data.map((data, index) => ({
    ...data,
    srNo: index + 1,
  }));

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };

  const handleDateChange = (e) => {
    const { name, value } = e.target
    setDate({ ...date, "date": value })
  }
  return (
    <>
      <div className="content-header">Class Attendance</div>

      <Row>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">

          {/* <ReadOnlyDateInputField
            name="Current Date"
            required
            value={currentDate}
            validated={validated}
          /> */}
          <DateInputField
            name='date'
            value={date.date}
            onChange={handleDateChange}
            feedbackMessage="date is required"
            validated={validated}
            placeholder='Today Date'
          />
        </Col>


        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

          <SelectDropdown
            label="Class"
            // value={formData.classId || ""}
            value={
              dropDowns.class
                ? { value: dropDowns.class, label: dropDowns.class }
                : dropDowns.class
            }
            placeholder="select class"
            onChange={(selectedOption) => {
              handleChange("classId", selectedOption);
              setSelectedClass(selectedOption.value);
              setDropDowns({ ...dropDowns, class: selectedOption.label });
              setFormErrors({ ...formErrors, class: false });
            }}
            options={classesClassSectionData.map((classItem) => ({
              value: classItem.id,
              label: classItem.name,
              // classesClassSectionDataId:classItem.classSections
            }))}
            required
            feedbackMessage="Please select a valid class"
          // placeholder="Select class"
          />
          {formErrors.class && (
            <div className="error-message">Please Select class.</div>
          )}
        </Col>

        {formData.classId &&
          selectedClassData &&
          selectedClassData.classSections.length > 0 && (
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
              <SelectDropdown
                label="Section"
                // value={formData.sectionId || ""}
                value={
                  dropDowns.sections
                    ? { value: dropDowns.sections, label: dropDowns.sections }
                    : dropDowns.sections
                }
                placeholder="Select section"
                onChange={(selectedOption) => {
                  handleChange("sectionId", selectedOption);
                  setSelectedSection(selectedOption.value);
                  setDropDowns({
                    ...dropDowns,
                    sections: selectedOption.label,
                  });
                  setFormErrors({ ...formErrors, sections: false });
                }}
                options={selectedClassData.classSections.map((section) => ({
                  key: section.id,
                  value: section.classesclassSection,
                  label: section.name,
                }))}
                required
                feedbackMessage="Please select a valid section"
              />
              {formErrors.sections && (
                <div className="error-message">Please Select Section.</div>
              )}
            </Col>
          )}


        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%",
          }}
        >

          <button
            className="class_search" // Apply appropriate styling
            onClick={handleSearchClick} // Attach the click event handler
          >
            Search
          </button>

        </div>

      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {showTable && (
          <Row>

            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

              <SelectDropdown
                value={
                  dropDowns.name
                    ? { value: dropDowns.name, label: dropDowns.name }
                    : null
                }
                // value={selectedPostId}
                className="input-style"
                options={prePostOptions.map((option) => ({
                  id: option.id,
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    prePostLunchStatus: selectedOption.id,
                  });
                  // setSelectedPostId(selectedOption.value);
                  setDropDowns({ ...dropDowns, name: selectedOption.label });
                  setFormErrors({ ...formErrors, name: false });
                }}
                placeholder="Select Pre/Post Attendance"
                feedbackMessage="Please select a Pre/Post Attendance."
              />
              {formErrors.name && (
                <div className="error-message">
                  Please Select Pre/Post Attendance.
                </div>
              )}
            </Col>


            <Col sm={12} xs={12} md={6} lg={12} className="rowCss">

              {classAttendanceData === null ? (
                <center>
                  <p>Loading...</p>
                </center>
              ) : classAttendanceData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                  <p>No records available</p>
                </center>
              ) : (
                <Row>
                  <div
                    className="d-flex justify-content-end align-items-start w-100"
                    style={{ fontSize: "20px" }}
                  >
                    <div className="ml-auto mr-4">
                      <input
                        type="checkbox"
                        style={{ transform: "scale(2)", marginRight: "4px" }}
                        checked={selectAllPresent}
                        onChange={handleSelectAllPresentChange}
                      />
                      <span
                        style={{
                          marginLeft: "15px",
                          color: "#006AB0",
                          fontWeight: "bold",
                        }}
                      >
                        Select All Present
                      </span>
                    </div>
                  </div>

                  <Table
                    data={classAttendanceData}
                    columns={columns}
                    hover={true}
                    checkboxState={checkboxState}
                    setCheckboxState={setCheckboxState}
                  />
                </Row>
              )}
            </Col>
          </Row>
        )}

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      {/* ... Displaying school kids in cards */}

      {/* </Container> */}
    </>
  );
};

export default ClassAttendance;
