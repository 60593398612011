import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';

const slice = createSlice({
    name: "timingsDropdown",
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        }

    }
});

export const { fetchDataStart, fetchDataFailure, fetchDataSuccess } = slice.actions;

export const fetchTimingsView = () => async (dispatch, getTimingsView) => {
    const { data, isLoading } = getTimingsView().timings;
    const tokenId = token();
    if (data === null && !isLoading) {
        dispatch(fetchDataStart());

        try {
            const response = await fetch(API_PATH + '/timings', {
                method: 'GET',
                headers: {
                    "Content-Type": 'application/json',
                    'Authorization': `Bearer ${tokenId}`
                },
                // body: JSON.stringify(requestAndSuggestionPost),
            })
            if (!response.ok) {
                throw new Error('Request failed');
            }
            const data1 = await response.json();
            dispatch(fetchDataSuccess(data1));
        } catch (error) {
            dispatch(fetchDataFailure())
        }
    }

}


  
export default slice.reducer;
