import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioButton = ({ label, value, checked, onChange }) => {
    return (
        <FormControlLabel
            value={value}
            labelPlacement="start"
            control={<Radio checked={checked} onChange={onChange} />}
            label={label}
            style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}
        />
    );
};

export default RadioButton;
