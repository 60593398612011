import React, { useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import "./DescriptionField.css";

const DescriptionField = ({
  label,
  name,
  value,
  onChange,
  required,
  colSize,
  placeholder,
  feedbackMessage,
  validated,
  validationRegex,
  validationErrorMessage,
  maxLength,
  mt4
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    adjustTextAreaHeight();
  }, [value]);

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (maxLength && inputValue.length > maxLength) {
      // Truncate the input if it exceeds the maxLength
      e.target.value = inputValue.substring(0, maxLength);
    }
    adjustTextAreaHeight();
    onChange(e);
  };

  return (
    <>
      <Form.Group>
        {label && <Form.Label className="label-style">{label}</Form.Label>}

        <Form.Control
          as="textarea"
          className="Description_field"
          name={name}
          value={value}
          onChange={handleInputChange}
          required={required}
          validated={validated}
          placeholder={placeholder}
          isInvalid={validated && !value} // Check if value is empty
          ref={textAreaRef}
          maxLength={maxLength}
        />
        {validated && feedbackMessage && (
          <Form.Control.Feedback type="invalid">
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
        {maxLength && (
          <div className="text-right">
            {value.length}/{maxLength} characters.
          </div>
        )}
      </Form.Group>
    </>
  );
};

export default DescriptionField;
