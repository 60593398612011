import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';


const slice = createSlice(
    {

        name: "sectionPost",
        initialState: {
            data: null,
            isLoading:false,
            hasError:false,
        },
        reducers: {
            fetchSectionData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchSectionSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchSectionFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setSectionAdd :(state,action) =>{
                state.isLoading = true;
                state.data = action.payload
            }
        },
    },
);

export const { fetchSectionData, fetchSectionSuccess, fetchSectionFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage ,setSectionAdd} = slice.actions;


export const postAddSectionRequest = (postData) => async (dispatch) => {
    dispatch(fetchSectionData());
    const tokenId = token();
    
    try{
        const response = await fetchWithAuthPost (API_PATH + "/class-section", 'POST', tokenId, postData); 
       
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchSectionSuccess([responseData.data]));
            dispatch(setSuccessMessage("Successful!"));
            dispatch(setShowSuccessMessage(true));

        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;