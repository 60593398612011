import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { postSubjectRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addSubjectSlice";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexClass } from "../reUsableForm/regex";
import ViewSubject from './viewSubject';
import SelectDropdown from "../SelectDropdown";
import { fetchDataOnce } from '../Classes/slice/viewClassSlice';

const CreateSubject = () => {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({ classId: false,name:false })


  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.addClass.data) || [];
  const classData = useSelector((state) => state.viewClass.data) || [];

  const combinedData = [...(apiData||[]),...classData]


  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchDataOnce());
    }
  }, [apiData, dispatch]);

  const showSuccessMsg = useSelector((state) => state.addSubject.showSuccessMessage);
  const successMsg = useSelector((state) => state.addSubject.successMessage);

  const showErrorMsg = useSelector((state) => state.addSubject.showErrorMessage);
  const errorMsg = useSelector((state) => state.addSubject.errorMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const newFormErrors = {

      classId: !formData.classId,
      name:!formData.name

    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }

  const class_id = formData.classId.id;
  const class_name = formData.classId.label;

  formData.classId = class_id;

  const sendData = {
   
   "name":formData.name,
   "classId":formData.classId,
   "className":class_name

  }
  
    dispatch(postSubjectRequest(sendData));
  };


  const handleClear = () => {
    setFormData({
      name: '',
      className:""
    });
    setFormErrors(false)
    setValidated(false);
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
<>
    <Form noValidate validated={validated} onSubmit={handleSubmit} >

      <div className="content-header">Add Subject</div>
      <Row style={{ justifyContent: "center", }}>
      <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.classId ? { id: formData.classId.id, label: formData.classId.label } : null}
              options={combinedData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name
              }))}

              onChange={(selectedOption) => {
                setFormData({ ...formData, classId: selectedOption});
                setFormErrors({ ...formErrors, classId: !selectedOption });
              }}
              placeholder="Select Class"
            />
            {formErrors.classId && (
              <div className="error-message">
                Please Select class .
              </div>
            )}
          </Col>
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <Form.Group >
          {/* <Form.Label className='mt2' ></Form.Label> */}
            <TextInputField
              className="fcontrol"
              type="text"
              name="name"
              placeholder='Subject'
              required
              value={formData.name}
              onChange={(event) => {
                  setFormData({ ...formData, "name": event.target.value });
                  setFormErrors({ ...formErrors, name: false });
                }}
                validated={validated}
                feedbackMessage="Please enter Subject."
                validationRegex={validationRegexClass}
                validationErrorMessage="Please enter a valid  Subject."
                />
          </Form.Group>
        </Col>
      </Row>
      <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "0.5%",
          marginTop: "0%"
        }}
      >
        <button
          className="clear-button-figma-div"
          type="button"
          onClick={handleClear}
          style={{ width: '6rem', height: '2rem' }}
        >
          Clear
        </button>
        <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
          Submit
        </button>
      </div>
    </Form>
    <ViewSubject/>
    </>
  );
};

export default CreateSubject;