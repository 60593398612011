import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';


const slice = createSlice(
    {
        name: "ptmDates",
        initialState: {
            data: null,
            isLoading:false,
            hasError:false,
        },
        reducers: {
            fetchParentTeacherMeetingData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchParentTeacherMeetingSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchParentTeacherMeetingFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setParentTeacherMeetingAdd :(state,action) =>{
                state.isLoading = true;
                state.data = action.payload
            }
        },
    },
);

export const { fetchParentTeacherMeetingData, fetchParentTeacherMeetingSuccess, fetchParentTeacherMeetingFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage ,setParentTeacherMeetingAdd} = slice.actions;


export const postPtmDates = (id, postData) => async (dispatch) => {
    dispatch(fetchParentTeacherMeetingData());
    const tokenId = token();
    
    try{
        const response = await fetchWithAuthPost (API_PATH + `/franchisee-school/${id}/parent-teacher-meet-dates`, 'POST', tokenId, postData); 
       
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchParentTeacherMeetingSuccess([responseData.data]));
            dispatch(setSuccessMessage("Successful!"));
            dispatch(setShowSuccessMessage(true));

        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;