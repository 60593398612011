/*
    authors: Revanth
    date: 21-10-2023
    update:21-10-2023 
    version: 1.0
*/

import React, { useEffect } from 'react';
import '../EnquiryDetails/enquiryView.css'
import Table from '../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { followUpListSlicer } from './franchiseSlice/franchiseFollowUpListSlicer';
import { UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const FranchiseFollowUpList = () => {
    const apiData = useSelector((state) => state.franchiseFollowUPList.data) || [];
    const postData = useSelector((state) => state.franchiseEnquirySlicer.data) || [];
    const putData = useSelector((state) => state.franchiseFollowUp.data) || [];

    const dispatch = useDispatch();

    const getBackgroundColor = (isFranchiseeOwnerCreated, followUps) => {
        if (isFranchiseeOwnerCreated) {
            return 'rgb(0, 255, 26)'; // Green
        }

        const latestFollowUp = followUps.reduce((latest, followUp) => {
            if (!latest || new Date(followUp.updatedAt) > new Date(latest.updatedAt)) {
                return followUp;
            }
            return latest;
        }, null);

        if (!latestFollowUp) {
            return 'rgb(240, 102, 102)'; // Red
        }

        if (latestFollowUp.status === 'interested') {
            return 'rgb(255, 255, 0)'; // Yellow
        }

        return 'rgb(240, 102, 102)'; // Red by default
    };




    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "name", header: "Name" },
        { field: "email", header: "Email" },
        { field: "address", header: "Address" },
        { field: "contactNumber", header: "Mobile No" },
        { field: "reference", header: "Reference" },
        { field: "FollowUps", header: "Follow Ups" },
        { field: "arrow", header: "Create Franchisee" },
    ]
    const combinedData = apiData.map(apiItem => {
        if (putData.length > 0) {
            const matchingPutItem = putData[0].id === apiItem.id ? putData[0] : null;
            if (matchingPutItem) {
                return {
                    ...apiItem,
                    franchiseeEnquiryFollowUps: [
                        ...(apiItem.franchiseeEnquiryFollowUps || []), // Existing follow-ups
                        matchingPutItem.franchiseeEnquiryFollowUps // New follow-up from putData
                    ]
                };
            } else {
                return apiItem;
            }
        } else {
            return apiItem;
        }
    });

    const newModifiedData = combinedData.map((postItem, index) => {
        const formattedDate = postItem?.date ? new Date(postItem?.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }) : '';

        const latestFollowUp = postItem.franchiseeEnquiryFollowUps.reduce((latest, followUp) => {
            if (!latest || new Date(followUp.updatedAt) > new Date(latest.updatedAt)) {
                return followUp;
            }
            return latest;
        }, null);


        const renderArrow = () => {
            if (postItem.isFranchiseeOwnerCreated === false) {
                if (latestFollowUp && latestFollowUp.status === 'interested') {
                    return (
                        <Link to={`/FranchiseOwnerCreation?name=${encodeURIComponent(postItem.name)}&email=${encodeURIComponent(postItem.email)}&contactNumber=${encodeURIComponent(postItem.contactNumber)}&address=${encodeURIComponent(postItem.address)}&franchiseeEnquiryId=${encodeURIComponent(postItem.id)}`}>
                            <PersonAddAlt1Icon style={{ fontSize: "28px", color: "white" }} />
                        </Link>
                    );
                } else {
                    return "-";
                }
            } else {
                return "Created";
            }
        };

        return {
            index: index + 1,
            id: postItem?.id || '',
            date: formattedDate,
            name: postItem?.name || '',
            email: postItem?.email || '',
            address: postItem?.address || '',
            contactNumber: postItem?.contactNumber || '',
            reference: postItem?.reference || '',
            status: postItem?.status || '',
            description: postItem?.description || '',
            franchiseeEnquiryFollowUps: postItem?.franchiseeEnquiryFollowUps || [],
            backgroundColor: getBackgroundColor(postItem.isFranchiseeOwnerCreated, postItem.franchiseeEnquiryFollowUps),
            arrow: renderArrow()
        };
    })

    const uniqueNewModifiedData = newModifiedData.filter(
        (obj, index, self) => self.findIndex(item => item.id === obj.id) === index
    );


    useEffect(() => {
        dispatch(followUpListSlicer());
    }, [dispatch]);

    return (
        <div>
            <h4 className='enquiryView'>Franchise Follow Up View</h4>

            {uniqueNewModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : uniqueNewModifiedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={uniqueNewModifiedData} columns={columns} hover={true} />
            )}
        </div>
    );
};

export default FranchiseFollowUpList;