// import React from 'react';
// import { Col, Form } from 'react-bootstrap';
// import "./ReadOnlyDateField.css"

// const FutureDateField = ({
//   label,
//   name,
//   value,
//   onChange,
//   required,
//   placeholder,
//   colSize,
//   feedbackMessage,
//   validated
// }) => {
//   // Get the current date in ISO format (YYYY-MM-DD)
//   const currentDate = new Date().toISOString().split('T')[0];

//   return (
//     <Form.Group>
//         <Form.Control
//           type="date"
//           className="readOnlyDate_field"
//           name={name}
//           value={value}
//           onChange={onChange}
//           required={required}
//           validated={validated}
//           min={currentDate}
//         />
//         {validated && feedbackMessage && (
//           <Form.Control.Feedback type="invalid">
//             {feedbackMessage}
//           </Form.Control.Feedback>
//         )}
//       </Form.Group>
//   );
// };

// export default FutureDateField;

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import "./DatePicker.css";

const FutureDateField = ({ name, label, value, onChange, placeholder, dateFormat, feedbackMessage, isValidDate = () => true, validated }) => {
  const currentDate = new Date();

  return (
    <Form.Group>
      <DatePicker
        selected={value}
        onChange={date => onChange(date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        dateFormat="dd-MM-yyyy"
        className={`DatePicker_field ${validated ? (value ? 'is-valid' : 'is-invalid') : ''}`}
        minDate={currentDate}
        isValidDate={isValidDate}
        // customStyles={customStyles}
      />
      {!validated && (
        <div className="invalid-feedback">Please select a valid date.</div>
      )}
    </Form.Group>
  );
};

export default FutureDateField;
