import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from "../../Auth/dependency_post";
import { responseFormat } from "../../Auth/errorResponse";


const slice = createSlice(
    {
        name: "employeeEnquiryPost",
        initialState: {
            data:null,
            isLoading:false,
            hasError:null,
        },
        reducers: {
            fetchEmployeeEnquiryData: (state,action) => {
                state.isLoading = action.payload;
                state.hasError = false;
            },
            fetchEmployeeEnquirySuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchEmployeeEnquiryFailure: (state,action) => {
                state.isLoading = false;
                state.hasError = action.payload;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setEmployeeEnquiryEmptyData: (state, action) => {
                state.data = action.payload;
            },
        },
    },
);

export const { fetchEmployeeEnquiryData, fetchEmployeeEnquirySuccess, fetchEmployeeEnquiryFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage, setEmployeeEnquiryEmptyData } = slice.actions;


export const postEmployeeEnquiryRequest = (postData) => async (dispatch) => {
    dispatch(fetchEmployeeEnquiryData());
    const tokenId = token();
    try{
        const response = await fetchWithAuthPost (API_PATH + "/employee-enquiry", 'POST', tokenId, postData); 
            
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else{
        const responseData = await response;
        dispatch(fetchEmployeeEnquirySuccess([responseData.data]));
        dispatch(setSuccessMessage("Successful!"));
        dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;