import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../Api/api";
import { franchiseeSchoolId, token } from "../../Api/token";
import { fetchParentSuccess, setFormData } from "./addParentsSlicer";

const slice = createSlice({
  name: "viewParent",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchParentDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchParentDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchParentDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchParentDataStart,
  fetchParentDataSuccess,
  fetchParentDataFailure,
} = slice.actions;


export const fetchParentDataOnce = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().viewParent;

  const tokenId = token();

  const FranchiseeSchoolId = franchiseeSchoolId()
  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchParentDataStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/guardians?isActive=true&franchiseeSchoolId=${FranchiseeSchoolId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });

      if (!response.ok) {
        throw new Error("Request failed"); // You can customize error handling
      }

      const data1 = await response.json();

      dispatch(fetchParentDataSuccess(data1));
      dispatch(setFormData([]));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchParentDataFailure());
    }
  }
};

export default slice.reducer;
