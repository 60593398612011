import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { fetchProgramPostData } from './addProgramSlice';



const slice = createSlice(
  {
    name: 'viewProgram',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchProgramsData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchProgramSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchProgramFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      },
    
    },
  },

);

export const { fetchProgramsData, fetchProgramSuccess, fetchProgramFailure, } = slice.actions;

export const fetchDataOnceProgram = () => async (dispatch, getState) => {

  const tokenId = token()

  const { data, isLoading } = getState().viewProgram;
  if (data === null && !isLoading) {
    dispatch(fetchProgramsData());

    try {
      const response = await fetch(API_PATH + "/programs", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenId}`

        }
      })
      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data1 = await response.json();
      dispatch(fetchProgramPostData([]))
      dispatch(fetchProgramSuccess(data1));
    } catch (error) {
      dispatch(fetchProgramFailure());
    }
  }

}

export default slice.reducer;
