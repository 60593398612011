import React from 'react';
import { Navigate } from 'react-router-dom';

export const RoleBasedRoute = ({ allowedRoles, children, redirect = 'parent-login' }) => {

  const storedRole = sessionStorage.getItem('roleId');
  const role = storedRole ? parseInt(storedRole, 10) : null;

  const isRoleAllowed = role && allowedRoles.includes(role);

  return isRoleAllowed ? (
    <>{children}</>
  ) : (
    <Navigate to={`/${redirect}`} replace={true} />
  );
};

