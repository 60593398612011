import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";


const slice = createSlice(
    {

        name: "feeDetailsPost",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchFeeDetailsData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchFeeDetailsSuccess: (state, action) => {
                state.isLoading = true;
                state.data = action.payload;
            },
            fetchFeeDetailsFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
        },
    },
);

export const { fetchFeeDetailsData, fetchFeeDetailsSuccess, fetchFeeDetailsFailure } = slice.actions;


export const postFeeDetailsRequest = (postData) => async (dispatch) => {
    dispatch(fetchFeeDetailsData());
    const tokenId = token();
    try {
        const response = await fetch(API_PATH + "/studentfee", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenId}`
            },
            body: JSON.stringify(postData),
        });
        if (!response.ok) {
            throw new Error("Request failed");
        }
        const responseData = await response.json();
        dispatch(fetchFeeDetailsSuccess(responseData));
    } catch (error) {
        dispatch(fetchFeeDetailsFailure());
    }
};
export default slice.reducer;