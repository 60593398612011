import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';

const slice = createSlice(
    {
        name: 'testPostAPI',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchTestData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchTestSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchTestFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
        },
    }


);

export const { fetchTestData, fetchTestSuccess, fetchTestFailure } = slice.actions;


export const postTestRequest = (postData) => async (dispatch) => {
    dispatch(fetchTestData());
    const tokenId = token();
    try {
        // Make your POST request here using the fetch function or an HTTP library like Axios
        const response = await fetch(API_PATH + '/dummy_Classes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":`Bearer ${tokenId}`
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
            throw new Error('Request failed'); // You can customize error handling
        } else {
            const responseData = await response.json();
            dispatch(fetchTestSuccess(responseData.classes));
        }

    } catch (error) {
        dispatch(fetchTestFailure());
    }
};


export default slice.reducer;
