import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentsFeeCollections } from './slice/studentFeeGet';
import TableView from "../reUsableForm/table/table4";
import { Col, Form, Row } from "react-bootstrap";
import SelectDropdown from '../SelectDropdown';
import { fetchStudentDataOnce } from './viewStudentSlicer';
import { postFeeRequest } from './slice/studentFeePost';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { useNavigate, useParams } from "react-router-dom";
import { API_PATH } from '../../Api/api';
import TextInputField from '../reUsableForm/textInputField';
import { amountPattern, amountWithDecimalPattern, validationRegexOnlyAlpha } from '../reUsableForm/regex';
import { useLocation } from 'react-router-dom';
import { token } from '../../Api/token';
import { fetchStudentFeeView } from './slice/viewStudentFeeSlice';
import { fetchRepaymentFee, setShowSuccessMessage, setShowErrorMessage } from './slice/repaymentFeeSlice';

const DueFee = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const feeCollections = useSelector((state) => state.studentsFeeCollections.data) || [];
    const isLoading = useSelector((state) => state.studentsFeeCollections.isLoading);
    const hasError = useSelector((state) => state.studentsFeeCollections.hasError);
    const postStudentData = useSelector((state) => state.addStudent.data) || [];
    const getStudentData = useSelector((state) => state.viewStudent.data) || [];

    const getData = useSelector((state)=>state.ViewStudentFees.data)||[]

    useEffect(() => {
        dispatch(fetchStudentFeeView());
    }, [dispatch]);

    function getRecentPaymentTransaction(data) {
        // Get the payment transactions array
        const paymentTransactions = [...data];
        
        // Sort the payment transactions based on paidDate in descending order
        paymentTransactions.sort((a, b) => new Date(b.paidDate) - new Date(a.paidDate));
        
        // Return the first transaction (i.e., the most recent one)
        return paymentTransactions[0];
      }

    const studentData = [...(getStudentData || []), ...postStudentData]
    const [submit, setSubmit] = useState(false);
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
    const FranchiseeSchoolId = Number(franchiseeSchoolId);
    const [formData, setFormData] = useState({
        studentClassId: "", studentName: '', classId: '', remark: ''
    });
    const [validated, setValidated] = useState(false);
    // const [data, setData] = useState();
    const [balance, setBalance] = useState();
    const [classId, setClassId] = useState()
    const [studentId, setStudentId] = useState()
    const [franchiseeSchoolFeeId, setFranchiseeSchoolFeeId] = useState()

    const [FeeShowErrorMsg, setFeeShowErrorMsg] = useState(false);
    const [FeeErrorMsg, setFeeErrorMsg] = useState("");


    const showSuccessMsg = useSelector((state) => state.repaymentFee.showSuccessMessage);
    const successMsg = useSelector((state) => state.repaymentFee.successMessage);

    const showErrorMsg = useSelector((state) => state.repaymentFee.showErrorMessage);
    const errorMsg = useSelector((state) => state.repaymentFee.errorMessage);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const amountDue = queryParams.get('amountDue');
   
    useEffect(()=>{
        if(id){
            const data = getData.find((obj)=>obj.id==id)

            setBalance(data?.balance)
            setClassId(data?.class?.id)
            setStudentId(data?.student?.id)
            setFranchiseeSchoolFeeId(data?.franchiseeSchoolFeeId)
        }

    },[id,getData])
    useEffect(() => {
        if (formData.classId) {
            dispatch(fetchStudentsFeeCollections(formData.classId, FranchiseeSchoolId));
        }
    }, [dispatch, formData.classId]);

    

    const [selectedRows, setSelectedRows] = useState({});
    const [selectRows, setSelectRows] = useState({
        // totalPaidAmount: amountDue,
    });
    const [paid, setPaid] = useState({});
    const [unpaid, setUnpaid] = useState({});
    const [checkboxValidation, setCheckboxValidation] = useState(false);






    const shouldDisableSubmit = () => {
        const checkedCount = Object.values(selectedRows).filter((row) => row.isChecked).length;
        const rowCount = feeCollections?.feeHead?.dueDates.length;
        if (checkedCount === rowCount && Object.keys(selectRows).length === 0) {
            return true;
        }
        if (
            Object.keys(selectRows).length === 0 || Object.keys(selectRows).length > 0) {
            return false;
        }
    };
    const TextInputFieldMemoized = React.memo(TextInputField);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmit(true);
       
        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

    
        const payload = {
            paidDate: getCurrentDateTime(),
            franchiseeSchoolFeeId:franchiseeSchoolFeeId ,
            totalDue: balance,
            received: amountPaid,
            balance: balanceAmount,
            discount: selectDiscount,
            reason: formData.remark,
            netDue: netDue,
            classId:classId,
            studentId:studentId,
            hasFullyPaid: balanceAmount === 0 ? true : false


        };
        dispatch(fetchRepaymentFee(id, payload));
        setSubmit(false)
    };

    const handleClear = (e) => {
        setFormData({
        });
        setValidated(false);
        setBalanceAmount(balance);
    };
   

    const navigate = useNavigate()
   

   


    const calculateBalanceAmount = (selectedRows) => {
        let balance = 0;
        Object.values(selectedRows).forEach((term) => {
            if (term.isChecked && term.paidAmount) {
                balance += parseFloat(term.paidAmount);
            }
        });

        return balance;
    };

    const calculateAmount = calculateBalanceAmount(selectRows);


    const [netDue, setNetDue] = useState();
    const [amountPaid, setAmountPaid] = useState()
    const [balanceAmount, setBalanceAmount] = useState()

    const [selectDiscount, setSelectDiscount] = useState()
    const handleDiscount = (event) => {
        setSelectDiscount(event.target.value)
    }

    useEffect(() => {
        if (balance) {
            setNetDue(balance)
            setBalanceAmount(balance)
        }

    }, [balance])

    useEffect(() => {
        if (selectDiscount) {
            const netDue = Number(balance) - Number(selectDiscount)
            setNetDue(netDue)
            setBalanceAmount(Number(netDue) - Number(amountPaid))
        }
    }, [selectDiscount, balance])


    const handleAmountPaid = (event) => {
        setAmountPaid(event.target.value)
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
        navigate("/ViewStudentFee")
        // window.location.reload();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
      };

    useEffect(() => {
        if (amountPaid) {
            setBalanceAmount(Number(netDue) - Number(amountPaid))
        }

    }, [amountPaid, netDue])

  
    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div>

                    <h2>Fee Repayment</h2>
                    <Row>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Total Due</Form.Label>
                            <TextInputField
                                name="Total Due"
                                placeholder="Total Due"
                                textType="Alphabet"
                                disabled
                                validated={validated}
                                value={balance}
                                feedbackMessage="Please enter valid Due."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Due."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Discount</Form.Label>
                            <TextInputField
                                name="Discount"
                                textType="name"
                                value={selectDiscount}
                                onChange={(event) => handleDiscount(event)}
                                required
                                placeholder="Discount"
                                validated={validated}
                                maxLength={25}
                                feedbackMessage="Please enter Monthly Fee."
                                validationRegex={amountWithDecimalPattern}
                                validationErrorMessage="Please enter Monthly Fee."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Remarks</Form.Label>
                            <TextInputField
                                name="Remarks"
                                placeholder="Remarks"
                                value={formData.remark || ""}
                                onChange={(event) => setFormData({ ...formData, remark: event.target.value })}
                                feedbackMessage="Please enter Remarks."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Remarks."
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Due</Form.Label>
                            <TextInputField
                                name="Total Due"
                                placeholder="Total Due"
                                textType="Alphabet"
                                disabled
                                validated={validated}
                                value={netDue}
                                feedbackMessage="Please enter first name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Total Due."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Amount Paid</Form.Label>
                            <TextInputField
                                name="Monthly Fee"
                                textType="name"
                                value={amountPaid}
                                onChange={(event) => handleAmountPaid(event)}
                                required
                                placeholder="Amount Paid"
                                validated={validated}
                                maxLength={25}
                                feedbackMessage="Please enter Monthly Fee."
                                validationRegex={amountWithDecimalPattern}
                                validationErrorMessage="Please enter Monthly Fee."
                            />
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <Form.Label className="lableName">Balance</Form.Label>
                            <TextInputField
                                name="Total Due"
                                placeholder="Balance"
                                textType="Alphabet"
                                disabled
                                validated={validated}
                                value={balanceAmount}
                                feedbackMessage="Please enter first name."
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="Please enter valid Total Due."
                            />
                        </Col>
                    </Row>
                </div>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup
                    show={FeeShowErrorMsg}
                    onClose={() => {
                        setFeeShowErrorMsg(false);
                    }}
                    errorMessage={FeeErrorMsg}
                />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button
                        className="save-button-figma-div"
                        type="submit"
                        disabled={submit || shouldDisableSubmit()}
                    >
                        {submit ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </Form>
        </>
    );
};
export default DueFee;