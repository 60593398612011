import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';


const slice = createSlice(
    {

        name: "addSubject",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            successMessage: '',
            showSuccessMessage: false,
            errorMessage: '',
            showErrorMessage: false,
        },
        reducers: {
            fetchSubjectData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchSubjectSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchSubjectFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setSubjectEmpty: (state, action) => {
                state.data = action.payload;
            }
        },
    },
);

export const { fetchSubjectData, fetchSubjectSuccess, fetchSubjectFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage, setSubjectEmpty } = slice.actions;


export const postSubjectRequest = (postData) => async (dispatch) => {
    dispatch(fetchSubjectData());
    const tokenId = token();
    try {
        const response = await fetchWithAuthPost(API_PATH + "/subject", "POST", tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        }
        else {
            const responseData = await response;
            dispatch(fetchSubjectSuccess([responseData.subject]));
            dispatch(setSuccessMessage("Successful!"));
            dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;