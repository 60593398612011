import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";

const InputField = ({
  label,
  name,
  required,
  value,
  type,
  onChange,
  placeholder,
  feedbackMessage,
  colSize,
  validated,
  validationRegex,
  validationErrorMessage,
  maxLength,
  textType,
  mt4,
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    let inputValue = event.target.value;

    // Check if the input exceeds maxLength
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    const isValidInput = validationRegex.test(inputValue);

    if (isValidInput) {
      setError(null);
      onChange({ target: { name, value: inputValue } });
    } else {
      setError(
        validationErrorMessage
      );
    }
  };

  return (

    <Form.Group >
      {label && <Form.Label className="label-style">{label}</Form.Label>}


      <Form.Control
        name={name}
        className="textInput_Field"
        required
        placeholder={placeholder}
        value={value}
        type={type}
        // validated={validated}
        onChange={handleInputChange}
        maxLength={maxLength}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {error ||
          (validated && !validationRegex.test(value) && error) ||
          (validated && value.length <= 0 && feedbackMessage)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputField;