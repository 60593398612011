import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';
import { responseFormat } from '../../Auth/errorResponse';
import { setEnquiryData } from './viewDropdownEmployeeSlice';


const slice = createSlice(
  {
    name: 'employeeEnquiryFollowUp',
    initialState: {
      data: null,
      isLoading: false,
      hasError: null,
      successMessage: '',
      showSuccessMessage: false,
      errorMessage: '',
      showErrorMessage: false,
    },
    reducers: {
      fetchEmployeeEnquiryFollowupStart: (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchEmployeeEnquiryFollowupSuccess: (state, action) => {
        state.isLoading = true;
        const newData = action.payload;
        if (!state.data || state.data.length === 0) {
          state.data = newData;
          return;
        }

        // Otherwise, update existing objects or add new ones
        newData.forEach(newItem => {
          const index = state.data.findIndex(item => item.id === newItem.id);
          if (index !== -1) {
            // Replace existing item
            state.data[index] = newItem;
          } else {
            // Add new item
            state.data.push(newItem);
          }
        });
        // state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchEmployeeEnquiryFollowupFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      },
      setSuccessMessage: (state, action) => {
        state.successMessage = action.payload;
      },
      setShowSuccessMessage: (state, action) => {
        state.showSuccessMessage = action.payload;
      },
      setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
      },
      setShowErrorMessage: (state, action) => {
        state.showErrorMessage = action.payload;
      },
      // setEmployeeFollowUpFirst: (state, action) => {
      //   state.isLoading = true;
      //   state.data = action.payload
      // }
    },
  },

);

export const { fetchEmployeeEnquiryFollowupStart, fetchEmployeeEnquiryFollowupSuccess, fetchEmployeeEnquiryFollowupFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const updateEnquiry = (enquiryId, updatedEnquiryEmployeeEnquiryFollowup) => async (dispatch) => {
  dispatch(fetchEmployeeEnquiryFollowupStart());
  const tokenId = token();

  try {
    const response = await fetch(API_PATH + `/employee-enquiry/${enquiryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${tokenId}`

      },
      body: JSON.stringify(updatedEnquiryEmployeeEnquiryFollowup),
    });
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const responseData = await response.json();
      dispatch(fetchEmployeeEnquiryFollowupSuccess([responseData.data]));
      dispatch(setSuccessMessage("Successful!"));
      dispatch(setEnquiryData([responseData.data]));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    const errMessage = responseFormat({ error })
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};
export default slice.reducer;
