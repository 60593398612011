import React from 'react';
import Select from 'react-select';
import './SelectDropdown.css';

const customStyles = (validated, hasValue) => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: '5px',
        width: '100%',
        color: '#A7A7A7 !important',
        fontFamily: 'Montserrat !important',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        borderColor: hasValue ? "#198754" : (validated ? "red" : "#ccc"),
    }),
    menu: (provided) => ({
        ...provided,
        // maxHeight: '130px',
        // overflowY: 'auto',
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        zIndex: 10000,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#A7A7A7', // Change the color as needed
        fontFamily: 'Montserrat',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
    }),
});

const SelectDropdown = ({ options, value, onChange, placeholder, clearable = false, validated, isDisabled }) => {
    const hasValue = value !== null && value !== undefined; // Check if a value is selected
    const styles = customStyles(validated, hasValue);
    return (
        <div>
            <Select
                // className="search-dropdown1"
                isSearchable
                options={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                styles={styles}
                isDisabled = {isDisabled}
            />
            {clearable && value && (
                <button onClick={() => onChange(null)}>Clear</button>
            )}
        </div>
    );
};

export default SelectDropdown;