import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { fetchStatePostData } from './stateSlice';


const slice = createSlice(
  {
    name: 'viewState',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchStateData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchStateSuccess: (state, action) => {
        state.isLoading = false;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchEventAndHolidayFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },

);

export const { fetchStateData, fetchStateSuccess, fetchEventAndHolidayFailure } = slice.actions;

export const fetchStateDataOnce = () => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewState;
  const tokenId = token();
  if (data === null && !isLoading) {
    dispatch(fetchStateData());

    try {
      const response = await fetch(API_PATH + '/states', {

        method: "GET",
        headers: {
          "Authorization": `Bearer ${tokenId}`
        },
      })

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchStatePostData([]))
      dispatch(fetchStateSuccess(data1));
    } catch (error) {
      dispatch(fetchEventAndHolidayFailure());
    }
  }
}
export default slice.reducer;
