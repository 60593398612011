
import {createSlice} from '@reduxjs/toolkit';
import { getEmployeeAreas, getEmployeeCities, getEmployeeList,getEmployeeStates,postEmployeeData, putEmployeeData } from './allRequests';
import { initialState } from './teachersInitialState';
import { getEmployeeQualifications } from './allRequests';


const employeeSlicer = createSlice({
    name:'employee',
    initialState,
    reducers:{
        getCustomHookOptions:(state,action)=>{
            const [qualificationsOptions, statesOptions, citiesOptions,
            designationOptions,areasOptions] = action.payload;
            state.teachers.customHookOptions.states = statesOptions;
            state.teachers.customHookOptions.cities = citiesOptions;
            state.teachers.customHookOptions.areas = areasOptions;
            state.teachers.customHookOptions.designation = designationOptions;
            state.teachers.customHookOptions.qualifications = qualificationsOptions;
        },
        getEmployeeViewList:(state,action)=>{
            state.teachers.employee.employeeViewList=[...state.teachers.employee.employeeViewList,action.payload]
        },
        getEmployeeRow:(state,action)=>{
            const row = action.payload;
            state.teachers.employeeRow=row
        },
        // modifyEmployeeList:(state,action)=>{
        //     const {editedRow,editedData} = action.payload;
        //     state.teachers.employee.employeeViewList.splice(editedRow,1,editedData)
        // },
        putEmployeeToList:(state,action)=>{
            state.teachers.getEmployeeViewList.data=action.payload;
            state.teachers.putEmployeeData.data=null;

        },
        postingEmplyee:(state,action)=>{
            state.teachers.getEmployeeViewList.data=action.payload;
            state.teachers.postEmployeeData.data=null;
        },
        changeLoadingStateToIdle:(state)=>{
            state.teachers.postEmployeeData.loading='idle'
        },
        changeLoadingStateToIdleEditEmployee:(state)=>{
            state.teachers.putEmployeeData.loading='idle'
        },
        useCitiesStateValue:(state,action)=>{
            state.customHooks.useCitiesStates={...action.payload}
        },
        changeErrorMessage:(state,action)=>{
            state.teachers.postEmployeeData.error=""
        }

    },
    extraReducers:(builder)=>{
        builder
        //postEmployeeData
        .addCase(postEmployeeData.pending,(state)=>{
            state.teachers.postEmployeeData.loading='pending'
        })
        .addCase(postEmployeeData.fulfilled,(state,action)=>{
            const response = action.payload;
            if(response.hasOwnProperty('errors')){
                state.teachers.postEmployeeData.loading='rejected';
                state.teachers.postEmployeeData.error=response.errors;
            }else if(response.hasOwnProperty('error')){
                state.teachers.postEmployeeData.loading='rejected';
                state.teachers.postEmployeeData.error=response.error;
            }else{
                state.teachers.postEmployeeData.loading='fulfilled';
                state.teachers.postEmployeeData.data = action.payload.data;
                state.teachers.postEmployeeData.error=null;
            }

        })
        .addCase(postEmployeeData.rejected,(state,action)=>{
            state.teachers.postEmployeeData.loading='rejected';
            state.teachers.postEmployeeData.error=action.payload.errors
        })
        //getEmployeeList request
        .addCase(getEmployeeList.pending,(state)=>{
            state.teachers.getEmployeeViewList.loading='pending'
        })
        .addCase(getEmployeeList.fulfilled,(state,action)=>{
                state.teachers.getEmployeeViewList.loading='fulfilled';
                state.teachers.getEmployeeViewList.data =action.payload;
                state.teachers.getEmployeeViewList.error=null;
        })
        .addCase(getEmployeeList.rejected,(state,action)=>{
            state.teachers.getEmployeeViewList.loading='rejected';
            state.teachers.getEmployeeViewList.error=action.payload.error
        })
        // getEmployeeQualifications request
        .addCase(getEmployeeQualifications.pending,(state)=>{
            state.teachers.getEmployeeQualifications.loading='pending'
        })
        .addCase(getEmployeeQualifications.fulfilled,(state,action)=>{
            state.teachers.getEmployeeQualifications.loading='fulfilled'
            state.teachers.getEmployeeQualifications.data=action.payload
        })
        .addCase(getEmployeeQualifications.rejected,(state,action)=>{
            state.teachers.getEmployeeQualifications.loading='rejected';
            state.teachers.getEmployeeQualifications.error=action.payload
        })
        //getStates request
        .addCase(getEmployeeStates.pending,(state)=>{
            state.teachers.getEmployeeStates.loading='pending'
        })
        .addCase(getEmployeeStates.fulfilled,(state,action)=>{
            state.teachers.getEmployeeStates.loading='fulfilled'
            state.teachers.getEmployeeStates.data=action.payload
        })
        .addCase(getEmployeeStates.rejected,(state,action)=>{
            state.teachers.getEmployeeStates.loading='rejected';
            state.teachers.getEmployeeStates.error=action.payload
        })
        //getCities request
        .addCase(getEmployeeCities.pending,(state)=>{
            state.teachers.getEmployeeCities.loading='pending'
        })
        .addCase(getEmployeeCities.fulfilled,(state,action)=>{
            state.teachers.getEmployeeCities.loading='fulfilled'
            state.teachers.getEmployeeCities.data=action.payload
        })
        .addCase(getEmployeeCities.rejected,(state,action)=>{
            state.teachers.getEmployeeCities.loading='rejected';
            state.teachers.getEmployeeCities.error=action.payload
        })
        //get employee areas
        .addCase(getEmployeeAreas.pending,(state)=>{
            state.teachers.getEmployeeAreas.loading='pending'
        })
        .addCase(getEmployeeAreas.fulfilled,(state,action)=>{
            state.teachers.getEmployeeAreas.loading='fulfilled'
            state.teachers.getEmployeeAreas.data=action.payload
        })
        .addCase(getEmployeeAreas.rejected,(state,action)=>{
            state.teachers.getEmployeeAreas.loading='rejected';
            state.teachers.getEmployeeAreas.error=action.payload
        })
        //PUT employee data
        .addCase(putEmployeeData.pending,(state)=>{
            state.teachers.putEmployeeData.loading='pending'
        })
        .addCase(putEmployeeData.fulfilled,(state,action)=>{
            const response = action.payload;
            if(response.hasOwnProperty('errors')){
                state.teachers.putEmployeeData.loading='rejected';
                state.teachers.putEmployeeData.error=response.errors;
            }else if(response.hasOwnProperty('error')){
                state.teachers.putEmployeeData.loading='rejected';
                state.teachers.putEmployeeData.error=response.error;
            }else{
                state.teachers.putEmployeeData.loading='fulfilled';
                state.teachers.putEmployeeData.data = action.payload.data;
                state.teachers.postEmployeeData.error=null;
            }
        })
        .addCase(putEmployeeData.rejected,(state,action)=>{
            state.teachers.putEmployeeData.loading='rejected';
            state.teachers.putEmployeeData.error=action.payload
        })
    }
})

export const {getEmployeeViewList,getEmployeeRow,changeLoadingStateToIdleEditEmployee,postingEmplyee,
    changeLoadingStateToIdle,useCitiesStateValue,changeErrorMessage,putEmployeeToList
   //modifyEmployeeList,
} =employeeSlicer.actions;
export default employeeSlicer.reducer;