import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import SelectDropdown from "../SelectDropdown";
import { pettyExpensesViewSlicer } from "./slice/pettyExpensesViewSlicer";
import { fetchExpensesTypeView } from "./slice/expensesTypeViewSlicer";
import TableView from "../reUsableForm/table/table";
import { format } from 'date-fns';

const PettyExpensesView = () => {
    const dispatch = useDispatch();
    const apiData = useSelector((state) => state.pettyExpensesView.data) || {};
    const apiDataa = useSelector((state) => state.expensesTypeViewSlicer.data) || [];
    const postData = useSelector((state) => state.expensesTypeSlicer.data) || [];

    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        expenseTypeId: null,
    });
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({
        fromDate: false,
        toDate: false,
        expenseTypeId: false,
    });

    useEffect(() => {
        dispatch(fetchExpensesTypeView());
        dispatch(pettyExpensesViewSlicer());
    }, [dispatch]);

    // Combine data for dropdown
    const combinedData = [...(apiDataa || []), ...postData];

    // Process API data for the table
    const apiDataArray = Array.isArray(apiData.petty_expenses) ? apiData.petty_expenses : [];
    const processedData = apiDataArray.map((row, index) => ({
        ...row,
        index: index + 1,
        amountReceived: parseFloat(row.received) || 0,  // Ensure values are numbers
        expenseTypeName: row.expenseType ? row.expenseType.name : 'N/A',
        formattedDate: row.date ? format(new Date(row.date), 'dd-MM-yyyy') : 'N/A',
        amount: parseFloat(row.amount) || 0,
        school: row.franchiseeSchool.name
    }));

    // Use API data for totals
    const totalAmount = apiData.totalAmount || 0;
    const totalReceived = apiData.totalReceived || 0;

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);

        // Validate form data before making API call
        if (formData.fromDate && formData.toDate && formData.expenseTypeId) {
            dispatch(pettyExpensesViewSlicer(formData.fromDate, formData.toDate, formData.expenseTypeId.id));
        } else {
            setFormErrors({
                fromDate: !formData.fromDate,
                toDate: !formData.toDate,
                expenseTypeId: !formData.expenseTypeId,
            });
        }
    };

    const handleClear = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            expenseTypeId: null,
        });
        dispatch(pettyExpensesViewSlicer());
        setFormErrors({
            fromDate: false,
            toDate: false,
            expenseTypeId: false,
        });
        setValidated(false);
    };

    const columns = [
        { field: 'index', header: 'Sr.No' },
        { field: 'school', header: 'School' },
        { field: 'formattedDate', header: 'Date' },
        { field: 'expenseTypeName', header: 'Expenses Type' },
        { field: 'amount', header: 'Expense Amount' },
        { field: 'amountReceived', header: 'Received Amount' },
        { field: 'balance', header: 'Balance' },
        { field: 'description', header: 'Description' },
    ];

    return (
        <>
            <h4 className="content-header">Petty Expenses View</h4>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="From Date*"
                            value={formData.fromDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, fromDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, fromDate: !date }));
                            }}
                            required
                            name="From Date"
                            placeholder="From Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the from date."
                            validated={validated}
                        />
                        {formErrors.fromDate && (
                            <div className="error-message">Please select the from date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="To Date*"
                            value={formData.toDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, toDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, toDate: !date }));
                            }}
                            required
                            name="To Date"
                            placeholder="To Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the to date."
                            validated={validated}
                        />
                        {formErrors.toDate && (
                            <div className="error-message">Please select the to date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={formData.expenseTypeId ? { id: formData.expenseTypeId.id, label: formData.expenseTypeId.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData(prevFormData => ({
                                    ...prevFormData,
                                    expenseTypeId: selectedOption
                                }));
                                setFormErrors(prevFormErrors => ({ ...prevFormErrors, expenseTypeId: !selectedOption }));
                            }}
                            placeholder="Select expenses"
                        />
                        {formErrors.expenseTypeId && (
                            <div className="error-message">
                                Please Select expenses.
                            </div>
                        )}
                    </Col>
                </Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-5%",
                        marginLeft: "35%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>
            <div className="totals" style={{
                color: "#464E5F",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "left",
                whiteSpace: "nowrap",
                padding: "8px",
                marginTop: "2%",
                display: 'flex',
                marginBottom: "-2%"
            }}>
                <p>Total Expense Amount: {totalAmount.toFixed(2)}</p>
                <p style={{ marginLeft: "5%" }}>Total Received Amount: {totalReceived.toFixed(2)}</p>
            </div>

            <Row>
                {processedData.length > 0 ? (
                    <>
                        <TableView columns={columns} data={processedData}
                            isExport={true}
                            isExcel={true}
                            excelFileName={`Petty_Expenses`}
                        />
                    </>
                ) : (
                    <div>No data available</div>
                )}
            </Row>
        </>
    );
};

export default PettyExpensesView;
