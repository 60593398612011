export const getOrPutStoreProcess = (input) => {
    const keysToRemove = ['isActive', 'createdBy', 'updatedBy', 'createdAt', 'updatedAt'];
  
    function removeKeysRecursively(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj; // If it's not an object, return as-is
      }
  
      if (Array.isArray(obj)) {
        // If it's an array, recursively remove keys from each element
        return obj.map(item => removeKeysRecursively(item));
      }
  
      const result = {};
      for (const key in obj) {
        if (!keysToRemove.includes(key)) {
          result[key] = removeKeysRecursively(obj[key]);
        }
      }
  
      return result;
    }
  
    const afterRemovedKeys = removeKeysRecursively(input);
  
    const renameAndAssignId = (eachObj) => {
      const newObj = { ...eachObj };
      if (newObj.permanentAddress) {
        newObj.Parea = newObj.permanentAddress.areaId;
        newObj.Pcity = newObj.permanentAddress.cityId;
        newObj.Pstate = newObj.permanentAddress.stateId;
        newObj.Paddress = newObj.permanentAddress.address;
        newObj.Ppin = newObj.permanentAddress.pincode;
        delete newObj.permanentAddress;
      }
      if (newObj.currentAddress) {
        newObj.Carea = newObj.currentAddress.areaId;
        newObj.Ccity = newObj.currentAddress.cityId;
        newObj.Cstate = newObj.currentAddress.stateId;
        newObj.Caddress = newObj.currentAddress.address;
        newObj.Cpin = newObj.currentAddress.pincode;
        delete newObj.currentAddress;
      }
      return newObj;
    };
  
    if (Array.isArray(afterRemovedKeys)) {
      const reAssignedWithIds = afterRemovedKeys.map(renameAndAssignId);
      return reAssignedWithIds;
    } else if (!Array.isArray(input) && typeof input === "object") {
      return renameAndAssignId(afterRemovedKeys);
    }
  
    return input;
  };

export const getOrPutViewProcess = (input, areas, qualifications) => {
    const replaceIdWithValue = (id, type) => {
        const findObjectById = (arr, id) => {
            return arr?.find(each => +each.id === +id);
        };

        switch (type) {
            case "qual":
                const qualObj = findObjectById(qualifications?qualifications:[], id);
                return qualObj ? qualObj.name : null;

            case "area":
                const areaObj = findObjectById(areas?areas:[], id);
                return areaObj ? areaObj.name : null;

            case "city":
                const cityObj = findObjectById(areas?areas:[], id);
                return cityObj ? cityObj.city?.name : null;

            case "state":
                const stateObj = findObjectById(areas?areas:[], id);
                return stateObj ? stateObj.city?.state?.name : null;

            default:
                return null;
        }
    };

    const replaceIdWithValues = (obj) => {
        const newObj = {...obj};
        newObj.qualificationId = replaceIdWithValue(newObj?.qualificationId, "qual");
        newObj.Parea = replaceIdWithValue(newObj.Parea, "area");
        newObj.Pcity = replaceIdWithValue(newObj.Pcity, "city");
        newObj.Pstate = replaceIdWithValue(newObj.Pstate, "state");
        newObj.Carea = replaceIdWithValue(newObj.Carea, "area");
        newObj.Ccity = replaceIdWithValue(newObj.Ccity, "city");
        newObj.Cstate = replaceIdWithValue(newObj.Cstate, "state");
        return newObj;
    };

    if (Array.isArray(input)) {
        return input.map(replaceIdWithValues);
    } else if (!Array.isArray(input) && typeof input === "object") {
        return replaceIdWithValues(input);
    } else {
        return null;
    }
};

export function calculateAge(dateOfBirth) {
  const birthDate = new Date(dateOfBirth);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If the birth month is yet to come in the current year or
  // if it's the same month but the birth day hasn't occurred yet,
  // subtract 1 from the age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}
