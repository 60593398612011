import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomCheckbox = ({ label, checked, onChange }) => {
    return (
        <FormControlLabel
            label={label}
            labelPlacement="start"
            control={<Checkbox  checked={checked} onChange={onChange} />}
            style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}
        />
    );
};

export default CustomCheckbox;
