import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { responseFormat } from '../../Auth/errorResponse';


const slice = createSlice(
    {
        name: "addClass",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchAddClassData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchAddClassSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchAddClassFailure: (state) =>{
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },
);

export const { fetchAddClassData, fetchAddClassSuccess, fetchAddClassFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const postAddClassRequest = (postData) => async (dispatch) => {
    dispatch(fetchAddClassData());

    const tokenId = token()
    try{
        const response = await fetchWithAuthPost (API_PATH + "/class", 'POST', tokenId, postData); 
            
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else{
        const responseData = await response;
        dispatch(fetchAddClassSuccess([responseData.data]));
        dispatch(setSuccessMessage("Successful!"));
        dispatch(setShowSuccessMessage(true));
        }
    } catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;