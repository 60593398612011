

export const fetchWithAuthPost = async (url, method, token, bodyData) => {
    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json' // Set appropriate content type
            },
            body: JSON.stringify(bodyData) // Include the request body for POST
        }).catch((error) => {
            const errorData = { "errors": [{ "message": "Internal Server Error" }] }
            throw new Error(JSON.stringify(errorData))
        });

        if (!response.ok) {
            if (response.status === 400) {
                return response.json().then(errorData => {
                    throw new Error(JSON.stringify(errorData));
                });
            } else
                if (response.status === 500 || response.status === 404 || response.status === 403 || response.status === 502) {
                    const error = { "errors": [{ "message": "Internal Server Error" }] }
                    throw new Error(JSON.stringify(error));
                }
            throw new Error(response.status);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

