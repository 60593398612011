import React from 'react'

const Statistics = () => {
  return (
   <>
   <h1>Statistics Page</h1>
   </>
  )
}

export default Statistics;