/*
    authors: Naveen
    date: 01-11-2023
    update:01-11-2023 
    version: 1.0
*/

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SelectDropdown from "../SelectDropdown";
import DatePickerPast from "../reUsableForm/DatePicketPast_present";
import { emailRegex, validationRegexAddress, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyPincode } from "../reUsableForm/regex";
import FileUploadField from "../../ReusableFields/FileUpload/FileUploadField";
import FileUpload from "../../ReusableFields/FileUpload/fileUpload";
import { useDispatch, useSelector } from "react-redux";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { PostFranchiseOwner, setShowErrorMessage, setShowSuccessMessage } from "./slice/franchiseOwnerSlice";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import EmailInputField from "../../ReusableFields/EmailFields/EmailField";
import { API_PATH } from "../../Api/api";
import { folderPath, franchiseOwnerDocument, franchiseOwnerProfile } from "../../Api/folderPath";
import { token } from "../../Api/token";
import { useNavigate, useLocation } from 'react-router-dom';
import FormatDate from "../reUsableForm/dateFormat";


export const FranchiseOwnerCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const defaultName = queryParams.get('name') || '';
    const defaultEmail = queryParams.get('email') || '';
    const defaultContactNumber = queryParams.get('contactNumber') || '';
    const defaultAddress = queryParams.get('address') || '';
    const defaultFranchiseId = queryParams.get('franchiseeEnquiryId') || '';
    const [formData, setFormData] = useState({
        name: defaultName,
        email: defaultEmail,
        contactNumber: defaultContactNumber,
        franchiseeEnquiryId: defaultFranchiseId,
        dateOfBirth: '',
        profilePictureUrl: '',
        documentUrls: [],
    })

    const [formErrors, setFormErrors] = useState({
        gender: false, permanentAddress: false, currentAddress: false,
    })
    const [validated, setValidated] = useState(false);
    const [permanentAddress, setPermanentAddress] = useState({
        type: 'permanent',
        address: defaultAddress,
    })
    const [currentAddress, setCurrentAddress] = useState({ type: 'current' })
    const [permanentArea, setPermanentArea] = useState({});
    const [currentArea, setCurrentArea] = useState({});
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [image, setImage] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const fileNamesArray = Array.from(
        { length: documents.length },
        (_, index) => documents[index].name
    );

    // const handleClassChange = () => {
    //     setSelectedSection("");
    //     setSelectedClassData([]);
    //     setFilteredStudentNames([]);
    //     setFormData({ ...formData, studentNameId: "" });
    //     setDropDowns({ ...dropDowns, studentName: "" });
    //     setFormErrors({ ...formErrors, studentName: false });
    //     setShowTable(false);

    //     const classSectionData = classesClassSectionData.find(
    //         (c) => c.id == selectedClass
    //     );
    //     setSelectedClassData(classSectionData);

    //     if (classSectionData && classSectionData.classSections.length === 0) {
    //         setSelectedSection(classSectionData.classesclassSection);
    //     }
    // };

    const getBorderColor = () => {
        if (documents && documents.length > 0) {
            return "#198754"; // Green when there are selected documents
        } else if (validated) {
            return "red"; // Red when validated but no documents selected
        } else {
            return "#ccc"; // Default color
        }
    };

    const handleDocumentChange = (event) => {
        const files = event.target.files;
        setDocuments(files);
        // setFormErrors({ ...formErrors, selectedDocuments: false });
    };

    const handleUpload1 = async () => {
        try {
            if (!documents) {
                console.error("No images to upload");
                return;
            }

            const formData = new FormData();

            for (let i = 0; i < documents.length; i++) {
                formData.append("files", documents[i]);
            }

            const response = await fetch(`${API_PATH}/uploadFiles`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${token()}`, // Assuming token() retrieves the token
                },
            });

            if (response.ok) {
                const result = await response.json();
                const fileIds = result.files.map((id) => String(id.fileId));
                return fileIds;


            } else {
                console.error("File upload failed");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const getBorderColorImages = () => {
        if (image && image.length > 0) {
            return "#198754"; // Green when there are selected documents
        } else if (validated) {
            return "red"; // Red when validated but no documents selected
        } else {
            return "#ccc"; // Default color
        }
    };
    const handleUploadImage = (event) => {
        const file = event.target.files;
        setImage(file);
        const hasImage = file && file.length > 0;
        setFormErrors({ ...formErrors, selectedImage: !hasImage });
    };
    const handleUpload = async () => {
        try {
            if (!image) {
                console.error("No images to upload");
                return;
            }

            const formData = new FormData();
            formData.append("file", image[0]);

            const response = await fetch(`${API_PATH}/uploadFile`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${token()}`,
                },
            });

            if (response.ok) {
                const result = await response.json();
                setFormData((prevData) => ({
                    ...prevData,
                    profilePictureUrl: result.fileId.toString(),
                }));
                setSelectedImage(result.fileId.toString());
                return result.fileId.toString()
            } else {
                console.error("File upload failed");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const areaDetails = useSelector((state) => state.areaDetails.data) || [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAreaData());
    }, [dispatch]);


    useEffect(() => {
        if (permanentAddress.areaId) {
            const paramentAreaDetails = (areaDetails.find(x => x.id == permanentAddress.areaId));
            setPermanentAddress({
                ...permanentAddress, cityName: paramentAreaDetails?.city.name, cityId: paramentAreaDetails?.city.id,
                stateName: paramentAreaDetails?.city?.state.name, stateId: paramentAreaDetails?.city?.state.id
            });

        }
    }, [permanentAddress.areaId]);

    useEffect(() => {
        if (currentAddress.areaId) {
            const currentAreaDetails = (areaDetails.find(x => x.id == currentAddress.areaId));
            setCurrentAddress({
                ...currentAddress, cityName: currentAreaDetails?.city.name, cityId: currentAreaDetails?.city.id,
                stateName: currentAreaDetails?.city?.state.name, stateId: currentAreaDetails?.city?.state.id
            });

        }
    }, [currentAddress.areaId])




    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmit(true);
        const newFormErrors = {
            gender: !formData.gender,
            dateOfBirth: !formData.dateOfBirth,
            // profilePictureUrl: !formData.profilePictureUrl,
            // documentUrls: !formData.documentUrls.length > 0,
            permanentAddress: !permanentAddress.areaId,
            currentAddress: !currentAddress.areaId,
            // selectedImage: image?.length === 0,
            // selectedDocuments: documents?.length === 0,
        }
        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            setSubmit(false);
            return;
        }
        if (image) {
            var file_id = await handleUpload();
            // setFormData(formData.profilePictureUrl)
        }


        if (documents.length > 0) {
            // setDocuments(selectedImages);
            var fileIds = await handleUpload1();
        }

        const sendData = {
            "name": formData.name,
            "dateOfBirth": FormatDate(formData.dateOfBirth),
            "gender": formData.gender,
            "email": formData.email,
            // "contactNumber": formData.contactNumber,
            "primaryContactNumber": formData.contactNumber,
            "secondaryContactNumber": formData.secondaryContactNumber,
            "permanentAddress": formData.permanentAddress,
            "currentAddress": formData.currentAddress,
            // "profilePictureUrl": formData.profilePictureUrl,
            // "documentUrls": formData.documentUrls,
            "documentUrls": fileIds,
            "franchiseeEnquiryId": formData.franchiseeEnquiryId,
            "profilePictureUrl": file_id,
            "permanentAddress": { ...permanentAddress },
            "currentAddress": { ...currentAddress }
        }

        dispatch(PostFranchiseOwner(sendData))
        setSubmit(false);
    }



    const handleCancel = () => {
        setFormData({
            dateOfBirth: '',
            email: "",
            documentUrls: [],
            profilePictureUrl: '',
        });
        setPermanentAddress({});
        setCurrentAddress({})
        setValidated(false);
        setSelectedImage([]);
        setDocuments([]);
        setImage([]);
        setPermanentArea({ area: [] });
        setCurrentArea({ area: [] });
        setFormErrors({})
    }


    const showSuccessMsg = useSelector((state) => state.franchiseOwner.showSuccessMessage);
    const successMsg = useSelector((state) => state.franchiseOwner.successMessage);

    const showErrorMsg = useSelector((state) => state.franchiseOwner.showErrorMessage);
    const errorMsg = useSelector((state) => state.franchiseOwner.errorMessage);

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
        navigate('/franchiseFollowUpList');
        window.location.reload();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };


    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="content-header">Franchise Owner creation</div>
                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Name"
                            placeholder="Name"
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={formData.name}
                            onChange={(event) => setFormData({ ...formData, name: (event.target.value) })}
                            feedbackMessage="Please enter first name."
                            validationRegex={validationRegexOnlyAlpha}
                            validationErrorMessage="Please enter valid first name."
                        />
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <Form.Group >
                            <Form.Label className='lableName'>Gender</Form.Label>
                            <div>
                                <Form.Check
                                    inline
                                    label="Male"
                                    type="radio"
                                    name="gender"
                                    value="Male"
                                    className='malebutton'
                                    checked={formData.gender === "Male"}
                                    onChange={(event) => {
                                        setFormData({ ...formData, gender: event.target.value })
                                        setFormErrors({ ...formErrors, gender: false })
                                    }}
                                />
                                <Form.Check
                                    inline
                                    label="Female"
                                    type="radio"
                                    name="gender"
                                    value="Female"
                                    className='malebutton'
                                    checked={formData.gender === "Female"}
                                    onChange={(event) => {
                                        setFormData({ ...formData, gender: event.target.value })
                                        setFormErrors({ ...formErrors, gender: false })
                                    }}
                                />

                            </div>

                            {formErrors.gender && (
                                <div className="error-message">
                                    Please select gender.
                                </div>
                            )}
                        </Form.Group>
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <Form.Group>
                            <div
                                className="uploadDoc"
                            // style={{
                            //     border: `1px solid ${getBorderColorImages()}`, // Dynamic border color
                            // }}
                            >
                                <div className="layout1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                                            fill="#636363"
                                        />
                                    </svg>
                                    <div className="title1">Upload&nbsp;Images</div>
                                </div>
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg'"
                                    onChange={handleUploadImage}
                                    style={{
                                        width: "100%",
                                        // height: "100%",
                                        opacity: 0,
                                        cursor: "pointer",
                                        position: "absolute",
                                        zIndex: 1,
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </div>
                            <div>
                                <p>{image?.[0]?.name}</p>
                            </div>
                        </Form.Group>
                        {formErrors.selectedImage && (
                            <div className="error-message">Please select image.</div>
                        )}
                    </Col>

                    <Col className="rowCss" sm={12} xs={12} md={6} lg={3}>
                        <DatePickerPast
                            name="Date Of Birth"
                            placeholder="DOB*"
                            required
                            value={formData.dateOfBirth}
                            onChange={(value) => {
                                setFormData({ ...formData, dateOfBirth: (value) })
                                setFormErrors({ ...formErrors, dateOfBirth: false })
                            }}
                            validated={validated}
                            feedbackMessage="Please enter date of birth."
                            validationErrorMessage="Please enter a valid date of birth."
                        />
                        {formErrors.dateOfBirth && (
                            <div className="error-message">
                                Please select date of birth.
                            </div>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <EmailInputField
                            name="email"
                            placeholder="Email*"
                            type="email"
                            required
                            validated={validated}
                            value={formData.email}
                            onChange={(event) => setFormData({ ...formData, email: (event.target.value) })}
                            feedbackMessage="Please enter Email"
                            validationRegex={emailRegex}
                            validationErrorMessage="Please enter valid Email."

                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Mobile No"
                            placeholder="Mobile Number"
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={formData.contactNumber}
                            onChange={(event) => setFormData({ ...formData, contactNumber: (event.target.value) })}
                            feedbackMessage="Please enter Mobile Number."
                            validationRegex={validationRegexMobileNo}
                            validationErrorMessage="Please enter valid Mobile Number."
                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <TextInputField
                            name="Mobile No"
                            placeholder=" Alternate Mobile Number"
                            textType="Alphabet"
                            // required
                            // validated={validated}
                            value={formData.secondaryContactNumber}
                            onChange={(event) => {
                                setFormData({ ...formData, secondaryContactNumber: (event.target.value) })
                            }}
                            feedbackMessage="Please enter Alternate Mobile Number."
                            validationRegex={validationRegexMobileNo}
                            validationErrorMessage="Please enter valid Alternate Mobile Number."
                        />
                    </Col>

                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <Form.Group>
                            <div
                                className="uploadDoc"
                            // style={{
                            //     border: `1px solid ${getBorderColor()}`,
                            // }}
                            >
                                <div className="layout1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                                            fill="#636363"
                                        />
                                    </svg>
                                    <div className="title1">Upload&nbsp;Doc</div>
                                </div>
                                <input
                                    type="file"
                                    accept=".pdf, .doc"
                                    multiple
                                    onChange={handleDocumentChange}
                                    style={{
                                        width: "100%",
                                        // height: "100%",
                                        opacity: 0,
                                        cursor: "pointer",
                                        position: "absolute",
                                        zIndex: 1,
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </div>
                            <div>
                                {fileNamesArray?.map((name, index) => {
                                    // const pathParts = name?.replace(/^.*?-(.*?)-/, '');
                                    // const lastPart = pathParts[pathParts?.length - 1];
                                    // const path = lastPart?.split("-");
                                    // const lastPath = path?.slice(2)?.join("-");
                                    return <p key={index}>{name}</p>;
                                })}
                            </div>
                        </Form.Group>
                        {formErrors.selectedDocuments && (
                            <div className="error-message">
                                Please select selected documents.
                            </div>
                        )}
                    </Col>
                </Row>

                <Row>
                    {/* <p className="sub-content-header" style={{ margin: '0.5rem', height: '5px' }}>Permanent Address </p> */}
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="Permanent Address"
                            placeholder="Permanent Address "
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={permanentAddress.address}
                            onChange={(event) => setPermanentAddress({ ...permanentAddress, address: (event.target.value) })}
                            feedbackMessage="Please enter Permanent Address."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter valid Permanent Address."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3}>

                        <SearchableDropDownField
                            value={permanentArea.area}
                            className="input-style"
                            onChange={(selectedOption) => {
                                setPermanentAddress({ ...permanentAddress, areaId: selectedOption.id });
                                setPermanentArea({ ...permanentArea, area: selectedOption })
                                setFormErrors({ ...formErrors, permanentAddress: !selectedOption })
                            }}
                            placeholder="Select Area"
                            options={areaDetails.map((option, index) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name,
                            }))}
                            feedbackMessage="Select Area"
                            validated={validated}

                        />
                        {formErrors.permanentAddress && (
                            <div className="error-message">
                                Please select area .
                            </div>
                        )}
                    </Col>

                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="Permanent Address"
                            placeholder="City "
                            textType="Alphabet"
                            disabled
                            validated={validated}
                            value={permanentAddress.cityName}
                            feedbackMessage="Please enter  city."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter  city."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="Permanent Address"
                            placeholder="State "
                            textType="Alphabet"
                            disabled
                            validated={validated}
                            value={permanentAddress.stateName}
                            feedbackMessage="Please enter state."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter state."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="Permanent Address"
                            placeholder="Pin Code "
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={permanentAddress.pincode}
                            onChange={(event) => {
                                setPermanentAddress({ ...permanentAddress, pincode: Number(event.target.value) })
                            }}
                            feedbackMessage="Please enter pin code."
                            validationRegex={validationRegexOnlyPincode}
                            validationErrorMessage="Please enter valid pin code."
                        />

                    </Col>
                </Row>
                <Row>
                    {/* <p className="sub-content-header" style={{ margin: '0.5rem', height: '5px' }}>Current Address </p> */}
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="Current Address"
                            placeholder="Current Address"
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={currentAddress.address}
                            onChange={(event) => {
                                setCurrentAddress({ ...currentAddress, address: (event.target.value) })

                            }}
                            feedbackMessage="Please enter Current Address."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter valid Current Address."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3}>
                        <SearchableDropDownField
                            value={currentArea.area}
                            className="input-style"
                            onChange={(selectedOption) => {
                                setCurrentAddress({ ...currentAddress, areaId: selectedOption.id })
                                setCurrentArea({ ...currentArea, area: selectedOption })
                                setFormErrors({ ...formErrors, currentAddress: false })
                            }}
                            placeholder="Select Area"
                            options={areaDetails.map((option, index) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name,
                            }))}
                            feedbackMessage="Select Area"
                            validated={validated}

                        />
                        {formErrors.currentAddress && (
                            <div className="error-message">
                                Please select area .
                            </div>
                        )}
                    </Col>

                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3} >
                        <TextInputField
                            name="current Address"
                            placeholder="City "
                            textType="Alphabet"
                            disabled
                            validated={validated}
                            value={currentAddress.cityName}
                            feedbackMessage="Please enter city."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter valid city."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3}>
                        <TextInputField
                            name="current Address"
                            placeholder="State "
                            textType="Alphabet"
                            disabled
                            validated={validated}
                            value={currentAddress.stateName}
                            feedbackMessage="Please enter state."
                            validationRegex={validationRegexAddress}
                            validationErrorMessage="Please enter valid state."
                        />
                    </Col>
                    <Col className="rowCss" sm={6} xs={12} md={6} lg={3}>
                        <TextInputField
                            name="current Address"
                            placeholder="Pin Code "
                            textType="Alphabet"
                            required
                            validated={validated}
                            value={currentAddress.pincode}
                            onChange={(event) => {
                                setCurrentAddress({ ...currentAddress, pincode: Number(event.target.value) })
                            }}
                            feedbackMessage="Please enter pin code."
                            validationRegex={validationRegexOnlyPincode}
                            validationErrorMessage="Please enter valid pin code."
                        />
                    </Col>
                </Row>

                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleCancel}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit" disabled={submit}>
                        {submit ? "Submitting..." : "Submit"}
                        {/* submit */}
                    </button>
                </div>
            </Form>
        </>
    )
}