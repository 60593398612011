import { createSlice } from '@reduxjs/toolkit';
import { token } from "../../../Api/token";
import { responseFormat } from '../../Auth/errorResponse';
import { API_PATH } from '../../../Api/api';
// import { setRoyaltyAmountData } from "./viewRoyaltyAmountSlice";


const slice = createSlice(
  {
    name: 'editRoyalAmount',
    initialState: {
      data: null,
      isLoading: false,
      hasError: null,
      successMessage: '',
      showSuccessMessage: false,
      errorMessage: '',
      showErrorMessage: false,
    },
    reducers: {
      fetchEditRoyalAmountStart: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchEditRoyalAmountSuccess: (state, action) => {
        state.isLoading = true;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchEditRoyalAmountFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      },
      setSuccessMessage: (state, action) => {
        state.successMessage = action.payload;
      },
      setShowSuccessMessage: (state, action) => {
        state.showSuccessMessage = action.payload;
      },
      setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
      },
      setShowErrorMessage: (state, action) => {
        state.showErrorMessage = action.payload;
      },
    },
  },

);

export const { fetchEditRoyalAmountStart, fetchEditRoyalAmountSuccess, fetchEditRoyalAmountFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const editSlice = (royaltyId, updatedEditRoyalAmount) => async (dispatch) => {
  dispatch(fetchEditRoyalAmountStart());
  const tokenId = token();
  try {
    const response = await fetch(API_PATH + `/royality/${royaltyId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${tokenId}`

      },
      body: JSON.stringify(updatedEditRoyalAmount),
    });
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const responseData = await response.json();
      dispatch(fetchEditRoyalAmountSuccess([responseData.franchiseeRoyalty]));
      // dispatch(setRoyaltyAmountData([responseData.franchiseeRoyalty]))
      dispatch(setSuccessMessage("Successful!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    const errMessage = responseFormat({ error })
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};
export default slice.reducer;
