import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { validationRegexOnlyAlpha, validationRegexMobileNo, validationRegexClass, validationRegexSalary, emailRegex } from "../reUsableForm/regex";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import EmailInputField from "../.././ReusableFields/EmailFields/EmailField";
import { useDispatch, useSelector } from "react-redux";
import { postEmployeeEnquiryRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/employeeEnquirySlice";
import useCitiesState from '../Teachers/customHooks/useCitiesState';
import SelectDropdown from "../SelectDropdown";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { API_PATH } from "../../Api/api";
import { employeeEnquiry } from "../../Api/folderPath";
import { token } from "../../Api/token";
import FileUploadField from "../../ReusableFields/FileUpload/FileUploadField";
import { uploadFiles } from "../reUsableForm/uploadImage-docs";
import FormatDate from "../reUsableForm/dateFormat";



const EmployeeEnquiry = () => {
    const [selectedDate,] = useState(new Date());
    const formatDate1 = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`
    };

    const formatDate2 = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`
    };

    const selectDate = formatDate2(selectedDate);
    const [formData, setFormData] = useState({
        date: selectDate, dateOfBirth: '',
        email: ''

    });
    const [formErrors, setFormErrors] = useState({ qualificationId: false, dateOfBirth: false, previousEmploymentJoiningDate: false, previousEmploymentLeavingDate: false, documentFiles: false, uploadDocument: false });
    const [validated, setValidated] = useState(false);
    const [qualificationsOptions] = useCitiesState();
    const [documentFiles, setDocumentFiles] = useState({ documentFiles: "" });
    const [uploadDocument, setUploadDocument] = useState([]);
    const tokenId = token()
    const [submit, setSubmit] = useState(false);



    const dispatch = useDispatch();
    const showSuccessMsg = useSelector((state) => state.employeeEnquiry.showSuccessMessage);
    const successMsg = useSelector((state) => state.employeeEnquiry.successMessage);

    const showErrorMsg = useSelector((state) => state.employeeEnquiry.showErrorMessage);
    const errorMsg = useSelector((state) => state.employeeEnquiry.errorMessage);
    const [uploadDoc, setUploadDoc] = useState(null)

    const handleDocumentChange = async (event) => {
        const files = event.target.files;
        setUploadDoc(files)
        setFormErrors({ ...formErrors, uploadDocument: false });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const franchiseeSchoolId = parseInt(sessionStorage.getItem('franchiseeSchoolId'));

        setSubmit(true)
        const newFormErrors = {
            email: !formData.email,
            qualificationId: !formData.qualificationId,
            dateOfBirth: !formData.dateOfBirth,
            previousEmploymentLeavingDate: !formData.previousEmploymentLeavingDate,
            previousEmploymentJoiningDate: !formData.previousEmploymentJoiningDate,
            uploadDocument: uploadDoc?.length === 0,
            // New condition for file upload validation
            //  uploadDocument: !formData.uploadDocumentUrl,
        };

        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            setSubmit(false)
            return;
        }

        if (uploadDoc) {
            var fileId = await uploadFiles(uploadDoc, "enquiry/employee/docs");
        }
        const uploadLocations = fileId.map((response) => response.Location);

        const data = {
            ...formData,
            "cvUrl": uploadLocations,
            dateOfBirth: FormatDate(formData.dateOfBirth),
            franchiseeSchoolId: franchiseeSchoolId,

        }
        dispatch(postEmployeeEnquiryRequest(data));
        setSubmit(false)
    };


    const handleClear = () => {
        setSubmit(false)
        setFormData({
            date: "",
            qualificationId: "",
            email: "",
            dateOfBirth: "",
            selectedDocuments: "",
            previousEmploymentJoiningDate: "",
            previousEmploymentLeavingDate: "",

        });
        setUploadDoc(null)
        setFormErrors({
            qualificationId: false,
        });
        setDocumentFiles({ documentFiles: "" })
        setValidated(false);
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    const getBorderColor1 = () => {
        if (uploadDoc && uploadDoc.length > 0) {
            return "#198754"; // Green when there are selected documents
        } else if (validated) {
            return "red"; // Red when validated but no documents selected
        } else {
            return "#ccc"; // Default color
        }
    };


    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Employee Enquiry</div>
            <Row >
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                    {/* <Form.Label>Date</Form.Label> */}
                    <TextInputField
                        className='enquiryEntryDate'
                        value={formatDate1(selectedDate)}
                    >
                    </TextInputField>
                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Name"
                        value={formData.name}
                        onChange={(event) => {
                            setFormData({ ...formData, "name": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Name"
                        validationRegex={validationRegexOnlyAlpha}
                        validationErrorMessage="please enter valid name"
                    />

                </Col>
                <Col xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Mobile No"
                        value={formData.contactNumber}
                        onChange={(event) => {
                            setFormData({ ...formData, "contactNumber": event.target.value })
                        }}
                        validated={validated}
                        feedbackMessage="please enter MobileNo "
                        validationRegex={validationRegexMobileNo}
                        validationErrorMessage="please enter valid Mobile No"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <EmailInputField
                        label="Email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={(event) => { setFormData({ ...formData, email: event.target.value }) }}
                        placeholder="Email"
                        validated={validated}
                        feedbackMessage="Please enter email"
                        validationRegex={emailRegex}
                        validationErrorMessage="Please enter valid email."
                    />

                </Col>
            </Row>
            <Row >
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                    <DatePickerPast
                        label="DOB*"
                        value={formData.dateOfBirth || ''}
                        onChange={(name, date, selectedDate) => {
                            setFormData({ ...formData, dateOfBirth: date });
                            setFormErrors({ ...formErrors, dateOfBirth: !date });
                        }}
                        required
                        name="DOB"
                        placeholder="Select Date of Birth*"
                        dateFormat="dd-MM-yyyy"
                        isValidDate={(date) => true}
                        feedbackMessage="Please select date of birth."
                        validated={validated}
                    />
                    {formErrors.dateOfBirth && (
                        <div className="error-message">
                            Please select a date of birth.
                        </div>
                    )}
                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                    <SelectDropdown
                        name='qualificationId'
                        value={formData.qualificationId || null}
                        isSearchable
                        placeholder='Select Qualification'
                        options={qualificationsOptions}
                        onChange={(selectedOption) => {
                            setFormData({ ...formData, qualificationId: selectedOption })
                            setFormErrors({ ...formErrors, qualificationId: !selectedOption });
                        }}
                    />
                    {formErrors.qualificationId && (
                        <div className="error-message">
                            Please Select qualification .
                        </div>
                    )}
                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="ExpectedSalary"
                        value={formData.expectedSalary}
                        onChange={(event) => {
                            setFormData({ ...formData, "expectedSalary": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter expectedSalary"
                        validationRegex={validationRegexSalary}
                        validationErrorMessage="please enter valid expectedSalary"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Work Experience"
                        value={formData.workExperience}
                        onChange={(event) => {
                            setFormData({ ...formData, "workExperience": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Experience"
                        validationRegex={validationRegexSalary}
                        validationErrorMessage="please enter valid Experience"
                    />
                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Company Name"
                        value={formData.previousEmployment}
                        onChange={(event) => {
                            setFormData({ ...formData, previousEmployment: event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Company Name"
                        validationRegex={validationRegexClass}
                        validationErrorMessage="please enter valid Company Name"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <DatePickerPast
                        label="Previous Joining Date*"
                        value={formData.previousEmploymentJoiningDate || ''}
                        onChange={(name, date, selectedDate) => {
                            setFormData({ ...formData, previousEmploymentJoiningDate: date });
                            setFormErrors({ ...formErrors, previousEmploymentJoiningDate: !date });
                        }}
                        required
                        name="previous Employment Joining Date"
                        placeholder="Previous Joining Date"
                        dateFormat="dd-MM-yyyy"
                        isValidDate={(date) => true}
                        feedbackMessage="Please previous Employment Joining Date."
                        validated={validated}
                    />
                    {formErrors.previousEmploymentJoiningDate && (
                        <div className="error-message">
                            Please select a previous Employment Joining Date.
                        </div>
                    )}

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Last Drawn Salary"
                        value={formData.previousEmploymentLastDrawnSalary}
                        onChange={(event) => {
                            setFormData({ ...formData, previousEmploymentLastDrawnSalary: event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Last Drawn Salary"
                        validationRegex={validationRegexSalary}
                        validationErrorMessage="please enter valid Last Drawn Salary"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <DatePickerPast
                        label="Previous Leaving Date*"
                        value={formData.previousEmploymentLeavingDate || ''}
                        onChange={(name, date, selectedDate) => {
                            setFormData({ ...formData, previousEmploymentLeavingDate: date });
                            setFormErrors({ ...formErrors, previousEmploymentLeavingDate: !date });
                        }}
                        required
                        name="previous Employment leaving Date"
                        placeholder="Previous Leaving Date"
                        dateFormat="dd-MM-yyyy"
                        isValidDate={(date) => true}
                        feedbackMessage="Please previous Employment Leaving Date."
                        validated={validated}
                    />
                    {formErrors.previousEmploymentLeavingDate && (
                        <div className="error-message">
                            Please select a previous Employment Leaving Date.
                        </div>
                    )}

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Reference"
                        value={formData.referenceContactName}
                        onChange={(event) => {
                            setFormData({ ...formData, "referenceContactName": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Reference"
                        validationRegex={validationRegexOnlyAlpha}
                        validationErrorMessage="please enter valid Reference"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    <TextInputField
                        type="text"
                        className="fcontrol"
                        required
                        placeholder="Reference Number"
                        value={formData.referenceContactNumber}
                        onChange={(event) => {
                            setFormData({ ...formData, "referenceContactNumber": event.target.value });
                        }}
                        validated={validated}
                        feedbackMessage="please enter Reference Number"
                        validationRegex={validationRegexMobileNo}
                        validationErrorMessage="please enter valid Reference Number"
                    />

                </Col>
                <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                    {/* <FileUploadField
                        label="Upload Documents"
                        placeholder="Upload&nbsp;Doc"
                        accept=".pdf ,.doc"
                        onChange={handleImageChange}
                        selectedImage={documentFiles}
                        validated={validated}
                        multiple
                    />
                    {validated && documentFiles.length === 0 && (
                        <div style={{ color: "red" }}>Please select at least Documents</div>
                    )}
                    {documentFiles?.length > 0 && (
                        <div className="file-names">
                            {documentFiles?.map((fileName, index) => {
                                const pathParts = fileName.split('\\');
                                const lastPart = pathParts[pathParts.length - 1];
                                const path = lastPart.split('-')
                                const lastPath = path.slice(2).join('-');
                                return (
                                    <p key={index}>{lastPath}</p>
                                );
                            })}
                        </div>
                    )} */}

                    <Form.Group>
                        <div className="uploadDoc"

                            style={{
                                border: `1px solid ${getBorderColor1()}`, // Dynamic border color
                            }}
                        >
                            <div className="layout1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                >
                                    <path
                                        d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                                        fill="#636363"
                                    />
                                </svg>
                                <div className="title1">Upload&nbsp;Docu</div>
                            </div>
                            <input
                                type="file"
                                accept=".pdf, .doc,.docx"
                                onChange={handleDocumentChange}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    cursor: "pointer",
                                    position: "absolute",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        </div>
                        <div>
                            <p >{uploadDoc?.[0].name}</p>



                        </div>
                    </Form.Group>
                    {formErrors.uploadDocument && (
                        <div className="error-message">
                            Please Select upload Doc .
                        </div>
                    )}
                </Col>
            </Row>
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-2%",
                    marginTop: "5%"
                }}
            >
                <button
                    className="clear-button-figma-div"
                    type="button"
                    onClick={handleClear}
                >
                    Clear
                </button>
                <button className="save-button-figma-div" type="submit" disabled={submit}>
                    {submit ? "Submitting..." : "Submit"}
                </button>
            </div>
        </Form>
    )
}
export default EmployeeEnquiry;