
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchFranchiseOwners } from './slice/franchiseOwnersGet'
export const FranchiseDetails = () => {
    const apiData = useSelector((state) => state.franchiseOwnerDetails.data) || [];


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchFranchiseOwners());
    }, [dispatch]);

    return (
        <>
        </>
    )
}