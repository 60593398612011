import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexPinNo, validationRegexOnlyAlpha } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";

const AddSchool = () => {
  const [formData, setFormData] = useState([])
  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [permanentAddress, setPermanentAddress] = useState({})


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);

      return;
    }

    setShowSuccessMsg(true);
    setSuccessMsg('SuccessFully Updated! ')
  }

  const handleClear = () => {
    setFormData({
      schoolName: "",
      city: "",
      state: "",
      pincode: "",
    });
  }

  return (


    <Form noValidate onSubmit={handleSubmit}>
      <div className="content-header">Add School</div>
      <Row Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col lg={4} md={4}>
          <TextInputField
            name="SchoolName"
            className="input-style"
            textType="Alphabet"
            value={formData.schoolName}
            onChange={(event) => {
              setFormData({ ...formData, "schoolName": event.target.value });

            }}
            required
            placeholder="schoolName"
            validated={validated}
            feedbackMessage="Please enter school Name."
            validationRegex={validationRegexOnlyAlpha}
            validationErrorMessage="Please enter a valid school Name."
          />
        </Col>

        <Col lg={4} md={4}>
          <TextInputField
             placeholder="City"
             className="input-style"
             type="text"
             value={formData.city || ''}
             onChange={(event) => setFormData({ ...formData, city: (event.target.value) })}           
            required
            validated={validated}
            feedbackMessage="Please enter city."
            validationRegex={validationRegexOnlyAlpha}
            validationErrorMessage="Please enter a valid city."
          />
        </Col>
        </Row>
      <Row style={{ justifyContent: "center", marginTop: "1%" }}>
        <Col lg={4} md={4}>
          <TextInputField
            name="state"
            className="input-style"
            textType="Alphabet"
            value={formData.state}
            onChange={(event) => {
              setFormData({ ...formData, "state": event.target.value });

            }}
            required
            placeholder="state"
            validated={validated}
            feedbackMessage="Please enter state."
            validationRegex={validationRegexOnlyAlpha}
            validationErrorMessage="Please enter a valid state."
          />
        </Col>    
        <Col lg={4} md={4}>
          <TextInputField
            type="text"
            className="input-style"
            placeholder='Pincode'
            value={formData.pincode || ''}
            onChange={(event) => setFormData({ ...formData, pincode: event.target.value })}
            required
            validated={validated}
            feedbackMessage="Please enter pincode."
            validationRegex={validationRegexPinNo}
            validationErrorMessage="Please enter a valid pincode."
          />
        </Col>
      </Row>

      <SuccessMessagePopup show={showSuccessMsg} onClose={() => { setShowSuccessMsg(false); handleClear() }} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={() => { setShowErrorMsg(false) }} errorMessage={errorMsg} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1%",
          marginTop: "5%"
        }}
      >
        <button
          className="clear-button-figma-div"
          type="button"
          onClick={handleClear}
        >
          Clear
        </button>
        <button className="save-button-figma-div" type="submit">
          Submit
        </button>
      </div>

    </Form>
  )
}


export default AddSchool;