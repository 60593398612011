import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token, franchiseeSchoolId } from "../../../Api/token";

const slice = createSlice({
  name: "viewFranchiseWiseSubjects",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchFranchiseWiseSubjectDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchFranchiseWiseSubjectDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchFranchiseWiseSubjectDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const {
  fetchFranchiseWiseSubjectDataStart,
  fetchFranchiseWiseSubjectDataSuccess,
  fetchFranchiseWiseSubjectDataFailure,
} = slice.actions;

export const fetchFranchiseWiseSubjectDataOnce =
  () => async (dispatch, getState) => {
    const { data, isLoading } = getState().viewFranchiseWiseSubjects;

    const tokenId = token();
    const FranchiseeSchoolId = franchiseeSchoolId();
    if (data === null && !isLoading) {
      // If data is null and the API is not loading, trigger the API request
      dispatch(fetchFranchiseWiseSubjectDataStart());

      try {
        // Make your API request here using the fetch function
        const response = await fetch(
          API_PATH + `/FranchiseSubjects?franchiseeSchoolId=${Number(FranchiseeSchoolId)}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed"); // You can customize error handling
        }

        const data1 = await response.json();
        dispatch(fetchFranchiseWiseSubjectDataSuccess(data1));
      } catch (error) {
        // Handle errors and dispatch the failure action
        dispatch(fetchFranchiseWiseSubjectDataFailure());
      }
    }
  };

export default slice.reducer;
