import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DropdownField from "../../reUsableForm/dropDownField";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeeHead } from "../FeeStructureSlice/feeHeadGet";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";
import {
  postFeeDueDate,
  setShowSuccessMessage,
  setShowErrorMessage,
  fetchDueDate,
  PostDueDates,
} from "../FeeStructureSlice/feeDueDatePost";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import SearchableDropDown from "../../../ReusableFields/DropDown/searchableDroDown";
import FeeDueDatesDetails from "./feeDueDateDetails";
import SelectDropdown from "../../SelectDropdown";

export const FeeDueDate = () => {
  const [formData, setFormData] = useState({
    feeId: "",
    trueValues: {},
  });
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({
    feeName: false,
    dueDate1: false,
    dueDate2: false,
    dueDate3: false,
    dueDate4: false,
  });

  const showSuccessMsg = useSelector(
    (state) => state.feeDueDate.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.feeDueDate.successMessage);

  const showErrorMsg = useSelector(
    (state) => state.feeDueDate.showErrorMessage
  );
  const errorMsg = useSelector((state) => state.feeDueDate.errorMessage);

  const apiData = useSelector((state) => state.feeHeadDetails.data) || [];
  const postData = useSelector((state) => state.feeHead.data) || [];

  const FeeData = [...(apiData || []), ...postData];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeeHead());
  }, [dispatch]);

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleCancel();
  };

  useEffect(() => {
    if (formData.feeId) {
      const result = FeeData.find(
        (item) =>
          item.id == formData.feeId &&
          Object.entries(item).some(
            ([key, value]) => key !== "isActive" && value === true
          )
      );
      const trueValues = Object.entries(result)
        .filter(([key, value]) => key !== "isActive" && value === true)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      setFormData({ ...formData, trueValues, dueDates: [] });
    }
  }, [formData.feeId]);

  const currentDate = new Date();
  const newCurrentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  function getFormattedDate(startDate, monthsToAdd) {
    const newDate = new Date(startDate);
    newDate.setMonth(startDate.getMonth() + monthsToAdd);

    const day = newDate.getDate().toString().padStart(2, "0");
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function getFormattedDate1(startDate, monthsToAdd) {
    const newDate = new Date(startDate);
    newDate.setMonth(startDate.getMonth() + monthsToAdd);

    const day = newDate.getDate().toString().padStart(2, "0");
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear();

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (formData.trueValues.monthly && formData.dueDateM === undefined) {
      const monthlyDueDates = [...Array(10)].map((_, index) =>
        getFormattedDate1(newCurrentDate, index)
      );
      const updatedFormData = { ...formData, dueDateM: monthlyDueDates };
      setFormData(updatedFormData);
    }
  }, [
    formData.trueValues.monthly,
    newCurrentDate,
    formData.dueDateM,
    formData.feeId,
  ]);

  useEffect(() => {
    if (formData.trueValues.biMonthly && formData.dueDate === undefined) {
      const biMonthlyDueDates = [...Array(5)].map((_, index) =>
        getFormattedDate1(newCurrentDate, index)
      );
      const updatedFormData = { ...formData, dueDate: biMonthlyDueDates };
      setFormData(updatedFormData);
    }
  }, [
    formData.trueValues.biMonthly,
    newCurrentDate,
    formData.dueDate,
    formData.feeId,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const newFormErrors = {
      feeName: !formData.feeName,
      dueDate1: formData.trueValues.term ? !formData.dueDate1 : false,
      dueDate2: formData.trueValues.term ? !formData.dueDate2 : false,
      dueDate3: formData.trueValues.term ? !formData.dueDate3 : false,
      dueDate4: formData.trueValues.term ? !formData.dueDate4 : false,
    };

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);
    if (hasErrors) {
      setValidated(true);
      return;
    }

    const value = Object.keys(formData.trueValues)[0];

    let sendData;

    if (value === "oneTimeAtAdmission" || value === "yearly") {
      sendData = {
        feeHeadId: formData.feeId,
        [value]: "Date Of Admission",
      };
    } else if (value === "monthly" || value === "biMonthly") {
      sendData = {
        feeHeadId: formData.feeId,
        feeDuration: value,
        dueDates: value === "biMonthly" ? formData.dueDate : formData.dueDateM,
      };
    } else if (value === "term") {
      sendData = {
        feeHeadId: formData.feeId,
        feeDuration: value,
        dueDates: [formData.dueDate1, formData.dueDate2, formData.dueDate3, formData.dueDate4],
      };
    } else if (value === "halfYearly") {
      sendData = {
        feeHeadId: formData.feeId,
        feeDuration: value,
        dueDates: [formData.dueDate4, formData.dueDate5],
      };
    }
    dispatch(PostDueDates(sendData));
  };

  const onChange = (dateString) => {
    setFormData({ ...formData, dueDate1: dateString });
    setFormErrors({ ...formErrors, dueDate1: false });
  };
  const onChange1 = (dateString) => {
    setFormData({ ...formData, dueDate2: dateString });
    setFormErrors({ ...formErrors, dueDate2: false });
  };
  const onChange2 = (dateString) => {
    setFormData({ ...formData, dueDate3: dateString });
    setFormErrors({ ...formErrors, dueDate3: false });
  };
  const onChange3 = (dateString) => {
    setFormData({ ...formData, dueDate4: dateString });
    setFormErrors({ ...formErrors, dueDate4: false });
  };
  const onChangeYearly = (dateString) => {
    setFormData({ ...formData, dueDate4: dateString });
    setFormErrors({ ...formErrors, dueDate4: false });
  };
  const onChangeYearly1 = (dateString) => {
    setFormData({ ...formData, dueDate5: dateString });
    setFormErrors({ ...formErrors, dueDate5: false });
  };

  const handleCancel = () => {
    setFormData({
      feeId: "",
      trueValues: {},
    });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header" style={{ marginTop: "1rem" }}>
          Fee Due
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.feeId ? { label: formData.feeName } : null}
              options={FeeData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  feeId: Number(selectedOption.id),
                  feeName: selectedOption.value,
                });
                setFormErrors({ ...formErrors, feeName: false });
              }}
              placeholder="select Fee Head"
              feedbackMessage="Please select Fee Head."
            />
            {formErrors.feeName && (
              <div className="error-message">Please Select Fee Head .</div>
            )}
          </Col>
        </Row>

        {formData.trueValues.monthly && (
          <>
            <Row style={{ marginTop: "2rem" }}>
              {[...Array(4)].map((_, index) => (
                <Col key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Enter monthly value ${index + 1}`}
                    value={getFormattedDate(newCurrentDate, index)}
                    disabled
                  />
                </Col>
              ))}
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              {[...Array(4)].map((_, index) => (
                <Col key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Enter monthly value ${index + 5}`}
                    value={getFormattedDate(newCurrentDate, index + 4)}
                    disabled
                  />
                </Col>
              ))}
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              {[...Array(2)].map((_, index) => (
                <Col key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Enter monthly value ${index + 9}`}
                    value={getFormattedDate(newCurrentDate, index + 8)}
                    disabled
                  />
                </Col>
              ))}
              <Col></Col>
              <Col></Col>
            </Row>
          </>
        )}

        {formData.trueValues.biMonthly && (
          <>
            <Row style={{ marginTop: "2rem" }}>
              {[...Array(4)].map((_, index) => (
                <Col key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Enter bi monthly value ${index + 1}`}
                    value={getFormattedDate(newCurrentDate, index)}
                    disabled
                  />
                </Col>
              ))}
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              {[...Array(1)].map((_, index) => (
                <Col key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Enter bi monthly value ${index + 5}`}
                    value={getFormattedDate(newCurrentDate, index + 4)}
                    disabled
                  />
                </Col>
              ))}
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </>
        )}

        {formData.trueValues.yearly && (
          <>
            <center style={{ marginTop: "1rem" }}>
              {" "}
              <h4>Date Of Admission</h4>
            </center>
          </>
        )}

        {formData.trueValues.oneTimeAtAdmission && (
          <>
            <center style={{ marginTop: "1rem" }}>
              {" "}
              <h4>Date Of Admission</h4>
            </center>
          </>
        )}

        {formData.trueValues.term && (
          <>
            <Row style={{ marginTop: "2rem" }}>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChange}
                  style={{ width: "248px", height: "42px" }}
                />
                {formErrors.dueDate1 && (
                  <div className="error-message">Please Select Date .</div>
                )}
              </Col>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChange1}
                  style={{ width: "248px", height: "42px" }}
                />
                {formErrors.dueDate2 && (
                  <div className="error-message">Please Select Date .</div>
                )}
              </Col>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChange2}
                  style={{ width: "248px", height: "42px" }}
                />
                {formErrors.dueDate3 && (
                  <div className="error-message">Please Select Date .</div>
                )}
              </Col>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChange3}
                  style={{ width: "248px", height: "42px" }}
                />
                {formErrors.dueDate4 && (
                  <div className="error-message">Please Select Date .</div>
                )}
              </Col>
            </Row>
          </>
        )}

        {formData.trueValues.halfYearly && (
          <>
            <Row style={{ margin: "2rem" }}>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChangeYearly}
                  style={{ width: "248px", height: "42px" }}
                />
              </Col>
              <Col>
                <DatePicker
                format="DD-MM-YYYY"
                  onChange={onChangeYearly1}
                  style={{ width: "248px", height: "42px" }}
                />
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </>
        )}

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "1%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear{" "}
          </button>
          <button className="save-button-figma-div" type="submit">
            {" "}
            Submit
          </button>
        </div>
      </Form>
      <div>
        <FeeDueDatesDetails />
      </div>
    </>
  );
};
