import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../reUsableForm/table/table';
import { useNavigate } from "react-router-dom";
import { fetchStudentFeeView } from './slice/viewStudentFeeSlice';
import { fetchStudentDataOnce } from './viewStudentSlicer';
import SelectDropdown from '../SelectDropdown';
import { Col } from 'react-bootstrap';
import { fetchRepaymentFee } from './slice/repaymentFeeSlice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FeePDFDocument } from '../reUsableForm/feePDF';
import { getParentLoginData } from '../parent/parentLoginSlicer/parentLoginRequests';

const ViewStudentFee = () => {
    const postStudentData = useSelector((state) => state.addStudent.data) || [];
    const getStudentData = useSelector((state) => state.viewStudent.data) || [];
    const apiData = useSelector((state) => state.ViewStudentFees.data) || [];
    const postData = useSelector((state) => state.studentFeePost.data) || null;
    const repaymentData = useSelector((state) => state.repaymentFee.data) || null;
    const [selectedStudent, setSelectedStudent] = useState(null);

    const roleId = sessionStorage.getItem('roleId');
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
    const FranchiseeSchoolId = Number(franchiseeSchoolId);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
    const data = parentLoginObject?.data;
    useEffect(() => {
        if (parentLoginObject?.loading === "idle") {
            const parentId = sessionStorage.getItem('userId');
            if (roleId === "5") {
                dispatch(getParentLoginData(parentId));
            }
        }
    }, [dispatch, parentLoginObject?.loading]);
    const children = data?.childrens
    useEffect(() => {
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
        dispatch(fetchRepaymentFee);
        if (children?.length > 0) {
            const studentIds = data.childrens.map(child => child.id);
            let ids = "";
            if (studentIds !== undefined && studentIds !== null) {
                const queryString = studentIds.map(id => 'studentId=' + id).join('&');
                ids += queryString
            }
            dispatch(fetchStudentFeeView(franchiseeSchoolId, ids));
        }
    }, [dispatch, FranchiseeSchoolId, children, data]);

    useEffect(() => {
        if (roleId !== "5") {
            dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
            dispatch(fetchStudentFeeView(franchiseeSchoolId));
            dispatch(fetchRepaymentFee);
        }
        // dispatch(RepaymentFee);
    }, [dispatch, FranchiseeSchoolId]);

    const navigateToStudentFee = () => {
        navigate('/StudentFeeCollection');
    };

    const studentData = [...getStudentData, ...postStudentData];

    const excelColumns = [
        { field: "student", header: "Student Name" },
        { field: "class", header: "Class" },
        { field: "totalDue", header: "Total Due" },
        { field: "discount", header: "Discount" },
        { field: "netDue", header: "Net Due" },
        { field: "received", header: "Received" },
        { field: "balance", header: "Balance" }
    ];

    const columns = [
        { field: "student", header: "Student Name" },
        { field: "class", header: "Class" },
        { field: "totalDue", header: "Total Due" },
        { field: "discount", header: "Discount" },
        { field: "netDue", header: "Net Due" },
        { field: "received", header: "Received" },
        { field: "balance", header: "Balance" },
        {
            field: "hasFullyPaid", header: "Has Fully Paid",
            render: rowData => rowData.hasFullyPaid ? "Yes" : "No"
        },
        { field: "transactions", header: "Payment Transactions" },
        roleId !== "5" ? { field: "repayment", header: "Repayment" } : {},
    ];

    let newModifiedData = {};
    if (postData) {
        const studentObject = studentData.find((obj) => obj.id == postData.studentFeePaymentTransaction.studentId);
        newModifiedData = {
            id: postData.studentFeePaymentTransaction.id,
            student: studentObject,
            class: studentObject.studentEnrollments[0].classesClassSection.class,
            totalDue: postData.studentFeePaymentTransaction.totalDue,
            discount: postData.studentFeePaymentTransaction.discount,
            netDue: postData.studentFeePaymentTransaction.netDue,
            received: postData.studentFeePaymentTransaction.received,
            balance: postData.studentFeePaymentTransaction.balance,
            transactions: [
                {
                    id: postData.studentFeePaymentTransaction.id,
                    feeTerms: postData.feePayments.map((item) => ({
                        term: item.term,
                        amountDue: item.amountDue,
                        dueDate: item.dueDate
                    })),
                    paymentTransactions: [
                        {
                            totalDue: postData.studentFeePaymentTransaction.amountDue,
                            discount: postData.studentFeePaymentTransaction.discount,
                            reason: postData.studentFeePaymentTransaction.reason,
                            netDue: postData.studentFeePaymentTransaction.netDue,
                            received: postData.studentFeePaymentTransaction.received,
                            balance: postData.studentFeePaymentTransaction.balance,
                            paidDate: postData.studentFeePaymentTransaction.paidDate,
                            updatedAt: postData.studentFeePaymentTransaction.updatedAt
                        }
                    ]
                },
            ]
        };
    }

    function findTransactionById(transactions, transactionId) {
        for (const transaction of transactions) {
            if (transaction.id === transactionId) {
                return transaction;
            }
        }
        return null; // Return null if no matching transaction is found
    }

    let repaymentDataArray = [];
    if (repaymentData) {
        // Assuming you only have one student object, you might not need a loop
        // If there can be multiple students, you may need to adjust the code accordingly
        const item = repaymentData;
        const studentObject = studentData.find((obj) => obj.id == item.studentId);
        const transaction = apiData.find((obj) => obj.id == item.originalTransactionId);
        const transactionObj = findTransactionById(transaction.transactions, item.id);
        const feeTerms = transactionObj ? transactionObj.feePayments.map((item) => ({
            term: item.term,
            amountDue: item.amountDue,
            dueDate: item.dueDate
        })) : [];
        repaymentDataArray.push({
            id: item.originalTransactionId,
            student: studentObject,
            class: studentObject.studentEnrollments[0].classesClassSection.class,
            totalDue: item?.totalDue,
            discount: item?.discount,
            netDue: item?.netDue,
            received: item?.received,
            balance: item?.balance,
            transactions: [
                {
                    id: transaction.id,
                    feeTerms: feeTerms,
                    paymentTransactions: [
                        {
                            totalDue: item?.totalDue,
                            discount: item?.discount,
                            reason: item?.reason,
                            netDue: item?.netDue,
                            received: item?.received,
                            balance: item?.balance,
                            paidDate: item?.paidDate,
                            updatedAt: item?.updatedAt
                        }
                    ]
                },
            ]
        });
    }

    const combinedData = [...(apiData || [])];

    repaymentDataArray.forEach(obj2Item => {
        // Find the corresponding object in obj1 based on id
        const indexToUpdate = combinedData.findIndex(obj1Item => obj1Item.id === obj2Item.id);

        // If object is found in combinedData, update its properties
        if (indexToUpdate !== -1) {
            const obj1ItemToUpdate = combinedData[indexToUpdate];
            // Create a new object with updated properties from obj2Item
            const updatedObj1Item = { ...obj1ItemToUpdate, ...obj2Item };
            // Retain certain fields from obj1ItemToUpdate
            updatedObj1Item.id = obj1ItemToUpdate.id;
            updatedObj1Item.student = obj1ItemToUpdate.student;
            updatedObj1Item.class = obj1ItemToUpdate.class;
            updatedObj1Item.discount = Number(obj1ItemToUpdate.discount) + Number(obj2Item.discount);
            updatedObj1Item.transactions = [...obj1ItemToUpdate.transactions, ...obj2Item.transactions]
            // Replace the existing object in combinedData with the updated object
            combinedData[indexToUpdate] = updatedObj1Item;
        } else {
            // If object is not found in combinedData, add it
            combinedData.push(obj2Item);
        }
    });



    const modifiedData = combinedData.map((items) => {
        const rowData = {};
        columns.forEach((column) => {
            if (column.field === "student") {
                rowData[column.field] = items.student?.name;
            } else if (column.field === "class") {
                rowData[column.field] = items.class?.name;
            } else {
                rowData[column.field] = items[column.field];
            }
            if ("id" === "id") {
                rowData["id"] = items.id;
            }
        });



        return rowData;
    });

    return (
        <div>
            <div className="content-header">View Student Fee</div>
            {roleId !== "5" ? (<button
                onClick={navigateToStudentFee}
                className="save-button-figma-div"
                style={{
                    position: "absolute",
                    top: 20,
                    right: 0,
                    marginRight: "20px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <a>Add Fees</a>
            </button>) : null}
            {modifiedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}><p>No records available</p></center>
            ) : (
                <>
                    <TableView
                        data={modifiedData}
                        columns={columns}
                        hover={true}
                        isExport={true}
                        isExcel={true}
                        excelColumns={excelColumns}
                        excelFileName={`Fee_Report`}
                        isPdf={true}
                        FeePDFDocument={FeePDFDocument}
                        // pdfData={modifiedData}
                        pdfFileName={"Fee_Report.pdf"}
                    />
                    <PDFDownloadLink
                        document={<FeePDFDocument data={modifiedData} />}
                        fileName="Fee_Report.pdf"
                    >
                    </PDFDownloadLink>
                </>
            )}
        </div>
    );
};


export default ViewStudentFee;
