import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import {columns } from './staticData';
import { getEmployeeList } from './teacherSlicers/allRequests'; 
import TeachersTable from './TeachersTable';
import { getOrPutStoreProcess, getOrPutViewProcess } from './teacherSlicers/newDataFilterFunctions';
import { putEmployeeToList,postingEmplyee } from './teacherSlicers/employeeViewSlicer';
import useAreasQuals from './customHooks/useAreasQuals';
const EmployeeView = () => {

  const columns = [
    { field: "srNo", header: "Sl.No" },
    { field: "dateOfJoining", header: "Date of joining" },
    { field: "name", header: "Name" },
    { field: "code", header: "Employee Code" },
    { field: "gender", header: "Gender" },
    { field: "dateOfBirth", header: "Date of Birth" },
    { field: "age", header: "Age" },
    { field: "email", header: "Email" },
    { field: "primaryContactNumber", header: "Mobile" },
    { field: "secondaryContactNumber", header: "Alternate Mobile" },
    { field: "qualificationId", header: "Qualification" },
    { field: "designation", header: "Designation" },
    { field: "workExperience", header: "work Experience" },
    { field: "salary", header: "Current Salary" },
    { field: "previousEmployment", header: "Previous Employment" },
    { field: "previousEmploymentJoiningDate", header: "Previous Employment Joining date" },
    { field: "previousEmploymentLeavingDate", header: "Previous Employment Leaving date" },
    { field: "previousEmploymentLastDrawnSalary", header: "Previous Employment Last drawn salary" },
    { field: "referenceContactName", header: "Reference Name" },
    { field: "referenceContactNumber", header: "Reference Number" },
    { field: "languagesKnown", header: "Languages Known" },
    { field: "religion", header: "Religion" },
    { field: "remark", header: "Remarks" },
    // { field: "Paddress", header: "Permanent Address" },
    // { field: "Parea", header: "Permanent Parea" },
    // { field: "Pcity", header: "Permanent City" },
    // { field: "Pstate", header: "Permanent State" },
    // { field: "Ppin", header: "Permanent Pincode" },
    { field: "Caddress", header: "Address" },
    { field: "Carea", header: "Area" },
    { field: "Ccity", header: "City" },
    { field: "Cstate", header: "State" },
    { field: "Cpin", header: "Pincode" },
    { field: "profilePictureUrl", header: "Image" },
    { field: "documentUrls", header: "Documents" },
    { field: "view", header: "view" },
    { field: "edit", header: "edit" },
  ];


  const dispatch = useDispatch();
  const employeesListStatus = useSelector((state)=>state.employeeSlicer?.teachers?.getEmployeeViewList?.loading);
  const [backendAreas,backendQualifications] = useAreasQuals();
 
  
  // putDataArray.push({
  //   profilePictureUrl: item?.profilePictureUrl,
  // })

  // postDataArray.push({
  //   profilePictureUrl: item?.profilePictureUrl,
  // })

  useEffect(()=>{
    if(employeesListStatus==='idle'){
      dispatch(getEmployeeList())
    } 
  },[dispatch,employeesListStatus])
  
  const employeesListError = useSelector(state=>state.employeeSlicer.teachers.getEmployeeViewList.error)
  const data = useSelector((state)=>state.employeeSlicer.teachers.getEmployeeViewList.data);
  const putData = useSelector((state)=>state.employeeSlicer.teachers.putEmployeeData.data);
  const postData = useSelector((state)=>state.employeeSlicer.teachers.postEmployeeData.data);

  const updatedData = (data)=>{
    const id = data?.findIndex((each)=>+each.id === +putData?.id);
    const updated = [...data];
    updated[id] = putData;
    return updated;
}

  const modifiedEmployeeList = data.length>0?updatedData(data):[]
  
  const modifiedListAfterPost = () => {
    const updatedPostData = {
      ...postData,
      employee: {
        ...postData.employee,
        permanentAddress: postData.permanentAddress_,
        currentAddress: postData.currentAddress_,
      }
    };
  
    delete updatedPostData.permanentAddress_;
    delete updatedPostData.currentAddress_;
    const modifiedList = [...data,updatedPostData.employee];
    return modifiedList;
  }
  
  useEffect(()=>{
    if(postData){
      const modifiedListAfterPostData =  modifiedListAfterPost();
      dispatch(postingEmplyee(modifiedListAfterPostData));
      return;
    }
    if(putData){
      dispatch(putEmployeeToList(modifiedEmployeeList));
      return;
    }
  },[putData,postData]);

  const idProcess = getOrPutStoreProcess(data);
  const viewProcess = getOrPutViewProcess(idProcess,backendAreas,backendQualifications);
  const finalData = viewProcess.length>0?viewProcess?.map((each,index)=>{return {...each,srNo:index+1}}):[];
  try{
    return (
      <>
         <h4>Employee List</h4>
         {
          employeesListError===null?<TeachersTable columns={columns} data={finalData?.length>0?finalData:[]} />
          :<p style={{height:'300px',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'larger'}}>Error Occured {employeesListError}.If it is inavlid token Refresh the page!</p>
         }
      </>
    )
  }catch(err){
    return <>Error Occured!</>
  }
}

export default EmployeeView;