import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexClass } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { postAddClassRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addClassSlice";
import { fetchDataOnceProgram } from "../programs/slice/viewProgramSlice"
import SelectDropdown from "../SelectDropdown";
import ViewClass from './viewClass';
import { uploadFiles } from '../reUsableForm/uploadImage-docs';


const AddClass = () => {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({ programId: false })

  const apiData = useSelector((state) => state.viewProgram.data) || [];
  const postData = useSelector((state) => state.addProgram.data) || [];

  const combinedData = [...(apiData||[]),...postData]

  const dispatch = useDispatch();
  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchDataOnceProgram());
    }
  }, [apiData, dispatch]);

  const showSuccessMsg = useSelector((state) => state.addClass.showSuccessMessage);
  const successMsg = useSelector((state) => state.addClass.successMessage);

  const showErrorMsg = useSelector((state) => state.addClass.showErrorMessage);
  const errorMsg = useSelector((state) => state.addClass.errorMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormErrors = {
      programId: !formData.programId,
      name: !formData.name
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some(error => error);
  
     const data = uploadFiles ()

    if (hasErrors) {
      setValidated(true);
      return;
    }


    const programId = formData.programId.id

    formData.programId = programId;

    dispatch(postAddClassRequest(formData));
  };

  const handleClear = () => {
    setFormData({
      name: '',
    });
    setFormErrors({
      programId: false,
    });
    setValidated(false);
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <div className="content-header">Add Class</div>
        <Row style={{ justifyContent: "center" }}>
          {/* <Col></Col>
          <Col></Col> */}
          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.programId ? { id: formData.programId.id, label: formData.programId.label } : null}
              options={combinedData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name
              }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, programId: selectedOption });
                setFormErrors({ ...formErrors, programId: !selectedOption });
              }}
              placeholder="Select program"
            />
            {formErrors.programId && (
              <div className="error-message">
                Please Select program .
              </div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group >
              {/* <Form.Label className='mt2' ></Form.Label> */}
              <TextInputField
                className="fcontrol"
                type="text"
                name="name"
                placeholder='Class'
                required
                value={formData.name}
                onChange={(event) => {
                  setFormData({ ...formData, "name": event.target.value });
                }}
                validated={validated}
                feedbackMessage="Please enter Class."
                validationRegex={validationRegexClass}
                validationErrorMessage="Please enter a valid  Class."
              />
            </Form.Group>
          </Col>
       
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <div>
        <ViewClass />
        {/* <uploadFiles/> */}
      </div>
    </>
  );
};

export default AddClass;