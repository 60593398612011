// SubjectAssessmentModal.js
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const SubjectAssessmentModal = ({ show, onHide, subjectAssessments }) => {
  return (
    <Modal show={show} onHide={onHide} className="modal" size="x2" style={{marginTop: '5rem'}}>
      <Modal.Header closeButton>
        <Modal.Title>Subject Assessments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", overflowX: "auto" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Subject</th>
              <th>Grade</th>
              <th>Marks</th>
            </tr>
          </thead>
          {subjectAssessments.length > 0 ? (
          <tbody>
            {subjectAssessments.map((assessment, index) => (
              <tr key={index}>
                <td>{assessment.subject.name}</td>
                <td>{assessment.grade}</td>
                <td>{assessment.marks}</td>
              </tr>
            ))}
          </tbody>
          ) : (
            <center style={{marginLeft: '75px'}}>
              <p>No Data</p>
            </center>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectAssessmentModal;
