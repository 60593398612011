import React, { useEffect, useState } from 'react';
import "./entryFollowUp.css";
import Table from '../reUsableForm/table/table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnquiryView } from './slice/enquiryViewSlicer';
import { fetchPlaySchool } from './slice/playSchoolSlicer';
import { fetchClasses } from './slice/classesSlicer';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { setShowErrorMessage, setShowSuccessMessage } from './slice/enquiryFollowUpSlicer';

const EnquiryFollowUp = () => {
    const data = useSelector((state) => state.enquiryView.data) || [];
    const postData = useSelector((state) => state.EnquiryEntry.data) || [];
    const putData = useSelector((state) => state.enquiryFollowUp.data) || [];

    const playSchool = useSelector((state) => state.playSchool.data) || [];
    const classes = useSelector((state) => state.classes.data) || [];



    const showSuccessMsg = useSelector((state) => state.enquiryFollowUp.showSuccessMessage);
    const successMsg = useSelector((state) => state.enquiryFollowUp.successMessage);

    const showErrorMsg = useSelector((state) => state.enquiryFollowUp.showErrorMessage);
    const errorMsg = useSelector((state) => state.enquiryFollowUp.errorMessage);


    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };


    const dispatch = useDispatch();
    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "dateOfBirth", header: "Date Of Birth" },
        { field: "studentName", header: "Student Name" },
        { field: "fatherName", header: "Father Name" },
        { field: "fatherContactNumber", header: "Father Contact No" },
        { field: "fatherOccupation", header: "Father Occupation" },
        { field: "motherName", header: "Mother Name" },
        { field: "motherContactNumber", header: "Mother Contact No" },
        { field: "motherOccupation", header: "Mother Occupation" },
        { field: "address", header: "Address" },
        { field: "expectedDateOfJoining", header: "Expected Date Of Joining" },
        { field: "referral", header: "Referral" },
        { field: "specialComments", header: "Special Comments" },
        { field: "program", header: "Play School" },
        { field: "className", header: "Classes" },
        { field: "mindSetDropdown", header: "Mind Set" },
        { field: "comments", header: "Remarks" },
        { field: "save", header: "Save" },
    ];

    const combinedData = [...(data || []), ...postData];

    const newModifiedData = combinedData.map((postItem, index) => {
        const findObjectProgram = playSchool.find((obj) => obj.id === postItem.programId);
        const findObjectClass = classes.find((obj) => obj.id === postItem.classId);
        const formattedDate = new Date(postItem.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const formattedDateOfBirth = new Date(postItem.dateOfBirth).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const formattedExpectedDateOfJoining = new Date(postItem.expectedDateOfJoining).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        return {
            index: index + 1,
            id: postItem.id,
            date: formattedDate,
            dateOfBirth: formattedDateOfBirth,
            studentName: postItem.studentName ? postItem.studentName : "N/A",
            fatherName: postItem.fatherName ? postItem.fatherName : "N/A",
            fatherContactNumber: postItem.fatherContactNumber ? postItem.fatherContactNumber : "N/A",
            fatherOccupation: postItem.fatherOccupation ? postItem.fatherOccupation : "N/A",
            motherName: postItem.motherName ? postItem.motherName : "N/A",
            motherContactNumber: postItem.motherContactNumber ? postItem.motherContactNumber : "N/A",
            motherOccupation: postItem.motherOccupation ? postItem.motherOccupation : "N/A",
            address: postItem.address ? postItem.address : "N/A",
            expectedDateOfJoining: formattedExpectedDateOfJoining,
            referral: postItem.referral ? postItem.referral : "N/A",
            specialComments: postItem.specialComments ? postItem.specialComments : "N/A",
            program: findObjectProgram ? findObjectProgram.name : '',
            className: findObjectClass ? findObjectClass.name : '',
            mindSet: postItem.mindSet,
            description: postItem.description,
        };
    });

    let newUpdatedData

    newUpdatedData = newModifiedData.filter(obj => !putData.some(postObj => postObj.id === obj.id))

    // newUpdatedData = newModifiedData.filter(obj => !putData.find(postObj => postObj.id === obj.id));

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        // handleCancel();

    };

    useEffect(() => {
        dispatch(fetchEnquiryView());
        dispatch(fetchPlaySchool());
        dispatch(fetchClasses());
    }, [dispatch]);

    return (
        <div>
            <h4 className="EnquiryFollowUp">Enquiry Follow Up</h4>
            {newModifiedData === null ? (
                <center><p>Loading...</p></center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}><p>No records available</p></center>
            ) : (
                <Table data={newModifiedData} columns={columns} hover={true} />


            )}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />


        </div>
    );
};

export default EnquiryFollowUp;
