/*
    authors: Naveen
    date: 16-01-2024
    update:16-01-2024 
    version: 1.0
*/


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchDailyUpdates } from "./slice/getPcturesUploads";
import Table from "../reUsableForm/table/table"
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";


export const ViewPicturesUploads = () => {

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "studentName", header: "Student Name" },
        { field: "class", header: "Class" },
        { field: "section", header: "Section" },
        { field: "pictures", header: "Images" },
    ]

    const dispatch = useDispatch();
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
    const FranchiseeSchoolId = Number(franchiseeSchoolId);

    const apiData = useSelector((state) => state.dailyUpdates.data) || [];
    const postData = useSelector((state) => state.pictureUpload.data) || []
    const students = useSelector((state) => state.viewStudent.data) || [];

    useEffect(() => {
        if (students.length === 0) {
            dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
        }
    }, [dispatch, students]);

    useEffect(() => {
        dispatch(FetchDailyUpdates())
    }, [dispatch])


    const CombinedData = [...(apiData || []), ...postData]


    let newModifiedData = [];

    CombinedData.forEach((obj, index) => {
        let studentId = obj.studentId
        let matchingStudent = students.find(student => student.id === studentId);

        if (matchingStudent) {
            obj = {
                ...obj, index: index + 1, studentName: matchingStudent.name, class: matchingStudent.studentEnrollments?.[0].classesClassSection?.class?.name || '',
                section: matchingStudent.studentEnrollments?.[0].classesClassSection?.classSection?.name || ''
            }

            newModifiedData.push(obj)
        }
    })

    return (
        <>
            <div className="content-header ">View Pictures Uploads</div>
            {newModifiedData === null ? (
                <center>
                    <p>Loading...</p>
                </center>
            ) : newModifiedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                    <p>No records available</p>
                </center>
            ) : (
                <Table data={newModifiedData} columns={columns} hover={true} />
            )}

        </>
    )
}