import React, { useEffect, useState } from "react";
import Table from "../../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassSchoolDataOnce } from "../../Franchise/slice/viewSchoolSlice";
import { fetchSchoolCreation } from "../slice/creationSchool";

const ViewSchool = () => {
  //   const [data,setData] = useState("")
  const columns = [
    { field: "franchiseeOwner", header: "FranchiseeOwner" },
    { field: "code", header: "Code" },
    { field: "name", header: "School Name" },
    { field: "primaryContactNumber", header: "Primary Number" },
    { field: "secondaryContactNumber", header: "Secondary Number" },
    { 
        field: "address", 
        header: "Address", 
        // Render only the first 10 characters of the address
        body: rowData => rowData.address.substring(0, 10) + (rowData.address.length > 10 ? "..." : "")
      },
    { field: "classes", header: "Class" },
  ];



  const apiData = useSelector((state) => state.viewSchoolData.data) || [];
  const postData = useSelector((state) => state.schoolCreation.data) || [];
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchClassSchoolDataOnce());
    }
  }, [dispatch, apiData]);

 

  const combinedData = [...(apiData || []), ...postData];
 

  return (
    <>
      <div className="content-header">View Schools</div>
      {combinedData === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : combinedData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table data={combinedData} columns={columns} hover={true} striped={true} />
      )}
    </>
  );
};
export default ViewSchool;
