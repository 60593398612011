

import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { API_PATH } from '../../../Api/api';
import { fetchProgramPostData } from './addProgramSlice';
import { fetchWithAuthorization } from '../../Auth/dependency-get';

const slice = createSlice(
  {
    name: 'getProgramBySchoolId',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchGetProgramBySchoolData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchGetProgramBySchoolSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchGetProgramBySchoolFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      },

    },
  },

);

export const { fetchGetProgramBySchoolData, fetchGetProgramBySchoolSuccess, fetchGetProgramBySchoolFailure, } = slice.actions;
const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')

export const fetchDataGetProgramBySchoolId = (schoolId) => async (dispatch, getState) => {

  const tokenId = token()

  const { data, isLoading } = getState().getProgramBySchoolId;

  try {
    const response = await fetchWithAuthorization(API_PATH + `/franchisee-school/${schoolId}/programs`, "GET", tokenId);
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const responseData = await response;
      dispatch(fetchProgramPostData([]))
      dispatch(fetchGetProgramBySchoolSuccess(responseData));

    }
  } catch (error) {
    dispatch(fetchGetProgramBySchoolFailure());
  }


}

export default slice.reducer;