import React from 'react';
import"./FileUploadField.css";

const FileUpload = ({ title, onChangeHandler }) => {
  const handleFileChange = (e) => {
    if (onChangeHandler) {
      onChangeHandler(e);
    }
  };

  return (
    <div className='uploadImg'
      // style={{
      //   position: 'relative',
      //   display: 'flex',
      //   width: '208px',
      //   height: '38px',
      //   padding: '16px',
      //   alignItems: 'center',
      //   borderRadius: '4px',
      //   border: '1px solid #ccc',
      //   background: '#FFF',
      // }}
    >
      <div className='layout1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
        >
          <path
            d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
            fill='#636363'
          />
        </svg>
        <div className='title1'>{title}</div>
      </div>
      <input
        type="file"
        accept='.jpg, .jpeg'
        onChange={handleFileChange}
        style={{
          // width: '100%',
          //  height: '100%',
          opacity: 0,
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default FileUpload;
