import React from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { Tooltip } from "@mui/material";

const PaymentTransaction = ({ show, handleClose, paymentTransactions }) => {
  const formatTransactions = (transactions) => {
    const formattedTransactions = [];
    transactions.forEach(transaction => {
      const feeTermsArray = transaction.feeTerms.map(term => ({
        term: term.term,
        amountDue: term.amountDue,
        dueDate: term.dueDate
      }));

      const sortedPayments = [...transaction.paymentTransactions].sort((a, b) => new Date(a.paidDate) - new Date(b.paidDate));

      const firstPayment = sortedPayments.shift();
      formattedTransactions.push({
        transactionId: transaction.id,
        feeTerms: feeTermsArray,
        totalDue: firstPayment.totalDue,
        discount: firstPayment.discount || "0.00",
        reason: firstPayment.reason || "N/A",
        netDue: firstPayment.netDue,
        received: firstPayment.received,
        balance: firstPayment.balance,
        paidDate: firstPayment.paidDate
      });

      sortedPayments.forEach(payment => {
        formattedTransactions.push({
          transactionId: transaction.id,
          feeTerms: [],
          totalDue: payment.totalDue,
          discount: payment.discount || "0.00",
          reason: payment.reason || "N/A",
          netDue: payment.netDue,
          received: payment.received,
          balance: payment.balance,
          paidDate: payment.paidDate
        });
      });
    });

    return formattedTransactions;
  };

  const transformedData = formatTransactions(paymentTransactions);

  const truncateTerm = (term) => {
    return term.length > 32 ? term.substring(0, 32) + "..." : term;
  };

  const truncateDueDate = (dueDate) => {
    return dueDate.length > 10 ? dueDate.substring(0, 10) + "..." : dueDate;
  };
  


  return (
    <Modal show={show} onHide={handleClose} style={{ "--bs-modal-width": "1300px" }}>
      <Modal.Header closeButton>
        <Modal.Title>Payment Transactions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Transaction Id</th>
              <th style={{ width: "300px"}}>Term</th>
              <th>Amount Due</th>
              <th style={{ width: "120px" }}>Due Date</th>
              <th>Total Due</th>
              <th>Discount</th>
              <th style={{ width: "80px" }}>Reason</th>
              <th>Net Due</th>
              <th>Received</th>
              <th>Balance</th>
              <th style={{ width: "120px" }}>Paid Date</th>
            </tr>
          </thead>
          <tbody>
            {transformedData && transformedData.length > 0 ? (
              transformedData.map((item, index) => {
                const updatedAtDate = new Date(item.paidDate);
                const day = updatedAtDate.getDate().toString().padStart(2, "0");
                const month = (updatedAtDate.getMonth() + 1).toString().padStart(2, "0");
                const year = updatedAtDate.getFullYear();
                const hours = updatedAtDate.getHours().toString().padStart(2, "0");
                const minutes = updatedAtDate.getMinutes().toString().padStart(2, "0");
                const period = hours >= 12 ? "PM" : "AM";
                const formattedHours = (hours % 12) || 12;
                const formattedDateTime = `${day}-${month}-${year}<br/>${formattedHours}:${minutes} ${period}`;

                const feeTermRows = item.feeTerms.length > 0 ? item.feeTerms.map((feeTerm, subIndex) => (
                  <tr key={`${index}-${subIndex}`}>
                    {subIndex === 0 && (
                      <React.Fragment>
                       <td rowSpan={item.feeTerms.length} style={{ padding: "5px" }}>
                          {item.transactionId}
                        </td>
                      </React.Fragment>
                    )}
                    <td style={{ padding: "1px"}}>
                      <Tooltip title={feeTerm.term} arrow>
                        <span>{truncateTerm(feeTerm.term)}</span>
                      </Tooltip>
                    </td>
                    <td style={{ padding: "1px" }}>{feeTerm.amountDue}</td>
                    <td style={{ padding: "1px" }}>
                      <Tooltip title={feeTerm.dueDate} arrow>
                        <span>{truncateDueDate(feeTerm.dueDate)}</span>
                      </Tooltip>
                      </td>
                    {subIndex === 0 && (
                      <React.Fragment>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.totalDue}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.discount}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.reason}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.netDue}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.received}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }}>{item.balance}</td>
                        <td rowSpan={item.feeTerms.length} style={{ padding: "15px" }} dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                      </React.Fragment>
                    )}
                  </tr>
                )) : (
                  <tr key={index}>
                    <td style={{ padding: "15px" }}>{item.transactionId}</td>
                    <td style={{ padding: "5px" }}>{item.term}</td>
                    <td style={{ padding: "15px" }}>{item.amountDue}</td>
                    <td style={{ padding: "15px" }}>{item.dueDate}</td>
                    <td style={{ padding: "15px" }}>{item.totalDue}</td>
                    <td style={{ padding: "15px" }}>{item.discount}</td>
                    <td style={{ padding: "15px" }}>{item.reason}</td>
                    <td style={{ padding: "15px" }}>{item.netDue}</td>
                    <td style={{ padding: "15px" }}>{item.received}</td>
                    <td style={{ padding: "15px" }}>{item.balance}</td>
                    <td style={{ padding: "15px" }} dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                  </tr>
                );

                return feeTermRows;
              })
            ) : (
              <tr>
                <td colSpan="11">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentTransaction;
