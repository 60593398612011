import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { validationRegexClass } from "../reUsableForm/regex";
import { postAreaRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addAreaSlice";
import { fetchDataOnce } from "../city/slice/viewCitySlice";
import SelectDropdown from "../SelectDropdown";
import AreaView from "./viewArea"

const AddArea = () => {
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [formErrors, setFormErrors] = useState({ cityId: false });

  const cityData = useSelector((state) => state.ViewCity.data) || [];
  const postData = useSelector((state) => state.city.data) || [];
  const combinedData = [...(cityData||[]),...postData]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataOnce());
  }, [cityData, dispatch]);

  const showSuccessMsg = useSelector((state) => state.addArea.showSuccessMessage);
  const successMsg = useSelector((state) => state.addArea.successMessage);

  const showErrorMsg = useSelector((state) => state.addArea.showErrorMessage);
  const errorMsg = useSelector((state) => state.addArea.errorMessage);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormErrors = {
      cityId: !formData.cityId,
      name: !formData.name,
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some((error) => error);


    if (hasErrors) {
      setValidated(true);
      return;
    }

    const areaData = {
      ...formData,
      cityId: Number(selectedCity),
    };

    dispatch(postAreaRequest(areaData));

    setSelectedCity('');
    setFormData({});
  };

  const handleClear = () => {
    setFormData({
      name: "",
    });
    setFormErrors({
      cityId: false,
    });
    setValidated(false);
    setSelectedCity('');
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };


  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Add Area</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.cityId ? { id: formData.cityId.id, label: formData.cityId.label } : null}
              options={combinedData.map((city) => ({ id: city.id, label: city.name, value: city.name }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, cityId: selectedOption });
                setSelectedCity(selectedOption.id);
                setFormErrors({ ...formErrors, cityId: !selectedOption });
              }}
              placeholder="Select city"
            />
            {formErrors.cityId && (
              <div className="error-message">
                Please Select city.
              </div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <Form.Group>
              <TextInputField
                type="text"
                className="input-style"
                required
                placeholder="Area"
                value={formData.name}
                onChange={(event) => {
                  setFormData({ ...formData, name: event.target.value });
                }}
                validated={validated}
                feedbackMessage="Please enter Area"
                validationRegex={validationRegexClass}
                validationErrorMessage="Please enter a valid Area"
              />
            </Form.Group>
          </Col>
        </Row>

        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
            style={{ width: '6rem', height: '2rem' }}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
            Submit
          </button>
        </div>
      </Form>
      <div>
        <AreaView />
      </div>
    </>
  );
};

export default AddArea;