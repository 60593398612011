import React from 'react'
import { Progress, Space } from 'antd';

const ProgressComponent = (props) => {
    const {eachChildData} = props;
    const attendance = eachChildData?.attendancePercentage;
    const slicedAttendance = Number(attendance).toFixed(2);
    return (
        <>

            <Space wrap className='progressWrapper'>
                <div className='progressDiv'>
                    {/* <div >
                        <Progress strokeWidth={10} type="circle" percent={100} size={70} format={() => <strong>'A+'</strong>} />
                        <p className='progressHeader'><strong>Grade</strong></p>
                    </div> */}
                    <div>
                        <strong><Progress strokeWidth={10}   type="circle" percent={slicedAttendance}  size={70} className='circularProgress' /></strong>
                        <p className='progressHeader'><strong>Attendance</strong></p>
                    </div>
                </div>
            </Space>

        </>
    )
}

export default ProgressComponent