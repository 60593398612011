import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import "./login.css"
import SelectDropdown from "../SelectDropdown";
// import TextInputField from '../reUsableForm/textInputField';
import TextInputField from '../../ReusableFields/TextFields/TextInputField'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIconOff from '@mui/icons-material/VisibilityOff';
import EmailInputField from "../../ReusableFields/EmailFields/EmailField";
import { API_PATH } from "../../Api/api";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField';
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { token } from "../../Api/token";

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    role: null,
  });

  const [validated, setValidated] = useState(false);
  // const [userName, setUserName] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: false })
  const [error, setError] = useState('');
  const [showMessage, setShowMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectRole, setSelectRole] = useState("");

  const validationRegex = /^[a-zA-Z0-9.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{0,50}$/;
  // const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  // const emailRegex = /^([a-zA-Z0-9._%+-]).*@/;

  // const validationRegexOnlyAlpha = /^(?![\s])^[a-zA-Z\s]*$/;
  const emailRegex = /^[a-zA-Z0-9@.]{0,50}$/;

  const staticRoleOptions = [
    { id: 1, label: 'Parent/Guardian', value: 'Parent' },
    { id: 2, label: 'Employee', value: 'Employee' },
    { id: 3, label: 'Franchisor', value: 'Franchisor' },
    { id: 4, label: 'Franchisee', value: 'Franchisee' },
  ];


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate()

  const isTokenExpired = (token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      if (decoded && decoded.exp) {
        const currentTime = Date.now() / 1000;


        sessionStorage.setItem("userName", decoded.userName);
        sessionStorage.setItem("roleId", decoded.roleId);
        sessionStorage.setItem("userId", decoded.userId);
        sessionStorage.setItem("franchiseeSchoolId", decoded.franchiseeSchoolId);
        sessionStorage.setItem("profilePictureUrl", decoded.profilePictureUrl);
        sessionStorage.setItem("classSectionIds", JSON.stringify(decoded.classSectionIds) || JSON.stringify([]));


        return decoded.exp < currentTime;
      }
      return true;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormErrors = {
      email: !formData.email,
      role: !formData.role,
    }
    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return;
    }
    
    try {
      const response = await fetch(API_PATH + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // If response is not okay, show error message
        setError('Incorrect username or password')

        setErrorMessage(true)

      }

      const data = await response.json();

      if (!data.token) {
        setError("Incorrect username or password");
        setErrorMessage(true)

      } else {
        sessionStorage.setItem("token", data.token);
        const storedToken = token()
        setShowMessage('SuccessFully LogIn !')

        if (isTokenExpired(storedToken)) {

          sessionStorage.setItem("sss", "sdd")
        } else {
          const roleId = sessionStorage.getItem("roleId");
          if (roleId === "5") {
            navigate('/parentProfile', { state: { accessToken: storedToken } });
          }else{
          navigate('/', { state: { accessToken: storedToken } });
          }
        }

      }
    } catch (error) {
      // Handle network or request error
      console.error(error);
    }
  };

  const handleCloseErrorPopup = () => {
    setErrorMessage(false)
  };


  return (
    <div
      className="login_bgImg"
    >
      <div className="login">
        <center>
          <img src="../../images/kidhood_logo_4.png" alt="Kidhood Logo" className="logo_image" />
        </center>
        <Form validated={validated} className="mt-4" onSubmit={handleSubmit}>
              <div className="mb-4">

                <SearchableDropDownField
                  isSearchable
                  value={selectRole}
                  required
                  options={staticRoleOptions}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, role: selectedOption.value });
                    setSelectRole(selectedOption.name);
                    setFormErrors({ ...formErrors, role: !selectedOption });
                  }}
                  placeholder="Select Role"
                />
                {formErrors.role && (
                  <div className="error-message">
                    Please Select Role.
                  </div>
                )}
              </div>
              <div className="mb-4">
                <EmailInputField
                  className="rowCss"
                  placeholder="Email / Phone Number"
                  name="email"
                  textType="Alphabet"
                  value={formData.email}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setFormData({ ...formData, email: inputValue })
                    setFormErrors({ ...formErrors, email: false });
                  }}
                  required
                  colSize={4}
                  validated={validated}
                  feedbackMessage="Please enter Email / Phone Number."
                  validationRegex={emailRegex}
                  validationErrorMessage="Please enter valid Email / Phone Number."
                />
              </div>
              <div style={{ position: 'relative' }}>
                <TextInputField
                  placeholder="Password"
                  name="password"
                  textType="Alphabet"
                  value={formData.password}
                  onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                  // required
                  type={showPassword ? "text" : "password"}
                  colSize={4}
                  validated={validated}
                  feedbackMessage="Please enter the password."
                  validationRegex={validationRegex}
                  validationErrorMessage="Please enter a valid password."

                />
                <span
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  id="button-addon2"
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}

                  style={{
                    position: "absolute",
                    top: "49%",
                    right: "15px", // Corrected the missing unit (px)
                    transform: "translateY(-50%)", // Added double quotes around translateY value
                    cursor: "pointer", // Added double quotes around none
                    fontSize: "16px",
                  }}
                >
                  {showPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityIconOff fontSize="small" />}
                </span>
              </div>
              <ErrorMessagePopup show={errorMessage} onClose={handleCloseErrorPopup} errorMessage={error} />
          <Button type="submit" className="login-button">Login</Button>
        </Form>
        {/* <SelectDropdown /> */}


      </div>



    </div >
  );
}

export default Login;