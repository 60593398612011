import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';



const slice = createSlice(
  {
    name: 'playSchool',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = slice.actions;

export const fetchPlaySchool = () => async (dispatch, getPlaySchool) => {

  const { data, isLoading } = getPlaySchool().playSchool;

  const tokenId = token();

  if (data === null && !isLoading) {
    dispatch(fetchDataStart());

    try {

      const response = await fetch(API_PATH + '/programs', {
        method: 'GET',
        headers: {
          "Content-Type": 'application/json',
          'Authorization': `Bearer ${tokenId}`
        },
        // body: JSON.stringify(requestAndSuggestionPost),
      });;


      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data1 = await response.json();
      dispatch(fetchDataSuccess(data1));
    } catch (error) {
      dispatch(fetchDataFailure());
    }
  }

}

export default slice.reducer;
