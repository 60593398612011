import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';

const slice = createSlice(
    {
        name: 'addAssessment',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchAssessmentData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchAssessmentSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchAssessmentFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    }


);

export const { fetchAssessmentData, fetchAssessmentSuccess, fetchAssessmentFailure ,setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage} = slice.actions;


export const PostAssessmentRequest = (postData) => async (dispatch) => {
    dispatch(fetchAssessmentData());
    const tokenId = token();

    try {
        // Make your POST request here using the fetch function or an HTTP library like Axios
        const response = await fetch(API_PATH + '/assessment-skill', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${tokenId}`
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
            throw new Error('Request failed'); // You can customize error handling
        }

        const responseData = await response.json();
        
        dispatch(fetchAssessmentSuccess([responseData.assessmentSkill]));
        dispatch(setSuccessMessage("Successful!"));
        dispatch(setShowSuccessMessage(true));

    } catch (error) {
        // dispatch(fetchAssessmentFailure());
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));

    }
};


export default slice.reducer;
