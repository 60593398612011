import React from 'react'
import ChildrenData from './ChildrenData';
import { useSelector } from 'react-redux';

const ChildrenProfileData = () => {
    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
    const data = typeof parentLoginObject?.data==='object'?parentLoginObject?.data:{};
    const students = Object.keys(data).length>0?data.childrens:[]
    
  return (
    <>
    {
      students?.length>0?
      students.map((eachChild,i)=>{
        return <ChildrenData eachChildData={eachChild} key={i}/>
      })
      :(<p style={{display:'flex',alignItems:'center',justifyContent:'center',height:'300px'}}>Data Not Found</p>)
    }
    </>
  )
}

export default ChildrenProfileData;