import React, { useState } from "react";
import TextInputField from '../reUsableForm/textInputField';
import { validationRegexAlphaNumeric } from '../reUsableForm/regex';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { Col, Form, Row } from 'react-bootstrap';
import { expensesType, setShowErrorMessage, setShowSuccessMessage } from './slice/expensesTypeSlicer';
import ExpensesTypeView from "./expensesTypeView";

const ExpensesType = () => {
    const dispatch = useDispatch();
    const defaultData = { name: '', }
    const showSuccessMsg = useSelector((state) => state.expensesTypeSlicer.showSuccessMessage);
    const successMsg = useSelector((state) => state.expensesTypeSlicer.successMessage);
    const showErrorMsg = useSelector((state) => state.expensesTypeSlicer.showErrorMessage);
    const errorMsg = useSelector((state) => state.expensesTypeSlicer.errorMessage);
    const [formData, setFormData] = useState(defaultData);
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({expenseType: false})

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newFormErrors = {
            name: !formData.name
        }
        const hasErrors = Object.values(newFormErrors).some((error) => error);
        setFormErrors(newFormErrors);

        if (hasErrors) {
            setValidated(true);
            return;
        }

        const sendData = {
            ...formData,
        }
        dispatch(expensesType(sendData));
        handleClear()
    }
    const handleClear = () => {
        setFormData(defaultData);
        setValidated(false);
        setFormErrors({ selectedDocuments: false })
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };
    

    return (
        <>
            <h4 className="content-header ">Expenses Type</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='name'
                            placeholder='Enter expenses type'
                            textType='Alphabet'
                            validated={validated}
                            required
                            feedbackMessage='Expenses type is required!'
                            validationErrorMessage='Give valid expenses type!'
                            value={formData.name}
                            validationRegex={validationRegexAlphaNumeric}
                            onChange={(event) => { setFormData({ ...formData, name: event.target.value }) }}
                        />
                    </Col>
                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>

            <div>
                <ExpensesTypeView />
            </div>
        </>
    )
}

export default ExpensesType