import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { token } from '../../Api/token';
import { API_PATH } from '../../Api/api';
import { changeParentProfileRenderedState, storeParentProfilePicture } from './parentLoginSlicer/parentLoginSlicer';
import { Col, Image } from 'react-bootstrap';
import { Avatar, Card, Row, Typography } from 'antd';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const ParentProfileData = () => {
  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const dispatch = useDispatch();
  // const isParentProfileRendered = useSelector(state => state.parentLoginReducer.parentProfileRendered);
  // const parentProfilePicture = useSelector(state => state.parentLoginReducer.parentProfilePicture);

  const data = parentLoginObject?.data;

  const currentAddress = data?.parent?.currentAddress?.address + ', ' + data?.parent?.currentAddress?.area + ', ' + data?.parent?.currentAddress?.city + ', ' + data?.parent?.currentAddress?.state + ', ' + data?.parent?.currentAddress?.pincode;

  const image = data?.parent?.profilePictureUrl || 'https://ionicframework.com/docs/img/demos/avatar.svg'



  // const bufferToBase64 = (buffer) => {
  //   return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  // };


  // const displayImage = async (image) => {
  //   try {
  //     const tokenId = token();
  //     const response = await fetch(API_PATH + `/uploadFiles/?fileId=${image}`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${tokenId}`
  //       }
  //     });
  //     const data = await response.json();

  //     if (data && data.length > 0) {
  //       const base64String = bufferToBase64(data[0].data.data);
  //       dispatch(storeParentProfilePicture(`data:image/png;base64,${base64String}`))
  //     }
  //   } catch (err) {
  //     console.error('Error fetching image:', err);
  //   }
  // };

  // useEffect(() => {
  //   if (parentLoginObject?.loading === 'fulfilled' && isParentProfileRendered) {
  //     displayImage(image);
  //     dispatch(changeParentProfileRenderedState())
  //   }
  // }, [parentLoginObject?.loading]);


  // {/* have to put horizontal line */}
  //       {/* <hr /> */}
  //       {/* {parentProfilePicture ? <img src={parentProfilePicture} alt='Profile' className='profileImg' width='150px' height='150px' /> : <p style={{ fontSize: 'small' }}>Unable to display Profile Picture!</p>} */}


  return (
    <>
      {/* <div className='parent-data-wrapper'>
        <div className='parent-data'>
          <div className='each-prop'><span className='each-key'>Name </span><span className='each-colon'>:</span><span className='each-value'> {data.parent?.name}</span></div>
          <div className='each-prop'><span className='each-key'>Email </span><span className='each-colon'>:</span><span className='each-value'> {data.parent?.email}</span></div>
          <div className='each-prop'><span className='each-key'>Contact Number </span><span className='each-colon'>:</span><span className='each-value'> {`${data.parent?.primaryContactNumber ? data.parent?.primaryContactNumber : ''} ${data.parent?.secondaryContactNumber ? `,${data.parent?.secondaryContactNumber}` : ''}`}</span></div>
          <div className='each-prop'><span className='each-key'>Address </span><span className='each-colon'>:</span><span className='each-value'> {currentAddress ? currentAddress : ''}</span></div>
        </div>
        <div className='profile-data'>
       
          {image ?
            <Image
              src={image}
              alt="P Picture"
              style={{ width: "140px", height: "125px", borderRadius: "30px" }}

            /> : null
          }
        </div>
      </div> */}

      <Card style={{ width: '100%', backgroundColor: 'transparent', border: 'none' }}>
        <Row align="middle">
          <Col>
            {image ? <Avatar size={180} src={image} /> : null}
          </Col>
          <Col style={{ marginLeft: '-75px' }}>
            <Title level={4} style={{ margin: 0 }}>{data.parent?.name || ''}</Title>
            <Text><strong>Email:</strong> {data.parent?.email || 'email@example.com'}</Text><br />
            <Text><strong>Contact Number:</strong> {`${data.parent?.primaryContactNumber ? data.parent?.primaryContactNumber : ''} ${data.parent?.secondaryContactNumber ? `,${data.parent?.secondaryContactNumber}` : ''}`}</Text><br />
            <Text><strong>Address:</strong> {currentAddress ? currentAddress : ''}</Text>
          </Col>
          <Col />
        </Row>
      </Card>

    </>
  );
};

export default ParentProfileData;

