import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';

const slice = createSlice({
    name: 'studentAttendance',
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
    },
    reducers: {
        fetchviewStudentAttendanceData: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchviewStudentAttendanceSuccess: (state, action) => {
            state.isLoading = false;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;
        },
        fetchviewStudentAttendanceFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessage: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessage: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    },
});

export const {
    fetchviewStudentAttendanceData,
    fetchviewStudentAttendanceSuccess,
    fetchviewStudentAttendanceFailure
} = slice.actions;

export const fetchMonthlyStudentAttendanceDataOnce = (franchiseeSchoolId, studentId, ids) => async (dispatch, getState) => {
    const { data, isLoading } = getState().studentAttendance;
    const tokenId = token();

    if (data === null && !isLoading) {
        dispatch(fetchviewStudentAttendanceData());

        try {
            const response = await fetch(
                `${API_PATH}/franchisee-school/${franchiseeSchoolId}/studentAttendances/monthly?studentId=${studentId}&${ids}`, 
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokenId}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data1 = await response.json();
            dispatch(fetchviewStudentAttendanceSuccess(data1));
        } catch (error) {
            dispatch(fetchviewStudentAttendanceFailure());
        }
    }
}

export default slice.reducer;
