import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';
import { fetchEnquiryView, setEnquiryData } from './enquiryViewSlicer';

const slice = createSlice(
  {
    name: 'enquiryFollowUp',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
      successMessage: '',
      showSuccessMessage: false,
      errorMessage: '',
      showErrorMessage: false,
    },
    reducers: {
      fetchDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
     
      fetchDataSuccess: (state, action) => {
        state.isLoading = false;
        const newData = action.payload;
        if (!state.data || state.data.length === 0) {
          state.data = newData;
          return;
        }

        // Otherwise, update existing objects or add new ones
        newData.forEach(newItem => {
          const index = state.data.findIndex(item => item.id === newItem.id);
          if (index !== -1) {
            // Replace existing item
            state.data[index] = newItem;
          } else {
            // Add new item
            state.data.push(newItem);
          }
        });
      },

      fetchDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      },
      setSuccessMessage: (state, action) => {
        state.successMessage = action.payload;
      },
      setShowSuccessMessage: (state, action) => {
        state.showSuccessMessage = action.payload;
      },
      setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
      },
      setShowErrorMessage: (state, action) => {
        state.showErrorMessage = action.payload;
      },
    },
  },

);

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const updateEnquiry = (enquiryId, updatedEnquiryData) => async (dispatch) => {
  dispatch(fetchDataStart());
  const tokenId = token();
  try {
    const response = await fetchWithAuthPost(API_PATH + `/student-enquiry/${enquiryId}`, "PUT", tokenId, updatedEnquiryData);
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const data = await response;

      dispatch(fetchDataSuccess([data.data]));
      dispatch(setEnquiryData([data.data]));
      dispatch(setSuccessMessage("Successfull!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    console.error("Error", error);
    const errMessage = responseFormat({ error })
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }

};
export default slice.reducer;
