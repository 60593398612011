import React, { useState, useEffect } from "react";
import  Table  from "../reUsableForm/table/table";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubjectDataOnce } from "./slice/viewSubjectSlice";
import { editSubjectSlice } from "./slice/subjectEditSlice";
import { setShowErrorMessage, setShowSuccessMessage } from './slice/subjectEditSlice'
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";

function ViewSubject() {
  
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});

  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "name", header: "Subject" },
    { field: "className", header: "Class Name"}
   
  ];

  const apiData = useSelector((state) => state.viewSubject.data) || [];
  const postData = useSelector((state) => state.addSubject.data) || [];
  const editData = useSelector((state) => state.editSubject.data) || [];
  const classData1 = useSelector((state) => state.viewClass.data) || [];




  const showSuccessMsg = useSelector((state) => state.editSubject.showSuccessMessage);
  const successMsg = useSelector((state) => state.editSubject.successMessage);

  const showErrorMsg = useSelector((state) => state.editSubject.showErrorMessage);
  const errorMsg = useSelector((state) => state.editSubject.errorMessage);

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
  };

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchSubjectDataOnce());
  }, [dispatch]);

  const handleUpdatedTable = (id, data) => {
    dispatch(editSubjectSlice(id, data))
  }
  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };
  const combinedData = [...apiData, ...postData].map(row => {
    const classNameObj = classData1.find(cls => cls.id === row.classId);
    const className = classNameObj ? classNameObj.name : ""; // Get className if found, otherwise set to empty string
    return {
      ...row,
      className: className
    };
  });

  const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1,
  }));
 

  return (
 
<>
    
      {processedData === null ? ( 
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) : 
      (
        <Table data={processedData} columns={columns} hover={true} striped={true}  showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        editedData={editedData}
        setEditedData={setEditedData}
        handleUpdate={handleUpdatedTable} />
      )
      }
       <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
      </>
  );
}
export default ViewSubject;