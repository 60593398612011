import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import TextInputField from '../reUsableForm/textInputField';
import SelectDropdown from '../SelectDropdown';
import { postAddParentTeacherMeetingRequest, setShowErrorMessage, setShowSuccessMessage } from './slice/parentTeacherMeeting';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import { validationRegexOnlyAlpha } from '../reUsableForm/regex';

const ParentTeacherMeeting = () => {
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({ remarks: false });
    const [dropDowns, setDropDowns] = useState({ class: '', sections: '' });
    const [selectedClassData, setSelectedClassData] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState('');
    const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
    const [ptmData, setPtmData] = useState([]);
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedClassId, setSelectedClassId] = useState();

    const tokenId = token();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        hasParentAttend: 'false',
        remarks: '',
        studentId: '',
        ptm: '',
        date: '',
        classId: '',
        sectionId: '',
    });

    useEffect(() => {
        if (classesClassSectionData.length === 0) {
            dispatch(fetchFranchiseClasswiseSectionsDataOnce());
        }
    }, [classesClassSectionData, dispatch]);

    const showSuccessMsg = useSelector((state) => state.parentTeacherMeetingPost.showSuccessMessage);
    const successMsg = useSelector((state) => state.parentTeacherMeetingPost.successMessage);

    const showErrorMsg = useSelector((state) => state.parentTeacherMeetingPost.showErrorMessage);
    const errorMsg = useSelector((state) => state.parentTeacherMeetingPost.errorMessage);

    const postStudentData = useSelector((state) => state.addStudent.data) || [];
    const getStudentData = useSelector((state) => state.viewStudent.data) || [];

    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
    const FranchiseeSchoolId = Number(franchiseeSchoolId);

    const studentData = [...(getStudentData || []), ...postStudentData];
    const [classData, setClassData] = useState([]);

    useEffect(() => {
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }, [dispatch, FranchiseeSchoolId]);

    const roleIds = Number(sessionStorage.getItem("roleId"));

    const API = `${API_PATH}/franchisee-school/${Number(franchiseeSchoolId)}/parent-teacher-meets-dates/by-ptm?`
    let classSection_id;
    const handleChange = async (name, value) => {

        if (name === "ptm") {
            setFormData({ ...formData, [name]: value.value || value });
            // await fetchPtmData(value.value || value);
        } else if (name === "classId") {
            const classIdValue = value.value || value;
            setFormData({ ...formData, [name]: classIdValue });
            handleClassChange(classIdValue);
            setDropDowns({ ...dropDowns, class: value.label });
            const classSectionData = classesClassSectionData.find((c) => c.id === classIdValue);
            if (classSectionData.classSections.length > 0) {
                // classSection_id = classSectionData.classSections[0].classesclassSection
                // (classSectionData.classSections[0].classesclassSection, ":::claa")
            } else if (classSectionData.classSections.length === 0) {
                classSection_id = classSectionData.classesclassSection
            }
            if (classSection_id && formData.ptm) {
                await fetchPtmData(API + `ptm=${formData.ptm}&classSectionId=${classSection_id}`);
            }
        } else if (name === "sectionId") {
            setSelectedSectionId(value.value);
            setDropDowns(prev => ({ ...prev, sections: value.label }));
            if (formData.ptm && value.value) {
                await fetchPtmData(API + `ptm=${formData.ptm}&classSectionId=${value.value}`);
            }
        }
    };
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const fetchPtmData = async (api) => {
        try {
            const response = await fetch(api, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenId}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            setFormData((prevData) => ({
                ...prevData,
                PTMDateId: data.id,
                date: formatDate(data.date),
                classId: data.class || '',
                sectionId: data.section || '',
            }));
            if (data.class) {
                handleClassChange(data.class);
            }
        } catch (error) {
            console.error("Error fetching PTM data:", error);
            setFormData((prevData) => ({
                ...prevData,
                date: '',
                classId: '',
                sectionId: '',
            }));
        }
    };

    const handleClassChange = (selectedClassId) => {
        const classSectionData = classesClassSectionData.find((c) => c.id === selectedClassId);
        if (classSectionData) {
            setSelectedClassData(classSectionData);
            // setFormData((prevData) => ({
            //     ...prevData,
            //     sectionId: classSectionData.classSections.length > 0 ? classSectionData.classSections[0].id : ''
            // }));
            if (classSectionData.classSections.length > 0) {
                setSelectedSectionId(classSectionData.classSections[0].classesclassSection);
            } else if (classSectionData.classSections.length === 0) {
                setSelectedClassId(classSectionData.classesclassSection);
            } else {
                setSelectedSectionId('');
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newFormErrors = {
            remarks: !formData.remarks,
        };
        const hasErrors = Object.values(newFormErrors).some((error) => error);

        setFormErrors(newFormErrors);

        if (hasErrors) {
            setValidated(true);
            return;
        }

        dispatch(postAddParentTeacherMeetingRequest(FranchiseeSchoolId, formData));
    };

    const handleCancel = () => {
        setFormData({
            date: '',
            hasParentAttend: 'false',
            remarks: '',
            studentId: '',
            ptm: '',
            classId: '',
            sectionId: '',
        });
        setFormErrors({
            remarks: false,
        });
        setValidated(false);
    };

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='content-header'>Parent Teacher Meeting</div>
                <Row className='mt-4'>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <SelectDropdown
                            name="ptm"
                            placeholder="Select PTM"
                            label="PTM Date"
                            options={[
                                { value: "PTM 1", label: "PTM 1" },
                                { value: "PTM 2", label: "PTM 2" },
                                { value: "PTM 3", label: "PTM 3" },
                                { value: "PTM 4", label: "PTM 4" }
                            ]}
                            value={formData.ptm ? { value: formData.ptm, label: formData.ptm } : null}
                            onChange={(e) => handleChange("ptm", e)}
                            isClearable
                        />
                        {formErrors.ptm && <div className="error">PTM Date is required</div>}
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <SelectDropdown
                            label="Class"
                            value={dropDowns.class ? { value: dropDowns.class, label: dropDowns.class } : null}
                            placeholder="Select class"
                            onChange={(selectedOption) => {
                                handleChange("classId", selectedOption);
                            }}
                            options={roleIds === 6 && classData
                                ? classData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                                : classesClassSectionData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                            }
                            required
                            feedbackMessage="Please select a valid class"
                        />
                        {formErrors.class && (
                            <div className="error-message">Please select a valid class.</div>
                        )}
                    </Col>
                    {formData.classId &&
                        selectedClassData &&
                        selectedClassData?.classSections?.length > 0 && (
                            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                                <SelectDropdown
                                    label="Section"
                                    value={
                                        dropDowns.sections
                                            ? {
                                                value: dropDowns.sections,
                                                label: dropDowns.sections,
                                            }
                                            : null
                                    }
                                    placeholder="Select section"
                                    onChange={(selectedOption) => {
                                        handleChange("sectionId", selectedOption);
                                    }}
                                    options={selectedClassData.classSections?.map((section) => ({
                                        key: section.id,
                                        value: section.classesclassSection,
                                        label: section.name,
                                    }))}
                                    required
                                    feedbackMessage="Please select a valid section"
                                />
                                {formErrors.sections && (
                                    <div className="error-message">Please Select Section.</div>
                                )}
                            </Col>
                        )}

                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <Form.Control
                            type="text"
                            placeholder="Date"
                            value={formData.date || ''}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <Form.Label></Form.Label>
                        <SelectDropdown
                            isSearchable
                            required
                            value={formData.studentId ? { label: formData.studentName } : null}
                            options={studentData.map((option) => ({
                                id: option.id,
                                value: option.studentEnrollments[0].studentRollNumber,
                                label: option.name,
                                classId: option.studentEnrollments[0].classesClassSection.classId,
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({
                                    ...formData,
                                    studentId: Number(selectedOption.id),
                                    studentName: selectedOption.label,
                                    classId: selectedOption.classId,
                                });
                            }}
                            validated={validated}
                            placeholder='Student Name'
                            feedbackMessage='Please select student name.'
                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <Form.Label className='lableName'>Parent attended PTM</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                label='Yes'
                                type='radio'
                                name='hasParentAttend'
                                value='true'
                                // className='malebutton'
                                checked={formData.hasParentAttend === 'true'}
                                onChange={(event) => setFormData({ ...formData, hasParentAttend: event.target.value })}
                            />
                            <Form.Check
                                inline
                                label='No'
                                type='radio'
                                name='hasParentAttend'
                                value='false'
                                // className='malebutton'
                                checked={formData.hasParentAttend === 'false'}
                                onChange={(event) => setFormData({ ...formData, hasParentAttend: event.target.value })}
                            />
                        </div>
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className='rowCss'>
                        <Form.Label className='lableName'>Remarks</Form.Label>
                        <TextInputField
                            name='Remarks'
                            placeholder='Remarks'
                            textType='text'
                            value={formData.remarks || ''}
                            onChange={(event) => setFormData({ ...formData, remarks: event.target.value })}
                            validated={validated}
                            feedbackMessage='Please enter Remarks.'
                            validationRegex={validationRegexOnlyAlpha}
                            validationErrorMessage='Please enter valid Remarks.'
                        />
                    </Col>
                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className='clear-button-figma-div' type='button' onClick={handleCancel}>
                        Clear
                    </button>
                    <button className='save-button-figma-div' type='submit'>
                        Submit
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default ParentTeacherMeeting;
