import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import './SearchableDropDownField.css'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    color: "#A7A7A7",
    display: "flex",
    // width: "278px",
    minHeight: "42px",
    // height: "160px",
    color: "#5E6366",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    padding: "-0.625rem 0.75rem 0.375rem 0.75rem",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: "normal",
    gap: "2px",
    alignSelf: "stretch",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    border: "0.5px solid #A7A7A7",
  }),
};

export const SelectingValuesDropDownField = ({
  value,
  onChange,
  placeholder,
  options,
  label,
  feedbackMessage,
  validated,
  isDisabled,
  isMulti
}) => {
  const selectedOption = options.find((option) => option.value === value);

  // Determine if the input is invalid based on the 'validated' prop
  const isInvalid = validated && !selectedOption;
  return (
    <>
      <Form.Group>
        <Select
          isMulti={isMulti}
          required
          className="search-dropdown"
          value={selectedOption}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          styles={customStyles}
          menuPlacement="auto" // Set menuPlacement to 'auto' to enable scrolling
          maxMenuHeight={200} // Set max height for the menu to enable scrolling after 6 options
        />

        {isInvalid && feedbackMessage && (
          <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};


