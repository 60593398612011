import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import "./DatePicker.css";

const DatePickerPast = ({ name, label, value, onChange, placeholder, dateFormat, feedbackMessage, isValidDate = () => true, validated }) => {
  const currentDate = new Date();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      width: '100%',
      color: '#A7A7A7 !important',
      fontFamily: 'Montserrat !important',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      borderColor: validated ? (value ? "#198754" : "red") : "#ccc", // Adjust border color based on validated and value props
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      zIndex: "99999 !important"
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 10000
    }),
  };

  return (
    <Form.Group controlId={name}>
      <DatePicker
        selected={value}
        onChange={date => onChange(name, date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        dateFormat={dateFormat}
        className={`DatePicker_field ${validated ? (value ? 'is-valid' : 'is-invalid') : ''}`}
        maxDate={currentDate}
        isValidDate={isValidDate}
        // customStyles={customStyles}
      />
      {!validated && (
        <div className="invalid-feedback">Please select a valid date.</div>
      )}
    </Form.Group>
  );
};

export default DatePickerPast;