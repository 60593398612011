import { responseStates } from "../../Teachers/teacherSlicers/teachersInitialState"
export const competetionInitialState = {
    postCompetetionAwards:responseStates,
    getCompetetionAwards:responseStates,
    putCompetetionAward:responseStates,
    postCompetetionName:responseStates,
    getCompetetionNames:responseStates,
    students:[
        {
            name:'Chandu',
            rollNumber:'11A0101',
            class:'V',
            section:'A'
        },
        {
            name:'Ramu',
            rollNumber:'11A0102',
            class:'VI',
            section:'B'
        },
        {
            name:'Harish',
            rollNumber:'11A0103',
            class:'VI',
            section:'c'
        },
        {
            name:'Hari',
            rollNumber:'12A0107',
            class:'VII',
            section:'C'
        },
        {
            name:'Vinod',
            rollNumber:'13A0105',
            class:'IX',
            section:'C'
        },
        {
            name:'Narasimha',
            rollNumber:'14A0101',
            class:'X',
            section:'B'
        },
    ],
    requiredStudent:{},
}
