import React, { useEffect, useState } from "react";
import TextInputField from '../reUsableForm/textInputField';
import { amountWithDecimalPattern, validationRegexAddress } from '../reUsableForm/regex';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { Col, Form, Row } from 'react-bootstrap';
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { pettyExpenses, setShowErrorMessage, setShowSuccessMessage } from "./slice/pettyExpensesSlicer";
import { fetchExpensesTypeView } from "./slice/expensesTypeViewSlicer";
import SelectDropdown from "../SelectDropdown";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchWithAuthorization } from "../Auth/dependency-get";

const PettyExpenses = () => {
    const dispatch = useDispatch();
    const showSuccessMsg = useSelector((state) => state.pettyExpensesSlicer.showSuccessMessage);
    const successMsg = useSelector((state) => state.pettyExpensesSlicer.successMessage);

    const showErrorMsg = useSelector((state) => state.pettyExpensesSlicer.showErrorMessage);
    const errorMsg = useSelector((state) => state.pettyExpensesSlicer.errorMessage);

    const apiData = useSelector((state) => state.expensesTypeViewSlicer.data) || [];
    const postData = useSelector((state) => state.expensesTypeSlicer.data) || [];

    const combinedData = [...(apiData || []), ...postData]

    const [formData, setFormData] = useState({
        date: "",
        amount: '',
        received: '',
        expenseTypeId: null,
        description: '',
        balance: '',
    });
    useEffect(() => {
        if (apiData.length === 0) {
            dispatch(fetchExpensesTypeView());
        }
    }, [apiData, dispatch]);

    const FranchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
    const franchiseeSchoolId = Number(FranchiseeSchoolId);

    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({ name: false, date: false })
    const [initialBalance, setInitialBalance] = useState(0.45);

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newFormErrors = {
            date: !formData.date,
            expenseTypeId: !formData.expenseTypeId,
            amount: !formData.amount,
            received: !formData.received,
        }
        const hasErrors = Object.values(newFormErrors).some((error) => error);
        setFormErrors(newFormErrors);

        if (hasErrors) {
            setValidated(true);
            return;
        }

        const sendData = {
            ...formData,
            franchiseeSchoolId: franchiseeSchoolId,
            expenseTypeId: formData.expenseTypeId.id,
        }
        dispatch(pettyExpenses(sendData));
        handleClear();

    }
    const handleClear = () => {
        setFormData({
            amount: '',
            received: '',
            modeOfPayment: '',
            description: '',
        });
        setFormErrors({})
        setValidated(false);
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        fetchBalance();
        // handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };

        if (name === "amount" || name === "received") {
            const amount = parseFloat(updatedFormData.amount) || 0;
            const received = parseFloat(updatedFormData.received) || 0;
            const totalBalance = received - amount
            const finalBalance = initialBalance + totalBalance
            // Calculate the new balance using the initial balance fetched from API
            updatedFormData.balance = (finalBalance).toFixed(2);
        }

        setFormData(updatedFormData);
    };

    const fetchBalance = async (retryCount = 3) => {
        try {

            const response = await fetchWithAuthorization(
                `${API_PATH}/franchisee-school/${franchiseeSchoolId}/petty-expenses/balance`, "GET", token()
            )
            const balanceFromAPI = parseFloat(response.balance) || 0;
            setInitialBalance(balanceFromAPI); // Store the fetched balance
            setFormData({ ...formData, balance: balanceFromAPI });
        } catch (error) {
            console.error('Error fetching balance:', error);
            if (retryCount > 0) {
                setTimeout(() => fetchBalance(retryCount - 1), 2000); // Retry after a 2-second delay
            } else {
                console.error('Failed to fetch balance after retries');
            }
        }
    };

    useEffect(() => {
        fetchBalance();
    }, [franchiseeSchoolId]); // Empty array means this runs once when the component mounts


    return (
        <>
            <h4 className="content-header ">Petty Expenses</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <DatePickerPast
                            label="DOB*"
                            value={formData.date || ""}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, date: date });
                                setFormErrors({ ...formErrors, date: false });
                            }}
                            required
                            name="DOB"
                            placeholder="Select Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select date."
                        />
                        {formErrors.date && (
                            <div className="error-message">Please select a date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={formData.expenseTypeId ? { id: formData.expenseTypeId.id, label: formData.expenseTypeId.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, expenseTypeId: selectedOption });
                                setFormErrors({ ...formErrors, expenseTypeId: !selectedOption });
                            }}
                            placeholder="Select expenses"
                        />
                        {formErrors.expenseTypeId && (
                            <div className="error-message">
                                Please Select expenses.
                            </div>
                        )}
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='amount'
                            placeholder='Enter amount'
                            textType='Alphabet'
                            validated={validated}
                            required
                            feedbackMessage='Enter amount is required!'
                            validationErrorMessage='Give valid enter amount!'
                            value={formData.amount}
                            validationRegex={amountWithDecimalPattern}
                            onChange={handleFormChange}
                        />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='received'
                            placeholder='Enter received amount'
                            textType='Alphabet'
                            validated={validated}
                            required
                            feedbackMessage='Received amount is required!'
                            validationErrorMessage='Give valid received amount!'
                            value={formData.received}
                            validationRegex={amountWithDecimalPattern}
                            onChange={handleFormChange}
                        />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='balance'
                            placeholder='Enter balance amount'
                            textType='Alphabet'
                            validated={validated}
                            required
                            feedbackMessage='Balance amount is required!'
                            validationErrorMessage='Give valid balance amount!'
                            value={formData.balance}
                            validationRegex={amountWithDecimalPattern}
                            readOnly
                        />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='description'
                            placeholder='Description'
                            textType='Alphabet'
                            validated={validated}
                            required='true'

                            feedbackMessage='Description is required!'
                            validationErrorMessage='Give valid description!'
                            value={formData.description}
                            validationRegex={validationRegexAddress}
                            onChange={(event) => { setFormData({ ...formData, description: event.target.value }) }}
                        />
                    </Col>

                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>

            </Form >
        </>
    )
}

export default PettyExpenses



