import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../reUsableForm/table/table';
import { useNavigate } from "react-router-dom";
import { FitnessParameterView } from './slice/fitnessParameterViewSlice'

const FitnessParameter = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FitnessParameterView());
    }, [dispatch]);

    const navigateToStudentFee = () => {
        navigate('/fitness');
    };

    const columns = [
        { field: "month", header: "Month" },
        { field: "name", header: "Student" },
        { field: "height", header: "Height" },
        { field: "weight", header: "Weight" },
        { field: "runningIn1mint", header: "Running" },
    ];

    const apiData = useSelector((state) => state.fitnessParameterView.data) || [];
    const postData = useSelector((state) => state.fitnessParameter.data) || null;

    let newModifiedData = {};
    const combinedData = [...(apiData || []), ...([newModifiedData])];
    const modifiedData = combinedData.map((items) => {
        const rowData = {};
        columns.forEach((column) => {
            if (column.field === "student") {
                rowData[column.field] = items.student?.name;
            } else {
                rowData[column.field] = items[column.field];
            }

            if (column.field === "name") {
                rowData[column.field] = items.student?.name; // Adjusted to fetch the student's name
            }

            if ("id" === "id") {
                rowData["id"] = items.id;
            }
        });
        return rowData;
    });


    return (
        <div>
            <div className="content-header">Fitness Parameter</div>
            <button
                onClick={navigateToStudentFee}
                className="save-button-figma-div"
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    marginRight: "20px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "206px",
                }}>
                <a >Add&nbsp;Fitness&nbsp;Parameter</a>
            </button>
            {modifiedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}><p>No records available</p></center>
            ) : (
                <TableView data={modifiedData} columns={columns} hover={true} />
            )}
        </div>
    );
};

export default FitnessParameter;