import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { currentPage } from "../slice/currentPageSlice";
import { Button, Space } from "antd";
import { SelectingValuesDropDownField } from "../../../ReusableFields/DropDown/multipleSelectDropDown";
import SearchableDropDown from "../../../ReusableFields/DropDown/searchableDroDown";
import { PostSchoolCreation, setShowErrorMessage, setShowSuccessMessage } from "../slice/creationSchool";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";
import { fetchDataOnce } from "../../Classes/slice/viewClassSlice";
import { fetchSectionDataOnce } from "../../section/slice/viewSectionSlice";
import { getAddSchoolData } from "../slice/schoolCreationslice";
import { fetchSubjectDataOnce } from "../../subject/slice/viewSubjectSlice";
import { fetchClassSectionsDataOnce } from "../slice/viewScetions";
import { fetchDataOnceProgram } from "../../programs/slice/viewProgramSlice";
import { addClassData } from "../slice/addClass";
import { fetchDataGetClassesByProgramId } from "../../programs/slice/getClassByProgramId";

export const AddClass = () => {
    const [validated, setValidated] = useState(false);
    const [classRows, setClassRows] = useState([{ id: 1, programId: null, classId: null, className: [], selectedSection: [] }]);

    const [formErrors, setFormErrors] = useState(
        [{ id: 1, classId: false, programId: false }]
    );
    const dispatch = useDispatch();

    const classes = useSelector((state) => state.getClassesByProgramId.data) || [];
    const sections = useSelector((state) => state.viewSections.data) || [];
    const programsData = useSelector((state) => state.viewProgram.data) || [];


    useEffect(() => {
        dispatch(fetchDataOnce())
        dispatch(fetchSubjectDataOnce())
        dispatch(fetchDataOnceProgram())

    }, [dispatch])

    const addClassRow = () => {
        const newRowId = classRows.length + 1;
        setClassRows([...classRows, { id: newRowId, classId: "" }]);
        const newFormErrorId = formErrors.length + 1
        setFormErrors([...formErrors, { id: newFormErrorId, classId: false, programId: false }]);

    };

    const removeClassRow = (id) => {
        setClassRows(classRows.filter((row) => row.id !== id));
    };




    const handleProgramChange = (id, selectedOption) => {
        dispatch(fetchDataGetClassesByProgramId(selectedOption.id))

        const updatedClassRows = classRows.map((row) => {
            if (row.id === id) {
                return { ...row, programId: selectedOption.id, classId: '', selectedSection: [] };
            }
            return row;
        });
        setClassRows(updatedClassRows);
        const updatedErrors = [...formErrors];
        updatedErrors[id - 1].programId = !selectedOption;
        setFormErrors(updatedErrors);
    };


    const handleClassChange = (id, selectedOption) => {
        dispatch(fetchClassSectionsDataOnce(selectedOption.id))
        const updatedClassRows = classRows.map((row) => {
            if (row.id === id) {
                return { ...row, classId: selectedOption.id, className: selectedOption, selectedSection: [] };
            }
            return row;
        });
        setClassRows(updatedClassRows);
        const updatedErrors = [...formErrors];
        updatedErrors[id - 1].classId = !selectedOption;
        setFormErrors(updatedErrors);
    };

    const handleSectionChange = (id, selectedOption) => {
        // const sections = selectedOption.map((data) => data.id)
        const updatedClassRows = classRows.map((row) => {
            if (row.id === id) {
                return { ...row, selectedSection: selectedOption };
            }
            return row;
        });
        setClassRows(updatedClassRows);
        const updatedErrors = [...formErrors];
        updatedErrors[id - 1].selectedSection = !selectedOption;
        setFormErrors(updatedErrors);
    };



    const addClassPreviousData = useSelector((state) => state.FranchiseAddClass.data) || [];


    const schoolData = useSelector((state) => state.addSchoolData.data);

    const showSuccessMsg = useSelector((state) => state.schoolCreation.showSuccessMessage);
    const successMsg = useSelector((state) => state.schoolCreation.successMessage);

    const showErrorMsg = useSelector((state) => state.schoolCreation.showErrorMessage);
    const errorMsg = useSelector((state) => state.schoolCreation.errorMessage);

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
        dispatch(currentPage('0'));
        dispatch(getAddSchoolData([]))
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    const handleCancel = () => {

        setClassRows([{ id: 1, classId: "", selectedSection: [] }])

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // Check for errors in classId and section
        const newFormErrors = classRows.map(({ classId, programId }) => ({
            classId: !classId,
            programId: !programId,
        }));

        setFormErrors(newFormErrors);
        const hasErrors = newFormErrors.some((error) => Object.values(error).some((value) => value));

        if (hasErrors) {
            setValidated(true);
            return;
        }

        // Prepare data for submission
        const newData = classRows.map(({ selectedSection, ...rest }) => ({
            ...rest,
            sections: selectedSection.map(section => section.id) || [],
        }));

        const sendData = {
            school: schoolData,
            classes: newData,
        };
        dispatch(PostSchoolCreation(sendData));
        // dispatch(addClassData(sendData?.classes))
    };





    const prev = () => {
        dispatch(currentPage('0'));
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {classRows.map((classRow) => (
                    <Row key={classRow.id}>
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                            <SearchableDropDown
                                value={programsData.find((data) => data.id === classRow.programId)?.name || ""}
                                className="input-style"
                                onChange={(selectedOption) => handleProgramChange(classRow.id, selectedOption)}
                                placeholder="Select Program*"
                                options={programsData.map((option, index) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage={formErrors[classRow.id - 1]?.programId && "Please select a program."}
                                validated={validated}
                            />
                            {formErrors[classRow.id - 1]?.programId && (
                                <div className="error-message">
                                    Please select a program
                                </div>
                            )}

                        </Col>
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >

                            <SelectingValuesDropDownField
                                value={classRow.className}
                                className="input-style"
                                onChange={(selectedOption) => handleClassChange(classRow.id, selectedOption)}
                                placeholder="Select Class"
                                options={classes.map((option) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage={formErrors[classRow.id - 1]?.classId && "Please select a class."}
                                validated={validated}
                            />
                            {formErrors[classRow.id - 1]?.classId && (
                                <div className="error-message">
                                    Please select a class
                                </div>
                            )}

                        </Col>
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                            <SelectingValuesDropDownField
                                isMulti={true}
                                value={classRow.selectedSection}
                                className="input-style"
                                onChange={(selectedOption) => handleSectionChange(classRow.id, selectedOption)}
                                placeholder="Select Section"
                                options={sections.map((option) => ({
                                    id: option.id,
                                    value: option.name,
                                    label: option.name,
                                }))}
                                feedbackMessage={formErrors[classRow.id - 1]?.selectedSection && "Please select at least one section."}
                                validated={validated}
                                
                            />
                             <div className="error-message">
                                    Please select a Sections
                                </div>

                        </Col>

                        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                            <Space>
                                <Button onClick={addClassRow}>Add Row</Button>
                                <Button onClick={() => removeClassRow(classRow.id)}>Remove Row</Button>
                            </Space>
                        </Col>
                    </Row>

                ))}

                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />


                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <Button onClick={prev}>
                            Previous
                        </Button>
                    </Col>
                    <div style={{ display: "flex", justifyContent: "center", }}>
                        <button className="clear-button-figma-div" type="button" onClick={handleCancel} >
                            Clear
                        </button>
                        <button className="save-button-figma-div" type="submit" >
                            Submit
                        </button>
                    </div>
                </Row>
            </Form >
        </>
    );
};
