import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { franchiseeSchoolId, token } from '../../../Api/token';


const slice = createSlice(


  {
    name: 'viewFranchiseClasswiseSections',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchFranchiseClasswiseSectionsDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchFranchiseClasswiseSectionsDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchFranchiseClasswiseSectionsDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchFranchiseClasswiseSectionsDataStart, fetchFranchiseClasswiseSectionsDataSuccess, fetchFranchiseClasswiseSectionsDataFailure } = slice.actions;


export const fetchFranchiseClasswiseSectionsDataOnce = (id) => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewFranchiseClasswiseSections;

  const tokenId = token();

  if ((data === null && !isLoading) || (data !== null)) {

    dispatch(fetchFranchiseClasswiseSectionsDataStart());

    try {

      const response = await fetch(API_PATH + `/franchiseclasswisesections/${id || franchiseeSchoolId()}`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${tokenId}`
          }
        });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data1 = await response.json();
      dispatch(fetchFranchiseClasswiseSectionsDataSuccess(data1));
    } catch (error) {

      dispatch(fetchFranchiseClasswiseSectionsDataFailure());
    }
  }

}

export default slice.reducer;
