/*
    authors: Rajeev
    date: 20-12-2023
    update:20-12-2023 
    version: 1.0
*/

import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../../Api/token";
import { API_PATH } from "../../../Api/api";
import { responseFormat } from "../../Auth/errorResponse";

const slice = createSlice({
  name: "addTimeTable",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchTimeTableData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchTimeTableSuccess: (state, action) => {
      state.isLoading = false;
      state.data = state.data
        ? [...state.data, ...action.payload]
        : action.payload;
    },
    fetchTimeTableFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
    setPostTime:(state,action)=>{
      state.isLoading = false;
      state.data = action.payload
    }
  },
});

export const {
  fetchTimeTableData,
  fetchTimeTableSuccess,
  fetchTimeTableFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
  setPostTime
} = slice.actions;

export const postTimeTableRequest = (postData) => async (dispatch) => {
  dispatch(fetchTimeTableData());
  const tokenId = token();
  try {
    // Make your POST request here using the fetch function or an HTTP library like Axios
    const response = await fetch(API_PATH + "/timeTable", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenId}`,
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      throw new Error("Request failed"); // You can customize error handling
    } else {
      const responseData = await response.json();
      dispatch(fetchTimeTableSuccess(responseData.classTimeTables));
      dispatch(setSuccessMessage("Successful!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    dispatch(fetchTimeTableFailure());
    const errMessage = responseFormat({ error });
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
