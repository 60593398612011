import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table";
import { validationRegexOnlyAlpha } from "../reUsableForm/regex";
import { setShowSuccessMessage, setShowErrorMessage, postAddCompetitionRequest } from "./slice/addCompetitionSlice";
import { fetchViewCompetitionDataOnce } from "./slice/viewCompetitionSlice";


const AddCompetition = () => {
  const [formData, setFormData] = useState({ name: '' });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const showSuccessMsg = useSelector((state) => state.addCompetition?.showSuccessMessage || false);
  const successMsg = useSelector((state) => state.addCompetition?.successMessage || '');

  const showErrorMsg = useSelector((state) => state.addCompetition?.showErrorMessage || false);
  const errorMsg = useSelector((state) => state.addCompetition?.errorMessage || '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    const data = { ...formData };
    dispatch(postAddCompetitionRequest(data));
  };

  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "name", header: "Competition" },
  ];

  const apiData = useSelector((state) => state.viewCompetition?.data) || [];
  const postData = useSelector((state) => state.addCompetition?.data) || [];
  
  const combinedData = [...apiData, ...postData];
  const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1,
  }));

  useEffect(() => {
    dispatch(fetchViewCompetitionDataOnce());
  }, [dispatch]);

  const handleClear = () => {
    setFormData({ name: '' });
    setValidated(false);
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <div>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Add Competition</div>
            <Row style={{ justifyContent: "center", marginTop: "1%" }}>
              <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                <Form.Group>
                  <TextInputField
                    type="text"
                    className="input-style"
                    required
                    placeholder="Competition"
                    value={formData.name}
                    onChange={(event) => {
                      setFormData({ ...formData, name: event.target.value });
                    }}
                    validated={validated}
                    feedbackMessage="please enter Competition"
                    // Assuming validationRegexOnlyAlpha is correctly defined
                    validationRegex={validationRegexOnlyAlpha}
                    validationErrorMessage="please enter valid Competition"
                  />
                </Form.Group>
              </Col>
            </Row>
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0.5%",
                marginTop: "0%"
              }}
            >
              <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
                style={{ width: '6rem', height: '2rem' }}
              >
                Clear
              </button>
              <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
                Submit
              </button>
            </div>
          </Form>
        </Col>
      </Row>
      <div className="App">
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={processedData} columns={columns} hover={true} striped={true} />
        )
      }
      </div>
    </div>
  );
};

export default AddCompetition;
