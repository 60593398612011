import React from 'react';
import { Form, Col } from 'react-bootstrap';
import "./AddressField.css"
const AddressField = ({
  label,
  name,
  address,
  onChange,
  required,
  placeholder,
  feedbackMessage,
  validated,
  mt4
}) => {
  return (
      <Form.Group>
  {label && <Form.Label className="label-style">{label}</Form.Label>}


        <Form.Control
          as="textarea"
          className="address_field"
          name={name}
          value={address}
          onChange={onChange}
          placeholder={placeholder?placeholder:"Address: Street,..."}
          required
          validated={validated}
          rows={3} // Set the number of visible rows for the textarea
        />
        {validated && feedbackMessage && (
          <Form.Control.Feedback type="invalid">
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
  );
};

export default AddressField;
