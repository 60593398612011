import React, { useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentDataOnce } from "./slicer/viewAssessmentSlicer";
import { PostAssessmentRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addAssessmentSlicer";
import { fetchDataOnce } from "../Classes/slice/viewClassSlice";

const AssessmentSkillView = () => {
    const columns = [
        { field: "index", header: "Sr.no" },
        { field: "className", header: "Class" },
        { field: "name", header: "Skill" },

    ];

    const apiData = useSelector((state) => state.viewAssessment.data) || [];
    const postData = useSelector((state) => state.addAssessment.data) || [];
    const data = useSelector((state) => state.viewClass.data) || [];
    const addData = [...(data || [])]

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAssessmentDataOnce());
        dispatch(fetchDataOnce());
    }, [dispatch])

    let newModifiedData = []
    if (postData && postData.length > 0) {
        postData.forEach((postItem) => {
            const modifiedData = postItem;
            const findObject = addData.find((obj) => obj.id == postItem.classId);
            const newData = {
                ...modifiedData,
                class: findObject,
                className: findObject?.name
            };
            newModifiedData.push(newData);
        });
    }

    const combinedData = [...(apiData || []), ...(newModifiedData)];
    const processedData = combinedData.map((row, index) => ({
        ...row,
        index: index + 1,
        className: row.class ? row.class.name : "",
    }));

    return (
        <>
            {/* <div className="content-header">View Area</div> */}
            {processedData === null ? (
                <center><p>Loading...</p></center>
            ) : processedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) :
                (
                    <Table data={processedData} columns={columns} hover={true} striped={true} />
                )
            }
        </>
    );
}
export default AssessmentSkillView;
