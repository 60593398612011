import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import {setEmployeeEnquiryEmptyData} from "./employeeEnquirySlice"

const slice = createSlice(
  {
    name: 'viewEmployeeEnquiry',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchEmployeeEnquiryData: (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchEmployeeEnquirySuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchEmployeeEnquiryFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      },
      setEnquiryData: (state, action) => {
        state.isLoading = false;
        const newData = action.payload;

        const existingObjectIndex = state.data.findIndex(item => item.id === newData.id);

        if (existingObjectIndex !== -1) {
          state.data[existingObjectIndex] = newData;
        }

      },
    },
  }
);
export const { fetchEmployeeEnquiryData, fetchEmployeeEnquirySuccess, fetchEmployeeEnquiryFailure, setEnquiryData } = slice.actions;

export const fetchDataOnce = () => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewEmployeeEnquiry;

  if (data === null && !isLoading) {
    dispatch(fetchEmployeeEnquiryData());

    try {
      const franchiseeSchoolId = parseInt(sessionStorage.getItem('franchiseeSchoolId'));
      const response = await fetch(API_PATH + `/employee-enquires?isActive=true&franchiseeSchoolId=${franchiseeSchoolId}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${token()}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchEmployeeEnquirySuccess(data1));
      dispatch(setEnquiryData(data1));
      dispatch(setEmployeeEnquiryEmptyData([]));
    } catch (error) {
      dispatch(fetchEmployeeEnquiryFailure());
    }
  }
}
export default slice.reducer;
