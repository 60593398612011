import { API_PATH } from "../../../../Api/api";
import { token } from "../../../../Api/token";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequestOptions } from "../../../Teachers/teacherSlicers/allRequests";

export const postQualification = createAsyncThunk('post qualification', async (payload, thunkApi) => {

    const response = await fetch(API_PATH + '/qualification', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token()}`
        },
        body: JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        return thunkApi.rejectWithValue(data.errors)
    }
    return data;
})