import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { fetchRevenueTypeView } from "./slice/revenueTypeViewSlicer";
import { RevenuesViewSlicer } from "./slice/revenueSheetViewSlicer";
import { format } from 'date-fns';
import TableView from "../reUsableForm/table/table";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import SelectDropdown from "../SelectDropdown";


const RevenueSheetView = () => {
    const dispatch = useDispatch();
    const apiData = useSelector((state) => state.revenueSheetView.data) || [];
    const apiDataa = useSelector((state) => state.revenueTypeView.data) || [];
    const postData = useSelector((state) => state.revenueType.data) || [];
    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        revenueTypeId: null,
    });
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({
        fromDate: false,
        toDate: false,
        revenueTypeId: false,
    });

    useEffect(() => {
        dispatch(fetchRevenueTypeView());
        dispatch(RevenuesViewSlicer());
    }, [dispatch]);

    // Combine data from different sources
    const combinedData = [...(apiDataa || []), ...postData];

    // Process API data
    const apiDataArray = Array.isArray(apiData) ? apiData : [];
    const processedData = apiDataArray.map((row, index) => ({
        ...row,
        index: index + 1,
        // id: row.id,
        date: row.date ? new Date(row.date).toLocaleDateString('en-GB') : 'N/A',
        revenueTypeName: row.revenueType ? row.revenueType.name : 'N/A',
        amountReceived: parseFloat(row.feeAmount) || 0,
        modeOfPayment: row.modeOfPayment || 'N/A',
        franchiseeSchoolName: row.franchiseeSchool ? row.franchiseeSchool.name : 'N/A',
        className: row.student?.studentEnrollments?.[0]?.classesClassSection?.class?.name || 'N/A',
        classSection: row.student?.studentEnrollments?.[0]?.classesClassSection?.classSection?.nam || 'N/A',
        studentName: row.student ? row.student.name : 'N/A',
        school: row.franchiseeSchool.name
    }));

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
        if (formData.fromDate && formData.toDate && formData.revenueTypeId) {
            dispatch(RevenuesViewSlicer(formData.fromDate, formData.toDate, formData.revenueTypeId.id));
        } else {
            setFormErrors({
                fromDate: !formData.fromDate,
                toDate: !formData.toDate,
                revenueTypeId: !formData.revenueTypeId,
            });
        }
    };


    const handleClear = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            revenueTypeId: null,
        });
        dispatch(RevenuesViewSlicer());
        setFormErrors({
            fromDate: false,
            toDate: false,
            revenueTypeId: false,
        });
        setValidated(false);
    };

    const columns = [
        { field: 'index', header: 'Sr.No' },
        { field: 'school', header: 'School' },
        { field: 'date', header: 'Date' },
        { field: 'className', header: 'Class' },
        { field: 'classSection', header: 'Section' },
        { field: 'studentName', header: 'Student' },
        { field: 'revenueTypeName', header: 'Revenue Type' },
        { field: 'amountReceived', header: 'Fee Amount' },
        { field: 'modeOfPayment', header: 'Mode Of Payment' },
    ];

    return (
        <>
            <h4 className="content-header">Revenue View</h4>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="From Date*"
                            value={formData.fromDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, fromDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, fromDate: !date }));
                            }}
                            required
                            name="From Date"
                            placeholder="From Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the from date."
                            validated={validated}
                        />
                        {formErrors.fromDate && (
                            <div className="error-message">Please select the from date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="To Date*"
                            value={formData.toDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, toDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, toDate: !date }));
                            }}
                            required
                            name="To Date"
                            placeholder="To Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the to date."
                            validated={validated}
                        />
                        {formErrors.toDate && (
                            <div className="error-message">Please select the to date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={formData.revenueTypeId ? { id: formData.revenueTypeId.id, label: formData.revenueTypeId.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData(prevFormData => ({
                                    ...prevFormData,
                                    revenueTypeId: selectedOption
                                }));
                                setFormErrors(prevFormErrors => ({ ...prevFormErrors, revenueTypeId: !selectedOption }));
                            }}
                            placeholder="Select revenue"
                        />
                        {formErrors.revenueTypeId && (
                            <div className="error-message">
                                Please Select revenue.
                            </div>
                        )}
                    </Col>
                </Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-5%",
                        marginLeft: "35%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>

            <Row>
                {processedData.length > 0 ? (
                    <TableView columns={columns} data={processedData} isExport={true} isExcel={true} excelFileName={`revenues`} />
                ) : (
                    <div>No data available</div>
                )}
            </Row>
        </>
    );
};


export default RevenueSheetView