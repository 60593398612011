import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import SelectDropdown from "../SelectDropdown";
import PastDateField from '../../ReusableFields/DateFields/PastDateField';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchFranchiseClasswiseSectionsDataOnce } from '../Assessment/slicer/viewfranchiseclasswisesectionsSlicer';
import { useDispatch, useSelector } from "react-redux";
import SearchableDropDown from '../../ReusableFields/DropDown/searchableDroDown';
import { getParentLoginData } from '../parent/parentLoginSlicer/parentLoginRequests';

const ViewActivityUpdate = () => {
  const dispatch = useDispatch();
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
  const tokenId = token();
  const roleIds = Number(sessionStorage.getItem("roleId"));
  const teacherId = Number(sessionStorage.getItem('userId'));
  const [classData, setClassData] = useState([]);
  const [formData, setFormData] = useState({ date: '', classId: '' });
  const [formErrors, setFormErrors] = useState({ update: false, ClassActivity: false, class: false, sections: false });
  const [dropDowns, setDropDowns] = useState({ class: '', sections: '' });
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [classActivityDropDowns, setClassActivityDropDowns] = useState({});
  const [selectedClassData, setSelectedClassData] = useState([]);
  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
  const Ids = Number(franchiseeSchoolId);
  const [selectedClass, setSelectedClass] = useState("");

  const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);
  const data = parentLoginObject?.data;
  const [validated, setValidated] = useState(false);
  const [childClass, setChildClass] = useState({});

  useEffect(() => {
    if (roleIds == 5) {
      if (parentLoginObject?.loading === "idle") {
        const parentId = sessionStorage.getItem('userId');
        dispatch(getParentLoginData(parentId));
      }
    }
  }, [dispatch, parentLoginObject?.loading]);

  useEffect(() => {
    if (roleIds === 6) {
      const fetchData = async () => {
        try {
          const url = `${API_PATH}/franchisee-school/${franchiseeSchoolId}/teachers/${teacherId}/classes`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch classes data. Status: ${response.status}`);
          }
          const result = await response.json();
          setClassData(result);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [Ids, teacherId, tokenId, roleIds]);

  useEffect(() => {
    if (classesClassSectionData.length === 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [classesClassSectionData, dispatch]);

  const fetchClassActivities = async (date, classSectionId) => {
    try {
      const response = await fetch(`${API_PATH}/franchisee-school/${franchiseeSchoolId}/class/activities?date=${date}&classSectionId=${classSectionId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching class activities:", error);
      return [];
    }
  };

  const handleClassChange = (selectedClassId) => {
    let classSectionData;
    if (roleIds === 6) {
      classSectionData = classData.find((c) => c.id === selectedClassId);


    } else {
      classSectionData = classesClassSectionData.find((c) => c.id === selectedClassId);
    }

    if (classSectionData) {
      setSelectedClassData(classSectionData);
      if (classSectionData.classSections.length == 1) {
        setSelectedSectionId(classSectionData.classSections[0].classesclassSection);
      } else if (classSectionData.classSections.length === 0) {
        setSelectedSectionId(classSectionData.classesclassSection);
      } else {
        setSelectedSectionId('');
      }
    }
  };

  useEffect(() => {
    if (selectedClass) {
      handleClassChange(selectedClass);
      setDropDowns({ ...dropDowns, sections: "" });
    }
  }, [selectedClass]);

  const handleChange = (name, value) => {
    if (name === "classId") {
      const classIdValue = value.value || value;
      setFormData({ ...formData, [name]: classIdValue });
      handleClassChange(classIdValue);
      setDropDowns({ ...dropDowns, class: value.label });
    } else if (name === "sectionId") {
      setSelectedSectionId(value.value);
      setDropDowns(prev => ({ ...prev, sections: value.label }));
    }

  };

  useEffect(() => {
    if ((formData.date && formData.classId && selectedSectionId) || (formData.date && childClass.classId)) {
      fetchClassActivities(formData.date, selectedSectionId || childClass.classId)
        .then(data => {
          setClassActivityDropDowns(prev => ({
            ...prev,
            activities: data ? data : null

          }));
        })
        .catch(error => console.error("Error fetching class activities:", error));
    }
  }, [formData.date, formData.classId, selectedSectionId, childClass]);
  
  return (
    <>
      <Row style={{ textAlign: 'center', marginTop: '20px' }}>
        <Col xs={12} md={6} lg={4} className="rowCss">
          <PastDateField
            name="date"
            placeholder="date"
            required
            value={formData.date}
            onChange={(e) => {
              setFormData({ ...formData, date: e.target.value });
              setFormErrors({ ...formErrors, update: false });

            }}
          />
        </Col>
        {roleIds == 5 && data?.childrens?.length > 0 ? (
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDown
              value={childClass.studentName || ''}
              className="input-style"
              onChange={(selectedOption) => {
                setChildClass({ ...childClass, "classId": selectedOption.id, "studentName": selectedOption.value })
              }}
              placeholder="Select child"
              options={data?.childrens?.map((option) => ({
                id: option.studentEnrollments?.[0].classesClassSectionId,
                value: option.name,
                label: option.name,
              }))}
              feedbackMessage="Please select Fee Head."
              validated={validated}
            />
          </Col>
        ) : (

          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              label="Class"
              value={dropDowns.class ? { value: dropDowns.class, label: dropDowns.class } : null}
              placeholder="Select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);

              }}
              options={roleIds === 6 && classData
                ? classData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
                : classesClassSectionData.map((classItem) => ({ value: classItem.id, label: classItem.name }))
              }
              required
              feedbackMessage="Please select a valid class"
            />
            {formErrors.class && (
              <div className="error-message">Please select a valid class.</div>
            )}
          </Col>
        )}

        {formData.classId &&
          selectedClassData &&
          selectedClassData?.classSections?.length > 0 && (
            <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
              <SelectDropdown
                label="Section"
                value={
                  dropDowns.sections
                    ? {
                      value: dropDowns.sections,
                      label: dropDowns.sections,
                    }
                    : null
                }
                placeholder="Select section"
                onChange={(selectedOption) => {
                  handleChange("sectionId", selectedOption);

                }}
                options={selectedClassData.classSections?.map((section) => ({
                  key: section.id,
                  value: section.classesclassSection,
                  label: section.name,
                }))}
                required
                feedbackMessage="Please select a valid section"
              />
              {formErrors.sections && (
                <div className="error-message">Please Select Section.</div>
              )}
            </Col>
          )}

        {classActivityDropDowns && classActivityDropDowns?.activities ? (
          <div>
            <p className="content-header">Lesson details for the day.</p>
            <div className="one-line" style={{ justifyContent: "center" }}>
              <p className="content-header">
                {classActivityDropDowns?.activities?.subject?.name || ''}:
              </p>
              <span style={{ fontWeight: "500" }}>{classActivityDropDowns?.activities?.update || ''}</span>
            </div>
          </div>
        ) : classActivityDropDowns.activities ? (
          <p>No activities available.</p>
        ) : (
          <p>No activities available. Please select a valid date and class.</p>
        )}
      </Row >
    </>
  );
};

export default ViewActivityUpdate;
