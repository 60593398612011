import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../Api/token";
import { API_PATH } from "../../Api/api";
import { fetchWithAuthPost } from "../Auth/dependency_post";
import { responseFormat } from "../Auth/errorResponse";

const slice = createSlice({
  name: "addParent",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
    successMessage: "",
    showSuccessMessage: false,
    errorMessage: "",
    showErrorMessage: false,
  },
  reducers: {
    fetchParentData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchParentSuccess: (state, action) => {
      state.isLoading = false;
      state.data = state.data
        ? [...state.data, ...action.payload]
        : action.payload;
    },

    // fetchParentSuccess: (state, action) => {
    //   state.isLoading = false;
    //   const newData = action.payload;

    //   // Check if newData is already present in state.data
    //   const isDataPresent = state.data && state.data.some(item => item.id === newData.id);

    //   // Add newData only if it's not already present
    //   state.data = isDataPresent ? state.data : [...state.data, newData];
    // },

    fetchParentFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
    setFormData: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  fetchParentData,
  fetchParentSuccess,
  fetchParentFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
  setFormData,
} = slice.actions;

export const postParentRequest = (postData, id) => async (dispatch) => {
  dispatch(fetchParentData());
  const tokenId = token();
  try {
    // Make your POST request here using the fetch function or an HTTP library like Axios
    const response = await fetchWithAuthPost(
      API_PATH + `/franchiseeSchool/${id}/guardian`,
      "POST",
      tokenId,
      postData
    );

    if (!response) {
      throw new Error("Request failed"); // You can customize error handling
    } else {
      const responseData = await response;
    
      dispatch(fetchParentSuccess([responseData.guardians]));

      dispatch(setSuccessMessage("Successful!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
   
    const errMessage = responseFormat({ error });
  
    dispatch(fetchParentFailure());
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
