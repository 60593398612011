import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewEmployeeEnquirySlice";


const ViewEmployeeEnquiry = () => {
  const columns = [
    { field: "srNo", header: "Sr.No" },
    { field: "date", header: "Date" },
    { field: "name", header: "Name" },
    { field: "contactNumber", header: "Contact Number" },
    { field: "email", header: "E mail" },
    { field: "dateOfBirth", header: "Date Of Birth" },
    { field: "qualificationId", header: "Qualification" },
    { field: "expectedSalary", header: "Expected Salary" },
    { field: "workExperience", header: "Experience" },
    { field: "previousEmployment", header: "Previous Company" },
    { field: "previousEmploymentJoiningDate", header: "Joining Date" },
    { field: "previousEmploymentLastDrawnSalary", header: "Last Drawn Salary" },
    { field: "previousEmploymentLeavingDate", header: "Last Working Day" },
    { field: "referenceContactName", header: "Reference" },
    { field: "referenceContactNumber", header: "Reference Number" },
    { field: "view", header: "Documents" },
    { field: "cvUrl", header: "Documents" },

  ];


  const apiData = useSelector((state) => state.viewEmployeeEnquiry.data) || [];
  const postData = useSelector((state) => state.employeeEnquiry.data)||[]
  const putData = useSelector((state) => state.employeeEnquiryFollowUp.data) || [];


  const combinedData = [...(apiData || []), ...postData];
  const processedData = combinedData.map((row, index) => ({
    ...row,
    srNo: index + 1,
  }));
 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataOnce());
  }, [dispatch]);

  const newUpdatedData = processedData.filter(obj => !putData.some(postObj => postObj.id === obj.id))

  return (
    <>
      <div className="content-header">View Employee Enquiry</div>
      {newUpdatedData === null ? ( 
        <center><p>Loading...</p></center>
      ) : newUpdatedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (

          <Table data={newUpdatedData} columns={columns} hover={true} striped={true} />
        )}
    </>
  );
}
export default ViewEmployeeEnquiry;