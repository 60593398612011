
import { Button, Steps, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { AddSchool } from "./addSchool";
import { RoyaltyAmount } from "./royaltyAmount";
import { AddClass } from "./addClass";
import { AddingFee } from "./addingFee";
import { CurrentPage } from "./currentPage";
import { useSelector } from "react-redux";


const steps = [
    {
        title: 'Add School',
        content: <AddSchool />,
    },
    // {
    //     title: 'Royalty Amount',
    //     content: <RoyaltyAmount />,
    // },
    {
        title: 'Add Classes',
        content: <AddClass />,
    },
    // {
    //     title: 'Fee',
    //     content: <AddingFee />,
    // },
];
export const SchoolCreation = () => {
    const { token } = theme.useToken();


    const apiData = useSelector((state) => state.addSchoolData.data);
    const apiData1 = useSelector((state) => state.addCurrentPage.data);

    const [current, setCurrent] = useState(0);
    useEffect(() => {
        setCurrent(apiData1)
    }, [apiData1])

   
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));



    return (
        <>
            <div className="content-header">School creation</div>
            <Steps current={current} items={items} />
            {steps[current].content}

        </>
    )
}