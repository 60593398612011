import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Tooltip, Modal } from 'antd';
import { API_PATH } from '../../Api/api';
import { franchiseeSchoolId, token } from '../../Api/token';
import { Pagination } from '@mui/material';
import './messagesTable.css';

const CustomTable = ({ data, columns }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Items per page can be adjusted as needed


    const showModal = (message) => {
        setModalContent(message);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <table className="custom-table">
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col?.header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex}>
                                    {col?.field === "message" ? (
                                        <Tooltip title={row[col.field]}>
                                            <span onClick={() => showModal(row[col.field])} style={{ cursor: 'pointer', color: 'blue' }}>
                                                {row[col.field].length > 30 ? row[col.field].substring(0, 30) + '...' : row[col.field]}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        row[col?.field]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                className="pagination-css"
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
            />
            <Modal title="Message" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>{modalContent}</p>
            </Modal>
        </>
    );
};

const ViewMessages = () => {

    const roleId = sessionStorage.getItem("roleId");
    const navigate = useNavigate();
    const auth_token = token();
    const [messages, setMessages] = useState([]);

    const navigateToStudentFee = () => {
        navigate('/sendMessage');
    };

    const columns = [
        { field: "sNo", header: "S.No" },
        { field: "date", header: "Date" },
        roleId !== "5" ? { field: "parent", header: "Parent" } : null,
        {
            field: "message", header: "Message"
        }
    ];

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth_token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const guardianId = sessionStorage.getItem("userId");
        if (roleId === "5") {
            fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId()}/message-center?guardianId=${guardianId}`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setMessages(result);
                })
                .catch((error) => console.error(error));
        } else {
            fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId()}/message-center`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setMessages(result);
                })
                .catch((error) => console.error(error));
        }
    }, []);

    const formattedData = messages?.map((msg, index) => ({
        sNo: index + 1,
        date: new Intl.DateTimeFormat('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'Asia/Kolkata'
        }).format(new Date(msg.date)),
        parent: msg.guardian,
        message: msg.message
    }));

    return (
        <div>
            <div className="content-header">{roleId !== "5" ? "Sent Messages" : "View Messages"}</div>
            {roleId !== "5" ? (
                <button
                    onClick={navigateToStudentFee}
                    className="save-button-figma-div"
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        marginRight: "20px",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "206px",
                    }}>
                    <a>Send&nbsp;Message</a>
                </button>
            ) : (
                null
            )}
            {formattedData.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}><p>No records available</p></center>
            ) : (
                <CustomTable data={formattedData} columns={columns} />
            )}
        </div>
    );
};

export default ViewMessages;
