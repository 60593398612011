// author:Rajeev;
// date:10/10/2023;
// version:1.0

import React, { useState, useEffect } from "react";

import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import TextInputField from "../reUsableForm/textInputField";
import {
  validationRegexOnlyPincode,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  validationRegexOnlyAlpha1,
  validationRegexClass,
  validationRegexAddress,
} from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  postStudentRequest,
  setShowSuccessMessage,
  setShowErrorMessage,
} from "./addStudentSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import { fetchParentDataOnce } from "../parent/viewParentSlicer";
import { fetchDataOnceProgram } from "../programs/slice/viewProgramSlice";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import { fetchTimingDataOnce } from "../TimeTableDetails/slicer/viewTimingSlicer";
import { fetchFrequenciesView } from "../EnquiryDetails/slice/enquiryFrequencySlicer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import ReadOnlyTextField from "../../ReusableFields/TextFields/ReadOnlyTextField";
import "../reUsableForm/textInputField.css";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { fetchDataGetProgramBySchool } from "../programs/slice/getProgramBySchoolSlice";
import { deleteFile, deleteFiles, uploadFiles } from "../reUsableForm/uploadImage-docs";
import FormatDate from "../reUsableForm/dateFormat";
const AddStudent = () => {
  const [validated, setValidated] = useState(false);
  const [classOptions, setClassOptions] = useState([]);
  const [showErrorMsgs, setShowErrorMsgs] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState("");
  const [daycare, setDaycare] = useState("false");
  const [previousSchool, setPreviousSchool] = useState("false");
  const [bonafideCertificate, setBonafideCertificate] = useState("false");
  const [transferCertificates, setTransferCertificates] = useState("false");
  const [reportCards, setReportCards] = useState("false");
  const [gurdain, setGurdain] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [filteredStudentNames, setFilteredStudentNames] = useState([]);
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [submit, setSubmit] = useState(false);

  const [selectParent, setSelectParent] = useState("");
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    religion: false,
    program: false,
    class: false,
    area: false,
    state: false,
    city: false,
    area1: false,
    frequency: false,
    timings: false,
    lastName: false,
    language: false,
    address: false,
    pincode: false,
    address1: false,
    pincode1: false,
    schoolName: false,
    classStudied: false,
    selectedImage: false,
    selectedDocuments: false,
    dateOfBirth: false,
    bonafideCertified: false,
    transferCertificate: false,
    reportCard: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const defaultName = queryParams.get("studentName") || "";
  const defaultAddress = queryParams.get("address") || "";
  const defaultDateOfBirth = queryParams.get("dateOfBirth") || "";
  const defaultStudentId = queryParams.get("studentEnquiryId") || "";
  // const defaultProgramId = queryParams.get("program") || "";
  // const defaultClassId = queryParams.get("class") || "";
  const formattedDateOfBirth = defaultDateOfBirth
    ? new Date(defaultDateOfBirth)
    : null;

  const [formData, setFormData] = useState({
    studentName: defaultName,
    dateOfBirth: formattedDateOfBirth,
    studentEnquiryId: defaultStudentId,
    gender: "Male",
  });
  const [currentAreaId, setCurrentAreaId] = useState({});
  const [permanentAreaId, setPermanentAreaId] = useState({});
  const [permanentArea, setPermanentArea] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const selectedFranchiseeSchoolId = Number(franchiseeSchoolId);
  const [currentArea, setCurrentArea] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [bonafideCertified, setBonafideCertified] = useState([]);
  const [transferCertificate, setTransferCertificate] = useState([]);
  const [reportCard, setReportCard] = useState([]);
  const [dropDowns, setDropDowns] = useState({
    // program: defaultProgramId,
    // class: defaultClassId,
  });

  const [defaultMother, setDefaultMother] = useState('');

  const [defaultFather, setDefaultFather] = useState('');

  const [defaultGuardian, setDefaultGuardian] = useState('');

  const [currentAddressErrors, setCurrentAddressErrors] = useState({
    area: false,
    state: false,
    city: false,
    pincode: false,
    address: false,
  });

  const resetParentStates = () => {
    setDefaultMother({ value: "", label: "" });
    setDefaultFather({ value: "", label: "" });
    setDefaultGuardian({ value: "", label: "" });

    setFather([]);
    setMother([]);
    setGuardians([]);
  };
  const fetchParentDetails = (parentId, parentType) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${token()}` },
    };
    resetParentStates();

    fetch(
      API_PATH + `/guardian-relationship?${parentType}Id=${parentId}`,
      requestOptions
    )
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        return data;
      })
      .then((data) => {
        // Update parent state variable with fetched data
        switch (parentType) {
          case "father":
            setFather({
              ...father,
              fatherNameId: parentId,
              primaryContactNumber: data.father.primaryContactNumber,
              currentAddress: data.father.currentAddress.address,
              label: data.father.name,
              value: parentId,
            });
            setFather((updatedFather) => {
              setDefaultFather({
                value: updatedFather?.fatherNameId || '', // or any unique identifier for the mother
                label: updatedFather?.label || '',
              });
              if (Array.isArray(gurdain) && updatedFather?.fatherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedFather?.fatherNameId || '']);
              }
              return updatedFather;
            });

            setMother({
              ...mother,
              motherNameId: data?.mother?.id || '',
              name: data?.mother?.name || '',
              primaryContactNumber: data?.mother?.primaryContactNumber || '',
              currentAddress: data?.mother?.currentAddress?.address || '',
              label: data?.mother?.name || '',
              value: data?.mother?.id || '',
            });
            setMother((updatedMother) => {
              setDefaultMother({
                value: updatedMother?.motherNameId || '', // or any unique identifier for the mother
                label: updatedMother?.name || '',
              });
              if (Array.isArray(gurdain) && updatedMother?.motherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedMother?.motherNameId || '']);
              }
              return updatedMother;
            });
            setGuardians({
              ...guardians,
              guardianId: data?.guardian?.id || null,
              name: data?.guardian?.name || '',
              primaryContactNumber: data?.guardian?.primaryContactNumber || '',
              currentAddress: data?.guardian?.currentAddress?.address || '',
              label: data?.guardian?.name || '',
              value: data?.guardian?.id || '',
            });
            setGuardians((updated2) => {
              setDefaultGuardian({
                value: updated2?.guardianId || '', // or any unique identifier for the mother
                label: updated2?.name || '',
              });
              if (Array.isArray(gurdain) && updated2?.guardianId) {
                setGurdain(prevGurdain => [...prevGurdain, updated2?.guardianId || '']);
              }
              return updated2;
            });

            break;

          case "mother":
            setFather({
              ...father,
              fatherNameId: data?.father?.id || null,
              name: data.father?.name || '',
              primaryContactNumber: data.father?.primaryContactNumber || '',
              currentAddress: data.father?.currentAddress?.address || '',
              label: data.father?.name || '',
              value: data.father?.id || '',
            });
            setFather((updatedFather) => {
              setDefaultFather({
                value: updatedFather?.fatherNameId || '', // or any unique identifier for the mother
                label: updatedFather?.name || '',
              });
              if (Array.isArray(gurdain) && updatedFather?.fatherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedFather?.fatherNameId || '']);
              }
              return updatedFather;
            });
            setMother({
              ...mother,
              motherNameId: parentId,
              // name: data.mother.name,
              primaryContactNumber: data.mother?.primaryContactNumber || '',
              currentAddress: data.mother?.currentAddress?.address || '',
              label: data.mother?.name || '',
              value: parentId,
            });
            setMother((updatedMother) => {
              setDefaultMother({
                value: updatedMother?.motherNameId || '', // or any unique identifier for the mother
                label: updatedMother?.label || '',
              });
              if (Array.isArray(gurdain) && updatedMother?.motherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedMother?.motherNameId || '']);
              }
              return updatedMother;
            });
            setGuardians({
              ...guardians,
              guardianId: data.guardian?.id || null,
              name: data.guardian?.name || '',
              primaryContactNumber: data.guardian?.primaryContactNumber || '',
              currentAddress: data.guardian?.currentAddress?.address || '',
              label: data.guardian?.name || '',
              value: data.guardian?.id || '',
            });
            setGuardians((updated3) => {
              setDefaultGuardian({
                value: updated3?.guardianId || '', // or any unique identifier for the mother
                label: updated3?.name || '',
              });
              if (Array.isArray(gurdain) && updated3?.guardianId) {
                setGurdain(prevGurdain => [...prevGurdain, updated3?.guardianId || '']);
              }
              return updated3;
            });
            break;
          case "guardian":
            setFather({
              ...father,
              name: data.father?.name || '',
              fatherNameId: data.father?.id || null,
              primaryContactNumber: data.father?.primaryContactNumber || '',
              currentAddress: data.father?.currentAddress?.address || '',
              label: data.father?.name || '',
              value: data.father?.id || '',
            });
            setFather((updatedFather) => {
              setDefaultFather({
                value: updatedFather?.fatherNameId || '', // or any unique identifier for the mother
                label: updatedFather?.name || '',
              });
              if (Array.isArray(gurdain) && updatedFather?.fatherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedFather?.fatherNameId || '']);
              }
              return updatedFather;
            });
            setMother({
              ...mother,
              motherNameId: data.mother?.id || null,
              name: data.mother?.name || '',
              primaryContactNumber: data.mother?.primaryContactNumber || '',
              currentAddress: data.mother?.currentAddress?.address || '',
              label: data.mother?.name || '',
              value: data.mother?.id || '',
            });
            setMother((updatedMother) => {
              setDefaultMother({
                value: updatedMother?.motherNameId || '', // or any unique identifier for the mother
                label: updatedMother?.name || '',
              });
              if (Array.isArray(gurdain) && updatedMother?.motherNameId) {
                setGurdain(prevGurdain => [...prevGurdain, updatedMother?.motherNameId || '']);
              }
              return updatedMother;
            });
            setGuardians({
              ...guardians,
              guardianId: parentId,
              primaryContactNumber: data.guardian?.primaryContactNumber || '',
              currentAddress: data.guardian?.currentAddress?.address || '',
              label: data.guardian?.name || '',
              value: parentId,
            });
            setGuardians((updated3) => {
              setDefaultGuardian({
                value: updated3?.guardianId || '', // or any unique identifier for the mother
                label: updated3?.label || '',
              });
              if (Array.isArray(gurdain) && updated3?.guardianId) {
                setGurdain(prevGurdain => [...prevGurdain, updated3?.guardianId || '']);
              }
              return updated3;
            });

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.error("Error fetching parent details:", error);
        // Handle error gracefully, show error message to user, etc.
      });
  };



  const [permanentAddress, setPermanentAddress] = useState({
    address: defaultAddress,
    type: "permanent",
  });
  const [currentAddress, setCurrentAddress] = useState({
    address: defaultAddress,
    type: "current",
  });
  const [father, setFather] = useState([]);
  const [mother, setMother] = useState([]);
  const [guardians, setGuardians] = useState([]);
  const [image, setImage] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    setDocuments(files);
    setFormErrors({ ...formErrors, selectedDocuments: false });
  };

  const handleUpload1 = async () => {
    try {
      if (!documents) {
        console.error("No images to upload");
        return;
      }

      const formData = new FormData();

      for (let i = 0; i < documents.length; i++) {
        formData.append("files", documents[i]);
      }

      const response = await fetch(`${API_PATH}/uploadFiles`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`, // Assuming token() retrieves the token
        },
      });

      if (response.ok) {
        const result = await response.json();
        const fileIds = result.files.map((id) => id.fileId);
        return fileIds;
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [bonafide, setBonafide] = useState(null);

  const handleBonafideDocumentChange = async (event) => {
    const files = event.target.files;

    setBonafide(files);
    setFormErrors({ ...formErrors, bonafideCertified: false });
  };

  const uploadBonafide = async () => {
    try {
      if (!bonafide) {
        console.error("No files to upload");
        return;
      }

      const formData = new FormData();
      formData.append("file", bonafide[0]);

      const response = await fetch(`${API_PATH}/uploadFile`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.ok) {
        const result = await response.json();

        setFormData((prevData) => ({
          ...prevData,
          bonafideCertifiedUrl: result.fileId.toString(),
        }));
        setBonafideCertified(result.fileId.toString());
        return result.fileId;
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [transferDoc, setTransferDoc] = useState(null);

  const handleTransferCertificateChange = async (event) => {
    const files = event.target.files;
    setTransferDoc(files);
    setFormErrors({ ...formErrors, transferCertificate: false });
  };
  const uploadTransDoc = async () => {
    try {
      if (!transferDoc) {
        console.error("No files to upload");
        return;
      }

      const formData = new FormData();
      formData.append("file", transferDoc[0]);

      const response = await fetch(`${API_PATH}/uploadFile`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.ok) {
        const result = await response.json();

        setFormData((prevData) => ({
          ...prevData,
          transferCertificate: result.fileId.toString(),
        }));
        setTransferCertificate(result.fileId.toString());
        return result.fileId;
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [reportDoc, setReportDoc] = useState(null);

  const handleReportCardChange = async (event) => {
    const files = event.target.files;
    setReportDoc(files);
    setFormErrors({ ...formErrors, reportCard: false });
  };
  const uploadReportDoc = async () => {
    try {
      if (!reportDoc) {
        console.error("No files to upload");
        return;
      }
      const formData = new FormData();
      formData.append("file", reportDoc[0]);
      const response = await fetch(`${API_PATH}/uploadFile`, {
        // signal,
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setFormData((prevData) => ({
          ...prevData,
          reportCard: result.fileId.toString(),
        }));
        setReportDoc(result.fileId.toString());
        return result.fileId;
      } else {
        console.error("Error uploading files:");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const getAge = (dob) => {
    const today = new Date();

    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const Age = getAge(formData.dateOfBirth);
  useEffect(() => {
    if (currentAreaId) {
      setCurrentAddress({
        ...currentAddress,
        cityId: currentAreaId.city,
        stateId: currentAreaId.state,
      });
    }
  }, [currentAreaId]);
  useEffect(() => {
    if (permanentAreaId) {
      setPermanentAddress({
        ...permanentAddress,
        cityId: permanentAreaId.city,
        stateId: permanentAreaId.state,
      });
    }
  }, [permanentAreaId]);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Note: Months are zero-based, so add 1
  const day = currentDate.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const apiData = useSelector((state) => state.areaDetails.data) || [];
  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
  const guardianData = useSelector((state) => state.viewParent.data) || [];
  const ProgramData = useSelector((state) => state.getProgramBySchool.data) || [];
  const frequencyData = useSelector((state) => state.frequencies.data) || [];
  const timingsData = useSelector((state) => state.viewTimings.data) || [];

  const dispatch = useDispatch();
  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [apiData, dispatch]);

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [classesClassSectionData, dispatch]);

  useEffect(() => {
    if (guardianData.length === 0) {
      dispatch(fetchParentDataOnce());
    }
  }, [guardianData, dispatch]);
  useEffect(() => {
    if (ProgramData.length === 0) {
      dispatch(fetchDataGetProgramBySchool(selectedFranchiseeSchoolId));
    }
  }, [dispatch, ProgramData, selectedFranchiseeSchoolId]);

  useEffect(() => {
    if (timingsData.length === 0) {
      dispatch(fetchTimingDataOnce());
    }
  }, [timingsData, dispatch]);

  useEffect(() => {
    if (frequencyData.length === 0) {
      dispatch(fetchFrequenciesView());
    }
  }, [frequencyData, dispatch]);
  useEffect(() => {
    const filteredClasses = classesClassSectionData.filter(
      (classItem) => classItem.programId === formData.programId
    );
    const newClassOptions = filteredClasses.map((classItem) => ({
      value: classItem.id,
      label: classItem.name,
    }));

    setClassOptions(newClassOptions);
  }, [formData.programId]);

  const showSuccessMsg = useSelector(
    (state) => state.addStudent.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.addStudent.successMessage);

  const showErrorMsg = useSelector(
    (state) => state.addStudent.showErrorMessage
  );
  const errorMsg = useSelector((state) => state.addStudent.errorMessage);

  const fatherDatas = guardianData
    .filter((item) => item.relation !== "father" && item.relation !== "mother")
    .map((item) => ({
      name: item.name,
      relation: item.relation,
    }));

  const handleUploadImage = (event) => {
    const file = event.target.files;
    setImage(file);
    setFormErrors({ ...formErrors, selectedImage: false });
  };
  const handleUpload = async () => {
    try {
      if (!image) {
        console.error("No images to upload");
        return;
      }

      const formData = new FormData();
      formData.append("file", image[0]);

      const response = await fetch(`${API_PATH}/uploadFile`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setFormData((prevData) => ({
          ...prevData,
          profilePictureUrl: result.fileId.toString(),
        }));
        setSelectedImage(result.fileId.toString());
        return result.fileId;
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);
    setFilteredStudentNames([]);
    setFormData({ ...formData, studentNameId: "" });
    setDropDowns({ ...dropDowns, studentName: "" });
    setFormErrors({ ...formErrors, studentName: false });
    setShowTable(false);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
  }, [selectedClass]);
  const getBorderColor = () => {
    if (documents && documents.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const getBorderColorImages = () => {
    if (image && image.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const getBorderColor1 = () => {
    if (bonafide && bonafide.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const getBorderColor2 = () => {
    if (transferDoc && transferDoc.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const getBorderColor3 = () => {
    if (reportDoc && reportDoc.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();


    if (father.length <= 0 && mother.length <= 0 && gurdain.length <= 0) {
      setShowErrorMsgs(true);
      setErrorMsgs("select at least one parent");
    }

    const newFormErrors = {
      religion: !formData.religion,
      program: !dropDowns.program,
      class: !dropDowns.class,
      area: !dropDowns.area,
      area1: !dropDowns.area1,
      studentName: !formData.studentName,
      language: !formData.language,
      address: !permanentAddress.address,
      pincode: !permanentAddress.pincode,
      schoolName: previousSchool === true ? !formData.schoolName : false,
      classStudied: previousSchool === true ? !formData.classStudied : false,
      selectedImage: image?.length === 0,
      selectedDocuments: documents?.length === 0,
      dateOfBirth: !formData.dateOfBirth,
      bonafideCertified: previousSchool === "true" ? !bonafide : false,
      transferCertificate: previousSchool === "true" ? !transferDoc : false,
      reportCard: previousSchool === "true" ? !reportDoc : false,
      timings: daycare === "true" ? !dropDowns.timings : false,
      frequency: daycare === "true" ? !dropDowns.frequency : false,
    };

    setFormErrors(newFormErrors);
    const updateAddress = {
      address: !currentAddress.address,
      pincode: !currentAddress.pincode,
    };
    setCurrentAddressErrors(updateAddress);

    const formErrorsValues = Object.values(newFormErrors);
    const addressErrorsValues = Object.values(updateAddress);
    const hasErrors = formErrorsValues
      .concat(addressErrorsValues)
      .some((error) => error);
    setFormErrors(newFormErrors);
    setCurrentAddressErrors(updateAddress);
    if (hasErrors) {
      setValidated(true);
      return;
    }


    if (image) {
      // var file_id = await handleUpload();
      const uploadResponses1 = await uploadFiles((image), "student/profile-images");
      var file_id = uploadResponses1.map((response) => response.Location) || null;

    }

    if (documents.length > 0) {
      // var file_ids = await handleUpload1();
      const uploadResponses1 = await uploadFiles((documents), "student/documents");
      var file_ids = uploadResponses1.map((response) => response.Location) || null;

    }

    if (bonafide) {
      // var bonafideDoc = await uploadBonafide();
      const uploadResponses1 = await uploadFiles((bonafide), "guardians/documents");
      var bonafideDoc = uploadResponses1.map((response) => response.Location) || null;
    }
    if (transferDoc) {
      // var tranferDoc = await uploadTransDoc();
      const uploadResponses1 = await uploadFiles((transferDoc), "guardians/documents");
      var tranferDoc = uploadResponses1.map((response) => response.Location) || null;
    }
    if (reportDoc) {

      // var reportDocs = await uploadReportDoc();

      const uploadResponses1 = await uploadFiles((reportDoc), "guardians/documents");
      var reportDocs = uploadResponses1.map((response) => response.Location) || null;
    }
    setGurdain();

    setSubmit(true);


    var guardiansId = [];

    // Check if defaultFather.value exists and add it to the array if present
    if (defaultFather.value) {
      guardiansId.push(defaultFather.value);
    }

    // Check if defaultMother.value exists and add it to the array if present
    if (defaultMother.value) {
      guardiansId.push(defaultMother.value);
    }

    // Check if defaultGuardian.value exists and add it to the array if present
    if (defaultGuardian.value) {
      guardiansId.push(defaultGuardian.value);
    }

    const sendData = {
      franchiseeSchoolId: selectedFranchiseeSchoolId,
      name: formData.studentName,
      gender: formData.gender,
      profilePictureUrl: file_id[0],
      documentUrls: file_ids,
      dateOfBirth: FormatDate(formData.dateOfBirth),
      studentEnquiryId: formData.studentEnquiryId,
      languagesKnown: formData.language,
      religion: formData.religion,
      age: getAge(formData.dateOfBirth),

      enrollment: {
        date: formattedDate,
        programId: formData.programId,
        classesClassSectionId: selectedSection,
      },

      currentAddress: currentAddress,
      permanentAddress: permanentAddress,

      guardiansId: guardiansId,
    };


    if (formData.frequencyId && formData.TimingId) {
      Object.assign(sendData.enrollment, {
        daycare: {
          dayFrequencyId: formData.frequencyId,
          timingId: formData.TimingId,
        },
      });
    }

    if (
      formData.schoolName &&
      formData.classStudied &&
      bonafideDoc &&
      tranferDoc &&
      reportDocs
    ) {

      if (!sendData.previousSchool) {
        sendData.previousSchool = {};
      }


      // Check if the conditions are met for adding previousSchool data
      if (
        formData.schoolName &&
        formData.classStudied &&
        bonafideDoc &&
        tranferDoc &&
        reportDocs
      ) {
        // Adding previousSchool data
        Object.assign(sendData.previousSchool, {
          previousSchoolName: formData.schoolName || "",
          previousClassStudied: formData.classStudied || "",
          previousBonafideCertifiedUrl: bonafideDoc
            ? bonafideDoc.toString()
            : "",
          previousTransferCertificateUrl: tranferDoc
            ? tranferDoc.toString()
            : "",
          previousReportCardUrl: reportDocs ? reportDocs.toString() : "",
        });
      }
    }


    // Check if the conditions are met for adding previousSchool data
    const response = dispatch(postStudentRequest(sendData));

    if (response) {
      setSubmit(false);
    } else {
      setSubmit(false);
      dispatch(fetchAreaData())
      dispatch(fetchDataGetProgramBySchool(selectedFranchiseeSchoolId));
    }

  };

  const handleCancel = (e) => {
    setCurrentAddress({});
    setSelectedImage(null);
    setSelectedDocuments([]);
    setBonafideCertificate("false");
    setTransferCertificates("false");
    setReportCards("false");
    setPermanentAddress({});
    setFormErrors("");
    setCurrentAddressErrors("");
    setDropDowns({ class: "" });
    // setFather({});
    setMother({});
    setDocuments([]);
    setFather({});
    setBonafide(null);
    setTransferDoc(null);
    setReportDoc(null);
    setGurdain([]);

    setFormData({
      dateOfBirth: "",
    });
    setValidated(false);
    setSubmit(false);
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    navigate("/viewStudent");
    window.location.reload();
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };


  const navigateToAnotherScreen = () => {
    navigate("/AddArea");
  };

  return (
    <div style={{ height: "100vh" }}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Add Students</div>
        <Row>
          <Col xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDownField
              isSearchable
              options={guardianData
                .filter((item) => item.relation === "father")
                .map((option, index) => ({
                  id: option.id,
                  value: option.primaryContactNumber || option.name,
                  label: option.name,
                  CurrentAddress: option.currentAddress.address,
                }))}

              onChange={(selectedOption) => {


                setSelectParent(selectedOption.id);

                fetchParentDetails(selectedOption.id, "father");
              }}
              placeholder="Father Name"
              value={defaultFather}
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Mobile Number"
              placeholder="Mobile Number*"
              textType="Alphabet"
              value={father.primaryContactNumber}
              disabled
              feedbackMessage="Please enter Mobile number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid mobile number."
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Address"
              placeholder="Address*"
              textType="Alphabet"
              disabled
              value={father.currentAddress}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDownField
              options={guardianData
                .filter((item) => item.relation === "mother")
                .map((option, index) => ({
                  id: option.id,
                  value: option.primaryContactNumber || option.name,
                  label: option.name,
                  CurrentAddress: option.currentAddress.address,
                }))}
              onChange={(selectedOption) => {
                setGurdain([...gurdain, selectedOption.id]);

                fetchParentDetails(selectedOption.id, "mother");
              }}

              value={defaultMother}
              placeholder="Mother Name"
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Mobile Number"
              placeholder="Mobile Number*"
              disabled
              textType="Alphabet"
              value={mother.primaryContactNumber}
              feedbackMessage="Please enter Mobile number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid mobile number."
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Address"
              placeholder="Address*"
              disabled
              textType="Alphabet"
              value={mother.currentAddress}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <SearchableDropDownField
              isSearchable
              options={guardianData
                .filter(
                  (item) =>
                    item.relation !== "father" && item.relation !== "mother"
                )
                .map((option, index) => ({
                  id: option?.id || null,
                  value: option.primaryContactNumber || option?.name || '',
                  label: option?.name || '',
                  CurrentAddress: option?.currentAddress?.address || '',
                }))}
              // value={gurdain.name}
              onChange={(selectedOption) => {
                setGurdain([...gurdain, selectedOption.id]);
                fetchParentDetails(selectedOption.id, "guardian");

              }}
              placeholder="Gurdain Name"

              value={defaultGuardian}
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Mobile Number"
              placeholder="Mobile Number*"
              disabled
              textType="Alphabet"
              value={guardians.primaryContactNumber}
              feedbackMessage="Please enter Mobile number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid mobile number."
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="rowCss">
            <TextInputField
              name="Address"
              placeholder="Address*"
              disabled
              textType="Alphabet"
              value={guardians.currentAddress}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid current address."
            />
          </Col>
          {/* ------------------------------firstName--------------------------------------------------- */}
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="First Name"
              placeholder="Name"
              textType="Alphabet"
              value={formData.studentName}
              required
              onChange={(event) => {
                setFormData({ ...formData, studentName: event.target.value });
                setFormErrors({ ...formErrors, studentName: false });
              }}
              validated={validated}
              feedbackMessage="Please enter first name."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter valid first name."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Male"
                  type="radio"
                  name="gender"
                  value="Male"
                  className="malebutton"
                  checked={formData.gender === "Male"}
                  onChange={(event) =>
                    setFormData({ ...formData, gender: event.target.value })
                  }
                />
                <Form.Check
                  inline
                  label="Female"
                  type="radio"
                  name="gender"
                  value="Female"
                  className="malebutton"
                  checked={formData.gender === "Female"}
                  onChange={(event) =>
                    setFormData({ ...formData, gender: event.target.value })
                  }
                />
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"
                style={{
                  border: `1px solid ${getBorderColorImages()}`, // Dynamic border color
                }}
              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Images</div>
                </div>
                <input
                  type="file"
                  accept=".jpg, .jpeg'"
                  onChange={handleUploadImage}
                  style={{
                    width: "100%",
                    // height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                <p>{image?.[0].name}</p>
              </div>
            </Form.Group>

            {formErrors.selectedImage && (
              <div className="error-message">Please Select Image .</div>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPast
              label="DOB*"
              value={formData.dateOfBirth || ""}
              onChange={(name, date, selectedDate) => {
                setFormData({ ...formData, dateOfBirth: date });
                setFormErrors({ ...formErrors, dateOfBirth: !date });
              }}
              required
              name="DOB"
              placeholder="Select Date of Birth*"
              dateFormat="dd-MM-yyyy"
              maxDate={currentDate}
              isValidDate={(date) => true}
              feedbackMessage="Please select date of birth."
            />
            {formErrors.dateOfBirth && (
              <div className="error-message">
                Please select a date of birth.
              </div>
            )}
          </Col>
          <Col xs={12} md={6} lg={3} className="rowCss">
            <ReadOnlyTextField
              name="Student Age"
              textType=""
              value={getAge(formData.dateOfBirth) || ""}
              required
              placeholder="Age"
              onChange={(e) => {
                setFormData({ ...formData, age: getAge(formData.dateOfBirth) });
              }}
              validated={validated}
              validationRegex={validationRegexMobileNo}
              maxLength={25}
            />
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"
                style={{
                  border: `1px solid ${getBorderColor()}`, // Dynamic border color
                }}
              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Docs</div>
                </div>
                <input
                  type="file"
                  accept=".pdf,.jpg"
                  multiple
                  onChange={handleDocumentChange}
                  style={{
                    width: "100%",
                    // height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                {fileNamesArray?.map((name, index) => {
                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>
            {formErrors.selectedDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Language"
              placeholder="Language*"
              textType="Alphabet"
              required
              value={formData.language}
              onChange={(event) => {
                setFormData({ ...formData, language: event.target.value });
              }}
              validated={validated}
              feedbackMessage="Please enter language."
              validationRegex={validationRegexOnlyAlpha1}
              validationErrorMessage="Please enter a valid language."
            />
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">


            <TextInputField
              name="Religion"
              placeholder="Religion*"
              textType="Alphabet"
              required
              value={formData.religion}
              onChange={(event) => {
                setFormData({ ...formData, religion: event.target.value });
              }}
              validated={validated}
              feedbackMessage="Please enter religion."
              validationRegex={validationRegexOnlyAlpha1}
              validationErrorMessage="Please enter a valid religion."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Permanent Address"
              placeholder="Permanent Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.address}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter permanent address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid permanent address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area
                  ? { value: dropDowns.area, label: dropDowns.area }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setPermanentAddress({
                  ...permanentAddress,
                  areaId: selectedOption.id,
                });
                setPermanentArea({
                  ...permanentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setPermanentAreaId({
                  ...permanentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });

                setDropDowns({ ...dropDowns, area: selectedOption.value });
                setFormErrors({ ...formErrors, area: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={permanentArea?.city?.name}
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={permanentArea?.state?.name}
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.pincode || ""}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Current Address"
              placeholder="Current Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.address}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area1
                  ? { value: dropDowns.area1, label: dropDowns.area1 }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setCurrentAddress({
                  ...currentAddress,
                  areaId: selectedOption.id,
                });
                setCurrentArea({
                  ...currentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setCurrentAreaId({
                  ...currentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });
                setDropDowns({ ...dropDowns, area1: selectedOption.value });
                setFormErrors({ ...formErrors, area1: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area1 && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={currentArea?.city?.name}
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={currentArea?.state?.name}
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.pincode || ""}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.program
                  ? { value: dropDowns.program, label: dropDowns.program }
                  : null
              }
              options={ProgramData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
              }))}
              onChange={(selectedOption) => {
                setSelectedProgram(selectedOption); // Update selected program

                setFormData({ ...formData, programId: selectedOption.id });
                setDropDowns({ ...dropDowns, program: selectedOption.value });
                setFormErrors({ ...formErrors, program: false });
              }}
              placeholder="Select Program"
            />
            {formErrors.program && (
              <div className="error-message">Please Select program .</div>
            )}
          </Col>

          <Col lg={3} xl={3} md={6} sm={6} xs={12} className="rowCss">
            <SearchableDropDownField
              label="Class"
              // value={formData.classId || ""}
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : dropDowns.class
              }
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              options={classOptions}
              required
              feedbackMessage="Please select a valid class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {formData.classId &&
            selectedClassData &&
            selectedClassData.classSections.length > 0 && (
              <Col lg={3} xl={3} md={6} sm={6} xs={12} className="rowCss">
                <SearchableDropDownField
                  label="Section"
                  value={
                    dropDowns.sections
                      ? { value: dropDowns.sections, label: dropDowns.sections }
                      : dropDowns.sections
                  }
                  placeholder="Select section"
                  onChange={(selectedOption) => {
                    handleChange("sectionId", selectedOption);
                    setSelectedSection(selectedOption.value);
                    setDropDowns({
                      ...dropDowns,
                      sections: selectedOption.label,
                    });
                    setFormErrors({ ...formErrors, sections: false });
                  }}
                  options={selectedClassData.classSections.map((section) => ({
                    key: section.id,
                    value: section.classesclassSection,
                    label: section.name,
                  }))}
                  required
                  feedbackMessage="Please select a valid section"
                />
                {formErrors.sections && (
                  <div className="error-message">Please Select Section.</div>
                )}
              </Col>
            )}

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Day Care*</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  name="daycare_yes"
                  value="true"
                  className="malebutton"
                  checked={daycare === "true"}
                  onChange={(event) => setDaycare(event.target.value)}
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  name="daycare_no"
                  value="false"
                  className="malebutton"
                  checked={daycare === "false"}
                  onChange={(event) => setDaycare(event.target.value)}
                />
              </div>
            </Form.Group>
          </Col>
          {daycare === "true" ? (
            <>
              <Col xs={12} md={6} lg={2} className="rowCss">
                <SearchableDropDownField
                  isSearchable
                  value={
                    dropDowns.frequency
                      ? {
                        value: dropDowns.frequency,
                        label: dropDowns.frequency,
                      }
                      : null
                  }
                  options={frequencyData.map((option, index) => ({
                    id: option.id,
                    value: option.name,
                    label: option.name,
                  }))}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      frequencyId: selectedOption.id,
                    });
                    setFormErrors({ ...formErrors, frequency: false });
                    setDropDowns({
                      ...dropDowns,
                      frequency: selectedOption.value,
                    });
                  }}
                  placeholder="Select Frequency"
                />

                {formErrors.frequency && (
                  <div className="error-message">Please Select frequency .</div>
                )}
              </Col>
              <Col xs={12} md={6} lg={2} className="rowCss">
                <SearchableDropDownField
                  isSearchable
                  value={
                    dropDowns.timings
                      ? { value: dropDowns.timings, label: dropDowns.timings }
                      : null
                  }
                  options={timingsData.map((option, index) => ({
                    id: option.id,
                    value: option.title,
                    label: option.title,
                  }))}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, TimingId: selectedOption.id });
                    setFormErrors({ ...formErrors, timings: false });
                    setDropDowns({
                      ...dropDowns,
                      timings: selectedOption.value,
                    });
                  }}
                  placeholder="Select Timings"
                />
                {formErrors.frequency && (
                  <div className="error-message">Please Select timings .</div>
                )}
              </Col>
            </>
          ) : (
            <Col>{/* false */}</Col>
          )}
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={12} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Previous School*</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  name="previoyusSchool_yes"
                  value="true"
                  className="malebutton"
                  checked={previousSchool === "true"}
                  onChange={(event) => setPreviousSchool(event.target.value)}
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  name="previousSchool_no"
                  value="false"
                  className="malebutton"
                  checked={previousSchool === "false"}
                  onChange={(event) => setPreviousSchool(event.target.value)}
                />
              </div>
            </Form.Group>
          </Col>
          {previousSchool === "true" ? (
            <>
              <Col xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="SchoolName"
                  placeholder="School Name"
                  className="fcontrol"
                  textType="Alphabet"
                  required
                  validated={validated}
                  value={formData.schoolName}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      schoolName: event.target.value,
                    });
                  }}
                  feedbackMessage="Please enter school name."
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Please enter valid school name."
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="ClassStudied"
                  placeholder="Class Studied"
                  className="fcontrol"
                  textType="Alphabet"
                  required
                  validated={validated}
                  value={formData.classStudied}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      classStudied: event.target.value,
                    });
                  }}
                  feedbackMessage="Please enter class studie."
                  validationRegex={validationRegexClass}
                  validationErrorMessage="Please enter valid class studie."
                />
              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <Form.Label className="lableName">
                    Bonafide Certificate{" "}
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      name="BonafideCertificate _yes"
                      value="true"
                      className="malebutton"
                      checked={bonafideCertificate === "true"}
                      onChange={(event) =>
                        setBonafideCertificate(event.target.value)
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      name="BonafideCertificate _no"
                      value="false"
                      className="malebutton"
                      checked={bonafideCertificate === "false"}
                      onChange={(event) =>
                        setBonafideCertificate(event.target.value)
                      }
                    />
                  </div>
                </Form.Group>
              </Col>
              {bonafideCertificate === "true" ? (
                <>
                  <Col xs={12} md={6} lg={3} className="rowCss">
                    <Form.Group>
                      <div
                        className="uploadDoc"
                        style={{
                          border: `1px solid ${getBorderColor1()}`, // Dynamic border color
                        }}
                      >
                        <div className="layout1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                              fill="#636363"
                            />
                          </svg>
                          <div className="title1">Upload&nbsp;Docu</div>
                        </div>
                        <input
                          type="file"
                          accept=".pdf,.jpg"
                          onChange={handleBonafideDocumentChange}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 1,
                            top: 0,
                            left: 0,
                          }}
                        />
                      </div>
                      <div>
                        <p>{bonafide?.[0].name}</p>
                      </div>
                    </Form.Group>
                    {formErrors.bonafideCertified && (
                      <div className="error-message">
                        Please Select bonafideCertificate .
                      </div>
                    )}
                  </Col>
                </>
              ) : (
                <Col>{/* false */}</Col>
              )}

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <Form.Label className="lableName">
                    Transfer Certificate{" "}
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      name="Transfer Certificate  _yes"
                      value="true"
                      className="malebutton"
                      checked={transferCertificates === "true"}
                      onChange={(event) =>
                        setTransferCertificates(event.target.value)
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      name="Transfer Certificate  _no"
                      value="false"
                      className="malebutton"
                      checked={transferCertificates === "false"}
                      onChange={(event) =>
                        setTransferCertificates(event.target.value)
                      }
                    />
                  </div>
                </Form.Group>
              </Col>
              {transferCertificates === "true" ? (
                <>
                  <Col xs={12} md={6} lg={3} className="rowCss">
                    <Form.Group>
                      <div
                        className="uploadDoc"
                        style={{
                          border: `1px solid ${getBorderColor2()}`, // Dynamic border color
                        }}
                      >
                        <div className="layout1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                              fill="#636363"
                            />
                          </svg>
                          <div className="title1">Upload&nbsp;Doc</div>
                        </div>
                        <input
                          type="file"
                          accept=".pdf,.jpg"
                          onChange={handleTransferCertificateChange}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 1,
                            top: 0,
                            left: 0,
                          }}
                        />
                      </div>
                      <div>
                        <p>{transferDoc?.[0].name}</p>
                      </div>
                    </Form.Group>
                    {formErrors.transferCertificate && (
                      <div className="error-message">
                        Please Select transferCertificate .
                      </div>
                    )}
                  </Col>
                </>
              ) : (
                <Col>{/* false */}</Col>
              )}

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <Form.Label className="lableName">Report Card</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      name="ReportCard_yes"
                      value="true"
                      className="malebutton"
                      checked={reportCards === "true"}
                      onChange={(event) => setReportCards(event.target.value)}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      name="ReportCard _no"
                      value="false"
                      className="malebutton"
                      checked={reportCards === "false"}
                      onChange={(event) => setReportCards(event.target.value)}
                    />
                  </div>
                </Form.Group>
              </Col>
              {reportCards === "true" ? (
                <>
                  <Col xs={12} md={6} lg={3} className="rowCss">
                    <Form.Group>
                      <div
                        className="uploadDoc"
                        style={{
                          border: `1px solid ${getBorderColor3()}`, // Dynamic border color
                        }}
                      >
                        <div className="layout1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                              fill="#636363"
                            />
                          </svg>
                          <div className="title1">Upload&nbsp;Docu</div>
                        </div>
                        <input
                          type="file"
                          accept=".pdf,.jpg"
                          onChange={handleReportCardChange}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 1,
                            top: 0,
                            left: 0,
                          }}
                        />
                      </div>
                      <div>
                        <p>{reportDoc?.[0].name}</p>
                      </div>
                    </Form.Group>
                    {formErrors.reportCard && (
                      <div className="error-message">
                        Please Select reportCard .
                      </div>
                    )}
                  </Col>
                </>
              ) : (
                <Col>{/* false */}</Col>
              )}
            </>
          ) : (
            <Col>{/* false */}</Col>
          )}
        </Row>

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
            style={{ marginBottom: "2rem" }}
          >
            Clear
          </button>
          <button
            type="submit"
            className="save-button-figma-div"
            style={{ marginBottom: "2rem" }}
            disabled={submit}
          >
            {submit ? <Spinner animation="border" /> : "Submit"}
          </button>
        </div>
      </Form>

    </div>
  );
};

export default AddStudent;
