/*
    authors: Naveen 
    date: 17-10-2023
    update:17-10-2023 
    version: 1.0
*/


import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexAlphaNumeric, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { PostAllocationRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/allocationBusRouteSlice";





export const AllocationBusRoute = () => {
    const [formData, setFormData] = useState([])
    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch();

    const showSuccessMsg = useSelector((state) => state.allocationBusRoute.showSuccessMessage);
    const successMsg = useSelector((state) => state.allocationBusRoute.successMessage);

    const showErrorMsg = useSelector((state) => state.allocationBusRoute.showErrorMessage);
    const errorMsg = useSelector((state) => state.allocationBusRoute.errorMessage);


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(PostAllocationRequest(formData));
    }

    const handleCancel = () => {
        setFormData({
            vehicleNo: "",
            driverName: "",
            mobileNo: "",
            routeMap: "",
        });
        setValidated(false);
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };
    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="content-header" >Allocation Bus Route</div>
                <Row >
                    <Col></Col>
                    <Col className="rowCss">
                        <TextInputField
                            name="Vehicle"
                            textType="AlphaNumeric"
                            value={formData.vehicleNo}
                            onChange={(event) => {
                                setFormData({ ...formData, "vehicleNo": event.target.value });
                            }}
                            required
                            placeholder="vehicleNo"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter Vehicle No."
                            validationRegex={validationRegexAlphaNumeric}
                            validationErrorMessage="Please enter a valid Vehicle No."
                        />
                    </Col>

                    <Col className="rowCss">
                        <TextInputField
                            name="Driver Name"
                            type="text"
                            textType="Alphabet"
                            value={formData.driverName}
                            onChange={(event) => {
                                setFormData({ ...formData, "driverName": event.target.value });

                            }}
                            required
                            placeholder="driverName"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter Driver Name."
                            validationRegex={validationRegexOnlyAlpha}
                            validationErrorMessage="Please enter a valid DriverName."
                        />
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col ></Col>
                    <Col className="rowCss">
                        <TextInputField
                            name="MobileNo"
                            type='text'
                            value={formData.mobileNo}
                            onChange={(event) => {
                                setFormData({ ...formData, "mobileNo": event.target.value });

                            }}
                            required
                            placeholder="MobileNo"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter MobileNo."
                            validationRegex={validationRegexMobileNo}
                            validationErrorMessage="Please enter a valid MobileNo."
                        />
                    </Col>
                    <Col>
                        {/* <TextInputField
                            name="Route Map"
                            textType="From -To"
                            placeholder="From - To"
                            value={formData.fromTo}
                            validated={validated}
                            required
                            maxLength={25}
                            onChange={(event) => {
                                setFormData({ ...formData, "fromTo": event.target.value });
                            }}
                            feedbackMessage="Please enter Bus Route Map."
                            validationRegex={validationRegexOnlyTextarea}
                            validationErrorMessage="Please enter a valid Bus Route Map."
                        /> */}
                    </Col>
                    <Col></Col>
         
                </Row>

                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleCancel}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>

            </Form>
        </>
    )
}
