import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectingValuesDropDownField } from '../../ReusableFields/DropDown/multipleSelectDropDown';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParentDataOnce } from '../parent/viewParentSlicer';
import { API_PATH } from '../../Api/api';
import { franchiseeSchoolId, token } from '../../Api/token';
import { useNavigate } from 'react-router-dom';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';


const SendMessage = () => {
    const [selectedParents, setSelectedParents] = useState([]);
    const [selectedCheckedParents, setSelectedCheckedParents] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate()
    const auth_token = token()
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [send, setSending] = useState(false);


    const apiData = useSelector((state) => state.viewParent.data) || [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchParentDataOnce());
    }, [dispatch]);

    const parentOptions = apiData.map((parent) => ({
        value: parent.id,
        label: parent.name
    }));

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            setSelectedParents([]);
            setSelectedCheckedParents(parentOptions);
        } else {
            setSelectedParents([]);
            setSelectedCheckedParents([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleParentsChange = (selectedOptions) => {
        setSelectedParents(selectedOptions || []);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = (event) => {
        setSending(true);
        event.preventDefault();

        // Construct the payload
        const selectedParentIds = (selectedParents.length > 0 ? selectedParents : selectedCheckedParents).map(parent => parent.value);
        const data = {
            guardianIds: selectedParentIds,
            message: message
        };
        // Set up the request headers and options
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth_token}`);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: "follow"
        };

        // Perform the API request
        fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId()}/message-center`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setSuccessMessage("Message sent successfully!");
                setShowSuccessPopup(true);
                setSending(false);
                // Handle success (e.g., show a success message or redirect)
            })
            .catch((error) => {
                console.error(error);
                setSending(false);
                // Handle error (e.g., show an error message)
            });
    };


    const handleClear = () => {
        setSelectedParents([]);
        setSelectedCheckedParents([]);
        setSelectAll(false);
        setSending(false);
        setMessage('');
    };

    const handleCloseSuccessPopup = () => {
        setShowSuccessPopup(false);
        handleClear();
        navigate("/viewMessages");
    };

    return (
        <div className="container">
            <h2>Send Message</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="text" value={new Date().toISOString().split('T')[0]} disabled style={{ width: '15%' }} />
                </Form.Group>

                <Form.Group controlId="formParents">
                    <Form.Label>Parents</Form.Label>
                    <div className="d-flex align-items-center">
                        <Space>
                            <Form.Check
                                type="checkbox"
                                label="Select All"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                className="mr-3"
                            />
                            {!selectAll && (
                                <SelectingValuesDropDownField
                                    isMulti
                                    value={selectedParents}
                                    onChange={handleParentsChange}
                                    options={parentOptions}
                                    placeholder="Select Parents"
                                />
                            )}
                        </Space>
                    </div>
                </Form.Group>

                <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={message}
                        onChange={handleMessageChange}
                        required
                        style={{ width: '70%' }}
                    />
                </Form.Group>
                <SuccessMessagePopup
                    show={showSuccessPopup}
                    onClose={handleCloseSuccessPopup}
                    successMessage={successMessage}
                />
                {/* <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} /> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0.5%",
                        marginTop: "2%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit" disabled={send}>
                        {send ? "Sending..." : "Send"}
                    </button>
                </div>

            </Form>
        </div>
    );
};

export default SendMessage;
