import { createSlice } from '@reduxjs/toolkit';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';


const slice = createSlice(
    {
        name: 'pictureUpload',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
            showSuccessMessage: false,
            successMessage: '',
            showErrorMessage: false,
            errorMessage: ''
        },
        reducers: {
            fetchPictureUpload: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchPictureUploadSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : [...action.payload];
            },
            fetchPictureUploadFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setFetchPictureUpload: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }
        },
    }


);

export const { fetchPictureUpload, fetchPictureUploadSuccess, fetchPictureUploadFailure, setSuccessMessage,
    setShowSuccessMessage, setErrorMessage, setShowErrorMessage, setFetchPictureUpload } = slice.actions;


export const PostPictureUpload = (postData) => async (dispatch) => {
    dispatch(fetchPictureUpload());
    const tokenId = token();
    try {
        const response = await fetchWithAuthPost(API_PATH + '/pictureUploading', 'POST', tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            dispatch(fetchPictureUploadSuccess(responseData.data));
            dispatch(setSuccessMessage("Successfully Created!"));
            dispatch(setShowSuccessMessage(true));

        }
    }
    catch (error) {
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }

};


export default slice.reducer;
