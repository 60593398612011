import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnceProgram } from "./slice/viewProgramSlice"


const ViewProgram = () => {
  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "name", header: "Program" },
  ];
  const apiData = useSelector((state) => state.viewProgram.data) || [];
  const postData = useSelector((state) => state.addProgram.data) || [];

  const combinedData = [...(apiData || []), ...postData];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDataOnceProgram());
  }, [dispatch]);

  const processedData = combinedData.map((row, index) => ({
    ...row,
    index: index + 1
  }));

  return (
    <>
      {/* <div className="content-header">View Program</div> */}
      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (

          <Table data={processedData} columns={columns} hover={true} striped={true} />
        )}
    </>
  );
}
export default ViewProgram;