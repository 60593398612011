import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';


export const FeePDFDocument = ({data}) => (
  <Document>
     {/* <View>dfgdfg</View> */}
    <Page size="A4" style={styles.page}>
    <Text style={{ ...styles.headerText,marginBottom:-10, marginLeft:240 }}>Fee Report </Text>
    <Text style={{ ...styles.headerText,marginBottom:-25, marginLeft:450,fontSize:15 }}>School Name </Text>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.header]}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Student Name</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Class</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Total Due</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Discount</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Net Due</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Received</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Balance</Text></View>
          </View>
          {data.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.student}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.class}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.totalDue}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.discount}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.netDue}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.received}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.balance}</Text></View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    margin: "10px 0"
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  header: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold"
  }
});
