import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';

const slice = createSlice({
    name: 'revenueTypeDelete',
    initialState: {
        data: [],
        isLoading: false,
        hasError: false,
        successMessage: '',
        showSuccessMessage: false,
        errorMessage: '',
        showErrorMessage: false,
    },
    reducers: {
        fetchDataStart: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.data = state.data ? [...state.data, ...action.payload] : action.payload;

        },
        fetchDataFailure: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessageRevenue: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessageRevenue: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setSuccessMessage, setShowSuccessMessageRevenue, setErrorMessage, setShowErrorMessageRevenue } = slice.actions;

export const deleteRevenueType = (revenueId) => async (dispatch) => {
    dispatch(fetchDataStart());
    const tokenId = token();

    try {
        const response = await fetchWithAuthPost(API_PATH + `/revenue-types/${revenueId}`, "DELETE", tokenId);

        if (!response) {
            const errorData = await response;
            throw new Error(errorData);
        } else {
            dispatch(fetchDataSuccess([revenueId ]));
            dispatch(setSuccessMessage("Successfully deleted!"));
            dispatch(setShowSuccessMessageRevenue(true));
        }
    } catch (error) {
        console.error("Error", error);
        const errMessage = responseFormat({ error });
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessageRevenue(true));
    }
};

export default slice.reducer;
