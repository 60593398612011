import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../../Api/api";
import { token } from '../../../Api/token';



const slice = createSlice(
  {
    name: 'viewClassSectionData',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchviewClassSectionDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchviewClassSectionDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchviewClassSectionDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchviewClassSectionDataStart, fetchviewClassSectionDataSuccess, fetchviewClassSectionDataFailure } = slice.actions;

export const fetchviewClassSectionDataOnce = (schoolIds) => async (dispatch, getState) => {
    let ids = "";
    if (schoolIds !== undefined && schoolIds !== null) {
      const queryString = 
        schoolIds.map(id => 'franchiseeSchoolId=' + id).join('&');
      ids += queryString
    }
  const { data, isLoading } = getState().viewClassSectionData;
  const tokenId = token();

  if (!isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchviewClassSectionDataStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/franchiseclasswisesections?${ids}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed'); // You can customize error handling
      }

      const data1 = await response.json();
      dispatch(fetchviewClassSectionDataSuccess(data1));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchviewClassSectionDataFailure());
    }
  }

}

export default slice.reducer;
