import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom"
import TableView1 from "../reUsableForm/table/table4";

const StudentFeeDueReports = () => {
  const [data, setData] = useState([])
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [formData, setFormData] = useState({});

  const [isValidFromDate, setIsValidFromDate] = useState({});
  const [isValidToDate, setIsValidToDate] = useState({});
  const columns = [
    { field: "id", header: "Sale Id" },
    { field: "saleDate", header: "Sale Date" },
    { field: "customerName", header: "Customer Name" },
    { field: "invoiceNumber", header: "Invoice Number" },
    { field: "vehicleId", header: "Vehicle Id" },
    { field: "saleBranch", header: "Sale Branch" },
    { field: "salePrice", header: "Sale Price" },
    { field: "dueAmount", header: "Due Amount" },
    { field: "advisor", header: "Advisor" },
   

  ]

 

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

  }

 



 

  return (
    <div className="App">
     <div className="content-header ">Student Fee Due Reports</div>
      {data === null ? ( // Check if data is null
        <center><p>Loading...</p></center>
      ) : data.length === 0 ? (
        <>
          <div style={{ margin: '1rem' }}>
            <Form onSubmit={handleSubmit}>
              <Row>

                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      required
                      className={`inputDate-figma ${isValidFromDate ? '' : 'is-invalid'}`}
                      placeholder="Date of Birth"
                      value={formData.fromDate || ''}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        const currentDate = new Date();
                        if (selectedDate <= currentDate) {
                          // Date is valid (not in the future)
                          setFormData({ ...formData, fromDate: event.target.value });
                          setIsValidFromDate(true);
                        } else {

                          setIsValidFromDate(false);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {isValidFromDate ? "Please  select From date." : "Please enter a valid from date."}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      required
                      className={`inputDate-figma ${isValidToDate ? '' : 'is-invalid'}`}
                      placeholder="Date of Birth"
                      value={formData.toDate || ''}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        const currentDate = new Date();
                        if (selectedDate <= currentDate) {
                          // Date is valid (not in the future)
                          setFormData({ ...formData, toDate: event.target.value });
                          setIsValidToDate(true);
                        } else {

                          setIsValidToDate(false);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {isValidToDate ? "Please select to date" : "Please enter a valid to date ."}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <button className='save-button-figma-div' type="submit" style={{ marginTop: '1.5rem' }}>
                    Generate
                  </button>
                </Col>
              </Row>

            </Form>

            <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
          </div>
        </>

      ) : (
        <>
          <div style={{ margin: '1rem' }}>
            <Form onSubmit={handleSubmit}>
              <Row>

                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      required
                      className={`inputDate-figma ${isValidFromDate ? '' : 'is-invalid'}`}
                      placeholder="Date of Birth"
                      value={formData.fromDate || ''}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        const currentDate = new Date();
                        if (selectedDate <= currentDate) {
                          // Date is valid (not in the future)
                          setFormData({ ...formData, fromDate: event.target.value });
                          setIsValidFromDate(true);
                        } else {

                          setIsValidFromDate(false);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {isValidFromDate ? "Please  select From date." : "Please enter a valid from date."}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      required
                      className={`inputDate-figma ${isValidToDate ? '' : 'is-invalid'}`}
                      placeholder="Date of Birth"
                      value={formData.toDate || ''}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        const currentDate = new Date();
                        if (selectedDate <= currentDate) {
                          // Date is valid (not in the future)
                          setFormData({ ...formData, toDate: event.target.value });
                          setIsValidToDate(true);
                        } else {

                          setIsValidToDate(false);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {isValidToDate ? "Please select to date" : "Please enter a valid to date ."}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <button className='save-button-figma-div' type="submit" style={{ marginTop: '1.5rem' }}>
                    Generate
                  </button>
                </Col>
              </Row>

            </Form>
          </div>
          <TableView1 data={data} columns={columns} hover={true} striped={true} FileName="Sales_Report" showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            editedData={editedData}
            setEditedData={setEditedData} />
        </>
      )}
    </div>
  );
}
export default StudentFeeDueReports;