import { createSlice } from "@reduxjs/toolkit";
import { responseStates } from "../../Teachers/teacherSlicers/teachersInitialState";
import { postLane } from "./laneRequests";
const initialState = {
    postLane: responseStates
}
const postLaneSlicer = createSlice({
    name: 'post lane',
    initialState,
    reducers: {
        CLSTIPostLane: (state) => {
            state.postLane.loading = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postLane.pending, (state) => {
                state.postLane.loading = 'pending';
            })
            .addCase(postLane.fulfilled, (state, action) => {
                const data = action.payload;
                if (Array.isArray(data)) {
                    // If data is an array, assume it's an array of objects
                    state.postLane.loading = 'fulfilled';
                    state.postLane.data = data;
                    state.postLane.error = null;
                } else if (data.hasOwnProperty('errors')) {
                    state.postLane.loading = 'rejected';
                    state.postLane.error = data.errors;
                } else {
                    state.postLane.loading = 'fulfilled';
                    state.postLane.data = [data['lane']];
                    state.postLane.error = null;
                }
            })
            .addCase(postLane.rejected, (state, action) => {
                const errors = action.payload;
                state.postLane.error = errors;
                state.postLane.loading = 'rejected'
            })


    }
})

export const { CLSTIPostLane } = postLaneSlicer.actions;
export default postLaneSlicer.reducer;