import React, { useState, } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexOnlyAlpha } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { postAddProgramRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addProgramSlice";
import ViewProgram from './viewProgram';


const AddProgram = () => {
  const [formData, setFormData] = useState({ name: '', });
  const [validated, setValidated] = useState(false);


  const dispatch = useDispatch();

  const showSuccessMsg = useSelector((state) => state.addProgram.showSuccessMessage);
  const successMsg = useSelector((state) => state.addProgram.successMessage);

  const showErrorMsg = useSelector((state) => state.addProgram.showErrorMessage);
  const errorMsg = useSelector((state) => state.addProgram.errorMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      // If the form is not valid, set the validated state to true to display validation errors
      setValidated(true);
      return;
    }


    const data = {
      ...formData,
    }
    dispatch(postAddProgramRequest(data));

  };
  const handleClear = () => {
    setFormData({
      name: '',
    });
    setValidated(false);
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  }
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <div className="content-header">Add Program</div>
        <Row >
          <Col></Col>
          <Col></Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <Form.Group >
              {/* <Form.Label className='mt2' ></Form.Label> */}
              <TextInputField
                className="fcontrol"
                type="text"
                name="name"
                placeholder='Program'
                required
                value={formData.name}
                onChange={(event) => {
                  setFormData({ ...formData, "name": event.target.value });
                }}
                validated={validated}
                feedbackMessage="Please enter program Name."
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter a valid  program Name."
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
      <div>
        <ViewProgram />
      </div>
    </>
  );
};

export default AddProgram;