import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../reUsableForm/textInputField";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { validationRegexOnlyAlpha } from "../reUsableForm/regex";
import { postCityRequest, setShowSuccessMessage, setShowErrorMessage } from "./slice/addCitySlice";
import { fetchStateDataOnce } from "../state/slice/viewStateSlice"
import SelectDropdown from "../SelectDropdown";
import viewCity from "./viewCity"
import ViewCity from "./viewCity";

const AddCity = () => {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [formErrors, setFormErrors] = useState({ stateId: false })

  const data = useSelector((state) => state.viewState.data) || [];
  const postData = useSelector((state) => state.state.data) || [];
  const combinedData = [...(data||[]),...postData]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStateDataOnce());

  }, [dispatch])

  const showSuccessMsg = useSelector((state) => state.city.showSuccessMessage);
  const successMsg = useSelector((state) => state.city.successMessage);

  const showErrorMsg = useSelector((state) => state.city.showErrorMessage);
  const errorMsg = useSelector((state) => state.city.errorMessage);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormErrors = {
      stateId: !formData.stateId,
      name: !formData.name
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some(error => error);


    if (hasErrors) {
      setValidated(true);
      return;
    }
    const cityData = {
      ...formData,
      stateId: Number(selectedState),
    };
    const stateId = formData.stateId.id

    formData.stateId = stateId;
    // formData.name = name;

    dispatch(postCityRequest(cityData));

    setSelectedState('');
    setFormData({});
  };
  const handleClear = () => {
    setFormData({
      city: "",
    });
    setFormErrors({
      stateId: false,
    });
    setValidated(false);
    setSelectedState('');
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Add City</div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <SelectDropdown
              isSearchable
              value={formData.stateId ? { id: formData.stateId.id, label: formData.stateId.label } : null}
              options={combinedData.map((state) => ({ id: state.id, label: state.name, value: state.name }))}
              onChange={(selectedOption) => {
                setFormData({ ...formData, stateId: selectedOption });
                setSelectedState(selectedOption.id)
                setFormErrors({ ...formErrors, stateId: !selectedOption });
              }}
              placeholder="Select state"
            />
            {formErrors.stateId && (
              <div className="error-message">
                Please Select state .
              </div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
            <Form.Group>
              <TextInputField
                type="text"
                className="input-style"
                required
                placeholder="City"
                value={formData.name}
                onChange={(event) => {
                  setFormData({ ...formData, name: event.target.value });
                }}
                validated={validated}
                feedbackMessage="Please enter City"
                validationRegex={validationRegexOnlyAlpha}
                validationErrorMessage="Please enter a valid City"
              />
            </Form.Group>
          </Col>
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.5%",
            marginTop: "0%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
            style={{ width: '6rem', height: '2rem' }}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" style={{ width: '6rem', height: '2rem' }}>
            Submit
          </button>
        </div>
      </Form>


      <div>
        <ViewCity />
      </div>
    </>
  );
};

export default AddCity;
