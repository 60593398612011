import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../../Api/api";
import { token } from "../../../Api/token";
import { fetchWithAuthorization } from "../../Auth/dependency-get";

const slice = createSlice({
  name: "feeDueDates",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } =
  slice.actions;

export const fetchDueDateGet = () => async (dispatch, getState) => {
  const { data, isLoading } = getState().feeDueDates;

  const tokenId = token();

  if (data === null && !isLoading) {
    dispatch(fetchDataStart());

    try {
      const response = await fetchWithAuthorization(
        API_PATH + "/dueDates",
        "GET",
        tokenId
      );

      if (!response) {
        throw new Error("Request failed");
      }

      const data1 = await response;
      dispatch(fetchDataSuccess(data1));
    } catch (error) {
      dispatch(fetchDataFailure());
    }
  }
};

export default slice.reducer;
