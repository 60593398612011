import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SearchableDropDownField from "../../../ReusableFields/DropDown/SearchableDropDownField";
import RadioButton from "../../reUsableForm/radioButton";
import { amountWithDecimalPattern, distancePattern, validationRegexOnlyAlpha } from "../../reUsableForm/regex";
import TextInputField from "../../reUsableForm/textInputField";
import { setShowErrorMessage, setShowSuccessMessage, transportationFeeMaster } from "./transportationFeeSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFrequencyDataOnce } from "../../Frequency/slice/viewFrequencySlice";
import SuccessMessagePopup from "../../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../../reUsableForm/pop-card/errorMessage";


export const TransportationFee = () => {

    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [frequencyId, setFrequencyId] = useState({})

    const [selectedOption, setSelectedOption] = useState('');

    const [formErrors, setFormErrors] = useState({
        frequencyId: false, distance: false, bimonthlyFee: false, monthlyFee: false, perDay: false, transportation: false
    });


    const getDayFrequency = useSelector((state) => state.dayFrequency.data) || [];
    const postDayFrequency = useSelector((state) => state.frequencySlice.data) || [];

    const DayFrequency = [...(getDayFrequency || []), ...postDayFrequency]

    const DistanceData = [{ "id": 1, "distance": "1" }, { "id": 2, "distance": "2" }, { "id": 3, "distance": "3" }, { "id": 4, "distance": "4" }, { "id": 5, "distance": "5" }]



    const showSuccessMsg = useSelector((state) => state.transportationFeeSlice.showSuccessMessage);
    const successMsg = useSelector((state) => state.transportationFeeSlice.successMessage);

    const showErrorMsg = useSelector((state) => state.transportationFeeSlice.showErrorMessage);
    const errorMsg = useSelector((state) => state.transportationFeeSlice.errorMessage);


    const dispatch = useDispatch()

    const handleRadioChange = (event) => {
        setFormErrors({ ...formErrors, transportation: false });
        setSelectedOption(event.target.value);
        setFormData({ ...formData, way: (event.target.value) })
    };


    useEffect(() => {
        dispatch(fetchFrequencyDataOnce())
    }, [dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        const newFormErrors = {
            frequencyId: !formData.frequencyName,
            distance: !formData.distance,
            bimonthlyFee: !formData.bimonthlyFee,
            perDay: !formData.dayFee,
            monthlyFee: !formData.monthlyFee,
            transportation: !formData.way

        };
        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            return;
        }

        const sendData = {
            ...formData,
            frequencyId: frequencyId.frequencyId,
        }
        dispatch(transportationFeeMaster(sendData));
    }

    const handleCancel = () => {
        setFormData({
            frequencyName: '',
            distance: '',
            bimonthlyFee: '',
            perDay: '',
            monthlyFee: '',
            transportation: ''
        });
        setSelectedOption('');
        setFormErrors({
        })
        setValidated(false);
    }
    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));

    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="content-header" style={{ margin: '1rem' }}>Fee Master</div>
                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={formData.frequencyName ? { label: formData.frequencyName } : null}
                            options={DayFrequency.map((frequency) => ({
                                id: frequency.id,
                                label: frequency.name,
                                value: frequency.name
                            }))}
                            onChange={(selectedOption) => {
                                setFrequencyId({ ...frequencyId, frequencyId: selectedOption ? selectedOption.id : null });
                                setFormErrors({ ...formErrors, frequencyId: false });
                                setFormData({ ...formData, frequencyName: selectedOption ? selectedOption.value : null })

                            }}
                            placeholder="Select Frequency"
                        />
                        {formErrors.frequencyId && (
                            <div className="error-message">
                                Please Select Frequency .
                            </div>
                        )}
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={formData.distance ? { label: formData.distance } : null}
                            options={DistanceData.map((distance) => ({
                                id: distance.id,
                                label: distance.distance,
                                value: distance.distance
                            }))}
                            onChange={(selectedOption) => {
                                setFormErrors({ ...formErrors, distance: false });
                                setFormData({ ...formData, distance: selectedOption ? Number(selectedOption.value) : null })

                            }}
                            placeholder="Select Distance"
                        />
                        {formErrors.distance && (
                            <div className="error-message">
                                Please Select distance .
                            </div>
                        )}
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Pick/Drop"
                            value="PickOrDrop"
                            checked={selectedOption === 'PickOrDrop'}
                            onChange={handleRadioChange}
                        />
                        {formErrors.transportation && (
                            <div className="error-message">
                                Please Select transportation way.
                            </div>
                        )}
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <RadioButton
                            label="Pick&Drop"
                            value="PickAndDrop"
                            checked={selectedOption === 'PickAndDrop'}
                            onChange={handleRadioChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="Monthly Fee"
                            textType="name"
                            value={formData.monthlyFee}
                            onChange={(event) => {

                                setFormData({ ...formData, "monthlyFee": Number(event.target.value) });
                                
                            }}
                            required
                            placeholder="Monthly Fee"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter Monthly Fee."
                            validationRegex={amountWithDecimalPattern}
                            validationErrorMessage="Please enter Monthly Fee."
                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="Monthly Fee"
                            textType="name"
                            value={formData.bimonthlyFee}
                            onChange={(event) => {
                                setFormData({ ...formData, "bimonthlyFee": Number(event.target.value) });
                                // setFormErrors({ ...formErrors, bimonthlyFee: false });
                            }}
                            required
                            placeholder="Bimonthly Fee"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter bimonthly Fee."
                            validationRegex={amountWithDecimalPattern}
                            validationErrorMessage="Please enter bimonthly Fee."
                        />
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name="Per day charges"
                            textType="name"
                            value={formData.dayFee}
                            onChange={(event) => {
                                setFormData({ ...formData, "dayFee": Number(event.target.value) });
                            }}

                            required
                            placeholder="Per Day Charges"
                            validated={validated}
                            maxLength={25}
                            feedbackMessage="Please enter Per Day Charges."
                            validationRegex={amountWithDecimalPattern}
                            validationErrorMessage="Please enter Per Day Charges."
                        />
                    </Col>
                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div style={{ display: "flex", justifyContent: "center", marginBottom: "1%", marginTop: "5%" }} >
                    <button className="clear-button-figma-div" type="button" onClick={handleCancel}  >Clear </button>
                    <button className="save-button-figma-div" type="submit">  Submit</button>
                </div>

            </Form>

        </>
    )
}