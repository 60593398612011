import React, { useEffect } from "react"; // Importing necessary modules
import { useDispatch, useSelector } from "react-redux";
import TableView from "../../reUsableForm/table/table"; // Importing TableView component
import { fetchSchoolFeeData } from "../FeeStructureSlice/feeStructureGetSlice";

const SchoolFeeDetails = () => {
  // Defining SchoolFeeDetails component using arrow function
  const dispatch = useDispatch(); // Initializing useDispatch hook
  const schoolFeeData = useSelector((state) => state.schoolFees.data) || [];
  const PostdueDateData = useSelector((state) => state.schoolFee.data) || [];
  const isLoading = useSelector((state) => state.schoolFee.isLoading);
  const hasError = useSelector((state) => state.schoolFee.hasError);

  // Fetching school fee data on component mount
  useEffect(() => {
    dispatch(fetchSchoolFeeData());
  }, [dispatch]);
  const combinedData = [...(schoolFeeData || []), ...PostdueDateData];

  // Defining columns for TableView component
  const columns = [
    { field: "id", header: "Sr.No" },
    { field: "academicYear", header: "Academic Year" },
    { field: "school", header: "School" },
    { field: "programName", header: "Program" },
    { field: "dayFrequency", header: "Frequency" },
    { field: "timing", header: "Timings" },
    { field: "feeHeadName", header: "Fee Head" },
    { field: "fee_classes", header: "Classes" },
    { field: "DueDatesViewWithAmount", header: "Due Date" },
  ];

  const truncateClasses = (classes) => {
    const classNames = classes.map(classObj => classObj.name).join(', ');
  return classNames;
  };

  // Format the data to match the structure expected by the TableView component
  const formattedData = combinedData.map((item, index) => ({
    ...item,
    id: index + 1,
    feeHeadName: item.feeHead.name,
    dueDates: item.feeHead.dueDates,
    fee_classes: truncateClasses(item.classes)
  }));

  // Rendering component JSX
  return (
    <div>
      <h2>School Fee Details</h2>
      {isLoading ? ( // Display loading message if data is loading
        <p>No Data </p>
      ) : hasError ? ( // Display error message if there's an error
        <p>Error occurred while fetching data.</p>
      ) : (
        <TableView // Render TableView component with formatted data
          data={formattedData}
          columns={columns}
          hover={true}
          striped={true}
        />
      )}
    </div>
  );
};

export default SchoolFeeDetails; // Exporting component
