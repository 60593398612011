/*
    authors: Rajeev
    date: 02-02-2024
    update:02-02-2024 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";

import { useDispatch, useSelector } from "react-redux";
import { viewRequestSuggestionsGet } from "./slicer/viewRequestSlicer";

export const FranchisorRequestReply = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const isActive = false
        dispatch(viewRequestSuggestionsGet(isActive));
    }, [dispatch]);

    const apiData = useSelector((state) => state.ViewComplaintsSuggestions.data) || [];
    const postData = useSelector((state) => state.AddComplaintsSuggestions.data) || [];

    const CombinedData = [...(apiData || []), ...postData]

    const formatDate = (date) => {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`
    };
    const columns = [
        { field: "index", header: "Sr.no" },
        { field: "date", header: "Date" },
        { field: "type", header: "Complaints/Suggestion" },
        { field: "description", header: "Description" },
        { field: "comment", header: "Reply" },
    ];


    const formattedApiData = CombinedData.map((item, index) => ({
        ...item,
        index: index + 1,
        date: formatDate(new Date(item.date)),
    }));
    return (
        <>
            <div className="content-header">View Requests / Suggestion</div>
            {formattedApiData === null ? (
                <center><p>Loading...</p></center>
            ) : formattedApiData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) :
                (
                    <Table data={formattedApiData} columns={columns} hover={true} striped={true} />
                )
            }
        </>
    );
}
