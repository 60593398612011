import { createSlice } from "@reduxjs/toolkit";
import { token } from "../../../Api/token";
import { API_PATH } from "../../../Api/api";
import { fetchWithAuthPost } from "../../Auth/dependency_post";
import { responseFormat } from "../../Auth/errorResponse";

const slice = createSlice({
  name: "schoolFee",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    fetchSchoolFee: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchSchoolFeeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = state.data
        ? [...state.data, ...action.payload]
        : action.payload;
    },
    fetchSchoolFeeFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
  },
});

export const {
  fetchSchoolFee,
  fetchSchoolFeeSuccess,
  fetchSchoolFeeFailure,
  setSuccessMessage,
  setShowSuccessMessage,
  setErrorMessage,
  setShowErrorMessage,
} = slice.actions;

export const ShoolFeeCreation = (postData) => async (dispatch) => {
  dispatch(fetchSchoolFee());

  const tokenId = token();

  try {
    const response = await fetchWithAuthPost(
      API_PATH + "/franchiseeSchoolFee",
      "POST",
      tokenId,
      postData
    );
    if (!response) {
      const errorData = await response;
      throw new Error(errorData.errors);
    } else {
      const responseData = await response;
      dispatch(fetchSchoolFeeSuccess([responseData.data]));

      dispatch(setSuccessMessage("Successfully Created!"));
      dispatch(setShowSuccessMessage(true));
    }
  } catch (error) {
    console.error("....error", error);
    const errMessage = responseFormat({ error });
    dispatch(setErrorMessage(errMessage));
    dispatch(setShowErrorMessage(true));
  }
};

export default slice.reducer;
