import { useLayoutEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getEmployeeAreas, getEmployeeQualifications } from '../teacherSlicers/allRequests';

const useAreasQuals = () => {
    const dispatch = useDispatch();
    const employeeQualificationsObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeQualifications);
    const getEmployeeAreasObject = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeAreas);
useLayoutEffect(() => {
        if (employeeQualificationsObject?.loading === 'idle') {
            dispatch(getEmployeeQualifications())
        }
    }, [dispatch, employeeQualificationsObject?.loading]);

    useLayoutEffect(() => {
        if (getEmployeeAreasObject?.loading === 'idle') {
            dispatch(getEmployeeAreas())
        }
    }, [dispatch, getEmployeeAreasObject?.loading]);
    const backendAreas = getEmployeeAreasObject?.data;
    const backendQualifications = employeeQualificationsObject?.data;
    return [backendAreas.length>0?backendAreas:[],backendQualifications.length>0?backendQualifications:[]];
}


export default useAreasQuals;