import React from 'react';
import { Col, Form } from 'react-bootstrap';
import './ReadOnlyDateField.css'

//Using Way


{/* <Col>
<ReadOnlyDateInputField
  label="Date of Birth"
  name="dob"
  value={selectedDate}
  required
/>
</Col> */}

const ReadOnlyDateInputField = ({
  label,
  name,
  value,
  onChange,
  required,
  colSize,
  feedbackMessage,
  validated
}) => {
  return (
    
      <Form.Group>
  {label && <Form.Label className="label-style">{label}</Form.Label>}


        <Form.Control
          type="date"
          className="readOnlyDate_field"
          name={name}
          readOnly
          value={value}
          onChange={onChange}
          validated={validated}
          required={required}
        />
        {validated && feedbackMessage && (
          <Form.Control.Feedback type="invalid">
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
  );
};

export default ReadOnlyDateInputField;

