/*
    authors: Revanth
    date: 21-10-2023
    update:21-10-2023 
    version: 1.0
*/

import React, { useEffect } from 'react';
import '../EnquiryDetails/enquiryView.css'
import Table from '../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFranchiseFollowUpView } from './franchiseSlice/franchiseFollowUpSlicer';
import { FranchiseGetView } from './franchiseSlice/franchiseViewSlicer';
const FranchiseView = () => {
    const apiData = useSelector((state) => state.franchiseView.data) || [];
    const postData = useSelector((state) => state.franchiseEnquirySlicer.data) || [];
    const putData = useSelector((state) => state.franchiseFollowUp.data) || [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FranchiseGetView());
    }, [dispatch]);

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "name", header: "Name" },
        { field: "contactNumber", header: "Contact No" },
        { field: "reference", header: "Reference" },
    ]
    const combinedData = [...(apiData || []), ...postData];
  
    const newModifiedData = combinedData.map((postItem, index) => {

        const formattedDate = new Date(postItem.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
        return {
            index: index + 1,
            id: postItem.id,
            date: formattedDate,
            name: postItem?.name,
            contactNumber: postItem?.contactNumber,
            reference: postItem?.reference,
            status: postItem?.status,
            description: postItem?.description,
        };
    })
    const newUpdatedData = newModifiedData.filter(obj => !putData.some(postObj => postObj.id === obj.id))

    return (
        <div>
            <h4 className='enquiryView'>Franchise View</h4>

            {newUpdatedData === null ? (
                <center><p>Loading...</p></center>
            ) : newUpdatedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={newUpdatedData} columns={columns} hover={true} />
            )}
        </div>
    );
};

export default FranchiseView;