import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import "./textInputField.css"

const DatePickerPast = ({ placeholder, value, onChange, errorMessage }) => {
    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const selectedDate = new Date(inputValue);
        selectedDate.setHours(0, 0, 0, 0);

        if (selectedDate <= currentDate) {
            setIsValid(true);
            onChange(inputValue);
        } else {
            setIsValid(false);
        }

    };

    return (
        <>
            <Form.Control
                placeholder={placeholder}
                type="date"
                // className={`input-style ${isValid ? '' : 'is-invalid'}`}
                className="textInput_Field" 
                value={value}
                onChange={handleInputChange}
                max={(new Date()).toISOString().split('T')[0]}
            />
            {!isValid && <div className="invalid-feedback">{errorMessage}</div>}
        </>
    );
};

export default DatePickerPast;
